import metadataServices from '@sstdev/lib_metadata-services';

const { dataDictionaryService } = metadataServices;

const createDataModel = (dataDictionaryRelation, featureFlags) => {
    const flattenedDataModel = Object.values(dataDictionaryRelation)
        .map(model => {
            if (!model?._meta) return [];

            // if the model is excluded from import, set the id to _skip
            if (model._meta?.excludeFromImport === true) {
                model._meta.id = '_skip';
                return [model];
            }

            // no support for image  through imports nor for invisible fields for now
            if (['Image', 'InvisibleElement'].includes(model._meta.hNodeType)) return [];

            // For now, this will work. But we probably will need to replace this with something smarter
            // where it takes specific properties to exclude from the import from blockly
            if (model._meta.hNodeType === 'NestedObject') return [];

            // shortcut for non-hierarchies:
            if (model._meta.hNodeType !== 'EditableList') return [model];

            return Object.values(model)
                .filter(x => x._meta && x._meta.title)
                .map(c => ({
                    ...c,
                    _meta: {
                        ...c._meta,
                        title: `[${model._meta.title}] - ${c._meta.title}${c._meta.required ? ' *' : ''}`,
                        required: false,
                        arrayProperty: model._meta.propertyName,
                        isArrayElement: true
                    }
                }));
        })
        .reduce((previous, current) => previous.concat(current), []) // and add dataTypes to all
        .map(field => {
            return field._meta
                ? {
                      _meta: { ...field._meta, dataType: fieldToDataType(field._meta, featureFlags) }
                  }
                : field;
        });

    return flattenedDataModel;
};

createDataModel.fromHNodes = (hNodes, featureFlags) => {
    if (!hNodes) return;

    const dataDictionaryExtracts = hNodes.reduce((collected, hNode) => {
        // eslint-disable-next-line no-unused-vars
        const [dataDictionary, typeDefinition] = dataDictionaryService.extract(hNode, featureFlags);
        const [propertyName] = Object.keys(typeDefinition);

        // include everything from the hNode, typeDefinition, and dataDictionary since the typeDefinition and dataDictionary seem to exclude some properties
        hNode = {
            _meta: {
                ...hNode,
                ...typeDefinition[propertyName]?._meta,
                ...dataDictionary[propertyName]?._meta
            }
        };

        return {
            ...collected,
            [propertyName]: hNode
        };
    }, {});

    return createDataModel(dataDictionaryExtracts, featureFlags);
};

export default createDataModel;

function fieldToDataType(field, featureFlags = []) {
    switch (field.hNodeType) {
        case 'ShortEncodedText':
            // need to pull in featureFlag here....
            if (featureFlags.includes('displayInAscii')) {
                return {
                    type: 'ASCII'
                };
            } else {
                return {
                    type: 'Hexadecimal',
                    tooltip: 'Valid HEX characters only (0-9/A-F)'
                };
            }

        case 'Integer':
            return {
                type: 'Integer'
            };

        case 'Currency':
            return {
                type: 'Currency'
            };
        case 'DatePicker':
        case 'ReadOnlyDateTime':
            return {
                type: 'Date/Time'
            };

        case 'Email':
            return {
                type: 'Email'
            };

        case 'Image':
            return {
                type: 'base64-encoded Image'
            };

        case 'DropDown':
        case 'DropDownWithFilter':
            return {
                type: 'Lookup'
            };

        case 'Toggle':
            return {
                type: 'Boolean',
                tooltip: 'Valid values:\n • true / false\n • 1 / 0\n • yes / no'
            };

        case 'EditableList':
            return {
                type: 'Array',
                tooltip:
                    'Click edit to select specific fields for individual entries, or be sure the field contains a properly formatted JSON array'
            };
    }
}
