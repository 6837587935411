import react, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { View, styled, Image, testProperties } from 'lib_ui-primitives';
import lodash from 'lodash';
const { get } = lodash;
import { getImage, TRANSPARENT_PIXEL } from '../../../../../images';

const rc = react.createElement;

const SplitLabel = styled(View).attrs({ name: 'split-label' })`
    flex-direction: row;
    width: Calc(100% - 12px);
    height: 100%;
`;
SplitLabel.displayName = 'SplitLabel';

const column = styled(View).attrs({ name: 'column' })`
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
`;

const QRCode = styled(Image).attrs({
    alt: 'QR Code Sample'
})`
    width: ${props => props.size || 100}px;
    z-index: 1;
`;

const QRCODE_PIZEL_SIZE = {
    5: 62, // 0.517 inch on 203 dpi
    4: 50, // 0.414 inch on 203 dpi
    3: 37, // 0.310 inch on 203 dpi
    2: 25 // 0.207 inch on 203 dpi
};

export default function QRContent(props) {
    const {
        record,
        hNode: { propertyName = 'title', alignRight = false },
        label: { height: labelHeight = 1 },
        children
    } = props || { hNode: {} };
    const value = get(record, propertyName, '').trim();

    let qrSize = props.hNode.size || 4;
    if (labelHeight < 0.6) {
        qrSize = Math.min(qrSize, 3);
    }

    const [src, setImage] = useState(TRANSPARENT_PIXEL);
    useEffect(() => {
        let allowStateUpdates = true;
        const loadImage = async () => {
            const image = await getImage('qrSample.png');
            if (allowStateUpdates) {
                setImage(image);
            }
        };
        if (value) {
            loadImage();
        }
        return () => (allowStateUpdates = false);
    }, [value]);

    //return as fragment, as we want the 2 lines to behave as 2 separate lines for spacing purposes.
    return rc(
        SplitLabel,
        null,
        !alignRight &&
            rc(
                column,
                { style: { flexGrow: 0 } },
                rc(QRCode, { src, size: QRCODE_PIZEL_SIZE[qrSize], ...testProperties({ testID: `qr-${value}` }) })
            ),
        rc(column, { style: { flexGrow: 1 } }, children),
        alignRight &&
            rc(
                column,
                { style: { flexGrow: 0 } },
                rc(QRCode, { src, size: QRCODE_PIZEL_SIZE[qrSize], ...testProperties({ testID: `qr-${value}` }) })
            )
    );
}

QRContent.propTypes = {
    hNode: PropTypes.shape({
        propertyName: PropTypes.string,
        includeHumanReadable: PropTypes.bool,
        excludeHumanReadable: PropTypes.bool
    }),
    record: PropTypes.object
};
