import logging from '@sstdev/lib_logging';
import { simpleChangeObserver } from 'lib_ui-services';
/**
 * Covers an array with various syntax sugar to implement a simple queue
 * @param {string} queueName - used for debugging only
 * @returns surprise - a queue
 */
export default function getQueue(queueName) {
    const _p = {
        queue: [],
        listeners: []
    };
    const { onChange, publishChange } = simpleChangeObserver();

    const _onChange = changeType => {
        publishChange(changeType);
        logging.debug(`[QUEUE] ${changeType} caused new ${queueName} queue state: ${JSON.stringify(_p.queue)}`);
    };

    const jumpQueue = member => {
        _p.queue.unshift(member);
        _onChange('jumpQueue');
        informListeners();
    };

    const enqueue = member => {
        _p.queue.push(member);
        _onChange('enqueue');
        if (_p.queue[0] === member) {
            informListeners();
        }
    };

    const peek = () => _p.queue[0];

    const dequeue = member => {
        const position = _p.queue.indexOf(member);
        _p.queue.splice(position, 1);
        _onChange('dequeue');
        if (position === 0) informListeners();
    };

    const next = () => {
        const member = _p.queue.shift();
        _onChange('next');
        informListeners();
        return member;
    };

    const contains = member => _p.queue.indexOf(member) >= 0;

    const onNewLeader = callback => {
        _p.listeners.push(callback);
        // unsubscribe
        return () => _p.listeners.splice(_p.listeners.indexOf(callback), 1);
    };

    const informListeners = () => {
        _p.listeners.forEach(listener => listener(_p.queue[0]));
    };

    const getLength = () => {
        return _p.queue.length;
    };
    return {
        contains,
        dequeue,
        enqueue,
        next,
        onNewLeader,
        peek,
        getLength,
        onChange,
        jumpQueue,
        _private: _p
    };
}
