import baseLogic from './didCreate_identity_backboneUser';
import baseWillUpdate from '../../namespace/relation/willUpdate_namespace_relation';
import { constants } from 'lib_ui-services';

export default {
    verb: 'willUpdate',
    namespace: 'identity',
    relation: 'backboneUser',
    useCaseIds: [constants.useCaseIds.NUCLEUS],
    priority: baseWillUpdate.priority + 5, //before the default willUpdate
    logic
};

async function logic({ data, dispatch }) {
    await baseLogic.logic({ data });
    if (data.newRecord.password) {
        const message = 'Password changed successfully.';
        dispatch(
            { message, timeout: 5000, addToList: false, isError: false },
            {
                verb: 'pop',
                namespace: 'application',
                relation: 'notification'
            }
        );
    }
    delete data.newRecord.password;
    delete data.oldRecord.password;

    return data;
}
