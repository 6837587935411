import lodash from 'lodash';
const { get } = lodash;
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import conversions from '@sstdev/lib_epc-conversions';
import { valueUtilities } from 'lib_ui-services';
import { getNestedValues } from './getNestedValues';

export default function getColumnValue(
    record,
    columnHNode,
    { featureFlags, activeRecord, splashRecord, navigationSelection } = {}
) {
    let value = get(record, columnHNode.propertyName);
    if (columnHNode.displayProperties?.length) {
        let prefix = [columnHNode.namespace, columnHNode.relation].join(':');
        if (prefix) prefix += '.';

        value = columnHNode.displayProperties
            .map(property => get(record, `${prefix}${property}`))
            .filter(Boolean)
            .join(', ');
    }
    if (value == null && columnHNode.dataType !== 'mappedBoolean') {
        return '';
    }
    switch (columnHNode.dataType) {
        case 'link':
        case 'text':
            return value;
        case 'dateTime':
            if (columnHNode.format === 'ISO') return formatISO(new Date(value));
            return format(new Date(value), columnHNode.format);
        case 'localShortDate':
            return format(new Date(value), 'P');
        case 'localVerboseDateTime':
            return format(new Date(value), 'PP p');
        case 'encodedText':
            return featureFlags.includes('displayInAscii') ? conversions.ascii.fromHex(value) : value;
        case 'mappedBoolean':
            if (columnHNode.mappedTrueValue || columnHNode.mappedFalseValue) {
                return value ? columnHNode.mappedTrueValue : columnHNode.mappedFalseValue;
            } else {
                return value ? 'True' : 'False';
            }
        case 'gpsCoordinates':
            throw new Error('not implemented');
        case 'nestedDetail': {
            const nestedField = columnHNode.children.find(c => c.hNodeTypeGroup === 'listColumn');
            if (!nestedField) return '';
            const values = getNestedValues(value, nestedField, {
                featureFlags,
                activeRecord,
                splashRecord,
                navigationSelection
            });
            return values.slice(0, 2).join(', ') + (values.length > 2 ? ', ...' : '');
        }
        case 'patchDetail':
            throw new Error('not implemented');
        // TODO: not sure what this is yet.
        // return value;
        case 'currency':
            return valueUtilities.formatCurrency(value);
        default:
            throw new Error(`Unknown data type for column ${columnHNode.label}`);
    }
}
