import fetchAllPages from './fetchAllPages';

export default {
    verb: 'willCopy',
    namespace: 'metadata',
    relation: 'useCaseDetail',
    description: 'Fetch all needed pages and add a reference to the source record upon branching/cloning (pre-create)',
    priority: 10,
    prerequisites: fetchAllPages.prerequisites,
    //this is the actual logic:
    logic: willCopy,
    onError: fetchAllPages.onError
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willCopy(message) {
    const data = await fetchAllPages.logic(message);
    const { record } = data;
    const { _id, title, major, minor, patch, build, version, 'metaui:useCase': useCase, meta, featureFlags } = record;
    return {
        ...data,
        record: {
            title,
            major,
            minor,
            patch,
            build,
            version,
            'metaui:useCase': useCase,
            meta,
            featureFlags,
            'metadata:useCaseDetail': { _id, title },
            verified: false,
            locked: false,
            notes: ''
        }
    };
}
