const modifyingMethods = ['pop', 'push', 'shift', 'unshift', 'splice', 'reverse', 'sort'];
export default class ObservableArray extends Array {
    constructor() {
        super();
        this.changeObservers = [];
        const that = this;
        modifyingMethods.forEach(method => {
            //no need to trigger events when the array is just being queried
            if (['filter', 'find', 'some', 'every'].includes(method)) return;
            this[method] = function () {
                const _superMethod = Array.prototype[method];
                const result = _superMethod.apply(that, arguments);
                that.changeObservers.forEach(observer => observer(that, method));
                return result;
            };
        });
        this.reset = this.reset.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    // store callbacks for change events subscriptions.
    onChange(func) {
        this.changeObservers.push(func);
        // unsubscribe
        return () => {
            this.changeObservers.splice(this.changeObservers.indexOf(func), 1);
        };
    }
    reset() {
        this.splice(0, this.length);
    }
}
