import { createElement as rc, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Button, Icon } from 'lib_ui-primitives';
import { Draggable, Label } from './styles';

//Key to limit dragging onto other droppable areas 9and vice versa:
export const DRAGGABLE_TYPE = 'column-configuration';

export function DraggableRow(props) {
    const { id, entry, onChange, onClick, index } = props;
    const ref = useRef(null);
    const [{ isDragging }, dragRef] = useDrag(
        () => ({
            type: DRAGGABLE_TYPE,
            item: { entry, index },
            collect: monitor => ({
                isDragging: !!monitor.isDragging()
            })
        }),
        []
    );
    const [{ isOver }, dropRef] = useDrop(
        () => ({
            accept: DRAGGABLE_TYPE,
            collect: monitor => ({
                isOver: !!monitor.isOver()
            }),
            drop: item => {
                if (!ref.current) {
                    return;
                }
                const dragId = item.entry.id;
                const dropId = entry.id;
                // Don't replace items with themselves
                if (dragId === dropId) {
                    return;
                }

                // perform the actual action
                onChange(dragId, dropId);
            }
        }),
        [index, entry]
    );
    //make dropRef and dragRef both point to ref:
    dropRef(dragRef(ref));

    return rc(
        Draggable,
        { id, ref, isDragging, isOver, title: 'Drag up or down to adjust column order' },
        rc(Icon, null, 'drag_indicator'),
        rc(Label, null, entry.title || '...'),
        rc(Button, {
            id: `visibility${entry.id}`,
            name: 'search-icon-container',
            buttonStyle: 'round',
            roundDiameter: 32,
            color: entry.hidden ? 'base' : 'accentOne',
            icon: entry.hidden ? 'visibility_off' : 'visibility',
            onClick: () => onClick(index)
        })
    );
}
