import getGlobalObject from './getGlobalObject';

const global = getGlobalObject();
/**
 * @typedef {Object} Auth0Config
 * @property {string} AUTH0_CLIENT_ID - The Auth0 client ID
 * @property {string} AUTH0_DOMAIN - The Auth0 domain
 * @property {string} AUTH0_CONNECTION_NAME - The Auth0 connection name
 */

/** @type {Object.<string, Auth0Config>} */
const configs = {
    // These are the Auth0 configurations for the different brands
    // The default values are the Auth0 configurations for dev
    sst: {
        AUTH0_CLIENT_ID: global.AUTH0_CLIENT_ID_SSTID || '5UkDDVtnEFiO5t1XTTm8CcU4cH1KlNc0',
        AUTH0_DOMAIN: global.AUTH0_DOMAIN_SSTID || 'login.dev.sstid.com',
        AUTH0_CONNECTION_NAME: global.AUTH0_CONNECTION_NAME || 'mongodb'
    },
    redbeam: {
        AUTH0_CLIENT_ID: global.AUTH0_CLIENT_ID_REDBEAM || '5WRcrjd8W7lR8J9MuOsRRIIZtkrjI6uF',
        AUTH0_DOMAIN: global.AUTH0_DOMAIN_REDBEAM || 'login.dev.redbeam.com',
        AUTH0_CONNECTION_NAME: global.AUTH0_CONNECTION_NAME || 'mongodb'
    },
    default: {
        AUTH0_CLIENT_ID: global.AUTH0_CLIENT_ID || '5WRcrjd8W7lR8J9MuOsRRIIZtkrjI6uF', //process.env.REACT_APP_AUTH0_CLIENT_ID;
        AUTH0_DOMAIN: global.AUTH0_DOMAIN || 'login.dev.redbeam.com', //process.env.REACT_APP_AUTH0_DOMAIN;
        AUTH0_CONNECTION_NAME: global.AUTH0_CONNECTION_NAME || 'mongodb'
    }
};

/**
 * Get the appropriate Auth0 configuration based on brand
 * @param {string} brand - The brand name
 * @returns {Auth0Config} The Auth0 configuration for the specified brand
 */
export default brand => {
    brand = brand.toLowerCase();

    // If not found, just return the default configuration (currently RedBeam dev)
    if (!configs[brand]) {
        return configs.default;
    }

    return configs[brand];
};
