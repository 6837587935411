import { createElement as rc } from 'react';
import styled from '../styled';
import webOnlyStyles from '../webOnlyStyles';

let Label = styled.label`
    position: relative;
    width: 50px;
    height: 25px;
`;
Label = webOnlyStyles(Label)`
    display: inline-block;
`;

const Track = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ disabled, theme, value }) => {
        const switchState = getSwitchState(value);
        if (disabled) {
            return theme.switch.disabledBackgroundColor;
        }
        switch (switchState) {
            case 'unchecked':
                return theme.switch.backgroundColorUnchecked;
            case 'checked':
                return theme.switch.backgroundColorChecked;
            default:
                // If the switch is in the neutral state, use the intermediate color
                return theme.switch.backgroundColorIntermediate;
        }
    }};

    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 31px;
    width: 46px;
    box-shadow: inset 0 0 2px #5c5c60;
    &:before {
        position: absolute;
        content: '';
        height: 21px;
        width: 21px;
        left: 2px;
        bottom: 2.5px;
        background-color: ${({ disabled, theme, value }) => {
            if (disabled) {
                return theme.switch.disabled;
            }
            return value ? theme.switch.checked : theme.switch.unchecked;
        }};
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
        transform: ${({ value }) => {
            const switchState = getSwitchState(value);
            switch (switchState) {
                case 'unchecked':
                    return 'translateX(0px)';
                case 'checked':
                    return 'translateX(20px)';
                default:
                    // If the switch is in the neutral state, move the toggle to the middle of the track
                    return 'translateX(10px)';
            }
        }};
    }
`;

const Input = styled.input.attrs(() => ({
    type: 'checkbox'
}))`
    opacity: 0;
    width: 0;
    height: 0;
    &:checked {
        background-color: #2196f3;
    }
    &:focus {
        box-shadow: 0 0 1px #2196f3;
    }
`;

export default function Switch(props) {
    const { value, onClick, className, disabled, name, onBlur, onFocus } = props;
    const onChange = e => onClick(e.target.checked);

    // We pass in a string when the toggle is in a neutral state, but we don't want that string to be interpreted as a checked value
    // So, if the value is not a boolean, default to false.
    const checked = typeof value === 'boolean' ? value : false;

    // prettier-ignore
    return rc(Label, { className },
        rc(Input, { name, checked, onChange, disabled, onBlur, onFocus, role: 'switch' }),
        rc(Track, { disabled, value }) //"Thumb" is rendered by the `:before`
    );
}

/**
 * Get the state of the switch based on the toggle value.
 * Used to determine the color and transform of the switch.
 *
 * @param {boolean} value
 * @returns {'checked' | 'unchecked' | 'neutral'}
 */
const getSwitchState = value => {
    if (value === false) {
        return 'unchecked';
    } else if (value === true) {
        return 'checked';
    }
    return 'neutral';
};
