const useCaseImages = {
    'Item Tracking': { path: 'Item Tracking.png' },
    'Work In Process': { path: 'WIP.png' },
    'Lot Management': { path: 'Lot Management.png' },
    'Asset Tracking': { path: 'AT.png' },
    MetaDataCreator: { path: 'MetaDataCreator.png' },
    MetaDataCreatorV3: { path: 'MetaDataCreator.png' },
    Nucleus: { path: 'Nucleus.png' },
    REX: { path: 'rex.png' },
    FLAIR: { path: 'flair.png' },
    PALM: { path: 'palm.png' },
    RedBeam: { path: 'RedbeamAT.png' },
    default: { path: 'sst-ball.png' }
};
export default useCaseImages;
