import logging, { UserError } from '@sstdev/lib_logging';
import network from '../../network';
import purgeSync from './purgeSync';
import pLimit from './pLimitPort';
import syncSingleRelation from './syncSingleRelation';
import notificationTypes from '../../constants/notificationTypes';
import * as metadata from '../../metadata';

const _p = {
    pLimit
};
export const _private = _p;
/**
 * Restrict running an array of async functions to run a maximum of `batchSize` in parallel.
 * Even if one or more functions fail, it will assure to continue to execute all other functions.
 * @param {Array<()=>Promise<void>>} arrayOfPromiseReturningFunctions
 * @param {number} [batchSize] Default: `6`. Most browsers have a max of being able to handle 6 outgoing requests at a time.
 * @returns {Promise<void>} Resolves when all functions succeeded. Rejects if any failed.
 */
export async function executeWithLimitedConcurrency(arrayOfPromiseReturningFunctions, batchSize = 6) {
    const limit = _p.pLimit(batchSize);

    const tasks = arrayOfPromiseReturningFunctions.map(task =>
        limit(() =>
            task().catch(error => {
                // if the error is a network error, we don't want to log it.
                // It will be retried anyway and this was flooding our logs.
                if (isNetworkError(error)) {
                    logging.debug('[SYNCHRONIZATION] Network error. Will retry.');
                } else {
                    logging.error(error);
                }
                throw error;
            })
        )
    );

    const results = await Promise.allSettled(tasks);
    const errors = results.filter(r => {
        return r.status === 'rejected';
    });
    if (errors?.length) {
        if (errors.every(e => isNetworkError(e.reason))) {
            throw new UserError(`${errors.length} network errors ocurred while limiting concurrency on your requests`);
        } else {
            throw new Error(`${errors.length} errors ocurred while limiting concurrency on your requests`);
        }
    }
}

function isNetworkError(err) {
    return (
        err.message === 'Network request failed' ||
        err.message === 'TypeError: Failed to fetch' ||
        err.message === 'net::ERR_NAME_NOT_RESOLVED' ||
        err.message.includes('is not valid JSON')
    );
}
/**
 * Synchronization is built using the following features
 * - Every individual http request will be retried up to 6 times with backoff (see getFromServer.js)
 *   backoff before each request = 0ms, 100ms, 200ms, 400ms, 800ms, 1600ms
 * - Every relation first obtains 1 batch of data, if there is more, THEN it does a count, and shows a progress bar
 *   (separately both for deletes, and for upserts)
 * - all http requests within a relation are rate limited by MAX_PATH_REQUEST_PER_TIME_FRAME (syncSingleRelation.js)
 * - all relations are synced somewhat in parallel, limited by `executeWithLimitedConcurrency` above
 * - all http requests (cross relations) are rate limited by MAX_REQUEST_PER_TIME_FRAME (syncSingleRelation.js)
 * - The first time a sync happens, when there has been data purged (which it does before any sync), or if it has been too long
 *   the user will be prompted with a progress bar
 * - All other times it will only spin the syncDialog icon
 */
class Synchronization {
    /**
     * @param {Object} database
     * @param {Object} database.settings
     * @param {function} database.isNewDatabase
     * @param {function} database.setStorageState
     * @param {function} database.getStorageState
     * @param {function} database.resetStorageState
     * @param {function} database.resumePhysicalWrites
     * @param {function} database.pausePhysicalWrites
     * @param {function} database.publish
     */
    constructor(database) {
        this.database = database;
        this.cleanUpBrokenSyncs = this.cleanUpBrokenSyncs.bind(this);
        this.syncAllRelations = this.syncAllRelations.bind(this);
        this.initialSyncFinished = new Promise(resolve => {
            this.resolveInitialSync = resolve;
        });

        const maxBatchSize = database.settings.config.backgroundSyncBatchSize;

        this._private = {
            executeWithLimitedConcurrency,
            getOfflineRelations: metadata.getOfflineRelations,
            notifyUser: _notifyUser(database.publish),
            setStorageState: database.setStorageState,
            getStorageState: database.getStorageState,
            syncSingleRelation: syncSingleRelation(database, maxBatchSize)
        };

        this.purgeWhatsNeeded = async publish => {
            //this is only called directly, and as a "nested" action from something else
            //so, no-one is waiting for failure notices, nor for a notice if we didn't do anything.
            const context = { verb: 'purge', namespace: 'application', relation: 'useCase', status: 'success' };
            try {
                const relationsPurged = await purgeSync.purgeWhatsNeeded(this._private, database);
                logging.debug(`[SYNCHRONIZATION] Purged ${relationsPurged.length} collections.`);
                if (relationsPurged.length) {
                    publish({ result: relationsPurged }, context);
                }
                return relationsPurged;
            } catch (err) {
                logging.error(err);
            }
        };
    }

    // called from Database.initializeStorage
    // IF they reloaded halfway the sync, this makes sure that all syncs restart
    cleanUpBrokenSyncs() {
        logging.debug('[SYNCHRONIZATION] Resetting sync state');
        this.database.settings.namespaces.forEach(ns =>
            ns.relations.forEach(rel => {
                if (!metadata.isLocalOnly(ns, rel, this.database.useCaseId) && !rel.originalRelation) {
                    this.database.setStorageState(ns.title, rel.title, 'syncStartTime', undefined);
                }
            })
        );
    }

    async syncAllRelations(payload, context, forceFullSync = false) {
        const { settings, publish } = this.database;

        const networkStatus = await network.getStatus();
        if (!networkStatus.isOnline) {
            this.resolveInitialSync();
            logging.debug('[SYNCHRONIZATION] Skipping sync since we are offline');
            return publish({ ...payload, result: [] }, { ...context, status: 'success' });
        }

        logging.debug('[SYNCHRONIZATION] syncAllRelations start');
        try {
            await this.database.pausePhysicalWrites();
            const startTime = new Date();

            // Perform purges first.
            const relationsPurged = await this.purgeWhatsNeeded(publish);

            const { getOfflineRelations, syncSingleRelation, executeWithLimitedConcurrency, notifyUser } =
                this._private;

            /**
             * @typedef {{
             *   initialSyncFinished: boolean,
             *   lastSyncRecordCompleted: string,
             *   nextBatchdQueryIndexKey: string,
             *   lastSyncTime: string,
             *   syncStartTime: string,
             *   lastPurgeTime: sting,
             *   lastBatch: boolean
             * }} StorageState
             * @type {Array<{namespace:string, relation:string, getStorageState:function():StorageState}>}
             */
            const offlineRelations = await getOfflineRelations(settings.namespaces, context);

            if (forceFullSync) {
                //to get a FULL sync, we need to clear all the lastSyncTime
                offlineRelations.forEach(relation => {
                    try {
                        this.database.setStorageState(
                            relation.namespace.title,
                            relation.relation.title,
                            'lastSyncTime',
                            undefined
                        );
                    } catch (error) {
                        logging.error(
                            `Failed to reset storage state lastSyncTime for ${relation.namespace.title}:${relation.relation.title}: ${error.message}`
                        );
                    }
                });
            }

            const [primaryRelation, secondaryRelations] = separatePrimaryFromSecondaryRelations(offlineRelations);

            let cutOff = new Date();
            //make the cutoff at 30 days
            cutOff.setDate(cutOff.getDate() - (settings.config.obtrusiveSyncAfterDaysUnsynced || 30));

            const obtrusive = primaryRelation.some(relation => {
                const storageState = this.database.getStorageState(relation.namespace.title, relation.relation.title);
                //make it obtrusive (make the user wait) if it was never synced, or if any relation was synced before the cutoff date
                return (
                    !storageState.initialSyncFinished ||
                    !storageState.lastSyncTime ||
                    new Date(storageState.lastSyncTime).getTime() < cutOff.getTime()
                );
            });

            let progress = -1;
            const incrementProgress = async () => {
                if (obtrusive) {
                    return publish(
                        {
                            mainTitle: 'Syncing In Progress...',
                            description:
                                'We are syncing your data. This may take a few minutes, depending on network speed.',
                            title: 'Progress',
                            //increment `progress`, then assign the updated value to `current` in practically a single operation to avoid concurrency issues
                            current: ++progress,
                            total: primaryRelation.length
                        },
                        { verb: 'update', namespace: 'application', relation: 'progress' }
                    );
                }
            };
            //dispatch message something to make the sync icon spin
            const assureSpinning = () =>
                publish(
                    { busy: true, source: 'httpSync', message: 'Sync Start', type: notificationTypes.SYNC_BUSY },
                    { verb: 'pop', namespace: 'application', relation: 'notification' }
                );
            //start the dialog, this increments `progress` it to 0
            await incrementProgress();
            let postPersistenceCallbacks = [];
            //the actual sync
            const primarySyncResult = executeWithLimitedConcurrency(
                primaryRelation.map(
                    relation => () =>
                        //assure spinning before each and every one, just in case the UI wasn't ready before
                        assureSpinning()
                            .then(() => syncSingleRelation(relation, obtrusive))
                            .then(({ postPersistence }) => {
                                //persistence is delayed. Keep the callback around until then
                                postPersistenceCallbacks.push(postPersistence);
                            })
                            .finally(incrementProgress)
                )
            );

            await notifyUser(primarySyncResult).finally(async () => {
                this.resolveInitialSync();
                if (obtrusive) {
                    //if the sync was obstructing app use, release it now.
                    publish(
                        { mainTitle: 'Syncing In Progress...' },
                        { verb: 'reset', namespace: 'application', relation: 'progress' }
                    );
                }
                const expendedMs = new Date() - startTime;
                logging.debug(
                    `[SYNCHRONIZATION] Primary relations sync completed in ${prettyPrintDuration(expendedMs)}`
                );
            });

            const secondaryResult = executeWithLimitedConcurrency(
                secondaryRelations.map(
                    relation => () =>
                        //assure spinning before each and every one, just in case the UI wasn't ready before
                        assureSpinning()
                            .then(() => syncSingleRelation(relation, false))
                            .then(({ postPersistence }) => {
                                //persistence is delayed. Keep the callback around until then
                                postPersistenceCallbacks.push(postPersistence);
                            })
                )
            );
            //now do the secondary relations
            await secondaryResult
                .then(() => relationsPurged)
                .finally(async () => {
                    await this.database.resumePhysicalWrites();
                    //persistence is done. Run all post-persistence callbacks
                    postPersistenceCallbacks.forEach(callback => callback());
                    const expendedMs = new Date() - startTime;
                    logging.debug(`[SYNCHRONIZATION] syncAllRelations completed in ${prettyPrintDuration(expendedMs)}`);
                });
        } catch (err) {
            await this.database.resumePhysicalWrites();
            logging.debug(`[SYNCHRONIZATION] syncAllRelations failed with error ${err.message}`);
            throw err;
        } finally {
            // Dispatch message to stop the sync icon from spinning
            publish(
                {
                    busy: false,
                    source: 'httpSync',
                    message: 'Sync Stop',
                    type: notificationTypes.SYNC_BUSY
                },
                { verb: 'pop', namespace: 'application', relation: 'notification' }
            );
        }
    }
    //effectively just a trimmed down `syncAllRelations`, for just 1 relation, and no obtrusive option
    async syncOneRelation({ namespaceTitle, relationTitle }, syncType) {
        const { publish } = this.database;

        const networkStatus = await network.getStatus();
        if (!networkStatus.isOnline) {
            logging.debug('[SYNCHRONIZATION] Skipping syncOneRelation since we are offline');
            return;
        }

        logging.debug('[SYNCHRONIZATION] syncOneRelation start');
        const startTime = new Date();

        const { getOfflineRelations, syncSingleRelation } = this._private;
        const offlineRelations = await getOfflineRelations();
        const offlineRelation = offlineRelations.find(
            o => o.namespace.title === namespaceTitle && o.relation.title === relationTitle
        );
        if (!offlineRelation) {
            logging.debug(`[SYNCHRONIZATION] ${namespaceTitle} ${relationTitle} not found`);
            return;
        }

        //dispatch message to make the sync icon spin
        publish(
            { busy: true, source: 'httpSync', message: 'Sync Start', type: notificationTypes.SYNC_BUSY },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );

        //the actual sync
        return syncSingleRelation(offlineRelation, false, syncType)
            .then(({ postPersistence, result }) => {
                //persistence happened right away, so we can directly run postPersistence()
                postPersistence();
                return { result };
            })
            .finally(async () => {
                // dispatch message to stop the sync icon from spinning
                await publish(
                    { busy: false, source: 'httpSync', message: 'Sync Stop', type: notificationTypes.SYNC_BUSY },
                    { verb: 'pop', namespace: 'application', relation: 'notification' }
                );
                const expendedMs = new Date() - startTime;
                logging.debug(`[SYNCHRONIZATION] syncOneRelation completed in ${prettyPrintDuration(expendedMs)}`);
            });
    }
}

const prettyPrintDuration = ms => {
    if (ms < 1000) {
        return `${Math.round(ms)}ms`;
    }
    return new Date(ms).toISOString().substring(11, 19);
};

const separatePrimaryFromSecondaryRelations = relations => {
    const primary = [];
    const secondary = [];
    relations.forEach(relation => {
        if (
            relation.relation.title.endsWith('-patch') ||
            relation.relation.title.endsWith('-transaction') ||
            ['locationHistory', 'transaction'].includes(relation.relation.title)
        ) {
            secondary.push(relation);
        } else {
            primary.push(relation);
        }
    });

    return [primary, secondary];
};

const _notifyUser = publish => async promise => {
    return promise
        .then(() => {
            publish(
                {
                    message: 'The application is ready for offline work.',
                    timeout: 1000,
                    addToList: false
                },
                { verb: 'pop', namespace: 'application', relation: 'notification' }
            );
        })
        .catch(error => {
            publish(
                {
                    message:
                        'Some or all of the data synchronization failed.  You may need to refresh the application when you have better connectivity.',
                    timeout: 5000,
                    addToList: true,
                    isError: true
                },
                { verb: 'pop', namespace: 'application', relation: 'notification' }
            );
            error.cause?.forEach(err =>
                logging.error('[SYNCHRONIZATION] ' + (err.stack || err.message || JSON.stringify(err)))
            );
            throw error;
        });
};

export default Synchronization;
