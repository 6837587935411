import lodash from 'lodash';
const { cloneDeep } = lodash;

/**
 * @param {string} value
 */
const normalize = value => value.toLowerCase().replace(/[^0-9a-z]/gi, '');

/**
 *
 * @param {Array<Array<string|number|bool|Date>>} data
 * @param {boolean} firstRowContainsHeader
 * @param {Array<object>} dataModel
 */
export default async function guessColumns(data, firstRowContainsHeader, dataModel) {
    return new Promise(resolve => {
        let columns = undefined;

        if (firstRowContainsHeader) {
            //simplify the dataModel
            let entityKeys = dataModel.map(model => {
                let title = normalize(model._meta.title);
                let propertyName = normalize(model._meta.propertyName);
                return {
                    title,
                    propertyName,
                    model
                };
            });

            //take the first row, we're told it is the column headers
            /** @type {T} columns */
            columns = data[0]
                //next normalize those column headers;
                .map(normalize)
                //then find the entityKey that matches
                .map(header => {
                    //the best candidate has a matching key-header
                    //next, where one includes the other
                    //finally, if they happened to match (part of) the propertyName
                    let matchingColumn =
                        entityKeys.find(k => k.title === header) ||
                        entityKeys.find(k => k.title.includes(header) || header.includes(k.title)) ||
                        entityKeys.find(k => k.propertyName.includes(header));

                    //remove the entityKey as an option for subsequent matches
                    //but only if it is not an array type
                    //because if it is, we need a clone, rather than a reference
                    if (matchingColumn && matchingColumn.model._meta) {
                        if (matchingColumn.model._meta.isArrayElement) {
                            matchingColumn = cloneDeep(matchingColumn);
                        } else {
                            entityKeys.splice(entityKeys.indexOf(matchingColumn), 1);
                        }
                    } //all we really want is the model

                    return matchingColumn ? matchingColumn.model : {};
                });
        } else {
            columns = [...dataModel.filter(x => x._meta.required), ...dataModel.filter(x => !x._meta.required)];

            //Now take the longest row in our preview, and adjust our columns to match that length:
            columns = data.reduce((a, b) => (a.length >= b.length ? a : b), []).map((x, i) => columns[i] || {});
        }

        setArrayIndexes(columns);
        resolve(cloneDeep(columns));
    });
}

function setArrayIndexes(columns) {
    columns.forEach((element, i) => {
        if (element._meta && element._meta.isArrayElement) {
            //set the index to the count of the same column that came before
            element._meta.arrayIndex = columns.filter(
                (c, index) => index < i && c._meta && c._meta.title === element._meta.title
            ).length;
        }
    });
}
