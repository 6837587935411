import { createElement as rc, useMemo } from 'react';
import { View, styled } from 'lib_ui-primitives';
import { AccessContextProvider } from './useAccessContext';
import AccessMenu from './AccessMenu';
import AccessDetails from './AccessDetails';
import useFormControl from '../../../hooks/useFormControl';

const TopToBottom = styled(View).attrs({ name: 'TopToBottom' })`
    flex-direction: column;
    flex-grow: 1;
`;
TopToBottom.displayName = 'TopToBottom';

const LeftToRight = styled(View).attrs({ name: 'LeftToRight' })`
    flex-direction: row;
    flex-grow: 1;
`;
LeftToRight.displayName = 'LeftToRight';

const AccessContainer = styled(TopToBottom).attrs({ name: 'AccessContainer' })`
    height: 100%;
    width: 100%;
`;
AccessContainer.displayName = 'AccessContainer';

export default function PageAccess(props) {
    const { hNode } = props;
    const toggleProps = useMemo(() => {
        return {
            ...props,
            hNode: {
                ...props.hNode,
                id: (props?.hNode?.id ?? props?.id) + 'defaultAccess',
                propertyPath: hNode.propertyName,
                propertyName: hNode.defaultAccessPropertyName || 'defaultAccess',
                defaultValue: true,
                hNodeType: 'Toggle'
            }
        };
    }, [hNode.defaultAccessPropertyName, hNode.propertyName, props]);

    // read the value from the default access toggle, so we can pass it to the context:
    const { value: allowAccessWhenUndefined } = useFormControl(toggleProps, true);

    // prettier-ignore
    return rc(AccessContainer, null,
        rc(AccessContextProvider, { allowAccessWhenUndefined },
            rc(TopToBottom, null,
                rc(LeftToRight, null,                    
                    rc(AccessMenu, props),
                    rc(AccessDetails, props)
                )
            )
        )
    );
}
