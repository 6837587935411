import { createElement as rc, Fragment } from 'react';
import { Fieldset, styled, View } from 'lib_ui-primitives';
import { GreedyOtherElements } from './styles';
import { getFormElements, getOtherElements } from './formComponentSelectors';

// Flex shrink is needed for scrolling of forms inside of dialogs when the
// form is taller than the dialog.
export const StyledSubForm = styled(View).attrs({ name: 'SubForm' })`
    flex-direction: column;
    background-color: transparent;
    flex-grow: ${props => (props.heightGreedy ? 1 : 0)};
    flex-basis: ${props => (props.heightGreedy ? '1px' : 'auto')};
    width: 100%;
    flex-shrink: 1;
`;

export default function SubForm(props) {
    const { children, heightGreedy, ...otherProps } = props || {};
    const formElements = getFormElements(children);
    const otherElements = getOtherElements(children);

    if (formElements.length > 0) {
        // prettier-ignore
        return rc(StyledSubForm, {heightGreedy},
            rc(Fieldset, { ...otherProps }, formElements),
            rc(GreedyOtherElements, null, otherElements)
        );
    }
    // If there are no field elements in this subform, skip creating a fieldset.
    return rc(Fragment, null, children);
}
