import lodash from 'lodash';
const { memoize } = lodash;
const { omit } = lodash;

// vastly reduce the size of the key used for memoization.  The size of this was
// previously causing a performance problem.
export default createWorkflow => {
    return memoize(createWorkflow, context => {
        const user = context.user;
        const newContext = omit(context, 'session', 'eventBoundaryContext');
        // Currently these are the only things in the (otherwise massive)
        // user context that are used for rule matching.
        newContext.user = {
            activeUseCaseId: user.activeUseCaseId,
            allFeatureFlags: user.allFeatureFlags
        };
        return JSON.stringify(newContext);
    });
};
