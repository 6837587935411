let operations = {
    EQUALS: {
        _id: '_eq',
        title: 'Equal To',
        symbol: '='
    },
    NOT_EQUALS: {
        _id: '_neq',
        title: 'Not Equal To',
        symbol: '≠'
    },
    CONTAINS: {
        _id: '_contains',
        title: 'Containing',
        symbol: '⊇'
    },
    STARTS_WITH: {
        _id: '_startsWith',
        title: 'Starting With',
        symbol: '^..'
    },
    GREATER_THAN: {
        _id: '_gt',
        title: 'Greater Than',
        symbol: '>'
    },
    GREATER_EQUAL: {
        _id: '_gte',
        title: 'Greater Or Equal To',
        symbol: '≥'
    },
    LESS_THAN: {
        _id: '_lt',
        title: 'Less Than',
        symbol: '<'
    },
    LESS_EQUAL: {
        _id: '_lte',
        title: 'Less Or Equal To',
        symbol: '≤'
    },
    INCLUDED_IN: {
        _id: '_in',
        title: 'Includes',
        symbol: '∈'
    },
    NOT_INCLUDED_IN: {
        _id: '_nin',
        title: 'Excludes',
        symbol: '∉'
    }
};
operations.default = operations.EQUALS;

operations.find = key => Object.values(operations).find(op => op._id === key) || operations.default;
Object.freeze(operations);
export default operations;
