import { createElement, useMemo } from 'react';
import Svg from './Svg';
import Path from './Path';
import styled from '../styled';

const StyledSvg = styled(Svg).attrs({ name: 'styled-svg' })`
    stroke: ${({ theme, color }) => color ?? theme.caretColor};
`;
StyledSvg.displayName = 'StyledSvg';

const rc = createElement;

const DIRECTION = Object.freeze({
    up: 'up',
    right: 'right',
    down: 'down',
    left: 'left'
});

const _directionAsDegrees = direction => {
    switch (direction) {
        case DIRECTION.up:
            return '180';
        case DIRECTION.right:
            return '-90';
        case DIRECTION.left:
            return '90';
        case DIRECTION.down:
        default:
            return '0';
    }
};
// react native wants 'deg' to be added onto the transform number.
const directionAsDegrees = direction => {
    // eslint-disable-next-line no-undef
    return _directionAsDegrees(direction) + (__SST_REACT_NATIVE__ ? 'deg' : '');
};

/**
 * At first this was created with a .native version, but so much of the component is identical it felt
 * like a maintenance problem.  The (obvious) downside is that there are a bunch of __SST_REACT_NATIVE__
 * checks in here.
 * @param {object} props
 * @returns Caret component
 */
const Caret = props => {
    const { isOpen, closed = DIRECTION.right, open = DIRECTION.down, direction, name, ...otherProps } = props;

    const transform = useMemo(() => {
        if (direction != null) {
            // eslint-disable-next-line no-undef
            if (__SST_REACT_NATIVE__) {
                return [{ rotate: directionAsDegrees(direction) }];
            } else {
                return `rotate(${directionAsDegrees(direction)})`;
            }
        } else {
            // eslint-disable-next-line no-undef
            if (__SST_REACT_NATIVE__) {
                return [{ rotate: isOpen ? directionAsDegrees(open) : directionAsDegrees(closed) }];
            } else {
                return `rotate(${isOpen ? directionAsDegrees(open) : directionAsDegrees(closed)})`;
            }
        }
    }, [isOpen, closed, direction, open]);

    return rc(
        StyledSvg,
        {
            name: name ?? 'caret',
            viewBox: '0 0 20 20',
            preserveAspectRatio: 'none',
            width: 16,
            height: 16,
            fill: 'transparent',
            strokeWidth: '4',
            transform,
            ...otherProps
        },
        rc(Path, { d: 'M1,6 L10,15 L19,6' })
    );
};

Caret.DIRECTION = DIRECTION;
Caret.displayName = 'Caret';
export default Caret;
