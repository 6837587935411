import BrowserPrint from '../../../utilities/BrowserPrint';
import { database } from 'lib_ui-services';

const _p = {
    // browserPrint is a zebra SDK: https://www.zebra.com/us/en/software/printer-software/browser-print.html
    browserPrint: BrowserPrint,
    getDatabase: database.get
};
export const _private = _p;

export default {
    verb: 'didGet',
    namespace: 'print',
    relation: 'printer',
    priority: 10,
    description: 'Load Zebra Printers from Zebra Browser Print',
    logic: loadPrinters
};

async function loadPrinters({ data, log, context }) {
    const { result: dbResult = [] } = data;
    const zebraPrinters = await loadZebraPrinterDefinitions(log);
    const newZebraPrinters = zebraPrinters.filter(z => !dbResult.some(i => i._id === z._id));

    if (newZebraPrinters.length) {
        //stuff them into loki without notifying the server
        //this will populate dropdowns.
        const db = _p.getDatabase();
        await db.upsert(
            {
                items: newZebraPrinters.map(z => ({
                    ...z,
                    meta: { modifiedBy: { _id: '000000000000000000000000' }, modifiedTime: new Date().toISOString() }
                }))
            },
            context
        );
    }

    const result = dbResult.filter(d => !d._zebraBrowserPrinter).concat(zebraPrinters);
    return { ...data, result };
}

function asId(uid) {
    return btoa(uid).replace(/=/g, '');
}

async function loadZebraPrinterDefinitions(log) {
    const defaultPrinter = await getDefaultZebraPrinter(log);
    const allZebraPrinters = await getAllZebraPrinters(log);
    if (!defaultPrinter) return allZebraPrinters;
    return allZebraPrinters.map(d => (d._id === defaultPrinter._id ? { ...d, default: true } : d));
}

async function getDefaultZebraPrinter(log) {
    return new Promise((resolve, reject) =>
        _p.browserPrint.getDefaultDevice(
            'printer',
            device => {
                if (!device) return resolve();
                return resolve({ ...device, _id: asId(device.uid), title: device.name });
            },
            error => {
                log.error(error);
                reject(error);
            }
        )
    );
}

async function getAllZebraPrinters(log) {
    return new Promise((resolve, reject) =>
        _p.browserPrint.getLocalDevices(
            devices => {
                const printers =
                    devices.printer?.map(p => ({
                        ...p,
                        _id: asId(p.uid),
                        title: p.name,
                        _zebraBrowserPrinter: true
                    })) || [];
                return resolve(printers);
            },
            error => {
                log.error(error);
                reject(error);
            }
        )
    );
}
