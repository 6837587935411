import lodash from 'lodash';
const { get } = lodash;
const { camelCase } = lodash;

import getFilter from '../filterFactory';
import clientDataRightsVersion from './filterHelpers.clientDataRights';
import getGlobalConfig from '../globalConfig';

function getDefaultFilters(hNode) {
    let filterHNodes = get(hNode, 'children', []).filter(
        // PatchDetail is a column, but if it is included, that means we need to do additional processing/filtering
        c => c.hNodeTypeGroup === 'filter' || c.hNodeType === 'PatchDetail'
    );
    return convertHNodeToFilters(filterHNodes);
}

function convertHNodeToFilters(hNodes) {
    let filters = {};
    hNodes.forEach(hNode => {
        let camelCased = camelCase(hNode.hNodeType);
        // Allow filters to be determined by metadata.
        /*eslint import/namespace: ['error', { allowComputed: true }]*/
        let filter = getFilter(camelCased);
        if (!filter) {
            throw new Error(`Unknown filter type: ${hNode.hNodeType}`);
        }
        filters[camelCased] = filter.fromHNode(hNode);
    });
    return filters;
}

/**
 *
 * @param {object} filter
 * @param {string} filter.propertyName Property Name or dot separated property path
 * @param {string} filter.op Mongo style operator. See below for supported values
 * @param {string|Array<string>} filter.value value(s) to match against
 * @returns
 */
function basicFilterToJavaScript(filter) {
    if (getGlobalConfig().featureFlags.includes('clientDataRights')) {
        return clientDataRightsVersion.basicFilterToJavaScript(filter);
    }
    const { propertyName, op, value } = filter;
    return record => {
        switch (op) {
            case '$neq':
                return get(record, propertyName) !== value;
            case '$contains':
                return get(record, propertyName, '').includes(value);
            case '$startsWith':
                return get(record, propertyName, '').startsWith(value);
            case '$gt':
                return get(record, propertyName) > value;
            case '$gte':
                return get(record, propertyName) >= value;
            case '$lte':
                return get(record, propertyName) < value;
            case '$lt':
                return get(record, propertyName) <= value;
            case '$in':
                return value.includes(get(record, propertyName));
            case '$nin':
                return !value.includes(get(record, propertyName));
            case '$eq':
            default:
                return get(record, propertyName) === value;
        }
    };
}

function criteriaToJavaScript(criteria = {}) {
    if (getGlobalConfig().featureFlags.includes('clientDataRights')) {
        return clientDataRightsVersion.criteriaToJavaScript(criteria);
    }
    if (Array.isArray(criteria)) {
        return record => criteria.every(crit => criteriaToJavaScript(crit)(record));
    }
    const [propertyName] = Object.keys(criteria);
    const filters = Object.entries(criteria[propertyName])
        .map(([op, value]) => ({ propertyName, op: op.replace('_', '$'), value }))
        .filter(x => x);
    return record => {
        return filters.every(part => basicFilterToJavaScript(part)(record));
    };
}

export default { getDefaultFilters, basicFilterToJavaScript, criteriaToJavaScript };
