import log from '@sstdev/lib_logging';
import { authentication, globalConfig, http, network } from 'lib_ui-services';

const loginWithNewPassword = authentication.authenticate;

export default {
    verb: 'doingCreate',
    namespace: 'security',
    relation: 'profile',
    type: 'confirm',
    prerequisites: [],
    description: 'Reset a user password',
    //this is the actual logic:
    logic: confirm
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function confirm({ data }) {
    const { tokenString, userName, password } = data.newRecord;
    const networkStatus = await network.getStatus();
    if (networkStatus.isOnline) {
        const tempToken = await loginWithResetToken(tokenString, userName);
        await UpdateServerWithNewPassword(tempToken, password);
        return await loginWithNewPassword({ userName, password });
    } else {
        throw new Error('Unable to set a new password while offline. Please go online and try again.');
    }
}

async function loginWithResetToken(tokenString, userName) {
    if (!userName) {
        throw new Error('Email is required');
    }
    let url = `/api/security/validateToken?token=${tokenString}`;
    const res = await http.get(url, false);
    if (res.userName.toLowerCase() !== userName.toLowerCase()) {
        throw new Error('Invalid User Email');
    }
    if (res.tokenKey) {
        // Set new token
        return res.tokenKey;
    } else {
        //If they tried to login with a reset token and there's still an old JWT in store,
        //then nuke it and make them request another.
        log.error('Unknown User or Invalid/Expired token');
        return null;
    }
}

async function UpdateServerWithNewPassword(tempToken, password) {
    const { protocol, hostname: host } = globalConfig();
    const protocolAndHost = `${protocol}//${host}`;
    let url = '/api/security/setPassword';
    return http.post(url, { password, protocolAndHost }, { Authorization: tempToken });
}
