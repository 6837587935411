import defaultDiscard from '@redux-offline/redux-offline/lib/defaults/discard';
import logging from '@sstdev/lib_logging';
import NotLoggedInError from '../errors/NotLoggedInError';

export default function discard(error, action, retries) {
    if (error instanceof NotLoggedInError) return false;
    if (action.stopDueToUnexpectedError) return true;
    // "Failed to fetch" indicates an error inside fetch itself
    // This can happen if the device is offline but maybe got into
    // that status AFTER we last checked and BEFORE fetch was called
    // -- this actually happened in our test cases believe it or not.
    // Unfortunately, this seems to be sort of a catch all error with fetch
    // and could mean other things too -- thus the retries check.
    if (error.message === 'Failed to fetch' && retries < 10) return false;
    if (error.status === 429 && retries < 10) return false;
    if (error.status === 429 && retries >= 10) {
        logging.error(`Rate Limiting unsuccessful. ${retries} progressive back offs were insufficient.`);
        return true;
    }
    if (error.status === 401 && retries < 3) {
        //just in case, if this was due to unfortunate timing with logging out,
        //do allow to requeue and therefore retry when they get back online
        return false;
    }

    return defaultDiscard.default(error, action, retries);
}
