import groupByFilter from './groupBy';
import groupByBooleanFilter from './groupByBoolean';

export default {
    getFilter,
    getUriComponent,
    fromHNode,
    getAggregateReducer
};

function fromHNode({ aggregateType }) {
    return getFilter(aggregateType);
}

function getFilter(aggregateType) {
    return {
        aggregateType
    };
}

function getUriComponent() {
    return '';
}

function getAggregateReducer(filters, itemCount) {
    if (!filters?.aggregateType?.aggregateType) {
        return x => x;
    }
    const {
        aggregateType: { aggregateType },
        groupBy,
        groupByBoolean
    } = filters;
    //special case, total count (no grouping)
    if (aggregateType === 'count' && !groupBy && !groupByBoolean) {
        return array => {
            //itemcount is faster, but just in case, use a fallback
            return [{ _id: { title: 'Total' }, count: itemCount || array.length }];
        };
    }

    if (groupBy) {
        return groupByFilter.getAggregateReducer(filters, itemCount, accumulators[aggregateType]);
    }
    if (groupByBoolean) {
        return groupByBooleanFilter.getAggregateReducer(filters, itemCount, accumulators[aggregateType]);
    }

    throw new Error(`Unsupported un-grouped aggregate type: '${aggregateType}'`);
}

const count = (resultSet, value) => {
    //memory optimized version of the default implemented in the grouping filters: it does not include the actual records
    resultSet[value] = resultSet[value] || { _id: { title: value }, count: 0 };
    resultSet[value].count++;
    return resultSet;
};

export const accumulators = {
    count
    // if we ever need something like an average/min/max, etc, we'll need to  accumulate the values in the accumulator
    // and add code to support a post-processing step in the getAggregateReducer calls,
    // to pass in a function that computes the average/min/max of those values.
};
