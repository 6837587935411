import { useCallback, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
const { get } = lodash;
const { omit } = lodash;
const { isEqual } = lodash;
import useEventSink from './useEventSink';
import { MultiSelectContext } from '../components/contextProviders/MultiSelectBoundary';

const EMPTY_OBJECT = {};

// The basic of the inversion logic is:
// If it is not set, NOTHING is selected by default, and clicking on an item selects it
// If inversion IS set, EVERYTHING is selected by default, and clicking on an item deselects it.

const useMultiSelect = props => {
    const { namespace, relation, singleRowSelect } = props || {};

    const [, publish] = useEventSink();

    const selections = useContext(MultiSelectContext);
    const selection = get(selections, [namespace, relation], EMPTY_OBJECT);

    const [invertSelection, setSelectionInversion] = useState(null);

    //if the selection changes, update the "invert selection" toggle in the header
    useEffect(() => {
        //if there is ANYTHING selected other than __invertSelection, put it in the tristate undefined
        if (Object.keys(selection).some(key => key !== '__invertSelection')) {
            setSelectionInversion(null);
        } else {
            //otherwise, if __invertSelection is set, set it to true, otherwise set it to false
            setSelectionInversion(!!selection.__invertSelection);
        }
    }, [selection]);

    // toggle the inversion
    const onChangeEverything = useCallback(
        value => {
            const newSelection = value ? { __invertSelection: true } : EMPTY_OBJECT;
            if (isEqual(newSelection, selection)) return;
            publish({ id: '__invertSelection', value }, { verb: 'select', namespace, relation });
        },
        [selection, publish, namespace, relation]
    );

    //check if invertSelection is true, but NOT this record marked for exclusion (true/false)
    //or if invertSelection is NOT true, AND this record is marked for inclusion (false/true)
    const isSelected = useCallback(
        id => {
            return XOR(selection.__invertSelection, selection[id]);
        },
        [selection]
    );

    //toggle a single record for inclusion
    const onIndividualChange = useCallback(
        (id, value) => {
            if (singleRowSelect) {
                return publish({ id, value, type: 'singleSelect' }, { verb: 'select', namespace, relation });
            }
            let seed = {};
            //record if invertSelection is true, but checkbox was unchecked (true/false)
            //or if invertSelection is NOT true, AND checkbox is checked (false/true)
            //this is done for easy select-all (, except ...) functionality
            if (XOR(selection.__invertSelection, value)) {
                seed[id] = true;
            }
            const newSelection = { ...seed, ...omit(selection, [id]) };
            if (isEqual(newSelection, selection)) return;
            publish({ id, value }, { verb: 'select', namespace, relation });
        },
        [selection, singleRowSelect, publish, namespace, relation]
    );

    return {
        onChangeEverything,
        invertSelection,
        onIndividualChange,
        isSelected,
        selection
    };
};

useMultiSelect.propTypes = {
    namespace: PropTypes.string.isRequired,
    relation: PropTypes.string.isRequired
};
export default useMultiSelect;

function XOR(left, right) {
    return Boolean(left) !== Boolean(right);
}
