//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _inaccessibleTag from './inaccessibleTag';
import _inactiveRecord from './inactiveRecord';
import _multipleMatch from './multipleMatch';
import _recordOnFile from './recordOnFile';
import _unknownTag from './unknownTag';
export const inaccessibleTag = _inaccessibleTag;
export const inactiveRecord = _inactiveRecord;
export const multipleMatch = _multipleMatch;
export const recordOnFile = _recordOnFile;
export const unknownTag = _unknownTag;
export default {inaccessibleTag, inactiveRecord, multipleMatch, recordOnFile, unknownTag};

