import notHandledHere from './notHandledHere';

export default {
    verb: 'doingEdit',
    description: 'Edit an existing record.',
    excludedNamespaceRelations: notHandledHere,
    //this is the actual logic:
    logic: edit
};

async function edit({ data, context, dispatch }) {
    const { namespace, relation, type } = context;
    let record;
    let _id = data._id;
    // Get the record from the database if necessary.
    if (data.record != null) {
        // If the record to edit is passed in.
        record = { ...data.record };
    } else if (data.activeRecord != null) {
        record = { ...data.activeRecord };
    } else {
        const payload = await dispatch({ _id }, { verb: 'get', namespace, relation, type: 'get' }, true);
        if ((payload?.result?.length ?? 0) === 0) {
            throw new Error(`No record exists with id ${_id}`);
        }
        record = payload.result[0];
    }

    let result = {
        activationVerb: 'edit',
        record,
        isNew: false,
        namespace,
        relation,
        type
    };
    if (data.oldRecord) {
        result.oldRecord = data.oldRecord;
    }
    return result;
}
