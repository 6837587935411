import getGlobalObject from './getGlobalObject';
import localStorage from '../localStorage';
let hostnameOverride;

// Get previously overridden hostname the first time this module is called.
// It would be a nightmare to change all globalConfig() calls to be async, so
// I didn't do that.  This loads in time for the app to have the hostname for
// all relevant operations.
export const asyncHostname = localStorage.getKey('overrideHostname', undefined, undefined, false).then(hostName => {
    if (hostName) {
        hostnameOverride = hostName;
    }
    return hostName;
});

export const overrideHostname = hostname => {
    hostnameOverride = hostname;
    localStorage.setKey('overrideHostname', hostname, undefined, false);
};

export default () => {
    if (hostnameOverride) return hostnameOverride;
    return getGlobalObject().location.hostname;
};
