let _router;
export function init(router) {
    _router = router;
}

function getRouter() {
    if (_router == null) {
        throw new Error('The getRouter service has not be initialized yet and cannot be used.');
    }
    return _router;
}
getRouter.init = init;
export default getRouter;
