import { createElement as rc, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { View, Switch, Text, styled, fromTheme } from 'lib_ui-primitives';
import FormField from './FormField';
import { useFormControl, useToggle } from '../../hooks';

// Always false so that label floats permanently (instead of overlapping the control).
const fieldEmpty = false;

const SwitchWithMargin = styled(Switch)`
    margin-left: ${fromTheme('textPadding')};
    margin-right: ${fromTheme('textPadding')};
    flex-shrink: 0;
`;

const SwitchContainer = styled(View)`
    padding-left: ${fromTheme('textPadding')};
    padding-top: ${fromTheme('textPadding')};
    margin-top: ${fromTheme('textMargin')};
    align-items: center;
`;

const ToggleOptions = styled(Text).attrs({ name: 'toggle-options' })`
    color: ${({ selected, theme }) => {
        return selected ? theme.defaultFontColor : theme.disabledFontColor;
    }};
`;
ToggleOptions.displayName = 'ToggleOptions';

const _p = {
    useFormControl
};
export const _private = _p;

/**
 * @typedef {Object} Props
 * @property {Object} hNode
 */
/** @type {import('react').FC<Props>} */
const Toggle = props => {
    const {
        hNode: { id, propertyName, toggleValues, tooltip, neutralOption }
    } = props;
    const [active, setActive] = useState(false);

    // In order to set the value of a toggle to the 'neutral' state, we need to pass in an override option to useFormControl
    // If there is a neutral option, use it, otherwise, fallback to the default value
    const { title, value, setValue, disabled, errors } = _p.useFormControl(props, neutralOption);

    const [toggleState, toggle] = useToggle(value, neutralOption);

    // Update the form control value when the toggle state changes
    useEffect(() => {
        // On first render, if a neutral option is set, the value from useFormControl is the neutral option, and the toggleState is undefined
        // so this effect runs and marks the state as dirty. To prevent this, we check if the toggleState is undefined and
        // if the value is the neutral option, if so, this is a no-op
        if (toggleState === undefined && value === neutralOption) return;
        if (value !== toggleState) {
            setValue(toggleState);
        }
        // Only run when the toggle state changes to prevent the toggle state from being updated twice
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleState]);

    const onFocus = () => setActive(true);
    const onBlur = () => setActive(false);

    // prettier-ignore
    return rc(FormField, { id, title, tooltip, errors, active, fieldEmpty},
        rc(SwitchContainer, null,
            // Explcitly check for true or false to prevent the neutral option from being selected
            rc(ToggleOptions, { selected: value === false }, toggleValues[0]),
            rc(SwitchWithMargin, { id, value: value, onClick: toggle, disabled, name: propertyName, onFocus, onBlur }),
            rc(ToggleOptions, { selected: value === true }, toggleValues[1])
        )
    );
};

Toggle.propTypes = {
    hNode: PropTypes.shape({
        id: PropTypes.string.isRequired,
        propertyName: PropTypes.string.isRequired,
        toggleValues: PropTypes.arrayOf(PropTypes.string).isRequired,
        tooltip: PropTypes.string,
        neutralOption: PropTypes.string
    }).isRequired
};

export default Toggle;
