import {
    Button,
    List,
    styled,
    fromTheme,
    Pressable,
    View,
    Text,
    webOnlyStyles,
    nativeOnlyStyles,
    h3,
    h1,
    Card
} from 'lib_ui-primitives';

let _FullScreenBody = nativeOnlyStyles(Card.Body)`
    flex-grow: 1;
`;
_FullScreenBody = webOnlyStyles(_FullScreenBody)`
    width:${({ theme }) => `${theme.mobile ? theme.width : theme.width * 0.8}px`};
`;
export const FullScreenBody = _FullScreenBody;
export const ClearAll = styled(Button)`
    align-self: flex-end;
`;

export const TopButtonBar = styled(Card.Header)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const NoNotifications = styled(h3)`
    margin: ${fromTheme('viewMargin')};
`;

export const Title = styled(h1)`
    font-size: 24px;
    font-family: ${fromTheme('fontAlternate')};
`;

export const InnerRow = styled(View).attrs({ name: 'inner-row' })`
    display: flex;
    flex-direction: row;
    background-color: ${props => (props.altRow ? 'transparent' : props.theme.backgroundColor)};
    height: ${fromTheme('listLineHeight')};
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const Row = styled(Pressable).attrs({ name: 'row' })`
    text-align: center;
    height: ${fromTheme('listLineHeight')};
    line-height: ${fromTheme('listLineHeight')};
    border-color: transparent;
    border-style: solid;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 0px;
    border-bottom-color: ${fromTheme('gridLineColor')};
    width: 100%;
`;

// Truncating notification text reference: https://css-tricks.com/flexbox-truncated-text/
let _MessageText = styled(Text)`
    min-width: 0;
    padding: ${fromTheme('textPadding')};
    overflow: hidden;
`;
export const MessageText = webOnlyStyles(_MessageText)`
    white-space: nowrap;
    text-overflow: ellipsis;
`;

let _TextContainer = styled(Pressable)`
    flex-grow: 0;
    flex-shrink: 1;
    overflow: hidden;
`;
_TextContainer = nativeOnlyStyles(_TextContainer)`
    width:${({ theme }) => `${theme.width - 60}px`};
`;
_TextContainer.displayName = 'NotificationTextContainer';

export const TextContainer = webOnlyStyles(_TextContainer)`
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
`;

export const RemoveButton = styled(Button)`
    flex-grow: 1;
`;

export const StyledList = styled(List).attrs({ name: 'notifications-list' })`
    flex-grow: 1;
    flex-direction: column;
    margin-top: ${fromTheme('viewMargin')};
    margin-bottom: ${fromTheme('viewMargin')};
    overflow: hidden;
`;
