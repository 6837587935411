import { createElement as rc, forwardRef } from 'react';
import ShortText from './ShortText';
import PropTypes from 'prop-types';
import { nativeOnlyProperties, webOnlyProperties } from 'lib_ui-services';
const Password = forwardRef(function Password(props, ref) {
    // prettier-ignore
    return rc(ShortText, {
        ...props,
        ref,
        ...webOnlyProperties({
            autoComplete: props.allowPasswordFill ? 'on' : 'new-password'
        }),
        ...nativeOnlyProperties({
            autoComplete: props.allowPasswordFill ? undefined : 'password'
        }),
        type: 'password'
    });
});
Password.propTypes = {
    allowPasswordFill: PropTypes.bool
};
Password.displayName = 'Password';
export default Password;
