//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _willCreate_namespace_relation from './willCreate_namespace_relation';
import _willRemove_namespace_relation from './willRemove_namespace_relation';
import _willUpdate_namespace_relation from './willUpdate_namespace_relation';
import _willUpsert_namespace_relation from './willUpsert_namespace_relation';
export const willCreate_namespace_relation = _willCreate_namespace_relation;
export const willRemove_namespace_relation = _willRemove_namespace_relation;
export const willUpdate_namespace_relation = _willUpdate_namespace_relation;
export const willUpsert_namespace_relation = _willUpsert_namespace_relation;
export default {willCreate_namespace_relation, willRemove_namespace_relation, willUpdate_namespace_relation, willUpsert_namespace_relation};

