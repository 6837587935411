import lodash from 'lodash';
const { set } = lodash;
const { unset } = lodash;
export default function applyUpdateOperators(doc, operators) {
    for (let [op, change] of Object.entries(operators)) {
        switch (op) {
            case '$set':
                for (let [key, value] of Object.entries(change)) {
                    set(doc, key, value);
                }
                break;
            case '$unset':
                for (let [key] of Object.entries(change)) {
                    unset(doc, key);
                }
                break;
            default:
                throw new Error(`${op} is not a supported update operator.`);
        }
    }
}
