// Some relations can have multiple references to the same foreign key at different levels.
// We need to specify the one path that is relevant to the dataRights filter,
// as well as some other variations of how restrictions should be applied
export const EXCEPTIONS = {
    item: {
        /**
         * items have multiple paths where these properties exist.
         * so we need to explicitly specify the 1 path we want
         */
        item: {
            'location:company': { paths: ['location:company._id'] },
            'location:building': { paths: ['location:building._id'] },
            'location:location': { paths: ['location:location._id'] }
        },
        // Transactions and item-patch work different:
        // If you have access to the item, you have access to the transaction/patch record
        // implemented in ticket #7159
        transaction: {},
        'item-patch': {}
    },
    inventory: {
        /**
         * we need to explicitly always allow inventories without any criteria on these properties
         * plus, inventories have either `_in` or `_nin`, so effectively 2 different paths
         * having access to records without a value makes no difference here
         */
        inventory: {
            'location:company': { alwaysAllowUndefinedCriteria: true },
            'location:building': { alwaysAllowUndefinedCriteria: true },
            'location:location': { alwaysAllowUndefinedCriteria: true }
        }
    },
    identity: {
        /**
         * there is only 1 path, but we need to explicitly DISallow any records that have any locations that WE _don't_ have access to
         */
        role: {
            'location:company': { paths: ['dataRights.location:company._id'], requireMatchAll: true },
            'location:building': { paths: ['dataRights.location:building._id'], requireMatchAll: true },
            'location:location': { paths: ['dataRights.location:location._id'], requireMatchAll: true }
        },
        user: {
            // Users get a copy of their role's dataRights by the server. Filter just like roles.
            'location:company': { paths: ['dataRights.location:company._id'], requireMatchAll: true },
            'location:building': { paths: ['dataRights.location:building._id'], requireMatchAll: true },
            'location:location': { paths: ['dataRights.location:location._id'], requireMatchAll: true }
        }
    }
};
