import { hooks } from 'lib_ui-primitives';

const { useRouter } = hooks;

export default function useIsInActiveRoute(props) {
    const { location } = useRouter();
    //if it is an exact match: it's a match
    if (props.currentRoute === location.pathname + location.search) return true;
    //if it is not an exact match, but it is contained in the start of the path (plus some extension)
    //it is in the active route too!
    return (location.pathname + location.search).startsWith(props.currentRoute + '/');
}
