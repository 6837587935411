import {
    useLayoutEffect,
    useCallback,
    useRef,
    createContext,
    createElement as rc,
    memo,
    useContext,
    useEffect,
    useMemo
} from 'react';
import PropTypes from 'prop-types';
import { EventBoundaryContext } from './EventBoundary';
import { eventSink } from 'lib_ui-services';
import useEventSink from '../../hooks/useEventSink';
import lodash from 'lodash';
const { set } = lodash;
const { get } = lodash;
import logging from '@sstdev/lib_logging';
import { hooks } from 'lib_ui-primitives';
const { useRouter } = hooks;

export const FilterInterdependencyBoundaryContext = createContext();
export const useFilterInterdependencyBoundary = () => useContext(FilterInterdependencyBoundaryContext);

const _p = {
    useRouter
};
export const _private = _p;
function FilterInterdependencyBoundaryProvider(props) {
    const { name, children, restoreSelectionsOnRemount = false } = props || {};
    const listeningFilters = useRef({});
    const parentSink = useEventSink();
    const { getRouteState, setRouteState } = _p.useRouter();
    const routeStorageKey = `listenersFor${name}`;
    const setSelection = useCallback(
        (value, namespace, relation) => {
            // lazily add listeners to the route specific storage
            const listeners = getRouteState(routeStorageKey, {});
            logging.debug(
                `[FilterInterdependencyBoundary] ${name}- setting for ${namespace}-${relation}: ${JSON.stringify(
                    value
                )}`
            );
            set(listeners, [namespace, relation], value);
        },
        [routeStorageKey, getRouteState, name]
    );

    const getSelection = useCallback(
        (namespace, relation) => {
            const listeners = getRouteState(routeStorageKey, {});
            const value = get(listeners, [namespace, relation]);
            logging.debug(
                `[FilterInterdependencyBoundary] ${name}- getting for ${namespace}-${relation}: ${JSON.stringify(
                    value
                )}`
            );
            return value;
        },
        [routeStorageKey, getRouteState, name]
    );

    const getAllSelections = useCallback(() => {
        return getRouteState(routeStorageKey, {});
    }, [getRouteState, routeStorageKey]);

    // When returning to this boundary, recreate any view filters for selections that were
    // retained in route state.
    useEffect(() => {
        addAnyFiltersNeeded();
        // Only do this when mounting.
        // eslint-disable-next-line
    }, []);

    const filterInterdependencyBoundaryContext = useMemo(
        () => ({
            name,
            getSelection,
            addFilterMetadata: (view, filters) => {
                let viewFiltersChanged = false;
                // Currently, foreignRelationSelectionOnForm is the only filter type that
                // requires a boundary to separate controls that are dependent on each other.
                const foreignRelationSelectionOnForm = filters.foreignRelationSelectionOnForm;
                if (foreignRelationSelectionOnForm != null) {
                    const { namespace, relation, id: filterId } = foreignRelationSelectionOnForm;
                    logging.debug(
                        `[FilterInterdependencyBoundary] ${name}- filter added for ${namespace}/${relation} on view for ${view.namespace}/${view.relation}(view name: ${view.name})`
                    );
                    set(listeningFilters.current, [namespace, relation], { view, filterId });
                    viewFiltersChanged = addAnyFiltersNeeded();
                }
                return viewFiltersChanged;
            }
        }),
        // The context instance should never change.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // Create an eventboundary context that is only interested in selection events of children
    // inside this boundary component.
    const eventBoundaryContext = useMemo(
        () => ({
            name,
            subscriptions: {},
            // eslint-disable-next-line no-undef
            logEvents: !__PRODUCTION__ && !__UNIT_TESTING__,
            parentSink,
            subscriptionFilter: [{ verb: 'select' }, { verb: 'clearSelection' }]
        }),
        [name, parentSink]
    );

    // Listen for selection events - these can trigger changes to cascading/hierarchical filters
    // like foreignRelationSelectionOnForm (e.g. Company > Building > Room dropdown filters)
    // IMPORTANT:
    // This is in a useLayoutEffect because it must happen BEFORE the child controls are rendered.
    // Otherwise, the select events used to create the initial filters will happen too early
    // and the events will bubble up to the parent FilternInterdependencyBoundary instead.
    useLayoutEffect(() => {
        // Subscribe to select events only (see above)
        const [subscribe] = eventSink(eventBoundaryContext);
        logging.debug(`[FilterInterdependencyBoundary] ${name} - creating selection subscription`);
        return subscribe({ verb: 'select', status: 'success' }, (payload, context) => {
            const { namespace, relation } = context;
            // Store selections that have occurred within this filter boundary by namespace/relation
            logging.debug(
                `[FilterInterdependencyBoundary] ${name} - selection for ${namespace}/${relation}: ${JSON.stringify(
                    payload.value
                )}`
            );
            setSelection(payload.value, namespace, relation);
            addAnyFiltersNeeded();
            clearSelectionsOnChildDependencies(namespace, relation);
        });

        // This should not change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventBoundaryContext]);

    function clearSelectionsOnChildDependencies(selectionNamespace, selectionRelation) {
        const [, publish] = eventSink(eventBoundaryContext);
        // Find any listening filters for this selection's namespace and relation
        const filter = listeningFilters.current[selectionNamespace]?.[selectionRelation];
        if (filter) {
            const { view } = filter;
            // If there are child dependencies, clear their selection if the parent
            // selection is changed.
            const dependentSelection = getSelection(view.namespace, view.relation);
            // Check to see if they are already cleared or you'll get an infinite loop
            if (!dependentSelection?.isDefaultRecord) {
                publish(
                    {},
                    { verb: 'clearSelection', namespace: view.namespace, relation: view.relation },
                    { preventBubble: true }
                );
            }
        }
    }

    /**
     * Based on the selections that have occurred within this filter boundary, look at any views listening
     * for the selection's namespace and relation, and adjust the corresponding filters for those views.
     * @returns boolean - whether any filters changed
     */
    function addAnyFiltersNeeded() {
        let filtersChanged = false;
        const listeners = getAllSelections();
        // Filters based off of selections (like cascading filters (i.e. foreignRelationSelectionOnForm) for cascading dropdowns)
        Object.keys(listeners).forEach(namespace => {
            Object.keys(listeners[namespace]).forEach(relation => {
                const selection = listeners[namespace][relation];
                // Find any listening filters for this selection's namespace and relation
                const filter = listeningFilters.current[namespace]?.[relation];
                if (filter) {
                    const { view, filterId } = filter;
                    if (selection != null && !selection.isDefaultRecord && !selectionIsEmptyArray(selection)) {
                        const fieldName = `${namespace}:${relation}._id`;
                        if (Array.isArray(selection)) {
                            const ids = selection.map(select => select._id);
                            filtersChanged =
                                view.viewCriteria?.addFind({ [fieldName]: { $in: ids } }, filterId) || filtersChanged;
                        } else {
                            // Always add the filter -- viewCriteria will not actually change if the filter already exists
                            filtersChanged =
                                view.viewCriteria?.addFind({ [fieldName]: selection._id }, filterId) || filtersChanged;
                        }
                    } else {
                        // If a selection is removed, (e.g. set to unassigned or similar)
                        // remove the corresponding filter.
                        filtersChanged = view.viewCriteria?.removeFilters(filterId) || filtersChanged;
                    }
                }
            });
        });
        return filtersChanged;
    }

    // Clear filters when unmounting
    useEffect(() => {
        const namespaces = listeningFilters.current;
        return () => {
            // Clear selections unless we need to restore them when remounting
            if (!restoreSelectionsOnRemount) {
                setRouteState(routeStorageKey, {});
            }
            Object.values(namespaces).forEach(relations => {
                Object.values(relations).forEach(filter => {
                    const { view, filterId } = filter;
                    view.viewCriteria?.removeFilters(filterId);
                });
            });
        };
        // Only do this when unmounting.
        // eslint-disable-next-line
    }, []);

    // prettier-ignore
    return rc(FilterInterdependencyBoundaryContext.Provider, { value: filterInterdependencyBoundaryContext },
        rc(EventBoundaryContext.Provider, { value: eventBoundaryContext },
            children
        )
    );
}

FilterInterdependencyBoundaryProvider.propTypes = {
    name: PropTypes.string.isRequired
};

export default memo(FilterInterdependencyBoundaryProvider);

function selectionIsEmptyArray(selection) {
    return Array.isArray(selection) && selection.length === 0;
}
