import { createElement as rc } from 'react';
import useAuth0 from '../../hooks/useAuth0';
import { View, Text, hooks } from 'lib_ui-primitives';

const { useRouter } = hooks;

export default function OAuth() {
    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
    const { groupNumber, history, protocol, host, getRoot } = useRouter();

    let groupPath = '';
    if (groupNumber != undefined && groupNumber !== '') {
        groupPath = `/g/${groupNumber}`;
    }

    if (!isAuthenticated && !isLoading) {
        history.replace(`${groupPath}/login`);
        // The group path here is based off of the request URL.
        // It is possible that the group is missing or could be a different group than that requested for the tenant
        // Regardless, we need to tell Auth0 to redirect to a grouped path to avoid a redirect loop.
        // If the group is incorrect, the Backbone.js component will redirect to the correct group.
        loginWithRedirect({
            scope: 'openid profile email offline_access',
            redirectUri: `${protocol}//${host}${getRoot()}`
        });
    } else {
        if (!isLoading) {
            //it should be rare/non-existent that a user would end up here,
            //as it should be intercepted in Auth0ProviderWithHistory.js which is used in components/App.js
            //But, just in case. better save than sorry; The user is Authenticated. Redirect to root.
            history.push(`${groupPath}/`);
        }
    }

    return rc(View, null, rc(Text, null, 'Redirecting to Identity Provider'));
}
