import { createContext, useState, useEffect, createElement as rc, useMemo } from 'react';
import PropTypes from 'prop-types';
import { eventSink } from 'lib_ui-services';
import useEventSink from '../../hooks/useEventSink';
import lodash from 'lodash';
const { set, get, omit, isEqual, cloneDeep } = lodash;
import { EventBoundaryContext } from './EventBoundary';

export const MultiSelectContext = createContext();

const EMPTY_OBJECT = {};

// The basic of the inversion logic is:
// If it is not set, NOTHING is selected by default, and clicking on an item selects it
// If inversion IS set, EVERYTHING is selected by default, and clicking on an item deselects it.

function MultiSelectBoundary(props = {}) {
    const { currentRoute = '' } = props;
    const [selections, setSelection] = useState(props.defaultSelections ?? EMPTY_OBJECT);
    const parentSink = useEventSink();

    const name = useMemo(() => `MultiSelectBoundary-${currentRoute}`, [currentRoute]);
    // Create an eventboundary context that is only interested in selection events of children
    // inside this boundary component.
    const eventBoundaryContext = useMemo(
        () => ({
            name,
            subscriptions: {},
            // eslint-disable-next-line no-undef
            logEvents: !__PRODUCTION__ && !__UNIT_TESTING__,
            parentSink,
            subscriptionFilter: [{ verb: 'select' }, { verb: 'selection' }, { verb: 'bulk' }]
        }),
        [name, parentSink]
    );
    const [subscribe, publish] = eventSink(eventBoundaryContext);

    useEffect(() => {
        const unsubscribe = subscribe({ verb: 'select' }, (payload, context) => {
            // id will be `__invertSelection` if the user clicks on the table's checkbox header
            const { id, value, type } = payload;
            const { namespace, relation } = context;
            setSelection(currentSelection => {
                if (id === '__invertSelection') {
                    set(currentSelection, [namespace, relation], value ? { __invertSelection: true } : {});
                } else {
                    let nsRelSelection = get(currentSelection, [namespace, relation], EMPTY_OBJECT);
                    let newSelection;
                    if (type === 'singleSelect') {
                        newSelection = { [id]: value };
                    } else {
                        let invertSelection = nsRelSelection.__invertSelection;
                        let seed = {};
                        //record if invertSelection is true, but checkbox was unchecked (true/false)
                        //or if invertSelection is NOT true, AND checkbox is checked (false/true)
                        //this is done for easy select-all (, except ...) functionality
                        if (XOR(invertSelection, value)) {
                            seed[id] = true;
                        }
                        newSelection = { ...seed, ...omit(nsRelSelection, [id]) };
                    }

                    //no change: prevent rerenders
                    if (isEqual(newSelection, nsRelSelection)) return currentSelection;
                    set(currentSelection, [namespace, relation], newSelection);

                    //Special: these are mutually exclusive
                    if (namespace === 'application') {
                        if (relation === 'savedFilter') {
                            set(currentSelection, [namespace, 'sharedFilter'], {});
                        } else if (relation === 'sharedFilter') {
                            set(currentSelection, [namespace, 'savedFilter'], {});
                        }
                    }
                }
                return cloneDeep(currentSelection);
            });
        });
        return unsubscribe;
    }, [subscribe]);

    //translate "selection" into "bulk"+selection-in-payload, and back
    useEffect(() => {
        const unsubscribes = [
            subscribe({ verb: 'selection' }, (payload, context) => {
                const { namespace, relation } = context;
                const selection = selections?.[namespace]?.[relation] || EMPTY_OBJECT;
                publish({ ...payload, selection }, { ...context, verb: 'bulk' });
            }),
            subscribe({ verb: 'bulk', status: 'success' }, (payload, context) => {
                publish(payload, { ...context, verb: 'selection' });
            }),
            subscribe({ verb: 'bulk', status: 'failure' }, (payload, context) => {
                publish(payload, { ...context, verb: 'selection' });
            })
        ];
        return () => unsubscribes.map(u => u());
    }, [publish, selections, subscribe]);

    //render the provider.
    // prettier-ignore
    return rc(MultiSelectContext.Provider, { value: selections },
        rc(EventBoundaryContext.Provider, { value: eventBoundaryContext },
            props?.children
        )
    );
}

MultiSelectBoundary.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
};
export default MultiSelectBoundary;

function XOR(left, right) {
    return Boolean(left) !== Boolean(right);
}
