export default {
    verb: 'didNavigate',
    namespace: 'application',
    relation: 'route',
    description: 'User clicked a logout link - so logout',
    routePath: ['/g/0/Logout', '/g/1/Logout', '/g/2/Logout'],
    prerequisites: [{ context: { verb: 'remove', namespace: 'security', relation: 'profile' } }],
    logic: () => {
        /* logic is handled by prerequisite */
    }
};
