import { errors as _errors } from 'lib_ui-primitives';
import basicValidation from '../namespace/relation/doingValidate_namespace_relation';

export default {
    verb: 'doingValidate',
    namespace: 'transaction',
    relation: 'checkIn',
    description: 'Report back to the user if any items were excluded due to not being checked out.',
    priority: (basicValidation.priority || 0) + 5, //we want this to run BEFORE basic validation
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'item',
                relation: 'item',
                type: 'find'
            },
            query: ({ data }) => {
                return {
                    _id: { $in: data.newRecord?.includedItems?.map(i => i._id) || [] },
                    'meta.deleted': { $exists: false }
                };
            }
        }
    ],
    logic: doingValidate
};
/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingValidate({ data, prerequisiteResults }) {
    if (!data?.newRecord?.includedItems?.length) {
        throw new _errors.ValidationError('Please select at least 1 Asset to check in.', {});
    }

    const { result: dbItems = [] } = prerequisiteResults[0] || {};

    // Save “original” transaction.
    return {
        ...data,
        newRecord: {
            ...data.newRecord,
            includedItems: dbItems
        }
    };
}
