//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _Form from './Form';
import _SingleRecordForm from './SingleRecordForm';
import _SubForm from './SubForm';
import _Title from './Title';
import _Transaction from './Transaction';
import * as formComponentSelectorsHold from './formComponentSelectors';
export const formComponentSelectors = formComponentSelectorsHold;
import * as stylesHold from './styles';
export const styles = stylesHold;
export const Form = _Form;
export const SingleRecordForm = _SingleRecordForm;
export const SubForm = _SubForm;
export const Title = _Title;
export const Transaction = _Transaction;
export default {Form, SingleRecordForm, SubForm, Title, Transaction};

