import lodash from 'lodash';
import { errors as _errors } from 'lib_ui-primitives';
import { metadata } from 'lib_ui-services';
import genericRule from '../../namespace/relation/doingValidate_namespace_relation';

const { omit } = lodash;

const _p = { getDictionary };
export const _private = _p;

export default {
    ...genericRule,
    excludedNamespaceRelations: [],
    namespace: 'metaui',
    relation: 'captionOverrides',
    logic: validate
};

async function validate({ data, context, ...rest }) {
    const { newRecord } = data;

    /**
     * Check for any duplicate values in the caption override record, regardless of the field.
     * The field needs to be ignored because navigation to the caption override record is based on the field value.
     * If there are duplicate values in the caption override record,
     * then the user will see multiple forms for the same field on that page.
     * @link https://sstdev.visualstudio.com/Backbone/_workitems/edit/6452
     */
    const dictionary = await _p.getDictionary('metaui', 'captionOverrides');
    const fieldsToCheck = Object.keys(dictionary);
    const duplicates = findDuplicates(newRecord, fieldsToCheck);

    const defaultErrorMessage =
        'Another caption override value already exists with this name. Please enter a different name.';

    try {
        // First, check for duplicates in the caption override record
        if (duplicates.length > 0) {
            const message = `${defaultErrorMessage} Duplicates: ${duplicates.join(', ')}`;
            // If there are duplicates, throw a validation error
            throw new _errors.ValidationError(message, {});
        }

        // Otherwise, proceed with the generic rule
        const result = await genericRule.logic({ data, context, ...rest });
        return result;
    } catch (error) {
        if (error.constructor.name === 'ValidationError') {
            const message = error?.message || defaultErrorMessage;
            throw new _errors.ValidationError(message, {});
        }
        throw error;
    }
}

/**
 * Returns the data dictionary for the namespace and relation.
 * @param {string} namespaceName - The namespace name.
 * @param {string} relationName - The relation name.
 * @returns {Object} - The dictionary for the namespace and relation.
 */
async function getDictionary(namespaceName, relationName) {
    const dictionary = await metadata.getDictionary();
    const captionOverrideProperties = omit(dictionary[namespaceName][relationName], '_meta');
    return captionOverrideProperties;
}

/**
 * Finds and returns an array of duplicate values in the captionOverrideRecord object.
 *
 * @param {Object} record - The object containing caption override values.
 * @param {Array} fieldsToCheck - The fields to check for duplicates.
 * @returns {Array} - An array of duplicate values found in the captionOverrideRecord object.
 */
function findDuplicates(record, fieldsToCheck) {
    const seenValues = new Set();
    const duplicates = new Set();

    for (const key of fieldsToCheck) {
        // Check if the field exists in the record
        if (key in record) {
            // Creating a caption override with the same value with a space at the end will still cause the error on the UI
            const value = record[key]?.trim();
            if (value === '') continue;
            if (seenValues.has(value)) {
                duplicates.add(value);
            } else {
                seenValues.add(value);
            }
        }
    }
    // Convert the Set of duplicates to an array for displaying purposes
    return Array.from(duplicates);
}
