import notHandledHere from '../notHandledHere';
import { constants, metadata } from 'lib_ui-services';
const { getPrettyRelationName } = metadata;

const _p = {
    getPrettyRelationName
};

export const _private = _p;

export default {
    verb: 'didRollback',
    excludedNamespaceRelations: notHandledHere,
    description: 'Notify user rollback happened',
    //this is the actual logic:
    logic: didRollback
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function didRollback({ data, dispatch, context }) {
    const { namespace, relation } = context;
    let message = data?.originalMessage ?? data?.message ?? '';
    // special processing for FLAIR items, to provide a friendlier message.
    if (context.user?.activeUseCaseId === constants.useCaseIds.FLAIR && relation === 'item') {
        if (message.includes('Patch request did not have a current record to patch')) {
            message = `Updated failed. A new inventory was likely opened before the item changes were synced. Changes to item ${
                data.newRecord?.assetNo || ''
            } will be lost.`;
            dispatch(
                { message, timeout: 5000, addToList: true, isError: true },
                {
                    verb: 'pop',
                    namespace: 'application',
                    relation: 'notification'
                }
            );
            return;
        }
    }
    // Pull together a notification message about the failure.
    const failingRelation = _p.getPrettyRelationName(namespace, relation);
    // Try to get the original failure message.
    // Create a general message, and append a more specific one if available.
    message = `${failingRelation} changes were unsuccessful. ${message}`;
    dispatch(
        { message, timeout: 5000, addToList: true, isError: true },
        {
            verb: 'pop',
            namespace: 'application',
            relation: 'notification'
        }
    );
}
