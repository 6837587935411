import { ScrollView, fromTheme, styled, webOnlyStyles, Button } from 'lib_ui-primitives';

// Most components are based on standard NavHeading components
import {
    NoShrink,
    NavHeaderPlain,
    Noncollapsible,
    LinkText as _LinkText,
    CollapseSpacer as _CollapseSpacer,
    CollapseIndicator as _CollapseIndicator,
    CollapseOffset as _CollapseOffset,
    FullWidthCollapse as _FullWidthCollapse
} from '../../../navHeading/styles';

// these are simply passed through so we don't need to import 2 separate "styles.js" files:
export const MenuRow = Noncollapsible;
export const CollapseSpacer = _CollapseSpacer;
export const CollapseOffset = _CollapseOffset;
export const FullWidthCollapse = _FullWidthCollapse;
export const AccessNavHeaderPlain = NavHeaderPlain;

// LinkText and NoShrink/NavSection get some extra styled to indicate their disabled state
export const LinkText = styled(_LinkText)`
    color: ${({ disabled, theme }) => {
        // darken the test when either this node is disabled, or a parent is
        if (disabled) {
            return theme.outerMenus.disabledFontColor;
        }
        return theme.colorScheme['white-text'];
    }};
`;

export const NavSection = styled(NoShrink)`
    background-color: ${({ disabled, theme }) => {
        // only darken the background if we're not already disabled due to a parent state
        if (disabled) {
            return theme.outerMenus.disabledBackgroundColor;
        }
        return 'transparent';
    }};
`;

// AccessIconToggle is _just_ a button with a lot of default attributes
// And we override the color of the icon to be white
let _AccessIconToggle = styled(Button).attrs({
    name: 'AccessIconToggle',
    buttonStyle: 'IconAction',
    color: 'transparent'
})`
    i {
        color: white;
    }
`;
_AccessIconToggle.displayName = 'AccessIconToggle';
export const AccessIconToggle = _AccessIconToggle;

// CollapseIndicator on the regular navHeading is not nested the same way as CollapseSpacer
// so, the styling on that is a mess. Fix it to make it look the same as the regular navHeading
// but while supporting consistent nesting.
export const CollapseIndicator = styled(_CollapseIndicator)`
    flex-shrink: 0;
    margin-top: 18px;
    margin-left: 6px;
    margin-right: 0px;
`;

// AccessMenuContainer is the (scrolling) wrapper around the entire menu.
// On the regular menu, I think that comes from "SideNav",
// but due to the different usage, we need very different styling here.
let _AccessMenuContainer = styled(ScrollView).attrs({ name: 'AccessMenu' })`
    background-color: ${fromTheme('outerMenus', 'backgroundColor')};
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    border-left-color: ${fromTheme('colorScheme', 'border')};
    border-left-width: ${({ theme }) => (theme.mobile ? 0 : '4px')};
`;
_AccessMenuContainer = webOnlyStyles(_AccessMenuContainer)`
    border-left-style: solid;
`;
_AccessMenuContainer.displayName = 'AccessMenu';
export const AccessMenuContainer = _AccessMenuContainer;
