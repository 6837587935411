import { createElement as rc, forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import styled from '../../styled';

import Button from '../Button';
import TextInput from '../TextInput';
import View from '../View';

const InputWrapper = styled(View).attrs({ name: 'input-wrapper' })`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const InnerInput = styled(TextInput)`
    flex-grow: 1;
`;

const DateButton = styled(Button)`
    flex-grow: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
    transform: translateX(-6px);
`;

/**
 * @typedef {Object} Props
 * @property {boolean} [disabled]
 */
/** @type {import('react').ForwardRefRenderFunction<Props> */
const Input = forwardRef((props, ref) => {
    const { onChange: _onChange, onClick: _onClick, onFocus, sequence } = props || {};
    const onChange = useCallback(
        value => {
            _onChange({ target: { value } });
        },
        [_onChange]
    );

    const onClick = useCallback(
        e => {
            // For some reason react-date-picker does not define onClick
            // for modal date pickers, but uses onFocus instead....
            _onClick?.(e) ?? onFocus(e);
        },
        [_onClick, onFocus]
    );

    const onKeyDown = useCallback(
        e => {
            if ([' ', 'Enter'].includes(e.key)) {
                onClick(e);
            }
        },
        [onClick]
    );

    // prettier-ignore
    return rc(InputWrapper, null,
        rc(InnerInput, { ...props, onChange, ref, spellCheck: 'false', autoComplete: 'off' }),
        props.disabled
            ? null
            : rc(DateButton, {
                roundDiameter: 32,
                icon: 'date_range',
                buttonStyle: 'IconAction',
                onClick,
                onKeyDown,
                iconSize: 24,
                color: 'transparent',
                sequence
            })
    );
});

Input.displayName = 'DatePickerInput';

Input.defaultProps = {
    disabled: false
};

Input.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    sequence: PropTypes.number.isRequired
};

export default Input;
