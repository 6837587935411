import { createElement as rc, useMemo, forwardRef } from 'react';
import useScrollLayoutChange from '../../hooks/useScrollLayoutChange';
// import useFramerateTest from './useFramerateTest';

/**
 * (WEB ONLY)
 * react-window component will allow the outer scrolling element's component
 * type to be passed in.  This is necessary in order to report on layout
 * changes resulting from a user scroll event
 * Note:  this is different from react native where the vertical flatlist is
 * defined independently from the horizontal scrollview.
 * @param {function} onScrollLayoutChange - callback containing the scrolling elements layout after a change
 * @param {string} id - hNode id for this component's parent
 * @param {array} [items] - the items displayed in the list - only needed if doing a framerate test
 * @returns component - the scrolling element for a list
 */
export default function useListOuterScrollingElement(onScrollLayoutChange, id) {
    //, items) {
    const scrollViewRef = useScrollLayoutChange(onScrollLayoutChange);

    // Provides framerate performance metrics for vertical scrolling.
    // This needs to be inside this hook because the scrollview ref is
    // defined inside here.
    //useFramerateTest(items, undefined, scrollViewRef);

    return useMemo(() => {
        // eslint-disable-next-line react/no-multi-comp
        const component = forwardRef((props, ref) => {
            if (typeof outerRef === 'function') {
                ref(scrollViewRef);
            } else if (ref != null && typeof ref === 'object' && ref.hasOwnProperty('current')) {
                ref.current = scrollViewRef;
            }
            // react-window creates styles props, etc. to allow scrolling.
            return rc('div', { ...props, ref: scrollViewRef, id: `outerElementForList${id}` });
        });
        component.displayName = 'outerElementType';
        return component;
    }, [scrollViewRef, id]);
}
