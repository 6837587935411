import doingGet_item_item_nextAutoId from '../../../../CRUD/item/item/doingGet_item_item_nextAutoId';
import { metadata, constants } from 'lib_ui-services';
const { useCaseIds } = constants;

// TODO: Would prefer to use prerequisites to get the next auto increment id, but rulesengine.io seems to have
// some trouble with `type` right now.  I've left the commented out code to use the rule when we fix that.
export default {
    verb: 'doingCopy',
    namespace: 'item',
    relation: 'item',
    status: 'success',
    description: 'Prepopulate a copied asset record with the next asset No',
    featureFlag: 'autoIncrementAssetNo',
    //prerequisites: [{ context: { verb: 'get', namespace: 'item', relation: 'item', type: 'nextAutoId' } }],
    useCaseIds: [useCaseIds.ASSET_TRACKING, useCaseIds.AMERICAN_WATER_ASSET_TRACKING],
    //this is the actual logic:
    logic: copySuccess
};
const _p = {
    doingGet_item_item_nextAutoId
};
export const _private = _p;
async function copySuccess({ data, dispatch, context }) {
    const { namespace, relation } = context;
    const result = await _p.doingGet_item_item_nextAutoId.logic({ context });
    //if (prerequisiteResults?.[0]?.result?.length) {
    if (result) {
        //nextAssetNo = prerequisiteResults[0].result;
        const nextAssetNo = result.toString();
        const titleAlternative = metadata.getTitleAlternative(namespace, relation);
        // Dispatch this as a change so that record will be dirty
        dispatch(
            {
                propertyName: titleAlternative,
                newValue: nextAssetNo
            },
            { verb: 'beginChange', namespace, relation }
        );
    }
    return data;
}
