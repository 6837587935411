import { errors as _errors } from 'lib_ui-primitives';
import { network } from 'lib_ui-services';

const _p = { getNetworkStatus: network.getStatus };
export const _private = _p;

export default {
    verb: 'doingValidate',
    namespace: 'import',
    relation: 'import',
    description: 'Import specific validation',
    // this is the actual logic:
    logic: validation
};

async function validation({ data }) {
    const networkStatus = await _p.getNetworkStatus();
    if (!networkStatus.isOnline) {
        const message = 'Failed to communicate with the server. Unable to import while offline.';
        throw new _errors.ValidationError(message, {});
    }

    const {
        newRecord: { columns, dataModel, foreignNamespace, foreignRelation }
    } = data;

    // Check for field level validation problems.
    const columnErrors = validateColumns(columns, dataModel, foreignNamespace, foreignRelation);
    if (columnErrors.length) {
        const message = 'There are problems with this Import. For details, see the red message(s) above.';
        throw new _errors.ValidationError(message, { columns: columnErrors });
    }
}

export function validateColumns(columns, dataModel, foreignNamespace, foreignRelation) {
    if (!columns?.length) {
        return ['No columns selected for this import.'];
    }

    const requiredColumns = dataModel.filter(d => d._meta && d._meta.required);
    const missingColumns = requiredColumns.filter(r => !columns.find(c => c?._meta && c._meta.id === r._meta.id));
    const unassignedColumns = columns.some(c => !c?._meta);
    let errors = [];

    // Custom validation for organization:person
    // At least _one_ of the required columns should be present
    if (foreignNamespace === 'organization' && foreignRelation === 'person') {
        const requiredPersonColumns = dataModel.filter(d => ['lastName', 'personId'].includes(d?._meta?.propertyName));
        const missingPersonColumns = requiredPersonColumns.filter(
            r => !columns.some(c => c?._meta?.propertyName === r?._meta?.propertyName)
        );
        if (missingPersonColumns.length === requiredPersonColumns.length) {
            errors = missingPersonColumns.map(m => `Column ${m._meta.title} is required.`);
        }
    }

    if (missingColumns.length) {
        errors = missingColumns.map(m => `Column ${m._meta.title} is required.`);
    }

    if (unassignedColumns) {
        errors.push('Please assign or exclude all unmatched columns.');
    }

    return errors;
}
