import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { View, styled } from 'lib_ui-primitives';

const LeftAlign = styled(View)`
    justify-content: flex-start;
    flex-grow: 1;
`;

function LeftAlignLayout(props) {
    if (props == null) return null;
    const { id, title: name, children, ...otherProps } = props;
    return rc(LeftAlign, { name, id, ...otherProps }, children);
}

LeftAlignLayout.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.array,
    hNode: PropTypes.object.isRequired
};

export default LeftAlignLayout;
