import { useState, useEffect } from 'react';

const useStepSize = numBars => {
    //the "cutOff" values for each arc, so that the first arc is shown after half the distance.
    //e.g.`[5, 15, 25,35,...] with 10 in between, with 10 bars total, or `[25,75]` with 50 in between, with 2 bars total
    const [steps, setSteps] = useState([10, 30, 50, 70, 90]);
    useEffect(() => {
        const stepSize = 100 / numBars;
        let newSteps = [];
        for (let step = stepSize / 2; step < 100; step += stepSize) {
            newSteps.push(step);
        }
        setSteps(newSteps);
    }, [numBars]);

    return steps;
};
export default useStepSize;
