import encryption from '@sstdev/lib_encryption';

export default {
    verb: 'willUpdate',
    namespace: 'mqtt',
    relation: 'users',
    priority: 10,
    prerequisites: [],
    description: 'encrypt the password',
    // this is the actual logic:
    logic: willUpdate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 *
 * @typedef {{
 *  title:string,
 *  description: string,
 *  newPassword: string
 * 'identity:tenant':{_id:string, title:string}
 *  }} SubmittedMqttUser
 *
 * @typedef {{
 *  title:string,
 *  description: string,
 *  password_hash: string
 * 'identity:tenant':{_id:string, title:string}
 *  }} EncryptedMqttUser
 */

/**
 * @param {{
 *   data: {newRecord:SubmittedMqttUser};
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {{newRecord:EncryptedMqttUser}}
 */
async function willUpdate({ data }) {
    const { newPassword, ...mqttUserRecord } = data.newRecord;

    if (newPassword) {
        const hash = await encryption.bcrypt.encrypt(newPassword);
        data.newRecord = { ...mqttUserRecord, password_hash: hash };
    }

    return data;
}
