export default _private => {
    const { startPromise, collection } = _private;
    return _id =>
        startPromise.then(
            () =>
                new Promise((resolve, reject) => {
                    try {
                        let doc = collection.by('_id', _id);
                        if (doc) {
                            collection.remove(doc);
                            return resolve({ deleted: 1 });
                        }
                        resolve({ deleted: 0 });
                    } catch (err) {
                        reject(err);
                    }
                })
        );
};
