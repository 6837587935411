import filterHelper from './filterHelpers';
import lodash from 'lodash';
const { get } = lodash;

export default {
    getFilter,
    getUriComponent,
    fromHNode,
    pageResetRequired: true,
    getMql,
    getJavaScriptFilter
};

function fromHNode({ values, propertyName, namespace, relation, originalRelation, allowUndefined = true }) {
    return getFilter(values, propertyName, namespace, originalRelation || relation, allowUndefined);
}

function getFilter(values, propertyName, optionalNamespace, optionalRelation, allowUndefined) {
    return {
        values,
        propertyName,
        optionalNamespace,
        optionalRelation,
        allowUndefined
    };
}

function getUriComponent(filters) {
    // This should always be an array of values
    if (!filters.values || !Array.isArray(filters.values)) return;
    let { values, propertyName, optionalNamespace, optionalRelation } = filters;
    if (optionalNamespace && optionalRelation) {
        propertyName = `${optionalNamespace}:${optionalRelation}.${propertyName}`;
    }

    // There is probably a better way to do this with regex, but will explore it later
    // Convert any array index to dot notation for mongodb query consumption
    if (propertyName.includes('[')) {
        propertyName = propertyName.replace('[', '.');
        propertyName = propertyName.replace('].', '.');
        propertyName = propertyName.replace(']', '.');
    }

    values = `["${values.join('","')}"]`;
    let result = `excludeProperty=${encodeURIComponent(propertyName)}`;
    result += `&excludePropertyValues=${encodeURIComponent(values)}`;
    return result;
}

function getMql(filters) {
    //we support having multiple instances of this filter. concat with array to assure we have an array
    const filterDefinitions = [].concat(filters.excludedValues);

    const mongoFilter = filterDefinitions
        .map(excludedValues => {
            if (excludedValues && Object.keys(excludedValues).length > 0) {
                let { values = [], propertyName, optionalNamespace, optionalRelation, allowUndefined } = excludedValues;
                // Remove array index for lokijs (e.g. inventory.0.found or inventory[0].found => inventory.found)
                let nodes = propertyName.split(/\.|\[|\]/g).filter(x => x);
                if (nodes.length > 1) {
                    nodes = nodes.filter(n => isNaN(Number.parseInt(n)));
                    propertyName = nodes.join('.');
                }

                if (optionalNamespace && optionalRelation) {
                    propertyName = `${optionalNamespace}:${optionalRelation}.${propertyName}`;
                }

                //before allowUndefined was exposed on blockly, the workaround was to add "null" as a string as excludedValue
                if (values.includes('null') || values.includes('undefined')) {
                    allowUndefined = false;
                    values = values.filter(v => !['null', 'undefined'].includes(v));
                    if (!values.length) {
                        //only "null" was configured to be excluded, so all they care about is that it exists.
                        return {
                            [propertyName]: {
                                $exists: true
                            }
                        };
                    }
                }

                let filter = {
                    [propertyName]: {
                        $nin: values
                    }
                };

                //only if we have 1 value
                if (values.length === 1) {
                    //and the value looks like a boolean
                    //then depending on the allowUndefined, specify the condition
                    switch (values[0].toLowerCase()) {
                        case 'true':
                            filter[propertyName] = allowUndefined ? { $ne: true } : { $eq: false };
                            return filter;
                        case 'false':
                            filter[propertyName] = allowUndefined ? { $ne: false } : { $eq: true };
                            return filter;
                    }
                }

                //otherwise, if allowUndefined is set, add additional clause:
                if (allowUndefined) {
                    return {
                        $or: [
                            filter,
                            {
                                [propertyName]: {
                                    $exists: false
                                }
                            }
                        ]
                    };
                }

                return filter;
            }
        })
        .filter(x => x); //only include actual filters

    if (!mongoFilter.length) {
        return;
    }
    if (mongoFilter.length === 1) {
        return mongoFilter[0];
    }
    return { $and: mongoFilter };
}

function getJavaScriptFilter(filter) {
    let { values = [], propertyName, optionalNamespace, optionalRelation, allowUndefined = false } = filter;
    if (optionalNamespace && optionalRelation) {
        propertyName = `${optionalNamespace}:${optionalRelation}.${propertyName}`;
    }
    if (values.includes('null') || values.includes('undefined')) {
        allowUndefined = true;
    }
    const condition = filterHelper.basicFilterToJavaScript({ propertyName, op: '$nin', value: values });

    return record => {
        if (get(record, propertyName, null) == null) return allowUndefined;
        return condition(record);
    };
}
