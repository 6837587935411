import React, { useEffect } from 'react';
import useEventSink from '../../hooks/useEventSink';
import { contexts } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';
const { notificationTypes } = constants;
const { UiNotificationContext } = contexts;
const rc = React.createElement;

let typeHandlers = {};

const context = {
    addTypeHandler: (type, notifyFunction) => {
        const handlersForType = (typeHandlers[type] = typeHandlers[type] ?? []);
        if (!Object.values(notificationTypes).includes(type)) {
            throw new Error(`${type} is an unknown notification type.`);
        }

        handlersForType.push(notifyFunction);
        return () => handlersForType.splice(handlersForType.indexOf(notifyFunction), 1);
    },
    openToasts: []
};

export default function UiNotificationProvider(props) {
    const [subscribe] = useEventSink();
    const { children } = props;
    useEffect(() => {
        const unsubscribe = subscribe(
            { verb: 'pop', namespace: 'application', relation: 'ui-notification' },
            (payload, context) => {
                let typeFound = false;
                const { type = notificationTypes.TOAST } = payload;
                Object.values(notificationTypes).forEach(notificationType => {
                    if (type & notificationType) {
                        typeFound = true;
                        const handlers = typeHandlers[notificationType] || [];
                        handlers.forEach(handler => handler(payload, context));
                    }
                });
                if (!typeFound) {
                    throw new Error(
                        `${type} is an unknown notification type.  See lib_ui-primitives.constants.notificationTypes for valid values.`
                    );
                }
            }
        );
        // cleanup;
        return () => {
            unsubscribe();
            typeHandlers = {};
        };
    }, [subscribe]);

    return rc(UiNotificationContext.Provider, { value: context }, children);
}
