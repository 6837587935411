import { constants, globalConfig as getGlobalConfig } from 'lib_ui-services';
import logging from '@sstdev/lib_logging';
import lodash from 'lodash';
const { omit } = lodash;
const { debounce } = lodash;
import conversion from '@sstdev/lib_epc-conversions';
const { ERROR, REMOVE_AND_ERROR, REMOVE, NONE } = constants.tagAction;

import clientDataRightsVersionMergeReads from './mergeReads.clientDataRights';

// WARNING: If need RFID for non-unique location rooms or buildings *with non-unique tags*,
// this will not work - but then it's not clear how RFID would ever work in that
// scenario anyway so....
export default async function mergeReads(newEntries, config, update, remove) {
    if (getGlobalConfig().featureFlags.includes('clientDataRights')) {
        return clientDataRightsVersionMergeReads(newEntries, config, update, remove);
    }

    if (newEntries.length === 0) return;
    const {
        displayGatheredErrors,
        titularField,
        publish,
        request,
        matchProperties,
        displayInAscii,
        mergeNamespace,
        mergeRelation,
        unknownTagAction,
        inactiveTagAction = NONE,
        beepForNewReads
    } = config;

    // All entries in a batch should be of the same sensorType
    const hexLookup = [constants.sensorTypes.RFID, constants.sensorTypes.BLE].includes(newEntries[0].sensorType);
    const requestFromSensor = [
        constants.sensorTypes.RFID,
        constants.sensorTypes.BLE,
        constants.sensorTypes.BARCODE
    ].includes(newEntries[0].sensorType);
    for (let i = 0; i < newEntries.length; i++) {
        try {
            const entry = newEntries[i];
            const criteria = { 'meta.deleted': { $exists: false } };
            const lookupValue = entry.tagId;
            // RFID/BLE should always have a tagId entry equal to the untransformed tagId
            if (hexLookup) {
                criteria.tagId = lookupValue;
            } else {
                //Manual or barcode entry. Match against matchProperties:
                criteria.$or = [];
                matchProperties.forEach(lookupField => {
                    criteria.$or.push({ [lookupField]: lookupValue });
                });
            }
            if (entry.inDatabase == null) {
                const { result } = await request(
                    { criteria },
                    {
                        verb: 'get',
                        type: 'find',
                        namespace: mergeNamespace,
                        relation: mergeRelation
                    }
                );
                if (result.length > 1) {
                    if (hexLookup) {
                        throw new Error(
                            `Unexpected number of database entries (${result.length}) found for tagId ${entry.tagId}`
                        );
                    } else {
                        const message = `More than one record matches ${lookupValue}.`;
                        displayGatheredErrors(message);
                        remove(entry);
                    }
                } else {
                    let newEntry;
                    if (result.length === 0) {
                        //  unknown tag;
                        newEntry = { ...omit(entry, ['rssi', 'batteryLevel']) };
                        newEntry.inDatabase = false;
                        let partialTag = lookupValue;
                        if (displayInAscii && hexLookup) {
                            partialTag = conversion.ascii.fromHex(partialTag);
                        }
                        // This is the maximum text that will fit in the current (2023-05-17)
                        // statically sized grid columns
                        partialTag = partialTag.substring(partialTag.length - 6);
                        newEntry[titularField] = `<${constants.UNKNOWN_TAG}...${partialTag}>`;
                        if ([ERROR, REMOVE_AND_ERROR].includes(unknownTagAction)) {
                            // Only display unknown tag errors for barcode/manual entries
                            if (
                                [constants.sensorTypes.MANUAL, constants.sensorTypes.BARCODE].includes(entry.sensorType)
                            ) {
                                const message = `${entry.tagId} does not exist in the database.`;
                                displayGatheredErrors(message);
                                if (unknownTagAction === REMOVE_AND_ERROR) {
                                    remove(entry);
                                    continue;
                                }
                            }
                        }
                    } else {
                        // Not ideal performance-wise to use destructuring here, but this
                        // SHOULD just happen once per unique read.
                        newEntry = {
                            ...result[0],
                            ...omit(entry, ['rssi', 'batteryLevel']),
                            // hold onto original database unique row id so it can be used
                            // for database updates later (see willUpdate_namespace_relation).
                            $dbLoki: result[0].$loki,
                            _id: result[0]._id
                        };
                        newEntry.inDatabase = true;
                        // Only display inactive tag errors for barcode/manual entries
                        if (newEntry.active === false) {
                            if (
                                [constants.sensorTypes.MANUAL, constants.sensorTypes.BARCODE].includes(entry.sensorType)
                            ) {
                                //if active is defined, AND set to false:
                                if ([ERROR, REMOVE_AND_ERROR].includes(inactiveTagAction)) {
                                    //let the user know
                                    const message = `${newEntry[titularField]} is an inactive record. You cannot perform a transaction on an inactive record.`;
                                    displayGatheredErrors(message);
                                }
                                if ([REMOVE, REMOVE_AND_ERROR].includes(inactiveTagAction)) {
                                    // don't include it in the grid:
                                    remove(entry);
                                }
                            } else {
                                // If this is RFID just remove the inactive entry
                                remove(entry);
                            }
                            continue;
                        }
                    }

                    // eslint-disable-next-line no-undef
                    if (beepForNewReads && requestFromSensor && !__NO_SOUND__) {
                        _p.beepCount++;
                        _p.debouncedBeep(publish, Math.min(_p.beepCount, 10));
                    }
                    try {
                        update(newEntry);
                    } catch (err) {
                        logging.error(
                            err,
                            '\nError while updating read merge entry.  Entry: ',
                            JSON.stringify(newEntry)
                        );
                    }
                }
            }
        } catch (err) {
            logging.error(err);
        }
    }
}

const _p = {
    debouncedBeep: debounce((publish, count) => {
        _p.beepCount = 0;
        publish(
            { message: 'New read', type: constants.notificationTypes.SOUND, times: count },
            {
                verb: 'pop',
                namespace: 'application',
                relation: 'notification'
            }
        );
    }, 1000),
    beepCount: 0
};
