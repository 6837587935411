import filterHelper from './filterHelpers';
// Because this is only used by lokijs dynamicViews (as opposed to a paged database get),
// most of this is dependent on the ActiveRecord context boundary which is passed in via
// the contextualInfo parameter on fromHNode() below.
export default {
    getFilter,
    fromHNode,
    getUriComponent,
    getMql,
    getJavaScriptFilter
};

function fromHNode(hNode, contextualInfo) {
    // At this time (8/7/2023) namespace:relation _should_ always be hardcoded to identity:user
    // technically, there is no reason, but practically, we don't see any reason not to.
    const { propertyName = 'routePath' } = hNode;
    const currentRoute = contextualInfo?.currentRoute;
    return getFilter(propertyName, currentRoute);
}

function getFilter(propertyName, currentRoute) {
    return {
        propertyName,
        currentRoute
    };
}

function getMql(filters) {
    if (filters.byCurrentRoute == null) return;
    const { propertyName, currentRoute } = filters.byCurrentRoute;
    return { [propertyName]: currentRoute };
}

function getUriComponent(filters) {
    const filter = filters['byCurrentRoute'];
    return `searchProperties=${encodeURIComponent(filter.propertyName)}&searchTerm=${encodeURIComponent(
        filter.currentRoute
    )}&exact=true`;
}

function getJavaScriptFilter(filter) {
    const { propertyName, currentRoute } = filter;
    const value = currentRoute;
    return filterHelper.basicFilterToJavaScript({ propertyName, op: '$eq', value });
}
