import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { View, Button, h4, fromTheme, styled, COMMON_COLOR_SCHEME } from 'lib_ui-primitives';
import HNode from '../../HNode';
import createTreePositionGetter from '../../utilities/createTreePositionGetter';

const ListRow = styled(View).attrs({ name: 'list-row' })`
    background-color: ${fromTheme('backgroundColor')};
    padding: ${fromTheme('viewPadding')};
    margin-bottom: ${fromTheme('viewMargin')};
    flex-direction: column;
    align-items: start;
    flex-shrink: 0;

    div[name='form-field'] {
        min-width: 200px;
    }
`;

const ListSubForm = styled(View).attrs({ displayName: 'list-subform' })`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const LeftToRight = styled(View)`
    flex-grow: 1;
    width: 100%;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled(h4)`
    margin: ${fromTheme('viewMargin')};
`;

const RemoveButton = styled(Button)`
    margin: 0;
`;

function WorkflowActionPane(props) {
    const {
        hNode: { propertyPath, title, children, treePosition },
        value,
        onRemove,
        childIndex,
        currentRoute
    } = props;

    function subFormRemove() {
        onRemove(childIndex);
    }

    const getTreePosition = createTreePositionGetter(treePosition, children.length);

    // prettier-ignore
    return rc(ListRow, null,
        rc(LeftToRight, null,
            rc(Title, null, title),
            rc(RemoveButton, {icon: 'clear', buttonStyle: 'round', color: 'transparent', fontColor:COMMON_COLOR_SCHEME.error, onClick: subFormRemove})
        ),
        rc(ListSubForm, null,
            children.map((hNode, index) =>
                rc(HNode, { 
                        key: index, 
                        hNode: {
                            ...hNode, 
                            propertyPath, 
                            id: `${hNode.id}_${childIndex}`,
                            treePosition: getTreePosition(index) 
                        }, 
                        currentRoute
                    }, 
                    value[hNode.propertyName])
            )
        )
    );
}

WorkflowActionPane.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};

export default WorkflowActionPane;
