export default {
    verb: 'didCreate',
    namespace: 'application',
    relation: 'savedFilter',
    description: 'close Saved Filter name dialog',
    logic: closeDialog
};

function closeDialog({ data, dispatch }) {
    const { newRecord } = data || {};
    dispatch(
        {
            message: `Filter "${newRecord?.title || ''}" saved.`,
            addToList: false,
            isError: false
        },
        {
            verb: 'pop',
            namespace: 'application',
            relation: 'notification'
        }
    );
    dispatch(
        {},
        {
            verb: 'close',
            namespace: 'application',
            relation: 'savedFilter',
            type: 'ignore-changes'
        }
    );
}
