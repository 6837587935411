import { executeForEach } from '../registeredServiceQueues';
import { globalConfig } from 'lib_ui-services';
export default {
    verb: 'doingStartup',
    namespace: 'sensor',
    relation: 'read',
    description: 'Start reading using a service that will provide sensor reads',
    logic
};

async function logic({ data, context, dispatch }) {
    if (data.sensorType == null) {
        throw new Error('sensorType is required when starting up a sensor read.');
    }
    executeForEach('scan', data, context);
    let timeout;
    try {
        timeout = setTimeout(() => {
            dispatch(data, { ...context, verb: 'stop' });
        }, data.intervalMilliseconds || globalConfig().sensorScanIntervalMilliseconds);
    } catch (err) {
        clearTimeout(timeout);
        throw err;
    }
}
