import { localStorage } from 'lib_ui-services';
import { useCallback, useState, useEffect, useRef } from 'react';

/**
 * This acts like useState, but retains the state within the route. If the user navigates
 * to another route it will have its own state.  If the user returns to the first route,
 * the previously stored state will be available until the app storage is cleared.
 * @param {string} stateName - name of state to store/retrieve
 * @param {any} defaultValue - the default value to store/retrieve
 * @returns array containing a the current state, the function to change it and whether the state is ready
 * to be used.  The last value is because the storage operation in localForage is async.
 */
export default function usePersistentState(stateName, defaultValue) {
    const [ready, setReady] = useState(false);
    const [state, setState] = useState(defaultValue);
    // Don't rerender for new refs of an equal object
    const firstDefaultValue = useRef(defaultValue);
    // Prevent state updates after this is destroyed.
    const allowStateChange = useRef(true);
    useEffect(
        () => () => {
            allowStateChange.current = false;
        },
        []
    );

    useEffect(() => {
        async function doAsync() {
            const existingState = await localStorage.getKey(stateName, undefined, firstDefaultValue.current);
            if (allowStateChange.current) {
                setState(existingState);
                setReady(true);
            }
        }
        doAsync();
    }, [stateName]);

    const proxySetState = useCallback(
        async newState => {
            setReady(false);
            let calculatedState = newState;
            if (typeof newState === 'function') {
                const prev = await localStorage.getKey(stateName);
                if (allowStateChange.current) {
                    if (prev == null) {
                        calculatedState = newState(firstDefaultValue.current);
                    } else {
                        calculatedState = newState(prev);
                    }
                }
            }
            if (allowStateChange.current) {
                await localStorage.setKey(stateName, calculatedState);
                if (allowStateChange.current) {
                    setState(calculatedState);
                    setReady(true);
                }
            }
        },
        [stateName]
    );

    return [state, proxySetState, ready];
}
