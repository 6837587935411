import logging from '@sstdev/lib_logging';
import { filters as allFilters } from 'lib_ui-services';
import { extractFiltersFromHNodes } from '../../../hooks/useFilterMetadata';
import getColumnValue from './getColumnValue';
import { getNestedValues } from './getNestedValues';

const EMPTY_ARRAY = [];
const EMPTY_STRING = '';
export default function getNestedDetailValue(
    value = [],
    column,
    { featureFlags, activeRecord, splashRecord, navigationSelection }
) {
    //only look at the first nested column
    let nestedField = column.children.find(c => c.hNodeTypeGroup === 'listColumn');
    if (!nestedField) return EMPTY_STRING;

    if (Array.isArray(value)) {
        //IF the value passed in is an array,
        if (!value.length) return EMPTY_STRING;
        //for all array entries that match the filter (if defined)

        const hNodeChildren = column?.children ?? EMPTY_ARRAY;
        const contextualInfo = { featureFlags, activeRecord, splashRecord, navigationSelection };
        const metadataBasedFilters = extractFiltersFromHNodes(hNodeChildren, contextualInfo);
        if (Object.keys(metadataBasedFilters).length) {
            const conditions = Object.entries(metadataBasedFilters).map(([key, value]) => {
                if (!allFilters[key]?.getJavaScriptFilter) {
                    logging.warn(
                        `[FILTER] Nested Detail Column's ${key} filter does not have a 'getJavaScriptFilter()' implementation`
                    );
                    return () => true;
                } else {
                    return allFilters[key]?.getJavaScriptFilter(value);
                }
            });
            //find the (first) entry that matches ALL/Every condition defined by the filters
            const index = value.findIndex(entry => conditions.every(condition => condition(entry)));
            if (index === -1) {
                //if nothing is found that matches, force child elements to use an index that does not exist
                //so nothing to show
                return EMPTY_STRING;
            } else {
                //if we found it, have child components use that specific array element
                value = [value[index]];
            }
        }
        //else, (if !Object.keys(metadataBasedFilters).length)) we have no filters, use all values
        // nothing to do.

        const values = getNestedValues(value, nestedField, {
            featureFlags,
            activeRecord,
            splashRecord,
            navigationSelection
        });
        return values.slice(0, 2).join(', ') + (values.length > 2 ? ', ...' : '');
    }
    // if value is not an array (when it is an object), just get the value for that first nested column.
    return getColumnValue(value, nestedField, { featureFlags, activeRecord, splashRecord, navigationSelection });
}
