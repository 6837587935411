import { useSpring as _useSpring } from '@react-spring/core';
import { useCallback, useContext } from 'react';
import AnimatingContext from '../../../contexts/AnimatingContext';

// Right now Spring (although probably quite useful and easier to understand) isn't really
// used for non-native components, most of the animations are written purely in CSS.  This
// non-native counterpart is here in case to avoid confusion or extra research for future
// maintainers.
export default function useSpring(props) {
    const { setIsAnimating } = useContext(AnimatingContext);
    const onStart = useCallback(() => setIsAnimating(true), [setIsAnimating]);
    const onRest = useCallback(() => setIsAnimating(false), [setIsAnimating]);

    return _useSpring({
        ...props,
        onStart,
        onRest
    });
}
