import notHandledHere from './notHandledHere';
import editRule from './doingEdit_namespace_relation';
export default {
    verb: 'doingView',
    description: 'View an existing record.',
    excludedNamespaceRelations: notHandledHere,
    //this is the actual logic:
    logic: view
};

async function view(...args) {
    // same logic as edit rule.
    const result = await editRule.logic(...args);
    result.activationVerb = 'view';
    return result;
}
