import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { View, styled } from 'lib_ui-primitives';

const SpaceApart = styled(View)`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const EvenlySized = styled(View).attrs({ name: 'evenly-sized' })`
    flex-basis: ${({ percent }) => `${percent}%`};
`;

function EvenColumnsLayout(props) {
    const { id, title: name, children, ...otherProps } = props;
    return rc(
        SpaceApart,
        { name, id, ...otherProps },
        children.map((child, key) => rc(EvenlySized, { key, percent: 100 / (children.length || 1) }, child))
    );
}

EvenColumnsLayout.propTypes = {
    children: PropTypes.array,
    hNode: PropTypes.object.isRequired
};

export default EvenColumnsLayout;
