import { Fragment, createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { h1, testProperties } from 'lib_ui-primitives';
import { CloseButton, Message, TextContainer } from './infoDisplayButtonStyles';

// const _MessageContent = styled(View).attrs({ name: 'message-content' })`
//     background-color: ${fromTheme('backgroundColor')};
// `;
/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function MessageContent(props) {
    const {
        toggleMessageVisibility,
        hNode,
        hNode: { title = '', message = '', displayTitle = false }
    } = props;

    return rc(
        Fragment,
        null,
        rc(CloseButton, {
            icon: 'close',
            color: 'transparent',
            buttonStyle: 'round',
            onClick: toggleMessageVisibility
        }),
        rc(
            TextContainer,
            { onClick: toggleMessageVisibility },
            displayTitle && rc(h1, testProperties(hNode, 'messageTitle'), title),
            rc(Message, testProperties(hNode, 'infoMessage'), message)
        )
    );
}

MessageContent.defaultProps = {};

MessageContent.propTypes = {
    hNode: PropTypes.object.isRequired,
    toggleMessageVisibility: PropTypes.func.isRequired
};

export default MessageContent;
