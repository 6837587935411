import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import styled from '../styled';
import fromTheme from '../fromTheme';
import PropTypes from 'prop-types';
import Button from './Button';

const rc = React.createElement;

const DrawerStyled = styled.div`
    top: 0;
    position: absolute;
    height: ${props => (props.open ? props.theme.height + 'px' : props.theme.outerMenus.headerHeight - 3 + 'px')};
    /* Blockly workspace uses up to z-index 70) */
    z-index: ${({ theme }) => theme.zindex.Drawer};
    width: ${fromTheme('drawerWidth')};
    left: ${({ theme }) => theme.drawerWidth * -1 + 'px'};
    background-color: ${fromTheme('outerMenus', 'backgroundColor')};
    transition: transform 0.3s linear;
    transform: ${props =>
        props.open ? `translateX(${props.openPosition + 'px'})` : `translateX(${props.closedPosition + 'px'})`};
`;

// top 3px is to center the button in the header, similar to the profile button etc, which has the benefit of a flex container
// right 6px is to make sure there is _some_ space to the right of the button, so that when the user mouses-off the button
// that the mouseLeave event is captured by the parent component (sideNav)
const ToggleButton = styled(Button).attrs({ name: 'toggle-button' })`
    position: absolute;
    top: ${({ theme: { mobile } }) => (mobile ? '-3px' : '3px')};
    right: ${({ theme: { mobile } }) => (mobile ? '-6px' : '6px')};
    transition: transform 0.3s linear;
    transform: ${props =>
        props.open ? `translateX(${props.closedPosition - props.openPosition + 'px'})` : 'translateX(0)'};
    margin: 6px;
`;

// Prevent
const onClickDoNothing = e => {
    e.stopPropagation();
};

function MenuDrawer(props) {
    const [icon, setIcon] = useState('menu');
    const {
        id = 'Drawer',
        animationTime,
        position = 'left',
        open,
        setOpen,
        locked,
        setLocked,
        children,
        className
    } = props;
    const theme = useContext(ThemeContext);
    const { mobile } = theme;
    const SCREEN_WIDTH = theme.width;
    const isLeftPosition = position === 'left';
    const DRAWER_WIDTH = theme.drawerWidth;
    const buttonSize = Math.max(
        theme.outerMenus.headerHeight - 3,
        theme.iconSize + theme.textMargin * 2 + theme.button.padding * 2
    );
    const openPosition = isLeftPosition ? DRAWER_WIDTH : SCREEN_WIDTH;
    const closedPosition = isLeftPosition ? 0 + buttonSize : SCREEN_WIDTH + DRAWER_WIDTH - buttonSize;

    const onMouseOver = () => {
        if (mobile) return;
        if (!open) {
            setOpen(true);
        }
        calculateIcon(true);
    };

    const onMouseLeave = () => {
        calculateIcon(false);
    };

    const onClick = e => {
        e.stopPropagation();
        e.preventDefault();
        if (mobile) {
            setOpen(open => !open);
        } else {
            setLocked(locked => !locked);
        }
    };

    const calculateIcon = hover => {
        if (hover) {
            if (locked) {
                setIcon('menu_open');
            } else {
                setIcon('menu_open|rotate(180deg)');
            }
        } else {
            if (open) {
                if (locked) {
                    setIcon('invisible');
                } else {
                    if (mobile) {
                        setIcon('close');
                    } else {
                        setIcon('menu_open|rotate(180deg)');
                    }
                }
            } else {
                if (locked) {
                    throw new Error('Navigation should not be locked and closed.');
                } else {
                    setIcon('menu');
                }
            }
        }
    };

    // Recalculate the icon when the drawer is locked/unlocked or opened/closed
    useEffect(() => {
        calculateIcon(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, locked]);

    // prettier-ignore
    return rc(DrawerStyled, { className, name: 'drawer', onClick: onClickDoNothing, buttonSize, openPosition, closedPosition, open, animationTime },
        rc(ToggleButton, {
            id: `btn${id}`,
            title: 'Toggle Navigation',
            alt: 'Menu',
            buttonStyle:'round',
            onMouseOver,
            onMouseLeave,
            onClick,
            icon,
            color: 'transparent',
            fontColor: theme.colorScheme['white-text'],
            openPosition, closedPosition, open
        }),
        open && children
    );
}
MenuDrawer.defaultProps = {
    open: false,
    drawerPercentage: 20,
    animationTime: 350,
    position: 'left'
};

MenuDrawer.propTypes = {
    open: PropTypes.bool,
    drawerPercentage: PropTypes.number,
    animationTime: PropTypes.number,
    position: PropTypes.oneOf(['left', 'right']),
    close: PropTypes.func
};

export default MenuDrawer;
