import { useLayoutEffect, useMemo, createElement as rc, useEffect, useState } from 'react';
import { contexts, getQueue } from 'lib_ui-primitives';
import { useTheme } from 'styled-components';
import logging from '@sstdev/lib_logging';

let debugSet = false;
/**
 * In browser, only one dialog is allowed on the page, so create a context to manage a queue of modal
 * requests
 * @param {*} props
 * @returns
 */
export default function ModalQueue(props) {
    const dialog = useMemo(() => document.querySelector('dialog'), []);
    // eslint-disable-next-line no-undef
    if (!__PRODUCTION__ && !debugSet) {
        // Monkey patch dialog close() and showModal() in dev for additional observability
        const _close = dialog.close;
        const _showModal = dialog.showModal;
        dialog.close = () => {
            logging.debug('[DIALOG] - closing.');
            // avoid illegal invocation error
            _close.apply(dialog);
        };
        dialog.showModal = () => {
            logging.debug('[DIALOG] - opening.');
            // avoid illegal invocation error
            _showModal.apply(dialog);
        };
        debugSet = true;
    }

    const [queue] = useState(getQueue('modalQueue'));
    const theme = useTheme();
    useEffect(() => {
        queue.onChange(() => {
            if (queue.getLength() === 0 && dialog.open) {
                dialog.close();
            } else if (queue.getLength() > 0 && !dialog.open) {
                dialog.showModal();
                dialog.scrollTop = 0;
            }
        });
    }, [dialog, queue]);

    useLayoutEffect(() => {
        dialog.style.top = 0;
        dialog.style.height = '100%';
        dialog.style.width = '100%';
        dialog.style.maxHeight = '100%';
        dialog.style.maxWidth = '100%';
        // Gray out the background
        dialog.style.background = theme.darkMode ? '#00000099' : '#ffffff60';
        dialog.style.border = 'none';
    }, [dialog, theme]);

    return rc(contexts.ModalQueueContext.Provider, { value: queue }, props.children);
}
