import Grid from '../Grid';
import { createElement as rc, useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
const { omit } = lodash;
import useEventSink from '../../../hooks/useEventSink';
import useReads from '../../../hooks/useReads';
import {
    CenterText,
    WorkingText,
    UnknownTagSwitch,
    GreedyView,
    ShowUnknownTags,
    ShowUnknownTagsLabel,
    ReadButton
} from './styles';
import HeaderRow from '../TableList/HeaderRow';
import useRowDetail from '../TableList/useRowDetail';
import { constants, metadata, dbViews } from 'lib_ui-services';
import logging from '@sstdev/lib_logging';
import MergedReadProvider, { useMergedReads } from '../../contextProviders/MergedReadProvider';
import { useColumnWidths } from '../columns';
const { EMPTY_ARRAY } = dbViews.emptyDbView;
const { sensorTypes } = constants;
const DEFAULT_SORTED_COLUMNS = {};
const _p = {
    useReads,
    useMergedReads,
    //eslint-disable-next-line no-undef
    isNative: () => __SST_REACT_NATIVE__ || __TEST_RFID__
};
export const _private = _p;
export default function SensorReadList(props) {
    const mergedReads = _p.useMergedReads();
    // no existing MergedReadsProvider
    if (mergedReads == null) {
        const {
            hNode: {
                mergeNamespace,
                mergeRelation,
                matchProperties: _matchProperties,
                id,
                sensorType,
                displayShowUnknownTags,
                unknownTagAction: _unknownTagAction
            }
        } = props ?? { hNode: {} };
        const titleAlternative = metadata.getTitleAlternative(mergeNamespace, mergeRelation, 'assetNo');
        const unknownTagAction =
            _unknownTagAction ?? (!displayShowUnknownTags ? constants.tagAction.REMOVE : constants.tagAction.NONE);
        const mergeReadProviderProps = {
            hNode: {
                id: `MergedReadProvider${id}`,
                namespace: mergeNamespace,
                relation: mergeRelation,
                matchProperties: _matchProperties ?? [titleAlternative],
                sensorType,
                unknownTagAction,
                beepForNewReads: false,
                // TODO: Update blockly Transaction block to allow these to be specified
                inactiveTagAction: constants.tagAction.NONE,
                duplicateTagAction: constants.duplicateTagAction.REMOVE_AND_ERROR
            }
        };
        return rc(MergedReadProvider, mergeReadProviderProps, rc(InnerSensorReadList, props));
    }
    return rc(InnerSensorReadList, props);
}
function InnerSensorReadList(props) {
    const {
        hNode,
        hNode: {
            sensorType,
            mergeNamespace,
            mergeRelation,
            displayShowUnknownTags = true,
            textForShowUnknownTagsCheckbox = 'Show Unknown Tags',
            includeReadButton = false,
            rowClickAction = 'edit',
            endOfRecordsText = 'End of Records'
        },
        currentRoute
    } = props || { hNode: {} };
    const [subscribe, publish] = useEventSink();
    // TODO: @david.welling: The check in and check out page in asset tracking on the web returned "undefined" for the sensor type.
    // I don't know if that should be changed in the blockly, simply defaulted in code, or just accepted.
    // But it was eventually leading to an error in registeredServicesQueues.
    // Accepting it as undefined for now, but turning it into an empty array for now.
    const typeArray = sensorType ? [sensorType] : [];
    // Lazily loads read state preferring any read state passed in with props
    const { reading: clocking } = _p.useReads(typeArray);
    const [records, setRecords] = useState(EMPTY_ARRAY);
    const { subscribeToChange, toggleIncludeUnknownTags, includeUnknownTags } = _p.useMergedReads();

    const [pauseGrid, setPauseGrid] = useState(false);
    useEffect(() => {
        let interval;
        const unsubscribe = subscribe({ verb: 'change', namespace: 'sensor', relation: 'read' }, payload => {
            if (payload == null) return;
            if (!Array.isArray(payload)) {
                throw new Error(
                    'Sensor read list expects reads to be returned as an array.  If you are sending a single read, wrap it in an array.'
                );
            }
            if (payload.length === 0 || payload[0].sensorType !== 'RFID') return;

            setPauseGrid(true);
            // debounce unpause for 1 second
            if (interval) {
                clearInterval(interval);
            }
            interval = setTimeout(() => {
                setPauseGrid(false);
            }, 1000);
        });
        return () => {
            unsubscribe();
            clearInterval(interval);
        };
    }, [subscribe]);

    useEffect(() => {
        if (subscribeToChange != null) {
            return subscribeToChange(newRecords => {
                logging.debug(`[SensorReadList] new records (length ${newRecords.length})`);
                setRecords(oldRecords => {
                    if (
                        Array.isArray(oldRecords) &&
                        oldRecords.length === 0 &&
                        Array.isArray(newRecords) &&
                        newRecords.length === 0
                    ) {
                        return oldRecords;
                    }
                    return newRecords;
                });
            });
        }
    }, [subscribeToChange]);

    //const [sortedColumns, setSortedColumns] = useState({});
    const headerClicked = useCallback(function () {
        // Do nothing - sorts will be overridden by rules engine on next
        // read (see integrateChangeIntoReads.js).
    }, []);

    // useCallback avoids rerenders of List
    const onClick = useCallback(
        item => {
            if (rowClickAction === 'nothing') return;
            if (item.inDatabase) {
                publish(
                    { _id: item._id },
                    { verb: rowClickAction, namespace: mergeNamespace, relation: mergeRelation }
                );
            } else {
                publish(
                    {
                        newRecord: omit(item, [
                            '_id',
                            'name',
                            'rssi',
                            'sensorType',
                            'time',
                            'compositeSortField',
                            '$loki'
                        ])
                    },
                    { verb: 'new', namespace: mergeNamespace, relation: mergeRelation, routePath: currentRoute }
                );
            }
        },
        [publish, mergeNamespace, mergeRelation, rowClickAction, currentRoute]
    );

    // Get the width of each column from the column definition
    const { columnWidths: columnHNodes, handleColumnWidthChange } = useColumnWidths(props, records);
    // Pass the resulting column nodes with the widths to RowDetail
    const [RowDetail, onScrollLayoutChange] = useRowDetail(hNode, columnHNodes, undefined, endOfRecordsText);

    // If we are not on a native device, then there is no way we have RFID scanning (right now).
    const enableRFID = _p.isNative();

    const startReads = () => {
        if (enableRFID) {
            publish(
                { sensorType, mergeNamespace, mergeRelation },
                { verb: 'startup', namespace: 'sensor', relation: 'read' }
            );
        }
    };
    const warnedOnce = useRef(false);
    // Do not show the sensor grid if this for RFID and we are not on native.
    // Currently (2022-03-28) RFID is not really supported in browsers.
    if (!enableRFID && sensorType === sensorTypes.RFID) {
        if (!warnedOnce.current) {
            logging.warn(
                'You have a non-native device, but are requesting RFID reads.  The blockly configuration may be wrong.'
            );
            warnedOnce.current = true;
        }
        return null;
    }

    // prettier-ignore
    return rc(GreedyView, { name: 'sensor-read-list', 'data-testid': props['data-testid'] },
        !clocking && !pauseGrid && rc(Grid, {
            listType: 'table',
            records,
            recordCount: records.length,
            sortedColumns: DEFAULT_SORTED_COLUMNS,
            columnHNodes,
            headerClicked,
            onClick,
            RowDetail,
            HeaderRow,
            onScrollLayoutChange,
            endOfRecordsText,
            handleColumnWidthChange,
            ...props
        }),
        (clocking || pauseGrid) && rc(CenterText, {},
            rc(WorkingText, {}, '...Working')
        ),
        [sensorTypes.RFID, sensorTypes.ANY].includes(sensorType) && displayShowUnknownTags && rc(ShowUnknownTags, { 'data-testid': 'show-unknown-tags' },
            rc(UnknownTagSwitch, { onClick: toggleIncludeUnknownTags, value: includeUnknownTags }),
            rc(ShowUnknownTagsLabel, null, textForShowUnknownTagsCheckbox)
        ),
        [sensorTypes.RFID, sensorTypes.ANY].includes(sensorType) && includeReadButton && rc(ReadButton, {
            icon: 'speaker_phone', // looks like device with waves shooting off it.
            buttonStyle: 'BigIconAction',
            onClick: startReads,
            clocking
        })
    );
}
InnerSensorReadList.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    'data-testid': PropTypes.string,
    hNode: PropTypes.shape({
        sensorType: PropTypes.string,
        mergeNamespace: PropTypes.string,
        mergeRelation: PropTypes.string,
        displayShowUnknownTags: PropTypes.bool,
        includeReadButton: PropTypes.bool,
        matchProperties: PropTypes.arrayOf(PropTypes.string)
    })
};

SensorReadList.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    'data-testid': PropTypes.string,
    hNode: PropTypes.shape({
        sensorType: PropTypes.string,
        mergeNamespace: PropTypes.string,
        mergeRelation: PropTypes.string,
        displayShowUnknownTags: PropTypes.bool,
        includeReadButton: PropTypes.bool
    })
};
