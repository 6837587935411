import { contextMatching as match } from 'lib_ui-services';
/**
 *
 * @typedef {import('rulesengine.io').RulesProvider} RulesProvider
 * @typedef { import('rulesengine.io').WorkflowStack } WorkflowStack
 * @typedef { import('rulesengine.io').Context } Context
 * @typedef { import('rulesengine.io').LoggingProvider } LoggingProvider
 *
 * @callback LogicFunction
 * @function
 * @param {object} parameters
 * @param {any} parameters.data
 * @param {object[]} parameters.prerequisiteResults (already stripped from ".result")
 * @param {Context} parameters.context
 * @param {WorkflowStack[]} parameters.workflowStack
 * @param {LoggingProvider} parameters.log
 * @param {(data:object, context:Context, awaitResult?:boolean)=>Promise<void|any>} parameters.dispatch publish to or request through eventSink
 *
 * @typedef extendedRule
 * //mostly copied from import('rulesengine.io').Rule
 * @property {string} verb tensed verb, e.g. `willCreate`, `doingUpdate`, `didRemove`
 * @property {string} [namespace]
 * @property {string} [relation]
 * @property {'success'|'failure'} [status]
 * @property {string} description
 * @property {string} [featureFlag]
 * @property {number} [priority]  defines order of execution within a specific tense when using `rulesArrayRepository`. Higher priorities get run first. default 0
 * @property {{ context: Context; string?: object | function }[]} prerequisites?
 * @property {LogicFunction} logic parameter: { data: T; prerequisiteResults: object[]; context: Context; workflowStack: WorkflowStack[],log:LoggingProvider }, returns T;
 * @property {function} onError parameter: { error: Error; data: T; context: Context; workflowStack: WorkflowStack[],log:LoggingProvider }, returns: T;
 * //extension
 * @property {{namespace:string,relation:string}[]} excludedNamespaceRelations
 * /

/**
 *
 * @param {extendedRule[]} rules
 * @returns { RulesProvider } RulesProvider
 */
export default class RulesArrayProvider {
    constructor(rules) {
        this.rules = rules;
    }

    /**
     *
     * @param {Context} context
     * @returns {Promise<Rule[]>}
     */
    async find(context) {
        const filtered = this.rules.filter(rule => match(rule, context));
        const sorted = filtered.sort((a, b) => {
            return (b.priority || 0) - (a.priority || 0);
        });
        return sorted;
    }
}
