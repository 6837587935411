import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import log from '@sstdev/lib_logging';
import { ErrorBoundary as _ErrorBoundary } from 'react-error-boundary';
import Text from './Text';

// Default Fallback component and error handlers:
export const _FallbackComponent = () => rc(Text, null, 'Something went wrong.');
const _onError = (error, info) => {
    const { componentStack } = info;
    // eslint-disable-next-line no-undef
    if (__UNIT_TESTING__) {
        /* eslint-disable no-console */
        if (!error?.message?.startsWith('An error was thrown inside one of your components')) {
            console.error(error);
            console.debug('Component Stack: ', componentStack);
        }
        /* eslint-enable no-console */
    } else {
        log.error(error);
        log.debug('Component Stack: ', componentStack);
    }
};

// Allow consumer to override defaults
export default function ErrorBoundary(props) {
    const { FallbackComponent = _FallbackComponent, onError = _onError, children } = props || {};
    return rc(_ErrorBoundary, { FallbackComponent, onError }, children);
}

ErrorBoundary.propTypes = {
    children: PropTypes.node
};
