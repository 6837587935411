/**
 * Paged filter does not work well for the initial sync because the first page size is small (.i.e 100)
 * while all subsequent pages are large (i.e. 1000).  This means asking for the second page will miss
 * the 900 records you didn't get with the first page.
 */
export default {
    getFilter,
    getUriComponent,
    fromHNode
};

function fromHNode({ limit }) {
    return getFilter(0, limit);
}

function getFilter(offset = 0, limit = 100) {
    return {
        offset,
        limit
    };
}

function getUriComponent(filters) {
    const filter = filters['syncRange'];
    let result = `offset=${encodeURIComponent(filter.offset)}`;
    result += `&limit=${encodeURIComponent(filter.limit)}`;
    return result;
}
