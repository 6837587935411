/**
 * This is not a block in blockly, but it is used to hold a bbFilter when there are multiple sorts.
 * Blockly allows multiple orderBy blocks, but this is incompatible with how the filters are
 * structured in bbFilters. i.e. you can't do this:
 * {
 *      orderBy: { property: 'a' },
 *      orderBy: { property: 'b' }
 * }
 * If there are currently multiple sorts on a loki dynamic view and that needs to be
 * converted to a url and sent to the server (e.g. to pull a new page for that view), this
 * filter allows that.
 */
export default {
    getFilter,
    getUriComponent,
    fromHNode
};

function fromHNode() {
    throw new Error('not implemented - no blockly block has been created for this filter.');
}

function getFilter(lokiSortCriteria = []) {
    return lokiSortCriteria.map(([path, desc]) => ({ path, desc }));
}

function getUriComponent(filters) {
    const filter = filters['orderByMultiple'] ?? [];
    if (filter.length > 0) {
        const stringsVerions = filter.map(({ path, desc }) => `${path}:${desc ? 'd' : 'a'}`);
        return `orderBy=${encodeURIComponent(stringsVerions.join(','))}`;
    }
}
