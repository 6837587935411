import logging from '@sstdev/lib_logging';
import globalConfig from '../globalConfig';
import lodash from 'lodash';
const { omit } = lodash;
import * as network from '../network';
import * as cache from './cache';

const _p = {
    getNetworkStatus: network.getStatus,
    httpGet: rawGet,
    cacheSave: cache.save,
    cacheFind: cache.find,
    handleOfflineAuthentication
};
export const _private = _p;

/**
 * use an OAuth authenticated user (either from Auth0, or other SSO source) to retrieve the matching backboneUser
 * @param {*} oauthUser
 * @param {*} groupNumber
 * @param {*} getAccessTokenSilently
 * @param {*} logout
 * @returns
 */
export default async function authorizeOAuthUser(oauthUser, groupNumber, getAccessTokenSilently, logout) {
    try {
        const networkStatus = await _p.getNetworkStatus();
        if (networkStatus.isServerReachable) {
            const token = await getAccessTokenSilently({ detailedResponse: true });
            const accessToken = token.access_token;
            const groupPath = groupNumber ? `/g/${groupNumber}` : '';
            let response = await _p.httpGet(groupPath + '/api/identity/self', {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json'
            });

            if (!response || !response.tenant?.[0].useCase?.[0]) return null;
            const dbUser = cleanse(response);
            const needTenantSelection = dbUser.tenant.length > 1 || dbUser.tenant[0].useCase.length > 1;
            let active = {};
            //no idea what exactly needs to go into profile.
            //Probably should be a function that maps the user to a profile.
            //Maybe even on the server?
            let profile = {
                userName: dbUser.userName,
                displayName: dbUser.displayName,
                picture: oauthUser.picture,
                sub: oauthUser.sub,
                user: { _id: dbUser.userId, ...dbUser },
                isOauthUser: true,
                loginExpiresAt: daysInTheFuture(globalConfig().auth0RefreshTokenAbsoluteLifetimeDays || 30)
            };
            if (!needTenantSelection) {
                const { needEulaAcceptance, needMlaAcceptance } = dbUser.tenant[0].useCase[0] || {};
                active.needsLicenseAcceptance = needEulaAcceptance || needMlaAcceptance || false;
                active.activeTenant = dbUser.tenant[0];
                active.activeTenantId = active.activeTenant['identity:tenant']._id;
                active.activeUseCase = dbUser.tenant[0].useCase[0];
                active.activeUseCaseId = active.activeUseCase['metaui:useCase']._id;
                active.group = active.activeUseCase['deploy:group'];
                active['identity:role'] = active.activeUseCase['identity:role'];
                active.allFeatureFlags = active.activeUseCase.featureFlags || [];
                active.briefUserReference = { _id: profile.user._id, displayName: profile.user.displayName };

                await _p.cacheSave(
                    { ...profile, needTenantSelection, getAccessTokenSilently, logout, ...active },
                    oauthUser.sub
                );
            }

            return { ...profile, needTenantSelection, getAccessTokenSilently, logout, ...active };
        } else {
            return _p.handleOfflineAuthentication(oauthUser, getAccessTokenSilently, logout);
        }
    } catch (error) {
        logging.error(error);
        return null;
    }
}

async function handleOfflineAuthentication(oauthUser, getAccessTokenSilently, logout) {
    const session = await _p.cacheFind(oauthUser.name, oauthUser.sub);
    if (!session) {
        throw new Error(`Unable to authorize user ${oauthUser.nickname} while offline.`);
    }
    return { ...session, getAccessTokenSilently, logout };
}

//we need to bypass the http.get functionality, as otherwise we'd end up with a circular reference
async function rawGet(url, headers) {
    const params = {
        method: 'GET',
        headers
    };
    const response = await fetch(url, params);
    if (response.ok) {
        return response.json();
    } else {
        logging.error(`[AUTHENTICATION] Failed to authorize. ${response.status} - ${response.statusText}`);
    }
}

//the server sometimes includes the full content of the license agreements in the user.
//But not always. So don't rely on it, and strip it out if it is there as it is humongous
function cleanse(dbResult) {
    const { tenant, ...cleanUser } = omit(dbResult, [
        'namespace',
        'verb',
        'relation',
        'needTenantSelection',
        'group',
        'appContext',
        'correlation'
    ]);
    const cleanTenants = tenant.map(t => {
        let { useCase, ...cleanTenant } = t;
        const cleanUseCases = useCase.map(uc => {
            let { 'backbone:eula': eula, 'backbone:mla': mla, ...cleanUseCase } = uc;
            if (eula) {
                cleanUseCase['backbone:eula'] = {
                    _id: eula._id,
                    title: eula.title
                };
            }
            if (mla) {
                cleanUseCase['backbone:mla'] = {
                    _id: mla._id,
                    title: mla.title
                };
            }
            return cleanUseCase;
        });
        return { ...cleanTenant, useCase: cleanUseCases };
    });
    return { ...cleanUser, tenant: cleanTenants };
}

function daysInTheFuture(daysToAdd) {
    let someDate = new Date();
    someDate.setDate(someDate.getDate() + daysToAdd);
    return someDate;
}
