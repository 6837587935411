import { errors } from 'lib_ui-primitives';
import genericRule from '../../namespace/relation/doingValidate_namespace_relation';

export default {
    ...genericRule,
    excludedNamespaceRelations: [],
    namespace: 'identity',
    relation: 'user',
    priority: genericRule.priority + 10, // before the generic rule
    description:
        'Validate the user username, generate a displayName if necessary, and then pass through to the generic rule.',
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'identity',
                relation: 'user',
                type: 'find'
            },
            query: ({ data }) => {
                const { userName } = data.newRecord;
                return { userName, 'meta.deleted': { $exists: false } };
            }
        }
    ],
    logic: doingValidate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */

async function doingValidate({ data, prerequisiteResults, context, ...rest }) {
    const { displayName, firstName, lastName, userName } = data.newRecord;
    if (prerequisiteResults?.[0]?.result?.length && data.newRecord._id !== prerequisiteResults[0].result[0]._id) {
        const errorMessage = `Another User exists with the email ${userName}.`;
        throw new errors.ValidationError(errorMessage, {
            userName: 'User with this email already exists'
        });
    }
    // Add displayName if it doesn't exist before the validation rule is run
    const recordWithDisplayName = { ...data.newRecord, displayName: displayName || `${firstName} ${lastName}` };
    return { data: { ...data, newRecord: recordWithDisplayName }, context, ...rest };
}
