import logging from '@sstdev/lib_logging';
import { executeForEach } from '../registeredServiceQueues';

export default {
    verb: 'doingStop',
    namespace: 'sensor',
    relation: 'read',
    description: 'Stop reading in a service that will provide sensor reads',
    logic
};

async function logic({ data, context }) {
    if (data.sensorType == null) {
        throw new Error('sensorType is required when stopping up a sensor read.');
    }
    try {
        executeForEach('stopScanning', { ...data }, context);
    } catch (err) {
        logging.error(err, context);
    }
}
