import { useCallback, useState } from 'react';

/**
 * The possible states of the toggle
 */
export const TOGGLE_STATES = {
    checked: true,
    unchecked: false,
    neutral: undefined
};

/**
 * A hook to manage toggle state with support for an optional neutral state.
 *
 * @param {boolean|undefined} initialValue The initial state of the toggle.
 * @param {string|undefined} neutralOption The value representing the neutral state. Will fallback to the default toggle logic if undefined.
 * @returns {[boolean|undefined, () => void]} The current state and a function to toggle the state.
 */
export default function useToggle(initialValue = false, neutralOption) {
    const fromValue = value => {
        // The neutral value is configured via blockly. The actual value is not important, and is only used to keep track of the toggle neutral state.
        if (neutralOption && value === neutralOption) return TOGGLE_STATES.neutral;
        return value ? TOGGLE_STATES.checked : TOGGLE_STATES.unchecked;
    };

    const [state, setState] = useState(fromValue(initialValue));

    // Cycle through the toggle states: unchecked (false) -> neutral (undefined) -> checked (true)
    const toggle = useCallback(() => {
        setState(currentState => {
            if (currentState === TOGGLE_STATES.checked) {
                return TOGGLE_STATES.unchecked;
            } else if (currentState === TOGGLE_STATES.unchecked && neutralOption) {
                return TOGGLE_STATES.neutral;
            } else {
                return TOGGLE_STATES.checked;
            }
        });
    }, [neutralOption]);

    return [state, toggle];
}
