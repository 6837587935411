/**
 * This is only used for Debugging the TreePosition algorithm.  See createTreePositionGetter.js.
 */

import { useRef, createElement as rc, useState, useCallback } from 'react';
import { Text, View, styled, fromTheme } from 'lib_ui-primitives';

const MeasuringView = styled(View).attrs({ name: 'measuring-view' })`
    height: 1px;
    width: 1px;
    background-color: transparent;
`;
const TreePosition = styled(Text).attrs({ name: 'tree-position' })`
    display: block;
    position: absolute;
    padding: 12px;
    background-color: ${fromTheme('baseBackgroundColor')};
    border-radius: 4px;
    font-size: 8px;
    z-index: 9998;
    &:hover {
        font-size: 12px;
        z-index: 9999;
    }
`;
TreePosition.displayName = 'TreePosition';
MeasuringView.displayName = 'MeasuringView';
function HNodeTreePosition(props) {
    const {
        treePosition: { depth, sequence, minSequence, maxSequence, numberOfChildren, desiredIndex }
    } = props ?? {};
    const ref = useRef();
    const [hover, setHover] = useState(false);

    const onMouseOver = useCallback(() => {
        setHover(true);
    }, []);
    const onMouseOut = useCallback(() => {
        setHover(false);
    }, []);

    // prettier-ignore
    return rc(MeasuringView, { ref },
        rc(TreePosition, { name: 'treePosition', onMouseOver, onMouseOut },
            hover ? `${desiredIndex+1} of ${numberOfChildren} Range:(${minSequence}-${maxSequence}) Depth:${depth} Sequence:${sequence}` :
            `${desiredIndex+1} of ${numberOfChildren} at D${depth}`
        )
    );
}

export default HNodeTreePosition;
