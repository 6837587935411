import react from 'react';
import PropTypes from 'prop-types';
import { useBarcode } from 'react-barcodes';
import styled from '../styled';
import View from './View';
import Svg from './Svg';

const rc = react.createElement;
const BarcodeContainer = styled(View)`
    margin-top: -10px;
`;

export default function Barcode(props) {
    const { value, ...options } = props;
    //for options see https://github.com/lindell/JsBarcode/wiki
    const { inputRef } = useBarcode({
        value,
        options: {
            format: 'CODE128',
            height: 16,
            width: 1,
            fontSize: 10,
            textMargin: 1,
            background: '#ffffff00',
            ...options
        }
    });
    if (!value) return null;
    //-- prettier-ignore
    return rc(
        BarcodeContainer,
        {
            'data-testid': `128barcode-${value}`
        },
        rc(Svg, { ref: inputRef })
    );
}

Barcode.propTypes = {
    value: PropTypes.string,
    height: PropTypes.number,
    format: PropTypes.string
};
