import { createElement } from 'react';
import { Text, View, webOnlyStyles, nativeOnlyStyles, styled } from 'lib_ui-primitives';

const rc = createElement;

let Missing = styled(View).attrs(props => ({ name: props?.name || 'Missing' }))`
    margin: 4px;
    padding: 8px;
    text-align: center;
    color: #f00;
    border: 2px dashed red;
    border-radius: 8px;
`;

Missing = webOnlyStyles(Missing)`
    width: ${props => props.width || 'Calc(100% - 28px)'};
    height: ${props => props.height || 'Calc(100% - 28px)'};
`;

//"Calc" does not work on native!
Missing = nativeOnlyStyles(Missing)`
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '100%'};
`;

const RedText = styled(Text)`
    color: #f00;
`;
export default function MissingComponent({ children, ...props }) {
    let content;
    if (!children) {
        content = rc(RedText, null, 'Implementation Pending');
    } else if (typeof children == 'string') {
        content = rc(RedText, null, children);
    } else {
        content = children;
    }
    return rc(Missing, props, content);
}
