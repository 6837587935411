/**
 * Sorts in place (mutates the array)
 * @param {Array} items - each must contain an 'rssi' field
 * @returns same array, but sorted
 */
export const sortbyRssi = function sortbyRssi(items) {
    items.sort(function (x, y) {
        let a = parseInt(x.rssi);
        let b = parseInt(y.rssi);
        if (x.rssi && y.rssi) {
            return b - a;
        } else if (!x.rssi && y.rssi) {
            return -1;
        } else if (x.rssi && !y.rssi) {
            return 1;
        } else {
            return 0;
        }
    });
    return items;
};

/**
 * Sorts in place (mutates the array)
 * @param {Array} items - array of objects which might contain 'inDatabase' boolean
 * @returns same array, but sorted
 */
export const sortMergedFirst = function sortMergedFirst(items) {
    items.sort(function (x, y) {
        if (!x.inDatabase && y.inDatabase) {
            return 1;
        } else if (x.inDatabase && !y.inDatabase) {
            return -1;
        } else {
            return 0;
        }
    });
    return items;
};

// Creating this collator ahead of time instead of just running localCompare directly
// creates a ~6x perf improvement.  Of course if we are using some language with more non-latin
// characters, the 'en' part might need to be made dynamic.
// https://stackoverflow.com/questions/14677060/400x-sorting-speedup-by-switching-a-localecompareb-to-ab-1ab10
const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
export function sortByField(items, field) {
    items.sort((a, b) => {
        return collator.compare(a[field], b[field]);
    });
    return items;
}

export function sortByPredicate(items, predicate) {
    items.sort((a, b) => collator.compare(predicate(a), predicate(b)));
    return items;
}
