export default {
    verb: 'didRemove',
    namespace: 'metadata',
    relation: 'useCaseDetail',
    description: 'Remove all pages, etc',
    //type: '',
    priority: 10, // before the generic didRemove as that strips the id
    //useCaseIds:[]
    prerequisites: [
        //namespaces
        getLinkedDocumentsFor('namespace'),
        //profile menu
        getLinkedDocumentsFor('profileMenu'),
        //navigation
        getLinkedDocumentsFor('navigation'),
        //pages
        getLinkedDocumentsFor('page')
    ],
    //this is the actual logic:
    logic: didRemove
};

function getLinkedDocumentsFor(relation) {
    return {
        context: {
            verb: 'get',
            namespace: 'metadata',
            relation: relation,
            type: 'find'
        },
        query: ({ data }) => {
            return {
                'metadata:useCaseDetail._id': data._id || data.id,
                'meta.deleted': { $exists: false }
            };
        }
    };
}

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function didRemove({ prerequisiteResults, dispatch, log }) {
    const [
        { result: namespaces },
        {
            result: [profileMenu]
        },
        {
            result: [navigation]
        },
        { result: pages }
    ] = prerequisiteResults;

    log.debug(`[METADATA] Removing ${namespaces.length} Namespaces`);
    const nsContext = { verb: 'remove', namespace: 'metadata', relation: 'namespace' };
    for (const ns of namespaces) {
        await dispatch({ record: ns, needsDecoration: true }, nsContext, true);
    }

    log.debug('[METADATA] Removing profile menu');
    const pmContext = { verb: 'remove', namespace: 'metadata', relation: 'profileMenu' };
    await dispatch({ record: profileMenu, needsDecoration: true }, pmContext, true);

    log.debug(`[METADATA] Removing ${pages.length} pages`);
    const pageContext = { verb: 'create', namespace: 'metadata', relation: 'page' };
    for (const page of pages) {
        const { _id, title } = page;
        await dispatch({ record: { _id, title }, needsDecoration: true }, pageContext, true);
    }

    log.debug('[METADATA] Removing navigation');
    const navContext = { verb: 'create', namespace: 'metadata', relation: 'navigation' };
    await dispatch({ record: navigation, needsDecoration: true }, navContext, true);

    //no need to return anything.
    log.debug('[METADATA] Dependencies successfully removed');
}
