import { createContext, createElement as rc, useCallback, useEffect, useMemo, useRef } from 'react';
import { createMachine, interpret } from 'xstate';
import useEventSink from '../../hooks/useEventSink';

export const RouteBoundMachineContext = createContext();
export default function RouteBoundMachineProvider(props) {
    const [subscribe] = useEventSink();
    const allUnsubscribes = useRef([]);
    const stateMachines = useRef({});
    const permanentActionRef = useRef({});
    const permanentServiceRef = useRef({});
    const currentActionRef = useRef({});
    const currentServiceRef = useRef({});

    const updateActions = useCallback(
        configId => newActions => {
            // Create 1 level of indirection in the actions so they can change
            // if necessary (if a useCallback dependency changes for instance).
            // This allows the action function to come from the consuming component
            // where it can consume component state, etc.
            Object.entries(newActions).forEach(([key, value]) => {
                // If the reference for the action name changes,
                // then store the new action function reference
                if (currentActionRef.current[configId][key] !== value) {
                    currentActionRef.current[configId][key] = value;
                }
            });
        },
        []
    );

    const updateServices = useCallback(
        configId => newServices => {
            // Create 1 level of indirection in the services so they can change
            // if necessary (if a useCallback dependency changes for instance).
            // This allows the service function to come from the consuming component
            // where it can consume component state, etc.
            Object.entries(newServices).forEach(([key, value]) => {
                // If the reference for the service name changes,
                // then store the new service function reference
                if (currentServiceRef.current[configId][key] !== value) {
                    currentServiceRef.current[configId][key] = value;
                }
            });
        },
        []
    );

    /**
     * See the useRouteBoundedMachine.js hook for one way in which this context is used.
     * The idea is to provide a way for multiple components in the same route to consume the
     * same state machine.  This is important when you are controlling workflows that involve
     * multiple components that may render and unmount while the same workflow is
     * in progress.
     */
    const context = useMemo(() => {
        const onTransitionCallbacks = {};
        let unsubscribes = allUnsubscribes.current;

        // The summary detail view is often used in routes to control the workflow
        // This captures when the detail view is opened or closed so
        // the included state machine act accordingly (if desired).
        function onLeaveDetail(callback) {
            unsubscribes.push(subscribe({ verb: 'submit', status: 'success' }, callback));
            unsubscribes.push(subscribe({ verb: 'cancel', status: 'success' }, callback));
            unsubscribes.push(subscribe({ verb: 'remove', status: 'success' }, callback));
        }
        function onEnterDetail(callback) {
            unsubscribes.push(subscribe({ verb: 'edit', status: 'success' }, callback));
            unsubscribes.push(subscribe({ verb: 'view', status: 'success' }, callback));
            unsubscribes.push(subscribe({ verb: 'copy', status: 'success' }, callback));
            unsubscribes.push(subscribe({ verb: 'new', status: 'success' }, callback));
        }

        // Allow multiple consumers to know when the machine state changes
        const onStateChange = configId => callback => {
            onTransitionCallbacks[configId].push(callback);
            // returns an unsubscribe function;
            return () => {
                onTransitionCallbacks[configId].splice(onTransitionCallbacks[configId].indexOf(callback), 1);
            };
        };
        return {
            onLeaveDetail,
            onEnterDetail,
            getStateMachine: (config, startingActions, startingServices) => {
                if (stateMachines.current[config.id] == null) {
                    // Create arrays for this configuration if necessary.
                    permanentActionRef.current[config.id] = permanentActionRef.current[config.id] ?? [];
                    currentActionRef.current[config.id] = currentActionRef.current[config.id] ?? [];
                    permanentServiceRef.current[config.id] = permanentServiceRef.current[config.id] ?? [];
                    currentServiceRef.current[config.id] = currentServiceRef.current[config.id] ?? [];
                    onTransitionCallbacks[config.id] = onTransitionCallbacks[config.id] ?? [];

                    // Assign the starting actions to the currentActionRef.
                    // This is important even though updateAction will be called later
                    // because it sets the keys inside the permanentActionRef
                    // (allowing the state machine to know from the outset what actions
                    // are available to consume).
                    updateActions(config.id)(startingActions);
                    updateServices(config.id)(startingServices);

                    // This creates indirection: it will give
                    // the state machine a function that calls whatever
                    // the current action function is.  This allows
                    // XState to use the original reference to call functions that may
                    // change as the component state changes (e.g. useCallback functions).
                    Object.keys(startingActions).forEach(key => {
                        if (typeof startingActions[key] === 'function') {
                            permanentActionRef.current[config.id][key] = (...args) => {
                                currentActionRef.current[config.id][key].apply({}, args);
                            };
                        } else {
                            // This is going to be stuff like declarative XState assign() results and it
                            // shouldn't need to be in the consuming component or change with component
                            // state changes
                            permanentActionRef.current[config.id][key] = startingActions[key];
                        }
                    });
                    Object.keys(startingServices).forEach(key => {
                        if (typeof startingServices[key] === 'function') {
                            permanentServiceRef.current[config.id][key] = (...args) => {
                                return currentServiceRef.current[config.id][key].apply({}, args);
                            };
                        } else {
                            // This is going to be stuff like declarative XState assign() results and it
                            // shouldn't need to be in the consuming component or change with component
                            // state changes
                            permanentServiceRef.current[config.id][key] = startingServices[key];
                        }
                    });

                    const machine = createMachine(config, {
                        actions: permanentActionRef.current[config.id],
                        services: permanentServiceRef.current[config.id]
                    });
                    // eslint-disable-next-line no-undef
                    const service = interpret(machine, { devTools: __USE_XSTATE_INSPECTOR__ });
                    service.onTransition(state => {
                        onTransitionCallbacks[config.id].forEach(c => c(state));
                    });
                    service.start();
                    stateMachines.current[config.id] = service;

                    // If the machine has no subscribers, these events will be ignored.
                    onLeaveDetail(() => service.send('leaveDetail'));
                    onEnterDetail(() => service.send('enterDetail'));
                }
                const { send } = stateMachines.current[config.id];
                const initialState = stateMachines.current[config.id].state;
                return [
                    send,
                    onStateChange(config.id),
                    initialState,
                    updateActions(config.id),
                    updateServices(config.id)
                ];
            }
        };
    }, [subscribe, updateActions, updateServices]);

    useEffect(() => {
        let unsubscribes = allUnsubscribes.current;
        return () => unsubscribes.forEach(u => u());
    }, []);

    return rc(RouteBoundMachineContext.Provider, { value: context }, props.children);
}
