import { createElement as rc } from 'react';
import styled from '../styled';
import webOnlyStyles from '../webOnlyStyles';

import fromTheme from '../fromTheme';
import View from './View';
import Text from './Text';
import Button from './Button';

const _Chip = styled(View)`
    background-color: ${fromTheme('backgroundHint')};
    margin: 3px;
    padding: 4px 8px;
    border-radius: ${fromTheme('button', 'fontSize')};
    align-items: center;
`;
const ChipText = styled(Text)`
    color: ${fromTheme('labelColor')};
    font-size: ${fromTheme('button', 'fontSize')};
`;
let ChipButton = styled(Button).attrs({ name: 'chip', title: 'Remove', buttonStyle: 'CircleAdd', icon: 'close' })`
    margin: 0px 0 0px 6px;
    padding: 1px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    background-color: ${fromTheme('backgroundHint')};
`;

ChipButton = webOnlyStyles(ChipButton)`
    &:hover {
        background-color: ${fromTheme('button', 'grayHighlight')};
    }
`;

const ChipContainer = styled(View)`
    align-items: flex-start;
    flex-wrap: wrap;
`;

function Chip(props) {
    const { id, value, onClick } = props;
    return rc(_Chip, null, rc(ChipText, {}, value), rc(ChipButton, { onClick, 'data-testid': `cmd${id}` }));
}

Chip.Container = ChipContainer;

export default Chip;
