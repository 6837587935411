import { database } from 'lib_ui-services';

export const _private = { database };

export default {
    verb: 'didNavigate',
    namespace: 'application',
    relation: 'route',
    description: "If there is only 1 inventory, and the user didn't explicitly request one, default to the one. ",
    priority: 5,
    routePath: [
        // Asset Tracking
        '/g/0/Inventory/Take',
        '/g/1/Inventory/Take'
    ],
    featureFlag: 'multiInventory',
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'inventory',
                relation: 'inventory',
                type: 'find'
            },
            query: {
                'inventory:status.title': 'Active',
                'meta.deleted': { $exists: false }
            }
        }
    ],
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @template T
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 */
async function logic({ data, prerequisiteResults, context, dispatch }) {
    const [, inventoryId] = data.navigate?.router?.location?.search?.split('=') || [];
    //there should be AT LEAST 1 inventory, or the other rule should already have kicked them away
    //if we don't have an inventoryId, or if the inventoryId is not valid
    if (!inventoryId || !prerequisiteResults[0].result.find(i => i._id === inventoryId)) {
        //and there is only 1 inventory
        if (prerequisiteResults[0].result.length === 1) {
            //then we can default to it.
            dispatch(
                {
                    to: context.routePath + `?inventory_inventory=${prerequisiteResults[0].result[0]._id}`
                },
                { verb: 'redirect', namespace: 'application', relation: 'route' }
            );
        }
    }
}
