import { createElement as rc, memo, useCallback, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import useEventSink from '../../../hooks/useEventSink';
import { webOnlyProperties } from 'lib_ui-services';

function LinkColumn(props) {
    const {
        record,
        Column,
        width,
        style,
        testAttrs,
        hNode: { id, propertyName, propertyUrl }
    } = props || { hNode: {} };

    const [, publish] = useEventSink();

    const onClick = useCallback(() => {
        publish(record, { verb: 'view', namespace: 'file', relation: 'attachment', type: 'content' });
    }, [publish, record]);

    const { title, cursor, textDecoration, icon } = useMemo(() => {
        const title = record[propertyName];
        const cursor = record[propertyUrl] ? 'pointer' : 'default';
        const textDecoration = record[propertyUrl] ? 'underline' : 'none';
        const icon = record.type?.startsWith('image') ? '🖼️' : '📄';
        return { title, cursor, textDecoration, icon };
    }, [propertyName, propertyUrl, record]);
    
    return rc(
        Fragment,
        null,
        rc(
            Column,
            {
                style: {
                    paddingLeft: '0',
                    paddingRight: '0'
                }
            },
            icon
        ),
        rc(
            Column,
            {
                id,
                onClick,
                width: width ? Number(width) - 22 : width,
                style: {
                    ...style,
                    paddingLeft: '3px',
                    textDecoration,
                    ...webOnlyProperties({ cursor })
                },
                ...testAttrs
            },
            title
        )
    );
}

LinkColumn.propTypes = {
    record: PropTypes.object,
    Column: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    hNode: PropTypes.object
};

export default memo(LinkColumn);
