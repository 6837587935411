import { createElement as rc } from 'react';
import { Router, ErrorBoundary, Toast, Beep, AnimatingBoundary, ModalQueue, PortalHost } from 'lib_ui-primitives';
import Backbone from './Backbone';
import { Theme } from './components';
import { EventBoundary, UserContext, UiNotification, Hints } from './components/contextProviders';
import ConfirmationDialog from './components/dialog/ConfirmationDialog';
import ProgressDialog from './components/dialog/ProgressDialog';
import Auth0ProviderWithHistory from './Auth0ProviderWithHistory';

/**
 * Responsible for basic app plumbing:
 *  Setting up the router
 *  Theme provider
 *  Provide eventBoundary
 *  Setup notification context
 *  Setup user context (even if the context indicates that there is no user logged in yet)
 *  Loading the rest of our app (implemented in Backbone).
 */
export default function Application() {
    // prettier-ignore
    return rc(ErrorBoundary, null,
        rc(Router, null,
            rc(EventBoundary, { logEvents: true, bubbleUpEvents: true, name: 'App' },
                rc(Theme, null,
                    rc(AnimatingBoundary, null,
                        rc(ModalQueue, null,
                            rc(UiNotification, null,
                                rc(Auth0ProviderWithHistory, null,
                                    rc(UserContext, null,
                                        rc(PortalHost, {fullScreen: true},
                                            rc(Hints, null,
                                                rc(Backbone, { name: 'Backbone' }),
                                                rc(Toast),
                                                rc(Beep),
                                                rc(ConfirmationDialog),
                                                rc(ProgressDialog)
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    );
}
