import { database } from 'lib_ui-services';
export default {
    verb: 'doingView',
    namespace: 'application',
    relation: 'notification',
    description: 'Mark all existing notifications as viewed',
    //this is the actual logic:
    logic: doingView
};

async function doingView({ context }) {
    const db = database.get();
    await db.updateMany({ criteria: {}, operations: { $set: { seen: true } } }, context);
}
