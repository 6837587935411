import { SpaceConstrainedContext } from 'lib_ui-primitives';
import { useContext } from 'react';

/**
 * Often with very small mobile views some controls (like headers) will need to be collapsed.
 * This will provide the relevant context to be consumed by those controls.
 * @returns an array containing whether the space is constrained and a method to constrain it.
 */
export default function useSpaceConstrained() {
    const { spaceConstrained, setConstrained } = useContext(SpaceConstrainedContext) ?? {};
    return [spaceConstrained, setConstrained];
}
