import { rfidStrategy } from 'lib_ui-services';
import { register } from '../ZNode';

const ISO_ICON_SIZE = 40;

async function RfidContent(props) {
    const {
        record,
        output,
        zNode: { alignTop = false, alignRight = false, alternatePropertyName },
        width,
        height,
        padding = 16
    } = props || { zNode: {} };
    const hexTagId = rfidStrategy.getTagId(record, alternatePropertyName);

    // default position: bottom-left
    const top = alignTop ? padding : height - ISO_ICON_SIZE - padding;
    const left = alignRight ? width - ISO_ICON_SIZE - padding : padding;

    // if in the future we want to make it more monkey proof
    // we might want to add a check for `labelType.rfid` here
    // to prevent attempting to encode RFID on a label that doesn't support it
    if (hexTagId) {
        // add the image, and add RFID encoding instructions
        return output.moveTo(left, top).addISOImage().addRfid(hexTagId);
    }

    return output;
}

RfidContent.height = () => 0;

register('RfidContent', RfidContent);
export default RfidContent;
