import format from 'date-fns/format';

/**
 * @typedef {ReturnType<typeof import('lib_ui-primitives').themeValues>} Theme
 */

/**
 * @param {Object} columnHNode
 * @param {Theme} theme
 * @param {number} contentLength
 * @returns {number}
 */
export default function getHNodeValueWidth(columnHNode, theme, contentLength) {
    const { fontSize, columnWidthToHeightRatio, maxTableCharLength } = theme;
    let contentWidth = 0;
    switch (columnHNode.dataType) {
        case 'button':
            contentWidth = theme.button.minWidth;
            break;
        case 'link':
        case 'text':
            contentWidth = fontSize * contentLength || maxTableCharLength;
            break;
        case 'dateTime':
            switch (columnHNode.format) {
                case 'ISO': //2019-09-18T19:00:52-04:00
                    contentWidth = 28 * fontSize;
                    break;
                case 'P': // 04/29/1453
                    contentWidth = 10 * fontSize;
                    break;
                case 'PP p': // Apr 29, 1453 12:00 AM
                    contentWidth = 21 * fontSize;
                    break;
                case 'Pp': // 04/29/1453, 12:00 AM
                    contentWidth = 20 * fontSize;
                    break;
                default:
                    return format(new Date(), columnHNode.format).length * fontSize;
            }
            break;
        case 'localShortDate':
            contentWidth = 10 * fontSize;
            break;
        case 'localVerboseDateTime':
            contentWidth = 21 * fontSize;
            break;
        case 'encodedText':
            contentWidth = fontSize * contentLength || maxTableCharLength;
            break;
        case 'mappedBoolean':
            if (columnHNode.mappedTrueValue || columnHNode.mappedFalseValue) {
                contentWidth =
                    Math.max(columnHNode.mappedTrueValue.length, columnHNode.mappedFalseValue.length, 2.1) *
                    fontSize *
                    columnWidthToHeightRatio;
            } else {
                contentWidth = 5 * fontSize; //false
            }
            break;
        case 'gpsCoordinates':
            contentWidth = theme.iconSize + 2 * theme.textPadding;
            break;
        case 'nestedDetail':
            contentWidth = fontSize * contentLength || maxTableCharLength;
            break;
        case 'patchDetail':
            if (columnHNode.asCardBody) {
                return '100%';
            } else {
                // 4 columns, the first one 160px, the rest 200px each
                return 780;
            }
        case 'currency':
            contentWidth = 10 * fontSize;
            break;
        default:
            throw new Error(`Unknown data type for column ${columnHNode.label}`);
    }
    return contentWidth;
}
