export default Object.freeze({
    USER: 'USER',
    ADMIN: 'ADMIN',
    SUPPORT: 'SUPPORT',
    /**
     * @deprecated Use DEITY
     */
    DIETY: 'DIETY',
    DEITY: 'DEITY',
    DEVICE: 'DEVICE'
});
