import { errors as _errors } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';

export default {
    verb: 'willCreate',
    namespace: 'file',
    relation: 'attachment',
    description: 'Validate file type of the attachment',
    logic: willCreate
};

/**
 * @typedef {import("rulesengine.io").Context} Context
 * @typedef {Object} Attachment
 * @prop {string} type mimetype, e.g. image/jpeg
 */

/**
 * @param {{
 *   data: {newRecord: Attachment};
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {Promise<void>}
 */
async function willCreate({ data, dispatch }) {
    // Run validation in a `willCreate` rule since we're calling `create` from willUpdate_namespace_relation_attachments
    const { newRecord } = data;
    if (constants.DISALLOWED_ATTACHMENT_FILE_TYPES.includes(newRecord.type)) {
        const message = `File type '${newRecord.type}' is not allowed.`;
        dispatch(
            {
                message,
                timeout: 5000,
                addToList: true,
                isError: true
            },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );
        throw new _errors.ValidationError(message, {});
    }
}
