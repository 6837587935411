import logging from '@sstdev/lib_logging';

/**
 * Represents an N-dimensional array with various operations.
 */
export default class NDimArray {
    constructor(defaultValue = false) {
        this.data = [];
        this.defaultValue = defaultValue;
    }

    /**
     * Returns the value at the given coordinates.
     * @param  {...Integer} coordinates - The coordinates of the value to get.
     * @returns {any} The value at the given coordinates.
     */
    get(coordinates) {
        let dim = this.data;
        let defaultValue = this.defaultValue;
        for (let i = 0; i < coordinates.length; i++) {
            dim[coordinates[i]] = dim[coordinates[i]] ?? [];
            dim = dim[coordinates[i]];
            defaultValue = dim.defaultValue ?? defaultValue;
        }
        if (dim.value == null) return defaultValue;
        return dim.value;
    }

    /**
     * Sets the value at the given coordinates.
     * @param {any} value - The value to set.
     * @param  {...Integer} coordinates - The coordinates of the value to set.
     */
    set(value, coordinates) {
        let dim = this.data;
        for (let i = 0; i < coordinates.length; i++) {
            dim[coordinates[i]] = dim[coordinates[i]] ?? [];
            dim = dim[coordinates[i]];
        }
        dim.value = value;
        dim.observers?.forEach(callback => callback(value));
    }

    /**
     * Deletes the dimension at the given coordinates.
     * @param  {...Integer} coordinates - The coordinates of the dimension to delete.
     * @returns {void}
     */
    deleteDim(coordinates) {
        let dim = this.data;
        for (let i = 0; i < coordinates.length - 1; i++) {
            if (dim[coordinates[i]] == null) return;
            dim = dim[coordinates[i]];
        }
        if (Array.isArray(dim)) {
            dim.splice(coordinates[coordinates.length - 1], 1);
        } else {
            logging.debug(`Unexpected type ${typeof dim} at ${coordinates}`);
        }
    }

    /**
     * Adds a new dimension to the NDimArray based on the given coordinates.
     * @param {number[]} coordinates - The coordinates specifying the position of the new dimension.
     */
    addDim(coordinates) {
        let dim = this.data;
        for (let i = 0; i < coordinates.length; i++) {
            dim[coordinates[i]] = dim[coordinates[i]] ?? [];
            dim = dim[coordinates[i]];
        }
    }

    /**
     * Deletes the value at the given coordinates.
     * @param  {...Integer} coordinates - The coordinates of the value to delete.
     * @returns {void}
     */
    deleteValue(coordinates) {
        let dim = this.data;
        for (let i = 0; i < coordinates.length; i++) {
            if (dim[coordinates[i]] == null) return;
            dim = dim[coordinates[i]];
        }
        delete dim.value;
        dim.observers?.forEach(callback => callback());
    }

    /**
     * Sets the value of a branch in the NDimArray.
     *
     * @param {*} value - The value to set for the branch.
     * @param {number[]} coordinates - The coordinates of the branch in the NDimArray.
     */
    setBranch(value, coordinates) {
        let dim = this.data;
        for (let i = 0; i < coordinates.length; i++) {
            dim[coordinates[i]] = dim[coordinates[i]] ?? [];
            dim = dim[coordinates[i]];
        }
        const { observers } = dim;
        this.changeChildDims(dim, value);
        dim.defaultValue = value;
        observers?.forEach(callback => callback(value));
    }

    /**
     * Changes the dimensions of the child elements recursively.
     * @param {Array} dim - The array of child elements.
     * @param {any} value - The new value to be assigned to the child elements.
     */
    changeChildDims(dim, value) {
        dim.forEach(c => {
            delete c.value;
            if (c.observers) c.observers.forEach(callback => callback(value));
            this.changeChildDims(c, value);
        });
    }

    /**
     * Observes changes in the NDimArray at the specified coordinates and invokes the provided
     * callback function.
     * @param {Function} callback - The callback function to be invoked when changes occur.
     * @param {Array<number>} coordinates - The coordinates of the NDimArray to observe.
     */
    observe(callback, coordinates) {
        let dim = this.data;
        for (let i = 0; i < coordinates.length; i++) {
            dim[coordinates[i]] = dim[coordinates[i]] ?? [];
            dim = dim[coordinates[i]];
        }
        if (dim.observers == null) dim.observers = [];
        if (!dim.observers.includes(callback)) {
            dim.observers.push(callback);
        }
    }

    /**
     * Checks if the specified coordinates have children in the NDimArray.
     * @param {number[]} coordinates - The coordinates to check.
     * @returns {boolean} - True if the coordinates have children, false otherwise.
     */
    hasChildren(coordinates) {
        let dim = this.data;
        for (let i = 0; i < coordinates.length; i++) {
            dim[coordinates[i]] = dim[coordinates[i]] ?? [];
            dim = dim[coordinates[i]];
        }
        return dim.length > 0;
    }
}
