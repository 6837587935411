import { useCallback, createElement as rc, forwardRef } from 'react';
import propTypes from 'prop-types';
import useEventSink from '../../hooks/useEventSink';
import { Button } from 'lib_ui-primitives';
import { globalConfig, constants } from 'lib_ui-services';
import useReads from '../../hooks/useReads';

const _p = {
    useReads
};
export const _private = _p;

// Essentially an Action Button which passes a payload containing the sensor read specific
// properties set in the blockly block
const SensorReadButton = forwardRef((props, ref) => {
    const {
        //iconFont = 'MaterialCommunityIcon',
        disabled,
        hNode,
        currentRoute,
        hNode: {
            title,
            alt = 'Scan for Tags',
            name = 'sensor-read-button',
            namespace = 'sensor',
            relation = 'read',
            iconName = 'speaker_phone',
            displayTitleOnButton,
            buttonStyle = 'IconAction',
            sensorType = 'RFID',
            scanType = 'OnRequest',
            intervalMilliseconds = globalConfig().sensorScanIntervalMilliseconds
        } = {},
        // Ignore children of action buttons - an example would be
        // import columns which are used elsewhere to provide metadata
        // to the import process, but they are not rendered.
        // eslint-disable-next-line no-unused-vars
        children,
        ...otherProps
    } = props || { hNode: {} };
    const [, publish] = useEventSink();
    const readContext = _p.useReads([sensorType]);

    const { reading: clocking, sensorTypesAvailable = [constants.sensorTypes.NONE] } = readContext;

    const onClick = useCallback(
        function onClick() {
            publish(
                {
                    sensorType,
                    scanType,
                    intervalMilliseconds
                },
                { verb: 'startup', namespace, relation, routePath: currentRoute }
            );
        },
        [publish, namespace, relation, currentRoute, sensorType, scanType, intervalMilliseconds]
    );

    if (!sensorTypesAvailable.includes(constants.sensorTypes.RFID)) {
        return null;
    }

    return rc(Button, {
        ref,
        alt,
        name,
        hNode,
        value: title,
        onClick,
        disabled,
        buttonStyle,
        icon: iconName,
        displayTitleOnButton,
        clocking,
        ...otherProps
    });
});

SensorReadButton.displayName = 'SensorReadButton';
SensorReadButton.propTypes = {
    disabled: propTypes.bool,
    children: propTypes.oneOfType([propTypes.arrayOf(propTypes.element), propTypes.element]),
    currentRoute: propTypes.string,
    // eslint-disable-next-line
    readState: propTypes.any
};
export default SensorReadButton;
