import { useCallback } from 'react';
import useEventSink from './useEventSink';

export default function useRulesEngine() {
    const [, , request] = useEventSink();
    const apply = useCallback(
        async (payload, context) => {
            return request(payload, context);
        },
        [request]
    );
    return {
        apply
    };
}
