import { createElement as rc, useState } from 'react';
import { styled, fromTheme } from 'lib_ui-primitives';
import FormField from '../../formElement/FormField';

const SearchField = styled(FormField).attrs({ name: 'search-element' })`
    max-width: 300px;
    min-width: 150px;
    margin-left: ${fromTheme('textMargin')};
    flex-shrink: 0;
    overflow: visible;
`;

export default function RangeField(props) {
    const {
        PrimitiveComponent,
        value,
        onChange,
        hNode,
        hNode: { id, title },
        sequence: _sequence
    } = props ?? { hNode: {} };
    const sequence = _sequence ?? hNode.treePosition?.sequence;
    const [active, setActive] = useState(false);
    /**
     * @param {React.FocusEvent<HTMLInputElement>} event
     */
    function onFocus(event) {
        setActive(true);
        event.target.select?.();
    }

    function onBlur() {
        setActive(false);
    }
    // prettier-ignore
    return rc(SearchField, { id, title, active },
        rc(PrimitiveComponent, {
            ...hNode,
            value,
            onChange,
            onFocus,
            onBlur,
            sequence
        })
    );
}
