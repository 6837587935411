import registeredSensorServiceQueues from '../registeredSensorServiceQueues';
import constants from '../constants';
// make this easier to mock
const _p = {
    isHidden,
    // eslint-disable-next-line no-undef
    isNative: () => __SST_REACT_NATIVE__ || __TEST_RFID__,
    // eslint-disable-next-line no-undef
    hasRfid: () => __TEST_RFID__ || registeredSensorServiceQueues.some(s => s.type === constants.sensorTypes.RFID)
};
export default adjustAvailableControlsByPlatform;
export const _private = _p;
function adjustAvailableControlsByPlatform(hNode) {
    if (hNode && hNode.children) {
        for (let i = hNode.children.length - 1; i >= 0; i--) {
            if (_p.isHidden(hNode.children[i])) {
                hNode.children.splice(i, 1);
            } else {
                adjustAvailableControlsByPlatform(hNode.children[i]);
            }
        }
    }
}

function isHidden(hNode) {
    if (hNode.hideOn?.length) {
        //if hide on native, and on native: hide
        if (hNode.hideOn.includes('NATIVE') && _p.isNative()) {
            return true;
        }
        //if hide on web, and on web: hide
        if (hNode.hideOn.includes('WEB') && !_p.isNative()) {
            return true;
        }
        //if hide on non-rfid (native),and no RFID: hide
        if (hNode.hideOn.includes('NONRFIDNATIVE') && !_p.hasRfid()) {
            return true;
        }
    }
    return false;
}
