let _cache;
/**
 * This avoids a problem where the authentication/index.js does not consistently load the cache
 * module because of a circular dependency.
 * @returns Object
 */
export default async function getCache() {
    if (_cache == null) {
        _cache = await import('./cache');
    }
    return _cache;
}
