// Because this is only used by lokijs dynamicViews (as opposed to a paged database get),
// most of this is dependent on the SplashRecord context boundary which is passed in via
// the contextualInfo parameter on fromHNode() below.
export default {
    getFilter,
    fromHNode,
    getUriComponent,
    getMql
};

function fromHNode(hNode, contextualInfo) {
    const { namespace, relation } = hNode;
    const foreignKey = contextualInfo?.splashRecord?.record?._id;
    return getFilter(namespace, relation, foreignKey);
}

function getFilter(namespace, relation, foreignKey) {
    return {
        namespace,
        relation,
        foreignKey
    };
}

function getMql(filters) {
    if (filters.foreignRelationSplashRecord == null) return;
    const { namespace, relation, foreignKey } = filters.foreignRelationSplashRecord;
    if (namespace && relation) {
        //return {};
        return { [`${namespace}:${relation}._id`]: foreignKey };
    } else {
        return { _id: foreignKey };
    }
}

function getUriComponent(filters) {
    const filter = filters['foreignRelationSplashRecord'];
    if (filter.namespace && filter.relation) {
        let foreignIdPath = `["${filter.namespace}:${filter.relation}._id"]`;
        return `searchProperties=${encodeURIComponent(foreignIdPath)}&searchTerm=${encodeURIComponent(
            filter.foreignKey
        )}&exact=true`;
    } else {
        return `searchProperties=${encodeURIComponent('"_id"')}&searchTerm=${encodeURIComponent(
            filter.foreignKey
        )}&exact=true`;
    }
}
