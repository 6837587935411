// Need to have access to the queue to see if there are pending transactions
// most of the code comes from '@redux-offline/redux-offline/lib/defaults/queue';
// but adds the ability to request a count of transactions in the queue.

export default function setup(eventSink) {
    let queueCount = 0;
    const [subscribe, publish] = eventSink;
    subscribe({ verb: 'get', namespace: 'application', relation: 'httpqueue' }, payload => {
        publish(
            {
                ...payload,
                queueCount
            },
            { verb: 'get', namespace: 'application', relation: 'httpqueue', status: 'success' }
        );
    });

    function enqueue(array, item) {
        const newQueue = [...array, item];
        queueCount = newQueue.length;
        return newQueue;
    }

    function dequeue(array) {
        const [, ...rest] = array;
        queueCount = rest.length;
        return rest;
    }

    function peek(array) {
        return array[0];
    }

    return {
        enqueue,
        dequeue,
        peek
    };
}
