import notHandledHere from './notHandledHere';
import { database } from 'lib_ui-services';

const _p = { database };
export const _private = _p;
export default {
    verb: 'doingCreate',
    type: 'transaction',
    excludedNamespaceRelations: [...notHandledHere, { namespace: 'print', relation: 'job' }],
    description: 'Update the transaction entry records on the database',
    priority: 10, // run this before the default doingCreate rule runs
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data, context, dispatch }) {
    const {
        newRecord: { entries },
        changes
    } = data;
    const mergeRelation = context.relation.split('-')[0];
    const transactionContext = { ...context, relation: mergeRelation };
    const db = _p.database.get();
    const entryResults = await Promise.allSettled(
        entries.map(async ({ _id }) => {
            const result = await db.getById({ _id }, transactionContext);
            if (!result.length > 0) {
                throw new Error(
                    'Not supported:  Creating new records with transaction entries (as opposed to updates) is not yet supported.'
                );
            }
            const [oldRecord] = result;
            const newRecord = { ...oldRecord, ...changes };
            await dispatch({ oldRecord, newRecord }, { ...transactionContext, verb: 'update' }, true);
        })
    );
    entryResults.forEach((result, i) => {
        if (result.status === 'rejected') {
            const entry = entries[i];
            // always the second field regardless of field name.
            const titleAlternative = entry[Object.keys(entry)[1]];
            dispatch(
                { isError: true, message: `${titleAlternative} update failed.  ${result.reason.message}` },
                { verb: 'pop', namespace: 'application', relation: 'notification' }
            );
        }
    });
    return { ...data, entryResults };
}
