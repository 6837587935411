import lodash from 'lodash';
const { omit } = lodash;
import globalConfig from '../globalConfig';
import * as network from '../network';
import http from '../http';
import * as cache from './cache';
import getUserProfileFromToken from './getUserProfileFromToken';

const standardErrorMessage = 'Unknown User or Incorrect Password';
const _p = {
    globalConfig,
    getNetworkStatus: network.getStatus,
    httpPost: http.post,
    cacheSave: cache.save,
    cacheFind: cache.find,
    getUserProfileFromToken,
    authenticateProprietary,
    standardErrorMessage,
    handleOfflineAuthenticate,
    daysInTheFuture
};
export const _private = _p;
export default async function authenticate(payload) {
    const { email, userName: _userName, password, isOauthUser, ...otherPayload } = payload;
    const userName = email || _userName;
    if (isOauthUser) {
        return Promise.reject(
            new Error('Misconfiguration. Auth0 authenticated users should not go through this code.')
        );
    }
    if (userName == null || userName === '') {
        return Promise.reject(new Error('An email must be provided.'));
    }
    if (!isOauthUser && (password == null || password === '')) {
        return Promise.reject(new Error('A password must be provided.'));
    }

    // In the browser, checking network status is unreliable, so we also catch the 'Failed to fetch'
    // error below and redirect to the offline code (handleOfflineAuthenticate).
    const networkStatus = await _p.getNetworkStatus();
    if (networkStatus.isOnline) {
        //if online, request online authentication.
        let session;
        try {
            session = await _p.authenticateProprietary({ userName: userName.trim(), password, ...otherPayload });
            session.needsLicenseAcceptance =
                session.activeUseCase?.needEulaAcceptance || session.activeUseCase?.needMlaAcceptance;

            if (session.needTenantSelection || session.needsLicenseAcceptance) {
                // the proprietary authentication still needs the password to login _again_ to select the tenant.
                // and again, to accept the license.
                // when we completely switch to OAuth, this wouldn't be necessary
                // IMPORTANT: this user record should NOT get stored anywhere, password is in clear text.
                session.password = password;
            } else {
                //we have a "user" record that can be used to login offline with. Store it in the cache.
                await _p.cacheSave(omit(session, ['getAccessTokenSilently', 'logout']), password);
            }
            return _p.getUserProfileFromToken(session);
        } catch (err) {
            if (err.status != null) {
                switch (err.status) {
                    case 404: {
                        if (!err.message) {
                            err.message = standardErrorMessage;
                        } else if (
                            /Request to insert .* failed/.test(err.message) ||
                            /Request to POST .* failed/.test(err.message)
                        ) {
                            const message = err.message.replace('..', '.').split('.')[1].trim() || standardErrorMessage;
                            err.message = message;
                        }
                        break;
                    }
                    // 401: server provides descriptive message. Do nothing
                    case 400: {
                        if (err.message && err.message.includes('"userName" is not allowed to be empty')) {
                            err.message = 'An email must be provided.';
                        } else if (err.message && err.message.includes('"password" is not allowed to be empty')) {
                            err.message = 'A password must be provided.';
                        } else {
                            err.message = standardErrorMessage;
                        }
                        break;
                    }
                }
            } else if (err.message === 'Failed to fetch') {
                return _p.handleOfflineAuthenticate(payload, userName.trim(), password);
            }
            throw err;
        }
    } else {
        return _p.handleOfflineAuthenticate(payload, userName.trim(), password);
    }
}

async function handleOfflineAuthenticate(payload, userName, password) {
    //if offline, lookup in local cache
    const { tenantId, useCaseId } = payload;
    const session = await _p.cacheFind(userName, password, tenantId, useCaseId);
    if (!session) {
        throw new Error('User not available offline or incorrect password.');
    }
    return _p.getUserProfileFromToken(session);
}

/**
 * Log in using the traditional proprietary format
 */
async function authenticateProprietary(payload) {
    const { userName, ...data } = payload;
    const response = await _p.httpPost(
        '/api/security/auth',
        {
            username: userName, //it expects all lowercase, but returns with uppercase N...
            ...data
        },
        false
    );
    if (!response || !response.tokenKey) {
        throw new Error(standardErrorMessage);
    }
    const { needTenantSelection, ...token } = response;
    //after `offlineSessionExpirationDays` days offline, they will have to re-authenticate...
    const loginExpiresAt = _p.daysInTheFuture(_p.globalConfig().offlineSessionExpirationDays);
    if (needTenantSelection) {
        return {
            ...token,
            loginExpiresAt,
            needTenantSelection
        };
    }
    return {
        ...token,
        loginExpiresAt
    };
}

function daysInTheFuture(daysToAdd) {
    let someDate = new Date();
    someDate.setDate(someDate.getDate() + daysToAdd);
    return someDate;
}
