import log from '@sstdev/lib_logging';
import metadataServices from '@sstdev/lib_metadata-services';
const { roleAccessService } = metadataServices;

/**
 * @typedef {{
 *    title: string,
 *    pageId: string,
 *    type: 'page' | 'pageList' |'tab' | 'menu',
 *    tabs: ComponentAccessTarget[],
 *    actions: ComponentAccessTarget[],
 * }} PageAccessTarget
 */

/**
 *
 * @param {{hNodes:[]}} metadata
 * @param {*} pageId
 * @returns {PageAccessTarget}
 */
export function getConfigurableAccess(metadata, pageId) {
    try {
        const { accessDefinition } = roleAccessService.extractAccess(metadata);
        return accessDefinition[pageId] || { tabs: [], actions: [] };
    } catch (error) {
        log.error('Error in getConfigurableAccess', error);
        return { tabs: [], actions: [] };
    }
}
