//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _doingCancel_namespace_relation from './doingCancel_namespace_relation';
import _doingChange_namespace_relation from './doingChange_namespace_relation';
import _doingCopy_namespace_relation from './doingCopy_namespace_relation';
import _doingEdit_namespace_relation from './doingEdit_namespace_relation';
import _doingNew_namespace_relation from './doingNew_namespace_relation';
import _doingView_namespace_relation from './doingView_namespace_relation';
import _notHandledHere from './notHandledHere';
import _willEdit_namespace_relation_singleRecordForm from './willEdit_namespace_relation_singleRecordForm';
export const doingCancel_namespace_relation = _doingCancel_namespace_relation;
export const doingChange_namespace_relation = _doingChange_namespace_relation;
export const doingCopy_namespace_relation = _doingCopy_namespace_relation;
export const doingEdit_namespace_relation = _doingEdit_namespace_relation;
export const doingNew_namespace_relation = _doingNew_namespace_relation;
export const doingView_namespace_relation = _doingView_namespace_relation;
export const notHandledHere = _notHandledHere;
export const willEdit_namespace_relation_singleRecordForm = _willEdit_namespace_relation_singleRecordForm;
export default {doingCancel_namespace_relation, doingChange_namespace_relation, doingCopy_namespace_relation, doingEdit_namespace_relation, doingNew_namespace_relation, doingView_namespace_relation, notHandledHere, willEdit_namespace_relation_singleRecordForm};

