import { createElement as rc, createContext } from 'react';
import lodash from 'lodash';
const { camelCase } = lodash;
import logging from '@sstdev/lib_logging';

export const SharedFilterBoundaryContext = createContext();

// Some components (e.g. charts) need a separate dbView (as opposed to the default which is to share a view
// for anything in the current route).  This allows filters within these components to only affect the
// component's dbview.
export default function SharedFilterBoundary(props) {
    const { children, boundaryName, boundaryId } = props ?? {};
    if (boundaryName == null && boundaryId == null) {
        logging.error('For a shared filter boundary, you must set either a boundary name or boundary id.');
    }
    const context = {
        boundaryId,
        boundaryName,
        getViewName: (namespace, relation) => {
            const nameParts = [namespace, relation];
            if (boundaryName != null) {
                nameParts.push(camelCase(boundaryName));
            }
            if (boundaryId != null) {
                nameParts.push(boundaryId);
            }
            return nameParts.join('_');
        }
    };
    return rc(SharedFilterBoundaryContext.Provider, { value: context }, children);
}
