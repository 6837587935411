import { createElement as rc } from 'react';
import { fromTheme, styled, View, webOnlyStyles } from 'lib_ui-primitives';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

/* Use `display` instead of `visible` to hide non-active tabs so that they
don't take up space in the UI */
let _TabPanel = styled(View).attrs({ name: 'tab-panel' })`
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 1px;
    display: ${({ selected }) => (selected ? 'flex' : 'none')};
    flex-direction: row;
    background-color: ${fromTheme('backgroundColor')};
    border-left-width: 1px;
    border-left-color: ${fromTheme('borderColor')};
`;

_TabPanel = webOnlyStyles(_TabPanel)`
    border-left-style: solid;
`;

/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 * @property {bool} selected
 */
/** @type {import('react').FC<Props>} */
function TabPanel(props) {
    const { native } = useTheme();
    const { children, ...otherProps } = props || {};

    // Native TabViewLayout needs to rerender whenever the panel is displayed because (for
    // performance reasons) native cannot have as many controls simultaneously rendered as web.
    // Currently, the CheckIn and CheckOut pages (used by web only) use tabs and have a stateful
    // component (MultiPickerSensorGrid) on one of the tabs.  This component loses state
    // when unmounted.
    if (props.selected || !native) {
        // prettier-ignore
        return rc(_TabPanel, otherProps, children);
    }
    return null;
}

TabPanel.propTypes = {
    selected: PropTypes.bool
};
export default TabPanel;
