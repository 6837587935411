export const standardColumnDefinition = [
    // 1: AssetNo, title
    { props: { hNode: { hNodeType: 'Barcode', propertyName: 'title' } } },
    // 2: 1Description, Room Name, or First Name
    { props: { hNode: { propertyName: 'description' } } },
    // support preview for up to 10 extra fields.
    // Currently, in reality, we only support up to 6 extra:
    // 3: SerialNo, Building Name or Last Name
    // 4: Department or Job Title
    // 5: Account
    // 6: Item Type
    // 7: Item Model
    // 8: PO Number
    ...Array.from(Array(10).keys()).map(i => ({ props: { hNode: { propertyName: `extra${i + 1}` } } }))
];
/**
 * @type {Object} LabelSize
 * @property {number} width `2` - Width of label in inches
 * @property {number} height `1` - Height of label in inches
 */
export const defaultSize = { width: 2, height: 1 };

export const selectATemplate = [
    {
        isError: true,
        fixedText: 'Please select a label template'
    }
    //leaving this in as examples of non-fixed text:
    // {
    //     'print:field': {
    //         title: '2, Description, if available'
    //     }
    // },
    // {
    //     includeHumanReadable: true
    // }
];

export const emptyDesignTemplate = [
    {
        isError: true,
        fixedText: 'Please add label content'
    }
];

export const EMPTY_ARRAY = [];
