import getHNodeValueWidth from './getHNodeValueWidth';

/**
 * @typedef {ReturnType<typeof import('lib_ui-primitives').themeValues>} Theme
 */

const _p = { getHNodeValueWidth };
export const _private = _p;

/**
 * Calculates the width of a column based on its content and theme.
 *
 * @param {Object} columnHNode - The column node.
 * @param {Theme} theme - The theme object.
 * @param {number|undefined} contentLength - The length of the longest content in the column.
 * @returns {number} - The calculated width of the column.
 */
export default function getColumnWidth(columnHNode, theme, contentLength) {
    if (columnHNode == null) return 0;
    const { fontSize, columnWidthToHeightRatio } = theme;
    const maxTableColumnWidth =
        contentLength && typeof contentLength === 'number'
            ? contentLength * fontSize * columnWidthToHeightRatio
            : theme.maxTableColumnWidth;

    const columnLabel = columnHNode.children ? columnHNode.children[0].label : columnHNode.label;
    const labelWidth = columnLabel.length * fontSize * columnWidthToHeightRatio + 2 * theme.textPadding;
    let contentWidth = _p.getHNodeValueWidth(columnHNode, theme, contentLength);
    // Ensure contentWidth is a number before adding padding
    contentWidth = typeof contentWidth === 'number' ? contentWidth + 2 * theme.textPadding : 0;
    // Don't let the width be greater than the max column width
    let result = Math.min(contentWidth, maxTableColumnWidth);
    // Make it at least as long as the label though.
    result = Math.max(labelWidth, result);
    return result;
}
