//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import * as depreciationHold from './depreciation';
export const depreciation = depreciationHold;
import _didCommit_item_item_remove from './didCommit_item_item_remove';
import _didGet_item_item from './didGet_item_item';
import _doingBulk_item_item_remove from './doingBulk_item_item_remove';
import _doingGet_item_item from './doingGet_item_item';
import _doingGet_item_item_nextAutoId from './doingGet_item_item_nextAutoId';
import _doingValidate_item_item_take_at from './doingValidate_item_item_take_at';
import _willCreate_item_item from './willCreate_item_item';
import _willUpdate_item_item_inventoryArray from './willUpdate_item_item_inventoryArray';
export const didCommit_item_item_remove = _didCommit_item_item_remove;
export const didGet_item_item = _didGet_item_item;
export const doingBulk_item_item_remove = _doingBulk_item_item_remove;
export const doingGet_item_item = _doingGet_item_item;
export const doingGet_item_item_nextAutoId = _doingGet_item_item_nextAutoId;
export const doingValidate_item_item_take_at = _doingValidate_item_item_take_at;
export const willCreate_item_item = _willCreate_item_item;
export const willUpdate_item_item_inventoryArray = _willUpdate_item_item_inventoryArray;
export default {didCommit_item_item_remove, didGet_item_item, doingBulk_item_item_remove, doingGet_item_item, doingGet_item_item_nextAutoId, doingValidate_item_item_take_at, willCreate_item_item, willUpdate_item_item_inventoryArray};

