const _styleToColor = {
    AccentOne: 'accentOne',
    AccentTwo: 'accentTwo',
    CircleAdd: 'success',
    Dark: 'base',
    Light: 'base',
    Negative: 'error',
    Positive: 'success',
    Unstyled: 'transparent',
    IconAction: 'primary',
    BigIconAction: 'primary',
    Gray: 'gray'
};

export default function styleToColor(style) {
    return _styleToColor[style];
}
