import { constants } from 'lib_ui-services';
import { hooks } from 'lib_ui-primitives';
const { useBbState: bbState } = hooks;
import { differenceInMinutes } from 'date-fns';

export default {
    verb: 'didNew',
    namespace: 'item',
    relation: 'transaction',
    description: 'New a record from a previously existing record of the same namespace/relation.',
    useCaseIds: [constants.useCaseIds.FREIGHT_RUNNER],
    //this is the actual logic:
    logic: didNew
};

// new is a reserved word
async function didNew({ data, context }) {
    const {
        user: { _id: userId }
    } = context;
    const retention = {
        id: userId,
        scope: constants.retention.SCOPE.LOCAL,
        visibility: constants.retention.VISIBILITY.PER_BROWSER,
        duration: constants.retention.DURATION.ALWAYS
    };

    // lookup the count for the current user...
    const totalScans = (await bbState.getDirect('palletCounter', retention)) || 0;
    data.record.totalScans = totalScans.toString();
    //if they haven't done enough to calculate an average yet, avoid division by zero, just return 0s.
    if (!totalScans || totalScans <= 1) {
        data.record.minutesPerPallet = '0';
        data.record.palletsPerMinute = '0';
        return data;
    }

    const timeOfFirstScan = (await bbState.getDirect('firstScan', retention)) || new Date();
    const minutesSinceFirstScan = differenceInMinutes(new Date(), new Date(timeOfFirstScan)) || 1;
    // calculate the average, round to 2 decimals
    data.record.minutesPerPallet = (Math.round((minutesSinceFirstScan / totalScans) * 100) / 100).toString();
    data.record.palletsPerMinute = (Math.round((totalScans / minutesSinceFirstScan) * 100) / 100).toString();
    return data;
}
