import innerUpsert from './innerUpsert';
import lodash from 'lodash';
const { omit } = lodash;
import verifyMetaKeysPresent from './_verifyMetaKeysPresent';
import { getRelationMetadata, getTitleAlternative } from '../../metadata/index';
import iso from '@sstdev/lib_isomorphic-json-patch';
// Must destructure here because @sstdev/lib_isomorphic-json-patch is commonjs
const { appHistory, rfc6902 } = iso;
/**
 * Typical insert/create CRUD
 * @param {object} action persist action dispatched to create this insert request
 */
export default database => async (payload, context) => {
    const { namespace, relation, correlationId } = context;
    const { omitFromComparisons } = database;
    const { newRecord, useUpsert } = payload;

    const relationMetadata = getRelationMetadata(namespace, relation, false);
    const storesPatchHistory = 'patchOffline' === relationMetadata?.patchRelationType;

    if (useUpsert) {
        const result = await innerUpsert(database)(namespace, relation, newRecord);
        // result is undefined if there is a noop.
        let upsertedItem = result && result.upsertedItem;
        return database.processSuccess([upsertedItem], payload, context);
    }

    verifyMetaKeysPresent(['createdBy', 'createdTime', 'modifiedBy', 'modifiedTime'], newRecord.meta, {
        operation: 'insert',
        namespace,
        relation
    });
    await database.relationDb(namespace, relation).insert(newRecord);
    if (storesPatchHistory) {
        await insertToPatchCollection();
    }
    return database.processSuccess([], payload, context);

    async function insertToPatchCollection() {
        // Create 'add' operation patches for all fields by comparing to an empty object
        const patches = rfc6902.compare({}, omit(newRecord, omitFromComparisons));
        const history = appHistory.getPatchHistory({}, patches);
        const titleAlternative = getTitleAlternative(namespace, relation);
        const patchDoc = {
            _id: correlationId,
            [`${namespace}:${relation}`]: {
                _id: newRecord._id,
                [titleAlternative]: newRecord[titleAlternative]
            },
            patches: history,
            meta: {
                modifiedTime: newRecord.meta.modifiedTime,
                modifiedBy: newRecord.meta.modifiedBy
            }
        };
        await database.relationDb(namespace, relation + '-patch').insert(patchDoc);
    }
};
