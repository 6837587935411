import { forwardRef, useMemo, useCallback, useEffect, createElement as rc, useState, useRef } from 'react';
import { Hints as _Hints, contexts, hooks } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';
import useEventSink from '../../hooks/useEventSink';
import lodash from 'lodash';
const { isEqual } = lodash;
import propTypes from 'prop-types';
import logging from '@sstdev/lib_logging';

const { useBbState } = hooks;

const { HintsContext } = contexts;
const { SCOPE, VISIBILITY, DURATION } = constants.retention;

const Hints = forwardRef((props, _ref) => {
    const hintsById = useRef({});
    const [hints, setHints] = useState([]);
    const [uiLoaded, setUiLoaded] = useState(false);
    const retention = { scope: SCOPE.GLOBAL, visibility: VISIBILITY.PER_BROWSER, duration: DURATION.ALWAYS };
    const [hintsClosed, setHintsClosed, ready] = useBbState([], 'hintsClosed', retention);
    const [route, setRoute] = useState();
    const [subscribe] = useEventSink();

    // Access the introJs regular (i.e. not react specific) API by getting from the
    // intro.js-react Hints component reference
    const introJs = useRef();
    const ref = useMemo(
        () => hintsElement => {
            introJs.current = hintsElement?.introJs;
            if ((_ref != null) & (typeof _ref === 'function')) {
                _ref(hintsElement);
            }
        },
        [_ref]
    );

    useEffect(() => {
        if (uiLoaded) {
            introJs.current.setOption('hintButtonLabel', 'OK');
        }
    }, [uiLoaded]);
    useEffect(() => {
        return subscribe(
            { verb: 'navigate', namespace: 'application', relation: 'route', status: 'success' },
            routeInfo => {
                setRoute(routeInfo.navigate.router.location.pathname);
            }
        );
    }, [subscribe, hints]);

    useEffect(() => {
        subscribe({ verb: 'change', namespace: 'application', relation: 'runtime' }, runtime => {
            if (runtime.initialRenderComplete) {
                setUiLoaded(true);
            }
        });
    }, [subscribe]);

    const onClose = useCallback(
        function (index) {
            if (!ready) {
                throw new Error('Timing problem: hintsClosed is not ready yet. ');
            }
            // get the id back out of introJs. This avoids the problem of outdated hints
            // state.  Can't get state using function update inside setHints because that
            // would require setHintsClosed to be nested inside setHints.
            const hints = introJs.current._hintItems ?? [];
            const id = hints[index].id;
            delete hintsById.current[id];
            const newHints = Object.values(hintsById.current);
            logging.info(`[HINTS] onClose - ${JSON.stringify(newHints, null, 3)}`);
            setHints(newHints);
            setHintsClosed(hintsClosed => {
                if (hintsClosed.indexOf(id) < 0) {
                    return [...hintsClosed, id];
                }
            });
        },
        [ready, setHintsClosed]
    );

    const addHint = useCallback((hNode, route) => {
        const { hint, id } = hNode;
        if (hint == null || hint.length === 0) return;
        const existingHint = hintsById.current[id];
        const newHint = { element: `#${id}`, hint, id, route };
        if (existingHint && isEqual(existingHint, newHint)) return;

        hintsById.current[id] = newHint;

        const newHints = Object.values(hintsById.current);
        logging.info(`[HINTS] addHint - ${JSON.stringify(newHints, null, 3)}`);
        setHints(newHints);
    }, []);

    const context = useMemo(
        () => ({
            addHint
        }),
        [addHint]
    );

    // The hints were disappearing when navigating back to a page which had previously rendered.
    // Also, the hints would sometimes hang around when logging out.  This fixes both those
    // problems.
    const unclosedHints = useMemo(() => {
        return hints.filter(h => !hintsClosed.includes(h.id) && h.route === route);
    }, [hintsClosed, hints, route]);

    // prettier-ignore
    return rc(HintsContext.Provider, { value: context },
        uiLoaded && rc(_Hints, { ref, hints: unclosedHints, enabled: uiLoaded, onClose }),
        props.children
    );
});
Hints.displayName = 'Hints';

Hints.propTypes = {
    children: propTypes.oneOfType([propTypes.arrayOf(propTypes.element), propTypes.element])
};
export default Hints;
