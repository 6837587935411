import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import styled from '../styled';
import fromTheme from '../fromTheme';
import View from './View';
import Text from './Text';
import nativeOnlyStyles from '../nativeOnlyStyles';

const ErrorText = styled(Text)`
    color: ${fromTheme('errorFontColor')};
    margin-bottom: ${fromTheme('textMargin')};
`;

let Container = styled(View)`
    flex-direction: column;
`;
Container = nativeOnlyStyles(Container)`
    padding-left: 4px;
`;

/**
 * @typedef {Object} Props
 * @property {(string|Error)[]} errors
 */
/** @type import('react').FC<Props> */
const ValidationError = props => {
    const { errors } = props;
    if (!errors?.length) return null;

    return rc(
        Container,
        null,
        errors.map((error, i) => rc(ErrorText, { key: `error${i}` }, toText(error)))
    );
};
const toText = error => (error instanceof Error ? error.message : error);

ValidationError.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(Error), PropTypes.string]))
};

export default ValidationError;
