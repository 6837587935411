/**
 * @typedef {object} TreePosition
 * @property {number} sequence - the sequence of the tree node
 * @property {number} minSequence - the minimum sequence allowed inside this tree node
 * @property {number} maxSequence - the maximum sequence allowed inside this tree node
 */

/**
 * @callback TreePositionGetter
 * @param {number} - index of the child tree node
 * @returns {number} - sequence of the child tree node
 */

/**
 * Create a function that will give a unique treePosition object based on the
 * parent treePosition and the number of children.  It should evenly divide the
 * available sequences positions given by the parent, allowing the children's
 * children to have integer space to do the same thing.
 *
 * It is mostly only used for hNodes that can receive focus -- typically those that
 * resolve down to one or more TextInput or TextInput.native primitive components.
 *
 * @example (using maxSequence of 1000 to make it easier to read)
 * Get two TreePositions for two children nodes
    {"depth":1,"sequence":2,"minSequence":3,"maxSequence":500,"diff":497}
    {"depth":1,"sequence":501,"minSequence":502,"maxSequence":1000,"diff":498}
 * Take the last TreePosition of the previous calc and use it as the parent
 * to get TreePositions for three more child nodes.
    {"depth":2,"sequence":502,"minSequence":503,"maxSequence":667,"diff":164}
    {"depth":2,"sequence":668,"minSequence":669,"maxSequence":833,"diff":164}
    {"depth":2,"sequence":834,"minSequence":835,"maxSequence":1000,"diff":165}
 * Take the last TreePosition of the previous calc and use it as the parent
 * to get TreePositions for four more child nodes.
    {"depth":3,"sequence":835,"minSequence":836,"maxSequence":875,"diff":39}
    {"depth":3,"sequence":876,"minSequence":877,"maxSequence":916,"diff":39}
    {"depth":3,"sequence":917,"minSequence":918,"maxSequence":957,"diff":39}
    {"depth":3,"sequence":958,"minSequence":959,"maxSequence":1000,"diff":41}

 * Note:
 * To make it a little easier to reason about, this currently just uses
 * integers, distributing the position sequences among all possible safe integers
 * in Javascript.  Because each increase of depth divides the available space by
 * the number of children, it is possible to run out, but it would have to be a
 * pretty deep tree I think.
 * If 9007199254740991 is the max safe integer and the average number of children
 * per tier is 2, I think it is
 * log2(9007199254740991) === 53
 * So 53 levels deep, but it goes a lot faster if there are a bunch of children
 * in each tier. If that happens, then it might make sense to refactor this to
 * use rational numbers instead.
 *
 * @param {TreePosition | object} hNodeOrParentTreePosition - overloaded - can be an hNode or the treePosition of the parent
 * @param {number} [numberOfChildren] - if not passing an hNode in the first param, need to know how many children will be in the parent
 * @returns {TreePositionGetter}
 */
export default function createTreePositionGetter(hNodeOrParentTreePosition, numberOfChildren) {
    // Duck typing to differentiate overloads.
    if (numberOfChildren != null) {
        const parentTreePosition = hNodeOrParentTreePosition;
        return creator(parentTreePosition, numberOfChildren);
    }
    const hNode = hNodeOrParentTreePosition;

    return creator(hNode.treePosition, hNode.children.length);
}

const memoizePosition = {};

/**
 *
 * @param {TreePosition} parentTreePosition
 * @param {number} [parentTreePosition.depth]
 * @param {number} parentTreePosition.sequence - the sequence of the parent
 * @param {number} parentTreePosition.minSequence
 * @param {number} parentTreePosition.maxSequence
 * @param {number} numberOfChildren
 * @returns {TreePosition}
 */
function creator(parentTreePosition, numberOfChildren) {
    const { maxSequence, minSequence, depth } = parentTreePosition ?? {
        depth: 0,
        sequence: 0,
        minSequence: 1,
        maxSequence: Number.MAX_SAFE_INTEGER
    };
    if (minSequence < 1) {
        throw new Error(`Invalid minimum tree position sequence: ${minSequence}`);
    }
    if (maxSequence < minSequence) {
        throw new Error(
            `The maximum tree position sequence (${maxSequence}) cannot be less than the minimum (${minSequence}).`
        );
    }
    // Hopefully this never happens.
    if (maxSequence - minSequence < numberOfChildren) {
        throw new Error(
            'All the available UI element sequences are exhausted for this parent.  createTreePositionGetter may need to be refactored to allow rational numbers instead of just integers.'
        );
    }

    /**
     * @param {number}
     * @returns {TreePosition}
     */
    return index => {
        // This is to avoid creating new references so that react doesn't rerender.
        const simpleKey = [index, minSequence, maxSequence, numberOfChildren, depth].join('|');
        if (memoizePosition[simpleKey] != null) return memoizePosition[simpleKey];
        memoizePosition[simpleKey] = {
            depth: depth + 1,
            ...cycleThrough(minSequence, maxSequence, 0, index, numberOfChildren)
        };
        return memoizePosition[simpleKey];
    };
}

// Avoid losses due to rounding by cycling down through results for each child,
// using the previous result's max as a starting min, until you reach the desired
// index.  This isn't particularly efficient, but it is pretty consistent.
function cycleThrough(min, max, currentIndex, desiredIndex, numberOfChildren) {
    // Split the remaining sequences over the remaining indexes, subtracting one for
    // the position belonging to this index.
    if (numberOfChildren - currentIndex === 0) {
        throw new Error('The remaining number of children (to split across remaining focus indexes) cannot be zero.');
    }
    const interval = Math.floor((max - min) / (numberOfChildren - currentIndex)) - 1;
    if (isNaN(interval)) {
        throw new Error('Something went wrong.  Interval is NaN.');
    }
    // If we've recursed down to the desired index, return the resulting TreePosition
    if (currentIndex === desiredIndex) {
        // If this is the last index, return the parent max as the max for this index
        // to avoid rounding errors.
        if (desiredIndex === numberOfChildren - 1) {
            return { sequence: min, minSequence: min + 1, maxSequence: max, numberOfChildren, desiredIndex };
        }
        return { sequence: min, minSequence: min + 1, maxSequence: min + interval, numberOfChildren, desiredIndex };
    } else {
        // Recurse down to the next index.
        return cycleThrough(min + interval + 1, max, currentIndex + 1, desiredIndex, numberOfChildren);
    }
}
