import { constants, metadata } from 'lib_ui-services';
import getLocationInfo from '../getLocationInfo';

const { useCaseIds, UNKNOWN_TAG } = constants;

export default {
    verb: 'willNew',
    namespace: 'item',
    relation: 'item',
    description: 'Remove "<Unknown Tag>" text and prepopulate location info if available.',
    useCaseIds: [useCaseIds.ASSET_TRACKING, useCaseIds.AMERICAN_WATER_ASSET_TRACKING],
    //this is the actual logic:
    logic: willNew
};
const _p = {
    getLocationInfo
};
export const _private = _p;
async function willNew({ data, context, dispatch }) {
    // Unknown tags are given some default title text when scanning so they
    // will not appear as blank space in the UI.  Remove that before editing.
    const titleAlternative = metadata.getTitleAlternative(context.namespace, context.relation, 'title');
    if (data?.newRecord?.[titleAlternative]?.startsWith(`<${UNKNOWN_TAG}`)) {
        delete data.newRecord[titleAlternative];
    }
    // If a room location id is passed in, then populate all the location info
    if (data.locationId) {
        const locationInfo = await _p.getLocationInfo(data.locationId, dispatch);
        data.newRecord = {
            ...data.newRecord,
            ...locationInfo
        };
    }

    return data;
}
