import { useCallback, useRef, createElement as rc, useState, useEffect, Fragment, useMemo } from 'react';
import { constants, globalConfig, orderedSet } from 'lib_ui-services';
import useEventSink from '../../hooks/useEventSink';
import lodash from 'lodash';
const { isEqual } = lodash;
const { uniq } = lodash;
import logging from '@sstdev/lib_logging';
import { useRouteReadOwnership } from './RouteReadOwnershipProvider';
import { contexts, hooks } from 'lib_ui-primitives';

const { useFeatureFlags } = hooks;
const { ReadContext } = contexts;
/**
 * @typedef {Object} Read
 * @property {'RFID'|'MANUAL'|'BARCODE'|'NONE'|'ANY'|'BLE'} [sensorType] Source of scan. _should_ never be NONE or ANY
 * @property {string} tagId HEX string representation of read
 * @property {string} asciiTagId ASCII string representation of read
 * @property {number} [rssi] rssi value (negative value between -100 and 0), only for RFID
 * @property {number} [batteryLevel] battery level of BLE tag (value between 0 and 100), only for BLE
 */

const EMPTY_ARRAY = [];
const RECORD_SEPARATOR = String.fromCharCode(30);
const _p = { useFeatureFlags };
export const _private = _p;
export default function ReadProvider(props) {
    const { id, desiredSensorTypes = EMPTY_ARRAY, children, active = true, debugText } = props ?? { id: 'noprops' };
    if (id == null) {
        throw new Error(
            'ReadProvider must be provided a unique id - try using the hNode.id for the containing component.'
        );
    }
    useEffect(() => {
        debugText && logging.debug(`[READ_CONTEXT] Mounting read provider for ${id}. Debug text: ${debugText}`);
        return () =>
            debugText && logging.debug(`[READ_CONTEXT] Unmounting read provider for ${id}. Debug text: ${debugText}`);
    }, [debugText, id]);
    const displayInAscii = _p.useFeatureFlags()?.includes('displayInAscii') ?? false;
    const reads = useRef(orderedSet([], ['tagId']));
    const [reading, setReading] = useState(false);
    const [sensorTypesAvailable, setSensorTypesAvailable] = useState([]);
    const requestedSensorTypes = useRef([]);

    //Holds the read object for the most recent Barcode or manually entered read
    const [mostRecentIndividualRead, setMostRecentIndividualRead] = useState();
    const [subscribe, publish] = useEventSink();

    // ReadProvider might be called by a formElement (for instance) that does not desire to
    // capture reads.  This avoids taking ownership of sensors in that case.
    const skip = desiredSensorTypes.length === 0;

    const routeReadOwnership = useRouteReadOwnership();

    // Take ownership of desired sensor types
    useEffect(() => {
        if (!skip && routeReadOwnership != null && active) {
            // On unmount or change of active status, this will return sensor ownership
            // to the previous owners.
            return routeReadOwnership.takeOwnership(desiredSensorTypes, id, debugText);
        }
    }, [active, desiredSensorTypes, id, skip, routeReadOwnership, debugText]);

    // Keep track of unmounting so we can pass this as part of the result;
    const canUpdateState = useRef(true);
    useEffect(() => {
        return () => (canUpdateState.current = false);
    }, []);

    const addReads = useCallback(
        function addReads(newReads) {
            //nothing left to process
            if (newReads == null || !newReads.length || !canUpdateState) return;

            if (!Array.isArray(newReads)) {
                newReads = [newReads];
            }
            // The sensorType should stay the same for each batch, so ignore them all if the
            // first does not match.
            const { sensorType } = newReads[0];
            if (!active || (routeReadOwnership != null && !routeReadOwnership.isOwner(sensorType, id))) {
                return;
            }

            let lastRead;
            newReads.forEach(read => {
                let tagId = read.tagId;
                let asciiTagId = read.asciiTagId;
                let sensorType = read.sensorType;

                //assure proper sensor type
                if (!sensorType) {
                    logging.error(new Error('[READ_CONTEXT] Received a read without sensorType'));
                    return;
                } else if ([constants.sensorTypes.NONE, constants.sensorTypes.ANY].includes(sensorType)) {
                    logging.error(
                        new Error(`[READ_CONTEXT] Please specify an explicit sensorType instead of ${sensorType}`)
                    );
                    return;
                }

                //assure proper hex and ascii data
                if (!tagId && !asciiTagId) {
                    if (tagId === '') {
                        logging.warn('[READ_CONTEXT] Detected RFID tag with empty EPC value');
                        return;
                    }
                    logging.error(new Error('[READ_CONTEXT] Received a read without hex or ascii value'));
                    return;
                }

                logging.debug(
                    `[READ_CONTEXT] Received new ${sensorType} read ${tagId ?? asciiTagId} in ReadProvider ${id}`
                );

                //recognize datawedge barcode scans
                if (sensorType === constants.sensorTypes.MANUAL && asciiTagId.includes(RECORD_SEPARATOR)) {
                    asciiTagId = asciiTagId.replace(RECORD_SEPARATOR, '');
                    sensorType = constants.sensorTypes.BARCODE;
                }

                // Ensure tagId is populated (it is used for lookup index for reads)
                if (tagId == null) read.tagId = asciiTagId;

                // Strip unnecessary zeroes from RFID reads
                // for more info see https://docs.sstid.com/en/development/techDocs/rfidTagEncoding
                if ([constants.sensorTypes.RFID, constants.sensorTypes.BLE].includes(read.sensorType)) {
                    if (displayInAscii) {
                        // strip null characters if this value is known to translate to ascii
                        read.tagId = read.tagId.replace(/^(00)+/, '').replace(/(00)+$/, '');
                    } else {
                        // otherwise, strip leading zeros (they are not significant digits)
                        read.tagId = read.tagId.replace(/^0+/, '');
                    }
                }
                const added = reads.current.add(read);
                if (!added && [constants.sensorTypes.MANUAL, constants.sensorTypes.BARCODE].includes(read.sensorType)) {
                    publish(
                        { isError: true, message: `${read.tagId} already added.` },
                        { verb: 'pop', namespace: 'application', relation: 'notification' }
                    );
                }
                lastRead = read;
            });

            if (
                lastRead != null &&
                [constants.sensorTypes.MANUAL, constants.sensorTypes.BARCODE].includes(sensorType)
            ) {
                setMostRecentIndividualRead(previous => {
                    // avoid rerender here if possible
                    if (previous?.tagId !== lastRead.tagId) {
                        return lastRead;
                    }
                    return previous;
                });
            }
        },
        [id, routeReadOwnership, active, publish, displayInAscii]
    );

    const removeRead = useCallback(function removeRead(read) {
        reads.current.remove('tagId', read.tagId);
        setMostRecentIndividualRead(previous => {
            if (previous?.tagId === read.tagId) {
                return undefined;
            }
            return previous;
        });
    }, []);

    const reset = useCallback(function reset() {
        logging.debug('[READ_CONTEXT] Clearing all reads');
        setMostRecentIndividualRead(undefined);
        reads.current.reset();
    }, []);

    useEffect(() => {
        if (skip) return;

        const unsubscribes = [
            subscribe({ verb: 'change', namespace: 'sensor', relation: 'read' }, addReads),
            subscribe({ verb: 'reset', namespace: 'sensor', relation: 'read' }, reset),
            subscribe({ verb: 'remove', namespace: 'sensor', relation: 'read' }, removeRead),
            // When a successful sensor read is started set reading to true
            subscribe(
                {
                    verb: 'startup',
                    namespace: 'sensor',
                    relation: 'read',
                    status: 'success'
                },
                ({ sensorType }) => {
                    if (desiredSensorTypes.includes(sensorType)) {
                        setReading(true);
                    }
                }
            ),
            // When a successful sensor read is stopped set reading to false
            subscribe(
                {
                    verb: 'stop',
                    namespace: 'sensor',
                    relation: 'read',
                    status: 'success'
                },
                ({ sensorType }) => {
                    if (desiredSensorTypes.includes(sensorType)) {
                        setReading(false);
                    }
                }
            )
        ];

        return () => unsubscribes.forEach(unsubscribe => unsubscribe());
    }, [subscribe, skip, addReads, reset, removeRead, desiredSensorTypes]);

    useEffect(() => {
        const unsubscribes = [
            // Once the sensor service has started, get the latest read state
            subscribe({ verb: 'startup', namespace: 'sensor', relation: 'service', status: 'success' }, payload => {
                if (payload != null) {
                    const { sensorTypesAvailable = [] } = payload;
                    setSensorTypesAvailable(prev => {
                        const unique = uniq([...prev, ...sensorTypesAvailable]);
                        if (!isEqual(unique, prev)) {
                            return unique;
                        }
                        return prev;
                    });
                }
            })
        ];

        return () => {
            unsubscribes.forEach(unsubscribe => unsubscribe());
        };
    }, [subscribe]);

    const requestSensorTypes = useCallback(
        sensorTypes => {
            sensorTypes.forEach(sensorType => {
                if (requestedSensorTypes.current.includes(sensorType)) return;
                requestedSensorTypes.current.push(sensorType);
                publish(
                    {
                        sensorType,
                        scanType: 'OnRequest',
                        intervalMilliseconds: globalConfig().sensorScanIntervalMilliseconds
                    },
                    { verb: 'startup', namespace: 'sensor', relation: 'service' }
                );
            });
        },
        [publish]
    );

    const context = useMemo(
        () => ({
            sensorTypesAvailable,
            requestSensorTypes,
            reads: reads.current,
            mostRecentIndividualRead,
            setMostRecentIndividualRead,
            addRead: addReads,
            removeRead,
            reset,
            reading,
            id
        }),
        [
            sensorTypesAvailable,
            requestSensorTypes,
            mostRecentIndividualRead,
            setMostRecentIndividualRead,
            addReads,
            removeRead,
            reset,
            reading,
            id
        ]
    );

    if (skip) return rc(Fragment, null, children);

    return rc(ReadContext.Provider, { value: context }, children);
}
