export default {
    verb: 'didRollback',
    namespace: 'application',
    relation: 'reset',
    description: 'Notify user of the error that caused the rollback, and restage the change.',
    //this is the actual logic:
    logic: didRollback
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function didRollback({ data, dispatch }) {
    // Pull together a notification message about the failure.
    // Try to get the original failure message.
    let message = data?.originalMessage ?? data?.message ?? '';
    // Create a general message, and append a more specific one if available.
    message = `Application Reset was unsuccessful. ${message}`;
    dispatch(
        { message, timeout: 5000, addToList: true, isError: true },
        {
            verb: 'pop',
            namespace: 'application',
            relation: 'notification'
        }
    );
}
