import { globalConfig, http } from 'lib_ui-services';
import encryption from '@sstdev/lib_encryption';

export const _private = { post: http.post };
export default {
    verb: 'doingCreate',
    namespace: 'security',
    relation: 'profile',
    type: 'setOfflinePin',
    logic
};

async function logic({ data }) {
    const { offlinePin } = data?.newRecord ?? {};
    if (offlinePin == null) {
        throw new Error('Offline PIN is required, but missing.');
    }

    // encrypt the pin
    const salt = Math.floor(Math.random() * 100000).toString();
    const encryptedPin = await encryption.bcrypt.encrypt(offlinePin.toString() + salt);

    // update the server with the new pin
    await UpdateServerWithNewPin(encryptedPin, salt);

    return { ...data, encryptedPin, salt };
}

async function UpdateServerWithNewPin(offlinePin, salt) {
    const { protocol, hostname: host } = globalConfig();
    const protocolAndHost = `${protocol}//${host}`;
    let url = '/api/security/setPin';
    return _private.post(url, { offlinePin, salt, protocolAndHost });
}
