import { createElement as rc } from 'react';
import { View, h2, styled } from 'lib_ui-primitives';
import Chart from './Chart';

const Stack = styled(View).attrs({ name: 'TotalsChart' })`
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-evenly;
`;

const Humongous = styled(h2)`
    font-family: Arial;
    font-size: 75pt;
    font-weight: 900;
`;

const FatH2 = styled(h2)`
    font-family: Arial;
    font-weight: 900;
`;

function TotalsChart(props) {
    const { data = [] } = props || {};
    if (data.length === 1) {
        return rc(Humongous, null, data[0].count);
    }
    return rc(
        Stack,
        null,
        data.map((result, i) => rc(FatH2, { key: `total${i}` }, `${result._id.title}: ${result.count}`))
    );
}

function TotalsElement(props) {
    return Chart(TotalsChart, props);
}

export default TotalsElement;
