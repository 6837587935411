import { createElement as rc, useState, useEffect, useMemo, useCallback } from 'react';
import { View, styled, ScrollView, fromTheme, Caret } from 'lib_ui-primitives';
import MenuEntry from './MenuEntry';
import useEventSink from '../../../hooks/useEventSink';
import useDbView from '../../../hooks/useDbView';
import { ObjectId } from 'lib_ui-services';
import { ToggleButtons } from './ToggleButtons';
import lodash from 'lodash';
const { isEqual, cloneDeep } = lodash;

const CaretWithMargin = styled(Caret).attrs({ name: 'caret-with-margin' })`
    margin: ${fromTheme('viewMarginMore')};
`;
CaretWithMargin.displayName = 'CaretWithMargin';

const MenuEditorContainer = styled(View).attrs({ name: 'menu-container' })`
    flex-direction: column;
    background-color: #5c5c60;
    flex-grow: 0;
`;
MenuEditorContainer.displayName = 'MenuEditorContainer';

const MenuContainer = styled(ScrollView).attrs({ name: 'menu-container' })`
    flex-grow: 0;
`;
MenuContainer.displayName = 'MenuContainer';

const FilterContainer = styled(View)`
    flex-direction: row;
    flex-shrink: 0;
    flex-grow: 0;
    justify-content: space-around;
    background-color: ${fromTheme('backgroundColorDarker')};
`;
FilterContainer.displayName = 'FilterContainer';

const _p = {
    useDbView
};
const splashFilter = {
    id: 'menuEditor',
    children: [
        {
            hNodeTypeGroup: 'filter',
            hNodeType: 'foreignRelationSplashRecord',
            namespace: 'metadata',
            relation: 'useCaseDetail'
        }
    ]
};

function MenuEditor(props) {
    const { hNode: { id, namespace = 'metadata', relation = 'navigation' } = {} } = props || { hNode: {} };

    const [filter, setFilter] = useState({});
    const [subscribe, publish] = useEventSink();
    const [data, setData] = useState({ children: [] });
    const { records } = _p.useDbView(namespace, relation, undefined, splashFilter);
    useEffect(() => {
        if (records?.length) {
            setData(prev => {
                if (isEqual(prev, records[0])) {
                    return prev;
                }
                return records[0];
            });
        }
    }, [records]);

    const saveNavigation = useCallback(
        newNavigation => {
            publish({ oldRecord: data, newRecord: newNavigation }, { verb: 'update', namespace, relation });
        },
        [publish, data, namespace, relation]
    );

    useEffect(() => {
        const unsubscribes = [
            subscribe({ verb: 'change', namespace, relation: 'navigation', type: 'addAbove' }, ({ _id }) => {
                setData(prev => {
                    const newNav = cloneDeep(prev);
                    const [parent, childIndex] = findParent(_id, newNav);

                    parent.children.splice(childIndex, 0, {
                        _id: ObjectId(),
                        title: '<New Page>',
                        isNavigationLink: true
                    });
                    //we will also need to add the metadata:page here!
                    saveNavigation(newNav);
                    return newNav;
                });
            }),
            subscribe({ verb: 'change', namespace, relation: 'navigation', type: 'addBelow' }, ({ _id }) => {
                setData(prev => {
                    const newNav = cloneDeep(prev);
                    const [parent, childIndex] = findParent(_id, newNav);
                    parent.children.splice(childIndex + 1, 0, {
                        _id: ObjectId(),
                        title: '<New Page>',
                        isNavigationLink: true
                    });
                    //we will also need to add the metadata:page here!
                    saveNavigation(newNav);
                    return newNav;
                });
            }),
            subscribe({ verb: 'change', namespace, relation: 'navigation', type: 'remove' }, async record => {
                if (record._isMenuEntryOnly) {
                    const okToDelete = await new Promise(resolve => {
                        publish(
                            {
                                message: `Are you sure you wish to delete the ${record.title} Menu Entry?`,
                                okButtonText: 'OK',
                                cancelButtonText: 'CANCEL',
                                okAction: () => resolve(true),
                                cancelAction: () => resolve(false)
                            },
                            { verb: 'confirm', namespace: 'application', relation: 'user' }
                        );
                    });
                    if (okToDelete) {
                        setData(prev => {
                            const newNav = cloneDeep(prev);
                            const [parent, childIndex] = findParent(record._id, newNav);
                            parent.children.splice(childIndex, 1);
                            saveNavigation(newNav);
                            return newNav;
                        });
                    }
                } else {
                    //if it is an actual record in database, run it through the rules engine
                    publish({ record, confirmed: true }, { verb: 'remove', namespace: 'metadata', relation: 'page' });
                }
            }),
            subscribe({ verb: 'change', namespace, relation: 'navigation', type: 'moveUp' }, ({ _id }) => {
                setData(prev => {
                    const newNav = cloneDeep(prev);
                    const [parent, childIndex] = findParent(_id, newNav);
                    const [movingPage] = parent.children.splice(childIndex, 1);
                    //if the one above this one is a subMenu, not a page
                    if (parent.children[childIndex - 1].children) {
                        //move it into that sub menu
                        parent.children[childIndex - 1].children.push(movingPage);
                    } else {
                        parent.children.splice(childIndex - 1, 0, movingPage);
                    }
                    saveNavigation(newNav);
                    return newNav;
                });
            }),
            subscribe({ verb: 'change', namespace, relation: 'navigation', type: 'moveDown' }, ({ _id }) => {
                setData(prev => {
                    const newNav = cloneDeep(prev);
                    const [parent, childIndex] = findParent(_id, newNav);
                    const [movingPage] = parent.children.splice(childIndex, 1);
                    parent.children.splice(childIndex + 1, 0, movingPage);

                    saveNavigation(newNav);
                    return newNav;
                });
            }),
            subscribe({ verb: 'change', namespace, relation: 'navigation', type: 'unNest' }, ({ _id }) => {
                setData(prev => {
                    const newNav = cloneDeep(prev);
                    const [parent, childIndex] = findParent(_id, newNav);
                    const [grandParent, parentIndex] = findParent(parent._id, newNav);
                    const [movingPage] = parent.children.splice(childIndex, 1);
                    grandParent.children.splice(parentIndex, 0, movingPage);

                    saveNavigation(newNav);
                    return newNav;
                });
            }),
            subscribe({ verb: 'change', namespace, relation: 'navigation', type: 'nestDeeper' }, ({ _id }) => {
                setData(prev => {
                    const newNav = cloneDeep(prev);
                    const [parent, childIndex] = findParent(_id, newNav);
                    const movingPage = parent.children[childIndex];
                    const newMenuLevel = { _id: ObjectId(), title: '<New Menu>', children: [movingPage] };
                    parent.children.splice(childIndex, 1, newMenuLevel);

                    saveNavigation(newNav);
                    return newNav;
                });
            })
        ];
        return () => unsubscribes.map(u => u());
    }, [subscribe, namespace, data, saveNavigation, publish]);

    const filteredData = useMemo(() => {
        return { ...filter, children: filterChildren(data.children, filter) };
    }, [filter, data]);

    const [isCollapsed, setIsCollapsed] = useState(false);
    if (isCollapsed) {
        return rc(CaretWithMargin, { id, isOpen: !isCollapsed, onClick: () => setIsCollapsed(false) });
    }
    // prettier-ignore
    return rc(MenuEditorContainer, null,
        rc(FilterContainer,null,
            rc(CaretWithMargin, { id, isOpen: !isCollapsed, onClick: () => setIsCollapsed(true) }),
            rc(ToggleButtons,{
                onChange:val=>setFilter(prev =>({...prev, network:val})),
                options:[
                    {key:'OFFLINE', icon:'signal_cellular_nodata'},
                    {key:'ONLINE', icon:'signal_cellular_alt'}
                ]
            }),
            rc(ToggleButtons,{
                onChange:val=>setFilter(prev =>({...prev, viewport:val})),
                options:[
                    {key:'MOBILE', icon:'smartphone'},
                    {key:'TABLET', icon:'tablet'},
                    {key:'DESKTOP', icon:'desktop_windows'}
                ]
            }),
            rc(ToggleButtons,{
                onChange:val=>setFilter(prev =>({...prev, role:val})),
                options:[
                    {key:'USER', icon:'groups'},
                    {key:'ADMIN', icon:'supervisor_account'},
                    {key:'SUPPORT', icon:'person'}
                ]
            })
        ),
        rc(MenuContainer, {scrollDirection:'vertical'},
            filteredData.children.map((menuEntry,i) => {
                return rc(MenuEntry, {
                    key: (menuEntry._id || i),
                    data: menuEntry,
                    namespace,
                    relation,
                    topLevel:true,
                    isFirst:i===0,
                    isLast:i===data.children.length-1
                });
            })
        )
    );
}

function findParent(_id, nav) {
    if (!nav.children) return null;
    const childIndex = nav.children.findIndex(c => c._id === _id);
    if (childIndex > -1) {
        return [nav, childIndex];
    }
    for (const child of nav.children) {
        const result = findParent(_id, child);
        if (result) return result;
    }
    return null;
}

function filterChildren(children, filter = {}) {
    const { network, role, viewport } = filter;
    let result = children;
    if (network) {
        result = result
            .filter(child => !child.hideWhen || child.hideWhen === network)
            .map(child => (child.children ? { ...child, children: filterChildren(child.children, filter) } : child));
    }
    if (role) {
        result = result
            .filter(child => !child.hiddenFor || child.hiddenFor.every(({ hiddenfor }) => hiddenfor !== role))
            .map(child => (child.children ? { ...child, children: filterChildren(child.children, filter) } : child));
    }
    if (viewport) {
        result = result
            .filter(
                child =>
                    (!child.showForViewPort ||
                        child.showForViewPort.some(({ showforviewport }) => showforviewport === viewport)) &&
                    (!child.hideForViewPort ||
                        child.hideForViewPort.every(({ hideforviewport }) => hideforviewport !== viewport))
            )
            .map(child => (child.children ? { ...child, children: filterChildren(child.children, filter) } : child));
    }
    return result;
}

export default MenuEditor;
