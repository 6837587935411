import logging from '@sstdev/lib_logging';

export default _private => {
    const { startPromise, collection } = _private;
    return maxSize =>
        startPromise.then(
            () =>
                new Promise((resolve, reject) => {
                    try {
                        let deleted = 0;
                        // This should use an index to move backwards in the collection
                        // removing documents until the maxSize is achieved.
                        const data = collection.chain().simplesort('meta.serverModifiedTime', { desc: true }).data();
                        for (let i = data.length - 1; i >= maxSize; i--) {
                            try {
                                const doc = data[i];
                                // make sure we actually have a document to delete
                                if (doc && typeof doc === 'object') {
                                    collection.remove(doc);
                                    deleted++;
                                }
                            } catch (err) {
                                logging.error(err);
                            }
                        }
                        resolve({ deleted });
                    } catch (err) {
                        reject(err);
                    }
                })
        );
};
