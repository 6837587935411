import { useRef, useCallback } from 'react';

/**
 * Prefers to return a passed ref (passedRef0).
 * Otherwise, creates one and returns that instead.
 * If the passed ref is a function ref, this returns its own
 * function ref which will capture the
 * reference node for use in the component consuming this
 * hook (putting it in a 'current' property on itself),
 * but will also pass on the reference node to the
 * original passed function ref.
 * @param {object} [passedRef] - a react ref
 * @returns - a react ref
 */
export default function useEnsureRef(passedRef) {
    const localNormalRef = useRef();
    // This function ref will capture the node for local use
    // but also call the function of the original passed ref.
    const localFunctionRef = useCallback(
        node => {
            // For using ref locally
            localFunctionRef.current = node;
            passedRef(node);
        },
        [passedRef]
    );

    // return localFunctionRef if passedRef is a function ref
    if (typeof passedRef === 'function') {
        return localFunctionRef;
    } else if (passedRef != null) {
        // If the passed ref is just a normal ref return that.
        return passedRef;
    }
    // Otherwise return a new ref
    return localNormalRef;
}
