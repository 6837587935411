//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _combineMetadata from './combineMetadata';
import _lockedVersion from './lockedVersion';
export const combineMetadata = _combineMetadata;
export const lockedVersion = _lockedVersion;
export default {combineMetadata, lockedVersion};

