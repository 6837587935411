//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _MeatballProfileLayout from './MeatballProfileLayout';
import _Menu from './Menu';
export const MeatballProfileLayout = _MeatballProfileLayout;
export const Menu = _Menu;
export default {MeatballProfileLayout, Menu};

