import { createElement as rc, useCallback } from 'react';
import MultiPickerDropDown from '../_abstractComponent/MultiPickerDropDown';
import { metadata } from 'lib_ui-services';
import useSearchElement from './useSearchElement';

const BEGINNING_OF_STRING_CHAR = '^';

/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function MultiPickerDropDownSearch(props) {
    const {
        hNode,
        hNode: { propertyName = 'title' }
    } = props || { hNode: {} };

    const valueToFilter = useCallback(
        values => {
            if (values != null && values.length) {
                const [idValues, regexValues] = values.reduce(
                    ([aggId, aggRE], value) => {
                        if (value._id === 'hint-regex') {
                            return [aggId, [...aggRE, value]];
                        }
                        return [[...aggId, value], aggRE];
                    },
                    [[], []]
                );
                let idFilters;
                if (idValues.length) {
                    const path = metadata.getPathToProperty(hNode) + '._id';
                    idFilters = { [path]: { $in: idValues.map(x => x._id) } };
                }
                let regexFilters;
                if (regexValues.length) {
                    const path = metadata.getPathToProperty(hNode) + '.' + propertyName;
                    regexFilters = regexValues.map(value => {
                        const startsWithRegex = BEGINNING_OF_STRING_CHAR + value.value.replaceAll('*', '.*');
                        return { [path]: { $regex: [startsWithRegex, 'i'] } };
                    });
                    if (idFilters) {
                        return { $or: [idFilters, ...regexFilters] };
                    }
                    return regexFilters.length > 1 ? { $or: regexFilters } : regexFilters[0];
                }
                return idFilters;
            }
            return {};
        },
        [hNode, propertyName]
    );
    const { value: values, setValue } = useSearchElement(props, valueToFilter, []);

    // prettier-ignore
    return rc(MultiPickerDropDown,{...props, value:values, setValue});
}

export default MultiPickerDropDownSearch;
