//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _update_metadata_profileMenu_success from './update_metadata_profileMenu_success';
import _willUpdate_metadata_profileMenu from './willUpdate_metadata_profileMenu';
export const update_metadata_profileMenu_success = _update_metadata_profileMenu_success;
export const willUpdate_metadata_profileMenu = _willUpdate_metadata_profileMenu;
export default {update_metadata_profileMenu_success, willUpdate_metadata_profileMenu};

