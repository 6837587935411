//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _deployment from './deployment';
import _environment from './environment';
export const deployment = _deployment;
export const environment = _environment;
export default {deployment, environment};

