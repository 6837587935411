import ObjectId from './ObjectId';

/**
 * publish a load (verb) start event, and follow it with a success if the given function completes
 * or a failure if the given function fails. If the given function returns a promise, that will be
 * evaluated to determine success (resolves) or failure (rejects).
 * @param {function} func - this function must complete before the load success will be called.
 * @param {object} context - normal event context
 * @param {function} publish - event publish function
 * @returns The result of the function (which is allowed to be a Promise).
 */
export function create(func, context, publish) {
    const loadId = new ObjectId().toString();
    try {
        publish({ loadId }, { ...context, verb: 'load' });
        const result = func();
        if (result instanceof Promise) {
            return result
                .then(funcResult => {
                    publish({ loadId, result: funcResult }, { ...context, verb: 'load', status: 'success' });
                    return funcResult;
                })
                .catch(err => {
                    publish(
                        { loadId, errors: { field: {}, form: [err] } },
                        { ...context, verb: 'load', status: 'failure' }
                    );
                    return err;
                });
        } else {
            publish({ loadId, result }, { ...context, verb: 'load', status: 'success' });
            return result;
        }
    } catch (err) {
        publish({ loadId, errors: { field: {}, form: [err] } }, { ...context, verb: 'load', status: 'failure' });
    }
}
