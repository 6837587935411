import { h2, Card, webOnlyStyles, styled, fromTheme, nativeOnlyStyles } from 'lib_ui-primitives';

let _CardBox = styled(Card)`
    flex-grow: 0;
`;
_CardBox = nativeOnlyStyles(_CardBox)`
    elevation: 0;
`;

_CardBox = webOnlyStyles(_CardBox)`
    box-shadow: ${({ theme: { darkMode, mobile } }) => {
        if (mobile) return 'unset';
        const shadowColor = darkMode ? '255 255 255' : '0 0 0';
        return `0 2px 2px 0 rgb(${shadowColor} / 14%), 0 3px 1px -2px rgb(${shadowColor} / 20%), 0 1px 5px 0 rgb(${shadowColor} / 12%)`;
    }};
    margin: ${({ theme: { mobile } }) => (mobile ? 0 : '3px')};
`;
_CardBox.displayName = 'CardBox';

export const CardBox = _CardBox;

export const HeaderText = styled(h2)`
    color: ${fromTheme('card', 'header', 'textColor')};
    margin: ${({ theme }) => (theme.mobile ? '0px' : theme.viewMarginMore + 'px')};
`;
