import { constants } from 'lib_ui-services';
import { COMMON_COLOR_SCHEME } from 'lib_ui-primitives';
import downloadTheFile from '../../namespace/relation/exportHelpers/exportHelper';

const { EXPORT_TYPES } = constants;

const _p = {
    downloadTheFile,
    showImportMessage,
    showFailedImportMessage,
    handleCsvDownload
};

export const _private = _p;

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

export default {
    verb: 'didCreate',
    namespace: 'import',
    relation: 'import',
    description: 'Notify the user that the import has completed',
    // this is the actual logic:
    logic: didCreate,
    // error handling to avoid the progress staying on the screen:
    onError
};

/**
 * @param {{
 *      error: Error;
 *      data: T;
 *      context: Context;
 *      dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>,
 *      workflowStack: WorkflowStack[]
 * }} parameters
 * */
function onError({ error, dispatch }) {
    dispatch(
        {
            mainTitle: 'Importing'
        },
        { verb: 'reset', namespace: 'application', relation: 'progress' }
    );
    throw error;
}

/**
 * Creates an array of error records from the provided failed records.
 * @param {Array} failedRecords - An array of failed records.
 * @returns {Array} An array of error records with consolidated error messages.
 */
const createErrorRecords = failedRecords => {
    return failedRecords.map(failedRecord => ({
        ...failedRecord,
        errors: failedRecord.errors
            // It's possible that the error is a string
            .map(e => e.message || e)
            .filter(Boolean)
            .join(', ')
    }));
};

/**
 * Notify the user that the import has completed
 */
async function showImportMessage(data, dispatch) {
    const { result: { title, totalRecords, failed = [] } = {} } = data;

    const numFailed = failed.length || 0;
    const numSucceeded = Math.round(totalRecords - numFailed);
    let message = `${numSucceeded} records imported successfully.`;

    if (numFailed) {
        message += ` ${numFailed} records failed to import.`;

        if (numFailed === totalRecords) {
            message = `Import "${title}" failed: all records failed to import.`;
        }

        // Create the failed records CSV
        const failedRecords = createErrorRecords(failed);

        // Show the option to download import errors to a CSV file
        await _p.handleCsvDownload(data, dispatch, title, failedRecords, message);
    }

    dispatch(
        {
            message,
            timeout: 5000
        },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
}

/**
 * Handle the Import error CSV download process
 */
async function handleCsvDownload(data, dispatch, title, failed, message) {
    // Add the "Errors" column as the first column,
    // along with any of the required column metadata used for sorting and displaying the columns in the exported file
    const columns = [
        { title: 'Errors', propertyName: 'errors', sequence: 0, id: 'errors' },
        ...data.newRecord.columns.map((column, index) => ({
            ...column._meta,
            sequence: column._meta.sequence !== undefined ? column._meta.sequence + 1 : index + 1
        }))
    ];

    const downloadErrorCsv = () => _p.downloadTheFile(title, failed, columns, EXPORT_TYPES.csv);
    await _p.showFailedImportMessage(dispatch, downloadErrorCsv, message);
}

/**
 * Show the Import error message and prompt the user to download the error CSV file
 */
async function showFailedImportMessage(dispatch, fn, message) {
    return new Promise(resolve => {
        dispatch(
            {
                title: 'Import Failed',
                message: [
                    message,
                    'Would you like to download the failed records?',
                    'Contact 1-877-373-0390 for support.'
                ],
                okButtonText: 'Download',
                cancelButtonText: 'Cancel',
                icon: 'warning',
                iconColor: COMMON_COLOR_SCHEME.warn,
                okAction: () => {
                    fn();
                },
                cancelAction: () => resolve(false)
            },
            { verb: 'confirm', namespace: 'application', relation: 'user' }
        );
    });
}

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function didCreate({ data, dispatch }) {
    dispatch(
        {
            mainTitle: 'Importing'
        },
        { verb: 'reset', namespace: 'application', relation: 'progress' }
    );

    // Handle import notifications and possible import error CSV download
    await _p.showImportMessage(data, dispatch);
}
