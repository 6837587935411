import { database } from 'lib_ui-services';
import lodash from 'lodash';
const { omit } = lodash;

const _p = { database };
export const _private = _p;
export default {
    verb: 'doingRollback',
    namespace: 'application',
    relation: 'reset',
    type: 'create',
    description: 'perform actual rollback of creation',
    //this is the actual logic:
    logic: doingRollback
};

/**
 *
 * @typedef {{_id:string, [s:string]:any}} BackboneRecord
 * @typedef {{ newRecord: BackboneRecord, patch:Array<object>, path:string, httpHeaders:object}} reduxOfflineCreateResponse
 *
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: reduxOfflineCreateResponse;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 */
async function doingRollback({ data, context, dispatch }) {
    await removeTheDocument(data, context);
    //IF they are still on the application reset screen, this will reopen the new dialog pane.
    //if they have moved on, this will not do anything:
    reopenDataPane(data, dispatch);
}

async function removeTheDocument(data, context) {
    const { _id } = data.newRecord;
    const db = _p.database.get();
    const lastModified = new Date();
    //perform the actual rollback
    await db.updateMany(
        {
            criteria: { _id },
            operations: {
                $set: {
                    'meta.deleted': true,
                    'meta.deletedBy': { title: 'rollback' },
                    'meta.deletedTime': lastModified,
                    'meta.status': 'clientSendFailure',
                    'meta.modifiedTime': lastModified
                }
            }
        },
        context
    );
}

function reopenDataPane(data, dispatch) {
    const newRecord = omit(data.newRecord, ['_id', 'title']);
    dispatch(
        { newRecord },
        {
            verb: 'new',
            namespace: 'application',
            relation: 'reset'
        }
    );
}
