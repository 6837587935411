export default {
    verb: 'didCreate',
    namespace: 'application',
    relation: 'sharedFilter',
    description: 'close Saved Filter name dialog',
    logic: closeDialog
};

function closeDialog({ data, dispatch }) {
    const { newRecord } = data || {};
    dispatch(
        {
            message: `Shared Filter "${newRecord.title}" created.`,
            addToList: false,
            isError: false
        },
        {
            verb: 'pop',
            namespace: 'application',
            relation: 'notification'
        }
    );
    dispatch(
        {},
        {
            verb: 'close',
            namespace: 'application',
            // this does need to be savedFilter, not sharedFilter
            // even though we are in a sharedFilter rule
            // but due to activeRecord limitations, we need to use a single form/dialog
            // to initiate both persist actions:
            relation: 'savedFilter',
            type: 'ignore-changes'
        }
    );
}
