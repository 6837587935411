import { createElement as rc } from 'react';
import { Icon, View, styled } from 'lib_ui-primitives';
import PropTypes from 'prop-types';
const NoIcon = styled(View).attrs({ name: 'no-icon-offset' })`
    margin-left: 16px;
`;
const WhiteIcon = styled(Icon).attrs({ fontColor: '#FFFFFF', iconSize: '16px' })`
    margin-top: 5px;
`;
const GreenIcon = styled(Icon).attrs({ fontColor: '#009F53', iconSize: '16px' })`
    margin-top: 5px;
`;
const RedIcon = styled(Icon).attrs({ fontColor: '#C6110B', iconSize: '16px' })`
    margin-top: 5px;
`;

function AccessModifiersAsIcons(props) {
    const {
        isCollapsed = true,
        toggleIsCollapsed,
        data: {
            children,
            removeForFeatureFlag,
            removeForFeatureFlags: _removeForFeatureFlags,
            addForFeatureFlag,
            addForFeatureFlags: _addForFeatureFlags,
            showForViewPort,
            hideForViewport,
            hiddenWhen,
            hiddenFor
        }
    } = props;
    const icons = [];
    const removeForFeatureFlags = removeForFeatureFlag || _removeForFeatureFlags;
    const addForFeatureFlags = addForFeatureFlag || _addForFeatureFlags;

    if (hiddenWhen?.some(({ hidewhen }) => hidewhen === 'ONLINE')) {
        //ONLINE/OFFLINE
        //only show when offline
        icons.push(rc(RedIcon, { key: 'network', 'data-testid': 'network-red' }, 'signal_cellular_nodata'));
    } else if (hiddenWhen?.some(({ hidewhen }) => hidewhen === 'OFFLINE')) {
        icons.push(rc(GreenIcon, { key: 'network', 'data-testid': 'network-green' }, 'signal_cellular_alt'));
    } else {
        icons.push(rc(NoIcon, { key: 'network', 'data-testid': 'network-none' }));
    }

    //feature flag
    if (removeForFeatureFlags?.length && addForFeatureFlags?.length) {
        //we some FFs are configured for to add this page
        //and others to remove it, show a white icon
        icons.push(rc(WhiteIcon, { key: 'ff', 'data-testid': 'ff-white' }, 'flag'));
    } else if (removeForFeatureFlags?.length) {
        icons.push(rc(RedIcon, { key: 'ff', 'data-testid': 'ff-red' }, 'flag'));
    } else if (addForFeatureFlags?.length) {
        icons.push(rc(GreenIcon, { key: 'ff', 'data-testid': 'ff-green' }, 'flag'));
    } else {
        icons.push(rc(NoIcon, { key: 'ff' }));
    }

    icons.push(rc(NoIcon, { style: { marginLeft: '6px' }, key: 'spacer1' }));

    //ViewPort:
    //MOBILE
    //if no show for, and this viewport is not explicitly configured as hidden,
    //OR if explicitly configured to show for this viewport: green
    if (
        (!showForViewPort?.length && !hideForViewport?.some(({ hideforviewport }) => hideforviewport === 'MOBILE')) ||
        showForViewPort?.some(({ showforviewport }) => showforviewport === 'MOBILE')
    ) {
        icons.push(rc(GreenIcon, { key: 'mobile', 'data-testid': 'mobile-green' }, 'smartphone'));
    } else if (
        //if this page is explicitly for some other viewport (but not this one,
        //as that would have been handled in the previous branch)
        //Or if this page is explicitly hidden for this viewport: red
        showForViewPort?.length ||
        hideForViewport?.some(({ hideforviewport }) => hideforviewport === 'MOBILE')
    ) {
        icons.push(rc(RedIcon, { key: 'mobile', 'data-testid': 'mobile-red' }, 'mobile_off'));
    } else {
        icons.push(rc(NoIcon, { key: 'mobile', 'data-testid': 'no-mobile' }));
    }

    //TABLET
    if (
        (!showForViewPort?.length && !hideForViewport?.some(({ hideforviewport }) => hideforviewport === 'TABLET')) ||
        showForViewPort?.some(({ showforviewport }) => showforviewport === 'TABLET')
    ) {
        icons.push(rc(GreenIcon, { key: 'tablet', 'data-testid': 'tablet-green' }, 'tablet'));
    } else if (
        showForViewPort?.length ||
        hideForViewport?.some(({ hideforviewport }) => hideforviewport === 'TABLET')
    ) {
        icons.push(rc(RedIcon, { key: 'tablet', 'data-testid': 'tablet-red' }, 'tablet'));
    } else {
        icons.push(rc(NoIcon, { key: 'tablet', 'data-testid': 'no-tablet' }));
    }

    //DESKTOP
    if (
        (!showForViewPort?.length && !hideForViewport?.some(({ hideforviewport }) => hideforviewport === 'DESKTOP')) ||
        showForViewPort?.some(({ showforviewport }) => showforviewport === 'DESKTOP')
    ) {
        icons.push(rc(GreenIcon, { key: 'desktop', 'data-testid': 'desktop-green' }, 'desktop_windows'));
    } else if (
        showForViewPort?.length ||
        hideForViewport?.some(({ hideforviewport }) => hideforviewport === 'DESKTOP')
    ) {
        icons.push(rc(RedIcon, { key: 'desktop', 'data-testid': 'desktop-red' }, 'desktop_access_disabled'));
    } else {
        icons.push(rc(NoIcon, { key: 'desktop', 'data-testid': 'no-desktop' }));
    }

    icons.push(rc(NoIcon, { style: { marginLeft: '6px' }, key: 'spacer2' }));

    //user Access
    //USER
    if (hiddenFor?.some(({ hiddenfor }) => hiddenfor === 'USER')) {
        icons.push(rc(RedIcon, { key: 'user', 'data-testid': 'user-red' }, 'groups'));
    } else {
        icons.push(rc(GreenIcon, { key: 'user', 'data-testid': 'user-green' }, 'groups'));
    }
    //ADMIN
    if (hiddenFor?.some(({ hiddenfor }) => hiddenfor === 'ADMIN')) {
        icons.push(rc(RedIcon, { key: 'admin', 'data-testid': 'admin-red' }, 'supervisor_account'));
    } else {
        icons.push(rc(GreenIcon, { key: 'admin', 'data-testid': 'admin-green' }, 'supervisor_account'));
    }
    //SUPPORT
    if (hiddenFor?.some(({ hiddenfor }) => hiddenfor === 'SUPPORT')) {
        icons.push(rc(RedIcon, { key: 'support', 'data-testid': 'support-red' }, 'person'));
    } else {
        icons.push(rc(GreenIcon, { key: 'support', 'data-testid': 'support-green' }, 'person'));
    }

    if (children) {
        icons.push(
            rc(Icon, { key: 'expand', onClick: toggleIsCollapsed }, isCollapsed ? 'expand_more' : 'expand_less')
        );
    } else {
        icons.push(rc(NoIcon, { style: { marginLeft: '24px' }, key: 'spacer3' }));
    }

    if (!icons.length) return null;
    return rc(View, null, ...icons);
}

AccessModifiersAsIcons.propTypes = {
    id: PropTypes.string,
    isCollapsed: PropTypes.bool,
    toggleIsCollapsed: PropTypes.func,
    data: PropTypes.shape({
        children: PropTypes.array,
        removeForFeatureFlag: PropTypes.array,
        removeForFeatureFlags: PropTypes.array,
        addForFeatureFlag: PropTypes.array,
        addForFeatureFlags: PropTypes.array,
        showForViewPort: PropTypes.array,
        hideForViewport: PropTypes.array,
        hiddenWhen: PropTypes.array,
        hiddenFor: PropTypes.array,
        isTabContainer: PropTypes.bool
    })
};

export default AccessModifiersAsIcons;
