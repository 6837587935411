import { createElement as rc, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Caret, testProperties } from 'lib_ui-primitives';
import useFormControl from '../../../hooks/useFormControl';
import EditableListRow from './EditableListRow';
import createTreePositionGetter from '../../../utilities/createTreePositionGetter';
import Collapse from '../../helpers/Collapse';
import makeCollapsible from '../../helpers/makeCollapsible';
import { useTheme } from 'styled-components';
import {
    StyledEditableList,
    Header,
    CaratButton,
    ListTitle,
    ExpandCollapseButtons,
    ExpandAllButton,
    CollapseAllButton,
    ColumnList
} from './styles';

export const _p = { useFormControl };
export const _private = _p;

const EditableList = makeCollapsible(function EditableList(
    props,
    { collapsed, setCollapsed, deleteCollapsed, addCollapsed, collapseAll, expandAll, hasChildren }
) {
    const {
        hNode: { id, propertyName, children: childrenHNodes, propertyPath, treePosition },
        currentRoute
    } = props ?? { hNode: {} };
    const { title, value, setValue, disabled } = _p.useFormControl(props);
    const theme = useTheme();

    function add() {
        setValue(prev => {
            return [...prev, {}];
        });
        addCollapsed();
    }

    function remove(index) {
        setValue(prev => {
            const newArray = [...prev];
            newArray.splice(index, 1);
            return newArray;
        });
        deleteCollapsed(index);
    }

    const toggleCollapse = useCallback(() => {
        setCollapsed(!collapsed);
    }, [collapsed, setCollapsed]);

    const getTreePosition = createTreePositionGetter(treePosition, value.length);

    // prettier-ignore
    return rc(StyledEditableList, null,
        rc(Header, null,
            rc(CaratButton, { onClick: toggleCollapse, ...testProperties(props.hNode, 'carat') },
                rc(Caret, {isOpen: !collapsed, color: theme.defaultFontColor, title: `${collapsed ? 'Expand' : 'Collapse'} ${title}` }),
            ),
            rc(ListTitle, null, title),
            !collapsed && rc(ExpandCollapseButtons, null,
                rc(Button, { icon: 'add', buttonStyle: 'round', color: 'accentOne', onClick: add, disabled, title:`Add ${title}` }),
                hasChildren() && rc(ExpandAllButton, { title: 'Expand All', icon: 'unfold_more', buttonStyle: 'round', color: 'accentTwo', onClick: expandAll, ...testProperties(props.hNode, 'expand-all') }),
                hasChildren() && rc(CollapseAllButton, { title: 'Collapse All', icon: 'unfold_less', buttonStyle: 'round', color: 'accentTwo', onClick: collapseAll, ...testProperties(props.hNode, 'collapse-all') })
            )
        ),
        rc(Collapse, { isCollapsed: collapsed, ['data-testid']: `${collapsed ? 'collapsed' : 'expanded'}-${id}` },
            rc(ColumnList, null,
                // reverse() changes the array in place.  Do not mutate the original array
                // or you will be very sad and confused.
                [...value].reverse().map((entry, index) => {
                    const propertyIndex = value.length - index - 1; // reverse index for property
                    return rc(EditableListRow, {
                            index,
                            prettyName: title,
                            propertyPath,
                            propertyName,
                            propertyIndex,
                            childrenHNodes,
                            remove,
                            currentRoute,
                            disabled,
                            treePosition: getTreePosition(index),
                            key: index
                        }
                    );
                })
            )
        )
    );
});

EditableList.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};

export default EditableList;
