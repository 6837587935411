import { register, render, calculateHight } from '../ZNode';

async function ColumnLayout(props) {
    const {
        output,
        zNode: { width, height, content }
    } = props || { zNode: {} };

    const heights = content.map(content => calculateHight({ ...props, width, height, zNode: content }));

    const contentHight = heights.reduce((a, b) => a + b, 0);
    // It looks better if the space before and after the content is the same as between the content
    const spacing = Math.floor((height - contentHight) / (heights.filter(h => h > 0).length + 1));
    const tops = heights.reduce(
        (sofar, h) => [...sofar, h ? sofar[sofar.length - 1] + h + spacing : sofar[sofar.length - 1]],
        [spacing]
    );

    return content.reduce(async (_sofar, content, i) => {
        const sofar = await _sofar;
        return render({ ...props, output: sofar, zNode: content, width, height, top: tops[i] });
    }, output);
}

register('ColumnLayout', ColumnLayout);
export default ColumnLayout;
