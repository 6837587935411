import { constants, globalConfig, http } from 'lib_ui-services';

export default {
    verb: 'didCreate',
    namespace: 'identity',
    relation: 'backboneUser',
    priority: 10,
    prerequisites: [],
    description: 'Submit password separately after user creation',
    useCaseIds: [constants.useCaseIds.NUCLEUS],
    //this is the actual logic:
    logic: createSuccess
};

export const _private = { http };

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function createSuccess({ data }) {
    if (data.newRecord.password) {
        const { password, userName } = data.newRecord;
        await UpdateServerWithNewPassword(password, userName);
    }
}

async function UpdateServerWithNewPassword(password, userName) {
    const { protocol, hostname: host } = globalConfig();
    const protocolAndHost = `${protocol}//${host}`;
    let url = '/api/security/setPassword';
    return _private.http.post(url, { password, userName, protocolAndHost });
}
