import { createElement as rc, useCallback, useState, useMemo, Fragment } from 'react';
import { constants } from 'lib_ui-services';
import { Text, View, styled, Pressable, ExternalLink } from 'lib_ui-primitives';
import ConfigEntry from './ConfigEntry';
import useBrand from '../../hooks/useBrand';

const DisclaimerWrapper = styled(View).attrs({ name: 'disclaimer' })`
    margin: 8px;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
`;
export const LeftToRight = styled(View)`
    flex-grow: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const SmallText = styled(Text)`
    font-size: 13px;
    line-height: 18px;
    padding-right: 6px;
`;

const HrefLink = styled(ExternalLink).attrs({ iconSize: '13px', style: { fontSize: '13px', lineHeight: '18px' } })``;

const _p = {
    useBrand
};
export const _private = _p;

/** @type {import("react").FC} */
const Disclaimer = () => {
    const brand = _p.useBrand();
    const [configVisible, setConfigVisible] = useState();
    const [configClickCount, setConfigClickCount] = useState(0);

    const closeConfig = useCallback(() => setConfigVisible(false), []);
    const configClick = useCallback(() => {
        if (configClickCount >= 4) {
            setConfigVisible(true);
        }
        setConfigClickCount(c => {
            return c + 1;
        });
    }, [configClickCount]);

    const support = useMemo(() => constants.SUPPORT_INFO[brand] || constants.SUPPORT_INFO.sst, [brand]);

    // prettier-ignore
    return rc(DisclaimerWrapper, null,
        !support.knowledgeBase && rc(SmallText, null, 'Need assistance? Contact your administrator'),
        support.knowledgeBase && rc(
            Fragment,
            null,
            rc(SmallText, null, 'Need assistance? Check out the'),
            rc(HrefLink, {
                url: support.knowledgeBase,
                title: `${support.displayName} knowledge base`,
                includeExternalIcon: true
            })
        ),
        rc(LeftToRight, null,
            rc(SmallText, null, `or call ${support.displayName} at `),
            rc(HrefLink, { url: `tel:${getRawPhoneNumber(support.supportPhone)}`, title: support.supportPhone, includeExternalIcon:false})
        ),
        rc(Pressable, {onClick: configClick},
        // eslint-disable-next-line no-undef
            rc(SmallText, null, `Version ${__PACKAGE_VERSION__}`)
        ),
        rc(ConfigEntry, {configVisible, closeConfig})
    );
};

/**
 * Takes a formatted phone number and returns a raw number with no formatting.
 * @param {string} phoneNumber
 */
function getRawPhoneNumber(phoneNumber) {
    return phoneNumber.replace(/[^\d]/g, '');
}

export default Disclaimer;
