import { createElement, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { View, h4, fromTheme, styled, ScrollView, testProperties } from 'lib_ui-primitives';
import useFormControl from '../../hooks/useFormControl';
import CriteriaRow from './CriteriaRow';
import DropDownButton from '../_abstractComponent/DropDownButton';
import createTreePositionGetter from '../../utilities/createTreePositionGetter';

const rc = createElement;
const StyledWorkflowCriteriaPane = styled(View)`
    background-color: ${fromTheme('backgroundColor')};
    flex-direction: column;
    flex-grow: 1;
    padding: 0;
    margin-bottom: ${fromTheme('viewMargin')};
`;
StyledWorkflowCriteriaPane.displayName = 'StyledWorkflowCriteriaPane';

const Header = styled(View).attrs({ name: 'header' })`
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-shrink: 0;
`;
Header.displayName = 'Header';

const ListTitle = styled(h4).attrs({ name: 'list-title' })`
    padding-left: ${fromTheme('viewPadding')};
`;
ListTitle.displayName = 'ListTitle';

const List = styled(ScrollView).attrs({ name: 'instance-list' })`
    flex-direction: column;
`;
List.displayName = 'InstanceList';

const _p = { useFormControl };
export const _private = _p;
function WorkflowCriteriaPane(props) {
    const {
        hNode: { id, propertyName, children: templates, treePosition, alwaysShowAll = true },
        currentRoute
    } = props ?? { hNode: {} };

    const { title, value, setValue, disabled } = _p.useFormControl(props);

    const getTreePosition = createTreePositionGetter(treePosition, value.length);

    const add = useCallback(
        function add(hNode) {
            setValue(prev => {
                return [...prev, hNodeToNewValue(hNode)];
            });
        },
        [setValue]
    );

    const remove = useCallback(
        function remove(index) {
            setValue(prev => {
                const newArray = [...prev];
                newArray.splice(index, 1);
                return newArray;
            });
        },
        [setValue]
    );

    useEffect(() => {
        if (alwaysShowAll) {
            templates.sort((x, y) => x.sequence - y.sequence);
            const defaultValue = templates.map(template => {
                return hNodeToNewValue(template);
            });
            setValue(prev => {
                if (prev && prev.length) {
                    return prev;
                }
                return defaultValue;
            });
        }
    }, [alwaysShowAll, setValue, templates]);

    const menuOptions = useMemo(() => {
        return templates.map((option, i) => {
            const { title } = option || {};

            return rc(DropDownButton.MenuItem, {
                hNode: { title },
                onClick: () => add(option),
                key: i,
                ...testProperties({ id: `mnu${i}` }, title)
            });
        });
    }, [templates, add]);

    // prettier-ignore
    return rc(StyledWorkflowCriteriaPane, null,
        rc(Header, null,
            rc(ListTitle, null, title),
            !alwaysShowAll && rc(DropDownButton, { id, icon: 'add', disabled, color: 'success', ...testProperties(props.hNode, 'AddEntry') },
                menuOptions
            )
        ),
        rc(List, null,
            value.map((entry, index) => 
                rc(CriteriaRow, { propertyName, entry, index, templates, disabled, entryToTemplate, remove, allowRemove:!alwaysShowAll, key: index, currentRoute, treePosition: getTreePosition(index) }))
        )
    );
}

//by default, we assume 1 property on the value, and that is should match the namespace:relation or propertyName on the template
const entryToTemplate = (value, templates) => {
    if (!Object.keys(value).length) return templates[0];
    return templates.find(hNode => {
        const { propertyName, foreignNamespace, foreignRelation } = hNode || {};
        if (foreignNamespace && foreignRelation) {
            return Object.keys(value).includes(`${foreignNamespace}:${foreignRelation}`);
        } else {
            return Object.keys(value).includes(propertyName);
        }
    });
};

function hNodeToNewValue(hNode) {
    const { propertyName, foreignNamespace, foreignRelation } = hNode || {};
    if (foreignNamespace && foreignRelation) {
        return { [`${foreignNamespace}:${foreignRelation}`]: {} };
    } else {
        return { [propertyName]: {} };
    }
}

WorkflowCriteriaPane.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};

export default WorkflowCriteriaPane;
