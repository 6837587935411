import { createElement as rc } from 'react';
import { Link } from 'react-router-dom';

import styled from '../../styled';
import fromTheme from '../../fromTheme';

const LinkStyled = styled(Link)`
    text-decoration-color: ${fromTheme('defaultFontColor')};
`;

/** @type {react.FC<{}>} */
export default function RouterLink(props) {
    return rc(LinkStyled, props);
}
