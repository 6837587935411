import { createElement as rc, useEffect, useState } from 'react';
import Form from '../Form';
import MultiSelectBoundary from '../../contextProviders/MultiSelectBoundary';
import MergedReadProvider, { useMergedReads } from '../../contextProviders/MergedReadProvider';
import LoadingBoundary from '../../contextProviders/LoadingBoundary';
import ActiveRecord from '../../contextProviders/ActiveRecord';
import useActiveRecord from '../../../hooks/useActiveRecord';
import useEventSink from '../../../hooks/useEventSink';
import useMultiSelect from '../../../hooks/useMultiSelect';
import DetailPane from '../../detailPane/DetailPane';
import { Text, useDebounce } from 'lib_ui-primitives';
import { TransactionLayout } from './style';
import { metadata, dbViews } from 'lib_ui-services';
import useReads from '../../../hooks/useReads';
const { EMPTY_ARRAY } = dbViews.emptyDbView;

const EMPTY_OBJECT = {};
const DEFAULT_SELECTIONS = { sensor: { read: { __invertSelection: true } } };

export default function Transaction(props) {
    // `record` is mainly included for test purposes here. It will usually
    // be supplied to the ActiveRecord component by an event from the rules
    // engine or similar.
    const {
        record,
        hNode: { namespace, relation, forAction },
        currentRoute
    } = props || { hNode: {} };

    if (!relation.endsWith('-transaction')) {
        throw new Error('Transaction blocks must be set up with a relation having a name ending in "-transaction"');
    }

    // prettier-ignore
    return rc(LoadingBoundary, null,
        rc(ActiveRecord, { namespace, relation, record, activationVerb: forAction, currentRoute },
            // This inverts the selection so that the grid starts out with all entries checked.
            rc(MultiSelectBoundary, { currentRoute, defaultSelections: DEFAULT_SELECTIONS },
                rc(MergedReadProvider, { ...props },
                    rc(InnerTransaction, props)
                )
            )
        )
    );
}

function InnerTransaction(props) {
    const {
        children,
        testID,
        accessibility,
        accessibilityLabel,
        hNode: {
            namespace,
            relation,
            transactionType,
            forAction,
            propertyName = 'entries',
            canSelectUnknownTags = false
        }
    } = props || { hNode: {} };

    const [subscribe, publish] = useEventSink();
    const activeRecord = useActiveRecord();
    const { record } = activeRecord;
    const mergeRelation = relation.split('-')[0];
    const titleAlternative = metadata.getTitleAlternative(namespace, mergeRelation);
    const { isSelected } = useMultiSelect({ namespace: 'sensor', relation: 'read' });
    const { reset } = useReads();

    //on initial load and when active record changes
    useEffect(() => {
        if (record == null) {
            reset();
            publish(EMPTY_OBJECT, { verb: forAction, namespace, relation, type: 'transaction', transactionType });
        }
    }, [forAction, namespace, publish, record, relation, transactionType, reset]);

    const { subscribeToChange } = useMergedReads();
    const [entries, setEntries] = useState(EMPTY_ARRAY);
    useEffect(() => {
        if (subscribeToChange != null) {
            return subscribeToChange(newEntries => {
                setEntries(newEntries);
            });
        }
    }, [subscribeToChange]);

    const onChange = useDebounce(
        entries => {
            const newValue = entries
                .filter(e => {
                    return e._id != null && isSelected(e._id) && (canSelectUnknownTags || e.inDatabase);
                }) // selection is inverted in grid (so checkboxes are all checked to start with)
                .map(e => ({ _id: e._id, [titleAlternative]: e[titleAlternative] }));

            publish(
                {
                    propertyName,
                    newValue
                },
                { verb: 'beginChange', namespace, relation, type: 'transaction', transactionType }
            );
        },
        [
            namespace,
            relation,
            transactionType,
            publish,
            titleAlternative,
            propertyName,
            isSelected,
            canSelectUnknownTags
        ],
        200
    );

    useEffect(() => {
        onChange(entries);
    }, [onChange, entries]);

    useEffect(() => {
        return subscribe({ verb: 'select', namespace: 'sensor', relation: 'read' }, () => {
            onChange(entries);
        });
    }, [onChange, entries, subscribe]);

    // prettier-ignore
    return rc(TransactionLayout, { 'data-testid': props['data-testid'], testID, accessibility, accessibilityLabel },
        !record && rc(Text, null, 'Loading...'),
        !!record && rc(DetailPane, null,
            rc(Form, { ...props, scrollWholeForm: true, name: 'transaction' }, children)
        )
    );
}
