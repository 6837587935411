import { http, metadata } from 'lib_ui-services';

const _p = {
    http,
    getNamespaces: metadata.getNamespaces
};

export const _private = _p;

export default {
    verb: 'doingGet',
    namespace: 'application',
    relation: 'swagger',
    logic: doingGet
};

async function doingGet({ context }) {
    const { user } = context;
    const activeUseCaseId = user?.activeUseCaseId;
    const groupNumber = user?.group?.title[1] ?? 0;
    //no need to add the group. http will add the group for us.
    const useCaseSwagger = await _p.http.get(`/api/metaui/swagger/${activeUseCaseId}`, true);
    const namespaces = _p.getNamespaces();
    let customApis = namespaces.filter(ns => ns.withCustomApi && !ns.hideApiDocs).map(ns => ns.title);
    const customSwaggers = await Promise.all(
        customApis.map(namespaceTitle => _p.http.get(`/docs/${namespaceTitle}/swagger`, true))
    );
    const combinedSwagger = merge(useCaseSwagger, namespaces, customSwaggers, customApis);
    combinedSwagger.basePath = `/g/${groupNumber}${combinedSwagger.basePath}`;
    return { result: { swagger: combinedSwagger } };
}

export function merge(useCaseSwagger, namespaces, customSwaggers, customApis) {
    let mainApi = useCaseSwagger;
    for (let i = 0; i < customSwaggers.length; i++) {
        // Merge all custom APIs endpoints into one tag
        Object.values(customSwaggers[i].paths).forEach(path => {
            Object.values(path).forEach(operation => {
                operation.tags = [namespaces.find(ns => ns.title === customApis[i]).prettyName];
                if (operation.responses && operation.responses.default) {
                    delete operation.responses.default;
                }
            });
        });
        Object.keys(customSwaggers[i].paths).forEach(path => {
            customSwaggers[i].paths[`/${customApis[i]}${path}`] = customSwaggers[i].paths[path];
            delete customSwaggers[i].paths[path];
        });

        mainApi.paths = { ...mainApi.paths, ...customSwaggers[i].paths };
        mainApi.definitions = { ...mainApi.definitions, ...customSwaggers[i].definitions };
        mainApi.tags = [
            ...mainApi.tags,
            { name: namespaces.find(ns => ns.title === customApis[i]).prettyName, description: '' }
        ];
    }
    mainApi.tags.sort((a, b) => {
        //Security comes first
        if (a.name === 'Security') return -1;
        if (b.name === 'Security') return 1;
        //Item comes second
        if (a.name === 'Item') return -1;
        if (b.name === 'Item') return 1;
        //location comes third
        if (a.name === 'Location') return -1;
        if (b.name === 'Location') return 1;
        //everything else comes alphabetically
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    });
    return mainApi;
}
