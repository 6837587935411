import { createElement as rc } from 'react';
import useEventSink from '../../../hooks/useEventSink';
import { styled, View, Button } from 'lib_ui-primitives';
const StyledButtonColumn = styled(View)`
    width: ${({ width }) => width + 'px'};
`;

export default function ButtonColumn(props) {
    const {
        record,
        width,
        hNode,
        hNode: { id, forAction, title, disabled, namespace, relation, iconName, displayTitleOnButton, buttonStyle },
        ...otherProps
    } = props || { hNode: {} };

    const [, publish] = useEventSink();

    function onClick(e) {
        e.preventDefault();
        e.stopPropagation();
        publish(record, { verb: forAction, namespace, relation });
    }

    return rc(
        StyledButtonColumn,
        { width },
        rc(Button, {
            id,
            hNode,
            value: title,
            onClick,
            disabled,
            buttonStyle,
            icon: iconName,
            displayTitleOnButton,
            ...otherProps
        })
    );
}
