import { createElement as rc, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TextInput, styled, fromTheme } from 'lib_ui-primitives';
import { filters } from 'lib_ui-services';
import useSearchElement from './useSearchElement';
import logging from '@sstdev/lib_logging';
import FormField from '../formElement/FormField';

const SearchField = styled(FormField).attrs({ name: 'search-element' })`
    max-width: 300px;
    min-width: 150px;
    margin-left: ${fromTheme('textMargin')};
    flex-shrink: 0;
    flex-grow: 0;
`;

const TextSearch = styled(TextInput).attrs({ name: 'value-search' })`
    flex-grow: 1;
`;

/* Implemented separately from KeySearch.
 * Theoretically, KeySearch could be implemented as some combination of a SearchPane and a ValueSearch
 * With some additional logic to show/hide the input field. But that exercise will be left to the reader
 */

/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function ValueSearch(props) {
    const {
        hNode,
        hNode: { id, title, propertyPath = '', treePosition },
        disabled,
        type = 'textbox'
    } = props || { hNode: {} };

    //How a selected value is translated into a filter depends on what the search element captures
    let defaultFilters = useMemo(() => filters.filterHelpers.getDefaultFilters(hNode), [hNode]);
    if (!defaultFilters?.propertiesToSearch) {
        // eslint-disable-next-line no-undef
        __PRODUCTION__ &&
            logging.error(
                'Missing `propertiesToSearch` filter on `ValueSearch`. You might have to copy it up from the grid in blockly.'
            );
        defaultFilters = {
            propertiesToSearch: { propertiesToSearch: ['assetNo', 'serialNo'] },
            ...defaultFilters
        };
    }
    const valueToFilter = useCallback(
        value => {
            if (value != null && value != '') {
                const fullTextSearch = {
                    searchTerm: value
                };
                if (propertyPath) {
                    defaultFilters.propertiesToSearch.propertiesToSearch =
                        defaultFilters.propertiesToSearch.propertiesToSearch.map(v => `${propertyPath}.${v}`);
                }

                return filters.fullTextSearch.getMql({ ...defaultFilters, fullTextSearch });
            }
            return {};
        },
        [defaultFilters, propertyPath]
    );

    const { value, setValue, active, onFocus, onBlur } = useSearchElement(props, valueToFilter, '');
    const fieldEmpty = !value;

    // prettier-ignore
    return rc(SearchField, { hNode, title, active, fieldEmpty },
        rc(TextSearch, {
            id,
            type,
            name: 'valueSearch-' + id,
            disabled,
            value,
            onChange: setValue,
            onBlur,
            onFocus,
            sequence: treePosition.sequence
        })
    );
}

ValueSearch.defaultProps = {
    disabled: false
};

ValueSearch.propTypes = {
    hNode: PropTypes.object.isRequired,
    currentRoute: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    type: PropTypes.string
};

export default ValueSearch;
