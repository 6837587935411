const notHandledHere = [
    { namespace: 'security', relation: 'profile' },
    { namespace: 'application' },
    { namespace: 'sensor' },
    { namespace: 'file' },
    { namespace: 'metadata', relation: 'navigation' },
    { namespace: 'metaui', relation: 'validations' },
    { namespace: 'metaui', relation: 'dataDictionary' }
];
export default notHandledHere;
