import { createElement as rc } from 'react';
import { ExternalLink } from 'lib_ui-primitives';
import { useTheme } from 'styled-components';
export default function Href(props) {
    const {
        hNode: { title, url, includeExternalIcon = true, openInNewWindow = includeExternalIcon }
    } = props || { hNode: {} };
    const theme = useTheme();
    return rc(ExternalLink, { title, url, includeExternalIcon, fontSize: theme.fontSize, openInNewWindow });
}
