import { useContext, createContext, createElement as rc } from 'react';
import logging from '@sstdev/lib_logging';
/**
 * Used to capture which component in the route currently owns a sensor using a ReadContext
 */
export const RouteReadOwnershipContext = createContext();

export default function RouteReadOwnershipProvider(props) {
    const { currentRoute, readOwnerId, children } = props;
    logging.debug(`[READ_CONTEXT] ReadContext ${readOwnerId} owns all sensorTypes for ${currentRoute}.`);
    const ownerRef = { id: readOwnerId };
    return rc(
        RouteReadOwnershipContext.Provider,
        {
            key: currentRoute,
            value: {
                sensorOwners: {
                    RFID: [ownerRef],
                    MANUAL: [ownerRef],
                    BARCODE: [ownerRef],
                    BLE: [ownerRef]
                }
            }
        },
        children
    );
}

export function useRouteReadOwnership() {
    const context = useContext(RouteReadOwnershipContext);

    if (context == null) return;

    /**
     * Determine if the ReadContext with the given id is the
     * current owner of the given sensor type
     * @param {string} sensorType - sensor type interested in
     * @param {string} id - id of the ReadContext to check
     * @returns {boolean} - is owner
     */
    context.isOwner = (sensorType, id) => {
        const lastIndex = context.sensorOwners[sensorType].length - 1;
        return context.sensorOwners[sensorType][lastIndex].id == id;
    };

    /**
     * Sets the ownership of the given desired sensor types to be the ReadContext for the
     * given id.
     * @param {Array} desiredSensorTypes - array of sensor types for which ownership is desired
     * @param {string} id - ReadContext id requesting ownership
     * @param {string} debugText - appended to debug messages
     * @returns {Function} - removes readcontext with above id from ownership
     */
    context.takeOwnership = (desiredSensorTypes, id, debugText) => {
        const ownerRef = { id };
        let reversion = () => {
            Object.keys(context.sensorOwners).forEach(sensorType => {
                removeLastOwnership(context, sensorType, ownerRef, debugText);
            });
        };
        desiredSensorTypes.forEach(sensorType => {
            setNewOwnership(context, sensorType, ownerRef, debugText);
        });
        return reversion;
    };
    return context;
}

/**
 * Search back through the previous owners for the given sensor type and remove the one that
 * matches exactly the owner reference passed in.
 * The ownerRef is a reference type so that
 * multiple instances of the same ReadContext can be in the ownership past and only the one
 * which matches the given ownerRef will be removed.
 * @param {Object} context - the RouteReadOwnership context
 * @param {string} sensorType - the sensor type this will remove ownership from
 * @param {Object} ownerRef - a reference containg the owning ReadContext's id
 * @param {string} ownerRef.id - the ReadContext's id
 * @param {string} debugText - appended to debug messages
 */
function removeLastOwnership(context, sensorType, ownerRef, debugText) {
    const owners = context.sensorOwners[sensorType];
    const index = owners.findIndex(o => o === ownerRef);
    if (index >= 0) {
        owners.splice(index, 1);
        const lastIndex = owners.length - 1;
        // eslint-disable-next-line no-undef
        !__UNIT_TESTING__ &&
            logging.debug(
                `[READ_CONTEXT] removed previous ownership of ${sensorType} sensorType from ${
                    ownerRef.id
                } read context. Current owner: ${owners[lastIndex].id}.${debugText ? '  Debug text: ' + debugText : ''}`
            );
    }
}

/**
 * Sets ownership of the given sensor type to the ReadContext with the given ownerRef's id
 * @param {Object} context - the RouteReadOwnership context
 * @param {String} sensorType - the sensor type that is being possessed
 * @param {Object} ownerRef - a reference containing a ReadContext's id
 * @param {string} ownerRef.id - the ReadContext's id
 * @param {String} debugText - appended to debug messages
 */
function setNewOwnership(context, sensorType, ownerRef, debugText) {
    const owners = context.sensorOwners[sensorType];
    // If the ReadContext is not already the owner....
    if (owners[owners.length - 1]?.id !== ownerRef.id) {
        const lastOwnerId = owners[owners.length - 1].id;
        // ... make it the owner now.
        owners.push(ownerRef);
        // eslint-disable-next-line no-undef
        !__UNIT_TESTING__ &&
            logging.debug(
                `[READ_CONTEXT] read context ${
                    ownerRef.id
                } is taking ownership of ${sensorType} sensorType from ${lastOwnerId}.${
                    debugText ? '  Debug text: ' + debugText : ''
                }`
            );
    }
}
