import { useEffect, createElement as rc, useContext } from 'react';
import PropTypes from 'prop-types';
import ActiveRecord from '../contextProviders/ActiveRecord';
import DetailPane from '../detailPane/DetailPane';
import useActiveRecord from '../../hooks/useActiveRecord';
import useEventSink from '../../hooks/useEventSink';
import Form from './Form';
import LoadingBoundary from '../contextProviders/LoadingBoundary';
import { Text, View, styled, fromTheme, webOnlyStyles } from 'lib_ui-primitives';
import useReads from '../../hooks/useReads';
import useSplashRecord from '../../hooks/useSplashRecord';
import { contexts } from 'lib_ui-primitives';
const { ModalQueueContext } = contexts;

let SingleRecordFormLayout = styled(View).attrs({ name: 'single-record-form-layout' })`
    flex-grow: 1;
    margin-right: ${fromTheme('viewMargin')};
`;

// Because this is sometimes displayed inside a Modal/PortalHost (which is not height: 100% on web),
// this needs a min-height fixed pixel value.  Otherwise, it will squish down to nothing
// inside the modal.
SingleRecordFormLayout = webOnlyStyles(SingleRecordFormLayout)`
    min-height: ${({ theme, modalVisible }) =>
        theme.mobile ? 'unset' : modalVisible ? theme.height - 200 + 'px' : 'unset'};
`;
SingleRecordFormLayout.displayName = 'SingleRecordFormLayout';

const EMPTY_OBJECT = {};
// Effectively, "SingeRecordForm" is more of a "Layout" that embeds a normal form.
// Compare to SummaryDetailLayout, without the grid
// And instead of an end-user clicking a record or button to initiate the form
// when it is rendered, and we notice that we haven't initiated the forAction yet,
// we programmatically trigger the action.

export default function SingleRecordForm(props) {
    if (props == null) return null;
    // `record` is mainly included for test purposes here. It will usually
    // be supplied to the ActiveRecord component by an event from the rules
    // engine or similar.
    const { hNode, record, children, currentRoute } = props;
    const { namespace, relation, forAction } = hNode;

    // prettier-ignore
    return rc(ActiveRecord, { namespace, relation, record, activationVerb: forAction, currentRoute },
        rc(InnerSingleRecordForm, props, children)
    );
}

export function InnerSingleRecordForm(props) {
    const { hNode, children } = props || {};
    const { namespace, relation, forAction, filterBySplashRecord } = hNode;
    const activeRecord = useActiveRecord();
    const splash = useSplashRecord();
    const { activationVerb, record } = activeRecord;
    const [subscribe, publish] = useEventSink();
    const { reset } = useReads();
    const queue = useContext(ModalQueueContext);
    const modalVisible = !!queue?.peek();
    //on initial load and when active record changes
    useEffect(() => {
        if (
            splash.record &&
            splash.namespace === namespace &&
            splash.relation === relation &&
            (record == null || record._id != splash.record._id)
        ) {
            publish(splash.record, { verb: forAction, namespace, relation, type: 'singleRecordForm' });
        } else if (record == null) {
            reset();
            if (filterBySplashRecord && splash.record) {
                publish({ splashRecord: splash }, { verb: forAction, namespace, relation, type: 'singleRecordForm' });
            } else {
                publish(EMPTY_OBJECT, { verb: forAction, namespace, relation, type: 'singleRecordForm' });
            }
        }
    }, [activationVerb, filterBySplashRecord, forAction, namespace, publish, record, relation, reset, splash]);

    //for subsequent use
    useEffect(() => {
        const unsubscribes = [
            // Consider removing this verb - would require changes to caption override and inventory tracking global settings
            subscribe({ verb: 'refresh', namespace, relation }, () => {
                if (splash.record && splash.namespace === namespace && splash.relation === relation) {
                    publish(splash.record, { verb: forAction, namespace, relation, type: 'singleRecordForm' });
                } else if (filterBySplashRecord && splash.record) {
                    publish(
                        { splashRecord: splash },
                        { verb: forAction, namespace, relation, type: 'singleRecordForm' }
                    );
                } else {
                    publish(EMPTY_OBJECT, { verb: forAction, namespace, relation, type: 'singleRecordForm' });
                }
            })
        ];
        return () => unsubscribes.forEach(unsubscribe => unsubscribe());
    }, [activationVerb, forAction, namespace, subscribe, publish, relation, reset, splash, filterBySplashRecord]);

    // prettier-ignore
    return rc(LoadingBoundary, null,
        rc(SingleRecordFormLayout, { modalVisible },
            !record && rc(Text, null, 'Loading...'),
            !!record && rc(DetailPane, null, rc(Form, { ...props, name: 'single-record-form' }, children))
        )
    );
}

SingleRecordForm.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    record: PropTypes.object,
    currentRoute: PropTypes.string.isRequired,
    hNode: PropTypes.shape({
        namespace: PropTypes.string,
        relation: PropTypes.string,
        forAction: PropTypes.string
    }),
    resetReads: PropTypes.bool
};
