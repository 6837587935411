import styled from '../styled';
import View from './View';
import fromTheme from '../fromTheme';
import GestureRecognizer from './contextProviders/GestureRecognizer';
import SpaceConstrained from './SpaceConstrained';
import { createElement as rc } from 'react';

const BaseApp = styled(View).attrs({ name: 'App', scroll: true })`
    background-color: ${({ theme }) => theme.baseBackgroundColor};
    color: ${({ theme }) => theme.defaultFontColor};
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: ${props => (props.theme.mobile ? 'stretch' : 'flex-start')};
    font-family: ${fromTheme('font')};
    border-bottom: thin solid ${fromTheme('colorScheme', 'border')};
`;

export default function App(props) {
    // prettier-ignore
    return rc(GestureRecognizer, null,
        rc(SpaceConstrained, null,
            rc(BaseApp, null, props?.children)
        )
    );
}
