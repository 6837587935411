import constants from '../../constants';
const { criteriaOperations } = constants;

/**
 *
 * @param {string} propertyName
 * @param {object} [model]
 * @returns {string | null} the path to the property in the model, or null if it is not found
 */
export function pathsToFilter(propertyName, model) {
    // for any leaf in the model tree that is not the property we are looking for
    // it simply will end up with no model left, or with no entries on the model
    // the former is caught here, the latter is caught in the loop below
    if (!model) return [];

    if (model[propertyName]) {
        const criteriaBasedPaths = Object.values(criteriaOperations)
            .map(op => {
                if (model[propertyName]?.[op._id] || model[propertyName]?.[0]?.[op._id] === false) {
                    return `${propertyName}.${op._id}._id`;
                }
            })
            .filter(Boolean);

        // If there are no criteriaBasedPaths, Keep it simple which works for basic reference properties,
        // as well as for arrays of references (multiPickers)
        // as in that case the "." (without an array index) will match _any_ array entry
        return criteriaBasedPaths.length ? criteriaBasedPaths : [`${propertyName}._id`];
    }

    for (const [key, subModel] of Object.entries(model)) {
        // no data in the _meta property we care about
        if (key === '_meta') {
            continue;
        }
        // if the key is "0", it is inside an array, and we should not include it in the path
        // as we want to filter on all array entry, not just entry "0"
        if (key.toString() === '0') {
            return pathsToFilter(propertyName, subModel);
        }
        // In most cases, we just want to recurse into the subModel while adding the key to the path
        const subPaths = pathsToFilter(propertyName, subModel);
        if (subPaths?.length) {
            // this breaks out of the loop AND returns this value as the result of the whole method:
            return subPaths.map(subPath => `${key}.${subPath}`);
        }
    }
    return [];
}
