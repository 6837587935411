//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _doingCreate_file_attachment from './doingCreate_file_attachment';
import _willBulk_file_attachment_remove from './willBulk_file_attachment_remove';
import _willCreate_file_attachment from './willCreate_file_attachment';
export const doingCreate_file_attachment = _doingCreate_file_attachment;
export const willBulk_file_attachment_remove = _willBulk_file_attachment_remove;
export const willCreate_file_attachment = _willCreate_file_attachment;
export default {doingCreate_file_attachment, willBulk_file_attachment_remove, willCreate_file_attachment};

