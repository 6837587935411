// @mdi/js is huge ( > 2mb) so please don't pull it in directly.
// These SVG paths are easy to look up and paste in here.
// For some reason treeshaking doesn't work for @mdi/js.
const cloudArrowUp =
    'M5.8,20c-7.2,0-5.7-9-0.6-9c0-2,0.6-3.6,2-5c1.4-1.3,3.1-2,5-2c1.6,0,2.9,0.5,4.2,1.4s2.1,2.2,2.5,3.7c1.3,0.3,2.3,1,3.1,2c0.8,1,1.2,2.2,1.2,3.5c0,1.5-0.5,2.8-1.6,3.8c-1.1,1.1-2.4,1.6-3.9,1.6h-6.6 M15.9,10.3l-4.1,4.2h2.8V20h2.7v-5.5h2.8L15.9,10.3z';
const cloudArrowDown =
    'M5.8,20c-7.2,0-5.7-9-0.6-9c0-2,0.6-3.6,2-5c1.4-1.3,3.1-2,5-2c1.6,0,2.9,0.5,4.2,1.4s2.1,2.2,2.5,3.7c1.3,0.3,2.3,1,3.1,2c0.8,1,1.2,2.2,1.2,3.5c0,1.5-0.5,2.8-1.6,3.8c-1.1,1.1-2.4,1.6-3.9,1.6h-6.6 M16,20l4.1-4.2h-2.8v-5.5h-2.7v5.5h-2.8L16,20z';

const mdiBarcodeScan =
    'M4,6H6V18H4V6M7,6H8V18H7V6M9,6H12V18H9V6M13,6H14V18H13V6M16,6H18V18H16V6M19,6H20V18H19V6M2,4V8H0V4A2,2 0 0,1 2,2H6V4H2M22,2A2,2 0 0,1 24,4V8H22V4H18V2H22M2,16V20H6V22H2A2,2 0 0,1 0,20V16H2M22,20V16H24V20A2,2 0 0,1 22,22H18V20H22Z';
const mdiChevronDoubleDown =
    'M16.59,5.59L18,7L12,13L6,7L7.41,5.59L12,10.17L16.59,5.59M16.59,11.59L18,13L12,19L6,13L7.41,11.59L12,16.17L16.59,11.59Z';
const mdiChevronDown = 'M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z';
const mdiChevronUp = 'M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z';
const mdiWindowClose =
    'M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z';
const mdiCloseBoxMultiple =
    'M4 20H18V22H4C2.9 22 2 21.11 2 20V6H4V20M20.22 2H7.78C6.8 2 6 2.8 6 3.78V16.22C6 17.2 6.8 18 7.78 18H20.22C21.2 18 22 17.2 22 16.22V3.78C22 2.8 21.2 2 20.22 2M19 13.6L17.6 15L14 11.4L10.4 15L9 13.6L12.6 10L9 6.4L10.4 5L14 8.6L17.6 5L19 6.4L15.4 10L19 13.6Z';
const mdiSignalDistanceVariant =
    'M4,6V4A12,12 0 0,1 16,16H14A10,10 0 0,0 4,6M4,10V8A8,8 0 0,1 12,16H10A6,6 0 0,0 4,10M4,12A4,4 0 0,1 8,16H4V12M3,18H19V16L22,19L19,22V20H3V18Z';

const mdiNumeric1Circle =
    'M10,7V9H12V17H14V7H10M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z';
const mdiNumeric2Circle =
    'M9,7V9H13V11H11A2,2 0 0,0 9,13V17H11L15,17V15H11V13H13A2,2 0 0,0 15,11V9A2,2 0 0,0 13,7H9M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z';
const mdiNumeric3Circle =
    'M15,15V13.5A1.5,1.5 0 0,0 13.5,12A1.5,1.5 0 0,0 15,10.5V9C15,7.89 14.1,7 13,7H9V9H13V11H11V13H13V15H9V17H13A2,2 0 0,0 15,15M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z';
const mdiNumeric4Circle =
    'M9,7V13H13V17H15V7H13V11H11V7H9M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z';
const mdiNumeric5Circle =
    'M9,7V13H13V15H9V17H13A2,2 0 0,0 15,15V13A2,2 0 0,0 13,11H11V9H15V7H9M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z';

const mdiCircleSlice8 =
    'M12 5C15.87 5 19 8.13 19 12C19 15.87 15.87 19 12 19C8.13 19 5 15.87 5 12C5 8.13 8.13 5 12 5M12 2C17.5 2 22 6.5 22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4Z';

export default {
    'cloud-arrow-up': cloudArrowDown,
    'cloud-arrow-down': cloudArrowUp,
    'cloud-upload': cloudArrowUp,
    'cloud-download': cloudArrowDown,
    'barcode-scan': mdiBarcodeScan,
    'keyboard-double-arrow-down': mdiChevronDoubleDown,
    'chevron-up': mdiChevronUp,
    'chevron-down': mdiChevronDown,
    close: mdiWindowClose,
    'close-box-multiple': mdiCloseBoxMultiple,
    'signal-distance-variant': mdiSignalDistanceVariant,
    'numeric-1-circle': mdiNumeric1Circle,
    'numeric-2-circle': mdiNumeric2Circle,
    'numeric-3-circle': mdiNumeric3Circle,
    'numeric-4-circle': mdiNumeric4Circle,
    'numeric-5-circle': mdiNumeric5Circle,
    'circle-slice-8': mdiCircleSlice8
};
