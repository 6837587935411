export default Object.freeze({
    xlsx: {
        _id: '1',
        title: 'xlsx',
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        description: 'Excel 2007+ XML (XLSX)',
        fileExt: '.xlsx',
        bookType: 'xlsx'
    },
    xls: {
        _id: '2',
        title: 'xls',
        contentType: 'application/vnd.ms-excel',
        description: 'Excel 97-2004 Workbook (XLS)',
        fileExt: '.xls',
        bookType: 'biff8'
    },
    csv: {
        _id: '3',
        title: 'csv',
        contentType: 'text/csv',
        description: 'Comma Separated Values (CSV)',
        fileExt: '.csv',
        bookType: 'csv'
    },
    html: {
        _id: '4',
        title: 'html',
        contentType: 'text/html',
        description: 'HTML Document',
        fileExt: '.html',
        bookType: 'html'
    },
    pdf: {
        _id: '5',
        title: 'pdf',
        contentType: 'application/pdf',
        description: 'PDF Document',
        fileExt: '.pdf',
        bookType: 'pdf'
    }
});
