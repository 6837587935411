export default {
    verb: 'doingCreate',
    namespace: 'sensor',
    relation: 'tag',
    status: 'success',
    description: 'Re-populate the fields',
    logic: changeSuccess
};
/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @template T
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function changeSuccess({ data, dispatch }) {
    //if it was not submitted by scan, it means it was submitted by pressing encode.
    if (!data.newRecord.receivedAsScan || !data.newRecord.encodeOnScan) {
        //which means we need to re-add the form's data.
        data.newRecord.receivedAsScan = false;
        //but only after the form has had a chance to clear.
        setTimeout(() => dispatch(data, { verb: 'new', namespace: 'sensor', relation: 'tag' }), 100);
    }
}
