import { createElement as rc, useContext, useEffect, useState } from 'react';
import { fromTheme, View, webOnlyStyles, styled, GestureContext } from 'lib_ui-primitives';
import useSpaceConstrained from '../../../hooks/useSpaceConstrained';
// substract three from height for bottom border
let _Header = styled(View).attrs({ name: 'header' })`
    min-height: ${({ theme: { outerMenus, mobile }, collapsed }) =>
        mobile && collapsed ? '0' : outerMenus.headerHeight + 'px'};
    max-height: ${({ theme: { mobile }, collapsed }) => (mobile && collapsed ? '0' : '99999px')};
    display: flex;
    flex-basis: auto;
    flex-grow: 0;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    background-color: ${fromTheme('outerMenus', 'backgroundColor')};
    border-bottom-color: ${fromTheme('outerMenus', 'border')};
    border-bottom-width: ${({ theme: { outerMenus, mobile }, collapsed }) =>
        mobile && collapsed ? '0' : outerMenus.borderWidth + 'px'};
    overflow: hidden;
`;

_Header = webOnlyStyles(_Header)`
    border-bottom-style: solid;
    transition: min-height 0.3s linear;
`;

/**
 * This will hold page title, buttons for navigation and profile, etc.
 * It will collapse if the app is space constrained or
 * a mobile user swipes up.
 * @param {object} props
 * @returns Main app header component
 */
export default function Header(props) {
    const [spaceConstrained] = useSpaceConstrained();
    const { onSwipeUp, onSwipeDown } = useContext(GestureContext);
    const [collapsed, setCollapsed] = useState(false);
    useEffect(() => {
        // prettier-ignore
        let unsubscribes = [
            onSwipeUp(() => setCollapsed(true)),
            onSwipeDown(() => setCollapsed(false))
        ];
        return () => unsubscribes.forEach(u => u());
    }, [onSwipeDown, onSwipeUp]);

    return rc(_Header, { collapsed: collapsed || spaceConstrained, ...props });
}
