import { useState, useEffect, useCallback, createElement as rc, useMemo } from 'react';
import { testProperties } from 'lib_ui-primitives';
import { useTheme } from 'styled-components';
import { DIRECTION } from '../../../_abstractComponent/DropDown/ArrowIcon';
import useAccessContext from '../useAccessContext';
import useFormControl from '../../../../hooks/useFormControl';
import { ACCESS_STATE, lesserState, accessModifiersToMaxState } from './ACCESS_STATE';
import ViewportAccessToggle from './ViewportAccessToggle';
import {
    AccessNavHeaderPlain,
    CollapseIndicator,
    CollapseOffset,
    CollapseSpacer,
    FullWidthCollapse,
    LinkText,
    MenuRow,
    NavSection
} from './styles';

const NEUTRAL_OPTION = 'Not Configured';

function AccessNavHeading(props) {
    const {
        id: _id,
        hNode: { title, children },
        hNode,
        formElementHNode,
        parentAccessState = ACCESS_STATE.ENABLED
    } = props || { hNode: {} };
    const id = _id || hNode.id;

    // Additional data we need beyond the parentAccessState
    // the maximum access state as configured in Blockly:
    const maxAccessState = useMemo(() => accessModifiersToMaxState(hNode), [hNode]);
    const { allowAccessWhenUndefined = true, selection, setSelection } = useAccessContext();
    const nonRemovable = !!hNode.customizableVisibility?.readonly;
    // the current state, as configured through mobile and desktop access:
    const { mobileProps, desktopProps } = useMemo(
        () => getMobileDesktopProps(hNode, formElementHNode),
        [hNode, formElementHNode]
    );
    const {
        value: allowMobileAccess,
        setValue: setMobileAccess,
        isDefaultValue: mobileDefault,
        defaultReady: mobileReady
    } = useFormControl(mobileProps);
    const {
        value: allowDesktopAccess,
        setValue: setDesktopAccess,
        isDefaultValue: desktopDefault,
        defaultReady: desktopReady
    } = useFormControl(desktopProps);
    // as we have to update the state in 2 steps, we need to suspend updates in between
    const [suspendUpdates, setSuspendUpdates] = useState(false);

    // default the current state to the parent state
    const [currentState, setCurrentState] = useState(parentAccessState);
    // but if the configuration changes, also update the current state
    useEffect(() => {
        if (suspendUpdates || !mobileReady || !desktopReady) return;
        let _allowMobileAccess = allowMobileAccess;
        let _allowDesktopAccess = allowDesktopAccess;
        if (mobileDefault) {
            _allowMobileAccess =
                allowAccessWhenUndefined &&
                [ACCESS_STATE.MOBILE_ONLY, ACCESS_STATE.ENABLED].includes(
                    lesserState(parentAccessState, maxAccessState)
                );
            setTimeout(() => {
                setMobileAccess(_allowMobileAccess);
            }, 0);
        } else if (desktopDefault) {
            _allowDesktopAccess =
                allowAccessWhenUndefined &&
                [ACCESS_STATE.DESKTOP_ONLY, ACCESS_STATE.ENABLED].includes(
                    lesserState(parentAccessState, maxAccessState)
                );
            setTimeout(() => {
                setDesktopAccess(_allowDesktopAccess);
            }, 0);
        }
        let nextState = currentState;
        if (_allowMobileAccess && _allowDesktopAccess) {
            nextState = ACCESS_STATE.ENABLED;
        } else if (_allowMobileAccess) {
            nextState = ACCESS_STATE.MOBILE_ONLY;
        } else if (_allowDesktopAccess) {
            nextState = ACCESS_STATE.DESKTOP_ONLY;
        } else {
            nextState = ACCESS_STATE.DISABLED;
        }
        nextState = lesserState(parentAccessState, nextState, maxAccessState);
        if (nextState !== currentState) {
            setCurrentState(nextState);
        }
    }, [
        suspendUpdates,
        allowMobileAccess,
        allowDesktopAccess,
        currentState,
        parentAccessState,
        maxAccessState,
        setMobileAccess,
        setDesktopAccess,
        allowAccessWhenUndefined,
        mobileDefault,
        desktopDefault,
        mobileReady,
        desktopReady
    ]);

    // some means to persist a new state:
    const updateAccessState = useCallback(
        nextAccessState => {
            if (nonRemovable) return;
            setSuspendUpdates(true);
            setCurrentState(nextAccessState);
            // Actually "persist" the configuration
            // we need to do the 2nd one in a timeout, as otherwise it will clash with the first one,
            // effectively "resetting"/ignoring the first one.
            switch (nextAccessState) {
                case ACCESS_STATE.ENABLED:
                    setMobileAccess(true);
                    setTimeout(() => setDesktopAccess(true), 0);
                    break;
                case ACCESS_STATE.DESKTOP_ONLY:
                    setMobileAccess(false);
                    setTimeout(() => setDesktopAccess(true), 0);
                    break;
                case ACCESS_STATE.MOBILE_ONLY:
                    setMobileAccess(true);
                    setTimeout(() => setDesktopAccess(false), 0);
                    break;
                case ACCESS_STATE.DISABLED:
                    setMobileAccess(false);
                    setTimeout(() => setDesktopAccess(false), 0);
                    break;
            }
            setSuspendUpdates(false);
        },
        [setMobileAccess, setDesktopAccess, nonRemovable]
    );

    // ========= basic UI state management =========
    const { colorScheme } = useTheme();
    const isActive = useMemo(() => selection && selection.id === hNode.id, [hNode.id, selection]);
    const disabledText = parentAccessState === ACCESS_STATE.DISABLED || currentState === ACCESS_STATE.DISABLED;
    const disabledBackground = parentAccessState !== ACCESS_STATE.DISABLED && currentState === ACCESS_STATE.DISABLED;

    const [isCollapsed, setIsCollapsed] = useState(true);
    const toggleIsCollapsed = useCallback(() => {
        setIsCollapsed(prevVal => !prevVal);
        setSelection(undefined);
    }, [setSelection]);
    const toggleSelection = useCallback(
        hNode => {
            setSelection(prev => {
                if (prev?.id === hNode.id) {
                    return undefined;
                }
                return hNode;
            });
        },
        [setSelection]
    );

    const [navHeadingChildren, hasChildren] = useMemo(() => {
        let nhChildren = [];
        if (children?.[0]?.hNodeType === 'TabNav') {
            nhChildren = children[0].children;
        } else if (children?.length) {
            nhChildren = children?.filter(
                child => child.hNodeTypeGroup === 'navHeading' && child.hNodeType !== 'NavHeadingRecordList'
            );
        }
        return [nhChildren, nhChildren.length > 0];
    }, [children]);

    if (maxAccessState == null) {
        // apparently this isn't supposed to be visible for ADMINs
        // NOTE: The SUPPORT role should continue to be configured through MDC/blockly.
        return null;
    }

    // prettier-ignore

    return rc(NavSection, { disabled: disabledBackground },
        rc(MenuRow, { isActive },
            !hasChildren && rc(CollapseSpacer),
            hasChildren && rc(CollapseIndicator, { color: colorScheme['white-text'], ...testProperties({ id }, 'chevron'), id: `chevron-${id}`, isOpen: !isCollapsed, closed: DIRECTION.right, open: DIRECTION.down }),
            rc(AccessNavHeaderPlain, { id, onClick: ()=> hasChildren ? toggleIsCollapsed() : toggleSelection(hNode)},
                rc(LinkText, {disabled: disabledText}, title)
            ),                
            rc(ViewportAccessToggle,
                {
                    id: hNode.id + '-viewport-toggle', 
                    value: currentState,
                    parentAccessState, 
                    maxAccessState,
                    nonRemovable,
                    onChange: updateAccessState
                
                }, 
            )
        ),
        // the children as subsection:
        hasChildren && rc(CollapseOffset, null,
            rc(FullWidthCollapse, { isCollapsed: isCollapsed }, 
               !isCollapsed && navHeadingChildren.map((navHeading, i) => 
                    rc(AccessNavHeading, { 
                            key:(navHeading.id || navHeading.title)+i, 
                            hNode: navHeading, 
                            formElementHNode,
                            parentAccessState:lesserState(parentAccessState, currentState) 
                    })
                )
            )
        )
    );
}

function getMobileDesktopProps(hNode, formElementHNode) {
    const mobileProps = {
        hNode: {
            id: hNode.id + '-mobile',
            propertyPath: [formElementHNode.propertyName, 'rights', hNode.id].join('.'),
            propertyName: 'mobile',
            title: 'Display on Mobile Devices',
            readOnly: false,
            toggleValues: ['No', 'Yes'],
            hNodeType: 'Toggle',
            neutralOption: NEUTRAL_OPTION
        }
    };
    const desktopProps = {
        hNode: {
            id: hNode.id + '-desktop',
            propertyPath: [formElementHNode.propertyName, 'rights', hNode.id].join('.'),
            propertyName: 'desktop',
            title: 'Display on Desktop and Tablet Browsers',
            readOnly: false,
            toggleValues: ['No', 'Yes'],
            hNodeType: 'Toggle',
            neutralOption: NEUTRAL_OPTION
        }
    };
    return { mobileProps, desktopProps };
}

export default AccessNavHeading;
