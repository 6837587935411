import { useEffect, createElement as rc, createContext, useRef } from 'react';

export const GestureContext = createContext();

const pointInsideSuspensionRectangle = async (x, y, suspensions) => {
    let inside;
    for (let i = 0; i < suspensions.length; i++) {
        const getRectFunc = suspensions[i];
        const rect = await getRectFunc();
        inside = rect.x <= x && x <= rect.x + rect.width && rect.y <= y && y <= rect.y + rect.height;
        if (inside) break;
    }
    return inside;
};

export default function GestureRecognizer(props) {
    let xDown = null;
    let yDown = null;

    const swipeLefts = useRef([]);
    const swipeRights = useRef([]);
    const swipeUps = useRef([]);
    const swipeDowns = useRef([]);

    const suspensions = useRef({ left: [], right: [] });

    /**
     * This must receive a function param because different environments have different means of
     * calculating the rectangle and because the rectangle must be evaluated at the time of the
     * event to avoid layout changes invalidating the rectangle dimensions.
     * @param {function} getRectFunc returns a rectangle for which left swipes will be ignored
     * @returns unsuspend function
     */
    function suspendLeftSwipe(getRectFunc) {
        suspensions.current.left.push(getRectFunc);
        return () => suspensions.current.left.splice(suspensions.current.left.indexOf(getRectFunc), 1);
    }

    /**
     * This must receive a function param because different environments have different means of
     * calculating the rectangle and because the rectangle must be evaluated at the time of the
     * event to avoid layout changes invalidating the rectangle dimensions.
     * @param {function} getRectFunc returns a rectangle for which right swipes will be ignored
     * @returns unsuspend function
     */
    function suspendRightSwipe(getRectFunc) {
        suspensions.current.right.push(getRectFunc);
        return () => suspensions.current.right.splice(suspensions.current.right.indexOf(getRectFunc), 1);
    }

    function onSwipeLeft(callback) {
        swipeLefts.current.push(callback);
        return () => swipeLefts.current.splice(swipeLefts.current.indexOf(callback));
    }
    function onSwipeRight(callback) {
        swipeRights.current.push(callback);
        return () => swipeRights.current.splice(swipeRights.current.indexOf(callback));
    }
    function onSwipeUp(callback) {
        swipeUps.current.push(callback);
        return () => swipeUps.current.splice(swipeUps.current.indexOf(callback));
    }
    function onSwipeDown(callback) {
        swipeDowns.current.push(callback);
        return () => swipeDowns.current.splice(swipeDowns.current.indexOf(callback));
    }

    // https://stackoverflow.com/a/30192291/1356444
    function handleTouchStart(evt) {
        xDown = evt.touches[0].clientX;
        yDown = evt.touches[0].clientY;
    }

    function handleTouchMove(evt) {
        if (xDown == null || yDown == null) {
            return;
        }
        const xUp = evt.touches[0].clientX;
        const yUp = evt.touches[0].clientY;
        const xDiff = xDown - xUp;
        const yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            /*most significant*/
            if (xDiff > 0) {
                /* left swipe */
                pointInsideSuspensionRectangle(xDown, yDown, suspensions.current.left).then(inside => {
                    if (!inside) {
                        swipeLefts.current.forEach(callback => callback());
                    }
                });
            } else {
                /* right swipe */
                pointInsideSuspensionRectangle(xDown, yDown, suspensions.current.right).then(inside => {
                    if (!inside) {
                        swipeRights.current.forEach(callback => callback());
                    }
                });
            }
        } else {
            if (yDiff > 0) {
                /* up swipe */
                swipeUps.current.forEach(callback => callback());
            } else {
                /* down swipe */
                swipeDowns.current.forEach(callback => callback());
            }
        }
        /* reset values */
        xDown = null;
        yDown = null;
    }
    useEffect(() => {
        document.addEventListener('touchstart', handleTouchStart, false);
        document.addEventListener('touchmove', handleTouchMove, false);
        return () => {
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchmove', handleTouchMove);
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return rc(
        GestureContext.Provider,
        { value: { onSwipeDown, onSwipeLeft, onSwipeRight, onSwipeUp, suspendLeftSwipe, suspendRightSwipe } },
        props.children
    );
}
