import { errors as _errors } from 'lib_ui-primitives';
/**
 * Code shared with `will*_metadata_relation` rules to prevent changes to locked metadata versions
 */
//import as:
// import lockedVersion, { prerequisite, preventChangesWhenLocked } from '../useCaseDetail/lockedVersion'

/**
 * Generate a prerequisite that fetches the useCaseDetail for the current record
 * (which might be itself)
 * then for the assigned metaui:useCase on that record
 * finds the useCaseDetail with the highest locked version
 * And returns that (in an object with a single item array in its "result" property)
 */
export function prerequisites() {
    return [
        //make sure we have a full record of the useCaseDetail
        {
            context: {
                verb: 'get',
                namespace: 'metadata',
                relation: 'useCaseDetail',
                type: 'get' //get by id
            },
            query: ({ data, context }) => {
                let _id;
                if (data.skipConfirm) {
                    // Does not abort entire workflow:
                    throw new Error('Removal through sockets. No need to check.');
                }
                const record = data.newRecord || data.record || data;
                if (context.relation === 'useCaseDetail') {
                    _id = record._id;
                } else if (record?.['metadata:useCaseDetail']?._id) {
                    _id = record['metadata:useCaseDetail']._id;
                }

                return {
                    _id
                };
            }
        },
        //get the highest locked version
        {
            context: {
                verb: 'get',
                namespace: 'metadata',
                relation: 'useCaseDetail',
                type: 'find' //based on some query
            },
            // //first we need to find the useCaseDetail
            // so that we can then find the highest locked version for the associated usecase
            // Except prerequsiteresults are not passed into query....
            // prerequisites: [
            //     {
            //         context: {
            //             verb: 'get',
            //             namespace: 'metadata',
            //             relation: 'useCaseDetail',
            //             type: 'get' //get by id
            //         },
            //         query: ({ data, context }) => {
            //             let _id;
            //             const record = data.newRecord || data.record;
            //             if (context.relation === 'useCaseDetail') {
            //                 _id = record._id;
            //             } else {
            //                 _id = record['metadata:useCaseDetail']._id;
            //             }

            //             return {
            //                 _id
            //             };
            //         }
            //     }
            // ],
            query: async ({ data, context, dispatch }) => {
                // so instead of in a prerequisite, pull the current useCaseDetail here
                let _id;
                if (data.skipConfirm) {
                    // Does not abort entire workflow:
                    throw new Error('Removal through sockets. No need to check.');
                }
                const record = data.newRecord || data.record || data;
                if (context.relation === 'useCaseDetail') {
                    _id = record._id;
                } else {
                    _id = record['metadata:useCaseDetail']._id;
                }

                const thisUcVersion = await dispatch(
                    {
                        query: { _id }
                    },
                    {
                        verb: 'get',
                        namespace: 'metadata',
                        relation: 'useCaseDetail',
                        type: 'get' //get by id
                    },
                    true
                );

                const [{ 'metaui:useCase': { _id: useCaseId } = {} }] = thisUcVersion.result || [];
                if (!useCaseId) throw new Error('Failed to find use case _id');
                return {
                    criteria: {
                        'metaui:useCase._id': useCaseId,
                        locked: true,
                        'meta.deleted': { $exists: false }
                    },
                    //order by highest version first
                    orderBy: (x, y) => {
                        //if e.g. y.major === x.major, the difference is 0
                        // 0 || <something> = <something>
                        // return value > 0, put x after y
                        // so, if e.g y.major = 2, x.major = 1, then result = 1
                        // meaning y is sorted before x.
                        return y.major - x.major || y.minor - x.minor || y.patch - x.patch;
                    },
                    // we only need the highest locked one
                    page: { limit: 1 }
                };
            }
        }
    ];
}

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result?:Array<object>, error?:string}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
export function preventChangesWhenLocked({ data, prerequisiteResults, dispatch, context }) {
    if (data.skipConfirm) return data;
    // If the record is being locked, don't check for locked versions
    if (data?.type === 'USE_CASE_VERSION_LOCK') return data;
    const highestLockedVersionRecord = prerequisiteResults[1].result?.[0];
    if (prerequisiteResults[0].error) {
        const message = `Failed to save record: ${prerequisiteResults[0].error}`;
        dispatch(
            { message, timeout: 5000, addToList: false, isError: true },
            {
                verb: 'pop',
                namespace: 'application',
                relation: 'notification'
            }
        );
        throw new Error(message);
    }
    const currentVersionRecord = prerequisiteResults[0].result[0];
    if (lockedVersion(highestLockedVersionRecord).isLessThan(currentVersionRecord)) {
        //all good
        return;
    }
    if (highestLockedVersionRecord._id === currentVersionRecord._id) {
        if (!currentVersionRecord.locked) {
            if (context?.user?.['identity:role'].title === 'SUPPORT') {
                // Only SUPPORT can lock a use case version
                return;
            }
        }
    }
    const message = `You cannot make changes to a version lower than ${highestLockedVersionRecord.version}.`;
    dispatch(
        { message, timeout: 5000, addToList: false, isError: true },
        {
            verb: 'pop',
            namespace: 'application',
            relation: 'notification'
        }
    );
    throw new _errors.ValidationError(message, {});
}

/**
 * Provides chainable interface for added readability to compare 2 versions.
 * @example lockedVersion(prerequisiteResults[1].result[0]).isLessThan(prerequisiteResults[0].record[0])
 * @param {{major:number, minor:number, patch:number}} locked Highest locked version
 * @returns {{isLessThan:function({major:number, minor:number, patch:number}):boolean}} true if something else has a higher version than locked
 */
export default function lockedVersion(locked) {
    return {
        //if the locked version is LESS than the other version, the other one is NOT locked
        //if it is equal or more, the other one IS locked
        isLessThan: somethingElse => {
            if (!locked) return true;
            return (
                (locked.major - somethingElse.major ||
                    locked.minor - somethingElse.minor ||
                    locked.patch - somethingElse.patch) < 0
            );
        }
    };
}
