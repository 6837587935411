import notHandledHere from './notHandledHere';
import { network, http, ObjectId, constants } from 'lib_ui-services';
import upsert from '../../../CRUD/namespace/relation/doingUpsert_namespace_relation';

const _p = {
    getQueryUrl: http.getQueryUrl,
    get: http.get,
    upsert: upsert.logic,
    getNetworkStatus: network.getStatus
};
export const _private = _p;

export default {
    verb: 'doingGetFromServer',
    excludedNamespaceRelations: notHandledHere,
    description: 'Get data from the server instead of LokiJS',
    //this is the actual logic:
    logic: doingGetFromServer,
    onError
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: {
 *      query:{
 *        criteria:{searchCriteria:object},
 *        orderBy?:string,
 *        page?:{offset:number,limit:number}
 *      },
 *      skipLookup?:boolean
 *   };
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {{
 *     query:{
 *        criteria:object,
 *     }
 *     result?:object[]
 * }}
 */
async function doingGetFromServer({ data, context, dispatch, log }) {
    const networkStatus = await _p.getNetworkStatus();
    const { query, skipLookup = false } = data;
    if (!networkStatus.isOnline || skipLookup) {
        return data;
    }
    dispatch(
        { busy: true, source: 'httpSync', message: 'Querying Server', type: constants.notificationTypes.SYNC_BUSY },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
    const { namespace, relation } = context;
    let result = [];
    try {
        const correlationId = new ObjectId();
        const url = _p.getQueryUrl(namespace, relation, query.criteria, correlationId);
        const httpResult = await _p.get(url);
        result = analyzeHttpResult(httpResult);
        if (result.length > 0) {
            await _p.upsert({ data: { items: result }, context: { ...context, verb: 'upsert' } });
        }
    } catch (error) {
        log.error(error);
    }

    dispatch(
        {
            busy: false,
            source: 'httpSync',
            message: 'Server Query Done',
            type: constants.notificationTypes.SYNC_BUSY
        },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
    return { ...data, result };
}

const analyzeHttpResult = httpResult => {
    // If there is no content (http 204/202/304) from the server, skip.
    if (!httpResult || httpResult === 'Accepted - No Content') {
        return [];
    }
    const items = httpResult.items || httpResult; // handle old and new api.
    if (!Array.isArray(items)) {
        throw new Error(
            `The lookup result from the server is in an unexpected format.  Details: doingGet_item_item method,  result: ${JSON.stringify(
                items
            )}`
        );
    }
    return items;
};

function onError({ error, dispatch }) {
    dispatch(
        {
            busy: false,
            source: 'httpSync',
            message: 'Server Query Failed',
            type: constants.notificationTypes.SYNC_BUSY
        },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
    throw error;
}
