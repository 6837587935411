export default database => context => {
    const { namespace, relation } = context;
    if (!namespace || typeof namespace !== 'string') {
        throw new Error('Invalid namespace');
    }

    if (!relation || typeof relation !== 'string') {
        throw new Error('Invalid relation');
    }

    const db = database.relationDb(namespace, relation);

    // We need to get the total size of the relation, which is in db._private.collection.data
    if (!Array.isArray(db?._private?.collection?.data)) {
        throw new Error('Invalid database structure');
    }

    return db._private.collection.data.length;
};
