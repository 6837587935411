import { createContext, useState, useEffect, createElement as rc, useMemo } from 'react';
import PropTypes from 'prop-types';
import useEventSink from '../../hooks/useEventSink';
import { h3, styled, View, fromTheme, Text, hooks } from 'lib_ui-primitives';
import { metadata } from 'lib_ui-services';
const { getPrettyRelationName } = metadata;
const { useRouter } = hooks;

const NO_CONTEXT_AVAILABLE = { available: false, loading: false };

const _p = {
    getPrettyRelationName,
    useRouter
};

const NavigationContextContainer = styled(View).attrs({ name: 'NavigationContextContainer' })`
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
`;
NavigationContextContainer.displayName = 'NavigationContextContainer';

const Message = styled(h3)`
    margin-left: ${fromTheme('viewMarginMore')};
`;

const CrumbTrail = styled(Text)`
    margin-left: ${fromTheme('viewMarginMore')};
    margin-top: ${fromTheme('viewMargin')};
    color: ${fromTheme('unobtrusiveFontColor')};
`;

export const _private = _p;

/**
 * @typedef { object } NavigationSelectionContextType
 * @property { boolean } available,
 * @property { boolean } loading
 * @property { string } namespace
 * @property { string } relation
 * @property { string } [_id]
 * @property { object } [record]
 * @property { string } record._id
 */

/**
 * @type {React.Context<NavigationSelectionContextType>}
 */
export const NavigationSelectionContext = createContext(NO_CONTEXT_AVAILABLE);

function NavigationSelectionContextBoundary(props) {
    const {
        hNode: { namespace, relation, includeBreadcrumb },
        children,
        //just for testing....
        record,
        available = false,
        loading = true
    } = props;
    const [value, setValue] = useState({ namespace, relation, record, available, loading });
    const [, , request] = useEventSink();
    const router = _p.useRouter();
    const { query, registerRouteStateKey } = router;

    const unavailableMessage = useMemo(() => {
        const prettyRelationName = _p.getPrettyRelationName(namespace, relation);
        return `${prettyRelationName} Unavailable`;
    }, [namespace, relation]);

    useEffect(() => {
        registerRouteStateKey(`${namespace}_${relation}`);
    }, [registerRouteStateKey, namespace, relation]);

    useEffect(() => {
        //if we are testing, don't do anything automatically
        if (record) return;

        let allowStateUpdate = true;

        async function requestRecordAsync() {
            const data = await request({ _id: qValue }, { verb: 'get', namespace, relation, type: 'get' });
            if (allowStateUpdate) {
                const result = data.result[0];
                setValue(prev => {
                    if (!result) {
                        //should we redirect?
                        return NO_CONTEXT_AVAILABLE;
                    }
                    //if we already had our record, or if this is not (any longer) the record we are waiting for
                    if (prev.available || prev._id !== result._id) {
                        // cause no rerenders.
                        return prev;
                    }
                    return {
                        _id: result._id,
                        record: result,
                        available: true,
                        loading: false,
                        namespace: prev.namespace,
                        relation: prev.relation
                    };
                });
            }
        }

        const qValue = query?.[`${namespace}_${relation}`];
        if (qValue) {
            setValue(prev => {
                if (prev._id === qValue) {
                    return prev;
                } else {
                    return {
                        _id: qValue,
                        available: false,
                        loading: true,
                        namespace: prev.namespace,
                        relation: prev.relation
                    };
                }
            });
            requestRecordAsync();
        } else {
            setValue(NO_CONTEXT_AVAILABLE);
        }
        return () => (allowStateUpdate = false);
    }, [namespace, query, relation, request, record]);

    if (value.loading) {
        return rc(Message, null, 'Loading...');
    }
    if (!value.available) {
        return rc(Message, null, unavailableMessage);
    }

    return rc(
        NavigationContextContainer,
        null,
        rc(
            NavigationSelectionContext.Provider,
            { value },
            //Comment this line out if we don't want the breadcrumb
            includeBreadcrumb && rc(BreadCrumb, value?.record),
            children
        )
    );
}

NavigationSelectionContextBoundary.propTypes = {
    namespace: PropTypes.string,
    relation: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
};

export default NavigationSelectionContextBoundary;

function BreadCrumb({ title = '' }) {
    const path = _p.useRouter().location.pathname;
    const routePieces = path?.split('/') || '';
    const currentPage = routePieces[routePieces.length - 1];
    return rc(CrumbTrail, { id: 'breadCrumb' }, ` > ${currentPage} > ${title}`);
}
