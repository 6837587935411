import { createContext, createElement as rc, useState } from 'react';

export const SpaceConstrainedContext = createContext();

/**
 * Provide the SpaceConstrainedContext - this allows small mobile views to be
 * aware if they need to hide headers or similar to give the user more real estate.
 * @param {object} props
 * @returns SpaceConstrainedContext providing component
 */
export default function SpaceConstrained(props) {
    const [spaceConstrained, setConstrained] = useState(false);
    return rc(SpaceConstrainedContext.Provider, { value: { spaceConstrained, setConstrained } }, props.children);
}
