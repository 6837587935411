import logging from '@sstdev/lib_logging';

// The service worker installer workflow will not actually start until
// the UI is ready.
// (see lib_ui-service-worker\src\installer\services.js svc_waitForInitialRender())
export default function register() {
    if ('serviceWorker' in navigator) {
        logging.debug('[SERVICEWORKER] registering at /service-worker_v2.js');
        navigator.serviceWorker.register('./service-worker_v2.js', { scope: '/' });
    }
}
