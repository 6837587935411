import { createElement as rc, forwardRef } from 'react';
import { styled, Label, fromTheme } from 'lib_ui-primitives';
import PropTypes from 'prop-types';

const paddedLabel = styled(Label).attrs({ name: 'text-label' })`
    margin: ${fromTheme('viewMargin')};
`;
paddedLabel.displayName = 'textLabel';

const TextLabel = forwardRef(function FormLabel(props, ref) {
    const {
        hNode: { id, title }
    } = props;

    // prettier-ignore
    return rc(paddedLabel, { id, ref }, title);
});
TextLabel.propTypes = {
    hNode: PropTypes.shape({
        title: PropTypes.string
    }).isRequired
};

export default TextLabel;
