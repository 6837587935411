import { Button, Switch, styled, nativeOnlyStyles, View, Label, fromTheme, webOnlyStyles, h2 } from 'lib_ui-primitives';

export const UnknownTagSwitch = styled(Switch)`
    margin: 2px;
`;

/**
 * flex-grow will allow this to take more space if it is available. flex-basis of 1
 * means it will start out with the smallest proportion of space compared to its peers
 * (assuming they have the default flex-basis = auto).
 * This allows the peers to take up as much room as they need to actually get their
 * controls on the screen, but no more.
 */
export const GreedyView = styled(View)`
    overflow: hidden;
    flex-direction: column;
    flex-grow: 1;
    max-width: ${fromTheme('width')};
    flex-basis: 1px;
`;

let _ShowUnknownTags = styled(View).attrs({ name: 'show-unknown-tags' })`
    position: absolute;
    left: ${fromTheme('viewMargin')};
    bottom: 18px;
    z-index: ${({ theme }) => theme.zindex.ShowUnknownTags};
    align-items: center;
    background-color: ${({ theme }) => (theme.darkMode ? '#000000BB' : '#FFFFFFBB')};
    border-color: ${fromTheme('baseBackgroundColorDarker')};
    border-width: 1px;
    border-radius: ${fromTheme('borderRadius')};
    padding-left: 3px;
    padding-right: ${fromTheme('viewPadding')};
    overflow: visible;
`;

export const ShowUnknownTags = webOnlyStyles(_ShowUnknownTags)`
    border-style: solid;
`;

export const ShowUnknownTagsLabel = styled(Label).attrs({ name: 'show-unknown-tags-label' })`
    margin-left: ${fromTheme('textMargin')};
    min-width: 0;
    font-size: 14px;
`;

let _ReadButton = styled(Button).attrs({ name: 'read-button' })`
    position: absolute;
    right: ${fromTheme('viewMargin')};
    bottom: 14px;
    z-index: ${({ theme }) => theme.zindex.SensorReadListReadButton};
`;

export const ReadButton = nativeOnlyStyles(_ReadButton)`
    elevation: 1;
`;

export const CenterText = styled(View).attrs({ name: 'center-text' })`
    margin-top: ${fromTheme('viewMarginMore')};
    justify-content: center;
    align-items: center;
`;
CenterText.displayName = 'CenterText';

export const WorkingText = styled(h2).attrs({ name: 'working-text' })``;
WorkingText.displayName = 'WorkingText';
