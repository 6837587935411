import logging from '@sstdev/lib_logging';
import lodash from 'lodash';
const { merge } = lodash;

export default _private => {
    const { startPromise, collection } = _private;
    return (docs, mergeIntoInserts) => {
        return startPromise.then(() => {
            let updated = 0,
                inserted = 0,
                errors = 0;
            for (const doc of docs) {
                try {
                    const foundDoc = collection.by('_id', doc._id);
                    if (foundDoc) {
                        const newDoc = {
                            ...doc,
                            meta: {
                                ...foundDoc.meta,
                                ...doc.meta
                            },
                            $loki: foundDoc.$loki,
                            _idx: foundDoc._idx
                        };
                        // Don't allow this to be deleted during cleanup of a
                        // tempQueryResult if it existed previously.
                        delete newDoc.meta.forTempQueryResult;
                        collection.update(newDoc);
                        updated++;
                    } else {
                        merge(doc, mergeIntoInserts);
                        collection.insert(doc);
                        inserted++;
                    }
                } catch (err) {
                    errors++;
                    logging.error(err);
                }
            }
            return { updated, inserted, errors };
        });
    };
};
