import { useRef, useCallback, createContext, createElement as rc, useState, useContext } from 'react';

export const TabContext = createContext();
export function useSelection() {
    return useContext(TabContext);
}
/**
 * This keeps track of which tab has been selected
 * @param {object} props
 * @returns Selection context provider
 */
export default function Selection(props) {
    const { numTabs } = props;
    const [selectedIndex, setSelectedIndex] = useState(0);
    const disabledTabs = useRef([]);
    const hiddenTabs = useRef([]);

    /**
     * Check if the given tab index is hidden or disabled.
     */
    const indexHiddenOrDisabled = useCallback(tabIndex => {
        return hiddenTabs.current.includes(tabIndex) || disabledTabs.current.includes(tabIndex);
    }, []);

    /**
     * If a any tab has changed visibility or whether enabled, make
     * sure a visible and enabled tab is displayed.
     */
    const reevaluateSelectedTab = useCallback(() => {
        // currently selected tab is visible and enabled. No need to
        // look for another.
        if (!indexHiddenOrDisabled(selectedIndex)) {
            return;
        } else {
            // Find the first visible and enabled tab and select it.
            for (let i = 0; i < numTabs; i++) {
                if (!indexHiddenOrDisabled(i)) {
                    setSelectedIndex(i);
                    break;
                }
            }
        }
    }, [indexHiddenOrDisabled, selectedIndex, numTabs]);

    const setIfIndexDisabled = useCallback(
        (tabIndex, isDisabled) => {
            if (isDisabled && !disabledTabs.current.includes(tabIndex)) {
                disabledTabs.current.push(tabIndex);
            }
            if (!isDisabled && disabledTabs.current.includes(tabIndex)) {
                disabledTabs.current.splice(disabledTabs.current.indexOf(tabIndex), 1);
            }
            reevaluateSelectedTab();
        },
        [reevaluateSelectedTab]
    );

    const setIfIndexHidden = useCallback(
        (tabIndex, isHidden) => {
            if (isHidden && !hiddenTabs.current.includes(tabIndex)) {
                hiddenTabs.current.push(tabIndex);
            }
            if (!isHidden && hiddenTabs.current.includes(tabIndex)) {
                hiddenTabs.current.splice(hiddenTabs.current.indexOf(tabIndex), 1);
            }
            reevaluateSelectedTab();
        },
        [reevaluateSelectedTab]
    );

    return rc(
        TabContext.Provider,
        { value: [selectedIndex, setSelectedIndex, setIfIndexDisabled, setIfIndexHidden] },
        props.children
    );
}
