import { constants } from 'lib_ui-services';
const { useCaseIds } = constants;
import { calculateDepreciation } from './depreciation';
export default {
    verb: 'didGet',
    namespace: 'item',
    relation: 'item',
    description: 'Depreciation Calculation',
    useCaseIds: [useCaseIds.ASSET_TRACKING, useCaseIds.AMERICAN_WATER_ASSET_TRACKING],
    //this is the actual logic:
    logic: didGet
};

/**
 * @param {Object} hNode
 * @param {string} searchText
 * @param {boolean} [forceOnline=true]
 */
async function didGet({ data }) {
    data.result = calculateDepreciation(data.result);
    return data;
}
