import { http } from 'lib_ui-services';
import defaultRecordPrep from '../../namespace/relation/willCreate_namespace_relation';

export default {
    verb: 'doingCreate',
    namespace: 'file',
    relation: 'attachment',
    priority: 10,
    prerequisites: [],
    description: 'Add metadata and create the given file on the server',
    // this is the actual logic:
    logic: doingCreate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 * @typedef {string} Base64EncodedString
 *
 * @typedef {Object} Attachment
 * @prop {string} _id unique ID
 * @prop {string} title unique ID as string to guarantee uniqueness
 * @prop {string} filename original filename
 * @prop {string} url full path to retrieve file
 * @prop {string} size file size in bytes
 * @prop {string} type mimetype, e.g. image/jpeg
 * @prop {'base64'|string} [encoding]
 * @prop {Base64EncodedString}  [content]
 * @prop {File} [file]
 */

/**
 * @param {{
 *   data: {newRecord: Attachment};
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {{newRecord:Attachment}}
 */
async function doingCreate({ data, context, dispatch }) {
    const { content, file, ...newRecord } = data.newRecord;
    // get meta data and default values
    const { newRecord: recordWithMeta } = await defaultRecordPrep.logic({ data: { newRecord }, context, dispatch });
    // We can't use the standard way to create the record because we can't wait for it to be queued
    // No need to wait for the result. If it went wrong, it will throw an error.
    await http.post('/api/file/attachment', recordWithMeta);
    dispatch(
        { isError: false, message: `Uploading ${newRecord.filename}` },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
    // now, depending on HOW we got the file, we need to upload it to the server
    if (content) {
        // we got the file as base64 encoded string.
        // Stick it in a format as expected by lib_base-server baseRoutes.js `contentRoute()`
        const payload = {
            filename: newRecord.filename,
            // the server does NOT want the file type in the content string
            content: content.replace('data:image/jpeg;base64,', ''),
            encoding: newRecord.encoding || 'base64',
            mimeType: newRecord.type
        };
        await http.post(`/api/file/attachment/${newRecord._id}/content`, JSON.stringify(payload));
    } else if (file) {
        // We got a `File` reference.
        // `fetch` knows how to handle that natively if we pass it in as a form
        let formData = new FormData();
        formData.append('file', file);
        await http.post(`/api/file/attachment/${newRecord._id}/content`, formData);
    }
    dispatch(
        { isError: false, message: `Uploading ${newRecord.filename} completed` },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
}
