import applyUpdateOperators from '../databaseOperations/_applyUpdateOperators';
export default _private => {
    const { startPromise, collection } = _private;
    return function updateMany(criteria, operations) {
        return startPromise.then(() => {
            const results = collection
                .chain()
                .find(criteria)
                .update(doc => applyUpdateOperators(doc, operations));
            return { updated: results.filteredrows.length };
        });
    };
};
