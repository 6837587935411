/**
 * Remove many CRUD
 * @param {object} action persist action dispatched to remove a document
 */
export default database => (payload, context) => {
    const { namespace, relation } = context;
    const getResult = database.relationDb(namespace, relation).truncateCollectionToMaxSize(payload);

    return getResult.then(result => database.processSuccess([], { ...payload, result }, context));
};
