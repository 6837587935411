import { createElement as rc, cloneElement, Fragment, useMemo } from 'react';
import logging from '@sstdev/lib_logging';
import PropTypes from 'prop-types';
import { filters as allFilters } from 'lib_ui-services';
import useFormControl from '../../hooks/useFormControl';
import useFilterMetadata from '../../hooks/useFilterMetadata';

const _p = {
    useFormControl
};

/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function NestedObject(props) {
    const {
        hNode,
        hNode: { propertyName: _propertyName },
        children = []
    } = props || { hNode: {} };

    const { value } = _p.useFormControl(props);

    const metadataBasedFilters = useFilterMetadata(hNode);

    const [propertyName, readOnly] = useMemo(() => {
        let propertyName = _propertyName;
        let readOnly = false;
        if (value && Array.isArray(value) && value.length) {
            if (Object.keys(metadataBasedFilters).length) {
                const conditions = Object.entries(metadataBasedFilters).map(([key, value]) => {
                    if (!allFilters[key]?.getJavaScriptFilter) {
                        logging.warn(
                            `[FILTER] NestedObject filter ${key} does not have a 'getJavaScriptFilter()' implementation`
                        );
                        return () => true;
                    } else {
                        return allFilters[key]?.getJavaScriptFilter(value);
                    }
                });
                //find the (first) entry that matches ALL/Every condition defined by the filters
                const index = value.findIndex(entry => conditions.every(condition => condition(entry)));
                if (index === -1) {
                    //if nothing is found that matches, force child elements to use an index that does not exist
                    // arrays are 0-based. zo, the length of an array with 1 element is 1
                    // and arrayWith1Element[1] is therefore non-existent
                    //
                    propertyName += `.${value.length}`;
                    readOnly = true;
                } else {
                    //if we found it, have child components use that specific array element
                    propertyName += `.${index}`;
                }
            } else {
                //if we have no filters, just use the FIRST entry
                propertyName += '.0';
            }
        }
        return [propertyName, readOnly];
    }, [_propertyName, metadataBasedFilters, value]);

    //We are letting React render the nested elements first, so we don't need any logic (or references) to that
    //then, we clone, inject with our propertyPath, memoize and replace those elements.
    //(The memoization is so that on subsequent renders, our cloned and updated element is directly rendered.)
    const nestedElements = useMemo(
        () =>
            [].concat(children).map(element => {
                let {
                    hNode,
                    hNode: { id, propertyPath }
                } = element.props;

                if (propertyPath) {
                    propertyPath = `${propertyName}.${propertyPath}]`;
                } else {
                    propertyPath = propertyName;
                }

                return cloneElement(element, {
                    ...element.props,
                    hNode: { ...hNode, propertyPath, readOnly },
                    key: id
                });
            }),
        [children, propertyName, readOnly]
    );

    return rc(Fragment, null, ...nestedElements);
}

NestedObject.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired
};

export default NestedObject;
