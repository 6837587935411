import react, { useState, useEffect } from 'react';
import useEventSink from '../../hooks/useEventSink';
import logging from '@sstdev/lib_logging';
import { Text } from 'lib_ui-primitives';
import PropTypes from 'prop-types';
import lodash from 'lodash';

const { get } = lodash;
const rc = react.createElement;

export default function ShortTextView(props) {
    const { namespace, relation, propertyName } = props.hNode;
    const [, , request] = useEventSink();
    const [text, setText] = useState('');
    useEffect(() => {
        async function getText() {
            const data = await request({}, { verb: 'get', namespace, relation });
            if (data.result && data.result.length === 1) {
                setText(get(data.result[0], propertyName));
            } else {
                logging.warn(`Unexpected results from get_${namespace}_${relation}`);
            }
        }
        getText();
    }, [request, namespace, relation, propertyName]);
    return rc(Text, { id: props.hNode.id }, text);
}

ShortTextView.propTypes = {
    hNode: PropTypes.object
};
