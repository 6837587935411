import { Text, View, TextInput, Button, styled, fromTheme } from 'lib_ui-primitives';
import _FormField from '../../formElement/FormField';
import SensorReadButton from '../SensorReadButton';
import { nativeOnlyProperties } from 'lib_ui-services';

export const ShowUnknownTags = styled(View)`
    margin-left: ${fromTheme('viewMargin')};
    align-items: center;
    padding-left: 3px;
`;
ShowUnknownTags.displayName = 'ShowUnknownTags';

export const ButtonBar = styled(View)`
    flex-direction: row;
    flex-grow: 0;
    width: 100%;
    max-width: ${fromTheme('width')};
    justify-content: space-between;
    flex-shrink: 0;
`;
ButtonBar.displayName = 'ButtonBar';

export const Container = styled(View).attrs({ name: 'container' })`
    width: 100%;
    flex-direction: row;
    flex-grow: 0;
    max-width: ${fromTheme('width')};
`;

export const GreedyView = styled(View).attrs({ name: 'greedy-view' })`
    overflow: hidden;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    max-width: ${fromTheme('width')};
    flex-basis: 1px;
`;
GreedyView.displayName = 'GreedyView';

export const BoundingView = styled(View)`
    flex-direction: column;
    max-height: ${fromTheme('height')};
    flex-grow: 1;
    flex-shrink: 1;
`;
BoundingView.displayName = 'BoundingView';

export const LittleDarker = styled(GreedyView).attrs({ name: 'little-darker' })`
    background-color: ${fromTheme('backgroundColor')};
`;
LittleDarker.displayName = 'LittleDarker';

export const CloseModalButton = styled(Button).attrs({ name: 'close-modal-button' })`
    align-self: flex-end;
`;
CloseModalButton.displayName = 'CloseModalButton';

export const MarkFoundButton = styled(Button).attrs({ name: 'save-all-button' })`
    align-self: flex-end;
    margin: 6px;
`;
MarkFoundButton.displayName = 'MarkFoundButton';

export const CheckButton = styled(Button)``;
CheckButton.displayName = 'CheckButton';

export const FormField = styled(_FormField).attrs({ name: 'find-record-form-field' })`
    margin: 0;
    margin-left: ${fromTheme('viewMargin')};
    max-width: 500px;
    min-width: 200px;
    flex-grow: 1;
`;

export const RfidButton = styled(SensorReadButton).attrs({
    name: 'rfid-button',
    alt: 'Scan for RFID',
    color: 'transparent'
})``;

// min-height here is important as the native app wants to squish the text entry area of the input
export const FindTextInput = styled(TextInput)`
    flex-grow: 1;
    min-height: ${({ theme }) => theme.fontSize + theme.textMargin * 2 + theme.textPadding * 2 + 'px'};
`;

export const SpreadLeftToRight = styled(View)`
    flex-direction: row;
`;

export const Total = styled(View)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: ${fromTheme('viewPadding')};
    flex-shrink: 0;
`;

export const TotalLabel = styled(Text).attrs(
    nativeOnlyProperties({
        numberOfLines: 1
    })
)`
    color: ${fromTheme('colorScheme', 'primary')};
    font-size: ${fromTheme('fontSizeSmall')};
`;

export const TotalNumber = styled(Text)`
    color: ${fromTheme('colorScheme', 'primary')};
    font-size: ${fromTheme('fontSizeLarge')};
`;
