import lodash from 'lodash';
const { isEqual } = lodash;
import cuid from 'cuid';
import getFilter from '../filterFactory';
import logging from '@sstdev/lib_logging';
import simpleChangeObserver from '../simpleChangeObserver';
import { filterFactory } from '..';

const EMPTY_LOKI_VIEW = {};

/**
 * Helper functions for changing dynamicView sorts and filters.
 * Do not put any state in here.  The state is all inside loki and
 * other consumers of the dynamicView won't be able to see any state here.
 * It's probably important to be aware that Backbone treats sorts (e.g. orderBy) as another
 * type of filter in the filter hNodeTypeGroup whereas lokijs treats them separately.
 * @param { import("lokijs").DynamicView} view lokijs dynamicView
 * @returns object
 */
export default function viewCriteria(lokiView = EMPTY_LOKI_VIEW) {
    const { onChange: onBeforeChange, publishChange: publishBeforeChange } = simpleChangeObserver();
    const cleanupOperationsByFilterId = {};
    const _p = {
        addDistinctFilter,
        applyPreFilters,
        removeFilters,
        addSort,
        isSortDifferent,
        isFilterDifferent,
        isDefaultFilterDifferent,
        removeSorts,
        cleanupOperationsByFilterId,
        cleanupFilter
    };

    function getSort() {
        if (lokiView === EMPTY_LOKI_VIEW) return;
        return lokiView.sortCriteria?.map(sort => sort[0]);
    }
    /**
     * Sorts the view idempotently.  It won't do any work if nothing is different.
     * @param {string} sort - path to field on record
     * @param {bool} desc - true if desc
     * @returns true if changed
     */
    function addSort(sort, desc = false, filterId) {
        logging.debug(`[VIEWCRITERIA] ${lokiView.name} altering sort ${sort}`);
        if (typeof filterId !== 'string') {
            throw new Error('A filter ID must be added when specifying a sort.');
        }
        if (typeof sort !== 'string') {
            throw new Error('viewCriteria.addSort sort parameter must be a string.');
        }
        if (typeof desc !== 'boolean') {
            throw new Error('viewCriteria.addSort desc parameter must be a boolean (true if descending).');
        }
        if (lokiView === EMPTY_LOKI_VIEW) return;
        if (sort == null) return;

        let currentSorts = (lokiView.sortCriteria ?? []).map(([sort, desc, filterIds]) => {
            return { sort, desc, filterIds };
        });

        const previousSortIndex = currentSorts.findIndex(
            currentSort =>
                currentSort.filterIds.includes(filterId) && currentSort.sort === sort && currentSort.desc === !desc
        );

        let changed = false;
        //if we previously sorted for this filter in the opposite sort direction, just get rid of that.
        //but don't blatantly remove it. as it assures the default sort.
        //This does mean you might get a "sort" like: [{assetNo, ascending}, {assetNo, descending}] but we'll accept that.
        // (it doesn't seem to negatively affect the performance.)
        if (previousSortIndex >= 0) {
            if (currentSorts[previousSortIndex].filterIds.length <= 1) {
                //this is the last/only filter prescribing this particular filter. remove.
                currentSorts.splice(previousSortIndex, 1);
            } else {
                const filterIndex = currentSorts[previousSortIndex].filterIds.findIndex(f => f === filterId);
                currentSorts[previousSortIndex].filterIds.splice(filterIndex, 1);
            }
            changed = true;
        }
        const matchingSortIndex = currentSorts.findIndex(
            currentSort => currentSort.sort === sort && currentSort.desc === desc
        );
        if (matchingSortIndex < 0) {
            // brand new sort - put it in the front
            currentSorts.unshift({ sort, desc, filterIds: [filterId] });
            changed = true;
        } else if (matchingSortIndex === 0) {
            // existing sort - already at the front - loki does no work.
            // // Be sure we are tracking it for this filterId though
            if (!currentSorts[0].filterIds.includes(filterId)) {
                //as filterIds is by reference, pushing here, will add it to the lokiView as well.
                currentSorts[0].filterIds.push(filterId);
            }
            //`changed` depends on if we previously removed this sort.
        } else {
            // existing sort, but further down in the sort priority (need to move it to top).
            const [currentSort] = currentSorts.splice(matchingSortIndex, 1);
            if (!currentSort.filterIds.includes(filterId)) {
                currentSort.filterIds.push(filterId);
            }
            currentSorts.unshift(currentSort);
            changed = true;
        }
        if (changed) {
            publishBeforeChange('addSort', currentSorts);
            lokiView.applySortCriteria(currentSorts.map(sort => [sort.sort, sort.desc, sort.filterIds]));
            logging.debug(`[VIEWCRITERIA] ${lokiView.name} addSort ${JSON.stringify(currentSorts)}`);
        } else {
            logging.debug(`[VIEWCRITERIA] ${lokiView.name} sort unchanged ${JSON.stringify(currentSorts)}`);
        }
        return changed;
    }

    function removeSorts(filterId) {
        if (lokiView === EMPTY_LOKI_VIEW) return false;
        let changed = false;
        let resultSort = [];
        // look through all the sorts
        lokiView.sortCriteria?.forEach(sc => {
            let sortFilters = sc[2];
            // if one of them contains the filter ID
            //(this includes the scenario where there are multiple orderBy filters on 1 grid)
            if (sortFilters.includes(filterId)) {
                // if there is more than one filter ID with the same sort
                if (sortFilters.length > 1) {
                    // only remove the filter ID, but keep the sort
                    sortFilters.splice(sortFilters.indexOf(filterId), 1);
                    resultSort.push(sc);
                } else {
                    // otherwise don't add the filter to the result sort
                    changed = true;
                }
            } else {
                // nothing changes - add the filter to the result sort
                resultSort.push(sc);
            }
        });
        // Only perform a sort operation if something has changed.
        if (changed) {
            if (resultSort.length === 0) {
                publishBeforeChange('removeSorts');
                lokiView.applySortCriteria(undefined);
            } else {
                publishBeforeChange('removeSorts', resultSort);
                lokiView.applySortCriteria(resultSort);
            }
            logging.debug(`[VIEWCRITERIA] ${lokiView.name} removeSorts ${JSON.stringify(resultSort)}`);
        }
        return changed;
    }

    function isSortDifferent(sort, desc = false) {
        if (typeof sort !== 'string') {
            throw new Error('viewCriteria.isSortDifferent sort parameter must be a string.');
        }
        if (typeof desc !== 'boolean') {
            throw new Error('viewCriteria.isSortDifferent desc parameter must be a boolean (true if descending).');
        }
        if (lokiView === EMPTY_LOKI_VIEW) return false;

        if (sort == null && (lokiView.sortCriteria?.length ?? 0) === 0) return false;
        const existingSort = lokiView.sortCriteria?.find(sc => sc[0] === sort && sc[1] === desc);
        return existingSort == null;
    }

    function getFinds() {
        if (lokiView === EMPTY_LOKI_VIEW) return [];
        return lokiView.filterPipeline.filter(f => f.type === 'find');
    }

    /**
     * Add a mongodb styled filter to this view.
     * @param {object} find - raw mongodb style filter
     * @param {string} filterId - unique ID of filter
     * @returns bool - true if view filter pipeline changed
     */
    function addFind(find, filterId = cuid(), routePath) {
        if (lokiView === EMPTY_LOKI_VIEW) return false;
        // If an undefined, null or empty criteria is given then we are done.
        if (find == null || isEmptyObject(find)) return false;

        let existingFind = lokiView.filterPipeline.filter(f => f.type === 'find');
        if (existingFind.length) {
            // Same criteria, leave without changing anything
            if (existingFind.some(f => isEqual(f.val, find))) return false;
        }
        // ensure mutation of criteria outside this function does not
        // break the logic above on rerenders, etc.
        let newCriteria = { ...find };
        // filter the view
        publishBeforeChange('addFind', newCriteria);
        // loki allows a filter object to be passed into applyFilter (unlike applyFind)
        // We can pass in additional data (such as routePath) via this object and then
        // use it later.
        const filter = {
            type: 'find',
            val: newCriteria,
            uid: filterId
        };
        if (routePath != null) {
            filter.routePath = routePath;
        }
        lokiView.applyFilter(filter);
        return true; // changed
    }

    /**
     * Perform any cleanups associated with the filterId and then remove the cleanups.
     * @param {string} filterId - identifier for a set of filters
     */
    async function cleanupFilter(filterId) {
        let cleanup;
        do {
            try {
                cleanup = (cleanupOperationsByFilterId[filterId] ?? []).pop();
                await cleanup?.action();
            } catch (err) {
                logging.debug(`An error occurred while cleaning up for filterId ${filterId}`);
                logging.error(err);
            }
        } while (cleanup != null);
    }

    async function cleanupAll() {
        return Promise.all(Object.keys(cleanupOperationsByFilterId).map(k => cleanupFilter(k)));
    }

    async function applyPreFilters(filters, filterId) {
        return Promise.all(
            Object.keys(filters).map(async filterName => {
                const cleanup = await getFilter(filterName)?.preFilter?.(filters, filterId, lokiView);
                if (cleanup != null) {
                    cleanupOperationsByFilterId[filterId] = cleanupOperationsByFilterId[filterId] ?? [];
                    cleanupOperationsByFilterId[filterId].push({ bbFilterType: filterName, action: cleanup });
                }
            })
        );
    }

    function removeFiltersFromOtherRoutes(routePath) {
        if (lokiView === EMPTY_LOKI_VIEW) return;
        const toRemove = lokiView.filterPipeline.filter(f => f.routePath != null && f.routePath !== routePath);
        toRemove.forEach(tr =>
            tr.uid
                ? lokiView.removeFilter(tr.uid)
                : () => {
                      /*noop*/
                  }
        );
    }

    /**
     * Add default backbone style filters
     * @param {object} filters A backbone style filter object
     * @param {string} filterId unique ID of filters
     * @returns {boolean} true if if any default filters were applied by this method, false otherwise
     */
    function applyDefaultFilters(filters, baseFilterId, routePath) {
        //make sure to _prefix_ the filterId, to prevent automatic removal in "removeFilters()"
        const filterId = 'default_' + baseFilterId;
        let changed = false;
        if (!filters.includeDeleted) {
            changed = _p.addDistinctFilter(filters, filterId, 'excludeDeleted', routePath) || changed;
        }
        return changed;
    }

    /**
     * Check default backbone style filters
     * @param {object} filters A backbone style filter object
     * @param {string} filterId unique ID of filters
     * @returns {boolean} true if if default filters are not applied, false otherwise
     */
    function isDefaultFilterDifferent(filters, baseFilterId) {
        //make sure to _prefix_ the filterId, to prevent automatic removal in "removeFilters()"
        const filterId = 'default_' + baseFilterId;
        let different = false;
        // excludeDeleted is the only default filter at this time.  It should be
        // here unless "includeDeleted" is set to true
        if (!filters.includeDeleted) {
            // different = true if the default filter is not applied yet.
            different = _p.isFilterDifferent(filters, filterId, 'excludeDeleted') || different;
        }
        return different;
    }

    function isFilterDifferent(filters, filterId, bbFilterType) {
        // Passing in `allFilters` as I can't overcome recursion and circular dependencies in a different way... :(
        const filter = getFilter(bbFilterType)?.getMql?.(filters, getFilter);
        const combinedFilterId = filterId + bbFilterType;
        let different = false;
        if (filter != null) {
            // different = true if a filter does not exist, but should
            different = !lokiView.filterPipeline.some(f => isEqual(f.val, filter));
        } else {
            // different = true if a filter exists but should not
            different = lokiView.filterPipeline.some(f => f.uid === combinedFilterId);
        }
        return different;
    }

    function areFiltersApplied(filters, filterId) {
        let different = false;
        if (lokiView === EMPTY_LOKI_VIEW) return true;
        if (filterId == null) {
            throw new Error('A filter ID must be specified to differentiate various consumers of the dynamic view.');
        }

        if (Object.keys(filters).length === 0) {
            different = _p.isDefaultFilterDifferent(filters, filterId) || different;
            // If none of the default filters are different, then all of them have been applied
            return !different;
        }

        // Sorting filters
        if (filters.orderBy) {
            const path = getFilter('orderBy').getDbIndex(filters);
            different = _p.isSortDifferent(path, filters.orderBy.direction === 'descending') || different;
        }
        filters.orderByMultiple?.forEach(orderBy => {
            const { path, desc } = orderBy;
            different = _p.isSortDifferent(path, desc) || different;
        });

        // Matching filters
        Object.keys(filters).forEach(filterName => {
            different = _p.isFilterDifferent(filters, filterId, filterName) || different;
        });
        different = _p.isDefaultFilterDifferent(filters, filterId) || different;

        // If none of the filters are different, then all of them have been applied
        return !different;
    }

    async function removeFilter(filterId, bbFilterType) {
        if (lokiView === EMPTY_LOKI_VIEW) return false;
        let changed = false;
        const combinedFilterId = filterId + bbFilterType;
        let existingFilters =
            lokiView?.filterPipeline?.filter(f => f.type === 'find' && f.uid === combinedFilterId) ?? [];
        if (existingFilters.length > 1) {
            // Expecting only one bbFilterType per filterId.
            logging.warn(
                `An unexpected number (${existingFilters.length}) of ${bbFilterType} filters was encountered for filter id ${filterId}.`
            );
        }
        publishBeforeChange('removeFilter', existingFilters);
        existingFilters.forEach(filter => {
            lokiView.removeFilter(filter.uid);
            changed = changed || true;
        });
        // perform any cleanups associated with the filter id and type.
        const cleanups = cleanupOperationsByFilterId[filterId]?.filter(c => c.bbFilterType === bbFilterType) ?? [];
        for (let i = 0; i < cleanups.length; i++) {
            const cleanup = cleanups[i];
            await cleanup.action();
            const indexToRemove = cleanupOperationsByFilterId[filterId].indexOf(cleanup);
            cleanupOperationsByFilterId[filterId].splice(indexToRemove, 1);
        }

        if (changed) {
            logging.debug(
                `[VIEWCRITERIA] ${lokiView.name} removeFilter uid: ${filterId} with filter type: ${bbFilterType}.`
            );
        }
        return changed;
    }

    /**
     * Use the filter ID provided by a previous applyFilters call
     * in order to remove the same filters.
     * Conceptually Backbone includes the orderBy filter metadata amongst the
     * filter hNodeTypeGroup, so this will also remove sorts associated with the
     * filterId given. To be clear, this is different from loki which keeps
     * sorts separate from filters.
     * @param {string} filterId unique ID of filters
     * @returns
     */
    function removeFilters(filterId) {
        if (lokiView === EMPTY_LOKI_VIEW) return false;
        let changed = false;
        let existingFilters = lokiView?.filterPipeline?.filter(f => f.type === 'find') ?? [];
        publishBeforeChange('removeFilters', existingFilters);
        existingFilters.forEach(filter => {
            if (filter.uid.startsWith(filterId)) {
                lokiView.removeFilter(filter.uid);
                changed = true;
            }
        });
        changed = removeSorts(filterId) || changed;
        // perform any cleanups associated with the filter.
        cleanupFilter(filterId);
        if (changed) {
            logging.debug(`[VIEWCRITERIA] ${lokiView.name} removeFilters uid:${filterId}`);
        }
        return changed;
    }

    function calculateAggregateResult(filters, itemCount, dataModel) {
        // This is needed to ensure that searchCriteria is included for the aggregate result.
        // Mainly used for charts with a dropdown filter that search on a nested entry (e.g. multi-inventory).
        const allFilters = getFiltersFromView();
        if (allFilters?.searchCriteria && !isEmptyObject(allFilters.searchCriteria)) {
            filters = { ...filters, searchCriteria: allFilters.searchCriteria };
        }
        const dontRestrictData = x => x;
        if (filters.aggregateType) {
            return lokiView.mapReduce(
                dontRestrictData,
                getFilter('aggregateType').getAggregateReducer(filters, itemCount)
            );
        } else if (filters.groupBy) {
            return lokiView.mapReduce(dontRestrictData, getFilter('groupBy').getAggregateReducer(filters, itemCount));
        } else if (filters.groupByBoolean) {
            return lokiView.mapReduce(
                dontRestrictData,
                getFilter('groupByBoolean').getAggregateReducer(filters, itemCount)
            );
        } else if (filters.patchDetail) {
            return lokiView.mapReduce(
                dontRestrictData,
                getFilter('patchDetail').getAggregateReducer(filters, itemCount, dataModel)
            );
        }
        //if there are no (supported) aggregate filters, return everything
        return lokiView.data();
    }

    // Gather up all the backbone filters from the loki filterPipeline and return them in one
    // object.
    function getFiltersFromView() {
        const findFilters = lokiView?.filterPipeline
            ?.filter(f => f.type === 'find')
            ?.reduce((prev, f) => {
                prev[f.bbFilterType] = f.bbFilter;
                return prev;
            }, {});

        if (lokiView.sortCriteria != null) {
            return {
                ...findFilters,
                orderByMultiple: filterFactory('orderByMultiple').getFilter(lokiView.sortCriteria)
            };
        }
        return findFilters;
    }

    function addDistinctFilter(filters, filterId, bbFilterType, routePath) {
        // Passing in `allFilters` as I can't overcome recursion and circular dependencies in a different way for nestedListEntry... :(
        const mqlFilter = getFilter(bbFilterType).getMql?.(filters, getFilter, lokiView, filterId);
        const bbFilter = filters[bbFilterType];
        const combinedFilterId = filterId + bbFilterType;
        let changed = false;
        if (mqlFilter != null) {
            // Don't allow two of the exact same filter (same exact database filtering criteria)
            // even if they have different IDs.  I don't think lokijs is smart enough to
            // know and it will just do the same work twice.
            if (!lokiView.filterPipeline.some(f => isEqual(f.val, mqlFilter))) {
                publishBeforeChange('addDistinctFilter', mqlFilter);
                // loki allows a filter object to be passed into applyFilter (unlike applyFind)
                // We can pass in additional data (such as routePath) via this object and then
                // use it later.
                const newFilter = {
                    type: 'find',
                    val: mqlFilter,
                    uid: combinedFilterId,
                    bbFilterType,
                    bbFilter
                };
                if (routePath != null) {
                    newFilter.routePath = routePath;
                }
                lokiView.applyFilter(newFilter);
                changed = true;
            }
        } else {
            if (lokiView.filterPipeline.some(f => f.uid === combinedFilterId)) {
                publishBeforeChange('removeFilters', mqlFilter);
                lokiView.removeFilter(combinedFilterId);
                changed = true;
            }
        }
        return changed;
    }

    /**
     * Add backbone style filters (as defined in Blockly metadata)
     * @param {object} filters A backbone style filter object
     * @param {string} filterId unique ID of filters
     * @returns {boolean} true if filter conditions changed, false if they were the same compared with the last time.
     */
    async function applyFilters(filters, filterId, routePath) {
        let changed = false;
        if (lokiView === EMPTY_LOKI_VIEW) return changed;
        if (filterId == null) {
            throw new Error('A filter ID must be specified to differentiate various consumers of the dynamic view.');
        }

        if (!areFiltersApplied(filters, filterId)) {
            publishBeforeChange('applyFilters');
        }
        // Clear everything out for this filterId if an empty array is passed.
        if (Object.keys(filters).length === 0) {
            changed = _p.removeFilters(filterId);
            await _p.applyPreFilters(filters, filterId);
            return applyDefaultFilters(filters, filterId) || changed;
        }

        // Filters like fullTextSearch may need to be called on the server async.
        await _p.applyPreFilters(filters, filterId);
        // Sorting filters
        if (filters.orderBy) {
            const path = getFilter('orderBy').getDbIndex(filters);
            changed = _p.addSort(path, filters.orderBy.direction === 'descending', filterId) || changed;
        }
        filters.orderByMultiple?.forEach(orderBy => {
            const { path, desc } = orderBy;
            changed = _p.addSort(path, desc, filterId) || changed;
        });

        // Matching filters
        Object.keys(filters).forEach(filterName => {
            changed = _p.addDistinctFilter(filters, filterId, filterName, routePath) || changed;
        });
        changed = applyDefaultFilters(filters, filterId) || changed;

        if (changed) {
            logging.debug(
                `[VIEWCRITERIA] ${lokiView.name} applyFilters requested:${JSON.stringify(
                    filters,
                    null,
                    3
                )} \n uid: ${filterId}`
            );
        }
        return changed;
    }

    return {
        addFind,
        addSort,
        applyFilters,
        areFiltersApplied,
        calculateAggregateResult,
        calculateFilters,
        cleanupAll,
        getFiltersFromView,
        getFinds,
        getSort,
        isSortDifferent,
        onBeforeChange,
        removeFilter,
        removeFilters,
        removeFiltersFromOtherRoutes,
        removeSorts,
        _private: _p
    };
}

function isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function calculateFilters(filters) {
    let calced = {};
    Object.keys(filters).forEach(filterName => {
        calced = calculateFilter(filters, filterName, calced);
    });
    return calced;
}

function calculateFilter(filters, filterName, calced) {
    let newCalc = {};

    const filter = getFilter(filterName).getMql(filters);
    if (filter != null) {
        newCalc = {
            ...calced,
            ...filter
        };
    } else {
        return calced;
    }
    return newCalc;
}
