import { createElement as rc, useCallback, useState } from 'react';
import { View, Button, styled, fromTheme } from 'lib_ui-primitives';
import PropTypes from 'prop-types';

const GraphFilters = styled(View).attrs({ name: 'graph-filters' })`
    align-self: flex-end;
    flex-grow: 1;
    max-width: 100%;
`;
GraphFilters.displayName = 'GraphFilters';

const InnerGraphFilters = styled(View).attrs({ name: 'inner-graph-filters' })`
    flex-direction: row-reverse;
    justify-content: flex-start;
    flex-grow: 1;
`;
InnerGraphFilters.displayName = 'InnerGraphFilters';

const ActualFilters = styled(View).attrs({ name: 'actual-filters' })`
    display: ${({ filtersVisible }) => (filtersVisible ? 'flex' : 'none')};
    margin: ${({ filtersVisible, theme }) => (filtersVisible ? theme.viewMargin : 0)}px;
    background-color: ${fromTheme('backgroundColorOnDarkColors')};
    border-radius: 3px;
    max-width: calc(100% - 65px);
`;
ActualFilters.displayName = 'ActualFilters';

export default function FilterContainer(props) {
    const { children: filterControls } = props;
    const [filtersVisible, setFiltersVisible] = useState(false);

    const toggleFilter = useCallback(() => {
        setFiltersVisible(prevState => {
            return !prevState;
        });
    }, []);

    if (!filterControls) return null;
    if (filterControls.length === 0) return null;

    // prettier-ignore
    return rc(GraphFilters, null,
        rc(InnerGraphFilters, null,
            rc(Button, {
                buttonStyle: 'IconAction',
                hoverText: 'Filter options',
                alt: 'filter options',
                icon: filtersVisible ? 'keyboard_arrow_down' : 'keyboard_arrow_left',
                onClick: toggleFilter
            }),
            rc(ActualFilters, { filtersVisible },
                filterControls
            )
        )
    );
}

FilterContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
};
