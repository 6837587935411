import { createElement } from 'react';
import { fromTheme, h3, styled, hooks } from 'lib_ui-primitives';
const { useRouter } = hooks;

const rc = createElement;

const Title = styled(h3)`
    font-family: ${fromTheme('fontAlternate')};
    color: ${fromTheme('outerMenus', 'fontColor')};
`;

function LayoutTitle() {
    const path = useRouter().location.pathname;
    const routePieces = path.split('/');
    const currentPage = routePieces[routePieces.length - 1];
    return rc(Title, { id: 'pageTitle' }, currentPage || 'Home');
}

export default LayoutTitle;
