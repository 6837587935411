/**
 * @typedef {Object} HNode
 * @property {string[]} falseValues
 */

/**
 * @param {HNode} hNode
 * @returns {(value: string) => boolean}
 */
function toBoolean({ falseValues }) {
    return value => {
        const lowerCaseFalseValues = falseValues.map(fv => fv.toString().toLowerCase());

        return !lowerCaseFalseValues.includes(value.toString().toLowerCase());
    };
}

export default toBoolean;
