export default {
    verb: 'doingChange',
    namespace: 'sensor',
    relation: 'tag',
    status: 'success',
    description: 'Automatically Encode RFID on Barcode Scan (if enabled))',
    logic: changeSuccess
};
/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @template T
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function changeSuccess({ data, dispatch, log }) {
    const {
        newRecord: { receivedAsScan, encodeOnScan },
        timeoutMs = 1500
    } = data;
    if (encodeOnScan && receivedAsScan) {
        log.debug('[RFID] Auto Triggering Encode');
        await dispatch(
            {
                isError: false,
                message: 'Please present tag to encode',
                timeout: timeoutMs
            },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );
        await new Promise(resolve =>
            setTimeout(
                () => dispatch(data, { verb: 'create', namespace: 'sensor', relation: 'tag' }).then(resolve),
                timeoutMs
            )
        );
    }
}
