import { useRef, useEffect, useCallback } from 'react';
/**
 * This is to facilitate a workaround for a bug with the react native android keyboard
 * when edited values are transformed in the onChange event
 * https://github.com/facebook/react-native/issues/11068#issuecomment-1153975055
 *
 * This works by setting any TextInput changes immediately to a local
 * state (i.e. useState) value (to avoid losing our cursor position
 * as an async change WILL lose the position).
 * Then the new change is applied to our normal setValue (which is
 * a prop passed in and CAN be async)
 * When the new value comes back from the prop, if it matches the local
 * state value, then that's a noop -- which means cursor position is
 * maintained.  This will be the 95% scenario.
 *
 * If the value is different (e.g. set to uppercase), this is the
 * scenario that react-native has the bug with.
 * In this scenario, we will set the local value first to an empty
 * string (which will lose our cursor position), and wait for the new local
 * value to rerender.
 * Then set the local value to the new updated value we received from
 * the value prop.  The correct value will be displayed, _however_ this
 * will put the cursor at the end of the string.  That's ok most of the
 * time because the user is just typing.  In rare cases, the user may
 * update the middle of a string and lose the cursor position.

                                       ┌───────┐
                                 ┌─────┤ Value ◄───┐
                                 │     └───────┘   │
                                 │                 │
┌────┐   Yes   ┌─────────────────▼──┐              │
│Noop│◄────────┤ value == local     │              │
└────┘         └───────┬────────────┘              │
                       │ No                  ┌─────┴────┐
                       │                     │ onChange │
                ┌──────▼───────┐             └┬─────────┘
                │ await set "" ├─────────┐    │    ▲
                └──────┬───────┘         │    │    │
                       │                 │    │    │
                       │                 │    │    │
                ┌──────▼──────────┐     ┌▼────▼─┐  │
                │ await set value ├─────► Local │  │
                └─────────────────┘     └───┬───┘  │
                                            │      │
                                       ┌────▼────┐ │
                                       │TextInput├─┘
                                       └─────────┘
(made with https://asciiflow.com/#/) */
export default function useAsyncState(localValue, setLocalValue) {
    const resolvesRef = useRef([]);
    const mutexRef = useRef(Promise.resolve());
    const localValRef = useRef(localValue);
    localValRef.current = localValue;
    const setValueAsync = useCallback(
        (newValue, upcomingValue) => {
            if (upcomingValue === localValRef.current) return mutexRef.current;
            mutexRef.current = mutexRef.current.then(() => {
                return new Promise(resolve => {
                    setLocalValue(value => {
                        if (value === newValue) {
                            resolve();
                            return value;
                        }

                        resolvesRef.current.push(resolve);
                        return newValue;
                    });
                });
            });
            return mutexRef.current;
        },
        [setLocalValue]
    );

    // If the localValue changes, resolve any pending async changes
    useEffect(() => {
        const lengthAtStart = resolvesRef.current.length;
        for (let i = 0; i < lengthAtStart; i++) {
            resolvesRef.current[i]();
        }
        resolvesRef.current.splice(0, lengthAtStart);
    }, [localValue]);

    return setValueAsync;
}
