import logging from '@sstdev/lib_logging';
import jsonPatch from '@sstdev/lib_isomorphic-json-patch';
import lodash from 'lodash';
const { cloneDeep, isEqual } = lodash;
import { errors as _errors } from 'lib_ui-primitives';
import combineMetadata from '../utilities/combineMetadata';
import { constants, http, network, ObjectId, globalConfig } from 'lib_ui-services';

const { useCaseIds } = constants;
const { rfc6902 } = jsonPatch;

const _p = {
    getNetworkStatus: network.getStatus,
    post: http.post,
    get: http.get,
    patch: http.patch,
    combineMetadata,
    globalConfig
};
export const _private = _p;
export default {
    verb: 'doingCreate',
    namespace: 'metadata',
    relation: 'deployment',
    priority: 5, //run before normal doingCreate, which will serve as persisting the result
    description: 'pull all the metadata pieces together and create something to save....',
    useCaseIds: [useCaseIds.META_DATA_CREATOR],
    prerequisites: [
        //version detail (different from the others as we need to match on _id, rather than on metadata:useCaseDetail._id )
        {
            context: {
                verb: 'get',
                namespace: 'metadata',
                relation: 'useCaseDetail',
                type: 'find'
            },
            query: ({ data }) => {
                const {
                    'metadata:useCaseDetail': { _id }
                } = data.newRecord;
                return {
                    _id,
                    'meta.deleted': { $exists: false }
                };
            }
        },
        //namespaces
        getLinkedDocumentsFor('namespace'),
        //profile menu
        getLinkedDocumentsFor('profileMenu'),
        //navigation
        getLinkedDocumentsFor('navigation'),
        //pages
        getLinkedDocumentsFor('page')
    ],
    //this is the actual logic:
    logic: doingCreate,
    onError
};

/**
 * @param {{
 *      error: Error;
 *      data: T;
 *      context: Context;
 *      dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>,
 *      workflowStack: WorkflowStack[]
 * }} parameters
 * */
function onError({ error, dispatch }) {
    dispatch(
        {
            isError: true,
            message: error.message,
            timeout: 5000
        },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
    throw error;
}

function getLinkedDocumentsFor(relation) {
    return {
        context: {
            verb: 'get',
            namespace: 'metadata',
            relation: relation,
            type: 'find'
        },
        query: ({ data }) => {
            const {
                'metadata:useCaseDetail': { _id }
            } = data.newRecord;
            return {
                'metadata:useCaseDetail._id': _id,
                'meta.deleted': { $exists: false }
            };
        }
    };
}

let progress = -1;
const incrementProgress = async (dispatch, pages) => {
    return dispatch(
        {
            mainTitle: 'Deployment In Progress...',
            description: 'We are deploying your metadata changes.',
            title: 'Progress',
            current: ++progress,
            total: pages
        },
        { verb: 'update', namespace: 'application', relation: 'progress' }
    );
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingCreate({ data, data: { newRecord: deployment }, prerequisiteResults, dispatch }) {
    try {
        ///////////////////////////////
        //   Data Collection Phase   //
        ///////////////////////////////
        const [
            {
                result: [useCaseDetail]
            },
            { result: namespaces },
            {
                result: [profileMenu]
            },
            {
                result: [navigation]
            },
            { result: pages }
        ] = prerequisiteResults;

        const {
            //_id, useCaseVersions always need a new _id, so don't use this
            'metaui:useCase': useCase,
            version,
            verified,
            includeSplashSelection = false,
            includeSideNav = true,
            extraProperties = []
        } = useCaseDetail;

        const targetEnvironment = deployment['deploy:environment'];

        const networkStatus = await _p.getNetworkStatus();
        if (!networkStatus.isServerReachable) {
            let message = `Failed to communicate with the server. Unable to Deploy  ${useCaseDetail.title} while offline.`;
            logging.error(`[METADATA] ${message}`);
            throw new _errors.ValidationError(message, {});
        }

        if (!verified && targetEnvironment.title === 'app') {
            logging.error(`[METADATA] Cannot deploy unverified ${useCaseDetail.title} to production`);
            throw new Error(`Cannot deploy unverified ${useCaseDetail.title} to production`);
        }
        //add namespaces and relations in appropriate format
        if (!namespaces?.length) {
            logging.error(`[METADATA] ${useCaseDetail.title} has no namespaces configured`);
            throw new Error(`Unable to deploy ${useCaseDetail.title}. It has no namespaces configured.`);
        }

        const deploymentType = deployment?.type;
        // If the deployment is being triggered by a use case version lock, add a note indicating that this is a locking deployment
        if (deploymentType === 'USE_CASE_VERSION_LOCK') {
            deployment.note = '*** LOCK ***';
        }

        deployment.version = version;
        deployment['metaui:useCase'] = useCase;

        ///////////////////////////////
        //   Data Generation Phase   //
        ///////////////////////////////

        await incrementProgress(dispatch, pages.length);
        //stich all data together and convert (blockly) to metadata:
        const actualHNodeContent = await _p.combineMetadata(
            namespaces,
            profileMenu,
            navigation,
            pages,
            {
                title: useCase.title,
                includeSplashSelection,
                includeSideNav
            },
            dispatch
        );

        await incrementProgress(dispatch, pages.length);

        //create the useCaseVersion record
        let useCaseVersion = {
            // oneTouch deployment does not recognize useCaseVersion changes if the _id doesn't change.
            // Ideally we had a consistent way of _id-ing release versions when deployed to environments,
            // but metaUi will identify duplicates by `metaui:useCase._id` + `release`,
            // and automatically delete the older record if we deploy the same version to an environment
            // but with a different _id.
            _id: ObjectId(),
            'metaui:useCase': useCase,
            release: version,
            //metadata-transform code requires 'hierarchies' to be defined (for now: 10/25/2022)
            //even if we don't ever use it:
            hierarchies: [],
            ...actualHNodeContent,
            meta: deployment.meta
        };
        extraProperties.forEach(prop => {
            useCaseVersion[prop.key] = prop.value;
        });

        //////////////////////////
        //   Deployment Phase   //
        //////////////////////////

        //Key Variables at this time:
        // `useCaseVersion`: pretty much identical to the original pre-MDC3.0 generated useCaseVersion record for target environment
        // `useCase`: {_id, title} record just in case we need to create it on the target environment
        // `deployment`: deployment definition record documenting this deployment, stays on local environment
        logging.debug(`[METADATA] Pushing ${useCase.title} v${version}`);
        //check if it is for localhost or not. if not, add .sstid.com
        const isLocalhost = ['localhost', ':'].some(str => targetEnvironment.title.includes(str));
        const sourceEnvironment = _p.globalConfig().hostname.split('.')[0];
        const targetHost = isLocalhost ? targetEnvironment.title : `${targetEnvironment.title}.sstid.com`;
        const sourceHost = isLocalhost ? sourceEnvironment : `${sourceEnvironment}.sstid.com`;

        //make sure this usecase exists on the target
        const _targetEnvironmentUseCases = await _p.get('/api/metaui/useCase', true, targetHost);
        const targetEnvironmentUseCases = _targetEnvironmentUseCases.items || _targetEnvironmentUseCases;

        //if not, add it
        let targetEnvUseCase = targetEnvironmentUseCases.find(uc => uc.title === useCase.title);
        if (!targetEnvUseCase) {
            await _p.post('/api/metaui/useCase', { ...useCase, meta: deployment.meta }, true, targetHost);
        } else {
            useCaseVersion['metaui:useCase']._id = targetEnvUseCase._id;
        }

        //Push the new useCaseVersion to the target
        const storedVersion = await _p.post('/api/metaui/useCaseVersion', useCaseVersion, true, targetHost);

        //set this UCV as active
        //if this fails, we're still (mostly) OK. So put that in its own try-catch
        try {
            await incrementProgress(dispatch, pages.length);

            //get the current group definition:
            const deployGroup = await _p.get(`/api/deploy/group/${deployment['deploy:group']._id}`, true, targetHost);
            const originalDeployGroup = deployGroup?.items?.[0] || deployGroup?.[0] || deployGroup;
            logging.debug(`[METADATA] Activating ${useCase.title} v${version} on ${originalDeployGroup.title}`);

            let updatedDeployGroup = cloneDeep(originalDeployGroup);
            //update the deploy group and set this version as the active one.
            const oldIndex = updatedDeployGroup.useCaseVersion.findIndex(ucv => {
                if (ucv['metaui:useCaseVersion'].display) {
                    return ucv['metaui:useCaseVersion'].display.startsWith(`${useCase.title},`);
                }
                return ucv['metaui:useCaseVersion']['metaui:useCase'].title === useCase.title;
            });
            if (oldIndex === -1) {
                //If no version was configured for this usecase, add it
                updatedDeployGroup.useCaseVersion.push({
                    'metaui:useCaseVersion': {
                        _id: storedVersion._id,
                        'metaui:useCase': { _id: targetEnvUseCase._id, title: useCase.title },
                        release: version
                    }
                });
            } else {
                //otherwise update it.
                updatedDeployGroup.useCaseVersion[oldIndex]['metaui:useCaseVersion'] = {
                    _id: storedVersion._id,
                    'metaui:useCase': { _id: targetEnvUseCase._id, title: useCase.title },
                    release: version
                };
            }
            //if there are _any_ changes, update the target environment with the new group definition
            if (!isEqual(originalDeployGroup, updatedDeployGroup)) {
                // apply the patch to the target environment
                const jsonPatches = rfc6902.compare(originalDeployGroup, updatedDeployGroup, false);
                await _p.patch(`/api/deploy/group/${deployment['deploy:group']._id}`, jsonPatches, true, targetHost);
            }
        } catch (error) {
            logging.error(
                `[METADATA] Failed to activate ${useCase.title} v${version} on ${targetEnvironment.title} ${deployment['deploy:group']?.title}: ${error.message}`
            );
        }

        deployment.title = `${useCase.title} ${version} ${deployment['deploy:environment'].title} ${
            deployment['deploy:group'].title
        } ${new Date().toISOString()}`;

        logging.debug(`[METADATA] Deployment ${deployment.title} was successful`);

        // post the deployment record to the local environment
        await incrementProgress(dispatch, pages.length);
        await _p.post('/api/metadata/deployment', deployment, true, sourceHost);

        return { ...data, newRecord: deployment };
    } catch (error) {
        logging.error(error);
        throw error;
    } finally {
        dispatch(
            {
                mainTitle: 'Deployment In Progress...',
                description: 'We are deploying your metadata changes.'
            },
            { verb: 'reset', namespace: 'application', relation: 'progress' }
        );
    }
}
