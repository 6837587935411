import lodash from 'lodash';
const { omit } = lodash;
const { get } = lodash;
const { isEqual } = lodash;
import notHandledHere from '../notHandledHere';
import { database } from 'lib_ui-services';
import setStatusOnPatchCollection from './setStatusOnPatchCollection';

const _p = {
    setStatusOnPatchCollection
};
export const _private = _p;
export default {
    verb: 'doingRollback',
    excludedNamespaceRelations: notHandledHere,
    type: 'update',
    description: 'perform actual rollback of update',
    //this is the actual logic:
    logic: doingRollback
};

/**
 *
 * @typedef {{_id:string, [s:string]:any}} BackboneRecord
 * @typedef {{ newRecord: BackboneRecord, oldRecord:BackboneRecord, patch:Array<object>, path:string, httpHeaders:object}} reduxOfflineUpdateResponse
 *
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: reduxOfflineUpdateResponse;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 */
async function doingRollback({ data, context }) {
    const unchanged = await areRequestValuesUnchanged(data, context);
    if (unchanged) {
        const modifiedTimeChanged = await wasModifiedTimeChanged(data, context);
        if (!modifiedTimeChanged) {
            await reverseRequestValues(data, context);
        }
        await _p.setStatusOnPatchCollection(context, 'clientSendFailure');
    } else {
        await _p.setStatusOnPatchCollection(context, 'conflict');
    }
}

/**
 * Determine if the values originally set by the request being rolled back
 * have been changed since the time of the request.
 * @param {object} action rollback action
 */
async function areRequestValuesUnchanged({ newRecord }, context) {
    const db = await database.get();
    const [currentDocument] = await db.getById(
        {
            _id: newRecord._id
        },
        context
    );

    const cleanCurrentDoc = omit(currentDocument, 'meta', '_idx', '$loki');
    const cleanNewDoc = omit(newRecord, 'meta', '_idx', '$loki');
    return isEqual(cleanNewDoc, cleanCurrentDoc);
}

async function wasModifiedTimeChanged({ newRecord }, context) {
    const newModifiedTime = get(newRecord, 'meta.modifiedTime', new Date(1899, 0, 1));
    const db = await database.get();
    const [currentDocument] = await db.getById(
        {
            _id: newRecord._id
        },
        context
    );
    const currentModifiedTime = get(currentDocument, 'meta.modifiedTime', new Date(1899, 0, 1));
    return newModifiedTime !== currentModifiedTime;
}

async function reverseRequestValues({ oldRecord }, context) {
    const db = database.get();
    await db.update({ newRecord: oldRecord }, context);
}
