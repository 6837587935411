import { textWidth } from 'lib_ui-primitives';
import { register } from '../ZNode';
import lodash from 'lodash';
const { get } = lodash;

const CHARACTER_WIDTH = 28; //1em = ~28 dots
const TEXT_HEIGHT = 28; //font '0' at fontsize 10 is about 28 dots high, including a padding

/**
 * Referenced content is dynamic. It references specific properties from the records.
 * E.g., it might reference the description.
 * This in contrast to the FixedContent, which only allows adding a fixed string to a label
 * think "Property Of"
 * but when you print the label for 2 different records, with ReferencedContent,
 * each label would have the content as specified on e.g. the "description" property for that record.
 * @param {*} props
 * @returns
 */
async function ReferencedContent(props) {
    const {
        record,
        output,
        zNode: { propertyName, fixedText },
        width,
        top,
        padding
    } = props || { zNode: {} };
    let value =
        get(record, propertyName, '').replaceAll('~', '').replaceAll('^', '').replace(/\s+/g, ' ').trim() ||
        fixedText ||
        '';

    const averageCharacterWidth = CHARACTER_WIDTH / 2;
    const moreThanOneLine = value.length * averageCharacterWidth > width;
    let y = top;
    if (moreThanOneLine) {
        // limit to first 2 lines only
        value = await trimToMax2Lines(value, width - padding * 2);
        // text is generated with the first line of text Top left corner at the cursor's y coordinate.
    }

    return (
        output
            // moveTo uses "FO" which defines the top left of the cursor
            .moveTo(padding, y)
            // font 0 at 28x28 roughly matches Arial fontsize 12 bold, scaled 1.2x vertically;
            .setFont(0, 'N', TEXT_HEIGHT, CHARACTER_WIDTH)
            .setAlignment('center', width - padding * 2)
            .addText(value)
    );
}

ReferencedContent.height = ({ record, zNode: { propertyName, fixedText }, width }) => {
    let value = get(record, propertyName, fixedText || '')
        .replaceAll('~', '')
        .replaceAll('^', '')
        .replace(/\s+/g, ' ')
        .trim();
    const averageCharacterWidth = CHARACTER_WIDTH / 2;
    const moreThanOneLine = value.length * averageCharacterWidth > width;
    return moreThanOneLine ? TEXT_HEIGHT * 2 + 8 : TEXT_HEIGHT;
};

const UNIT_SEPARATOR = '\n';
async function trimToMax2Lines(value, contentWidth) {
    // split words on spaces and dashes, remove the spaces, but not the dashes
    // can't use a positive look behind with zero length assertion like /\s|(?<=-)/
    // As ReactNative does not support that yet
    const words = value
        .replaceAll(' ', UNIT_SEPARATOR)
        .replaceAll('-', '-' + UNIT_SEPARATOR)
        .split(UNIT_SEPARATOR);
    let lines = [];
    let currentLine = 0;
    for (const word of words) {
        const lineLength = await textWidth.measure(
            lines[currentLine] ? `${lines[currentLine]} ` : '',
            'Arial, sans-serif',
            CHARACTER_WIDTH
        );
        const wordLength = await textWidth.measure(word, 'Arial, sans-serif', CHARACTER_WIDTH);

        if (!lines[currentLine]) {
            lines[currentLine] = word;
        } else if (lineLength + wordLength <= contentWidth) {
            lines[currentLine] = `${lines[currentLine]} ${word}`;
        } else {
            if (currentLine === 0) {
                lines[currentLine + 1] = word;
                currentLine++;
            } else if (currentLine === 1) {
                lines[currentLine] = `${lines[currentLine]} ${word}`;
                while (
                    (await textWidth.measure(lines[currentLine], 'Arial, sans-serif', CHARACTER_WIDTH)) > contentWidth
                ) {
                    lines[currentLine] = lines[currentLine].substring(0, lines[currentLine].length - 1).trim();
                }
                break;
            } else {
                break;
            }
        }
    }
    //join what is left back together. remove any spaces we might be putting in behind a dash
    return lines.join(' ').replace(/-\s/g, '-');
}

register('ReferencedContent', ReferencedContent);
export default ReferencedContent;
