import { useCallback, useContext, useMemo, createElement, Children } from 'react';
import { ThemeContext } from 'styled-components';
import { metadata, constants } from 'lib_ui-services';
import { Text, View, MenuShellItem, styled, testProperties, fromTheme, ExternalLink } from 'lib_ui-primitives';
import Toggle from '../_abstractComponent/Toggle';
import HNode from '../../HNode';
import DropDownButton from '../_abstractComponent/DropDownButton';
import LoadingBoundary from '../contextProviders/LoadingBoundary';
import useBrand from '../../hooks/useBrand';

// For some reason, the Dark Mode switch will be dropped if you put this in the
// import above (e.g. import {createElement as rc} from 'react').
// We couldn't figure out why -- thinking maybe webpack does something weird here?
const rc = createElement;

const VersionContainer = styled(View).attrs({ name: 'version-container' })`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
VersionContainer.displayName = 'VersionContainer';
const ProfileStyle = styled(View)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const Version = styled(Text).attrs({ name: 'version' })`
    font-size: 10px;
    margin-left: 3px;
    margin-top: 6px;
    margin-right: 3px;
    color: ${fromTheme('unobtrusiveFontColor')};
`;

const HrefLink = styled(ExternalLink).attrs(({ theme }) => ({
    iconSize: '13px',
    style: { fontSize: theme.fontSize + 'px', lineHeight: '18px' }
}))``;

const _p = {
    useBrand,
    getUseCaseTitle: metadata.getUseCaseTitle,
    getUseCaseId: metadata.getUseCaseId
};
export const _private = _p;

export default function MeatballProfileLayout(props) {
    const { children, hNode } = props || {};
    const { darkMode, setDarkMode } = useContext(ThemeContext);
    const onClick = useCallback(checked => setDarkMode(checked), [setDarkMode]);
    const release = metadata.getUseCaseRelease();
    const useCaseTitle = _p.getUseCaseTitle();
    const useCaseId = _p.getUseCaseId();
    const brand = _p.useBrand();
    // Convert buttons, etc from existing blockly to appropriate Menu components
    // TODO: Add menu blocks to MDC?
    const menuItems = useMemo(() => {
        const children = hNode.children.map((hNode, index) => {
            switch (hNode.hNodeType) {
                case 'Button':
                    return rc(DropDownButton.MenuItem, { hNode });
                case 'ShortTextView':
                    return rc(DropDownButton.MenuHeader, null, rc(HNode, { hNode }));
                case 'SimpleDialog':
                    // Put the dialog components outside the menu (see below)
                    return null;
                default:
                    return rc(MenuShellItem, { key: `menuItem${index}` }, ({ ref }) => rc(HNode, { hNode, ref }));
            }
        });
        return children;
    }, [hNode.children]);

    const dialogItems = useMemo(() => {
        const c = Children.toArray(children).filter(component => component.props.hNode.hNodeTypeGroup === 'dialog');

        return c;
    }, [children]);

    const supportInfo = useMemo(() => {
        // Only show the knowledge base link for RedBeam Asset Tracking
        if (useCaseId !== constants.useCaseIds.ASSET_TRACKING) return;
        const info = constants.SUPPORT_INFO[brand];
        if (info.knowledgeBase) return info;
    }, [brand, useCaseId]);

    // prettier-ignore
    return rc(ProfileStyle, null,
        rc(LoadingBoundary, null,
            rc(DropDownButton, {
                icon: 'person',
                id: props.id || 'profile',
                ...testProperties(hNode, 'profile')
            },
                ...menuItems,
                rc(DropDownButton.MenuDivider),
                rc(MenuShellItem, { key: 'miDarkMode' },
                    () => rc(Toggle, { onClick, value: darkMode, falseLabel: 'Dark Mode', trueLabel: '' })
                ),
                rc(DropDownButton.MenuDivider),
                supportInfo &&
                rc(MenuShellItem, { key: 'knowledge-base' },
                    () => rc(HrefLink,
                        {
                            url: supportInfo.knowledgeBase,
                            title: `${supportInfo.displayName} Knowledge Base`,
                            includeExternalIcon: true
                        }
                    )
                ),
                rc(DropDownButton.MenuDivider),
                rc(MenuShellItem, { key: 'miVersion' },
                    () => rc(VersionContainer, null,
                        //eslint-disable-next-line no-undef
                        rc(Version, null, `Platform: v${__PACKAGE_VERSION__}`),
                        rc(Version, null, `${useCaseTitle}: v${release}`)
                    )
                )
            ),
            ...dialogItems
        )
    );
}
