import { createElement as rc, useState, Fragment, useCallback } from 'react';
import { Pressable, styled, TextInput } from 'lib_ui-primitives';
import FormField from '../formElement/FormField';
import PropTypes from 'prop-types';
import { PhotoshopPicker } from 'react-color';
import logging from '@sstdev/lib_logging';

const FontSelector = styled('select').attrs({ name: 'font-selector' })`
    margin-top: 6px;
    width: 170px;
`;
FontSelector.displayName = 'FontSelector';

const ColorBlock = styled(Pressable).attrs({ name: 'color-block' })`
    width: 20px;
    height: 20px;
    margin-left: 10px;
    background-color: ${({ color }) => color};
    border-radius: 2px;
    border: 1px solid #ccc;
    flex-shrink: 0;
    align-self: center;
`;
ColorBlock.displayName = 'ColorBlock';

const StyledTextInput = styled(TextInput)`
    flex-grow: 1;
`;

function ThemeValue(props) {
    const {
        autoComplete = 'off',
        id,
        max,
        maxLength,
        min,
        minLength,
        placeholder,
        pattern,
        propertyName,
        rows,
        multiline,
        type = 'textbox',
        value,
        updateTheme,
        title,
        onBlur,
        active = true,
        errors = [],
        disabled,
        onKeyPress,
        onFocus,
        autoFocus = false,
        sequence = 9999
    } = props ?? {};

    const fieldEmpty = value === '';
    const isColor = typeof value === 'string' && (value.includes('#') || value.includes('rgb'));
    const isFont = ['font', 'fontAlternate'].includes(propertyName);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [localValue, setLocalValue] = useState(value);

    const onChangeComplete = useCallback(
        color => {
            const newValue = color.hex ?? color;
            logging.debug('[COLOR_PICKER] ', propertyName, ': ', newValue);
            setLocalValue(newValue);
            updateTheme(propertyName, newValue);
        },
        [updateTheme, propertyName]
    );
    const onColorBlockClick = useCallback(() => {
        setShowColorPicker(p => !p);
    }, []);

    // prettier-ignore
    return rc(Fragment, null,
        rc(FormField, { title, errors, active, fieldEmpty, onBlur },
            isFont && rc(FontSelector, { value: localValue, onChange: e => onChangeComplete(e.target.value) },
            rc('option', { value: 'Lato' }, 'Lato'),
            rc('option', { value: 'Montserrat' }, 'Montserrat'),
            rc('option', { value: 'Nunito' }, 'Nunito'),
            rc('option', { value: 'Poppins' }, 'Poppins'),
            rc('option', { value: 'RedHatDisplay' }, 'RedHatDisplay'),
            rc('option', { value: 'RedHatText' }, 'RedHatText'),
            rc('option', { value: 'Roboto' }, 'Roboto')
            ),
            !isFont && rc(StyledTextInput, {
                active,
                id,
                name: propertyName,
                type,
                disabled,
                autoComplete,
                spellCheck: false,
                max,
                maxLength,
                min,
                minLength,
                pattern,
                placeholder: active ? placeholder ?? '' : '',
                multiline,
                rows,
                value: localValue,
                onKeyPress,
                onChange: onChangeComplete,
                onFocus,
                autoFocus,
                sequence
            }),
            isColor && rc(ColorBlock, { color: localValue, onClick: onColorBlockClick })
        ),
        // Color may change a lot (e.g. when dragging inside the color picker) so use onChangeComplete
        // to avoid unnecessary rerenders.
        isColor && showColorPicker && rc(PhotoshopPicker, {
            color: localValue,
            onChangeComplete,
            onAccept: onColorBlockClick,
            onCancel: onColorBlockClick
        })
    );
}
ThemeValue.propTypes = {
    onChange: PropTypes.func
};

export default ThemeValue;
