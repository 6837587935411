import logging from '@sstdev/lib_logging';
import { executeForEach } from '../registeredServiceQueues';
import { globalConfig, constants } from 'lib_ui-services';

const _p = {
    executeForEach
};

export const _private = _p;
export default {
    verb: 'doingCreate',
    namespace: 'sensor',
    relation: 'tag',
    description: 'Interact with native code to actually encode a physical tag',
    useCaseIds: [constants.useCaseIds.ITEM_TRACKING, constants.useCaseIds.WORK_IN_PROCESS],
    logic,
    onError
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @template T
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data: { newRecord }, context, dispatch }) {
    // don't catch errors, as we want them to be displayed on the form.
    const sensorType = newRecord.sensorType || constants.sensorTypes.RFID;
    const response = await _p.executeForEach('encode', { sensorType, ...newRecord }, context);
    if (response.find(r => r.type === sensorType)?.result == '') {
        logging.debug(`[${sensorType}] Encoding ${newRecord.value} succeeded`);
        dispatch(
            { isError: false, message: 'Tag Encoding Successful!', timeout: globalConfig().notificationTimeout },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );
    } else {
        const message = response[0].result || 'Unable to encode tags at this time';
        throw new Error(message);
    }
}

/**
 * @param {{
 *      error: Error;
 *      data: T;
 *      context: Context;
 *      dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>,
 *      workflowStack: WorkflowStack[]
 * }} parameters
 * */
function onError({ error, dispatch }) {
    //Errors don't work on Single Record forms, and I don't have time to figure out why.
    dispatch(
        {
            isError: true,
            message: error.message || error,
            timeout: globalConfig().notificationTimeout
        },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );

    // throw error;
    // return data;
}
