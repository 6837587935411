import { useContext, useMemo } from 'react';
import { eventSink, globalConfig } from 'lib_ui-services';
import { EventBoundaryContext } from '../components/contextProviders/EventBoundary';
import { contexts } from 'lib_ui-primitives';
const { PreviewContext } = contexts;

/**
 * @typedef {() => void} UnsubscribeFunction
 * @typedef {{enqueueHandlers:boolean, debugDetail:any, bubbleAlways:boolean, preventBubble:boolean, waitForSubscriber:true}} PublishOptions
 *
 * @typedef {(context: object, callback: (action: any) => any) => UnsubscribeFunction} SubscribeFunction
 * @typedef {(payload: object, context: object, options?: PublishOptions) => Promise} PublishFunction
 * @typedef {(payload: object, context: object, timeOutMs?: number = 30000 | 500 | 15000, errorWhenNoSubscribers: boolean = true, options?: PublishOptions) => Promise<any>} RequestFunction
 *
 * @typedef {[SubscribeFunction, PublishFunction, RequestFunction]} EventSink [subscribe, publish, request]
 */

/**
 * Inspired by https://kentcdodds.com/blog/how-to-use-react-context-effectively
 * See EventBoundary for the other half of this.
 */

const _p = {
    eventSink
};
/**
 * Get an event publish/subscribe provider (aka sink).
 * @param {boolean} ignoreMissingContext - usually this hook will fail if it doesn't see an existing EventBoundaryContext.  But it's possible a parent EventBoundary has not rendered yet or similar.
 * @returns {EventSink} [subscribe, publish, request]
 */
export default function useEventSink(ignoreMissingContext = false) {
    const context = useContext(EventBoundaryContext);
    const inPreview = useContext(PreviewContext);
    return useMemo(() => {
        if (context == null) {
            if (ignoreMissingContext) {
                return null;
            }
            throw new Error(
                'The parent react component hierarchy must contain a EventBoundary component before the useEventSink hook can be used.'
            );
        }
        const eventSink = _p.eventSink(context);

        // If this is inside a preview context, ensure every event and subscription is marked accordingly.
        if (inPreview) {
            const [subscribe, _publish, _request] = eventSink;
            return [
                subscribe,
                function publish(payload, context, options) {
                    failProhibitedPreviewVerbs(context.verb);
                    return _publish(payload, { ...context, preview: true }, options);
                },
                function request(payload, context, timeOutMs, errorWhenNoSubscribers = true, options) {
                    failProhibitedPreviewVerbs(context.verb);
                    return _request(payload, { ...context, preview: true }, timeOutMs, errorWhenNoSubscribers, options);
                }
            ];
        }

        // Otherwise, return the normal event sink.
        return eventSink;
    }, [context, ignoreMissingContext, inPreview]);
}

export const _private = _p;

function failProhibitedPreviewVerbs(verb) {
    if (globalConfig().prohibitedPreviewVerbs.includes(verb)) {
        throw new Error(`The verb ${verb} is prohibited in preview mode.`);
    }
}
