import { errors } from 'lib_ui-primitives';

export default {
    verb: 'doingValidate',
    namespace: 'application',
    relation: 'reset',
    excludedNamespaceRelations: [],
    priority: 10,
    featureFlag: 'clientDataRights',
    description: 'Require the user not to have data access limitations',
    prerequisites: [
        {
            context: { verb: 'get', namespace: 'identity', relation: 'userRole' }
        }
    ],
    logic: doingValidate
};

function doingValidate({ prerequisiteResults }) {
    const userRole = prerequisiteResults?.[0]?.result?.[0];
    if (!userRole) {
        throw new errors.ValidationError('Unable to find role to evaluate access limitations.', {});
    }
    if (userRole.access?.dataAccess?.length) {
        throw new errors.ValidationError('Unable to perform an Application Purge with data access limitations.', {});
    }
}
