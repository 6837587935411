// This is just a pass through, as to be able to handle both datawedge scans AND laser scans,
// this is handled in useFormControl
/**
 * @param {*} hNode
 * @returns {(value: string) => string}
 */
// eslint-disable-next-line no-unused-vars
const tabAfterScan = hNode => value => value;

export default tabAfterScan;
