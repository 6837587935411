import logging from '@sstdev/lib_logging';
import { constants } from 'lib_ui-services';
import { executeForEach } from '../registeredServiceQueues';
export default {
    verb: 'doingReset',
    namespace: 'sensor',
    relation: 'read',
    description: 'Reset (i.e. clear) reads in a service that provides RFID sensor reads',
    logic
};

async function logic({ data, context }) {
    try {
        // Right now, this rules is used to reset reads on the zebra RFID service
        // Other handlers reset the javascript side reads
        data.sensorType = data.sensorType ?? constants.sensorTypes.RFID;
        executeForEach('resetReads', data, context);
    } catch (err) {
        logging.error(err, context);
    }
}
