//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _doingValidate_rex_rma from './doingValidate_rex_rma';
import _willCreate_rex_rma from './willCreate_rex_rma';
export const doingValidate_rex_rma = _doingValidate_rex_rma;
export const willCreate_rex_rma = _willCreate_rex_rma;
export default {doingValidate_rex_rma, willCreate_rex_rma};

