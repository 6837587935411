import { useState, useEffect } from 'react';
import lodash from 'lodash';
const { get } = lodash;
import useFormContext from '../../../../hooks/useFormContext';
import useEventSink from '../../../../hooks/useEventSink';
const { isEqual } = lodash;

const _p = {
    useFormContext
};
export const _private = _p;

/**
 * Retrieve the full record referenced by a dropdown selection
 * @param {*} targetRelation
 * @param {*} defaultValue
 * @returns
 */
export default function useSelectedFormEntry({ namespace, relation }, defaultValue = {}) {
    const { newRecord = {} } = _p.useFormContext();
    const [subscribe, publish] = useEventSink();
    const [selectedRecord, setSelectedRecord] = useState(defaultValue);
    const dropdownValue = get(newRecord, `${namespace}:${relation}`);
    useEffect(() => {
        const unsubscribe = subscribe({ verb: 'get', namespace, relation, status: 'success' }, ({ result } = {}) => {
            if (!result || !result.length) return;
            const dbRecord = result[0];
            if (dbRecord) {
                if (!isEqual(selectedRecord, dbRecord)) {
                    setSelectedRecord(dbRecord);
                }
            } else {
                if (!isEqual(selectedRecord, defaultValue)) {
                    setSelectedRecord(defaultValue);
                }
            }
        });
        if (dropdownValue?._id && dropdownValue?._id === selectedRecord?._id) {
            //we already loaded the record for the selected dropdown value.
            return;
        } else if (dropdownValue?._id && dropdownValue?._id !== selectedRecord?._id) {
            // a new value was selected in the dropdown
            publish({ _id: dropdownValue._id }, { verb: 'get', namespace, relation });
        } else {
            //the dropdown was cleared
            if (!isEqual(selectedRecord, defaultValue)) {
                setSelectedRecord(defaultValue);
            }
        }
        return unsubscribe;
    }, [namespace, relation, dropdownValue, subscribe, publish, selectedRecord, defaultValue]);
    return selectedRecord;
}
