import { useEffect, useCallback } from 'react';
import lodash from 'lodash';
const { debounce } = lodash;

/**
 * Using debounce inside a react component isn't ideal because the debounced function
 * will be instantiated every time react re-renders the component, causing the
 * debounced function to be called on ever re-render.  This is not great if your
 * debounced function is setting state. 👀 This hook abstracts the memoization of
 * the debounced function so that it only gets called when you expect it.
 * @template {Function} T
 * @param {T} callback
 * @param {number} [delay=1000]
 * @param {import('lodash').DebounceSettings} [options]
 */
function useDebounce(callback, dependencies, delay = 1000, options) {
    if (dependencies == null) {
        throw new Error(
            'Pass in any dependencies for your debounced function.  If you do not have any dependencies, pass an empty array.'
        );
    }

    // The useCallback here is to prevent multiple instances of the debounce from being created
    // on new renders.  It returns a debounced function just like lodash.debounce.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounced = useCallback(debounce(callback, delay, options), [delay, ...dependencies]);
    useEffect(() => {
        // Return a function from the useEffect so that it can be called on unmount
        // or if there is a a change to dependencies,
        // The function just cancels the previous debounce like the lodash.debounce cancel function.
        return () => debounced.cancel();
    }, [debounced]);

    return debounced;
}

export default useDebounce;
