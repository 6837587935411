import { useCallback, createElement as rc, useMemo } from 'react';
import { testProperties } from 'lib_ui-primitives';
import PropTypes from 'prop-types';
import { ACCESS_STATE, tooltip, lesserState, stateAsIcon } from './ACCESS_STATE';

import { AccessIconToggle } from './styles';

function ViewportAccessToggle(props) {
    const { id, maxAccessState, parentAccessState, value, onChange, nonRemovable } = props;

    const setNextState = useCallback(
        currentState => {
            // Logic:
            // If the parent is DISABLED, or the page cannot be removed (non Removable), don't change the state
            // If the parent is ENABLED, cycle through ENABLED => DISABLED => DESKTOP_ONLY => MOBILE_ONLY
            // IF the parent is DESKTOP_ONLY, toggle between DESKTOP_ONLY <=> DISABLED
            // IF the parent is MOBILE_ONLY, toggle between MOBILE_ONLY <=> DISABLED
            if (nonRemovable || parentAccessState === ACCESS_STATE.DISABLED) {
                return;
            }

            //breaking this up in 2 switch statements to make it more readable
            let nextAccessState;
            switch (currentState) {
                case ACCESS_STATE.ENABLED:
                    nextAccessState = ACCESS_STATE.DISABLED;
                    break;
                case ACCESS_STATE.DISABLED:
                    if ([parentAccessState, maxAccessState].includes(ACCESS_STATE.MOBILE_ONLY)) {
                        nextAccessState = lesserState(ACCESS_STATE.MOBILE_ONLY, maxAccessState);
                    } else {
                        nextAccessState = lesserState(ACCESS_STATE.DESKTOP_ONLY, maxAccessState, parentAccessState);
                    }
                    break;
                case ACCESS_STATE.DESKTOP_ONLY:
                    if ([parentAccessState, maxAccessState].includes(ACCESS_STATE.DESKTOP_ONLY)) {
                        nextAccessState = ACCESS_STATE.DISABLED;
                    } else {
                        nextAccessState = lesserState(ACCESS_STATE.MOBILE_ONLY, maxAccessState, parentAccessState);
                    }
                    break;
                case ACCESS_STATE.MOBILE_ONLY:
                    if ([parentAccessState, maxAccessState].includes(ACCESS_STATE.MOBILE_ONLY)) {
                        nextAccessState = ACCESS_STATE.DISABLED;
                    } else {
                        nextAccessState = lesserState(ACCESS_STATE.ENABLED, maxAccessState, parentAccessState);
                    }
                    break;
            }

            onChange(nextAccessState);
        },
        [parentAccessState, onChange, maxAccessState, nonRemovable]
    );

    const componentProps = useMemo(() => {
        return {
            onClick: () => setNextState(value),
            // `title` is shown as a tooltip on desktop:
            title: tooltip[lesserState(parentAccessState, value)],
            icon: stateAsIcon(lesserState(parentAccessState, value)),
            ...testProperties({ id }, 'viewportToggle')
        };
    }, [id, parentAccessState, setNextState, value]);

    return rc(AccessIconToggle, componentProps);
}

ViewportAccessToggle.propTypes = {
    maxAccessState: PropTypes.number,
    parentAccessState: PropTypes.number,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    nonRemovable: PropTypes.bool
};

export default ViewportAccessToggle;
