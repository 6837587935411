//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _doingValidate_inventory_inventory_close from './doingValidate_inventory_inventory_close';
import _doingValidate_inventory_inventory_critChange from './doingValidate_inventory_inventory_critChange';
export const doingValidate_inventory_inventory_close = _doingValidate_inventory_inventory_close;
export const doingValidate_inventory_inventory_critChange = _doingValidate_inventory_inventory_critChange;
export default {doingValidate_inventory_inventory_close, doingValidate_inventory_inventory_critChange};

