import { getRelationMetadata } from '../metadata';
import http from '../http';
import getQueryUrl from '../http/getQueryUrl';
import network from '../network';
import database from '../database';
import getGlobalConfig from '../globalConfig';

const { minFullTextSearchTermLength } = getGlobalConfig();
const _p = { network, http, database, getRelationMetadata, getQueryUrl };
export default {
    getFilter,
    getUriComponent,
    fromHNode,
    pageResetRequired: true,
    preFilter,
    getMql,
    _private: _p
};

function fromHNode() {
    return getFilter();
}

function getFilter(searchTerm = '') {
    return {
        searchTerm
    };
}

function getUriComponent(filters, limitSyncSize) {
    const { fullTextSearch: { searchTerm = '' } = {}, fields = ['_id'] } = filters;
    if (searchTerm === '') {
        return '';
    }
    let url = `searchTerm=${encodeURIComponent(searchTerm)}`;
    // If this relation has a limitSyncSize property, then return the full record so that
    // it will be merged into the local database (where it might not already exist).
    // Otherwise, we just need the _ids to match local records.
    if (!limitSyncSize) {
        url += `&fields=${encodeURIComponent(JSON.stringify(fields))}`;
    }
    // While propertiesToSearch is used in getMql(), it is not processed here.
    // Instead, it is added to the URL in propertiesToSearch.js.
    return url;
}

async function preFilter(filters, filterId, lokiView) {
    const { paged: { page = 0 } = {}, fullTextSearch: { searchTerm } = {} } = filters;
    if (searchTerm == null) return;

    const bbFilterType = 'fullTextSearch';
    const combinedFilterId = filterId + bbFilterType;
    const { namespace, relation } = lokiView;
    const { limitSyncSize, supportsFullTextSearch } = _p.getRelationMetadata(namespace, relation);

    const { isOnline } = await _p.network.getStatus();
    if (isOnline && searchTerm?.length >= minFullTextSearchTermLength && supportsFullTextSearch) {
        const modifiedFilters = { ...filters };
        delete modifiedFilters.propertiesToSearch;
        if (modifiedFilters.paged == null) {
            throw new Error('A paged filter is required for an online fullTextSearch.');
        }
        // Get _ids from server and add filter to lokiView for _ids
        const url = _p.getQueryUrl(namespace, relation, modifiedFilters, filterId);
        const serverResult = await _p.http.get(url, true);
        const idsFound = serverResult?.items.map(i => i._id) || [];
        const bbFilter = filters[bbFilterType];
        // start with any previous server lookup results (if any)
        bbFilter.serverResults =
            lokiView.filterPipeline.find(f => f.uid === combinedFilterId)?.bbFilter?.serverResults ?? {};
        // add this page of results
        bbFilter.serverResults[`page${page}`] = idsFound;
        // If this is a limitSyncSize relation, then the server will return the whole record
        // (because they might not exist locally), so insert them locally.
        if (limitSyncSize) {
            const records = serverResult?.items ?? [];
            if (!records || records.length === 0) return;
            // Mark each record with the queryId so it can be cleaned up later
            records.forEach(record => {
                record.meta.forTempQueryResult = filterId;
            });

            // get a reference to the local database and upsert the records
            const db = _p.database.get();
            await db.bulkUpsert(namespace, relation, records);
            // Provide cleanup function to remove these inserted records.  Otherwise, the
            // local database may become too large.
            return () =>
                db.removeMany(
                    { trueDelete: true, criteria: { 'meta.forTempQueryResult': filterId } },
                    { namespace, relation }
                );
        }
    }
}

function getMql(filters) {
    const {
        fullTextSearch,
        propertiesToSearch,
        caseSensitive = false,
        exactMatchOnly = false,
        preferLocal = false
    } = filters;

    if (fullTextSearch == null || fullTextSearch.searchTerm == null || fullTextSearch.searchTerm === '') return;

    // If we have full text search results from the server, use those.
    if (!preferLocal && Object.keys(fullTextSearch.serverResults ?? {}).length > 0) {
        // Gather all server _id results into one array.
        const $in = Object.values(fullTextSearch.serverResults).reduce((prev, current) => prev.concat(current), []);
        return { _id: { $in } };
    }

    if (propertiesToSearch?.propertiesToSearch == null) {
        throw new Error('propertiesToSearch must be specified when performing a local fullTextSearch.');
    }

    //regex taken from https://stackoverflow.com/a/9310752/8575651
    const escapedString = fullTextSearch.searchTerm.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
    // If we have a splitChar, then we need to search for each term separately.
    if (fullTextSearch.splitChar && escapedString.includes(fullTextSearch.splitChar)) {
        // split the search term, and trim any leading or trailing whitespace
        const terms = escapedString.split(fullTextSearch.splitChar).map(s => s.trim());
        // We want ALL the terms to be present in the result, so we use $and.
        return {
            // but we only need 1 property to contain the term, so per term we use $or.
            $and: terms.map(term => getOr(propertiesToSearch, term, { caseSensitive, exactMatchOnly }))
        };
    }
    //otherwise, we just need one of the properties to contain the entire term (use $or)
    return getOr(propertiesToSearch, escapedString, { caseSensitive, exactMatchOnly });
}

function getOr(propertiesToSearch, escapedString, options) {
    const { caseSensitive, exactMatchOnly } = options;
    const $or = propertiesToSearch.propertiesToSearch
        .filter(x => x)
        .map(key => {
            let $regex = [escapedString];
            if (caseSensitive) {
                if (exactMatchOnly) {
                    return { [key]: escapedString };
                }
            } else if (exactMatchOnly) {
                $regex = [`^${$regex[0]}$`, 'i'];
            } else {
                $regex.push('i');
            }

            return { [key]: { $regex } };
        });
    if ($or.length === 1) {
        return $or[0];
    }
    return { $or };
}
