import globalConfig from '../globalConfig';
import copyActiveRoleToUserRoot from './copyActiveRoleToUserRoot';

//in preparation for OAuth
export default async function refreshToken({ refresh_token, loginExpiresAt }) {
    const url = `${globalConfig().protocol}//${globalConfig().hostname}/api/oauth/token`;
    const data = {
        grant_type: 'refresh_token',
        client_id: globalConfig().clientId,
        refresh_token
    };
    const headers = { 'content-type': 'application/x-www-form-urlencoded' };
    const params = await setPostMethod(data, headers);
    const response = rawPost(url, params);

    if (!response || !response.access_token) {
        //we probably should redirect to login here...?
        throw new Error('Unable to renew token');
    }
    let accessTokenValidUntil = secondsInTheFuture(0.8 * response.expires_in);
    return getUserProfileFromToken({
        ...btoa(response.access_token),
        ...response,
        loginExpiresAt,
        accessTokenValidUntil
    });
}

//we need to bypass the http.post functionality, as otherwise we'd end up with a circular reference
async function rawPost(url, params) {
    const response = await fetch(url, params);
    if (response.ok) {
        return response.json();
    }
}

function setPostMethod(data, headers = {}) {
    let body;
    headers = {
        'content-type': 'application/json',
        ...headers
    };
    body = typeof data === 'string' ? data : formUrlEncode(data);
    return {
        method: 'POST',
        body,
        headers
    };
}

function formUrlEncode(data) {
    let formBody = [];
    for (const property in data) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
}

const secondsInTheFuture = secondsToAdd => {
    let someDate = new Date();
    someDate.setSeconds(someDate.getSeconds() + secondsToAdd);
    return someDate;
};

function getUserProfileFromToken(token) {
    let { user, activeTenantId, activeUseCaseId } = token;
    let activeTenant = user.tenant.find(t => t['identity:tenant']._id === activeTenantId);
    let activeUseCase = activeTenant.useCase.find(t => t['metaui:useCase']._id === activeUseCaseId);

    const userActiveRecord = copyActiveRoleToUserRoot(activeTenant, activeUseCase, user);
    const allFeatureFlags = activeUseCase.featureFlags || [];
    return {
        ...userActiveRecord,
        activeTenantId,
        activeUseCaseId,
        activeTenant,
        activeUseCase,
        allFeatureFlags
    };
}
