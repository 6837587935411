//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _DashboardLayout from './DashboardLayout';
import _EvenColumnsLayout from './EvenColumnsLayout';
import _GreedyColumnLayout from './GreedyColumnLayout';
import _GreedySectionLayout from './GreedySectionLayout';
import _LeftAlignLayout from './LeftAlignLayout';
import _MeatbalProfileLayout from './MeatbalProfileLayout';
import _RightAlignLayout from './RightAlignLayout';
import _SpaceApartLayout from './SpaceApartLayout';
import _SummaryDetailLayout from './SummaryDetailLayout';
import _TabViewLayout from './TabViewLayout';
export const DashboardLayout = _DashboardLayout;
export const EvenColumnsLayout = _EvenColumnsLayout;
export const GreedyColumnLayout = _GreedyColumnLayout;
export const GreedySectionLayout = _GreedySectionLayout;
export const LeftAlignLayout = _LeftAlignLayout;
export const MeatbalProfileLayout = _MeatbalProfileLayout;
export const RightAlignLayout = _RightAlignLayout;
export const SpaceApartLayout = _SpaceApartLayout;
export const SummaryDetailLayout = _SummaryDetailLayout;
export const TabViewLayout = _TabViewLayout;
export default {DashboardLayout, EvenColumnsLayout, GreedyColumnLayout, GreedySectionLayout, LeftAlignLayout, MeatbalProfileLayout, RightAlignLayout, SpaceApartLayout, SummaryDetailLayout, TabViewLayout};

