import { createElement as rc, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { styled, nativeOnlyStyles, Button, contexts } from 'lib_ui-primitives';
import { useTheme } from 'styled-components';
const { FocusContext } = contexts;

let _ComboButton = styled(Button).attrs({ name: 'combo-button' })`
    flex-shrink: 0;
`;
_ComboButton = nativeOnlyStyles(_ComboButton)`
    padding: 8px;
`;
_ComboButton.displayName = 'ComboButton';

/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function ComboButton(props) {
    const { isOpen, mobile, id, toggleButtonProps, ...otherProps } = props;
    const focusContext = useContext(FocusContext);
    const theme = useTheme();
    const [icon, title] = getIconAndTitle(isOpen, mobile);

    // The downshift onPress was unnecessarily changing on every render.
    // Also this allows the next input to be focused after a dropdown selection
    // if the user clicks the chevron to open the dropdown.  Otherwise,
    // the actual input is never focused and the FocusProvider does not know
    // to select the input AFTER this dropdown's input.
    const clickMethod = toggleButtonProps.onPress == null ? 'onClick' : 'onPress';
    const onClickOrPress = toggleButtonProps[clickMethod];
    toggleButtonProps[clickMethod] = useCallback(e => {
        focusContext?.setLastFocused(`formFieldbb${id}-input`);
        onClickOrPress(e);
        // keep the original downshift event handler for onClick/onPress
        // eslint-disable-next-line
    }, []);

    delete toggleButtonProps.onBlur;

    // prettier-ignore
    return rc(_ComboButton, {
        id: `toggle-button-${id}`,
        icon,
        title,
        color: 'transparent',
        iconFont: 'MaterialCommunityIcon',
        fontColor: theme.darkMode ? '#fff' : '#000',
        buttonStyle: 'round',
        roundDiameter: theme.button.roundDiameterLarge,
        ...toggleButtonProps,
        ...otherProps,
        'data-testid': `${id}-toggle-button`
    });
}

ComboButton.defaultProps = {};

ComboButton.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    mobile: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    toggleButtonProps: PropTypes.any.isRequired
};

export default ComboButton;

function getIconAndTitle(isOpen, mobile) {
    switch (isOpen) {
        case true:
            return mobile ? ['close', 'Close'] : ['chevron-up', 'Close'];
        case false:
            return ['chevron-down', 'Open'];
    }
}
