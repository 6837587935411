import ZPL from '../ZPL';
import { register, render } from '../ZNode';

async function NewLabel(props) {
    const {
        record,
        zNode: { width = 406, height = 203, padding = 8, offset = { x: 0, y: 0 }, content }
    } = props || { zNode: {} };

    let output = ZPL.createLabel(width, height, padding);

    // eslint-disable-next-line no-undef
    if (__UNIT_TESTING__) {
        output = output.addLabelShape(width, height, padding);
    }
    output = output.addComment(`<${record.assetNo || record.title || record._id}>`).setHome(offset);

    return content.reduce(async (_sofar, content) => {
        const sofar = await _sofar;
        return render({ record, output: sofar, zNode: content, width, height, padding });
    }, output);
}

register('NewLabel', NewLabel);
export default NewLabel;
