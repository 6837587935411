export default {
    getFilter,
    getUriComponent,
    fromHNode,
    pageResetRequired: true,
    getDbIndex
};

function fromHNode({ propertyName, direction, optionalNamespace, optionalRelation }) {
    return getFilter(propertyName, direction, optionalNamespace, optionalRelation);
}

function getFilter(propertyName = '', direction = 'descending', optionalNamespace, optionalRelation) {
    return {
        propertyName,
        direction,
        optionalNamespace,
        optionalRelation
    };
}

function getUriComponent(filters) {
    const filter = filters['orderBy'];
    let { direction, propertyName, optionalNamespace, optionalRelation } = filter;
    if (optionalNamespace && optionalRelation) {
        propertyName = `${optionalNamespace}:${optionalRelation}.${propertyName}`;
    }
    if (direction !== 'descending') {
        propertyName += ':a';
    }
    return `orderBy=${encodeURIComponent(propertyName)}`;
}

function getDbIndex(filters) {
    const orderByFilter = filters?.orderBy;
    if (!orderByFilter) return 'title';
    let { propertyName, optionalNamespace, optionalRelation } = orderByFilter;
    if (optionalNamespace && optionalRelation) {
        return `${optionalNamespace}:${optionalRelation}.${propertyName}`;
    }
    return propertyName || 'title';
}
