import { constants, dbViews, http } from 'lib_ui-services';
import notHandledHere from '../notHandledHere';
import downloadTheFile, { sendToFile } from '../exportHelpers/exportHelper';
import { transformRecords, applyDidGet } from '../exportHelpers/utils';

const { getDbView } = dbViews;

const _p = {
    getDbView,
    downloadTheFile,
    http,
    waitForStatus,
    checkExportConditions,
    performServerSideExport,
    sendToFile,
    waitFor
};

export const _private = _p;

export default {
    verb: 'doingExport',
    excludedNamespaceRelations: notHandledHere,
    description: 'Export the records to xlsx, xls, csv, html or pdf based on the filter',
    // this is the actual logic:
    logic,
    onError
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 *
 * @typedef ExportDefinition
 * @property {Array} [data] OPTIONAL Array of data to use instead of using it from a view
 * @property {string} [viewName] OPTIONAL name of LokiJS view if not passing in data directly
 * @property {string} [filePrefix] OPTIONAL Title/file prefix
 * @property {Array.<{propertyName:string, label:string, dataType:string}>} columns
 */

/**
 * @param {{
 *   data: ExportDefinition;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 */
async function logic({ data, context, dispatch, log }) {
    const { namespace, relation, fileType, featureFlags, navigationSelection, fileName, filePrefix, columns } = data;

    const canPerformServerSideExport = _p.checkExportConditions(data);
    if (canPerformServerSideExport) {
        return _p.performServerSideExport(data, dispatch, log);
    }

    let records = data.data;
    if (!records?.length) {
        const view = await _p.getDbView(namespace, relation, data.viewName);
        records = view.data;
        // if we still have no records, we don't need to do anything else
        if (!records?.length) return;
    }

    // if groupBy, flatten the _id
    if (typeof records[0]._id === 'object') {
        // result of an aggregate
        records = records.map(item => {
            const { _id, ...rest } = item;
            return { ...rest, ..._id };
        });
    }

    // allow running any post-processing rules, like depreciation
    records = await applyDidGet(records, context, dispatch);

    /**
     * Transform the records based on the feature flags enabled
     * This is done before the export so that the export is consistent with the view
     * If multiInventory is enabled, filter the records to only include the selected inventory in the navigation context
     * If displayInAscii is enabled, transform the encodedText properties to ascii
     */
    transformRecords(records, columns, featureFlags, navigationSelection); // update applicable fields to show hex values in ascii

    await _p.downloadTheFile(fileName, records, columns, fileType, filePrefix);
}

/**
 * Perform server-side export
 */
async function performServerSideExport(data, dispatch, log) {
    // It takes about 150,000ms to export 100,000 records
    // as of 05/06/2024, we are setting the max number of export records to 500,000
    // TODO: Determine the retry count and wait time based on the number of records
    const MAX_RETRIES = 150;
    const WAIT_TIME = 5000;

    try {
        dispatch(
            { busy: true, source: 'httpSync', message: 'Exporting data', type: constants.notificationTypes.SYNC_BUSY },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );

        // wait until the report has a status of 'Complete'
        const reportRecord = await _p.waitForStatus({
            url: `/api/report/generate/${data.reportId}`,
            checkProperty: 'status',
            successValue: 'Complete',
            failedValue: 'Failed',
            maxRetries: MAX_RETRIES,
            waitTime: WAIT_TIME,
            dispatch
        });

        if (reportRecord.length === 0) {
            throw new Error('Export failed');
        }

        const result = await getContent(data.reportId);

        dispatch(
            {
                busy: false,
                source: 'httpSync',
                message: 'Export Done',
                type: constants.notificationTypes.SYNC_BUSY
            },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );

        return _p.sendToFile(data.fileName, result, data.fileType);
    } catch (error) {
        log.error(error);
        throw error;
    }
}

/**
 * Retrieve the content of the export.
 * Sometimes, the content is not immediately available from Google Cloud Storage, so we retry the request.
 * @param {string} reportId The ID of the report
 * @param {number} contentRetryCount The number of retries for fetching content
 * @returns {Promise<Object>} The content of the export
 */
async function getContent(reportId, contentRetryCount = 0) {
    // Maximum retries for fetching content after a 404 error
    const MAX_CONTENT_RETRIES = 3;
    const CONTENT_WAIT_TIME = 3000;

    while (contentRetryCount < MAX_CONTENT_RETRIES) {
        try {
            const result = await _p.http.get(`/api/report/generate/${reportId}/content`);
            if (result) {
                return result;
            }
        } catch (error) {
            if (error.status === 404) {
                contentRetryCount++;
                await waitFor(CONTENT_WAIT_TIME);
            } else {
                throw error;
            }
        }
    }
    throw new Error('Failed to retrieve export content after multiple attempts.');
}

/**
 * Wait for the given milliseconds
 * @param {number} milliseconds The given time to wait
 * @returns {Promise} A fulfilled promise after the given time has passed
 */
function waitFor(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

/**
 * Checks if the server-side export can proceed based on certain conditions.
 */
function checkExportConditions(data) {
    // Feature Flag for server-side export generation
    const FF_SERVER_SIDE_EXPORT = 'SERVER_SIDE_EXPORT';
    if (data.reportId && data.featureFlags?.includes(FF_SERVER_SIDE_EXPORT)) {
        return true;
    }
    return false;
}

function onError({ error, dispatch }) {
    dispatch(
        {
            busy: false,
            source: 'httpSync',
            message: 'Export Failed',
            type: constants.notificationTypes.SYNC_BUSY
        },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
    throw error;
}

/**
 * Checks if the specified property in the first record from the HTTP response matches the expected value.
 * Retries the HTTP request until the property matches the successValue, encounters the failedValue, or the maximum number of retries is met.
 * Dispatches a notification only when the status changes from the previous status.
 *
 * TODO: Determine the wait time and number of retries based on the record count
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.url - The URL to fetch data from.
 * @param {string} params.checkProperty - The property to check in the record.
 * @param {string} params.successValue - The expected value of the property.
 * @param {string} params.failedValue - The value of the property that should stop retries immediately.
 * @param {function} [params.dispatch] - The dispatch function to send a notification to the user.
 * @param {number} [params.maxRetries=10] - The maximum number of retries allowed.
 * @param {number} [params.waitTime=5000] - The time to wait before retrying.
 * @returns {Promise<Array>} - Returns the array of records if the check passes.
 * @throws {Error} - Throws an error if the property value does not match the expected value or if retries fail.
 */
export async function waitForStatus({
    url,
    checkProperty,
    successValue,
    failedValue,
    dispatch,
    maxRetries = 10,
    waitTime = 5000
}) {
    let retryCount = 0;
    let previousStatus = null;
    while (retryCount < maxRetries) {
        // Wait before making the first HTTP GET request
        // No need to check immediately since the report is unlikely to be ready
        await new Promise(resolve => setTimeout(resolve, waitTime));
        const httpResult = await _p.http.get(url);
        const records = httpResult?.items;
        if (records && records.length > 0) {
            const currentStatus = records[0][checkProperty];
            if (currentStatus === successValue) {
                return records;
            } else if (currentStatus === failedValue) {
                if (dispatch && currentStatus !== previousStatus) {
                    await dispatch(
                        {
                            addToList: false,
                            message: `Export failed, the ${checkProperty} is ${failedValue}.`
                        },
                        { verb: 'pop', namespace: 'application', relation: 'notification' }
                    );
                }
                return [];
            }
            if (dispatch && currentStatus !== previousStatus) {
                await dispatch(
                    {
                        addToList: false,
                        message: `Current ${checkProperty} is ${currentStatus}.`
                    },
                    { verb: 'pop', namespace: 'application', relation: 'notification' }
                );
            }
            previousStatus = currentStatus;
        }
        retryCount++;
    }
    throw new Error('Export failed. The wait time for the export to complete was exceeded.');
}
