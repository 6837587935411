import { memo, createElement as rc } from 'react';
import PropTypes from 'prop-types';
import HNode from '../../HNode';
import createTreePositionGetter from '../../utilities/createTreePositionGetter';

function RouteChildren(props) {
    const { parentHNode, currentRoute } = props;
    const nonNavHeadings = parentHNode.children.filter(c => c.hNodeTypeGroup !== 'navHeading');
    const getTreePosition = createTreePositionGetter(parentHNode);
    return nonNavHeadings.map((hNodeChild, i) => {
        hNodeChild.treePosition = getTreePosition(i);
        return rc(HNode, {
            key: hNodeChild.id,
            hNode: hNodeChild,
            currentRoute: currentRoute
        });
    });
}

RouteChildren.propTypes = {
    parentHNode: PropTypes.object.isRequired,
    currentRoute: PropTypes.string.isRequired
};

export default memo(RouteChildren);
