import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { metadata } from 'lib_ui-services';
import HNode from '../../HNode';
import SplashRecord from '../contextProviders/SplashRecord';
import useSplashRecord from '../../hooks/useSplashRecord';
import NoNavAppLayout from './NoNavAppLayout';
import { Text } from 'lib_ui-primitives';

function SplashContextAppLayout(props) {
    const {
        hNode: { namespace, relation }
    } = props || { hNode: {} };

    // prettier-ignore
    return rc(SplashRecord, {namespace, relation },
            rc(InnerSplash, props)
        );
}

function InnerSplash(props) {
    const {
        hNode,
        hNode: {
            namespace,
            relation,
            splashPage,
            children: [nestedAppLayout]
        },
        currentRoute
    } = props || { hNode: {} };
    const { hasSplashed, isLoading } = useSplashRecord();
    if (isLoading) {
        return rc(Text, {}, 'Loading...');
    }
    if (hasSplashed) {
        return rc(HNode, { hNode: nestedAppLayout, currentRoute });
    } else {
        const relationLabel = metadata.getPrettyRelationName(namespace, relation);
        const noSideNav = {
            ...nestedAppLayout.children[0],
            children: [{ ...splashPage, title: `Select ${relationLabel}` }]
        };
        return rc(NoNavAppLayout, { ...props, hNode: { ...hNode, children: [noSideNav] }, children: undefined });
    }
}

SplashContextAppLayout.propTypes = {
    hNode: PropTypes.object
};
export default SplashContextAppLayout;
