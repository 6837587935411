import { createElement as rc, useCallback } from 'react';
import useCollapsibleHierarchy from './useCollapsibleHierarchy';
import CollapsibleHierarchyContext from './CollapsibleHierarchyContext';

/**
 * Creates a higher-order component that adds collapsible functionality to a given component.
 * This higher-order component will wrap the given component with a CollapsibleHierarchyContext.Provider
 * and provide functions used to control the collapsible functionality similar to the way
 * react.forwardRef() works.
 * It must be used in conjuction with the makeCollapsibleRow component.
 * @param {React.Component} Component - The component to be wrapped with collapsible functionality.
 * @returns {React.Component} - The wrapped component with collapsible functionality.
 *
 * @example
 * // Create a collapsible component
 * const Collapsible = makeCollapsible((props, { isCollapsed, setCollapsed, deleteCollapsed, addCollapsed, collapseAll, expandAll }) => {
 *    return < a component that uses the above functions to control collapsible functionality />
 */
export default function makeCollapsible(Component) {
    return props => {
        const name = [props?.name ?? props?.hNode?.hNodeType, props?.hNode?.title, props?.id]
            .filter(s => s != null)
            .join('|');
        const { hierarchyContext: context, currentHierarchyRowCoordinates: coordinatesForThisList } =
            useCollapsibleHierarchy(name);

        /** sets whether the current collapsible node is collapsed */
        const setCollapsed = useCallback(
            collapsed => {
                context.setCollapsed(collapsed, coordinatesForThisList);
            },
            [context, coordinatesForThisList]
        );

        /** Call when deleting a collapsible row */
        const deleteCollapsed = useCallback(
            index => {
                context.deleteCollapsed([...coordinatesForThisList, index]);
            },
            [context, coordinatesForThisList]
        );

        /** Call when adding a collapsible row */
        const addCollapsed = useCallback(() => {
            context.addCollapsed(coordinatesForThisList);
        }, [context, coordinatesForThisList]);

        const expandAll = useCallback(() => {
            context.expandAll(coordinatesForThisList);
        }, [context, coordinatesForThisList]);

        const collapseAll = useCallback(() => {
            context.collapseAll(coordinatesForThisList);
            context.setCollapsed(false, coordinatesForThisList);
        }, [context, coordinatesForThisList]);

        /** Determines if the current collapsible node has children rows */
        const hasChildren = useCallback(() => {
            return context.hasChildren(coordinatesForThisList);
        }, [context, coordinatesForThisList]);

        const collapsed = context.isCollapsed(coordinatesForThisList);
        const reactComponentInstance = Component(props, {
            collapsed,
            setCollapsed,
            deleteCollapsed,
            addCollapsed,
            collapseAll,
            expandAll,
            hasChildren,
            coordinatesForThisList
        });
        // prettier-ignore
        return rc(CollapsibleHierarchyContext.Provider, { value: context },
            reactComponentInstance
        );
    };
}
