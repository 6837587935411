import authentication from '../authentication';
import log from '@sstdev/lib_logging';
import ObjectId from '../ObjectId';

/**
 *
 * @param {string} event
 * @param {Array<Object>} data
 * @param {Array} eventSink [subscribe, publish, request]
 * @returns
 */
export default async function handleBackboneMessages(event, rawData, eventSink) {
    log.debug(`[SOCKETS] processing ${event}.`);
    if (!Array.isArray(rawData)) {
        log.error('[SOCKETS] Unexpected data load. Data is not an array.', rawData);
        return;
    }
    if (rawData.length !== 1) {
        log.error(`[SOCKETS] Unexpected data load. Data length is not 1 (it was ${rawData.length}).`);
        return;
    }
    const data = rawData[0];
    try {
        const [, publish] = eventSink;
        //we received some event from the server
        const [verb, namespace, relation, status] = event.split('_');
        const context = { verb, namespace, relation, status };
        //process it as we please
        let upsert = {};
        if (context.namespace === 'socket' && context.relation === 'room') {
            //this is a device-connecting (or disconnecting) event
            //and people don't want to be bothered by this :)
            //it comes in as `load_socket_room_success`,
            // but  it has a `data.message` property, rather than that data is an array of updates
            return;
        }
        if (data.payload) {
            if (Array.isArray(data.payload)) {
                upsert.items = data.payload;
            } else {
                upsert.items = [data.payload]; // Maintain backwards compatibility with the previous socket server
            }
        }
        if (status === 'failure') {
            publish(data, context);
            return;
        } else if (event === 'chat') {
            // TODO: at some point we probably should change the server side verb for
            // this to something more intuitive.
            publish(
                {
                    message: data.displayName + ': ' + data.message,
                    timeout: 2000
                },
                {
                    verb: 'pop',
                    namespace: 'application',
                    relation: 'notification'
                }
            );
        } else if (event === 'create_deploy_release_success') {
            // Special situation here.  A new version of backbone has
            // been deployed.  We need to pass this on to the rest of the client.
            // This will inform the lib_ui-service-worker so
            // it can check to see if it needs to install a new service worker.
            publish(data, context);
            return;
        } else {
            switch (verb) {
                case 'load':
                case 'get': {
                    //server sent us a `get`, more than likely a `get__success`.
                    //so, the data just needs to be upserted into the database
                    publish({ items: data }, { verb: 'upsert', namespace, relation });
                    break;
                }
                case 'upsert': {
                    if (event === 'upsert_application_purgeClient_success') {
                        //if we received purge requests
                        const loggedInUser = authentication.getCurrentSession();
                        //and (all) the received purge requests are NOT specifically for someone else
                        const applicablePurgeRequests = data.create.concat(data.update).some(r => {
                            return (
                                !r['identity:user'] ||
                                !r['identity:user']._id ||
                                r['identity:user']._id === loggedInUser._id
                            );
                        });
                        if (applicablePurgeRequests) {
                            publish(
                                {
                                    message: 'One moment please while we re-sync your data.',
                                    timeout: 2000
                                },
                                { verb: 'pop', namespace: 'application', relation: 'notification' }
                            );

                            log.debug('[SOCKETS] Initiating full sync');

                            return publish(
                                {},
                                {
                                    verb: 'update',
                                    namespace: 'application',
                                    relation: 'useCase',
                                    type: 'syncAllDataToLocal'
                                }
                            );
                        }
                        break;
                    }
                    // New way of processing socket messages as batch:
                    // three arrays, process them in this order: create, update, remove
                    let { create, update, remove } = data;

                    if (create != null && create.length > 0) {
                        let items = create;
                        if (update?.length) {
                            // if an item exists in both create and update, take the update version
                            // to avoid a race condition. This happens e.g. when importing items in AT
                            // which are added to the inventory in a separate step, resulting in both a create, and an update being send.
                            items = create.map(c => {
                                const newC = update.find(u => u._id === c._id);
                                return newC || c;
                            });
                        }
                        publish({ isNew: true, items }, { verb: 'upsert', namespace, relation });
                    }
                    if (update != null && update.length > 0) {
                        publish({ items: update }, { verb: 'upsert', namespace, relation });
                    }
                    if (remove != null && remove.length > 0) {
                        remove.forEach(item => {
                            publish(
                                { id: item._id || item.id, meta: item.meta, skipConfirm: true },
                                { verb: 'remove', namespace, relation }
                            );
                        });
                    }
                    break;
                }
                case 'create':
                    publish({ isNew: true, ...upsert }, { verb: 'upsert', namespace, relation });
                    break;
                case 'update': {
                    publish({ ...upsert }, { verb: 'upsert', namespace, relation });
                    break;
                }
                case 'remove':
                    publish(
                        { id: data.id.$oid ? data.id.$oid : data.id, meta: data.meta },
                        { verb: 'remove', namespace, relation }
                    );
                    break;
                case 'send': {
                    //GPO Updates, `send_deviceManagement_gpo_success`
                    let item = { _id: new ObjectId(), ...data };
                    //we _might_ want to do this for all "send" actions? stuff them into a "stream" namespace
                    //to emphasize the difference of their use (socket push only, so, shouldn't show up in API, shouldn't have edit (or add) screens, etc)
                    let streamingNamespace = namespace === 'deviceManagement' ? 'stream' : namespace;
                    if (event === 'send_deviceManagement_gpo_success') {
                        //do some additional manipulation for easy display
                        item.gpoPorts = item.gpoPorts.join();
                        item.receivedTime = new Date().toISOString();
                    }
                    publish(
                        { isNew: true, items: [item] },
                        { verb: 'upsert', namespace: streamingNamespace, relation }
                    );
                    break;
                }
                default:
                //ignore anything else for now
            }
        }
    } catch (err) {
        log.error(err);
    }
}
