import lodash from 'lodash';
const { isEqual } = lodash;
const { unset } = lodash;
import logging from '@sstdev/lib_logging';
import verifyMetaKeysPresent from './_verifyMetaKeysPresent';
import { getPathToProperty } from '../../metadata';

// called from Insert, Update and Upsert
// Upsert ALWAYS clears properties on the original if the incoming item does not have them.
export default function execute(database) {
    /**
     * @param {string} namespace
     * @param {string} relation
     * @param {object} item
     * @param {Array<string> | undefined} propertiesToUnset Array of properties to unset, or undefined if any properties not set on item should always just be cleared.
     */
    return (namespace, relation, item, propertiesToUnset) => {
        let noop;
        let upsertedItem;
        let isNew = false;
        if (!item || item._id === undefined || item._id === null) {
            throw new Error('When updating or creating a document, a document with an _id must be provided.');
        }

        let collection = database.relationDb(namespace, relation);
        verifyMetaKeysPresent(['modifiedBy', 'modifiedTime'], item.meta, 'upsert');
        return collection
            .upsert(item._id, existingItem => {
                if (Array.isArray(existingItem)) {
                    if (existingItem.length && existingItem.length > 2) {
                        throw new Error(
                            `There was an error.  Upsert resulted in ${existingItem.length} items being updated`
                        );
                    }
                    existingItem = existingItem.length ? existingItem[0] : undefined;
                }
                // Do a comparison to avoid unnecessary writes.
                if (isUnchanged(existingItem, item)) {
                    noop = true;
                    return false; // This results in a noop (for optimization).
                }
                // Check if existingItem is empty (which means it does not exist already)
                isNew = !existingItem || Object.getOwnPropertyNames(existingItem).length === 0;

                // upsert from sockets doesn't get propertiesToUnset,
                // even if properties got unset.
                // but in that case we simply want whatever we got anyway.
                if (propertiesToUnset) {
                    upsertedItem = {
                        ...existingItem,
                        ...item
                    };

                    propertiesToUnset.forEach(p => {
                        const key = getPathToProperty(p);
                        unset(upsertedItem, key);
                    });
                } else {
                    //so, just overwrite the existing record out right.
                    //(but we do want the loki specific fields)
                    upsertedItem = {
                        ...item,
                        ...extractLokiData(existingItem)
                    };
                }

                return upsertedItem;
            })
            .then(result => {
                if (noop) return; // Do nothing to state
                if (!result.updated) {
                    logging.error('Error creating record: ' + JSON.stringify(result));
                }
                if (upsertedItem === undefined) {
                    throw new Error('There was an error.  Upserted item is undefined.');
                }
                return {
                    isNew,
                    upsertedItem
                };
            });
    };
}

// Check to see if any data has changed (other than loki metadata)
function isUnchanged(v1, v2) {
    if (typeof v1 === 'undefined') return false;
    let v1NoMeta = removeMetaData(v1);
    let v2NoMeta = removeMetaData(v2);
    return isEqual(v1NoMeta, v2NoMeta);
}

// TODO:  See httpCommunication.removeMetaData - this code is starting to duplicate.
// Need to find a more centralized solution.
function removeMetaData(data) {
    let cleanData = { ...data };
    // Workaround for sockets sending patches
    if (data.patches) delete cleanData.patches;

    if (data.$loki) delete cleanData.$loki;
    if (data._idx) delete cleanData._idx;
    if (data.meta) delete cleanData.meta;
    return cleanData;
}

function extractLokiData(data) {
    if (typeof data === 'undefined') return {};
    const { $loki, _idx } = data;
    return { $loki, _idx };
}
