import { useContext } from 'react';
import { SharedFilterBoundaryContext } from '../../components/contextProviders/SharedFilterBoundary';

import { hooks } from 'lib_ui-primitives';
const { useRouter } = hooks;
/**
 * Figures out the correct name for the dbView (i.e. lokijs DynamicView) given
 * several factors:
 * Prefers an override name if that is passed in.
 * Next tries to create a name based on the shared filter boundary context if that exists.
 * Lastly gives a name based on the current route path.
 * @param {string} namespace - namespace of collection
 * @param {string} relation - relation of collection
 * @param {string} overrideName - forces the db view name to this instead
 * @returns {string} - the name of the dbView
 */

export default function useGetDbViewName(namespace, relation, overrideName) {
    const {
        location: { pathname: path }
    } = useRouter();
    const sharedFilterBoundaryContext = useContext(SharedFilterBoundaryContext);
    return (
        overrideName ??
        (sharedFilterBoundaryContext != null
            ? sharedFilterBoundaryContext.getViewName(namespace, relation)
            : `${namespace}_${relation}_${path}`)
    );
}
