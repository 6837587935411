import react from 'react';
import { NavigationSelectionContext } from '../components/contextProviders/NavigationSelectionContext';
const { useContext } = react;

/**
 * @typedef { object } NavigationSelectionContextType
 * @property { boolean } available,
 * @property { boolean } loading
 * @property { string } namespace
 * @property { string } relation
 * @property { string } [_id]
 * @property { object } [record]
 * @property { string } record._id
 */
/**
 *
 * @returns {NavigationSelectionContextType}
 */
export default function useNavigationSelection() {
    return useContext(NavigationSelectionContext);
}
