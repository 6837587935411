import { styled, fromTheme, View, Text } from 'lib_ui-primitives';
import { nativeOnlyProperties } from 'lib_ui-services';
import SensorReadList from '../../list/SensorReadList';

export const MultiPickerTransaction = styled(View).attrs({ name: 'multi-picker-transaction-grid' })`
    padding-top: ${fromTheme('viewPadding')};
    background-color: ${fromTheme('backgroundColorLighter')};
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
`;

export const SpreadLeftToRight = styled(View)`
    flex-direction: row;
`;

export const Total = styled(View)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: ${fromTheme('viewPadding')};
    flex-shrink: 0;
`;

export const TotalLabel = styled(Text).attrs(
    nativeOnlyProperties({
        numberOfLines: 1
    })
)`
    color: ${fromTheme('colorScheme', 'primary')};
    font-size: ${fromTheme('fontSizeSmall')};
`;

export const TotalNumber = styled(Text)`
    color: ${fromTheme('colorScheme', 'primary')};
    font-size: ${fromTheme('fontSizeLarge')};
`;

export const SelectedRecordList = styled(SensorReadList)`
    min-height: 300px;
`;
