import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { View, styled } from 'lib_ui-primitives';

const _GreedyColumnLayout = styled(View).attrs({ name: 'greedy-column-layout' })`
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex-grow: 1;
`;
/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function GreedyColumnLayout(props) {
    if (props == null) return null;
    const { id, title: name, children, ...otherProps } = props;
    return rc(_GreedyColumnLayout, { name, id, ...otherProps }, children);
}

GreedyColumnLayout.defaultProps = {};

GreedyColumnLayout.propTypes = {
    hNode: PropTypes.object.isRequired,
    currentRoute: PropTypes.string.isRequired,
    id: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
};

export default GreedyColumnLayout;
