import { database } from 'lib_ui-services';

export const _private = { database };

export default {
    verb: 'didNavigate',
    namespace: 'application',
    relation: 'route',
    description: 'Warn user if visiting Take page without open inventory',
    priority: 10,
    routePath: [
        // Asset Tracking
        '/g/0/Inventory/Take',
        '/g/1/Inventory/Take',
        // FLAIR
        '/g/0/Physical Inventory/Take',
        '/g/1/Physical Inventory/Take'
    ],
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @template T
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 */
async function logic({ dispatch, context, log }) {
    //on mobile view, with FLAIR, the user is immediately redirected to the take page
    //the sync might not have completed yet, meaning we don't _know_ if there are inventories or not
    //so, first make sure we have received
    await _private.database.isDbReady();
    const db = _private.database.get();
    log.debug('[NAVIGATION] WAITING FOR SYNC TO FINISH');
    await db.synchronization.initialSyncFinished;
    log.debug('[NAVIGATION] CHECKING OPEN INVENTORIES');
    const response = await dispatch(
        {
            query: {
                'inventory:status.title': 'Active'
            }
        },
        {
            verb: 'get',
            namespace: 'inventory',
            relation: 'inventory',
            type: 'find'
        },
        true
    );
    log.debug(`[NAVIGATION]${response?.result?.length ? ' NOT' : ''} REDIRECTING`);
    if (!response?.result?.length) {
        const { routePath } = context;
        // Inform user that they have to create inventory first and redirect to landing page
        dispatch(
            {
                title: 'No Active Inventory Found',
                message: 'Please open an inventory first.',
                okButtonText: 'OK',
                noCancelOption: true,
                okAction: () => {
                    // Will fail over to index if inaccessible
                    //redirect to /Inventory/Manage
                    dispatch(
                        {
                            to: routePath.replace('/Take', '/Manage')
                        },
                        { verb: 'redirect', namespace: 'application', relation: 'route' }
                    );
                }
            },
            { verb: 'confirm', namespace: 'application', relation: 'user' }
        );
    }
}
