import logging from '@sstdev/lib_logging';
import { errors as _errors } from 'lib_ui-primitives';
import { constants, metadata } from 'lib_ui-services';

import basicValidation from '../../namespace/relation/doingValidate_namespace_relation';
const { getPrettyRelationName } = metadata;

const _p = {
    getPrettyRelationName
};

export const _private = _p;

export default {
    verb: 'doingValidate',
    namespace: 'item',
    relation: 'item',
    description: 'Require Building and Room on the Take Page',
    priority: (basicValidation.priority || 0) + 5, //we want this to run BEFORE basic validation
    useCaseIds: [constants.useCaseIds.ASSET_TRACKING, constants.useCaseIds.AMERICAN_WATER_ASSET_TRACKING],
    routePath: [
        // Asset Tracking
        '/g/0/Inventory/Take',
        '/g/1/Inventory/Take'
    ],
    logic: doingValidate
};
/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
function doingValidate({ data, context }) {
    const { namespace, relation } = context;
    let fieldErrors = {};

    if (!data?.newRecord?.['location:location'] || !data?.newRecord?.['location:location']?._id) {
        fieldErrors['location:location'] = 'Room is required';
    }
    if (!data?.newRecord?.['location:building'] || !data?.newRecord?.['location:building']?._id) {
        fieldErrors['location:building'] = 'Building is required';
    }
    if (!data?.newRecord?.['location:company'] || !data?.newRecord?.['location:company']?._id) {
        fieldErrors['location:company'] = 'Company is required';
    }

    if (fieldErrors && Object.keys(fieldErrors).length > 0) {
        let message = `There are problems with this ${_p.getPrettyRelationName(
            namespace,
            relation
        )}.  For details, see the red message(s) above.`;
        // Help debug error rendering problems by logging the field/error
        // combinations
        logging.error(JSON.stringify(fieldErrors));
        throw new _errors.ValidationError(message, fieldErrors);
    }
    return data;
}
