import JsBarcode from 'jsbarcode';
import { register } from '../ZNode';
import lodash from 'lodash';
const { get } = lodash;

const BARCODE_WITHOUT_TEXT_HEIGHT = 35;

async function BarcodeContent(props) {
    const {
        record,
        output,
        zNode: { propertyName },
        width,
        top
    } = props || { zNode: {} };
    const value = get(record, propertyName, '').replaceAll('~', '');

    if (value) {
        // barcodes don't have an `alignment = "center"` option.
        // Instead, they are always drawn with the top left corner on the cursor.
        // Approximate where the top left corner should be to center the barcode:
        const x = (width - getBarcodeLength(value)) / 2;
        return output.moveTo(x, top).addBarcode(value, { barcodeHeight: BARCODE_WITHOUT_TEXT_HEIGHT });
    }

    return output;
}

BarcodeContent.height = () => BARCODE_WITHOUT_TEXT_HEIGHT;

export function getBarcodeLength(value, barWidth = 2) {
    if (!value) return 0;
    //Ideal Case, Subset C (numeric only, even length)
    const data = {};
    JsBarcode(data, value, { format: 'CODE128', height: 16, width: barWidth, fontSize: 10, textMargin: 1 });
    return data.encodings[0].data.length * barWidth;
    // console.log(data);
    // //worst case, Subset B:
    // //code 128 B uses 0.526" for the first 2 characters, and 0.108 for each character thereafter
    // return Math.round((0.526 + (value.length - 2) * 0.108) * dpi);
}

register('BarcodeContent', BarcodeContent);
export default BarcodeContent;
