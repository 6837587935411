import { createElement as rc, useEffect } from 'react';
import PropTypes from 'prop-types';
import logging from '@sstdev/lib_logging';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import addMinutes from 'date-fns/addMinutes';

import { styled, hooks } from 'lib_ui-primitives';
import { ObjectId, constants } from 'lib_ui-services';

import useEventSink from '../../hooks/useEventSink';
import useNavigationSelection from '../../hooks/useNavigationSelection';
import RightAlignLayout from '../layout/RightAlignLayout';
import HNode from '../../HNode';
import useUserContext from '../../hooks/useUserContext';

const { useBbState } = hooks;

const { SCOPE, VISIBILITY, DURATION } = constants.retention;

const searchElementRetention = {
    scope: SCOPE.PER_ROUTE,
    duration: DURATION.SESSION,
    visibility: VISIBILITY.PER_BROWSER
};

const ButtonBar = styled(RightAlignLayout)`
    flex-shrink: 0;
    flex-grow: 0;
`;

const _p = {
    useNavigationSelection,
    useUserContext,
    useBbState
};
export const _private = _p;
/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */

function SearchPaneHeader(props) {
    const {
        hNode: { id, namespace, relation, children = [] },
        searchElementIds = [],
        onLoad,
        resetFilter,
        currentRoute
    } = props || { hNode: {} };

    const [subscribe, publish, request] = useEventSink();
    const currentUser = _p.useUserContext().briefUserReference;
    const {
        available,
        namespace: navNamespace,
        relation: navRelation,
        _id: navId,
        record: navRecord
    } = _p.useNavigationSelection();

    useEffect(() => {
        let allowStateChange = true;
        const displayPopup = async function displayPopup(isShared) {
            logging.info(`Persisting ${isShared ? 'shared ' : ''}saved filter`);
            let newRecord = {
                _id: ObjectId(),
                filters: await dehydrate(searchElementIds, currentRoute),
                namespace,
                relation,
                routePath: currentRoute,
                isShared
            };
            if (!isShared) {
                newRecord['identity:user'] = currentUser;
            }
            if (available) {
                newRecord[`${navNamespace}:${navRelation}`] = {
                    _id: navId,
                    title: navRecord?.title
                };
            }

            if (!allowStateChange) return;
            publish(
                {
                    activationVerb: 'new',
                    namespace: 'application',
                    relation: 'savedFilter',
                    isNew: true,
                    record: newRecord
                },
                { verb: 'new', namespace: 'application', relation: 'savedFilter', status: 'success', isNew: true }
            );
        };
        const unsubscribes = [
            subscribe({ verb: 'save', namespace, relation, type: 'filter' }, () => displayPopup(false)),
            subscribe({ verb: 'save', namespace, relation, type: 'sharedFilter' }, () => displayPopup(true))
        ];

        return () => {
            allowStateChange = false;
            return unsubscribes.map(u => u());
        };
    }, [
        subscribe,
        publish,
        namespace,
        relation,
        currentRoute,
        searchElementIds,
        currentUser,
        available,
        navNamespace,
        navRelation,
        navId,
        navRecord?.title
    ]);

    useEffect(() => {
        let allowStateChange = true;
        const loadFilter = async function loadFilter(payload, context, isShared) {
            logging.info(`Loading ${isShared ? 'shared' : 'saved'} filter ${payload._id}`);
            const {
                result: [filterState]
            } = await request(payload, { ...context, verb: 'get', status: undefined, type: 'get' });

            if (!filterState) {
                logging.warn(`Failed to load ${isShared ? 'shared' : 'saved'} filter ${payload._id}`);
            } else {
                logging.info(`Found ${isShared ? 'shared' : 'saved'} filter ${filterState.title}`);
            }

            //technically, we probably don't even need to check for this
            //as all we do is stuff things in localStorage, but regardless:
            if (!allowStateChange) return;
            //reset to a clean slate:
            resetFilter();
            await hydrate(filterState.filters, searchElementIds, currentRoute);
            onLoad();
        };

        const unsubscribes = [
            subscribe({ verb: 'setFilter', namespace: 'application', relation: 'savedFilter' }, loadFilter),
            subscribe({ verb: 'setFilter', namespace: 'application', relation: 'sharedFilter' }, loadFilter)
        ];

        return () => {
            allowStateChange = false;
            return unsubscribes.map(u => u());
        };
    }, [currentRoute, namespace, onLoad, relation, request, searchElementIds, subscribe, resetFilter]);

    return rc(
        ButtonBar,
        { id: `${id}-buttons` },
        children.map((hNode, index) => rc(HNode, { hNode, key: index, currentRoute }))
    );
}

async function dehydrate(searchElementIds, currentRouteStateKey) {
    let result = {};
    for (const stateName of searchElementIds) {
        let state;
        if (stateName.startsWith('from-') || stateName.startsWith('to-')) {
            state = await _p.useBbState.getDirect(stateName, searchElementRetention, currentRouteStateKey);
            if (state) {
                state = dateToOffset(state);
            }
        } else {
            state = await _p.useBbState.getDirect(stateName, searchElementRetention, currentRouteStateKey);
        }
        if (state) {
            result[stateName] = state;
        }
    }
    return result;
}

async function hydrate(newState, searchElementIds, currentRouteStateKey) {
    for (const stateName of searchElementIds) {
        let value = newState[stateName];
        if (stateName.startsWith('from-') || stateName.startsWith('to-')) {
            if (value) {
                value = offsetToDate(value);
            }
            await _p.useBbState.setDirect(value, stateName, searchElementRetention, currentRouteStateKey);
        } else {
            await _p.useBbState.setDirect(value, stateName, searchElementRetention, currentRouteStateKey);
        }
    }
}

function dateToOffset(date) {
    const offset = differenceInMinutes(new Date(date), new Date());
    return offset;
}
function offsetToDate(offset) {
    const newDate = addMinutes(new Date(), offset);
    return newDate.toISOString();
}

SearchPaneHeader.propTypes = {
    hNode: PropTypes.object.isRequired,
    searchElementIds: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    currentRoute: PropTypes.string.isRequired
};
export default SearchPaneHeader;
