import notHandledHere from './notHandledHere';

export default {
    verb: 'doingCancel',
    description: 'Cancel an existing form operation (e.g. edit or new).',
    excludedNamespaceRelations: notHandledHere,
    //this is the actual logic:
    logic: cancel
};

// This will remove the record portion of the ActiveRecord context (see ActiveRecord.js).
// Changing the context will allow observing UI elements to react accordingly.
function cancel({ context }) {
    const { namespace, relation, type } = context;
    return {
        namespace,
        relation,
        type
    };
}
