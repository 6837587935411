import { database } from 'lib_ui-services';

const _p = { database };
export const _private = _p;

export default {
    verb: 'willNew',
    description: 'Clean up any previous resets, and set a title',
    namespace: 'application',
    relation: 'reset',
    //this is the actual logic:
    logic: willNew
};

async function willNew({ data, context }) {
    //this _should_ be a `PURGE` prerequisite call or something, as these are now CRUD operations in a FORM rule
    //but anything in the `application` namespace requires special rules for everything
    //so: shortcut, and just call database directly
    const db = _p.database.get();
    await db.clear({}, context);
    return {
        ...data,
        newRecord: {
            title: new Date(),
            ...data.newRecord
        }
    };
}
