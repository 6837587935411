import { createElement as rc, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '../styled';
import fromTheme from '../fromTheme';
import lodash from 'lodash';
const { omit } = lodash;

const UnstyledCrossBrowserSlider = styled.input.attrs({ type: 'range', name: 'slider' })`
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    appearance: none;
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */

    &:focus {
        outline: none; /* Removes the blue border when active */
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
    }
    /* No need to unset anything for Firefox (-moz) */
`;

// Webkit cannot style progress so we fake it with a long shadow leading up to the thumb element
const makeLongShadow = (color, thumbHeight, trackHeight) => {
    const size = (thumbHeight - trackHeight) / 2; //the amount of pixels the thumb sticks out from the track

    let shadow = '0 0 3px #000000'; //make the thumb seem ON TOP of the slider
    // This thing makes a ridiculously long style which clogs up the unit testing output
    // eslint-disable-next-line no-undef
    if (!__UNIT_TESTING__) {
        for (let i = 0; i <= 700; i++) {
            //Add "shadow" dots from the thumb to the left by using an ever bigger (negative) offset
            //As the thumb is rounded, so is the shadow. Which means we can't move the dot more than 1 px each time
            //we need a negative size for the spread to limit the shadow to _just_ the track
            //and we don't want it to stick out on the right, so use the shadow's width as offset to the left to hide the first shadow fully under the thumb
            shadow = `${shadow}, ${-thumbHeight / 2 - i}px 0 0 -${size - 1}px ${color}`;
        }
    }
    return shadow;
};

const ThumbOnlySlider = styled(UnstyledCrossBrowserSlider)`
    /* Special styling for WebKit (Chrome, Opera, Safari, Edge) */
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 23px;
        width: 23px;
        border-radius: 50%;
        background: ${({ disabled, theme }) => (disabled ? theme.slider.disabled : theme.slider.selected)};
        cursor: pointer;
        margin-top: -10px; /* to vertically center the thumb on the track */
        /* Hack to hide the beginning of the track with the "selected" color */
        box-shadow: ${({ disabled, theme }) =>
            makeLongShadow(disabled ? theme.slider.disabled : theme.slider.selected, 23, 3)};
    }
    &:hover::-webkit-slider-thumb {
        background: ${({ disabled, theme }) => (disabled ? theme.slider.disabled : theme.slider.hover)};
        box-shadow: ${({ disabled, theme }) =>
            makeLongShadow(disabled ? theme.slider.disabled : theme.slider.hover, 23, 3)};
    }

    /* All the same stuff for Firefox, except firefox has support for progress track */
    &::-moz-range-thumb {
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        height: 23px;
        width: 23px;
        border-radius: 50%;
        background: ${({ disabled, theme }) => (disabled ? theme.slider.disabled : theme.slider.selected)};
        cursor: pointer;
    }
    &::-moz-range-progress {
        background: ${({ disabled, theme }) => (disabled ? theme.slider.disabled : theme.slider.selected)};
    }
    &:hover::-moz-range-thumb {
        background: ${({ disabled, theme }) => (disabled ? theme.slider.disabled : theme.slider.hover)};
    }
    &:hover::-moz-range-progress {
        background: ${({ disabled, theme }) => (disabled ? theme.slider.disabled : theme.slider.hover)};
    }
`;

const SliderWithTrackAndThumb = styled(ThumbOnlySlider)`
    overflow: hidden;
    max-width: 700px;
    height: 25px;
    margin-left: 6px;
    margin-right: 6px;

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        box-shadow: 1px 1px 1px ${fromTheme('slider', 'backgroundColor')};
        background: ${fromTheme('slider', 'backgroundColor')};
    }

    &:hover::-webkit-slider-runnable-track {
        background: ${fromTheme('slider', 'backgroundColor')};
    }

    &::-moz-range-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        box-shadow: 1px 1px 1px ${fromTheme('slider', 'backgroundColor')};
        background: ${fromTheme('slider', 'backgroundColor')};
    }
`;

function Slider(props, ref) {
    const onChangeCallback = props.onChange;
    const onChange = useCallback(e => onChangeCallback(parseInt(e.target.value)), [onChangeCallback]);
    return rc(SliderWithTrackAndThumb, {
        role: 'slider',
        min: 0,
        max: 100,
        step: 1,
        ...omit(props, ['children']),
        onChange,
        ref
    });
}

Slider.PropTypes = {
    disabled: PropTypes.boolean,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number
};

export default forwardRef(Slider);
