import { createElement as rc } from 'react';
import useDbView from '../../hooks/useDbView';
import { metadata, nativeOnlyProperties } from 'lib_ui-services';
import { Text, View, styled, fromTheme } from 'lib_ui-primitives';
import capitalize from 'lodash/capitalize';
const Label = styled(Text).attrs({ name: 'label' })`
    color: ${fromTheme('dataAggregation', 'fontColor')};
    font-size: ${({ centerLabelAbove, theme }) => (centerLabelAbove ? theme.fontSizeLabel : theme.fontSize)}px;
`;
Label.displayName = 'Label';

const AggregateText = styled(Text).attrs({ name: 'aggregate-text' })`
    color: ${fromTheme('dataAggregation', 'fontColor')};
    font-size: ${({ centerLabelAbove, theme }) => (centerLabelAbove ? theme.fontSizeLarge : theme.fontSize)}px;
`;
AggregateText.displayName = 'AggregateText';

const VerticalAggregateContainer = styled(View).attrs({ name: 'vertical-aggregate-container' })`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    margin-left: 6px;
    margin-top: 6px;
    overflow: visible;
`;
VerticalAggregateContainer.displayName = 'VerticalAggregateContainer';

const HorizontalAggregateContainer = styled(View).attrs({ name: 'horizontal-aggregate-container' })`
    align-items: center;
    flex-direction: row;
    margin-right: 6px;
    margin-left: 6px;
`;
HorizontalAggregateContainer.displayName = 'HorizontalAggregateContainer';

const _p = { useDbView, getRelationMetadata: metadata.getRelationMetadata };
export const _private = _p;
export default function DataAggregation(props) {
    const {
        hNode,
        hNode: { namespace, relation, aggregateOperation, title, centerLabelAbove }
    } = props;
    if (aggregateOperation !== 'count') {
        throw new Error(
            `Aggregation operation of ${aggregateOperation} has not implemented yet. See dataView.DataAggregation.js`
        );
    }
    const relationMetadata = _p.getRelationMetadata(namespace, relation);
    if (relationMetadata.limitSyncSize) {
        throw new Error(
            `Aggregation operation of ${aggregateOperation} is not supported on ${namespace}.${relation} because it is too large to fit on the client (i.e. limitSyncSize is true for the relation).`
        );
    }
    const { recordCount } = _p.useDbView(namespace, relation, undefined, hNode);

    // prettier-ignore
    return rc(centerLabelAbove ? VerticalAggregateContainer : HorizontalAggregateContainer, { id: props.hNode.id },
        rc(Label, {centerLabelAbove, ...nativeOnlyProperties({ellipsizeMode: 'clip', numberOfLines: 1})}, ((title + '') === '') ? `${capitalize(aggregateOperation)}:`: title),
        rc(AggregateText, null, recordCount.toString())
    );
}
