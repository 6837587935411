import defaultChange from '../../FORMS/namespace/relation/doingChange_namespace_relation';

export default {
    verb: 'doingChange',
    description: 'Merge changes into the given namespace/relation record.',
    namespace: 'security',
    relation: 'profile',
    //this is the actual logic:
    logic: defaultChange.logic
};
