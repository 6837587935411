import { createElement as rc, useContext, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useFormControl from '../../hooks/useFormControl';
import { View, Slider as _Slider, styled, fromTheme, GestureContext, Text } from 'lib_ui-primitives';
import FormField from './FormField';

// Always false so that label floats permanently (instead of overlapping the control).
const fieldEmpty = false;

const ValueText = styled(Text).attrs({ name: 'value-text' })`
    font-size: ${fromTheme('fontSize')};
    color: ${fromTheme('slider', 'selected')};
    margin: ${fromTheme('viewMargin')};
    min-width: ${({ theme }) => theme.fontSize * 3}px;
`;
ValueText.displayName = 'ValueText';

const SliderWithMargin = styled(_Slider)`
    margin-left: ${fromTheme('viewMarginMore')};
    margin-right: ${fromTheme('viewMargin')};
    padding-right: ${fromTheme('viewPadding')};
    flex-grow: 1;
`;

const SliderContainer = styled(View)`
    padding-left: ${fromTheme('textPadding')};
    padding-top: ${fromTheme('textPadding')};
    align-items: center;
    flex-grow: 1;
`;

const Slider = props => {
    const {
        hNode,
        hNode: { id, propertyName, min = 0, max = 100, step = 1, tooltip, defaultValue = min }
    } = props;
    const [active, setActive] = useState(false);
    const { title, value, setValue, disabled, errors } = useFormControl(
        { ...props, hNode: { defaultValue, ...hNode } },
        defaultValue
    );

    const ref = useRef();
    const { suspendLeftSwipe, suspendRightSwipe } = useContext(GestureContext);
    useLayoutEffect(() => {
        const releases = [];
        if (!ref.current) {
            throw new Error('No reference for the scrolling element is defined.');
        }
        releases.push(suspendLeftSwipe(() => ref.current.measureRect()));
        releases.push(suspendRightSwipe(() => ref.current.measureRect()));

        return () => releases.forEach(release => release());
    }, [suspendRightSwipe, suspendLeftSwipe]);

    const onFocus = () => setActive(true);
    const onBlur = () => setActive(false);

    // prettier-ignore
    return rc(FormField, { id, title, tooltip, errors, active, fieldEmpty},
        rc(SliderContainer, {ref},
            rc(ValueText, null, value.toString()),
            rc(SliderWithMargin, {id, value, min, max, step, onChange:setValue, disabled, name: propertyName, onFocus, onBlur}),
        )
    );
};

Slider.propTypes = {
    hNode: PropTypes.object.isRequired
};

export default Slider;
