export default {
    verb: 'willCreate',
    namespace: 'metadata',
    //any relation in this namespace
    excludedNamespaceRelations: [{ namespace: 'metadata', relation: 'useCaseDetail' }],
    description: 'Assure splash reference is on the newRecord',
    //type: '',
    priority: 100, // before anything else
    //this is the actual logic:
    logic: willCreate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willCreate({ data }) {
    const { newRecord, splashContext = {} } = data;
    if (newRecord['metadata:useCaseDetail']) {
        return;
    }
    const { namespace, relation, record, hasSplashed } = splashContext || {};
    if (!hasSplashed || !record) {
        throw new Error('Missing Use Case Version from Splash Context');
    }
    const { _id, title } = record;
    newRecord[`${namespace}:${relation}`] = { _id, title };

    return data;
}
