import { constants } from 'lib_ui-services';
import lodash from 'lodash';
const { cloneDeep } = lodash;
const { isEqual } = lodash;

export default {
    verb: 'didUpdate',
    namespace: 'metadata',
    relation: 'page',
    description: 'Keep Navigation record in sync with page',
    //type: '',
    //priority:0,
    //useCaseIds:[]
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'metadata',
                relation: 'navigation',
                type: 'find'
            },
            query: ({ data }) => {
                const {
                    'metadata:useCaseDetail': { _id }
                } = data.newRecord;
                return {
                    'metadata:useCaseDetail._id': _id,
                    'meta.deleted': { $exists: false }
                };
            }
        }
    ],
    //this is the actual logic:
    logic: didUpdate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function didUpdate({
    data,
    prerequisiteResults: [
        {
            result: [navigation]
        }
    ],
    dispatch
}) {
    const topBlock = data.newRecord.blockly.blocks.blocks.find(b => constants.acceptableTopBlocks.includes(b.type));
    const securityModifiers = secModsToArray(topBlock.inputs?.securityModifier);
    const secModPropObject = blocklySecModToNavSecMod(securityModifiers);

    const originalNavigation = cloneDeep(navigation);
    const navEntryRef = findRecursively(navigation.children, data.newRecord._id);
    const navEntryCopy = cloneDeep(navEntryRef);

    //only keep the basic props
    Object.keys(navEntryRef).forEach(key => {
        if (['_id', 'children'].includes(key)) return;
        //we have to delete the unwanted keys off, as we have a reference,
        //and we can't just spread the object and reassign a new object.
        delete navEntryRef[key];
    });
    if (topBlock.type === 'menu_TabbedPage') {
        navEntryRef.isTabContainer = true;
    }
    if (topBlock.type === 'page_NavigationLink') {
        navEntryRef.isNavigationLink = true;
    }
    if (topBlock.type === 'page_NavigationSelectedContextPage') {
        navEntryRef.isMenuList = true;
    }

    //now force the new Security Modifiers on it
    Object.entries(secModPropObject).forEach(([key, value]) => {
        navEntryRef[key] = value;
    });

    navEntryRef.title = topBlock.fields.title;

    if (!isEqual(navEntryCopy, navEntryRef)) {
        await dispatch(
            { oldRecord: originalNavigation, newRecord: navigation },
            {
                verb: 'update',
                namespace: 'metadata',
                relation: 'navigation'
            }
        );
    }
}

function findRecursively(children, _id) {
    if (!children || !children.length) return;
    for (const child of children) {
        if (child._id === _id) return child;
        const grandChild = findRecursively(child.children, _id);
        if (grandChild) return grandChild;
    }
}

function secModsToArray({ block } = {}) {
    if (!block) return [];
    const { inputs: { securityModifier } = {}, ...secMod } = block;
    return [secMod, ...secModsToArray(securityModifier)];
}

function blocklySecModToNavSecMod(secModArray) {
    return secModArray.reduce((agg, secMod) => {
        if (!secMod.fields) return agg;
        const property = typeToProperty(secMod.type);
        if (agg[property]) {
            agg[property].push(secMod.fields);
        } else {
            agg[property] = [secMod.fields];
        }
        return agg;
    }, {});
}

function typeToProperty(value) {
    if (value.startsWith('accessModifier_')) {
        {
            //spread the resulting string into a charArray
            const [first = '', ...rest] = value.replace('accessModifier_', '');
            return first.toLowerCase() + rest.join('');
        }
    }
    return value;
}
