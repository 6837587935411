import { useRef, useEffect, useCallback } from 'react';

/**
 * Creates a timeout (i.e. "delayed function"). On unmount, it clears
 * any pending timeouts to avoid unmounted state updates.
 *
 * @param {function} func - the callback to run after the timeout
 * @param {array} dependencies - your timeout function will be recreated if these change
 * @param {*} [period=0] - the period to wait before timing out
 * @example
 *  const [inputIsDirty, setInputIsDirty] = useState(false);
 *  // Will set inputIsDirty to true after current stack completes
 *  const delayedSetInputIsDirty0 = useTimeout(setInputIsDirty);
 *  delayedSetInputIsDirty0(true);
 *  // Will set inputIsDirty to true after 50ms
 *  const delayedSetInputIsDirty50 = useTimeout(setInputIsDirty, [], 50);
 *  delayedSetInputIsDirty50(true);
 */
export default function useTimeout(func, dependencies = [], period = 0) {
    const timeouts = useRef([]);
    useEffect(
        () => () => {
            timeouts.current.forEach(t => {
                clearTimeout(t);
            });
        },
        []
    );

    return useCallback((...args) => {
        return new Promise((resolve, reject) => {
            try {
                // If the delayed function itself (not useTimeout) is called
                // multiple times, then this keeps an array of the corresponding
                // timeouts, so they are separate (and so they can all be cleared
                // when the parent component unmounts)
                timeouts.current.push(
                    setTimeout(() => {
                        resolve(func(...args));
                    }, period)
                );
            } catch (err) {
                reject(err);
            }
        });
        // Dependencies are verified by eslint on the consuming code instead of here.
        // eslint-disable-next-line
    }, dependencies);
}
