import lokijs from 'lokijs';
import IndexedAdapter from 'lokijs/src/loki-indexed-adapter';
const _idbAdapter = new IndexedAdapter('appAdapter');

/** @type {"NODEJS" | "BROWSER" | "NATIVESCRIPT" | "CORDOVA" | "NA"} */
export const env = 'BROWSER';
export const idbAdapter = _idbAdapter;

// adapter = new lokijs.LokiPartitioningAdapter(_idbAdapter, { paging: true, pageSize:10*1024*1024 });
/*  IF WE NEED A SMALLER PAGING SIZE (than 25 meg) THIS IS HOW */
const adapter = new lokijs.LokiPartitioningAdapter(_idbAdapter, {
    paging: true,
    // 3 meg page size (3145728)
    pageSize: 3 * 1024 * 1024
});

export default adapter;
