import genericRule from '../../FORMS/namespace/relation/doingChange_namespace_relation';

export default {
    verb: 'doingChange',
    namespace: 'sensor',
    relation: 'tag',
    description: genericRule.description,
    //this is the actual logic:
    logic: genericRule.logic
};
