import notHandledHere from './notHandledHere';

export default {
    verb: 'defaultDidGet',
    description: 'Default pass-through to assure exports work',
    excludedNamespaceRelations: notHandledHere,
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data }) {
    // We need at least 1 rule in the rules engine to assure it triggers a "success"
    // we need a "success" on didGet, as otherwise eventSink "request" doesn't resolve ever.
    // just having a rule which passes the data through assure's we always have get the success.
    // The alternative would be to implement an "unhandled" status in rulesEngine.io in case no rules were found
    // and update the eventSink to use that to just return the input on request.
    return data;
}
