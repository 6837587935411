export default {
    verb: 'didNavigate',
    namespace: 'application',
    relation: 'route',
    description: 'log route changes (only inside use cases, login etc are not logged!)',
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @template T
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 */
async function logic({ data, context, log, error }) {
    if (error) {
        log.error(error, context);
    } else {
        log.debug(
            `[NAVIGATION] Navigated to ${data.navigate?.router?.location?.pathname}${data.navigate?.router?.location?.search}`,
            context
        );
    }
}
