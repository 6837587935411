import { createElement as rc } from 'react';
export default function PlainLabel(props) {
    // Remove non-DOM stuff.
    /* eslint-disable no-unused-vars */
    const {
        floating,
        empty,
        testID,
        active,
        accessibilityLabel,
        accessibility = false,
        children,
        ...otherProps
    } = props;
    /* eslint-enable no-unused-vars */
    if (Array.isArray(children)) {
        if (children.some(child => typeof child !== 'string')) {
            throw new Error('A text field was passed something other than text.');
        }
    } else if (typeof children !== 'string') {
        throw new Error(
            `A text field was passed something other than text. Received ${typeof children}: ${JSON.stringify(
                children
            )}`
        );
    }
    return rc('label', { accessibility: accessibility.toString(), ...otherProps }, children);
}
