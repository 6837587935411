import { createElement as rc } from 'react';
import { TextInput, styled, fromTheme } from 'lib_ui-primitives';
import CriteriaElement from './CriteriaElement';
import PropTypes from 'prop-types';
import createTreePositionGetter from '../../utilities/createTreePositionGetter';
import { constants } from 'lib_ui-services';
const { criteriaOperations } = constants;

const supportedOperations = [
    criteriaOperations.EQUALS,
    criteriaOperations.NOT_EQUALS,
    criteriaOperations.CONTAINS,
    criteriaOperations.STARTS_WITH
];

const TextField = styled(TextInput)`
    max-width: ${fromTheme('form', 'entryMaxWidth')};
`;
export default function ShortTextCriteria(props) {
    const {
        hNode: { id, minLength = 0, maxLength = 256, propertyName, treePosition }
    } = props || { hNode: {} };

    const getTreePosition = createTreePositionGetter(treePosition, 2);
    return rc(
        CriteriaElement,
        { ...props, supportedOperations, defaultValue: '', treePosition: getTreePosition(0) },
        rc(TextField, {
            id: `innerValue${id}`,
            minLength,
            maxLength,
            name: propertyName,
            sequence: getTreePosition(1).sequence
        })
    );
}

ShortTextCriteria.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};
