import { useCallback, useRef } from 'react';
import logging from '@sstdev/lib_logging';

/**
 * Allow a multiple handlers to fire if a single change takes place.
 * This is helpful if you have two different components that need to
 * react to a single change from another component.
 * pass `listenForChange` to the onChange property of the changing component
 * pass `addChangeHandler` to the components that want to observe the change.
 * @returns [function, function]
 */
export default function useMultipleChangeHandlers() {
    const changeHandlers = useRef([]);
    const listenForChange = useCallback((...args) => {
        logging.debug(
            `[useMultipleChangeHandlers] listenForChange called with ${
                changeHandlers.current?.length ?? 0
            } change handlers.`
        );
        changeHandlers.current.forEach(handler => {
            handler(...args);
        });
    }, []);
    const addChangeHandler = useCallback(handler => {
        logging.debug(
            `[useMultipleChangeHandlers] addChangeHandler called with ${
                changeHandlers.current?.length ?? 0
            } change handlers.`
        );
        // Should be a noop if already added
        if (!changeHandlers.current.includes[handler]) {
            changeHandlers.current.push(handler);
            const handlers = changeHandlers.current;
            //unsubscribe
            return () => handlers.splice(handlers.indexOf(handler), 1);
        }
    }, []);
    return [listenForChange, addChangeHandler];
}
