/**
 * Update many CRUD
 * @param {object} action persist action dispatched to update a document
 */
export default database => (payload, context) => {
    const { namespace, relation } = context;
    const getResult = database.relationDb(namespace, relation).updateMany(payload.criteria, payload.operations);

    return getResult.then(() => database.processSuccess([], payload, context));
};
