import { errors } from 'lib_ui-primitives';
import genericRule from '../../namespace/relation/doingValidate_namespace_relation';

export default {
    ...genericRule,
    excludedNamespaceRelations: [],
    namespace: 'identity',
    relation: 'role',
    priority: genericRule.priority + 10, // before the generic rule
    description: 'Prevent updating standard/system roles',
    logic: doingValidate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */

function doingValidate(message) {
    const { system, title } = message.data.newRecord;
    if (system) {
        const errorMessage = `Unable to change built-in role ${title}.`;
        throw new errors.ValidationError(errorMessage, {
            title: 'This role can not be modified.'
        });
    }
    return message;
}
