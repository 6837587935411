//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _doingValidate_identity_role from './doingValidate_identity_role';
import _get_identity_userRole from './get_identity_userRole';
import _willRemove_identity_role from './willRemove_identity_role';
export const doingValidate_identity_role = _doingValidate_identity_role;
export const get_identity_userRole = _get_identity_userRole;
export const willRemove_identity_role = _willRemove_identity_role;
export default {doingValidate_identity_role, get_identity_userRole, willRemove_identity_role};

