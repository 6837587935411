import { constants } from 'lib_ui-services';
export default {
    verb: 'didCreate',
    namespace: 'item',
    relation: 'transaction',
    description: 'Notify user transaction was saved',
    priority: 5,
    useCaseIds: [constants.useCaseIds.WISE_ID, constants.useCaseIds.FREIGHT_RUNNER],

    //this is the actual logic:
    logic: didCreate
};

function didCreate({ dispatch }) {
    dispatch(
        {
            message: 'Transaction Saved',
            timeout: 1000
        },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
}
