import lodash from 'lodash';
const { get } = lodash;
import patchGeneratingRule from '../../namespace/relation/willUpdate_namespace_relation';
import { constants } from 'lib_ui-services';

const { useCaseIds } = constants;

export default {
    verb: 'willUpdate',
    namespace: 'item',
    relation: 'item',
    prerequisites: [],
    description: 'Add additional `test` patches to stabilize (inventory) array mutations ',
    //run this AFTER the rule that generates the patches
    priority: patchGeneratingRule.priority - 5,
    useCaseIds: [useCaseIds.ASSET_TRACKING, useCaseIds.AMERICAN_WATER_ASSET_TRACKING, useCaseIds.FLAIR],
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data, context }) {
    const { newRecord, patch, offlineAction } = data;
    const { verb } = context;
    //NOTE: this whole function is probably generic if you base arrayProperty and keypath of the context namespace/relation
    const arrayProperty = 'inventory';
    const keyPath = '/inventory:inventory/_id';

    // To identify attributes like `/inventory/0` or `/inventory[0]' (or even without the leading /)
    const nestedArrayPathRegExp = new RegExp(`^\\/?${arrayProperty}[/[]\\d+]?`);
    const nestedArrayMutatingPatch = patch.find(p => p.op !== 'test' && nestedArrayPathRegExp.test(p.path));
    //if no modification of the inventory. Do nothing.
    if (!nestedArrayMutatingPatch) return;
    //match and take the 1's result (which is the matched string)
    const arrayEntryPath = nestedArrayMutatingPatch.path.match(nestedArrayPathRegExp)[0];

    //change /inventory[0] into /inventory/0, and add path to identifying foreign key id
    const idPath = `${arrayEntryPath.replace('[', '/').replace(']', '')}${keyPath}`;

    const arrayElementId = get(
        newRecord,
        idPath.split('/').filter(x => x)
    );

    //Create a test patch that assures we only update the array entry if the _id matches what we expect
    //i.e. that it hasn't been replaced with something else (another inventory).
    const arrayElementTestPatch = {
        op: 'test',
        path: idPath,
        value: arrayElementId
    };

    const newPatches = [arrayElementTestPatch, ...patch];
    offlineAction[verb].patch = newPatches;
    offlineAction.meta.offline.effect.patch = newPatches;
    offlineAction.meta.offline.commit[verb].patch = newPatches;
    offlineAction.meta.offline.rollback[verb].patch = newPatches;

    return { ...data, patch: newPatches, offlineAction };
}
