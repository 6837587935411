import lodash from 'lodash';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useTheme } from 'styled-components';
import getColumnWidth from './getColumnWidth';
import getColumnValue from './getColumnValue';

const { isEqual } = lodash;

const _p = { getColumnValue, getColumnWidth, calculateMaxColumnLength };

/**
 * Custom hook to calculate and manage column widths.
 * @param {Object} props
 * @param {Object} props.hNode
 * @param {Array} props.hNode.children - The children of the parent hNode.
 * @param {Array} records - The array of records to iterate through.
 * @returns {Object} An object containing columnWidths and handleColumnWidthChange function.
 */
export default function useColumnWidths(props, records) {
    const theme = useTheme();
    const [columnWidths, setColumnWidths] = useState([]);

    const hNodeColumns = useMemo(() => {
        return props.hNode.children.filter(c => c.hNodeTypeGroup === 'listColumn');
    }, [props.hNode.children]);

    const initialWidths = useMemo(() => {
        return hNodeColumns.map(hNode => ({
            ...hNode,
            width: hNode.width || _p.getColumnWidth(hNode, theme),
            defaultWidth: hNode.defaultWidth || _p.getColumnWidth(hNode, theme)
        }));
    }, [hNodeColumns, theme]);

    useEffect(() => {
        setColumnWidths(prevWidths => {
            if (isEqual(prevWidths, initialWidths)) {
                return prevWidths;
            }
            return initialWidths;
        });
    }, [initialWidths]);

    /**
     * Handles the change in column width.
     * @param {Object} column - The column object.
     * @param {string} column.id - The ID of the column.
     * @param {number} column.width - The current width of the column.
     * @param {number} column.defaultWidth - The default width of the column, based on the theme, font, label etc.
     */
    const handleColumnWidthChange = useCallback(
        column => {
            const columnContentLength = _p.calculateMaxColumnLength(records, column);
            const calculatedWidth = _p.getColumnWidth(column, theme, columnContentLength);

            // Ensure calculatedWidth is not less than defaultWidth
            if (calculatedWidth < column.defaultWidth) {
                return;
            }

            const newWidth = column.width === column.defaultWidth ? calculatedWidth : column.defaultWidth;

            setColumnWidths(prev =>
                prev.map(c =>
                    c.id === column.id
                        ? {
                              ...c,
                              width: newWidth,
                              defaultWidth: column.defaultWidth
                          }
                        : c
                )
            );
        },
        [records, theme]
    );

    return { columnWidths, handleColumnWidthChange };
}

/**
 * Calculates the longest value for a single columnHNode across all records.
 * @param {Array} records - The array of records to iterate through.
 * @param {Object} columnHNode - The columnHNode to check.
 * @param {Object} [options={}] - Additional options to pass to getColumnValue.
 * @returns {number} The length of the longest value.
 */
function calculateMaxColumnLength(records, columnHNode, options = {}) {
    return records.reduce((max, record) => {
        let value;
        try {
            // It's possible to run into an error if the columnHNode data type is not supported by getColumnValue
            // We can ignore this error and return an empty string, since the unsupported data types will not be displayed anyway
            value = _p.getColumnValue(record, columnHNode, options);
        } catch {
            value = '';
        }
        return Math.max(max, value?.length || 0);
    }, 0);
}

/**
 * Filters and returns the columnHNodes that are not hidden.
 * If columnHNodes are passed in, it uses those, otherwise it assumes all children are listColumns and filters them.
 * @param {Array} columnHNodes - The array of columnHNodes to filter.
 * @param {Array} children - The children of the parent hNode.
 * @returns {Array} The filtered array of columnHNodes.
 */
export const getColumnHNodes = (columnHNodes, children) => {
    return (columnHNodes || children.filter(c => c.hNodeTypeGroup === 'listColumn')).filter(c => !c.hidden);
};

/**
 * Returns the widths of the columnHNodes.
 * If the width is already set, it uses that, otherwise it calculates it based on the theme and data type.
 * @param {Array} columnHNodes - The array of columnHNodes.
 * @param {Object} theme - The theme object.
 * @returns {Array} The array of column widths.
 */
export const getColumnWidths = (columnHNodes, theme) => {
    return columnHNodes.map(hNode => hNode.width || getColumnWidth(hNode, theme));
};
