import { View, h5, fromTheme, styled, webOnlyStyles } from 'lib_ui-primitives';

export const ReverseEntries = styled(View).attrs({ name: 'reverse-entries' })`
    background-color: ${fromTheme('backgroundColor')};
    flex-direction: column-reverse;
    padding: 0;
    flex-grow: 1;
`;
ReverseEntries.displayName = 'ReverseEntries';

export const StyledDynamicFieldEntry = styled(View).attrs({ name: 'styled-dynamic-field-entry' })`
    background-color: ${fromTheme('backgroundColor')};
    flex-direction: column;
    padding: 0;
    flex-grow: 1;
`;
StyledDynamicFieldEntry.displayName = 'StyledDynamicFieldEntry';

export const Header = styled(View).attrs({ name: 'header' })`
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
`;
Header.displayName = 'Header';

export const Title = styled(h5)`
    color: ${fromTheme('colorScheme', 'accent-1-hover')};
`;
Title.displayName = 'Title';

export const ListRow = styled(View).attrs({ name: 'dynamic-field-entry-row' })`
    background-color: ${fromTheme('backgroundColor')};
    flex-direction: row;
    align-items: flex-end;
    max-width: ${({ theme: { width, viewMargin } }) => {
        return width - viewMargin * 2 + 'px';
    }};
    justify-content: space-between;
`;
ListRow.displayName = 'ListRow';

const _Container = styled(View).attrs({ name: 'dynamic-field' })`
    max-width: ${fromTheme('form', 'entryMaxWidth')};
    min-width: ${({ theme: { width, viewMargin, button } }) => {
        return Math.min(width - viewMargin * 2 - button.minWidth, 280) + 'px';
    }};
    margin: 0;
    color: ${fromTheme('defaultFontColor')};
    font-family: ${fromTheme('font')};
    font-size: ${fromTheme('fontSize')};
`;

export const Container = webOnlyStyles(_Container)`
    display: block;
    overflow: visible;
`;
Container.displayName = 'Container';

const _LabelAndField = styled(View).attrs({ name: 'label-and-field' })`
    position: relative;
    font-size: 16px;
    margin: 0;
    width: 100%;
`;

export const LabelAndField = webOnlyStyles(_LabelAndField)`
    display: inline-block;
    box-sizing: border-box;
    overflow: visible;
`;
LabelAndField.displayName = 'LabelAndField';
