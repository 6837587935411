import genericRule from '../../namespace/relation/willCreate_namespace_relation';

export default {
    ...genericRule,
    excludedNamespaceRelations: [],
    namespace: 'application',
    relation: 'savedFilter',
    logic: redirectOrPassThrough
};

async function redirectOrPassThrough({ data, context, dispatch, ...rest }) {
    const { newRecord: { isShared = false, ...newRecord } = {} } = data;
    if (isShared) {
        context.relation = 'sharedFilter';
        await dispatch({ ...data, newRecord: { ...newRecord } }, context);
        // including an ".data" in the result makes rulesEngine.io use the entire object as "result"
        // with it only overriding the context
        // including an ".error" in the result causes it to break out of the workflow
        return { error: { field: {}, form: ['Aborted. Processed as Shared Filter'], aborted: true }, data: {} };
    }
    return genericRule.logic({ data: { ...data, newRecord }, context, dispatch, ...rest });
}
