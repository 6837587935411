import { Text, styled, webOnlyStyles, fromTheme } from 'lib_ui-primitives';
import { nativeOnlyProperties } from 'lib_ui-services';

export let ColumnWidth = styled(Text).attrs(props => ({
    title: typeof props.children === 'string' ? props.children : undefined,
    name: 'column',
    ...nativeOnlyProperties({ numberOfLines: 2, ellipsizeMode: 'tail' })
}))`
    width: ${({ width }) => width + 'px'};
    border-left-width: 0px;
    border-left-color: ${fromTheme('gridLineColor')};
    text-align: left;
    overflow: hidden;
    padding-left: ${fromTheme('textPadding')};
    padding-right: ${fromTheme('textPadding')};
`;
export let ColumnNoWidth = styled(Text).attrs(props => ({
    title: typeof props.children === 'string' ? props.children : undefined,
    name: 'column',
    ...nativeOnlyProperties({ numberOfLines: 2, ellipsizeMode: 'tail' })
}))`
    border-left-width: 0px;
    border-left-color: ${fromTheme('gridLineColor')};
    text-align: left;
    overflow: hidden;
    padding-left: ${fromTheme('textPadding')};
    padding-right: ${fromTheme('textPadding')};
`;
ColumnNoWidth = webOnlyStyles(ColumnNoWidth)`
    border-left-style: solid;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
ColumnWidth = webOnlyStyles(ColumnWidth)`
    border-left-style: solid;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
