import styled from '../styled';
import PropTypes from 'prop-types';
import { keyframes } from 'styled-components';
import View from './View';
import fromTheme from '../fromTheme';

const spin = keyframes`
    to { transform: rotate(360deg); }
`;

const Spinner = styled(View)`
    display: inline-block;
    width: ${props => (props.size === 'small' ? '25px' : '50px')};
    height: ${props => (props.size === 'small' ? '25px' : '50px')};
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: ${fromTheme('spinnerColor')};
    animation: ${spin} 1s ease-in-out infinite;
    flex-shrink: 0;
`;

Spinner.propTypes = {
    size: PropTypes.oneOf(['small', 'large']),
    color: PropTypes.string
};

Spinner.defaultProps = {
    size: 'small'
};

export default Spinner;
