import lodash from 'lodash';
const { cloneDeep } = lodash;
import setDefaultValuesIfNecessary from '../../../utilities/setDefaultValuesIfNecessary';
import { COMMON_COLOR_SCHEME } from 'lib_ui-primitives';

const _p = {
    setDefaultValuesIfNecessary
};

export const _private = _p;

export default {
    verb: 'willChange',
    namespace: 'transaction',
    relation: 'checkIn',
    description: 'Notify the user if a newly added asset originated from a different location',
    logic: changeSuccess
};

async function changeSuccess({ data, dispatch, context }) {
    const { newRecord: _newRecord, propertyName, newValue, hNode } = data;

    const newTransactionRecord = cloneDeep(_newRecord);
    // Need to merge in defaults to avoid validation errors for missing required values
    // that are defaulted. Always treat as new.
    await _p.setDefaultValuesIfNecessary({ ...context, hNode, isNew: true }, newTransactionRecord);

    const newTransactionRoom = newTransactionRecord['location:location'];
    //if a check-in room has been selected,
    //and we are changing the included items
    if (newTransactionRoom && propertyName === 'includedItems') {
        //if we can find an item that wasn't on the includedItems yet
        const newlyAddedItem = newValue.find(
            item => !newTransactionRecord.includedItems?.find(x => x._id === item._id)
        );
        //and that new item has a different location that what we are checking in to
        if (
            newlyAddedItem &&
            newlyAddedItem['location:location']?._id &&
            newlyAddedItem['location:location']._id !== newTransactionRoom._id
        ) {
            //ALERT user
            const userOKWithLocationChange = await new Promise(resolve => {
                dispatch(
                    {
                        // title: ' ☠ ☠ WARNING! ☠ ☠ DEATH MAY COME YOUR WAY ☠ ☠ ',
                        message: [
                            `Asset ${newlyAddedItem.assetNo} is currently assigned to a different location.`,
                            'Continuing will assign it to the selected location.',
                            'Continue?'
                        ],
                        okButtonText: 'YES',
                        cancelButtonText: 'NO',
                        icon: 'warning',
                        iconColor: COMMON_COLOR_SCHEME.warn,
                        okAction: () => resolve(true),
                        cancelAction: () => resolve(false)
                    },
                    { verb: 'confirm', namespace: 'application', relation: 'user' }
                );
            });
            if (!userOKWithLocationChange) {
                //remove read, which will remove it from the grid.
                dispatch(newlyAddedItem, { verb: 'remove', namespace: 'sensor', relation: 'read' });
                //no need to further process the record with this change
                // "change" back to the current value (pre-adding this asset)
                return { ...data, newValue: _newRecord.includedItems };
            }
        }
    }
    return data;
}
