import lodash from 'lodash';
const { omit } = lodash;
import notHandledHere from './notHandledHere';
import { metadata, ObjectId } from 'lib_ui-services';

export default {
    verb: 'doingNew',
    description: 'New a record from a previously existing record of the same namespace/relation.',
    priority: 0,
    excludedNamespaceRelations: notHandledHere,
    //this is the actual logic:
    logic: doingNew
};
// new is a reserved word
function doingNew({ data, context }) {
    const { namespace, relation, type } = context;
    const { newRecord, ...restOfData } = data;
    const keysToOmit = metadata.commonKeysToOmit;
    // Allow a 'newRecord' property to be passed in with preset values.  This happens before
    // form state is created so this will not set the record to dirty.
    const record = { _id: new ObjectId().toString(), ...omit(newRecord, keysToOmit) };
    return {
        activationVerb: 'new',
        record,
        namespace,
        relation,
        type,
        isNew: true,
        //I don't think this will break anything.
        //However, If data being passed through breaks things, the one property we know we need is `createdBySearch`
        ...restOfData
    };
}
