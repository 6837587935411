import { createElement as rc, Fragment, useRef, useCallback, useEffect, useMemo } from 'react';
import { Text, Link, Card, hooks } from 'lib_ui-primitives';
import Form from '../form/Form';
import { Email, Password } from '../formElement';
import FormButton from '../formButton/FormButton';
import Disclaimer from './Disclaimer';
import useActiveRecord from '../../hooks/useActiveRecord';
import useAuthenticationType from './useAuthenticationType';
import useEventSink from '../../hooks/useEventSink';
import { nativeOnlyProperties } from 'lib_ui-services';
import FocusProvider from '../contextProviders/FocusProvider';
import { CardBox, HeaderText } from './styles';

const { useRouter } = hooks;

const getHNode = ({ namespace, relation, type }) => ({
    id: 'loginform',
    title: '',
    hNodeType: 'Form',
    hNodeTypeGroup: 'form',
    children: [
        {
            id: 'loginEmail',
            title: 'Email',
            hNodeType: 'ShortText',
            hNodeTypeGroup: 'formElement',
            propertyName: 'email',
            defaultValue: '',
            scanBarcode: true,
            scanRfid: false,
            treePosition: { sequence: 1 },
            transforms: [{ hNodeType: 'TabAfterScan' }]
        },
        {
            id: 'password',
            title: 'Password',
            hNodeType: 'Password',
            hNodeTypeGroup: 'formElement',
            propertyName: 'password',
            defaultValue: '',
            scanBarcode: true,
            scanRfid: false,
            treePosition: { sequence: 2 },
            transforms: [{ hNodeType: 'ActionAfterScan', forAction: 'submit', namespace, relation, subtype: type }]
        }
    ]
});
const formButtonHNode = {
    id: 'login',
    title: 'LOGIN',
    hNodeType: 'FormButton',
    hNodeTypeGroup: 'formButton',
    hNodeBlockGroup: 'formButtons',
    formAction: 'submit'
};

const _p = { useAuthenticationType };
export const _private = _p;
export default function Login() {
    const errors = _p.useAuthenticationType('login');
    const emailRef = useRef();
    const passRef = useRef();
    const { namespace, relation, record, type } = useActiveRecord();
    const [, publish] = useEventSink();
    const hNode = useMemo(() => getHNode({ namespace, relation, type }), [namespace, relation, type]);
    useEffect(() => {
        setTimeout(() => {
            emailRef.current?.focus();
        }, 1000);
    }, []);
    const onKeyPress = useCallback(
        e => {
            if (e.key === 'Enter') {
                if (emailRef.current.value == null || emailRef.current.value.length === 0) {
                    emailRef.current.focus();
                } else if (passRef.current.value == null || passRef.current.value.length === 0) {
                    passRef.current.focus();
                } else {
                    publish({ record }, { verb: 'submit', namespace, relation, type });
                }
            }
        },
        [namespace, relation, publish, record, type]
    );

    const { groupNumber } = useRouter();
    let groupPath = '';
    if (groupNumber != undefined && groupNumber !== '') {
        groupPath = `/g/${groupNumber}`;
    }

    // prettier-ignore
    return rc(Fragment, null,
        rc(FocusProvider, { name: 'login' },
            rc(CardBox, null,
                rc(Card.Header, { style: { justifyContent: 'center' } },
                    rc(HeaderText, { name: 'card-header-title' }, 'Login')
                ),
                rc(Card.Body, null,
                    rc(Form, { hNode, errors, popErrorNotifications: false, heightGreedy: false, centerFields: true },
                        rc(Email, {
                            ref: emailRef,
                            onKeyPress,
                            ...nativeOnlyProperties({
                                onChange: text => emailRef.current.value = text
                            }),
                            active: true,
                            hNode: hNode.children[0]
                        }),
                        rc(Password, {
                            ref: passRef,
                            onKeyPress,
                            ...nativeOnlyProperties({
                                onChange: text => passRef.current.value = text,
                            }),
                            allowPasswordFill: true,
                            hNode: hNode.children[1]
                        }),
                        rc(FormButton, { hNode: formButtonHNode })
                    )
                ),
                rc(Card.Footer, { style: { justifyContent: 'space-between', flexShrink: 0, borderTopWidth: 0 } },
                    // 2ROOT: leaving this in but commented out for now. Eventually, we will need to come up with a design that allows all to be included. Probably.
                    // eslint-disable-next-line no-undef
                    //!__SST_REACT_NATIVE__ && rc(Link, { id: 'signup-link', to: `${groupPath}/signup` }, rc(Text, {id: 'signup-text'}, 'Sign up')),

                    // eslint-disable-next-line no-undef
                    !__SST_REACT_NATIVE__ && rc(Link, { id: 'sso-link', to: `${groupPath}/oauth` }, rc(Text, { id: 'sso-text' }, 'Single Sign-On')),
                    // eslint-disable-next-line no-undef
                    !__SST_REACT_NATIVE__ && rc(Link, { id: 'reset-link', to: `${groupPath}/reset` }, rc(Text, { id: 'forgot-password-text' }, 'Forgot Password?'))
                )
            ),
        ),
        rc(Disclaimer)
    );
}
