/**
 * FYI - There is no blockly block for this right now.
 * This is because there is currently no scenario it will be used.
 * I suppose it is just a matter of time though.
 * The main purpose of this filter is a placeholder to show how to do it.
 * It will take SIGNIFICANTLY less time to implement it later if I include
 * this here now to show how the code works.
 */
export default {
    getFilter,
    getUriComponent,
    fromHNode,
    pageResetRequired: true
};

function fromHNode() {
    return getFilter();
}

function getFilter() {
    return {};
}

function getUriComponent() {
    // We have not implemented case sensitive filters on the server
    throw new Error('not implemented');
}
