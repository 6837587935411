import { useCallback, useMemo } from 'react';
import { useDebounce } from 'lib_ui-primitives';
const defaultTimeout = 200;

/**
 * @callback ClickEvent
 * @param{React.MouseEvent} [event]
 * @return {void}
 *
 * @typedef {Object} Options
 * @property {number} [timeout]
 */

/**
 * This tries to protect the user by:
 *  checking dependencies on the doubleClick using the doubleClickDependencies
 *  checking dependencies on the click using the clickDependencies.
 * The react-hooks eslint will also help by looking at the doubleClickDependencies
 * to see if they are valid.  Unfortunately, it will not check two different sets
 * of dependencies for the same hook, so you are on your own with the clickDependencies.
 * @param {ClickEvent} doubleClick
 * @param {array} doubleClickDependencies
 * @param {ClickEvent | Options} [clickOrOptions]
  @param {array} clickDependencies
 * @param {Options} [optionalOptions]
 */
export default function useDoubleClick(
    doubleClick,
    doubleClickDependencies,
    clickOrOptions,
    clickDependencies,
    optionalOptions
) {
    const click = useGetClick(clickOrOptions, clickDependencies);
    const options = useMemo(() => getOptions(clickOrOptions, optionalOptions), [clickOrOptions, optionalOptions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedDoubleClick = useDebounce(doubleClick, doubleClickDependencies, options.timeout, undefined);

    /**
     * @param {React.MouseEvent} event
     */
    return useCallback(
        (event, ...args) => {
            // Browser decides if it is double or single click.  No need
            // for counters or timeouts.
            // React native will not include event.detail because onClick is just
            // onPress changed to onClick for the sake of consistency.  For this reason,
            // this will not allow double click on react native.  It will always treat it
            // as a single click.
            if (event?.detail === 2) {
                event.persist();
                debouncedDoubleClick(event, ...args);
            } else {
                if (click != null) {
                    event.persist();
                    click(event, ...args);
                }
            }
        },
        [click, debouncedDoubleClick]
    );
}

/**
 * @param {ClickEvent | Options} [clickOrOptions]
 */
function useGetClick(clickOrOptions, clickDependencies) {
    // This strange little dance is because of the rules-of-hooks:
    //  the useCallback cannot be conditional, but we don't know if clickOrOptions is
    // a function.  So the input is made conditional instead (so that whatever is passed into
    // useCallback is always a function).
    const isFunction = typeof clickOrOptions === 'function';
    let func = () => {};
    if (isFunction) {
        func = clickOrOptions;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    func = useCallback(func, clickDependencies);
    if (isFunction) return func;
}

/**
 * @param {ClickEvent | Options} clickOrOptions
 * @param {Options} optionalOptions
 */
function getOptions(clickOrOptions, optionalOptions) {
    if (typeof clickOrOptions === 'object') return { timeout: defaultTimeout, ...clickOrOptions };

    return { timeout: defaultTimeout, ...optionalOptions };
}
