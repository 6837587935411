import { constants } from 'lib_ui-services';
import lodash from 'lodash';
const { cloneDeep } = lodash;

export default {
    verb: 'willNew',
    namespace: 'identity',
    relation: 'role',
    description: 'Initialize the role with "USER" level rights',
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'identity',
                relation: 'role',
                type: 'find'
            },
            query: () => {
                return { title: constants.WELL_KNOWN_ROLES.USER };
            }
        }
    ],
    //this is the actual logic:
    logic: willNew
};

async function willNew({ data, prerequisiteResults }) {
    const userRole = prerequisiteResults?.[0]?.result?.[0];
    if (!userRole) {
        throw new Error('The "USER" role is not found.');
    }

    data.newRecord = {
        ...data.newRecord,
        access: cloneDeep(userRole.access)
    };

    return data;
}
