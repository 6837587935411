import { useState, useEffect } from 'react';
import { useFilterInterdependencyBoundary } from '../../contextProviders/FilterInterdependencyBoundary';
import logging from '@sstdev/lib_logging';
import useEventSink from '../../../hooks/useEventSink';
import { metadata } from 'lib_ui-services';

export const _private = {
    useFilterInterdependencyBoundary
};

/**
 * Determine if a component specified by hNode should be disabled based on the FilterInterdependencyBoundaryContext
 * state created by the filters contained in that hNode.
 * The FilterInterdependencyBoundaryContext will not change when the filter selections change (otherwise everything
 * inside of it would rerender).
 * Instead of relying on that, this listens for a selection on a lower priority than the FilterInterdependencyBoundary
 * and uses the selection value of contained in FilterInterdependencyBoundary to determine if the component should be
 * disabled.
 * Changes to the disabled state inside here will cause the component containing this hook to be rerendered.
 * @param {object} hNode - hNode of component containing filters that might need to disable that component
 * @returns {boolean} disabled
 */
export default function useIsDisabledByFilter(hNode, defaultValue = false) {
    const [subscribe] = useEventSink();
    const [disabled, setDisabled] = useState(defaultValue);
    const boundaryContext = _private.useFilterInterdependencyBoundary();
    useEffect(() => {
        // hNode (e.g. a dropdown) has a ForeignRelationSelectionOnForm
        // filter with the disableBeforeParentSelection set to true
        const filters = metadata.findAllHNodes(
            hNode.children,
            hNode => hNode.hNodeType === 'ForeignRelationSelectionOnForm' && hNode.disableBeforeParentSelection
        );
        const unsubscribes = filters.map(filter => {
            // namespace and relation of parent
            const { namespace, relation } = filter;
            const parentExistsWithoutSelection = doesParentExistWithoutSelection(boundaryContext, namespace, relation);
            // disabled if a parent exists without a selection
            setDisabled(parentExistsWithoutSelection);
            return subscribe({ verb: 'select', namespace, relation, status: 'success', priority: -10 }, () => {
                const parentExistsWithoutSelection = doesParentExistWithoutSelection(
                    boundaryContext,
                    namespace,
                    relation
                );
                // disabled if a parent exists without a selection
                setDisabled(parentExistsWithoutSelection);
            });
        });
        return () => unsubscribes.forEach(u => u());
    }, [subscribe, hNode, boundaryContext]);

    logging.debug(`[useIsDisabledByFilter] - ${hNode.foreignNamespace}:${hNode.foreignRelation} = ${disabled}`);
    return disabled;
}

function doesParentExistWithoutSelection(boundaryContext, namespace, relation) {
    const selectionOfParent = boundaryContext.getSelection(namespace, relation);
    return (
        selectionOfParent == null ||
        selectionOfParent.isDefaultRecord === true ||
        (Array.isArray(selectionOfParent) &&
            (selectionOfParent.length === 0 || selectionOfParent[0].isDefaultRecord === true)) ||
        Object.keys(selectionOfParent).length === 0
    );
}
