//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _BarcodeContent from './BarcodeContent';
import _ColumnLayout from './ColumnLayout';
import _FixedTextContent from './FixedTextContent';
import _NewLabel from './NewLabel';
import _QRContent from './QRContent';
import _ReferencedContent from './ReferencedContent';
import _RfidContent from './RfidContent';
import _SplitLayout from './SplitLayout';
export const BarcodeContent = _BarcodeContent;
export const ColumnLayout = _ColumnLayout;
export const FixedTextContent = _FixedTextContent;
export const NewLabel = _NewLabel;
export const QRContent = _QRContent;
export const ReferencedContent = _ReferencedContent;
export const RfidContent = _RfidContent;
export const SplitLayout = _SplitLayout;
export default {BarcodeContent, ColumnLayout, FixedTextContent, NewLabel, QRContent, ReferencedContent, RfidContent, SplitLayout};

