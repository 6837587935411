import { constants, metadata } from 'lib_ui-services';
import { COMMON_COLOR_SCHEME } from 'lib_ui-primitives';
import downloadTheFile from '../exportHelpers/exportHelper';

const { EXPORT_TYPES } = constants;

export default {
    verb: 'didBulk',
    type: 'remove',
    description:
        'Notify the user that the bulk remove operation has completed and offer CSV download for failed records',
    logic: didBulkRemove
};

export const _private = {
    createErrorRecords,
    handleCsvDownload,
    showFailedRemovalMessage,
    downloadTheFile
};

/**
 * @param {{
 *   data: { result: { totalRecords: number, failed: Array } };
 *   prerequisiteResults: object[];
 *   context: { namespace: string, relation: string };
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 */
async function didBulkRemove({ data, context, dispatch }) {
    const { result: { totalRecords = 0, failed = [] } = {} } = data;

    // If there are no records to remove, don't show the notification
    if (totalRecords === 0) {
        return;
    }

    const { namespace, relation } = context;
    const prettyName = metadata.getPrettyRelationName(namespace, relation);
    const titleAlternative = metadata.getTitleAlternative(namespace, relation);
    const titleLabel = metadata.getTitleAlternativePretty(namespace, relation);

    const numFailed = failed.length;
    const numSucceeded = totalRecords - numFailed;
    const messageArray = [];

    messageArray.push(`${numSucceeded} ${prettyName} records removed successfully.`);

    if (numFailed > 0) {
        messageArray.push(`${numFailed} ${prettyName} records failed to be removed.`);

        if (numFailed === totalRecords) {
            messageArray[0] = `All ${totalRecords} ${prettyName} records failed to be removed.`;
            messageArray.pop(); // Remove the second message in this case
        }

        // Handle the CSV download for failed records
        const title = `${prettyName}_Failed_Removals`;
        await _private.handleCsvDownload(dispatch, title, failed, messageArray, titleAlternative, titleLabel);
    }

    dispatch(
        {
            message: messageArray.join('\n'),
            timeout: 5000
        },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
}

/**
 * Utility functions
 */

/**
 * Creates an array of error records from the provided failed records.
 * @param {Array} failedRecords - An array of failed records.
 * @returns {Array} An array of error records with consolidated error messages.
 */
function createErrorRecords(failedRecords) {
    return failedRecords.map(failedRecord => ({
        ...failedRecord.record,
        errors: failedRecord.error || 'Unknown error'
    }));
}

/**
 * Give the user the option to download a CSV file with a description of why the removal failed
 */
async function handleCsvDownload(dispatch, title, failed, messageArray, titleAlternative, titleLabel) {
    const columns = [
        { title: 'Errors', propertyName: 'errors', sequence: 0, id: 'errors' },
        { title: titleLabel, propertyName: titleAlternative, sequence: 1, id: titleAlternative }
    ];

    const failedRecords = _private.createErrorRecords(failed);
    const downloadErrorCsv = () => _private.downloadTheFile(title, failedRecords, columns, EXPORT_TYPES.csv);
    await _private.showFailedRemovalMessage(dispatch, downloadErrorCsv, messageArray);
}

/**
 * Show the Remove error message and prompt the user to download the error CSV file
 */
async function showFailedRemovalMessage(dispatch, fn, messageArray) {
    return new Promise(resolve => {
        dispatch(
            {
                title: 'Bulk Remove Failed',
                message: [...messageArray, 'Would you like to download the failed records?'],
                okButtonText: 'Download',
                cancelButtonText: 'Cancel',
                icon: 'warning',
                iconColor: COMMON_COLOR_SCHEME.warn,
                okAction: () => {
                    fn();
                },
                cancelAction: () => resolve(false)
            },
            { verb: 'confirm', namespace: 'application', relation: 'user' }
        );
    });
}
