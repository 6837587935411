import lodash from 'lodash';
const { get } = lodash;
const { set } = lodash;

const indexTools = _private => {
    const { startPromise, collection, MIN_LEXICAL_VALUE } = _private;

    /**
     * For paging, it is much faster to jump to an index value at the start
     * of each new page.
     * To safely use paging based off unique index values, every index value
     * must be completely unique.
     * This function will create an index (obviously), but it will also
     * create a value that combines _id (which is already unique)
     * with the value being indexed and place the new value in a safe path
     * away from the original data.  This new value will be the actual value
     * that is indexed.
     * @param {string} indexTitle - usually the property name that will be indexed
     * @param {boolean} caseSensitive - mainly influences sorting because pure case-sensitive lexical sorting will look strange to a user.  For instance: 'A','Z','a' - because 'a' is a greater value than 'Z' in ASCII/unicode.
     */
    function addLexicalIndex(indexTitle, caseSensitive = false) {
        // _id index already exists.
        if (indexTitle === '_id') return startPromise;
        return startPromise.then(() => {
            // Get the path in the record where we will store the index value.
            const indexPath = getSafeIndexPath(indexTitle);
            // If the index already exists, exit.
            if (collection.binaryIndices[indexPath]) return;

            // Get function which generates the correct index value in a given document.
            const setIndexValuePerDocument = setSafeIndexValue(indexPath, indexTitle, caseSensitive);

            // Make sure unique value used by index is in all existing records.
            collection.updateWhere(() => true, setIndexValuePerDocument);

            // This syntax creates the index if it doesn't exist
            // - I know you can't get here if it does,
            // but there is not other lokijs syntax to create an index :)
            collection.ensureIndex(indexPath);

            // Make sure any changes to the record update the index path as well.
            collection.on('pre-insert', setIndexValuePerDocument);
            collection.on('pre-update', setIndexValuePerDocument);
        });
    }

    /**
     * Create an index value path safely away from the original value
     * @param {string} indexPath -- path to value to use for index
     */
    function getSafeIndexPath(indexPath) {
        // '_id' is always safe and unique by itself
        if (indexPath === '_id') return indexPath;
        return `_idx._${indexPath}`;
    }

    /**
     * This will use the safe index path to determine the original document path that
     * is used to derive the index value.
     * @param {string} safeIndexPath - the path used by the index to store the value used by the index
     */
    function getOriginalValuePath(safeIndexPath) {
        return safeIndexPath.substr(6);
    }

    /**
     * Derive the unique index value.  It should be the original index column
     * value + MIN_LEXICAL_VALUE + the unique _id.
     * Placing the MIN_LEXICAL_VALUE (i.e. '\u0000') in between ensures the index
     * order is not affected by the unique id.
     * @example:
     * 'test10' + 'abcdeUniqueId'
     * // would get sorted BEFORE
     * 'test1' + 'bcdefUniqueId'
     * // because b > 0 - this would be BAD.
     * // Instead,
     * 'test1' + '\u0000' + 'bcdefUniqueId'
     * 'test10' + '\u0000' + 'abcdeUniqueId'
     * // gets sorted in the correct order.
     * @param {string} safePath - the path where the index value is stored
     * @param {string} path - the path where the original value is stored
     * @param {boolean} [caseSensitive=false] - the index will be stored lower case if this is not true
     */
    function setSafeIndexValue(safePath, path, caseSensitive = false) {
        return doc => {
            let indexEntryValue = get(doc, path, '').toString();
            if (!caseSensitive) {
                indexEntryValue = indexEntryValue.toLowerCase();
            }
            return set(doc, safePath, indexEntryValue + MIN_LEXICAL_VALUE + doc._id);
        };
    }

    /**
     * Listen for changes to the documents in this collection and ensure the index
     * value is always updated if a new doc appears or a doc changes
     */
    function ensureIndexesAreUpdated() {
        // Ensure any new changes are also reflected in existing indexes
        // see addLexicalIndex.js for additional details.
        Object.keys(collection.binaryIndices).forEach(indexPath => {
            // Only select indexes created by indexTools.js
            if (indexPath.startsWith('_idx')) {
                const originalPath = getOriginalValuePath(indexPath);
                // Make sure any changes to the record update the index.
                collection.on('pre-insert', setSafeIndexValue(indexPath, originalPath));
                collection.on('pre-update', setSafeIndexValue(indexPath, originalPath));
            }
        });
    }

    return {
        addLexicalIndex,
        getSafeIndexPath,
        setSafeIndexValue,
        getOriginalValuePath,
        ensureIndexesAreUpdated
    };
};

export default indexTools;
