import { createElement as rc, forwardRef, useCallback } from 'react';
import { TextInput, hooks } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';
import PropTypes from 'prop-types';
import useScanningForField from '../hooks/useScanningForField';
import useTransforms from './transforms/useTransforms';
const { useEnsureRef } = hooks;

const RECORD_SEPARATOR = String.fromCharCode(30);

const ScanTextInput = forwardRef(function ScanTextInput(props, passedRef) {
    const ref = useEnsureRef(passedRef);
    const { hNode, active, name, onChange, scanChange, ...otherProps } = props ?? {};

    const { reset } = useScanningForField(hNode, scanChange ?? onChange, active, ref, true, name);
    const { transformOnChange } = useTransforms(hNode);

    const datawedgeRecognizingOnChange = useCallback(
        value => {
            let scanType = constants.sensorTypes.MANUAL;
            if (value.includes(RECORD_SEPARATOR)) {
                value = value.replace(RECORD_SEPARATOR, '');
                scanType = constants.sensorTypes.BARCODE;
                //Assumption at this time: barcodes have correct value that don't need transformations anymore
                //so just pass value through without transforming.
                scanChange?.(value, scanType);
            } else if (!value) {
                reset();
            }
            onChange(transformOnChange(value), scanType);
        },
        [onChange, reset, scanChange, transformOnChange]
    );
    // prettier-ignore
    return rc(TextInput, { ref, name, onChange: datawedgeRecognizingOnChange, ...otherProps });
});
ScanTextInput.propTypes = {
    hNode: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
};

export default ScanTextInput;
