//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _willMark_item_item from './willMark_item_item';
import _willNew_item_item from './willNew_item_item';
export const willMark_item_item = _willMark_item_item;
export const willNew_item_item = _willNew_item_item;
export default {willMark_item_item, willNew_item_item};

