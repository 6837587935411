import lodash from 'lodash';
const { omit } = lodash;
import * as cache from './cache';

const _p = {
    cacheSave: cache.save
};
export const _private = _p;

const g = [
    { _id: '605506e21637f11038e1963b', title: 'g0' },
    { _id: '605506fd1637f11038e1963c', title: 'g1' }
];

export default async function selectTenant(payload) {
    const { userName, sub, isOauthUser, tenantId, useCaseId, ...rest } = payload;
    if (!isOauthUser) {
        return Promise.reject(
            new Error('Misconfiguration. Only Auth0 authenticated users should go through this code.')
        );
    }
    if (!userName || !sub) {
        return Promise.reject(new Error('A valid Auth0 user must be provided'));
    }
    const activeTenant = rest.user?.tenant?.find(t => t['identity:tenant']._id === tenantId);
    if (!activeTenant) {
        return Promise.reject(new Error('Invalid Tenant'));
    }
    const activeUseCase = activeTenant.useCase.find(t => t['metaui:useCase']._id === useCaseId);
    if (!activeUseCase) {
        return Promise.reject(new Error('Invalid Use Case'));
    }
    const role = activeUseCase['identity:role'];

    //default to g1 if nothing there. #5177 should give us the actual 'deploy:group'
    const group = activeUseCase['deploy:group'] || g[1];
    const profile = {
        ...omit(rest, ['type']),
        userName,
        sub,
        isOauthUser,
        needTenantSelection: false,
        activeTenant,
        activeTenantId: tenantId,
        activeUseCase,
        activeUseCaseId: useCaseId,
        'identity:role': role,
        allFeatureFlags: activeUseCase.featureFlags || [],
        needsLicenseAcceptance: false,
        // to insert in meta as modifiedBy for instance.
        briefUserReference: { _id: rest.user._id, displayName: rest.user.displayName },
        group
    };
    await _p.cacheSave(profile, sub);

    return profile;
}
