//for more information see also https://www.zebra.com/content/dam/support-dam/en/documentation/unrestricted/guide/software/zpl-zbi2-pg-en.pdf

const LABEL_WIDTH = '^PW';
/**
 * Label length, ignored unless the printer is in continuous label mode.
 */
const LABEL_HEIGHT = '^LL';
/**
 * Change the origin (top left) of the label coordinates
 * relative to the print grid. if labelWith = 100, and labelHome has = 10,10, then you only have 90 pixels that you can print on.
 * Once you have issued an ^LH command, the setting is retained until you turn off the printer or send a new ^LH command to the printer.
 */
const LABEL_HOME = '^LH';
/**
 * Shift print grid (and origin) up or down
 * NOTE: It probably is wiser to have the user configure this directly on the printer
 * than trying to mess with this from backbone
 */
const LABEL_SHIFT_DOWN = '^LT';
/**
 * Shift print grid (and origin) left (positive values) or right (negative values)
 * NOTE: It probably is wiser to have the user configure this directly on the printer
 * than trying to mess with this from backbone
 */
const LABEL_SHIFT_LEFT = '^LS';

/**
 * The label media type being used. Valid values are
 *   N (continuous media),
 *   V (variable-length continuous media),
 *   W or Y (non-continuous web-sensing media),
 *   M (non-continuous mark-sensing media), and
 *   A (auto-detect media type during calibration).
 * There is no default value. Most labels we use are W or M
 * Using N can be a workaround for e.g. Silverline labels that are inconsistent to calibrate.
 */
const MEDIA_MODE = '^MN';

/**
 *  The ^JUS command saves the current printer configuration settings to the printer’s flash memory.
 */
const SAVE_CONFIGURATION = '^JUS';

/**
 * The ^BC command creates the Code 128 bar code, a high-density, variable length, continuous, alphanumeric symbology.
 * It was designed for complexly encoded product identification.
 * Code 128 has three subsets of characters.
 * There are 106 encoded printing characters in each set,
 * and each character can have up to three different meanings, depending on the character subset being used.
 * Each Code 128 character consists of six elements: three bars and three spaces.
 */
const CODE_128_BAR_CODE = '^BC';

/**
 * The ^BQ command produces a matrix symbology consisting of an array of nominally square modules
 * arranged in an overall square pattern. A unique pattern at three of the symbol’s four corners assists in
 * determining barcode size, position, and inclination
 */
const QR_CODE = '^BQ';

/**
 * The ^BY command is used to change the default values for the module width (in dots),
 * the wide bar to narrow bar width ratio and the bar code height (in dots).
 * It can be used as often as necessary within a label format.
 */
const BAR_CODE_FIELD_DEFAULT = '^BY';

/**
 * The ^FD command defines the data string for a field.
 * The field data can be any printable character except those used as command prefixes (^ and ~).
 */
const FIELD_DATA = '^FD';

/**
 * The ^FO command sets a field origin, relative to the label home (^LH) position.
 * ^FO sets the UPPER-LEFT corner of the field area by defining points along the x-axis and y-axis independent of the rotation.
 * Note, ^FT would set the BOTTOM-LEFT corner, but we don't use that here.
 */
const FIELD_ORIGIN = '^FO';

/**
 * The ^FB command allows you to print text into a defined block type format.
 * This command formats an ^FD or ^SN string into a block of text using the origin, font and rotation specified for the text string.
 * the ^FB command also contains an automatic word-wrap function
 */
const FIELD_BLOCK = '^FB';

/**
 * The ^FR command allows a field to appear as white over black or black over white.
 * When printing a field and the ^FR command has been used, the color of the output is the reverse of its background.
 */
// eslint-disable-next-line no-unused-vars
const FIELD_REVERSE_PRINT = '^FR';

/**
 * The ^FS command denotes the end of the field definition.
 */
const FIELD_END = '^FS';

/**
 * The  ~SD  command  allows  you  to  set  the  darkness  of  printing.
 * Format: ~SD##
 * `##` Desired darkness setting (two-digit number 00 to 30)
 * NOTE: using maximum darkness (30) can cause the ribbon to tear, especially when printing horizontal lines
 */
const SET_DARKNESS = '~SD';

/**
 * The ^PR command determines the media and slew speed (feeding a blank label) during printing.
 */
const SET_PRINT_SPEED = '^PR';

/**
 * The ^GF command allows you to download graphic field data directly into the printer’s bitmap storage
 * area. This command follows the conventions for any other field, meaning a field orientation is included. The
 * graphic field data can be placed at any location within the bitmap space.
 */
const GRAPHIC_FIELD = '^GF';

/**
 * The ^FX command is useful when you want to add non-printing informational comments or statements within a label format.
 * Any data after the ^FX command up to the next caret (^) or tilde (~) command does not have any effect on the label format.
 * Therefore, you should avoid using the caret (^) or tilde (~) commands within the ^FX statement.
 */
const COMMENT = '^FX';

/**
 * The ^XA command is used at the beginning of ZPL code.
 * It is the opening bracket and indicates the start of a new label format.
 */
const START_LABEL = '^XA';

/**
 * The ^XZ command is the ending (closing) bracket.
 * It indicates the end of a label format.
 * When this command is received, a label prints.
 */
const END_LABEL = '^XZ';

/**
 * Use this command to read or write to (encode) an RFID tag or to specify the access password.
 * Read or Write RFID Format
 * When using this command to read a tag, you may use a field variable to print the tag data on the label or to
 * return the data to the host.
 */
const RFID_FIELD = '^RF';

/**
 * Use this command to set up RFID parameters including tag type; programming position;
 * and error handling, such as setting the number of labels that will be attempted if an error occurs.
 * NOTE: probably useless from backbone, as the exact value is extremely dependent on exact (sub-millimeter) positioning of the label
 *
 */
// eslint-disable-next-line no-unused-vars
const RFID_SETUP = '^RS';

/**
 * Set RF Power Levels for Read and Write
 * NOTE: Printers automatically select the best antenna element and read/write power levels for the media
 * during RFID transponder calibration.
 * Some printers (including the ZT400 series) also may set the levels during an adaptive antenna sweep
 * NOTE: probably useless from backbone, as the exact value is extremely dependent on exact (sub-millimeter) positioning of the label
 */
// eslint-disable-next-line no-unused-vars
const RFID_POWER = '^RW';

/**
 * Use this command to initiate tag calibration for RFID media. During the tag calibration process
 * (which can take up to 5 minutes on some printers, depending on the type of RFID inlay and the label
 * size) the printer moves the media, reads the tag’s TID to determine chip type, calibrates the RFID
 * tag position, and determines the optimal settings for the RFID media being used. Depending on the
 * printer, these settings include the programming position, the antenna element to use, and the
 * read/write power level to use.
 * NOTE: Probably best to have the user do this through the printer's interface
 */
const RFID_CALIBRATE = '^HR';

const GRAPHIC_BOX = '^GB';

/**
 *
 * @param {number} width
 * @param {number} height
 * @param {number} contentWidth
 * @param {number} margin
 * @param {object} [overrides]
 * // label Size overrides
 * @param {'N'|'V'|'W'|'M'|'A'} [overrides.mediaMode] N = continuous media, W = web (gap) sensing, M = Mark, A = auto-detect media type during calibration
 * @param {number} [overrides.labelWidth] dots, Override when necessary for e.g. margins
 * @param {number} [overrides.labelLength] dots, overrides to facilitate e.g. Large gaps/spacers between labels
 * @param {object} [overrides.margin] Define Label Home (origin) relative to the top left corner
 * @param {number} overrides.offset.x dots
 * @param {number} overrides.offset.y dots
 * // rfid Specific overrides
 * @param {string} [overrides.writePosition] E.g. 'B6'
 * @param {number} [overrides.readPower]
 * @param {number} [overrides.writePower]
 * @param {antenna} [overrides.antenna] E.g. 'A4'
 * @returns
 */
function createLabel(width, height, padding /*contentWidth = width, margin = 8, overrides = null*/) {
    let zpl = { value: '', width, height, padding };

    zpl.value +=
        `${SET_DARKNESS}25\n` + //max darkness
        `${START_LABEL}\n\n`;

    return {
        moveTo: _moveTo(zpl),
        addComment: _addComment(zpl, false),
        addLabelShape: _addLabelShape(zpl),
        addRectangle: _drawRectangle(zpl),
        calibrateRfid: _calibrateRfid(zpl),
        addLabelSpec: _addLabelSpec(zpl),
        setHome: _setHome(zpl),
        toString: toStringNotAllowed
    };
}

function _addLabelShape(zplSoFar) {
    return function addLabelShape(width, height, padding) {
        /* For use on labelary.com to see the label shape, in case of offsets
            Generates something like this:
          
           ^FX: Label layout
           ^FO0,0^GB480,200,2,b,1^FS
           ^FX: padding
           ^FO16,16^GB448,168,1^FS

        */
        zplSoFar.value +=
            `${COMMENT} Label layout\n` +
            `${FIELD_ORIGIN}0,0` +
            `${GRAPHIC_BOX}${width},${height},2,b,1` + //draw a box around the label
            `${FIELD_END}\n` +
            `${COMMENT} padding\n` +
            `${FIELD_ORIGIN}${padding},${padding}` +
            `${GRAPHIC_BOX}${width - 2 * padding},${height - 2 * padding},1` + //draw a box around the content
            `${FIELD_END}\n\n`;

        return {
            moveTo: _moveTo(zplSoFar),
            addComment: _addComment(zplSoFar, false),
            addRectangle: _drawRectangle(zplSoFar),
            setHome: _setHome(zplSoFar),
            toString: toStringNotAllowed
        };
    };
}

function _drawRectangle(zplSoFar) {
    return function drawRectangle(left, top, width, height) {
        zplSoFar.value +=
            `${FIELD_ORIGIN}${left},${top}` +
            `${GRAPHIC_BOX}${width},${height},1` + //draw a box around the content
            `${FIELD_END}\n`;

        return {
            moveTo: _moveTo(zplSoFar),
            addComment: _addComment(zplSoFar, false),
            setHome: _setHome(zplSoFar),
            toString: toStringNotAllowed
        };
    };
}

function _addLabelSpec(zplSoFar) {
    /**
     * @param {{x?: number, y?: number}} offset //top left corner of the actual label
     * @param {string} mediaMode //N = continuous media, A = auto-detect media type during calibration
     */
    return function addLabelSpec(offset = {}, mediaMode = 'A') {
        zplSoFar.value +=
            `${SET_PRINT_SPEED}1,1,2\n` + //slowest speed
            `${MEDIA_MODE}${mediaMode}\n` + //set media mode
            `${LABEL_WIDTH}${zplSoFar.width}` + //Sets the label print width
            `${LABEL_HEIGHT}${zplSoFar.height}\n`; //Sets the label length, ignored when printer is NOT in continuous label mode

        if (offset.x || offset.y) {
            zplSoFar.value +=
                `${LABEL_SHIFT_DOWN}${offset?.y || 0}\n` + `${LABEL_SHIFT_LEFT}${offset?.x ? -offset.x : 0}\n`;
        }

        zplSoFar.value += `${LABEL_HOME}0,0\n` + `${SAVE_CONFIGURATION}\n`;

        return {
            moveTo: _moveTo(zplSoFar),
            addComment: _addComment(zplSoFar, true),
            toString: _endLabelAndSerialize(zplSoFar)
        };
    };
}

function _setHome(zplSoFar) {
    return function setHome(offset = { x: 0, y: 0 }) {
        zplSoFar.value += `${LABEL_HOME}${offset.x},${offset.y}\n`;
        return {
            moveTo: _moveTo(zplSoFar),
            addRectangle: _drawRectangle(zplSoFar),
            addComment: _addComment(zplSoFar, true),
            toString: _endLabelAndSerialize(zplSoFar)
        };
    };
}

function _addComment(zplSoFar, allowToString) {
    return function addComment(comment) {
        zplSoFar.value = zplSoFar.value + `${COMMENT} ${comment}\n`;
        return {
            moveTo: _moveTo(zplSoFar),
            setHome: _setHome(zplSoFar),
            toString: allowToString ? _endLabelAndSerialize(zplSoFar) : toStringNotAllowed
        };
    };
}

function _moveTo(zplSoFar) {
    /**
     * Move relative to top left corner
     * @param {number} x left to right coordinate in dots
     * @param {number} y top to bottom coordinate in dots
     */
    return function moveTo(x, y) {
        if (x > zplSoFar.fullWidth) {
            throw new Error(`Invalid X coordinate. ${x},${y} is outside the label's ${zplSoFar.fullWidth}px width`);
        }
        if (y > zplSoFar.y) {
            throw new Error(`Invalid Y coordinate. ${x},${y} is outside the label's ${zplSoFar.height}px height`);
        }
        zplSoFar.value = zplSoFar.value + '\n' + `${FIELD_ORIGIN}${x},${y}\n`;
        return {
            addRectangle: _drawRectangle(zplSoFar),
            setFont: _setFont(zplSoFar),
            setAlignment: _setAlignment(zplSoFar),
            addText: _addText(zplSoFar),
            addBarcode: _addBarcode(zplSoFar),
            addImage: _addImage(zplSoFar),
            addISOImage: _addISOImage(zplSoFar),
            toString: toStringNotAllowed
        };
    };
}

function _setFont(zplSoFar) {
    /**
     *
     * @param {*} font '0' prints about everything
     * @param {*} orientation default 'N' for Normal
     * @param {*} height font '0' at fontsize 10 is about 28 dots high
     * @param {*} width fonts '0' looks good when square, e.g. 28x28
     * @returns
     */
    return function setFont(font = '0', orientation = 'N', height, width) {
        zplSoFar.value = zplSoFar.value + `    ^A${font}`;
        if (orientation !== 'N') {
            zplSoFar.value += orientation; //no comma separator before orientation
        }
        if (height) {
            zplSoFar.value += ',' + height;
            if (width) {
                //if excluded, the font will auto scale
                zplSoFar.value += ',' + width;
            }
        }
        zplSoFar.value += '\n';
        return {
            setAlignment: _setAlignment(zplSoFar),
            addText: _addText(zplSoFar),
            toString: toStringNotAllowed
        };
    };
}

function _setAlignment(zplSoFar) {
    return function setAlignment(alignment, width = zplSoFar.width) {
        switch (alignment.toLowerCase()) {
            case 'center':
                zplSoFar.value = zplSoFar.value + `    ${FIELD_BLOCK}${width},2,0,C\n`;
                break;
            case 'right':
                zplSoFar.value = zplSoFar.value + `    ${FIELD_BLOCK}${width},2,0,R\n`;
                break;
            case 'justified':
                zplSoFar.value = zplSoFar.value + `    ${FIELD_BLOCK}${width},2,0,J\n`;
                break;
            case 'left':
            default:
                //do noting
                break;
        }

        return {
            addText: _addText(zplSoFar),
            toString: toStringNotAllowed
        };
    };
}

function _addBarcode(zplSoFar) {
    return function addBarcode(value, specs = {}) {
        let specsWithDefaults = { type: 'CODE128', barWidth: 2, barcodeHeight: 41, ...specs };
        let barcodeZPL = '';
        switch (specsWithDefaults.type) {
            //including this switch for future extension, even though right now it might seem silly.
            //case 'CODE39'
            //    break;
            case 'QR': {
                const {
                    orientation = 'N',
                    model = 2, // 1 (original) and 2 (enhanced – recommended)
                    magnificationFactor = '4', //1-100, default 2 on 200dpi printer, 4 on 2 200dpi printer gives about 1/2 inch square
                    errorCorrection = 'Q' //L = Low/fast, M = standard, Q = high, H = ultra High
                } = specsWithDefaults;
                barcodeZPL = `${QR_CODE}${orientation},${model},${magnificationFactor}`;
                value = `${errorCorrection}A,${value}`;

                break;
            }
            case '':
            case undefined:
            case 'CODE128':
            default: {
                const {
                    orientation = 'N',
                    barcodeHeight, //41 = 0.2 inch tall bars @ 203dpi printer
                    includeHumanReadable = false,
                    humanReadableAboveBarcode = false,
                    calculateCheckDigit = false,
                    subset
                } = specsWithDefaults;
                const mode = subset ? 'N' : 'A';
                barcodeZPL = `${CODE_128_BAR_CODE}${orientation},${barcodeHeight},${includeHumanReadable ? 'Y' : 'N'},${
                    humanReadableAboveBarcode ? 'Y' : 'N'
                },${calculateCheckDigit ? 'Y' : 'N'},${mode}`;

                if (subset) {
                    switch (subset) {
                        case 'A':
                            value = `>9${value}`;
                            break;
                        case 'B':
                            value = `>:${value}`;
                            break;
                        case 'C':
                            value = `>;${value}`;
                            break;
                    }
                }
                break;
            }
        }
        zplSoFar.value =
            zplSoFar.value +
            (specsWithDefaults.type === 'QR'
                ? ''
                : `    ${BAR_CODE_FIELD_DEFAULT}${specsWithDefaults.barWidth},3,${specsWithDefaults.barcodeHeight}\n`) + //Configures the bar code width, widthRatio, height`
            `    ${barcodeZPL}\n` + //set barcode specs
            `    ${FIELD_DATA}${value}\n` + //fill the field
            `${FIELD_END}\n`; //close the field

        return {
            moveTo: _moveTo(zplSoFar),
            addComment: _addComment(zplSoFar, true),
            toString: _endLabelAndSerialize(zplSoFar)
        };
    };
}

function _addImage(zplSoFar) {
    return function addImage(value, byteCount, totalBytes, bytesPerRow = 8) {
        const compression = 'A'; //A = ASCII Hexadecimal
        zplSoFar.value =
            zplSoFar.value + `    ${GRAPHIC_FIELD}${compression},${byteCount},${totalBytes},${bytesPerRow},${value}\n`;
        return {
            moveTo: _moveTo(zplSoFar),
            addComment: _addComment(zplSoFar, true),
            toString: _endLabelAndSerialize(zplSoFar)
        };
    };
}

function _addISOImage(zplSoFar) {
    return function addISOImage() {
        // 5x5 mm on 203dpi printer
        zplSoFar.value =
            zplSoFar.value +
            '    ^GFA,265,320,8,:Z64:eJzTe/doAQMQ1P8//AFEV61atA9EOzAwsIPoBwwM3AfAfEYHBzCf+YcBRF5GACLPtwOqngciv7gPor7wCER+xzuoejcwn4kvCcxnZ0kG8/nPPQbz5Y8kQvhNShD5Boj9/A1Q9zBA3cPADOWD6MePf+9rBtLuHy/KHgTxrRfvApnnLMgo4Azi71694zFY/qOAI1j9YguwvCNE/tHjZoh8IaMoSP8j62b7ZiTzke0DBYj9/////wFp8fr6+hIgDQAzDEao:7C08\n';

        // This is an attempt in manually generating the logo.
        // leaving it in just in case for whatever reason we need to get back to that.
        //         zplSoFar.value += `    ^FO10,157^GB40,40,2,,8^FS
        // ^FO15,162^GB30,30,2,,8^FS
        // ^FO20,167^GB20,20,2,,8^FS
        //
        // ^FO10,175^GB40,21,21,W^FS
        // ^FO10,157^GB22,40,21,W^FS
        //
        // ^FO26,172^GE8,8,4^FS
        //
        // ^FO10,157^GB2,40,2^FS
        // ^FO10,195^GB40,2,2^FS
        // ^FO10,157^GB19,2,2^FS
        // ^FO47,178^GB2,19,2^FS

        // ^FT16,194^A0N,14,15^FH^FDRFID^FS\n`;
        return {
            moveTo: _moveTo(zplSoFar),
            addRfid: _addRfid(zplSoFar),
            addComment: _addComment(zplSoFar, true),
            toString: _endLabelAndSerialize(zplSoFar)
        };
    };
}

function _addRfid(zplSoFar) {
    return function addRfid(hexValue) {
        if (zplSoFar.rfidAdded) {
            throw new Error('RFID already added to this label');
        }

        const operation = 'W'; //W = Encode the tag
        const format = 'H'; //H = Hexadecimal
        // RFID tags are encoded per "word"
        // 1 word = 16 bits = 4 hex characters
        const paddedHex = padToWord(hexValue);
        const startingBlock = 0;
        // 1 hex = 4 bit. 2 hex characters = 1 byte
        const numberOfBytesToWrite = paddedHex.length / 2;
        // const memoryBank = 1; //1 = EPC
        zplSoFar.value =
            zplSoFar.value +
            `    ${RFID_FIELD}${operation},${format},${startingBlock},${numberOfBytesToWrite}\n` + // set RFID specs
            `    ${FIELD_DATA}${paddedHex}\n` + //fill the field
            `${FIELD_END}\n`; //close the field
        zplSoFar.rfidAdded = true;
        return {
            moveTo: _moveTo(zplSoFar),
            addComment: _addComment(zplSoFar, true),
            toString: _endLabelAndSerialize(zplSoFar)
        };
    };
}

function _calibrateRfid(zplSoFar) {
    return function calibrateRfid() {
        const maxNumberOfVoid = 1;
        zplSoFar.value =
            zplSoFar.value +
            `   ${COMMENT}<CALIBRATION>\n` +
            `   ${RFID_SETUP}8,,,${maxNumberOfVoid}\n` +
            `   ${RFID_CALIBRATE}\n` +
            // Calibration somehow messes up the first label afterwards. So, actually "print" a label
            END_LABEL +
            START_LABEL +
            `\n${LABEL_HOME}0,0\n` +
            `${COMMENT} Spacer Label to force finishing up\n` +
            // draw a 1x1 pixel to make the printer know this is a real label
            `${FIELD_ORIGIN}0,0${GRAPHIC_BOX}1,1,1,b,1${FIELD_END}\n`;

        return {
            toString: _endLabelAndSerialize(zplSoFar)
        };
    };
}

function _addText(zplSoFar) {
    return function addText(value, lineEnd = '\\&') {
        zplSoFar.value =
            zplSoFar.value +
            `    ${FIELD_DATA}${value}${lineEnd}\n` + //fill the field, end with a line end
            `${FIELD_END}\n\n`; //close the field
        return {
            moveTo: _moveTo(zplSoFar),
            addComment: _addComment(zplSoFar, true),
            toString: _endLabelAndSerialize(zplSoFar)
        };
    };
}

function _endLabelAndSerialize(zplSoFar) {
    return function endLabelAndSerialize() {
        return zplSoFar.value + END_LABEL;
    };
}

function toStringNotAllowed() {
    throw new Error('Incomplete ZPL');
}

function padToWord(hex) {
    while (hex.length % 4 !== 0) {
        hex = '0' + hex;
    }
    return hex;
}

export default { createLabel };

/*
~SD30
^XA
^PR2,2,2
^PW406
^LL203
^FO68,162
    ^BY2,3,41
    ^BCN,41,Y,N,N,N
    ^FD>:barcode
^FS
^FO0,77
    ^A0N,28,28
    ^FB406,1,7,C
    ^FDtoplines toplines toplines\&
^FS
^XZ

would be generated by

zpl.createLabel(406,203)
  .moveTo(68,162)                         //coordinates are relative to top left corner
  .addBarcode('barcode', {subset: 'B'})
  .moveTo(0,77)                           //yes, we do NOT need to follow a specific order.
  .setFont(0,'N',28,28)
  .setAlignment('center')
  .addText('toplines toplines toplines')
  .toString()

*/
