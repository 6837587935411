import { TextContainer, InnerRow, RemoveButton, MessageText, StyledList, Row } from './styles';
import { createElement as rc } from 'react';
import useEventSink from '../../hooks/useEventSink';

const _p = {
    useEventSink
};
export const _private = _p;
const RowDetail = props => {
    const { item, rowIndex, onClick } = props;
    const [, publish] = _p.useEventSink();
    const onRemoveClick = e => {
        e.stopPropagation();
        publish(
            { _id: item._id },
            {
                verb: 'remove',
                namespace: 'application',
                relation: 'notification'
            }
        );
    };
    const onShowSelectedNotification = e => {
        e.stopPropagation();
        onClick(item);
    };
    // prettier-ignore
    return rc(InnerRow, {name: 'row-detail', altRow: rowIndex%2 === 0},
        rc(TextContainer, {onClick: onShowSelectedNotification},
            // These properties only matter to react native, the same values are set with CSS in web
            rc(MessageText, {numberOfLines: 2, ellipsizeMode: 'tail'}, item.message)
        ),
        rc(RemoveButton, {buttonStyle: 'round', color: 'transparent', icon: 'delete', onClick: onRemoveClick})
    );
};

/**
 *
 * @typedef {Object} notification
 * @property {string} message
 * @property {string} _id
 */

/**
 *
 * @param {object} props
 * @param {notification[]} notifications
 * @param {function} onShowSelectedNotification call this to display a notification by itself
 * @param {string} [className] a css class name - her to allow a parent to alter the default StyledList
 * @returns {JSX.Element}
 */
export default function NotificationList(props) {
    const { notifications, onShowSelectedNotification, className } = props;
    return rc(StyledList, {
        name: 'notifications-list',
        data: notifications,
        itemCount: notifications.length,
        className,
        onClick: onShowSelectedNotification,
        Row,
        RowDetail
    });
}
