import genericRule from '../../namespace/relation/doingValidate_namespace_relation';
import { errors as _errors } from 'lib_ui-primitives';

export default {
    ...genericRule,
    excludedNamespaceRelations: [],
    namespace: 'application',
    relation: 'savedFilter',
    logic: switchOrPassThrough
};

async function switchOrPassThrough({ data, context, ...rest }) {
    const { newRecord: { isShared } = {} } = data;

    try {
        if (isShared) {
            const sharedResult = await genericRule.logic({
                data,
                context: { ...context, relation: 'sharedFilter' },
                ...rest
            });
            return sharedResult;
        }
        const result = await genericRule.logic({ data, context, ...rest });
        return result;
    } catch (error) {
        // there is only ever 1 possible validation that could fail at this stage, and that is a duplicate error
        // so, we are save to just intercept any of those without further regards:
        if (
            error.constructor.name === 'ValidationError' ||
            error.message.includes('Select another Name or change the existing')
        ) {
            const message =
                'Another saved filter already exists with this name.  Please enter a different filter name.';
            throw new _errors.ValidationError(message, {});
        }
        throw error;
    }
}
