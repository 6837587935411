import { useEffect, useState } from 'react';
import useNetwork from './useNetwork';

export default function useDisabledForNetwork(hNode) {
    const { isConnected } = useNetwork();
    const [disabled, setDisabled] = useState(isConnected);
    const [hidden, setHidden] = useState(isConnected);
    const [message, setMessage] = useState();
    const { disableWhen, hideWhen } = hNode || {};

    useEffect(() => {
        if (isConnected) {
            setHidden(hideWhen?.includes?.('ONLINE'));
            const disablement = disableWhen?.find(x => x.disableWhen === 'ONLINE');
            setDisabled(!!disablement);
            if (disablement) {
                setMessage(disablement.message || 'Unavailable while online.');
            }
        } else {
            setHidden(hideWhen?.includes?.('OFFLINE'));
            const disablement = disableWhen?.find(x => x.disableWhen === 'OFFLINE');
            setDisabled(!!disablement);
            if (disablement) {
                setMessage(disablement.message || 'Unavailable while offline.');
            }
        }
    }, [isConnected, disableWhen, hideWhen]);
    return { disabledByNetwork: disabled, hiddenByNetwork: hidden, disabledMessage: message };
}
