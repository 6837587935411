import genericRule from '../../CRUD/namespace/relation/doingValidate_namespace_relation';

export default {
    verb: 'doingValidate',
    namespace: 'sensor',
    relation: 'tag',
    description: 'Validate the Encode Request',
    //this is the actual logic:
    logic: genericRule.logic
};
