/**
 * explicitly always allow records without any criteria
 * So, accept all records that either have the correct value on any of the paths,
 * or no values on any of the paths at all
 *
 * Having access to records without a value makes no difference here
 *
 * Sample data:
 * ```
 * {
 *    title: 'Inventory Q3',
 *    criteria:[
 *        { location:company: {_in: [{_id: '1234'}, {_id: '5678'}]} },
 *        { location:building: {_nin: [{_id: '9874'}]} },
 *        { location:location: {} }
 *    ]
 * }
 * ```
 *
 * The query needs to allow the following kind of records:
 * - Records for which there is no _in or _nin at all
 * - Records with an _in that has at least one of the filterValues
 * - Records with an _nin that at least does not have _one_ of the filterValues
 *
 * Sample Query:
 * ```
 * {
 *  $or:[
 *       { criteria: { $elemMatchAll: { 'location:location': { $exists: false } } } },
 *       { 'criteria.location:location._in': { $size: { $eq: 0 } } },
 *       { 'criteria.location:location._nin': { $size: { $eq: 0 } } },
 *       { 'criteria.location:location._in._id': { $in: ['allowedId'] } },
 *       {
 *           'criteria.location:location._nin': { $exists: true },
 *           'criteria.location:location._nin._id': { $not: { $contains: ['allowedId'] } }
 *       }
 *   ]
 * }
 * ```
 *
 * @param {Array<string>} paths - Array of paths to filter
 * @param {Array<{_id:string}>} filterValues - Array of values to filter by
 * @returns {object} - filter object
 */
export default function alwaysAllowUndefinedCriteriaFilter(paths, filterValues) {
    if (!filterValues?.length || !paths?.length) return undefined;
    if (!paths[0].startsWith('criteria.')) {
        throw new Error('alwaysAllowUndefinedCriteriaFilter: paths must start with "criteria."');
    }
    const actualValues = filterValues.map(({ _id }) => _id).filter(_id => _id !== '0'.repeat(24));

    // sample path: `criteria.location:building._in._id`
    // keyProperty: `location:building`
    const [keyProperty] = paths[0].split('.').slice(1);
    // operators: ['_in', '_nin']
    const operators = paths.map(path => path.split('.').slice(2)[0]);

    const noneExistClause = {
        $or: [
            // EITHER:
            //     there is no criteria with a `keyProperty` value
            {
                criteria: {
                    $elemMatchAll: {
                        [keyProperty]: { $exists: false }
                    }
                }
            },
            // OR:
            //      the criteria exists, but none of the operators have values
            //      (We treat the absence of any criteria ass everything is accepted)
            ...operators.map(op => ({
                [`criteria.${keyProperty}.${op}`]: { $size: { $eq: 0 } }
            }))
        ]
    };
    if (!actualValues?.length) return noneExistClause;

    // OR:
    //     When there are criteria;
    const otherClauses = operators
        .map(op => {
            //     if it has a negative operator (_ne or _nin):
            //         there should be at least one of the filter value NOT included in that list.
            if (op.startsWith('_n')) {
                return {
                    // $contains in lokijs checks if the array in the given field contains all the values in the given array
                    // if we invert that with $not, it tells us at least one value in the filter values was NOT included.
                    [`criteria.${keyProperty}.${op}`]: { $exists: true },
                    [`criteria.${keyProperty}.${op}._id`]: {
                        $not: { $contains: actualValues }
                    }
                };
            }
            //     If it has a positive operator (_eq or $in):
            //         it needs to include at least one of the filter values
            return {
                [`criteria.${keyProperty}.${op}._id`]: { $in: actualValues }
            };
        })
        .filter(Boolean);
    return { $or: [...noneExistClause.$or, ...otherClauses] };
}
