import logging from '@sstdev/lib_logging';
import { executeForEach } from '../registeredServiceQueues';
import { globalConfig, constants } from 'lib_ui-services';
import { COMMON_COLOR_SCHEME } from 'lib_ui-primitives';
import cloneDeep from 'lodash/cloneDeep';
import getAllFeatureFlags from '../../../utilities/getAllFeatureFlags';

const _p = {
    executeForEach,
    asyncConfirmationModal
};

export const _private = _p;
export default {
    verb: 'doingCreate',
    namespace: 'sensor',
    relation: 'tag',
    description: 'Interact with native code to Read a tag for association',
    useCaseIds: [constants.useCaseIds.ASSET_TRACKING, constants.useCaseIds.AMERICAN_WATER_ASSET_TRACKING],
    prerequisites: [
        {
            //lookup item based on assetNo === newRecord.value
            context: {
                verb: 'get',
                namespace: 'item',
                relation: 'item',
                type: 'find'
            },
            query: ({ data }) => {
                if (!data?.newRecord?.value) {
                    //avoid lookup, as there is nothing to look for. Throw error to abort prerequisite workflow
                    // (does not abort entire workflow!)
                    throw new Error('No `value` passed in. Unable to find asset to associate.');
                }
                return {
                    assetNo: data.newRecord.value,
                    'meta.deleted': { $exists: false }
                };
            }
        }
    ],
    logic,
    onError
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @template T
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data: { newRecord }, prerequisiteResults, context, dispatch }) {
    if (!newRecord?.value) {
        throw new Error('Asset ID cannot be empty.');
    }

    // don't catch errors, as we want them to be displayed on the form.
    const sensorType = newRecord.sensorType || constants.sensorTypes.RFID;

    // we are relying on doingNew_sensor_tag to have set the proper scanConfig (with scanType = 'Encode')
    // and on doingChange_sensor_tag-AT to
    const response = await _p.executeForEach('scanDirect', { sensorType }, context);
    const sensorResult = response.find(r => r.type === sensorType)?.result;

    const itemToUpdate = prerequisiteResults?.[0]?.result;

    if (Array.isArray(sensorResult)) {
        let requestedResult = sensorResult.reduce((soFar, read) => {
            if (read.tagId) {
                //dedupe reads:
                soFar[read.tagId] = read;
            }
            return soFar;
        }, {});
        requestedResult = Object.values(requestedResult);
        //validate read results
        if (requestedResult.length < 1) {
            throw new Error(`Assignment Failed: No ${sensorType} tag detected`);
        }

        if (requestedResult.length > 1) {
            logging.warn(
                `[${sensorType}] found tags ${requestedResult.map(r => r.tagId).join(', ')} to associate with Assets ${
                    newRecord.value
                }`
            );
            throw new Error(`Assignment Failed: More than 1 ${sensorType} tag detected`);
        }

        const displayInAscii = getAllFeatureFlags(context).includes('displayInAscii') ?? false;
        let tagId = requestedResult[0].tagId;
        if (displayInAscii) {
            // strip null characters if this value is known to translate to ascii
            tagId = tagId.replace(/^(00)+/, '').replace(/(00)+$/, '');
        } else {
            // otherwise, strip leading zeros (they are not significant digits)
            tagId = tagId.replace(/^0+/, '');
        }
        const { result: tagLookupResult } = await dispatch(
            { tagId },
            { verb: 'get', namespace: 'item', relation: 'item', type: 'find' },
            true
        );
        // If this tag is already assigned to a different asset.
        if (tagLookupResult.length > 0) {
            const assetNo = tagLookupResult[0].assetNo;
            // Users cannot reassign tags to a different asset.  Must be higher access level.
            if (isUser(context)) {
                throw new Error(`This tag is already assigned to asset ${assetNo}.`);
            }
            // Trying to assign the asset to the same tag.
            if (assetNo === itemToUpdate[0]?.assetNo) {
                throw new Error(`This tag is already assigned to asset ${assetNo}.`);
            }
            const shouldProceed = await _p.asyncConfirmationModal(dispatch, assetNo, itemToUpdate[0]?.assetNo);
            if (!shouldProceed) {
                throw new Error('Tag assignment cancelled.');
            }

            await Promise.all(
                tagLookupResult.map(async item => {
                    const newRecord = cloneDeep(item);
                    delete newRecord.tagId;
                    await dispatch(
                        { oldRecord: item, newRecord, propertiesToUnset: [{ propertyName: 'tagId' }] },
                        { verb: 'update', namespace: 'item', relation: 'item' },
                        true
                    );
                })
            );
        }
        if (!itemToUpdate?.length) {
            const redirectFinishedAction = () => {
                // Create the new asset
                dispatch(
                    { newRecord: { tagId, assetNo: newRecord.value } },
                    { verb: 'new', namespace: 'item', relation: 'item' }
                );
            };
            const reboundRoute = {
                reboundRoute: context.routePath,
                failureAction: async () => {
                    // Put the tagId back in any records it was removed from.
                    try {
                        await tagLookupResult.map(async item => {
                            const newRecord = cloneDeep(item);
                            delete newRecord.tagId;
                            await dispatch(
                                { oldRecord: newRecord, newRecord: item },
                                { verb: 'update', namespace: 'item', relation: 'item' },
                                true
                            );
                        });
                    } catch (err) {
                        logging.error(`Failure to replace tagId ${tagId} in asset ${newRecord.assetNo}`, err);
                    }
                },
                reboundEvents: [
                    { verb: 'create', namespace: 'item', relation: 'item', status: 'success' },
                    { verb: 'create', namespace: 'item', relation: 'item', status: 'failure' },
                    { verb: 'cancel', namespace: 'item', relation: 'item', status: 'success' },
                    { verb: 'cancel', namespace: 'item', relation: 'item', status: 'failure' }
                ],
                failureEvents: [
                    { verb: 'create', namespace: 'item', relation: 'item', status: 'failure' },
                    { verb: 'cancel', namespace: 'item', relation: 'item', status: 'success' },
                    { verb: 'cancel', namespace: 'item', relation: 'item', status: 'failure' }
                ]
            };
            // Redirect to Update page
            const match = context.routePath.match(/\/(.+)\/(.*)\/(.*)/);
            let url;
            if (match[1] === 'g') {
                url = `/${match[1]}/${match[2]}/Update`;
            } else {
                throw new Error(`Unexpected format for route path (${context.routePath}).`);
            }
            dispatch(
                { to: url, reboundRoute, redirectFinishedAction },
                { verb: 'redirect', namespace: 'application', relation: 'route' }
            );
        } else {
            await dispatch(
                { oldRecord: itemToUpdate[0], newRecord: { ...itemToUpdate[0], tagId } },
                { verb: 'update', namespace: 'item', relation: 'item' },
                true
            );
            // Notify user of success
            logging.debug(`[${sensorType}] Associating ${newRecord.value} to ${tagId} succeeded`);
            dispatch(
                { isError: false, message: 'Tag Association Successful!', timeout: globalConfig().notificationTimeout },
                { verb: 'pop', namespace: 'application', relation: 'notification' }
            );
        }
    } else {
        const message = response[0].result || 'Unable to associate tags at this time';
        throw new Error(message);
    }
}

/**
 * @param {{
 *      error: Error;
 *      data: T;
 *      context: Context;
 *      dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>,
 *      workflowStack: WorkflowStack[]
 * }} parameters
 * */
function onError({ error, dispatch }) {
    //Errors don't work on Single Record forms, and I don't have time to figure out why.
    dispatch(
        {
            isError: true,
            message: error.message || error,
            timeout: globalConfig().notificationTimeout
        },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );

    // throw error;
    // return data;
}

function isUser(context) {
    return context.user.role.title === constants.WELL_KNOWN_ROLES.USER;
}

async function asyncConfirmationModal(dispatch, oldAssetNo, newAssetNo) {
    return new Promise(resolve => {
        const message2 =
            newAssetNo != null
                ? `Are you sure you want to assign it to new asset ${newAssetNo}?`
                : 'Are you sure you want to assign it to a new asset?';
        dispatch(
            {
                message: [`This tag is already assigned to asset ${oldAssetNo}.`, message2],
                okButtonText: 'YES',
                icon: 'warning',
                iconColor: COMMON_COLOR_SCHEME.warn,
                okAction: () => resolve(true),
                cancelAction: () => resolve(false)
            },
            { verb: 'confirm', namespace: 'application', relation: 'user' }
        );
    });
}
