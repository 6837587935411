import { constants } from 'lib_ui-services';
import lodash from 'lodash';
const { omit } = lodash;

export default {
    verb: 'willCreate',
    namespace: 'item',
    relation: 'transaction',
    description: 'clean off the shift summary',
    priority: 50,
    useCaseIds: [constants.useCaseIds.FREIGHT_RUNNER],

    //this is the actual logic:
    logic: didCreate
};

async function didCreate({ data }) {
    let { newRecord } = data || {};
    newRecord = omit(newRecord, ['totalScans', 'minutesPerPallet', 'palletsPerMinute']);

    return { ...data, newRecord };
}
