import { createElement as rc, useState, useEffect } from 'react';
import lodash from 'lodash';
const { startCase } = lodash;
import { Image, styled, View, Text } from 'lib_ui-primitives';
import { useTheme } from 'styled-components';

import { getLogo, TRANSPARENT_PIXEL } from '../../images';
import useBrand from '../hooks/useBrand';

export const variants = {
    COLOR: 'color',
    WHITE: 'white'
};

const TopToBottom = styled(View)`
    flex-direction: column;
`;

const ResponsiveImage = styled(Image).attrs(props => ({
    name: startCase(`${props.brand} Logo`),
    alt: startCase(`${props.variant} ${props.brand} Logo`)
}))`
    width: 100%;
    max-width: 175px;
`;

const Punchline = styled(Text).attrs({ name: 'PunchLine' })`
    align-self: center;
    color: ${({ theme }) => theme.colorScheme?.['accent-1']};
    font-weight: bold;
    font-style: italic;
    font-size: 13px;
`;

const _p = {
    useBrand,
    useTheme
};
export const _private = _p;

/**
 * @typedef {Object} Props
 * @property {'color'|'white'} [variant]
 */
/**
 * @type {import('react').FC<Props>} Logo
 */
const Logo = props => {
    const { variant = variants.WHITE, addSlogan = false } = props;
    const brand = _p.useBrand();
    const { darkMode } = _p.useTheme();
    const [src, setImage] = useState(TRANSPARENT_PIXEL);
    const [slogan, setSlogan] = useState('');

    useEffect(() => {
        let allowStateUpdates = true;
        const getImage = async () => {
            const image = await getLogo(brand, variant, darkMode);
            if (allowStateUpdates) {
                setImage(image);
                if (brand === 'redbeam' && addSlogan) setSlogan('Tracking Made Easy®');
            }
        };
        getImage();
        return () => (allowStateUpdates = false);
    }, [addSlogan, brand, darkMode, variant]);

    // prettier-ignore
    return rc(TopToBottom, null,
        rc(ResponsiveImage, { src, brand, variant }),
        rc(Punchline, null, slogan)
    );
};

export default Logo;
