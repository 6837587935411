import { assign, actions as xactions } from 'xstate';
const { raise } = xactions;

// These are simple actions to add context data to the xstate state
// using the assign action method.
// The more complex actions are passed into the machine from the
// MarkText component
export const actions = {
    setMarkText: assign((context, event) => ({ markText: event.markText, sensorType: event.sensorType })),
    clearMarkText: assign(() => ({ markText: '' })),
    setReadStatus: assign((context, event) => {
        return { readsExist: event.readsExist, filteredReadsExist: event.filteredReadsExist };
    }),
    setLocationId: assign((context, event) => {
        return { locationId: event.locationId };
    })
    //These actions are implemented/passed in in MarkRecord/index.js:
    // markAsFound
    // markAllAsFound
    // clearMarkTextFilter
    // applyFilter
    // logError
};

const machineConfig = {
    id: 'markRecord',
    initial: 'start',
    context: {},
    states: {
        start: {
            entry: [raise('checkIfOpenModal')],
            on: {
                checkIfDisplayHelpText: {
                    cond: context => {
                        return context.native && context.locationId == null && context.readsExist;
                    },
                    actions: ['displayHelpText']
                },
                // If a user scans and a location has been selected, then display the modal
                // modal screen containing the scans.
                checkIfOpenModal: {
                    target: 'modal',
                    cond: context => {
                        return context.native && context.locationId != null && context.readsExist;
                    }
                },
                inputFocused: [
                    {
                        target: 'modal',
                        // Open the input modal if this is a native mobile view and reads exist
                        // Do not reopen the modal if the user just closed it.
                        cond: (context, event, meta) => {
                            return (
                                context.native &&
                                meta.state.event.type !== 'closeModal' &&
                                meta.state.event.type !== 'done.invoke.resetReads' &&
                                context.mobile &&
                                context.locationId != null
                            );
                        }
                    },
                    {
                        // If this is mobile and the user just closed the modal
                        cond: (context, event, meta) => {
                            return (
                                (meta.state.event.type === 'closeModal' ||
                                    meta.state.event.type === 'done.invoke.resetReads') &&
                                context.mobile
                            );
                        },
                        // Prevent the input from being focused -- this allows the user to open the
                        // modal again (if desired) by clicking on the input.
                        actions: (context, event) => {
                            event.blur();
                        }
                    }
                ],
                markTextChanged: {
                    actions: ['setMarkText', 'applyFilter']
                },
                markAsFound: {
                    target: 'detail',
                    actions: ['markAsFound', 'clearMarkTextFilter', 'clearMarkText']
                },
                locationChange: {
                    actions: ['setLocationId', raise('checkIfOpenModal')]
                },
                locationCleared: {
                    actions: ['setLocationId', 'clearMarkTextFilter', 'clearMarkText', raise('checkIfOpenModal')]
                },
                readCountChange: {
                    /*Each of these raised events has conditions which determine if a modal displays or if the help
                    message displays, and those actions are mutually exclusive.
                    The conditions for displaying the modal include the presence of a location, and the conditions for
                    displaying the help message toast include the absence of a location.
                    */
                    actions: ['setReadStatus', raise('checkIfOpenModal'), raise('checkIfDisplayHelpText')]
                }
            }
        },
        modal: {
            on: {
                changeAbandonConfirmationFailed: {
                    target: 'detail'
                },
                unknownTagClicked: {
                    target: 'detail'
                },
                knownTagClicked: {
                    target: 'detail'
                },
                markTextChanged: {
                    actions: ['setMarkText', 'applyFilter']
                },
                markAsFound: {
                    target: 'openingDetail',
                    actions: ['markAsFound', 'clearMarkTextFilter', 'clearMarkText']
                },
                closeModal: {
                    target: 'closingModal'
                },
                readCountChange: {
                    actions: ['setReadStatus']
                },
                markAllAsFound: {
                    target: 'markingAllAsFound'
                },
                markAllSuccess: {
                    target: 'closingModal'
                },
                reset: {
                    target: 'closingModal'
                }
            }
        },
        // Create a separate state so that modal will close briefly
        markingAllAsFound: {
            invoke: {
                id: 'markAllAsFound',
                src: 'markAllAsFound',
                onDone: {
                    target: 'closingModal',
                    actions: ['clearMarkTextFilter', 'clearMarkText']
                },
                onError: {
                    target: 'modal',
                    actions: ['logError']
                }
            }
        },
        closingModal: {
            invoke: {
                id: 'resetReads',
                src: 'resetReads',
                onDone: 'start',
                onError: {
                    target: 'start',
                    actions: ['logError']
                }
            },
            on: {
                readCountChange: {
                    actions: ['setReadStatus']
                },
                inputFocused: [
                    {
                        // If this is mobile and the user just closed the modal
                        cond: (context, event, meta) => {
                            return meta.state.event.type === 'closeModal' && context.mobile;
                        },
                        // Prevent the input from being focused -- this allows the user to open the
                        // modal again (if desired) by clicking on the input.
                        actions: (context, event) => {
                            event.blur();
                        }
                    }
                ]
            }
        },
        openingDetail: {
            invoke: {
                id: 'resetReads',
                src: 'resetReads',
                onDone: 'detail',
                onError: {
                    target: 'detail',
                    actions: ['logError']
                }
            },
            on: {
                readCountChange: {
                    actions: ['setReadStatus']
                }
            }
        },
        detail: {
            on: {
                leaveDetail: [
                    {
                        cond: context => {
                            //after native barcode or rfid scan:
                            return context.native && context.filteredReadsExist;
                        },
                        target: 'modal'
                    },
                    {
                        cond: context => {
                            //this happens after a datawedge scan
                            return !context.native && context.filteredReadsExist;
                        },
                        target: 'start'
                    },
                    {
                        cond: context => {
                            //after manual entry on the web
                            return !context.filteredReadsExist;
                        },
                        target: 'start'
                    }
                ],
                reset: {
                    target: 'start'
                }
            }
        }
    }
};
export default machineConfig;
