import { errors as _errors } from 'lib_ui-primitives';
import metaSettingWillCreate from '../../namespace/relation/willCreate_namespace_relation';

export default {
    verb: 'willCreate',
    namespace: 'metadata',
    relation: 'useCaseDetail',
    description: 'Pre fill title and fix version',
    priority: metaSettingWillCreate.priority - 2, //after the regular willCreate
    //this is the actual logic:
    logic: willCreate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willCreate({ data, dispatch }) {
    const { newRecord } = data;
    let {
        major,
        minor,
        patch,
        version: oldVersion,
        'metaui:useCase': useCase,
        //major change reasons
        reorganizeMenu,
        majorChanges,
        userRetrainingRequired,
        //minor changes reasons
        reorganizeForms,
        addPages,
        addFeatures,
        otherChanges,
        userLikelyNotices,
        //patch change reasons
        addFields,
        fixTypos,
        fixLayout,
        userWontNotice
    } = newRecord;

    let change = '';
    if (reorganizeMenu || majorChanges || userRetrainingRequired) {
        change = 'major';
        major++;
        minor = 0;
        patch = 0;
    } else if (reorganizeForms || addPages || addFeatures || otherChanges || userLikelyNotices) {
        change = 'minor';
        minor++;
        patch = 0;
    } else if (addFields || fixTypos || fixLayout || userWontNotice) {
        change = 'patch';
        patch++;
    } else {
        const message = 'Unable to create a branch without indication of the nature of the change.';
        dispatch(
            { message, timeout: 5000, addToList: false, isError: true },
            {
                verb: 'pop',
                namespace: 'application',
                relation: 'notification'
            }
        );
        throw new _errors.ValidationError(message, {});
    }

    //check if the new version does not exist yet
    let response = await dispatch(
        { query: { major, minor, patch, 'metaui:useCase._id': useCase._id } },
        { verb: 'get', namespace: 'metadata', relation: 'useCaseDetail', type: 'find' },
        true
    );
    let rebumped = false;
    while (response?.result?.length) {
        response = await new Promise((resolve, reject) => {
            let majorNext = major;
            let minorNext = minor;
            let patchNext = patch;
            switch (change) {
                case 'major':
                    majorNext++;
                    break;
                case 'minor':
                    minorNext++;
                    break;
                case 'patch':
                    patchNext++;
                    break;
            }

            dispatch(
                {
                    message: [
                        `Your intended changes would be a ${change} update, but there is already a ${
                            useCase.title || ''
                        } version with version number ${response.result[0].version}.`,
                        `Should we use ${majorNext}.${minorNext}.${patchNext}.0 instead? `,
                        'Note that skipping over versions in this way might lead to lost changes.'
                    ],
                    okButtonText: 'OK',
                    cancelButtonText: 'CANCEL',
                    okAction: async () => {
                        major = majorNext;
                        minor = minorNext;
                        patch = patchNext;
                        rebumped = true;
                        let response = await dispatch(
                            { query: { major, minor, patch, 'metaui:useCase._id': useCase._id } },
                            { verb: 'get', namespace: 'metadata', relation: 'useCaseDetail', type: 'find' },
                            true
                        );
                        resolve(response);
                    },
                    cancelAction: () => {
                        const message =
                            'Branching aborted. Please update the nature of your change, or cancel out of branching.';
                        dispatch(
                            { message, timeout: 5000, addToList: false, isError: false },
                            {
                                verb: 'pop',
                                namespace: 'application',
                                relation: 'notification'
                            }
                        );
                        reject(new Error(message));
                    }
                },
                { verb: 'confirm', namespace: 'application', relation: 'user' }
            );
        });
    }

    const title = `${useCase.title} v${major}.${minor}.${patch}`;
    const version = `${major}.${minor}.${patch}.0`;
    //check new version number with user
    const okToChange =
        rebumped ||
        (await new Promise(resolve => {
            dispatch(
                {
                    message: [
                        'Based on your indicated intended changes,',
                        `we will update the version from ${oldVersion} to ${version}.`,
                        'To change this, cancel, and change the intend of your changes.',
                        'Continue?'
                    ],
                    okButtonText: 'OK',
                    okAction: () => resolve(true),
                    cancelAction: () => resolve(false)
                },
                { verb: 'confirm', namespace: 'application', relation: 'user' }
            );
        }));

    if (!okToChange) {
        const message = 'Branching aborted. Please update the nature of your change, or cancel out of branching.';
        dispatch(
            { message, timeout: 5000, addToList: false, isError: false },
            {
                verb: 'pop',
                namespace: 'application',
                relation: 'notification'
            }
        );
        throw new Error(message);
    }
    data.offlineAction.create.newRecord = {
        ...data.offlineAction.create.newRecord,
        major,
        minor,
        patch,
        build: 0,
        version,
        title
    };

    return {
        ...data,
        newRecord: { ...newRecord, major, minor, patch, build: 0, version, title }
    };
}
