import { lazy, createElement as rc, Suspense } from 'react';
import { Text } from 'lib_ui-primitives';

//Lazy load just in case camera stuff loads slow
export default function HavenProxy(props) {
    const Haven = lazy(() => import(/* webpackPrefetch: true */ './InnerHaven'));
    const fallback = rc(Text, null, 'Loading...');
    // prettier-ignore
    return rc(Suspense, {fallback},
        rc(Haven, props)
    );
}
