// This is the 'new' api replacing 'getAll'
// where the filtering is done with the faster lokijs syntax rather
// than using a reducing function.
export default database => (payload, context) => {
    const { namespace, relation } = context;
    const db = database.relationDb(namespace, relation);
    return db.find(payload).then(result => {
        return database.processSuccess(result, payload, context);
    });
};
