import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { constants, metadata } from 'lib_ui-services';
import { hooks } from 'lib_ui-primitives';
import Button from './Button';
import DropDownButton from '../_abstractComponent/DropDownButton';
import { useDbView, useNetwork, useNavigationSelection } from '../../hooks';
import useGetDbViewName from '../../hooks/useDbView/useGetDbViewName';
import { _private as useColumnCustomization } from '../list/useColumnCustomization';

const { useBbState, useRouter } = hooks;

const _p = {
    useBbState,
    useRouter,
    useGetDbViewName,
    useDbView,
    useDisplayedColumns,
    getCustomizationStateName: useColumnCustomization.getCustomizationStateName,
    getRelationMetadata: metadata.getRelationMetadata
};
export const _private = _p;

const exportTypes = [
    { title: 'Excel 2007+ XML (XLSX)', type: 'xlsx' },
    { title: 'Excel 97-2004 Workbook (XLS)', type: 'xls' },
    { title: 'Comma Separated Values (CSV)', type: 'csv' },
    { title: 'HTML Document', type: 'html' },
    { title: 'PDF Document', type: 'pdf' }
];

function DownloadButton(props) {
    const {
        hNode: { iconName = 'cloud_download', includeExportType, namespace, relation, children, id, filePrefix },
        data
    } = props || { hNode: {} };

    const viewName = _p.useGetDbViewName(namespace, relation, props.viewName);
    const { isConnected } = useNetwork();
    const { limitSyncSize } = _p.getRelationMetadata(namespace, relation);

    // Get the navigation selection context to include in the export if needed
    const navigationSelection = useNavigationSelection();

    // Get the displayed columns for the export
    const displayedColumns = _p.useDisplayedColumns(namespace, relation, children);
    const propsTitle = props.title || props.hNode?.title;
    const altText = includeExportType ? propsTitle || 'Select Format to Export To' : propsTitle || 'Export Data';

    // Common props for both the Button (when includeExportType is false) and the DropDownButton (when includeExportType is true)
    const commonProps = {
        id,
        alt: altText,
        hoverText: altText,
        ...props,
        ...(includeExportType ? {} : { buttonStyle: 'IconAction', iconName, forAction: 'export' }),
        actionPayload: {
            //the file name prefix
            filePrefix,
            //to allow retrieving the view when generating the export
            viewName,
            //just in case the export data was passed in explicitly (e.g. from charts)
            data,
            //what properties to include in the export
            columns: displayedColumns,
            //the navigation selection context (for multi-inventory exports)
            navigationSelection,
            // needed for the export to know how to fetch the data
            limitSyncSize,
            // the network status
            isConnected
        }
    };

    // If includeExportType is true, then we want to render a DropDownButton with a list of export types
    return includeExportType
        ? rc(
              DropDownButton,
              { ...commonProps, icon: iconName },
              exportTypes.map(type => {
                  // Map the export types to the DropDownButton.MenuItem component
                  return rc(DropDownButton.MenuItem, {
                      hNode: { forAction: 'export', namespace, relation, ...type },
                      actionPayload: {
                          ...commonProps.actionPayload
                      },
                      key: type.type
                  });
              })
          )
        : rc(Button, { ...commonProps });
}

/**
 * Get the displayed columns for the export
 * @param {string} namespace
 * @param {string} relation
 * @param {Array} columns
 * @returns {Array} The displayed columns for the export with their sequence and hidden properties
 */
function useDisplayedColumns(namespace, relation, columns) {
    const router = _p.useRouter();
    const pathname = _p.getCustomizationStateName(router.location.pathname, namespace, relation);

    const [storedColumnHNodes] = _p.useBbState(columns, pathname, {
        duration: constants.retention.DURATION.ALWAYS,
        scope: constants.retention.SCOPE.GLOBAL,
        visibility: constants.retention.VISIBILITY.PER_BROWSER
    });

    return columns.map(column => {
        // Find the stored column that matches the current column's title
        const storedColumnIndex = storedColumnHNodes.findIndex(sc => sc.title === column.title);
        const storedColumn = storedColumnHNodes[storedColumnIndex];
        // We need the stored column's index (for the sequence) and hidden property
        // If a stored column is found, merge the stored column with the current column
        if (storedColumn) {
            return { ...column, ...storedColumn, sequence: storedColumnIndex };
        }
        return column;
    });
}

DownloadButton.propTypes = {
    hNode: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        namespace: PropTypes.string,
        relation: PropTypes.string,
        /** whether or not to display export type dropdown */
        includeExportType: PropTypes.bool,
        /** OPTIONAL: if undefined, prettyRelationNme or `export_{relation}` will be used */
        filePrefix: PropTypes.string
    }).isRequired,
    /** OPTIONAL: to have the export pull the data directly from the lokiView */
    viewName: PropTypes.string,
    /** OPTIONAL: to pass data in directly */
    data: PropTypes.array
};

export default DownloadButton;
