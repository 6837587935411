import { useEffect, useState, useRef } from 'react';
import lodash from 'lodash';
const { get } = lodash;
import { ObjectId } from 'lib_ui-services';
import useEventSink from '../../../hooks/useEventSink';
import getColumnCell from './getColumnCell';

const _p = { getColumnCell };
export const _private = _p;

/**
 * A custom React hook that updates a calculated column value.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Object} params.record - The initial data record.
 * @param {Object} params.columnHNode - Configuration object for the column.
 * @param {Object} [params.style] - Style object for custom styling.
 * @param {boolean} [params.skipUndefined] - Whether to skip rendering if the value is undefined.
 * @param {Function} [params.platformSpecificValueLookup] - Function to look up platform-specific values.
 * @param {Object} [params.theme] - Theme object for styling.
 * @param {number} [params.width] - Width of the column.
 * @param {Function} [params.setWidth] - Function to set the width of the column.
 * @param {Array} [params.featureFlags] - Feature flags for conditional rendering.
 * @param {Object} [params.activeRecord] - Active record data.
 * @param {Object} [params.splashRecord] - Splash record data.
 * @param {Object} [params.navigationSelection] - Navigation selection data.
 * @returns {React.Component|null} - A React component representing the calculated column cell or null if conditions are not met.
 *
 * This hook listens for changes in specified dependencies and updates the value of a "calculated column" accordingly.
 * It subscribes to events via the `useEventSink` hook and publishes changes when dependencies change.
 * The hook is specifically designed to work with a column that has a `hNodeType` of `Calculated`.
 */

export default function useCalculatedColumn({
    record,
    columnHNode,
    style,
    skipUndefined,
    platformSpecificValueLookup,
    theme,
    width,
    setWidth,
    featureFlags,
    activeRecord,
    splashRecord,
    navigationSelection
}) {
    const [subscribe, publish] = useEventSink();
    const [item, setItem] = useState(record);

    // This should come from the columnHNode for a new "calculated column" type
    // Leaving defaults in to avoid breaking existing depreciation columns.
    const {
        hNodeType,
        propertyName,
        namespace = 'item',
        relation = 'item',
        calculationDependencies = ['acquisitionDate', 'cost', 'recoveryPeriod', 'scrapValue']
    } = columnHNode;

    const dependencyValues = calculationDependencies.map(propertyName => get(item, propertyName));
    // Use an unique objectId to separate out the row messages.
    const eventId = useRef(new ObjectId().toString());

    useEffect(() => {
        if (propertyName !== 'depreciatedValue' && hNodeType !== 'Calculated') return;

        let allowStateUpdate = true;
        const unsubscribe = subscribe(
            { verb: 'didGet', namespace, relation, status: 'success', eventId: eventId.current },
            payload => {
                const {
                    result: [updatedItem]
                } = payload;

                const currentValue = get(item, propertyName);
                const newValue = get(updatedItem, propertyName);
                if (allowStateUpdate && item._id === updatedItem._id && currentValue !== newValue) {
                    setItem(updatedItem);
                }
            }
        );
        return () => {
            unsubscribe();
            allowStateUpdate = false;
        };
    }, [subscribe, propertyName, namespace, relation, item, hNodeType]);

    // ** Useful for debugging when calculations occur. **
    // const depsObject = {};
    // calculationDependencies.forEach((key, i) => {
    //     depsObject[key] = dependencyValues[i];
    // });
    // const deps = useWhatChanged({ propertyName, namespace, relation, ...depsObject });

    // When the dependency values change, initiate a new calculation.
    useEffect(() => {
        if (propertyName !== 'depreciatedValue' && hNodeType !== 'Calculated') return;

        publish({ result: [item] }, { verb: 'didGet', namespace, relation, eventId: eventId.current });
        // calculation dependencies are passed from metadata
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyName, namespace, relation, ...dependencyValues]);

    if (columnHNode.propertyName !== 'depreciatedValue' && hNodeType !== 'Calculated') return null;

    return _p.getColumnCell({
        record: item,
        columnHNode,
        style,
        skipUndefined,
        platformSpecificValueLookup,
        theme,
        width,
        setWidth,
        featureFlags,
        activeRecord,
        splashRecord,
        navigationSelection
    });
}
