import { session } from 'lib_ui-services';
export const _private = {
    saveProfile: session.cache.saveProfile,
    getProfile: session.cache.getProfile
};
export default {
    verb: 'didCreate',
    namespace: 'security',
    relation: 'profile',
    type: 'setOfflinePin',
    logic
};

async function logic({ data }) {
    const { encryptedPin, salt } = data ?? {};
    if (encryptedPin == null || salt == null) {
        throw new Error('Encrypted PIN and salt are required, but missing.');
    }
    // update the local cache with the new pin
    const profile = await _private.getProfile();
    if (profile == null) {
        throw new Error('Profile not found');
    }
    profile.offlinePin = encryptedPin;
    profile.salt = salt;
    await _private.saveProfile(profile);
}
