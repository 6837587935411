import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { NavTab as _NavTab, styled, webOnlyStyles, fromTheme } from 'lib_ui-primitives';
import useHiddenForViewPort from '../../hooks/useHiddenForViewPort';
import useIsInActiveRoute from '../../hooks/useIsInActiveRoute';
import useDisabledForNetwork from '../../hooks/useDisabledForNetwork';

let NavTab = styled(_NavTab)`
    display: inline-block;
    margin-top: ${fromTheme('viewMargin')};
    padding: 10px 24px;
    color: ${props => (props.disabled ? fromTheme('disabledFontColor')(props) : fromTheme('defaultFontColor')(props))};
    text-decoration: none;
    font-weight: bold;
    background-color: ${({ $tabIsActive, theme }) => ($tabIsActive ? theme.backgroundColor : 'transparent')};
    border-top-width: ${({ $tabIsActive }) => ($tabIsActive ? '3px' : '1px')};
    border-top-color: ${({ $tabIsActive, theme }) => ($tabIsActive ? theme.colorScheme.success : '#00000000')};
    border-bottom-width: 0;
    border-bottom-color: transparent;
`;
NavTab = webOnlyStyles(NavTab)`
    vertical-align: top;
    border-top-style: solid;
    cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
    &:hover {
        background-color: ${props => (props.disabled ? 'transparent' : fromTheme('backgroundColor')(props))};
    }
`;

/**
 * navHeadings implement (Route) Navigational Support ONLY.
 * In this case, it provides the individual tabs at the top of the page where the tabs show
 * @param {*} props
 * @returns
 */
function TabNavHeading(props) {
    const {
        currentRoute,
        hNode: { id, title },
        hNode
    } = props;

    const hiddenForViewport = useHiddenForViewPort(hNode);
    const { disabledByNetwork, hiddenByNetwork, disabledMessage } = useDisabledForNetwork(hNode);
    const $tabIsActive = useIsInActiveRoute(props);
    if (hiddenForViewport || hiddenByNetwork) {
        return null;
    }

    // prettier-ignore
    return rc(NavTab, { $tabIsActive, id: id, key: id, to: currentRoute, disabled: disabledByNetwork, title: disabledMessage },
        title
    );
}

TabNavHeading.propTypes = {
    currentRoute: PropTypes.string,
    children: PropTypes.array,
    hNode: PropTypes.object,
    hiddenForViewport: PropTypes.bool
};

export default TabNavHeading;
