import notHandledHere from './notHandledHere';
import { dbViews } from 'lib_ui-services';

const _p = { dbViews };
export const _private = _p;

export default {
    verb: 'doingGetMore',
    excludedNamespaceRelations: notHandledHere,
    description: 'Resync the current relation',
    //this is the actual logic:
    logic: doingGetMore
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingGetMore({ data, context, dispatch }) {
    const { dbViewName } = data;
    const { namespace, relation } = context;
    const dbView = await _p.dbViews.getDbView(namespace, relation, dbViewName);
    const results = await dbView.getNextPage();
    if (results == null || results.length === 0) {
        const message = 'No additional records were found.';
        dispatch(
            { message, timeout: 5000, addToList: false, isError: false },
            {
                verb: 'pop',
                namespace: 'application',
                relation: 'notification'
            }
        );
    }
}
