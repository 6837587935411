import { useMemo, createElement as rc, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import webOnlyStyles from '../../webOnlyStyles';

import { shouldShowTimeInput } from './dateHelpers';
import InlineDatePicker from './InlineDatePicker';
import PortalDatePicker from './PortalDatePicker';

import styled from '../../styled';
import fromTheme from '../../fromTheme';
import Button from '../Button';

let ClearButton = styled(Button).attrs({
    name: 'clearDate',
    title: 'Clear',
    buttonStyle: 'CircleAdd',
    icon: 'close',
    color: 'base'
})`
    right: 10px;
    margin: 0 0 3px -5px;
    padding: 0px;
    width: 29px;
    height: 29px;
    min-width: 29px;
    min-height: 29px;
    background-color: transparent;
    box-shadow: none;
`;

ClearButton = webOnlyStyles(ClearButton)`
    &:hover {
        background-color: ${fromTheme('button', 'grayHighlight')};
    }
`;

/**
 * @typedef {Object} Props
 * @property {*} [props.autoComplete]
 * @property {string} [props.format] for acceptable format strings, see https://date-fns.org/v2.19.0/docs/format
 * @property {string} props.id
 * @property {(value: Date) => void} props.onChange
 * @property {string} [props.placeholder]
 * @property {string} props.propertyName
 * @property {boolean} [props.disabled]
 * @property {boolean} [props.showAsModal]
 * @property {string} [props.title]
 * @property {Date | string} props.value
 */
/** @type {import('react').FC<Props>} */
const DatePicker = props => {
    const { format, onChange, showAsModal, disabled, value: _value } = props || {};
    const value = useMemo(() => (_value ? new Date(_value) : undefined), [_value]);

    const { viewPort } = useTheme();
    const showTimeInput = shouldShowTimeInput(format);
    const handleClear = useCallback(() => onChange(), [onChange]);
    const handleOnChange = useCallback(
        /**
         * @param {string | Date | number | null} date
         */
        date => {
            onChange(date ? new Date(date).toISOString() : undefined);
        },
        [onChange]
    );
    if (viewPort === 'MOBILE' || showAsModal) {
        // prettier-ignore
        return rc(Fragment, null,
            rc(PortalDatePicker, { ...props, onChange: handleOnChange, showTimeInput, showAsModal: true, value }),
            rc(ClearButton, { onClick: () => onChange() })
        );
    }
    // prettier-ignore
    return rc(Fragment, null,
        rc(InlineDatePicker, { ...props, onChange: handleOnChange, showTimeInput, value }),
        !disabled && rc(ClearButton, { onClick: handleClear })
    );
};

DatePicker.defaultProps = {
    format: 'PP p'
};

DatePicker.propTypes = {
    autoComplete: PropTypes.string,
    format: PropTypes.string,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    showAsModal: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)])
};

export default DatePicker;
