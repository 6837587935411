import { createElement as rc } from 'react';
import styled from '../styled';
import fromTheme from '../fromTheme';
import ScrollView from './ScrollView';
import View from './View';

const _FieldSet = styled.fieldset.attrs({ name: 'fieldset' })`
    margin: 0;
    padding: ${fromTheme('viewPadding')};
    border: none;
    flex-grow: ${({ scrollWholeForm }) => (scrollWholeForm ? 0 : 1)};
    flex-shrink: 0;
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: ${props => (props.centerFields ? 'center' : 'inherit')};
    align-items: flex-start;
    align-content: flex-start;
`;

const FieldSetContainer = styled(View)`
    flex-grow: ${({ greedy }) => (greedy ? 1 : 0)};
    flex-direction: column;
`;

export default function Fieldset(props) {
    const { children, greedy, scrollWholeForm, ...otherProps } = props || {};
    if (scrollWholeForm) {
        return rc(_FieldSet, props);
    }
    // prettier-ignore
    return rc(FieldSetContainer, { greedy },
        rc(ScrollView, null,
            rc(_FieldSet, otherProps, children)
        )
    );
}
