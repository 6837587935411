import { createElement as rc, useMemo, useState, useEffect, useContext, useRef, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { styled, View, useDebounce, contexts } from 'lib_ui-primitives';
import useEventSink from '../../hooks/useEventSink';
import useReads from '../../hooks/useReads';
import UserActivatedInput from '../_abstractComponent/UserActivatedInput';

const Container = styled(View)`
    width: 100%;
    align-self: flex-start;
`;

const _p = {
    useReads
};
export const _private = _p;
/**
 * Lookup record by a single property, (possibly always on the server). Select it for edit if found, prepare new record if not found. (E.g. VIN lookup).
 * @param {*} props
 * @returns
 */
function FindRecord({
    inputDebounce = 800,
    currentRoute,
    clocking,
    hNode: { id, namespace, relation, placeholder, readOnly = false },
    hNode
}) {
    const { focusIfExists } = useContext(contexts.FocusContext);
    const [subscribe, publish] = useEventSink();
    const [errors, setErrors] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [itemSelected, setItemSelected] = useState(false);

    const inputDomElement = useRef(null);
    const { reading, reset } = _p.useReads();
    /**
     * @param {string} [overrideSearchText]
     */
    const submitSearchFilter = useDebounce(
        searchText => {
            let message;
            // Very basic validation
            if (searchText == null || searchText === '') {
                message = 'Enter or scan a value to look up.';
            } else if (hNode.pattern) {
                const regex = new RegExp(hNode.pattern);

                if (!regex.test(searchText)) {
                    message = `${searchText} is not a valid value.`;
                }
            }
            if (message != null) {
                if (allowStateChanges.current) {
                    setErrors([message]);
                }
                return;
            }
            // This publish is handled by the rules engine.  It usually results in an
            // "edit" or "new" detail screen being displayed.
            publish(
                {
                    hNode,
                    currentRoute,
                    searchText
                },
                { verb: 'find', namespace, relation }
            );
        },
        [hNode, currentRoute, publish, namespace, relation],
        inputDebounce || 800,
        { leading: true }
    );

    // Do this once, instead of repeating all over the place.  Set variable
    // when unmounting so async changes cannot try to update state after
    // an unmount;
    const allowStateChanges = useRef(true);
    useEffect(() => () => (allowStateChanges.current = false), []);

    // Blur if RFID scanning (so keyboard on devices is hidden)
    useEffect(() => {
        return subscribe({ verb: 'startup', namespace: 'sensor', relation: 'read', status: 'success' }, () =>
            inputDomElement.current?.blur()
        );
    }, [subscribe]);

    // Resets filters and input as needed
    useEffect(() => {
        const currentListenerUnsubscribes = [];

        // Setup reset of filter/input if user exits from edit/new mode of detail
        [
            { verb: 'submit', namespace, relation, status: 'success' },
            { verb: 'cancel', namespace, relation },
            { verb: 'new', namespace, relation, status: 'failure' },
            { verb: 'edit', namespace, relation, status: 'failure' },
            { verb: 'remove', namespace, relation, status: 'success' }
        ].forEach(listenForAction => {
            // Create a subscription for each event that signals an exit from the detail view of a found record.
            currentListenerUnsubscribes.push(
                subscribe(listenForAction, () => {
                    if (allowStateChanges.current) {
                        // Clear out previous selection
                        setSearchText('');
                        setItemSelected(false);
                        reset();
                        // Focus on input after hiding detail area
                        focusIfExists(`formFieldfindRecord-${id}`);
                    }
                })
            );
        });

        // Make component readOnly if edit or new action is dispatched
        [
            { verb: 'new', namespace, relation, status: 'success' },
            { verb: 'edit', namespace, relation, status: 'success' }
        ].forEach(listenForAction => {
            // Create a subscription for each event that signals an exit from the detail view of a found record.
            currentListenerUnsubscribes.push(
                subscribe(listenForAction, () => {
                    if (allowStateChanges.current) {
                        // Clear out previous selection
                        setItemSelected(true);
                    }
                })
            );
        });

        return () => {
            currentListenerUnsubscribes.forEach(unsubscribe => unsubscribe());
        };
    }, [subscribe, namespace, relation, reset, focusIfExists, id]);

    /**
     * Handles the onChange event from the inputDomElement
     * @param {import('react').ChangeEvent<HTMLInputElement>} event
     */
    const onChange = useCallback(value => {
        if (allowStateChanges.current) {
            setSearchText(value);
        }
    }, []);

    // Default all scanning properties to true (which will prefer the RFID Power Button if possible).
    // Settings created in Blockly will override this.
    const userActivatedInputHNode = useMemo(
        () => ({
            scanRfid: true,
            scanBarcode: true,
            displayScanButton: true,
            displayRfidPowerButton: true,
            ...hNode
        }),
        [hNode]
    );
    let disabled = readOnly || itemSelected || clocking || reading;
    // prettier-ignore
    return rc(Container, null,
        rc(UserActivatedInput, {
            id: `findRecord-${id}`,
            inputRef: inputDomElement,
            hNode: userActivatedInputHNode,
            disabled,
            onChange,
            onSubmit: submitSearchFilter,
            placeholder,
            currentValue: searchText,
            errors,
            currentRoute
        })
    );
}

FindRecord.propTypes = {
    currentRoute: PropTypes.string,
    clocking: PropTypes.bool,
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};

export default memo(FindRecord);
