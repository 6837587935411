import { View, Portal, styled, Text, h3 } from 'lib_ui-primitives';

export const ConfigurationPortal = styled(Portal).attrs({ name: 'configuration-portal' })`
    flex-direction: column;
`;

export const ConfigurationPortalContainer = styled(View).attrs({ name: 'configuration-portal-container' })`
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
`;
ConfigurationPortalContainer.displayName = 'ConfigurationPortalContainer';

export const Title = styled(h3)`
    margin-left: ${({ theme }) => theme.viewMarginMore}px;
    margin-right: ${({ theme }) => theme.viewMarginMore}px;
`;

export const scrollContainer = styled(View)`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    width: 100%;
`;

export const OrderableList = styled(View).attrs({ name: 'orderable-list' })`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
`;
OrderableList.displayName = 'OrderableList';

export const Draggable = styled(View).attrs({ name: 'draggable' })`
    opacity: ${({ isDragging }) => (isDragging ? 0.2 : 1)};
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    border-top: ${({ isOver }) => (isOver ? 'solid' : 'none')} 4px;
`;
Draggable.displayName = 'Draggable';

export const Label = styled(Text)`
    flex-grow: 1;
    min-width: 200px;
`;

export const ButtonBar = styled(View).attrs({ name: 'button-bar' })`
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    justify-content: flex-end;
`;
