import { createElement as rc, Children } from 'react';
import TabPanel from './TabPanel';
import { useSelection } from './Selection';
import { styled, View } from 'lib_ui-primitives';

const _TabPanels = styled(View).attrs({ name: 'tab-panels' })`
    flex-grow: 1;
    flex-basis: 1px;
`;
/** @type {import('react').FC<Props>} */
function TabPanels(props) {
    const [selectedIndex] = useSelection();
    const { children, childrenHNodes } = props;

    // prettier-ignore
    return rc(_TabPanels, null, Children.map(children, (child, i) =>
        rc(TabPanel, { selected: selectedIndex === i, childHNode: childrenHNodes[i] }, child))
    );
}

export default TabPanels;
