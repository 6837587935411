import notHandledHere from './notHandledHere';

export default {
    verb: 'willEdit',
    description: 'Obtain a record to edit',
    type: 'singleRecordForm',
    excludedNamespaceRelations: notHandledHere,
    prerequisites: [
        {
            context: {
                verb: 'get',
                type: 'find'
            },
            //I don't think there is a way to specify we only want at most one result...
            query: ({ data }) => {
                if (data?._id) {
                    return {
                        _id: data._id,
                        'meta.deleted': { $exists: false }
                    };
                } else if (data?.splashRecord) {
                    const {
                        record: { _id },
                        namespace,
                        relation
                    } = data.splashRecord;
                    return {
                        [`${namespace}:${relation}._id`]: _id,
                        'meta.deleted': { $exists: false }
                    };
                }
                return {
                    'meta.deleted': { $exists: false }
                };
            }
        }
    ],
    //this is the actual logic:
    logic: willEdit
};

async function willEdit({ prerequisiteResults, context }) {
    const { namespace, relation } = context;
    // There _should_ be a record, as it should have been created (by the server) at the time of synchronization.
    const record = prerequisiteResults?.[0]?.result?.[0];
    if (!record) {
        throw new Error(
            `No ${namespace}:${relation} record exists. Please make sure that the server is setup to create a record if none exists.`
        );
    }

    return {
        _id: record._id,
        record,
        isNew: false
    };
}
