import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
//https://mac-s-g.github.io/react-json-view/demo/dist/
import _JsonTree from 'react-json-view';
import { styled, View, Text, testProperties } from 'lib_ui-primitives';

const JsonTree = _JsonTree.default ?? _JsonTree;
const JsonViewerContainer = styled(View).attrs({ name: 'json-viewer-container' })`
    height: 100%;
    width: 100%;
    min-height: 7em;
    overflow-y: auto;
    background: black;
    min-width: ${({ theme }) => theme.width * 0.5}px;
    max-height: ${({ theme }) => theme.height * 0.9}px;
`;

JsonViewerContainer.displayName = 'JsonViewerContainer';
export default function JsonViewer(props) {
    let { tree, errors, id, title, collapsed = 3 } = props ?? {};

    if (errors && errors.length) {
        return rc(Text, null, errors.join('/n'));
    }

    if (!tree) {
        return rc(Text, null, 'Add blocks to create metadata.');
    }

    return rc(
        JsonViewerContainer,
        {
            id: 'jsonViewer-' + title + '-' + id,
            ...testProperties(props, title)
        },
        rc(JsonTree, {
            src: tree,
            theme: 'shapeshifter',
            displayObjectSize: false,
            displayDataTypes: false,
            collapsed,
            name: false
        })
    );
}

JsonViewer.propTypes = {
    dispatch: PropTypes.func,
    tree: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    errors: PropTypes.array,
    id: PropTypes.string
};
