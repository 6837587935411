import { createElement as rc, useCallback } from 'react';
import PropTypes from 'prop-types';
import { nativeOnlyProperties, webOnlyProperties } from 'lib_ui-services';
import UserActivatedInput from '../UserActivatedInput';
import { hooks } from 'lib_ui-primitives';

const { useMemoizedObject } = hooks;

/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function DropDownInput(props) {
    const {
        disabled,
        autoFocus,
        hNode,
        hNode: { id, placeholder },
        inputProps,
        labelProps: _labelProps,
        className,
        style: _style,
        errors,
        title,
        sequence
    } = props;

    // In Downshift (or somewhere) this 'aria-labellby' property is spelled wrong
    // https://www.w3.org/TR/wai-aria-1.1/#states_and_properties
    // I couldn't find the code though, so I couldn't submit an issue.
    inputProps['aria-labelledby'] = inputProps['aria-labellby'];
    delete inputProps['aria-labellby'];

    // Downshift expects the dom/native event formats, so this code will convert the
    // on change argument to the format it expects.
    const tempOnChange = inputProps.onChange ?? inputProps.onChangeText;
    // For native, remove this downshift handler (it won't exist on web).
    // We don't use it and downshift recreates it every time causing rerenders.
    if (inputProps.onChangeText) {
        delete inputProps.onChangeText;
    }
    inputProps.onChange = useCallback(
        value => {
            // ensure the proper format.
            let cleanValue = value;
            if (typeof value === 'object' && Object.keys(value).includes('target')) {
                cleanValue = value.target.value;
            }
            return tempOnChange({
                ...webOnlyProperties({ target: { value: cleanValue } }),
                ...nativeOnlyProperties({ nativeEvent: { text: cleanValue } })
            });
        },
        // Do not allow downshift to break this by sending new references for the same onChange button
        // eslint-disable-next-line
        []
    );

    const labelProps = useMemoizedObject(_labelProps);
    // On native only, there is a frustrating problem where under
    // some conditions I haven't identified styled
    // components creates new style objects on every render. This
    // avoids that problem.
    const style = useMemoizedObject({ ..._style, flexGrow: 1 });
    // UserActivatedInput expects slightly different prop names
    // e.g. 'inputBlurred' instead of 'onBlur'
    const { value: currentValue, onFocus: inputFocused, onBlur: inputBlurred, ...otherInputProps } = inputProps;
    // Ensure hNode title is populated if it is passed in without an hNode
    hNode.title = hNode.title || title;
    // prettier-ignore
    return rc(UserActivatedInput, {
        sequence,
        disabled,
        hNode,
        labelProps,
        autoFocus,
        excludeCheckButton: true,
        errors,
        inputFocused,
        inputBlurred,
        currentValue,
        ...otherInputProps,
        style,
        className,
        placeholder,
        displayThenClearScannedValue: false,
        //data-testid does NOT match id, which is set in inputProps!
        //inputProps.id === id
        'data-testid': `normal-input-${id}`,
        ...nativeOnlyProperties({
            onKeyPress: inputProps.onKeyDown,
            onKeyDown: undefined,
            'aria-autocomplete': undefined,
            'aria-controls': undefined,
            'aria-labelledby': undefined,
            autoComplete: undefined
        })
    });
}

DropDownInput.defaultProps = {};

DropDownInput.propTypes = {
    autoFocus: PropTypes.bool.isRequired,
    hNode: PropTypes.shape({
        id: PropTypes.string,
        placeholder: PropTypes.string,
        getInputProps: PropTypes.func,
        getLabelProps: PropTypes.func
    }),
    sequence: PropTypes.number.isRequired
};

export default DropDownInput;
