import logging from '@sstdev/lib_logging';
import filterFactory from '../../filterFactory';
import http from '../../http';
import getQueryUrl from '../../http/getQueryUrl';
import ObjectId from '../../ObjectId';
import SYNC_TYPES from '../../constants/SYNC_TYPES';

const _p = {
    getBatchQuery,
    getBatchHeaders
};
export const _private = _p;
export async function getBatch(
    namespace,
    relation,
    {
        ifModifiedSince,
        ifDeletedSince,
        limit = 1000,
        lastRecordId,
        earliestModifiedTime,
        latestModifiedTime,
        syncType = SYNC_TYPES.default
    } = {}
) {
    const query = getBatchQuery(syncType, limit, earliestModifiedTime, latestModifiedTime, lastRecordId);
    const url = getQueryUrl(namespace, relation, query, new ObjectId());
    const headers = getBatchHeaders(syncType, ifModifiedSince, ifDeletedSince);

    const httpResult = await http.get(url, headers);
    return analyzeHttpResult(httpResult);
}
export const getBatchWithRetry = retryWithBackoff(getBatch);

export async function getCount(namespace, relation, { ifModifiedSince, ifDeletedSince } = {}) {
    const query = { aggregateType: true, includeDeleted: true };
    const url = getQueryUrl(namespace, relation, query, new ObjectId());

    let headers = {};
    if (ifModifiedSince) {
        headers['if-modified-since'] = ifModifiedSince;
    }
    if (ifDeletedSince) {
        headers['x-if-deleted-since'] = ifDeletedSince;
    }
    const httpResult = await http.get(url, headers);
    const records = analyzeHttpResult(httpResult);

    if (records && records.length > 0 && records[0].count) {
        return records[0].count;
    }

    return 0;
}
export const geCountWithRetry = retryWithBackoff(getCount);

const analyzeHttpResult = httpResult => {
    // If there is no content (http 204/202/304) from the server, skip.
    if (!httpResult || httpResult === 'Accepted - No Content') {
        return [];
    }
    const items = httpResult.items || httpResult; // handle old and new api.
    if (!Array.isArray(items)) {
        throw new Error(
            `The lookup result from the server is in an unexpected format.  Details: Synchronization.getFromServer.analyzeHttpResult method,  result: ${JSON.stringify(
                items
            )}`
        );
    }
    return items;
};

/**
 * Wait for the given milliseconds
 * @param {number} milliseconds The given time to wait
 * @returns {Promise} A fulfilled promise after the given time has passed
 */
function waitFor(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

/**
 * Execute a promise and retry with exponential backoff
 * based on the maximum retry attempts it can perform
 * @param {function} asyncFn function to be executed
 * @param {number} maxRetries The maximum number of retries to be attempted
 * @param {number} scale How quick to increment the backoff
 * @param {function(number, Error)} onRetry callback executed on every retry
 * @returns {Promise} The result of the given promise passed in
 */
export function retryWithBackoff(asyncFn, maxRetries = 6, scale = 150, onRetry) {
    // Notice that we declare an inner function here
    // so we can encapsulate the retries and don't expose
    // it to the caller. This is also a recursive function
    const retry =
        retries =>
        async (...args) => {
            try {
                // Make sure we don't wait on the first attempt
                if (retries > 0) {
                    // Here is where the magic happens.
                    // on every retry, we exponentially increase the time to wait.
                    // Here is how it looks for a `maxRetries` = 6 and various 'scale
                    // `scale` = 50
                    // (2 ** 1) * 50 = 100 ms  (100 ms total wait time)
                    // (2 ** 1) * 50 = 200 ms  (300 ms total wait time)
                    // (2 ** 3) * 50 = 400 ms  (700 ms total wait time)
                    // (2 ** 4) * 50 = 800 ms  (1.5 seconds total wait time)
                    // (2 ** 5) * 50 = 1600 ms (3.1 seconds total wait time)
                    // `scale` = 100
                    // (2 ** 1) * 100 = 200 ms  (200 ms total wait time)
                    // (2 ** 1) * 100 = 400 ms  (600 ms total wait time)
                    // (2 ** 3) * 100 = 800 ms  (1.4 seconds total wait time)
                    // (2 ** 4) * 100 = 1600 ms  (3 seconds total wait time)
                    // (2 ** 5) * 100 = 3200 ms (6.2 seconds total wait time)
                    // `scale` = 200
                    // (2 ** 1) * 200 = 400 ms  (400 ms total wait time)
                    // (2 ** 2) * 200 = 800 ms  (1.2 Seconds total wait time)
                    // (2 ** 3) * 200 = 1600 ms  (2.4 seconds total wait time)
                    // (2 ** 4) * 200 = 3200 ms  (5.6 seconds total wait time)
                    // (2 ** 5) * 200 = 6400 ms (12 seconds total wait time)
                    const timeToWait = 2 ** retries * scale;
                    logging.debug(`[SYNCHRONIZATION] waiting for ${timeToWait}ms...`);
                    await waitFor(timeToWait);
                }
                return await asyncFn(...args);
            } catch (e) {
                // only retry if we didn't reach the limit
                // otherwise, let the caller handle the error
                if (retries < maxRetries) {
                    onRetry && onRetry(retries + 1, e);
                    return retry(retries + 1)(...args);
                } else {
                    logging.debug('[SYNCHRONIZATION] Max retries reached. Bubbling the error up');
                    throw e;
                }
            }
        };

    return retry(0);
}

export default { getBatch, getBatchWithRetry, getCount, geCountWithRetry, retryWithBackoff };

function getBatchHeaders(syncType, ifModifiedSince, ifDeletedSince) {
    let headers = {};
    // a sizeLimitedFullBatch will need two ranges of dates (see getBatchQuery),
    // everything else just needs anything newer and can use the default
    // if-modified-since logic on the server side.
    if (ifModifiedSince && syncType !== SYNC_TYPES.sizeLimitedFullBatch) {
        headers['if-modified-since'] = ifModifiedSince;
    }
    if (ifDeletedSince) {
        headers['x-if-deleted-since'] = ifDeletedSince;
    }
    return headers;
}

function getBatchQuery(syncType = SYNC_TYPES.default, limit, earliestModifiedTime, latestModifiedTime, lastRecordId) {
    let query = {
        syncRange: filterFactory('syncRange').getFilter(0, limit),
        // If limitSyncSize, then we'll need the records ordered by lastModifiedTime
        // because we'll pull batches by ranges of lastModifiedTime.
        // Otherwise, we'll use ranges of _id to ensure
        // each batch begins after the last left off.
        // (_id is slightly more efficient because it is unique, so
        // there should be no overlap.)
        orderBy:
            syncType === SYNC_TYPES.default
                ? filterFactory('orderBy').getFilter('_id', 'ascending')
                : filterFactory('orderBy').getFilter('meta.serverModifiedTime', 'descending'),
        includeDeleted: true
    };

    // If we are requesting a full batch for a relation with limitSyncSize,
    // then we'll pull data from EITHER SIDE of the previously pulled range.
    // In other words, we'll pull records that are either newer or older than
    // the previously pulled range.
    if (syncType === SYNC_TYPES.sizeLimitedFullBatch) {
        if (/* XOR */ !!earliestModifiedTime ^ !!latestModifiedTime) {
            throw new Error(
                'In getFromServer, earliestModifiedTime AND latestModifiedTime must both be provided or neither should be provided.'
            );
        }
        if (earliestModifiedTime != null && latestModifiedTime != null) {
            let criteria = {
                $or: [
                    { 'meta.serverModifiedTime': { $gt: latestModifiedTime } },
                    { 'meta.serverModifiedTime': { $lt: earliestModifiedTime } }
                ]
            };
            query = { ...query, ...filterFactory('searchCriteria').getFilter(criteria) };
        }
    } else if (syncType === SYNC_TYPES.default && lastRecordId) {
        // If this is not a limitSyncSize relation and we have a previous
        // lastRecordId, then it means we need to pull a batch of records
        // that is AFTER the lastRecordId.
        let criteria = {
            _id: { $gt: { $oid: lastRecordId } }
        };
        query = { ...query, ...filterFactory('searchCriteria').getFilter(criteria) };
    }
    return query;
}

/**
 * Checks if the specified property in the first record from the HTTP response matches the expected value.
 *
 * @param {string} url - The URL to fetch data from.
 * @param {string} checkProperty - The property to check in the record.
 * @param {string} successValue - The expected value of the property.
 * @param {string} failedValue - The value of the property that should stop retries immediately.
 * @param {function} [dispatch] - The dispatch function to send a notification to the user.
 * @returns {Promise<Array>} - Returns the array of records if the check passes.
 * @throws {Error} - Throws an error if the property value does not match the expected value.
 */
export async function checkProperty(url, checkProperty, successValue, failedValue, dispatch) {
    const httpResult = await http.get(url);
    const records = analyzeHttpResult(httpResult);
    if (records[0] && records[0][checkProperty] !== successValue) {
        const currentStatus = records[0][checkProperty];
        if (currentStatus === failedValue) {
            return [];
        }
        // if the dispatch function is provided, send a notification to the user
        if (dispatch) {
            await dispatch(
                {
                    addToList: false,
                    message: `Current ${checkProperty} is ${currentStatus}.`
                },
                { verb: 'pop', namespace: 'application', relation: 'notification' }
            );
        }
        throw new Error(`Expected ${checkProperty} to be ${successValue}.`);
    }
    return records;
}

/**
 * Wraps the `checkProperty` function with a retry mechanism that includes exponential backoff.
 * @type {(url: string, checkProperty: string, successValue: string, failedValue: string, dispatch: function) => Promise<Array>}
 */
export const checkPropertyWithRetry = retryWithBackoff(checkProperty, 20, 40);
