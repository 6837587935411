export default {
    verb: 'doingValidate',
    namespace: 'rex',
    relation: 'rma',
    description: 'Set some default RMA fields',
    priority: 10, //before the default validation
    //this is the actual logic:
    logic: doingValidate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingValidate({ data }) {
    //willCreate will set these values for real,  as changes made here don't cary over to the willCreate workflow.
    //so, here just stuff in some dummy values

    data.newRecord.dateCreated = new Date();
    data.newRecord['rex:status'] = { title: 'tmp', _id: '000000000000000000000000' };

    return data;
}
