import { constants } from 'lib_ui-services';
const { useCaseIds } = constants;

export default {
    verb: 'doingCopy',
    namespace: 'deploy',
    relation: 'deployment',
    useCaseIds: [useCaseIds.ONE_TOUCH],
    description: 'Remove some things added server side which are not needed on the client side.',
    priority: -10,
    //this is the actual logic:
    logic: doingCopy
};

// Remove some things added server side which are not needed on the client side.
// They will cause the POST validation to fail if left in.
function doingCopy({ data }) {
    const { record } = data;
    record.status = 'new';
    delete record.dynatracePaasToken;
    let env = record['deploy:environment'];
    env = { _id: env._id, title: env.title };
    record['deploy:environment'] = env;

    let releaseGroups = record.releaseGroups;
    releaseGroups.forEach(rg => {
        let release = rg['deploy:release'];
        release = {
            _id: release._id,
            title: release.title
        };
        rg['deploy:release'] = release;
    });
    return data;
}
