/**
 * turn `123456789012345678901234`
 * into `12345678-9012345678-901234`
 * so it looks a bit less daunting
 * @param {string} value
 * @returns
 */
export default function asTransactionCode(value) {
    if (!value || value.length !== 24) return value;
    return `${value.slice(0, 8)}-${value.slice(8, -6)}-${value.slice(-6)}`;
}
