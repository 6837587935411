import notHandledHere from './notHandledHere';
import { database } from 'lib_ui-services';
import logging from '@sstdev/lib_logging';

export default {
    verb: 'doingUpsert',
    description: 'upsert the indicated data on the database',
    excludedNamespaceRelations: notHandledHere.filter(f => f.namespace !== 'file'),
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @typedef {{items: Array<T>}} UpsertPayload
 *
 * @param {{
 *   data: UpsertPayload;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {Promise}
 */
async function logic({ data, context }) {
    const db = database.get();
    let result;
    try {
        result = await db.upsert(data, context);
        const rejects = {};
        result?.forEach(r => {
            if (r.status === 'rejected') {
                if (r.reason instanceof Error) {
                    const message = r.reason?.message ?? 'No message given';
                    rejects[message] = rejects[message] == null ? 1 : rejects[message] + 1;
                }
            }
        });
        if (Object.keys(rejects).length > 0) {
            const message = `Some upserts failed for ${context.namespace} - ${context.relation}\n${JSON.stringify(
                rejects,
                null,
                3
            )}`;
            logging.error(message);
        }
    } catch (err) {
        // Individual record upsert errors should be captured in lib_ui-services\src\database\databaseOperations\upsert.js
        // so this will be something more critical.
        logging.error(err);
        throw err;
    }
    return { ...data, result };
}
