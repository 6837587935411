import { memo, createElement as rc, useContext, useMemo, forwardRef, useRef, useLayoutEffect } from 'react';
import lodash from 'lodash';
const { memoize } = lodash;
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ThemeContext } from 'styled-components';
import View from '../View';
import ListItemRenderer from './ListItemRenderer';
import styled from '../../styled';
import propTypes from 'prop-types';
import { GestureContext } from '../contextProviders/GestureRecognizer';
import useListOuterScrollingElement from './useListOuterScrollingElement';

// If you don't have this min-height, then Autosize will freak out and
// not display any content.
// Dropdown needs height 100% or it will only go to the min-height.
const NonTouchContainer = styled(View)`
    min-height: 100px;
    height: 100%;
    width: 100%;
    flex-grow: 1;
`;

let StickyHeader = styled(View).attrs({ name: 'sticky-header' })`
    position: sticky;
    left: 0;
    top: 0;
    z-index: ${({ theme }) => theme.zindex.ListStickyHeader};
    overflow: visible;
`;

// This is used to render a sticky list header if a header is passed into the component
// as child
const getInnerElementType = (Header, itemHeightPixels) => {
    // The `innerElementType` property is taken by a react-window list to encapsulate the rows.
    // It just uses a div element if you don't pass something in.
    // prettier-ignore
    const innerElementType = forwardRef((props, ref) => {
        const { children, ...rest } = props || {};
        // This encapsulates all list rows (including the header).
        return rc('div', { ref, ...rest },
            // Make the header stick to the top of the list
            rc(StickyHeader, { style: { height: itemHeightPixels } },
                // passed into the List component as a child
                Header
            ),
            // The rest of the rows in the list
            children
        );
    });
    innerElementType.displayName = 'ListInnerElement';
    innerElementType.propTypes = {
        children: propTypes.array
    };
    return innerElementType;
};
function List(props) {
    const {
        id,
        itemCount,
        data: items,
        Row,
        RowDetail,
        onClick,
        onRenderItem,
        highlightedIndex,
        selectedItem,
        getItemProps,
        itemHeightPixels,
        children,
        className,
        listType,
        blockLeftSwipe = true,
        blockRightSwipe = true,
        onScrollLayoutChange = () => {
            /*default to noop*/
        },
        endOfRecordsText
    } = props || {};
    const { suspendLeftSwipe, suspendRightSwipe } = useContext(GestureContext);

    const ref = useRef();

    useLayoutEffect(() => {
        const releases = [];
        if (!ref.current) {
            throw new Error('No reference for the scrolling element is defined.');
        }
        if (blockLeftSwipe) {
            releases.push(suspendLeftSwipe(() => ref.current.getBoundingClientRect()));
        }
        if (blockRightSwipe) {
            releases.push(suspendRightSwipe(() => ref.current.getBoundingClientRect()));
        }
        return () => releases.forEach(release => release());
    }, [blockLeftSwipe, blockRightSwipe, suspendRightSwipe, suspendLeftSwipe]);

    let innerItemCount = itemCount;
    // The only child that should be passed in is the List's header.
    const Header = children;
    let innerElementType,
        hasHeader = false;
    if (Header) {
        hasHeader = true;
        innerElementType = getInnerElementType(Header, itemHeightPixels);
        // Add one for the header
        innerItemCount++;
    }
    // Allow for 'End of records' message by adding one more row to the count
    if (endOfRecordsText != null && endOfRecordsText.length > 0) {
        innerItemCount++;
    }

    const theme = useContext(ThemeContext);
    const itemData = useMemo(
        () => ({
            Row,
            RowDetail,
            items,
            selectedItem,
            highlightedIndex,
            theme,
            getItemProps,
            onClick,
            onRenderItem,
            hasHeader,
            listType
        }),
        [
            Row,
            RowDetail,
            items,
            selectedItem,
            highlightedIndex,
            theme,
            getItemProps,
            onClick,
            onRenderItem,
            hasHeader,
            listType
        ]
    );
    const outerElementType = useListOuterScrollingElement(onScrollLayoutChange, id, items);
    const VariableHeightList = memoize(
        ({ height, width }) => {
            // ** USEFUL FOR DEBUGGING EXTRA CALLS. **
            //console.info(`NOT MEMOIZED ${height}|${width}|${JSON.stringify(items[0])}`);

            // prettier-ignore
            return rc(VariableSizeList, {
                id: `fixed-list-${id}`,
                name: 'List',
                height,
                width,
                itemCount: innerItemCount,
                itemSize: RowDetail.getItemSize ? RowDetail.getItemSize : () => itemHeightPixels ?? theme.listLineHeight,
                // Fixed Size List expects 'itemData' to include any data needed by the item renderer
                itemData,
                innerElementType,
                outerElementType,
                className
            },
                ListItemRenderer
            );
        },
        ({ height, width }) => `${height}|${width}`
    );

    // prettier-ignore
    const listResult = rc(AutoSizer, { name: 'auto-sizer', nonce: window.__webpack_nonce__ }, VariableHeightList);

    // It is incredibly difficult to get the auto-sizer to work correctly in unit testing
    // and the list will not render any rows unless the autosizer says there is room.
    // So this makes sure that the autosizer makes room to render rows during unit tests.
    // eslint-disable-next-line no-undef
    if (__UNIT_TESTING__) {
        // prettier-ignore
        return rc(View, {
            ref,
            id: `auto-sizer-container-${id}`,
            'data-testid': `auto-sizer-container-${id}`,
            className,
            style: {
                height: '500px',
                width: '500px',
                flexGrow: 1
            }
        },
            listResult
        );
    }

    // prettier-ignore
    return rc(NonTouchContainer, { ref, id: 'non-touch-container' },
        listResult
    );

    // return listResult;
}

List.propTypes = {
    // Only one header element allowed.
    children: propTypes.element
};
const _List = memo(List);
_List.displayName = 'MemoizedList';
export default _List;
