import { createElement as rc, useContext, useEffect, useState } from 'react';
import { fromTheme, webOnlyStyles, styled, GestureContext, h3 } from 'lib_ui-primitives';
let _Title = styled(h3).attrs({ name: 'title' })`
    max-height: ${({ theme: { mobile, smallMobile }, collapsed }) =>
        smallMobile || (mobile && collapsed) ? '0' : '100px'};
    margin: 0;
    padding: ${({ theme: { mobile, smallMobile, viewPaddingMore }, collapsed }) =>
        smallMobile || (mobile && collapsed) ? '0' : viewPaddingMore + 'px'};
    color: ${fromTheme('defaultFontColor')};
    overflow: hidden;
    flex-shrink: 0;
    border-bottom-width: ${({ theme }) => (theme.mobile ? '1px' : '0')};
    border-bottom-color: ${fromTheme('borderColor')};
`;
_Title = webOnlyStyles(_Title)`
    transition: max-height 0.3s linear;
    border-bottom-style: solid;
`;

export default function Title(props) {
    const { onSwipeUp, onSwipeDown } = useContext(GestureContext);
    const [collapsed, setCollapsed] = useState(false);
    useEffect(() => {
        // prettier-ignore
        let unsubscribes = [
            onSwipeUp(() => setCollapsed(true)),
            onSwipeDown(() => setCollapsed(false))
        ];
        return () => unsubscribes.forEach(u => u());
    }, [onSwipeUp, onSwipeDown]);

    return rc(_Title, { collapsed, ...props });
}
