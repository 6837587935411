import isValid from 'date-fns/isValid';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';

/**
 * Returns true if the format string contains any of the time format characters
 * @param {string} format
 */
export function shouldShowTimeInput(format) {
    const timeFormatCharacters = [
        'ISO',
        'p',
        'h',
        'H',
        'm',
        's',
        'S',
        'a',
        'b',
        'B',
        'K',
        'k',
        'X',
        'x',
        'O',
        'z',
        't',
        'T'
    ];

    return timeFormatCharacters.some(char => format.includes(char));
}

/**
 * Determines whether a date value is valid
 * @param {string | number | Date | undefined} value
 */
export function validDate(value) {
    if (!value) return false;

    const date = new Date(value);

    return isValid(date);
}

/**
 * Formats a Date to the appropriate format if it can be formatted.
 * @param {string | number | Date} date
 * @param {string} dateFormat
 */
export function formatDate(date, dateFormat) {
    if (!date || !isValid(new Date(date))) return date;

    if (dateFormat === 'ISO') return formatISO(new Date(date));

    return format(new Date(date), dateFormat);
}
