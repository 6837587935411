/**
 * Upserts are usually only used by sync or socket requests.
 * bulkUpsert is only used by synchronization
 * The goal is to avoid conflict errors for updates or inserts of a document that
 * may or may not already be in the local database.
 */
export default database => (namespace, relation, items, mergeIntoInserts) => {
    // Skip if nothing to upsert.
    if (!items || items.length === 0) return Promise.resolve();
    const db = database.relationDb(namespace, relation);
    return db.bulkUpsert(items, mergeIntoInserts);
};
