// import { useSelector } from 'react-redux';
import configureStore from './configureStore';

/**
 * @type {{
 *   getState: () => object;
 *   dispatch: (arg0: {
 *       type: string;
 *       payload: object;
 *   }) => void;
 *   purge: () => void;
 * }}
 */
let _reduxOffline;

export function getStatus() {
    const {
        offline: { busy, online, outbox, retryCount, retryScheduled }
    } = _reduxOffline.getState();
    return { busy, online, pendingCount: outbox.length, retryCount, retryScheduled };
}

export async function initialize(eventSink) {
    _reduxOffline = await configureStore(eventSink);
}

export function process(action) {
    return _reduxOffline.dispatch(action);
}

export function enable(enable) {
    _reduxOffline?.enable(enable);
}
