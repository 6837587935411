import { createElement as rc, useState } from 'react';
import startCase from 'lodash/startCase';
import ThemeValue from './ThemeValue';
import { Pressable, Caret, View, styled, fromTheme, h3, nativeOnlyStyles } from 'lib_ui-primitives';
import _Collapse from '../helpers/Collapse';

const SKIPPED = ['zindex', 'viewPort', 'keyboardHeight', 'height', 'width', 'colorScheme'];

const Collapse = styled(_Collapse).attrs({ name: 'collapse' })`
    flex-direction: column;
`;
Collapse.displayName = 'Collapse';

const ToggleCaret = styled(Caret).attrs({ name: 'toggle-caret' })`
    margin-right: 6px;
    height: 8px;
    width: 8px;
`;
ToggleCaret.displayName = 'ToggleCaret';

const EntriesContainer = styled(View).attrs({ name: 'entries-container' })`
    flex-direction: column;
    margin-left: 4px;
    padding-left: 4px;
    overflow: visible;
    background-color: ${fromTheme('backgroundColor')};
`;
EntriesContainer.displayName = 'EntriesContainer';

let Title = styled(Pressable).attrs({ name: 'tenant-title' })`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
Title = nativeOnlyStyles(Title)`
    margin-top: ${fromTheme('viewMargin')};
    margin-bottom: ${fromTheme('viewMargin')};
`;

/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
const ThemeEntries = function ThemeEntries(props) {
    const { updateTheme, themeValues, parentProperty, title, collapsed = true } = props;
    const [isCollapsed, setIsCollapsed] = useState(collapsed);

    // prettier-ignore
    return rc(EntriesContainer, null,
        rc(Title, { onClick: () => setIsCollapsed(x => !x)},
            rc(ToggleCaret, { isOpen: !isCollapsed, }),
            rc(h3, null, startCase(title))
        ),
        rc(Collapse, { isCollapsed },
            Object.entries(themeValues).map(([_propertyName, value], i) => {
                // It doesn't make sense to change some theme properties (e.g. those that are calculated like height).
                if (SKIPPED.includes(_propertyName)) {
                    return null;
                }

                let newTitle = _propertyName, propertyName = _propertyName;
                if (parentProperty) {
                    // Allow nested properties to be updated.
                    propertyName = parentProperty + '.' + propertyName;
                }
                if (typeof value === 'string' || typeof value === 'number') {
                    return rc(ThemeValue, { key: propertyName + i, value, title: newTitle, updateTheme, propertyName });
                } else if (typeof value === 'object' && Object.keys(value).length > 0) {
                    // This is a nested object so recurse.
                    return rc(ThemeEntries, { key: propertyName + i, themeValues: value, parentProperty: propertyName, updateTheme, title: propertyName });
                } else {
                    // unexpected type
                    return null;
                }
            }),
        ),
    );
};

ThemeEntries.defaultProps = {};

ThemeEntries.propTypes = {};

export default ThemeEntries;
