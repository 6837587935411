import * as network from '../network';
import http from '../http';
import * as cache from './cache';

const _p = {
    getNetworkStatus: network.getStatus,
    httpPatch: http.patch,
    getCurrentSession: cache.getCurrentSession,
    clearUser: cache.clearUser
};
export const _private = _p;

export default async function acceptLicense(acceptedLicense, groupNumber = 0, context) {
    const networkStatus = await _p.getNetworkStatus();
    if (networkStatus.isServerReachable) {
        const { user } = context; //await _p.getCurrentSession();
        const userId = (user?.user || user)._id || (user?.user || user).userId;
        const userName = (user?.user || user).userName;
        let patchValue = {
            'identity:tenant': user.activeTenant['identity:tenant'],
            'metaui:useCase': user.activeUseCase['metaui:useCase'],
            acceptedAt: { $date: new Date().toISOString() }
        };
        let path;

        if (acceptedLicense.type === 'MLA') {
            patchValue['backbone:mla'] = {
                _id: acceptedLicense._id,
                title: acceptedLicense.title
            };
            path = '/acceptedMla/-';
        } else if (acceptedLicense.type === 'EULA') {
            patchValue['backbone:eula'] = {
                _id: acceptedLicense._id,
                title: acceptedLicense.title
            };
            path = '/acceptedEula/-';
        }

        const patch = [
            {
                op: 'add',
                path,
                value: patchValue
            }
        ];
        await _p.httpPatch(`/g/${groupNumber}/api/identity/self/${userId}`, patch);
        await _p.clearUser(userName);
    } else {
        throw new Error('Please go online to accept License.');
    }
}
