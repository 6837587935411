import notHandledHere from './notHandledHere';
import logging from '@sstdev/lib_logging';
import { metadata, validateModel, network } from 'lib_ui-services';
import { errors as _errors } from 'lib_ui-primitives';
import rule from './doingGet_namespace_relation';
import setDefaultValuesIfNecessary from '../../../../utilities/setDefaultValuesIfNecessary';
import lodash from 'lodash';
const { cloneDeep } = lodash;
const { get } = lodash;

const { getTitleAlternative, getPrettyRelationName, getLabelForPropertyName } = metadata;

const _p = {
    getTitleAlternative,
    getPrettyRelationName,
    getLabelForPropertyName,
    setDefaultValuesIfNecessary,
    validateModel,
    getNetworkStatus: network.getStatus,
    dbGet: rule.logic,
    getFilterForDuplicates,
    getDuplicateCheckFields: metadata.getDuplicateCheckFields
};

// Match NGINX and lib_base-server configuration:
const MAX_8MB_IN_BYTES = 8 * 1024 * 1024;

export const _private = _p;

export default {
    verb: 'doingValidate',
    excludedNamespaceRelations: notHandledHere,
    description: 'Prepare to create the given record on the database',
    //this is the actual logic:
    logic: validation
};

async function validation({ data, context }) {
    const { namespace, relation, hNode } = context;
    if (namespace === 'print' && relation === 'job') {
        // print does require a validate workflow, or else it will display an "no workflow found" error
        // but there is nothing to validate here.
        return;
    }
    const { newRecord: _newRecord } = data;
    const newRecord = cloneDeep(_newRecord);
    // Need to merge in defaults to avoid validation errors for missing required values
    // that are defaulted.
    await _p.setDefaultValuesIfNecessary(context, newRecord);

    // Check for field level validation problems.
    let errors;
    if (hNode != null) {
        errors = await _p.validateModel.withHNode(newRecord, hNode);
    } else {
        errors = await _p.validateModel.withDictionary(newRecord, namespace, relation);
    }

    // special validation for attachments:
    if (data.newRecord?.newAttachments?.length) {
        const networkStatus = await _p.getNetworkStatus();
        // There isn't a great way to display the field error for attachments, so we'll just use the title alternative (e.g. assetNo, title etc.)
        const checkPropertyName = _p.getTitleAlternative(namespace, relation);
        if (!networkStatus.isServerReachable) {
            errors = errors || {};
            errors[checkPropertyName] = 'Unable to upload files while Offline';
        } else if (
            data.newRecord?.newAttachments?.size > MAX_8MB_IN_BYTES ||
            data.newRecord?.newAttachments?.find(f => f.size > MAX_8MB_IN_BYTES)
        ) {
            errors = errors || {};
            errors[checkPropertyName] = 'Unable to upload files over 8MB';
        }
    }

    if (errors && Object.keys(errors).length > 0) {
        let message = `There are problems with this ${_p.getPrettyRelationName(
            namespace,
            relation
        )}.  For details, see the red message(s) above.`;
        // Help debug error rendering problems by logging the field/error
        // combinations
        logging.error(JSON.stringify(errors));
        throw new _errors.ValidationError(message, errors);
    }

    //metadata has duplicates between usecases
    if (namespace !== 'metadata') {
        // for everything else: Check for duplicates
        const checkPropertyName = _p.getTitleAlternative(namespace, relation);
        const filter = _p.getFilterForDuplicates(namespace, relation, newRecord);

        if (namespace === 'identity' && !newRecord[checkPropertyName]) {
            const titleLabel = _p.getLabelForPropertyName(checkPropertyName, hNode);
            throw new _errors.ValidationError(
                `There are problems with this ${_p.getPrettyRelationName(
                    namespace,
                    relation
                )}.  For details, see the red message(s) above.`,
                { [checkPropertyName]: `${titleLabel} is required.` }
            );
        }

        // Find records with same property value
        const { result } = await _p.dbGet({
            data: { ...filter, 'meta.deleted': { $exists: false } },
            context: { verb: 'get', namespace, relation, type: 'find' }
        });

        // Fail if find at least one with same property but a different Id
        if (result[0] && result[0]._id !== newRecord._id) {
            let prettyRelationName = _p.getPrettyRelationName(namespace, relation);
            // TODO: This will just return the checkPropertyName value if hNode doesn't
            // exist - instead, need to check data dictionary in that case.
            let propertyLabel = _p.getLabelForPropertyName(checkPropertyName, hNode);
            let message = `Another ${prettyRelationName} exists with the ${propertyLabel} ${newRecord[checkPropertyName]}.  Select another ${propertyLabel} or change the existing ${prettyRelationName}.`;
            throw new _errors.ValidationError(message, {});
        }
    }
}

function getFilterForDuplicates(namespace, relation, record) {
    const fieldsToCheckForDuplicates = _p.getDuplicateCheckFields(namespace, relation);
    if (Array.isArray(fieldsToCheckForDuplicates)) {
        return fieldsToCheckForDuplicates.reduce((acc, field) => {
            acc[field] = get(record, field);
            return acc;
        }, {});
    } else {
        const checkField = fieldsToCheckForDuplicates;
        return { [checkField]: record[checkField] };
    }
}
