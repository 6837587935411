import { createElement as rc } from 'react';
import { styled, View } from 'lib_ui-primitives';
import TabCarousel from './TabCarousel';
import Selection from './Selection';
import TabPanels from './TabPanels';

const _Tabs = styled(View).attrs({ name: 'tabs' })`
    flex-grow: 1;
    flex-basis: 1px;
    flex-direction: column;
`;

/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function Tabs(props) {
    const {
        children,
        hNode: { children: childrenHNodes, id }
    } = props || { children: [], hNode: { children: [] } };

    // prettier-ignore
    return rc(Selection, { numTabs: childrenHNodes.length },
        rc(_Tabs, { id },
            rc(TabCarousel, { id, childrenHNodes }),
            rc(TabPanels, { id, childrenHNodes }, children)
        )
    );
}

export default Tabs;
