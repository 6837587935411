import filterHelper from './filterHelpers';
// Because this is only used by lokijs dynamicViews (as opposed to a paged database get),
// most of this is dependent on the ActiveRecord context boundary which is passed in via
// the contextualInfo parameter on fromHNode() below.
export default {
    getFilter,
    fromHNode,
    getUriComponent,
    getMql,
    getJavaScriptFilter
};

function fromHNode(hNode, contextualInfo) {
    const { namespace, relation } = hNode;
    const foreignKey = contextualInfo?.activeRecord?.record?._id;
    return getFilter(namespace, relation, foreignKey);
}

function getFilter(namespace, relation, foreignKey) {
    return {
        namespace,
        relation,
        foreignKey
    };
}

function getMql(filters) {
    if (filters.foreignRelationActiveRecord == null) return;
    const { namespace, relation, foreignKey } = filters.foreignRelationActiveRecord;
    return { [`${namespace}:${relation}._id`]: foreignKey };
}

function getUriComponent(filters) {
    const filter = filters['foreignRelationActiveRecord'];
    let foreignIdPath = `["${filter.namespace}:${filter.relation}._id"]`;
    return `searchProperties=${encodeURIComponent(foreignIdPath)}&searchTerm=${encodeURIComponent(
        filter.foreignKey
    )}&exact=true`;
}

function getJavaScriptFilter(filter) {
    const { namespace, relation, foreignKey } = filter;
    const propertyName = `${namespace}:${relation}._id`;
    const value = foreignKey;
    return filterHelper.basicFilterToJavaScript({ propertyName, op: '$eq', value });
}
