/**
 * @param {*} hNode
 * @returns {(value: string) => number | string}
 */
// eslint-disable-next-line no-unused-vars
function toNumber(hNode) {
    return value => {
        const valueAsNumber = +value.toString();

        if (isNaN(valueAsNumber)) return value;

        return valueAsNumber;
    };
}

export default toNumber;
