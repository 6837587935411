import lodash from 'lodash';
const { unset } = lodash;
import { getPathToProperty } from '../../metadata';
export default _private => {
    const { startPromise, collection } = _private;
    return function update(doc, propertiesToUnset = []) {
        return startPromise.then(() => {
            if (Array.isArray(doc)) throw new Error('Expected document, received array for update.');
            if ([undefined, null].includes(doc)) throw new Error('You cannot update a null or undefined document.');

            const existingDoc = collection.by('_id', doc._id);
            if (!existingDoc) {
                throw new Error('You cannot update a document that has not been saved.');
            }
            // Ensure loki meta is merged in (backbone meta should already be added by actions like submitAction)
            const newDoc = {
                ...existingDoc,
                ...doc,
                meta: {
                    ...existingDoc.meta,
                    ...doc.meta
                }
            };
            propertiesToUnset.forEach(p => {
                const fullPath = getPathToProperty(p);
                unset(newDoc, fullPath);
            });
            collection.update(newDoc);
            return { updated: 1 };
        });
    };
};
