// Most of this is currently handled by BarcodeScanner component: lib_ui-primitives\src\components\scanners\BarcodeScanner\index.js
// It might be nice to move some of that into here and make it more configureable if more sophisticated scanning
// is needed.  Right now this mainly allows the useReads hook to correctly report available sensor types.
const init = function init() {
    startupSensorService(
        {
            scanType: 'OnRequest',
            sensorType: 'BARCODE'
        },
        {
            verb: 'startup',
            namespace: 'sensor',
            relation: 'service'
        }
    );
};

export default {
    init,
    startupSensorService,
    stopSensorService,
    scan,
    stopScanning,
    // update, //Barcode does not support any form of updating at this time.
    dispose,
    isReaderAvailable: () => true
};

function scan() {
    // See BarcodeScanner component: lib_ui-primitives\src\components\scanners\BarcodeScanner\index.js
    throw new Error('not implemented');
}

async function startupSensorService(config) {
    if (config.scanType !== 'OnRequest') {
        throw new Error(`${config.scanType} scan for web barcode service is not implemented.`);
    }

    return true;
}

function stopSensorService() {}

function dispose(payload, context) {
    stopSensorService(payload, context);
}

function stopScanning() {}
