export default _private => {
    const { startPromise, collection } = _private;
    const count = (criteria = {}) =>
        startPromise.then(
            () =>
                new Promise((resolve, reject) => {
                    try {
                        resolve(collection.count(criteria));
                    } catch (err) {
                        reject(err);
                    }
                })
        );
    return count;
};
