import { constants } from 'lib_ui-services';

export default {
    verb: 'willRemove',
    namespace: 'item',
    relation: 'reasonCode',
    description: 'Prevent deletion of system defined reason codes',
    useCaseIds: [constants.useCaseIds.WISE_ID],
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'item',
                relation: 'reasonCode',
                type: 'get'
            },
            query: ({ data }) => {
                //if the data has skipConfirm, just pass through
                //most likely came from sockets
                if (data.skipConfirm) {
                    throw Error('Unnecessary: from sync');
                }
                //deletes pass the `_id` using `id`....
                return { _id: data.id };
            }
        }
    ],
    //this is the actual logic:
    logic: willRemove
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willRemove({ data, prerequisiteResults }) {
    //if the data has skipConfirm, just pass through
    //most likely came from sockets
    if (data.skipConfirm) return data;
    const reasonCode = prerequisiteResults[0].result?.[0];
    if (reasonCode?.system) {
        throw new Error(`Unable to remove system predefined reason code ${reasonCode.title}.`);
    }
}
