import { useEffect, useState } from 'react';
import logging from '@sstdev/lib_logging';
import lodash from 'lodash';
const { get, cloneDeep } = lodash;
import { metadata } from 'lib_ui-services';
import useUserContext from '../useUserContext';
import setDefaultValuesIfNecessary from 'lib_ui-core/src/utilities/setDefaultValuesIfNecessary';

const _p = { setDefaultValuesIfNecessary };
export const _private = _p;

/**
 * This will spin down through the hNodes of a form and find the first formElement that
 * does not already have a value associated with it.  It will return the id of that hNode.
 * @param {object} hNode - form hNode
 * @param {object} record - new record for the form change
 * @param {bool} isMobile - is the theme mobile
 * @returns string - the id of the hNode
 */
export default function useGetAutoFocusHNodeId(hNode, record, isMobile, isNew, isDirty) {
    const [autoFocusHNodeId, setAutoFocusHNodeId] = useState();
    const user = useUserContext();
    const focusOnFirstEmpty = ['Transaction', 'SingleRecordForm'].includes(hNode.hNodeType);
    useEffect(() => {
        if (isDirty) return;
        let allowStateUpdate = true;
        async function doAsync() {
            const formElements = metadata.findAllHNodes(hNode.children, child => {
                const readOnly = (child.readOnlyFor ?? []).includes(user?.['identity:role']?.title);
                return !readOnly && child.hNodeTypeGroup === 'formElement' && child.hNodeType !== 'InvisibleElement';
            });
            let autoFocusHNode;
            if (focusOnFirstEmpty) {
                const { namespace, relation } = hNode;
                const modelWithDefaults = cloneDeep(record ?? {});
                // If this becomes a perf problem it might be a good idea to store this
                // once as a field in the activeRecord and only recalc when the activeRecord
                // changes.  We can't memoize it because the defaults might change in situations
                // like the use of the DefaultToPreviousSelection filter block.
                await _p.setDefaultValuesIfNecessary({ hNode, isNew, namespace, relation }, modelWithDefaults);
                // Exclude hNodes with a value already.
                autoFocusHNode = formElements.find(childHNode => {
                    const path = metadata.getPathToProperty(childHNode);
                    const value = get(modelWithDefaults, path);
                    return value == null || value === '' || value === 0;
                });
            } else if (formElements.length > 0) {
                // Default focus to first form element;
                autoFocusHNode = formElements[0];
            }
            if (autoFocusHNode) {
                logging.debug(
                    `[Form] autofocus ${autoFocusHNode.title} | ${autoFocusHNode.propertyName} (hNodeType: ${autoFocusHNode.hNodeType})`
                );
                if (allowStateUpdate) setAutoFocusHNodeId(autoFocusHNode.id);
            }
        }
        doAsync();
        return () => (allowStateUpdate = false);
    }, [hNode, record, isMobile, user, focusOnFirstEmpty, isDirty, isNew]);

    return autoFocusHNodeId;
}
