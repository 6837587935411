import { ScrollContext } from '../components/contextProviders/ScrollBoundary';
import { useContext, useMemo, useEffect } from 'react';
export default function useScrollBoundary(func, dependencies) {
    const context = useContext(ScrollContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoFunc = useMemo(() => func, dependencies);
    // unsubscribe here so that user does not need to remember
    useEffect(() => {
        if (!context) return;
        return context.addSubscriber(memoFunc);
    }, [context, memoFunc]);
}
