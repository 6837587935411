import { createElement as rc, createContext, useRef, useMemo } from 'react';

export const ScrollContext = createContext();

export default function ScrollBoundary(props) {
    const { children } = props || {};
    const subscribers = useRef([]);
    const context = useMemo(
        () => ({
            addSubscriber: func => {
                subscribers.current.push(func);
                // return unsubscribe function
                return () => subscribers.current.splice(subscribers.current.indexOf(func), 1);
            },
            scroll: e => subscribers.current.forEach(func => func(e))
        }),
        []
    );
    return rc(ScrollContext.Provider, { value: context }, children);
}
