import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import domPurify from 'dompurify';
const { sanitize } = domPurify;
import styled from '../styled';

import View from './View';
const HtmlContainer = styled(View)`
    flex-direction: column;
    overflow-y: auto;
`;

/**
 * This only used to display complete, predefined documents like license agreements
 * @typedef {Object} Props
 * @property {string} html
 * @property {string} [styles]
 */
/** @type {import('react').FC<Props>} */
const Html = ({ html }) => {
    return rc(HtmlContainer, { dangerouslySetInnerHTML: { __html: sanitize(html) } });
};

Html.propTypes = {
    html: PropTypes.string.isRequired,
    styles: PropTypes.string
};

export default Html;
