import doingChange from '../../FORMS/namespace/relation/doingChange_namespace_relation';
export default {
    verb: 'doingChange',
    namespace: 'security',
    relation: 'profile',
    type: 'setOfflinePin',
    description: 'Change a record for setOfflinePin using the default logic.',
    priority: 0,
    //this is the actual logic:
    logic: doingChange.logic
};
