import { createElement as rc } from 'react';
import styled from '../styled';

import View from './View';
import fromTheme from '../fromTheme';

// TODO:  link label to input with `for`, `forHtml` or whatever.
const Container = styled(View).attrs(props => ({ name: props.name || 'label' }))`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: baseline;
    max-width: ${fromTheme('form', 'entryMaxWidth')};
    min-width: ${fromTheme('form', 'entryMinWidth')};
    margin-top: ${fromTheme('textMargin')};
    margin-bottom: ${fromTheme('textMargin')};
    margin-right: ${fromTheme('textMargin')};
    color: ${fromTheme('defaultFontColor')};
    align-content: flex-start;
    flex-grow: 0;
    flex-shrink: 1;
    font-family: ${fromTheme('font')};
    font-size: ${fromTheme('fontSize')};
`;

export default props => {
    const { children, className, name } = props;
    let title,
        otherChildren = null;
    if (typeof children === 'string') {
        title = children;
    } else if (Array.isArray(children)) {
        [title, ...otherChildren] = children;
    } else {
        title = children;
    }
    // prettier-ignore
    return rc(Container, {className, name},
        rc('label', null, title),
        otherChildren
    );
};
