import styled from '../styled';

import fromTheme from '../fromTheme';
import View from './View';

const Card = styled(View).attrs({ name: 'card' })`
    display: flex;
    flex-direction: column;
    min-width: 300px;
    box-shadow: ${({ shadow = true, theme: { darkMode } }) => {
        if (!shadow) return 'unset';
        const shadowColor = darkMode ? '255 255 255' : '0 0 0';
        return `0 2px 2px 0 rgb(${shadowColor} / 14%), 0 3px 1px -2px rgb(${shadowColor} / 20%), 0 1px 5px 0 rgb(${shadowColor} / 12%)`;
    }};
    height: 100%;
    flex-grow: 1;
    overflow: visible;
    background-color: ${fromTheme('baseBackgroundColor')};
`;
Card.displayName = 'Card';

Card.Header = styled(View).attrs({ name: 'card-header' })`
    display: flex;
    flex-direction: row;
    background-color: ${fromTheme('card', 'header', 'backgroundColor')};
    color: ${fromTheme('card', 'header', 'textColor')};
    padding: 0 ${fromTheme('card', 'padding')};
    flex-shrink: 0;
`;
Card.Header.displayName = 'CardHeader';

Card.Body = styled(View).attrs({ name: 'card-body' })`
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding: ${fromTheme('card', 'padding')};
    border-top: solid 1px ${fromTheme('borderColor')};
    height: 100%;
`;
Card.Body.displayName = 'CardBody';

Card.Footer = styled(View).attrs({ name: 'card-footer' })`
    flex-direction: row;
    display: flex;
    background-color: ${fromTheme('card', 'footer', 'backgroundColor')};
    padding: ${fromTheme('card', 'footer', 'paddingY')} ${fromTheme('card', 'padding')};
    border-top: solid 1px ${fromTheme('borderColor')};
    flex-shrink: 0;
`;
Card.Footer.displayName = 'CardFooter';

Card.Divider = styled(View).attrs({ name: 'card-divider' })``;
Card.Divider.displayName = 'CardDivider';

export default Card;
