import logging from '@sstdev/lib_logging';

/**
 * @name Firebase.GetOuter
 * @function
 * @param {object} _private
 * @returns {function(string):Promise<Array<object>>} `get` callback which returns a promise with an array with the result
 */
export default _private => {
    const { startPromise, collection } = _private;
    return _id =>
        startPromise.then(
            () =>
                new Promise((resolve, reject) => {
                    try {
                        if (_id == null) {
                            throw new Error('An _id was not provided for the firepants get operation.');
                        }
                        const result = collection.by('_id', _id);
                        if (result?.['0'] != null) {
                            // eslint-disable-next-line no-undef
                            if (!__PRODUCTION__) {
                                // log an error so we get as exact trace information as possible, and are sure it is logged _somewhere_
                                //NOTE 1/3/2922: If you got this error logged, pay attention to what operation you did beforehand.
                                // At this time, we do not know if it is LokiJS messing up, or our own code messing up.
                                // or even if it was something we messed up but already fixed
                                const error = new Error('Spread array found merged in object.');
                                logging.error(error);
                                throw error;
                            }
                            // under _some_ circumstances, LokiJS (or the layer we built on top) seems to be acting up
                            //and seems to merge an array with the record in it, with the record itself.
                            const { 0: part1, ...part2 } = result;
                            // the working assumption is that these are 2 times the same,
                            // but just in case, just merge both in:
                            return [{ ...part2, ...part1 }];
                        }
                        resolve([result]);
                    } catch (err) {
                        reject(err);
                    }
                })
        );
};
