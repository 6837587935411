export default {
    getFilter,
    getUriComponent,
    fromHNode,
    pageResetRequired: true
};

function fromHNode({ alsoSearchWithAscii }, state) {
    return getFilter(alsoSearchWithAscii, state);
}

function getFilter() {
    // (somehow) use userContext here instead?
    throw new Error('not implemented');

    // if (state.security_profile.allFeatureFlags.includes('displayInAscii')) {
    //     return alsoSearchWithAscii;
    // }
}

function getUriComponent() {
    throw new Error('not implemented');
}
