import { createElement as rc, forwardRef } from 'react';
import useFormControl from '../../hooks/useFormControl';
import { styled } from 'lib_ui-primitives';
import FormField from './FormField';
import PropTypes from 'prop-types';
import ScanTextInput from '../ScanTextInput';

const StyledTextInput = styled(ScanTextInput)`
    flex-grow: 1;
`;

const _p = {
    useFormControl
};
export const _private = _p;

const ShortText = forwardRef(function ShortText(props, ref) {
    const {
        autoComplete = 'off',
        hNode,
        hNode: {
            id,
            max,
            maxLength,
            min,
            minLength,
            placeholder,
            pattern,
            propertyName,
            rows,
            treePosition: { sequence }
        },
        multiline,
        type = 'textbox',
        onKeyPress
    } = props;

    const { autoFocus, title, value, setValue, disabled, errors, onBlur, onFocus, active } = _p.useFormControl(props);
    const fieldEmpty = value === '';

    // prettier-ignore
    return rc(FormField, { hNode, title, errors, active, fieldEmpty, onBlur },
        rc(StyledTextInput, {
            hNode,
            active,
            ref,
            id,
            name: propertyName,
            type,
            disabled,
            autoComplete,
            spellCheck: false,
            max,
            maxLength,
            min,
            minLength,
            pattern,
            placeholder: active ? placeholder ?? '' : '',
            multiline,
            rows,
            value,
            onKeyPress,
            onChange: setValue,
            onFocus,
            autoFocus,
            sequence
        })
    );
});
ShortText.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired,
    autoComplete: PropTypes.string,
    multiline: PropTypes.bool,
    type: PropTypes.string,
    onKeyPress: PropTypes.func,
    onChange: PropTypes.func
};

export default ShortText;
