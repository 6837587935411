import logging from '@sstdev/lib_logging';
import { constants, registeredSensorServiceQueues } from 'lib_ui-services';

export const clearRegistrations = () => registeredSensorServiceQueues.splice(0, registeredSensorServiceQueues.length);
export async function executeForEach(serviceMethod, payload, context) {
    if (!payload.sensorType) {
        throw new Error(
            'Actions for sensor services must contain the sensor type for the action (i.e. sensorType: "BLE" or sensorType: "RFID") in the action payload.'
        );
    }
    logging.debug(
        `[SENSOR_QUEUE] Requesting ${serviceMethod}. Matching to ${
            registeredSensorServiceQueues.length
        } services (${registeredSensorServiceQueues.map(s => s.type).join(',')})`
    );
    return Promise.all(
        registeredSensorServiceQueues.map(async registration => {
            if (
                [registration.type, constants.sensorTypes.ANY].includes(payload.sensorType) &&
                registration.service[serviceMethod]
            ) {
                logging.debug(`[SENSOR_QUEUE] executing ${serviceMethod} on ${registration.type}`);
                const result = await registration.service[serviceMethod](payload, context);
                if (result != null) {
                    return { result, type: registration.type };
                }
            }
            return { type: registration.type };
        })
    );
}
