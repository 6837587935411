import { useCallback, useState, useEffect, memo, Fragment, createElement as rc, useContext } from 'react';
import { offlineResilientCommunication, socket, constants } from 'lib_ui-services';
import { View, Label, Button, styled, Modal, Text, Card, h1, contexts } from 'lib_ui-primitives';
import useUserContext from '../../../hooks/useUserContext';
import useEventSink from '../../../hooks/useEventSink';
import lodash from 'lodash';
const { isEqual } = lodash;
import useNetwork from '../../../hooks/useNetwork';

const ButtonBar = styled(View)`
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 1;
    flex-direction: row;
    justify-content: flex-end;
    min-height: 32px;
    width: 100%;
`;

const SubText = styled(Text)`
    margin-left: 4px;
`;

const _p = {
    offlineResilientCommunication
};
export const _private = _p;
function SyncDialog({ id } = {}) {
    const { busy, online, pendingCount, retryCount, retryScheduled } = _p.offlineResilientCommunication.getStatus();
    const [syncing, setSyncing] = useState({ httpQueue: busy, httpSync: false });
    const [socketOnline, changeSocketState] = useState(false);
    const networkStatus = useNetwork();

    const userContext = useUserContext();
    const { _id: userId, allFeatureFlags } = userContext;
    const [subscribe, publish] = useEventSink();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const unsubscribe = subscribe({ verb: 'update', namespace: 'application', relation: 'socket' }, payload => {
            changeSocketState(payload.isOnline);
        });
        changeSocketState(socket.getStatus());
        return () => unsubscribe();
    }, [subscribe]);

    const notificationContext = useContext(contexts.UiNotificationContext);
    useEffect(() => {
        const removeHandler = notificationContext.addTypeHandler(constants.notificationTypes.SYNC_BUSY, payload => {
            const { busy, source } = payload;
            setSyncing(prev => {
                const newValue = { ...prev, [source]: busy };
                if (!isEqual(prev, newValue)) {
                    return newValue;
                }
                return prev;
            });
        });
        return removeHandler;
    }, [notificationContext]);

    const WaitingForConnection = !online || !socketOnline || !networkStatus.isConnected;

    const showForceSync = online && allFeatureFlags.includes('canForceSync');
    const buttonColor = WaitingForConnection ? 'error' : syncing.httpQueue || syncing.httpSync ? 'success' : 'gray';

    let title = 'No pending requests';
    let iconName = 'published_with_changes';
    if (syncing.httpQueue || syncing.httpSync) {
        title = 'Sync In Progress';
        iconName = 'cached';
    } else if (WaitingForConnection) {
        title = 'Waiting for Connection';
        iconName = 'cached';
    }

    const onOk = () => setOpen(false);
    const onClick = useCallback(() => setOpen(true), [setOpen]);
    const forceSync = () => {
        if (!userId) throw new Error('Unable to find the currently logged in user.');
        setOpen(false);
        publish(
            { userId },
            { verb: 'persist', namespace: 'inventory', relation: 'inventory', type: 'forceInventorySync' }
        );
    };

    // prettier-ignore
    return rc(Fragment, {},
        rc(Button, { id, title, color: buttonColor, clocking: syncing.httpQueue || syncing.httpSync, name: 'notifications-button', icon: iconName, buttonStyle: 'round', onClick }),
        rc(Modal, { visible: open, id: 'syncDialogModal' },
            rc(Card, { shadow: false },
                rc(Card.Header, null, rc(h1, null, 'Sync Status')),
                rc(Card.Body, null,
                    rc(Label, null,
                        'Client to server communication: ',
                        rc(SubText, { title: 'Client to server communication' }, online ? 'Connected' : 'Disconnected')
                    ), rc(Label, null,
                        'Server to client communication: ',
                        rc(SubText, { title: 'Server to client communication' }, socketOnline ? 'Connected' : 'Disconnected')
                    ),
                    rc(Label, null,
                        'Pending requests: ',
                        rc(SubText, { title: 'Pending requests' }, pendingCount.toString())
                    ),
                    rc(Label, null,
                        'Syncing client to server: ',
                        rc(SubText, { title: 'Transmit active' }, busy ? 'Yes' : 'No')
                    ),
                    rc(Label, null,
                        'Syncing server to client: ',
                        rc(SubText, { title: 'Receive active' }, syncing.httpSync ? 'Yes' : 'No')
                    ),
                    retryCount > 0 && rc(Label, null,
                        'Retries: ',
                        rc(SubText, { title: 'Retry count' }, retryCount)
                    ),
                    (retryCount > 0 || retryScheduled) && rc(Label, null,
                        'Retry Scheduled: ',
                        rc(SubText, { title: 'Is retry scheduled' }, retryScheduled ? 'Yes' : 'No')
                    )
                ),
                rc(Card.Footer, null,
                    rc(ButtonBar, null,
                        showForceSync && rc(Button, { value: 'Force Sync', onClick: forceSync, color: 'gray' }),
                        rc(Button, { value: 'OK', onClick: onOk, color: 'primary' })
                    )
                )
            )
        )
    );
}

export default memo(SyncDialog);
