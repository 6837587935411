import { useRef, useCallback } from 'react';

/**
 * Downshift doesn't give access to the refs it attaches to the elements of a
 * dropdown, but they are needed for focus handling logic.  This will intercept
 * the dom node before passing it on to the original downshift function that consumes the
 * ref. Then it will return a new ref containing that dom node as the 'current' property.
 * @param {object} props - props returned by a downshift get<X>Props() function (e.g. getRootProps, getMenuProps, getItemProps)
 * @returns - a ref to the menu/root/item dom node.
 */
export default function useInterceptRef(props) {
    const { ref: originalRef } = props;
    const interceptedRef = useRef();
    props.ref = useCallback(
        function (node) {
            if (typeof node === 'function') {
                throw new Error('Function ref not implemented for useInterceptRef');
            }
            if (originalRef != null && typeof originalRef !== 'function') {
                throw new Error('Expected a function for the downshift ref.');
            }
            interceptedRef.current = node;

            originalRef?.(node);
        },
        [originalRef]
    );
    return interceptedRef;
}
