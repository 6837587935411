import { network } from 'lib_ui-services';
const _p = {
    getNetworkStatus: network.getStatus
};

export const _private = _p;
export default {
    //as long as we don't have a `verb` here, it won't be picked up as a rule itself.
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'metadata',
                relation: 'navigation',
                type: 'find',
                status: undefined
            },
            query: ({ data }) => {
                return {
                    'metadata:useCaseDetail._id': data.record._id,
                    'meta.deleted': { $exists: false }
                };
            }
        },
        //----------------------------------------------
        {
            context: {
                verb: 'getFromServer',
                namespace: 'metadata',
                relation: 'page',
                status: undefined
            },
            skipLookup: async ({ data, dispatch }) => {
                const networkStatus = await _p.getNetworkStatus();
                const skip = !networkStatus.isOnline;
                if (!skip) {
                    const title = `${data.record['metaui:useCase'].title} v${data.record.version}`;
                    const showProgress = progressNotifier(dispatch, title, PROGRESS.MAX);
                    await showProgress(PROGRESS.NONE);
                }
                return skip;
            },
            query: ({ data }) => {
                return {
                    criteria: {
                        searchCriteria: {
                            'metadata:useCaseDetail._id': data.record._id,
                            'meta.deleted': { $exists: false }
                        }
                    }
                };
            }
        }
    ],
    //this is the actual logic:
    logic: doingEdit,
    // error handling to avoid the progress staying on the screen:
    onError
};

const PROGRESS = {
    NONE: 0,
    GOT_PAGES: 1,
    DONE: 2,
    MAX: 2
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingEdit({ data, prerequisiteResults, dispatch }) {
    //Sanity Check: assure we got 1 navigation record
    if (prerequisiteResults[0].result.length !== 1) {
        // just to be sure:
        closeDialog(dispatch);
        throw new Error(`Expected 1 navigation record, but got ${prerequisiteResults[0].result.length}.`);
    }

    const networkStatus = await _p.getNetworkStatus();
    // not online: do nothing
    if (!networkStatus.isOnline || !prerequisiteResults[1]?.result?.length) {
        // just to be sure:
        closeDialog(dispatch);
        return data;
    }

    // Sanity complete: Do the actual logic:

    const title = `${data.record['metaui:useCase'].title} v${data.record.version}`;
    const showProgress = progressNotifier(dispatch, title, PROGRESS.MAX);
    await showProgress(PROGRESS.GOT_PAGES);

    const navigationRecord = prerequisiteResults[0]?.result?.[0];
    const pagesFromServer = prerequisiteResults[1]?.result || [];
    const neededPages = pagesFromNavigation(navigationRecord);

    neededPages.forEach(navigationPage => {
        let serverPage = pagesFromServer.find(page => page._id === navigationPage._id);
        if (!serverPage) {
            throw new Error(`Page ${navigationPage.title} not found on the server.`);
        }
    });

    await showProgress(PROGRESS.DONE);

    //finally, close the progress dialog
    await closeDialog(dispatch);

    return data;
}

function pagesFromNavigation(navEntry) {
    if (!navEntry.children) {
        return navEntry;
    }
    return navEntry.children.reduce((acc, child) => {
        return acc.concat(pagesFromNavigation(child));
    }, []);
}

/**
 * @param {{
 *      error: Error;
 *      data: T;
 *      context: Context;
 *      dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>,
 *      workflowStack: WorkflowStack[]
 * }} parameters
 * */
async function onError({ error, dispatch }) {
    await closeDialog(dispatch);
    throw error;
}

const progressNotifier = (dispatch, title, total) => current => {
    return dispatch(
        {
            mainTitle: 'Loading Pages',
            description: title,
            title: '',
            current,
            total
        },
        { verb: 'update', namespace: 'application', relation: 'progress' }
    );
};

function closeDialog(dispatch) {
    return dispatch(
        {
            mainTitle: 'Loading Pages'
        },
        { verb: 'reset', namespace: 'application', relation: 'progress' }
    );
}
