export const ACCESS_STATE = {
    ENABLED: 3,
    DESKTOP_ONLY: 2,
    MOBILE_ONLY: 1,
    DISABLED: 0
};

export const tooltip = {
    [ACCESS_STATE.ENABLED]: 'Users with this role can see and access this menu on both DESKTOP and MOBILE DEVICES',
    [ACCESS_STATE.DESKTOP_ONLY]: 'Users with this role can see and access this menu on DESKTOP ONLY',
    [ACCESS_STATE.MOBILE_ONLY]: 'Users with this role can see and access this menu on MOBILE DEVICES ONLY',
    [ACCESS_STATE.DISABLED]: 'Users with this role CANNOT see or access this menu'
};

export function lesserState(...args) {
    if (args.includes(ACCESS_STATE.MOBILE_ONLY) && args.includes(ACCESS_STATE.DESKTOP_ONLY)) {
        return ACCESS_STATE.DISABLED;
    }
    return Math.min(...args);
}

export function stateAsIcon(state) {
    switch (state) {
        case ACCESS_STATE.ENABLED:
            return 'visibility';
        case ACCESS_STATE.DESKTOP_ONLY:
            return 'desktop_windows';
        case ACCESS_STATE.MOBILE_ONLY:
            return 'smartphone';
        case ACCESS_STATE.DISABLED:
            return 'visibility_off';
        default:
            return 'visibility';
    }
}

export function accessModifiersToMaxState(hNode) {
    if (hNode.hiddenFor?.includes('ADMIN')) {
        return null;
    }

    //if nothing is configured, then both mobile and desktop are allowed
    if (!hNode.showForViewport?.length && !hNode.hideForViewport?.length && !hNode.hideOn?.length) {
        return ACCESS_STATE.ENABLED;
    }
    //otherwise we have _SOME_ limitations. Figure out what they are

    // Totally intentionally ignoring Tablet for now in all of the user configurable access

    // assume mobile is allowed, unless:....
    let mobileAllowed = true;
    // ... if "something" is explicitly allowed, but it isn't mobile:
    if (hNode.showForViewport?.length && !hNode.showForViewport?.includes('MOBILE')) {
        mobileAllowed = false;
    }
    // ... mobile is explicitly disallowed
    if (hNode.hideForViewport?.includes('MOBILE')) {
        mobileAllowed = false;
    }
    // NOTE: We cannot exclude mobile based on hideOn = native, as mobile-web is still allowed

    // desktop is allowed, unless:...
    let desktopAllowed = true;
    // ... if "something" is explicitly allowed, but it isn't desktop:
    if (hNode.showForViewport?.length && !hNode.showForViewport?.includes('DESKTOP')) {
        desktopAllowed = false;
    }
    // ... desktop is explicitly disallowed
    if (hNode.hideForViewport?.includes('DESKTOP')) {
        desktopAllowed = false;
    }
    // if web is explicitly disallowed (there is no desktop native app)
    if (hNode.hideOn?.includes('WEB')) {
        desktopAllowed = false;
    }

    if (mobileAllowed && desktopAllowed) {
        return ACCESS_STATE.ENABLED;
    } else if (mobileAllowed) {
        return ACCESS_STATE.MOBILE_ONLY;
    }
    return ACCESS_STATE.DESKTOP_ONLY;
}
