import { useRef, createElement as rc, useEffect, useState, useCallback } from 'react';
import useEventSink from '../../hooks/useEventSink';
import { Button, hooks } from 'lib_ui-primitives';
import useActiveRecord from '../../hooks/useActiveRecord';
import PropTypes from 'prop-types';
import useGetDbViewName from '../../hooks/useDbView/useGetDbViewName';
const { useTimeout } = hooks;

const _p = { clockingAnimationTime: 500, maxRepetitionCount: 60 };
export const _private = _p;
export default function ActionButton(props) {
    const {
        hNode,
        hNode: {
            id,
            forAction,
            subtype,
            title,
            disabled,
            namespace,
            relation,
            iconName,
            displayTitleOnButton,
            buttonStyle,
            showBusyUntilFinished = false,
            autoRepeatSeconds = 0
        },
        actionPayload = {},
        // Ignore children of action buttons - an example would be
        // import columns which are used elsewhere to provide metadata
        // to the import process, but they are not rendered.
        // eslint-disable-next-line no-unused-vars
        children,
        currentRoute,
        ...otherProps
    } = props;
    const [subscribe, publish] = useEventSink();
    const [clocking, setClocking] = useState(false);
    const [autoRepeat, setAutoRepeat] = useState(false);
    const autoRepeatTimer = useRef(null);
    const setClockingWithDelay = useTimeout(setClocking, [setClocking], _p.clockingAnimationTime);
    const cleanups = useRef([
        () => {
            if (autoRepeatTimer.current) {
                clearTimeout(autoRepeatTimer.current);
            }
        }
    ]);

    // -- Action Payload stuff (published payload for 'forAction' event)
    // There's got to be a better way of handling this.  Right now this is sort of a grab bag
    // of values that are somtimes needed by the rules engine when a button is clicked.

    // Provide the active record context data for any rules engine listeners
    actionPayload.activeRecord = useActiveRecord();
    // provide unique identifier for this button
    actionPayload.hNodeId = id;
    // allow rules engine listener to know what dbView is being used in this context
    // This name is dependent on react context, so cannot just derive it in the rules engine.
    actionPayload.dbViewName = useGetDbViewName(namespace, relation);

    const onClick = useCallback(
        async function onClick(e) {
            e.preventDefault();
            e.stopPropagation();

            function doPublish() {
                // If the button is marked to include the grid selection, instead of going directly to the rules engine
                // we first emit a `selection` action, which is handled by the MultiSelectBoundary
                // the MultiSelectBoundary will add the current grid selection to the request, and emit a "bulk" action
                // willBulk_namespace_relation.js will take that selection, and expand and replace it with the actual record that were selected
                // finally, a doingBulk_namespace_relation_<forAction> is responsible for the actual handling of that bulk action
                if (hNode.withSelection) {
                    publish(actionPayload, {
                        verb: 'selection',
                        namespace,
                        relation,
                        routePath: currentRoute,
                        type: forAction
                    });
                } else {
                    publish(actionPayload, {
                        verb: forAction,
                        namespace,
                        relation,
                        type: subtype,
                        routePath: currentRoute
                    });
                }
            }

            function repeat(repetition = 0) {
                doPublish();
                // but stop after a maximum of 60 repeats
                if (repetition + 1 < _p.maxRepetitionCount) {
                    autoRepeatTimer.current = setTimeout(() => repeat(repetition + 1), autoRepeatSeconds * 1000);
                } else {
                    setAutoRepeat(false);
                    setClockingWithDelay(false);
                    if (autoRepeatTimer.current) {
                        clearInterval(autoRepeatTimer.current);
                    }
                }
            }

            if (autoRepeatSeconds) {
                if (autoRepeat) {
                    setAutoRepeat(false);
                    setClockingWithDelay(false);
                    if (autoRepeatTimer.current) {
                        clearTimeout(autoRepeatTimer.current);
                    }
                } else {
                    setAutoRepeat(true);
                    setClocking(true);
                    // kick off the first repetition, which will automatically reschedule as long as necessary.
                    repeat(0);
                }
                return;
            }
            if (showBusyUntilFinished) {
                setClocking(true);
            }

            doPublish();
        },
        [
            autoRepeatSeconds,
            showBusyUntilFinished,
            hNode.withSelection,
            autoRepeat,
            setClockingWithDelay,
            publish,
            actionPayload,
            namespace,
            relation,
            currentRoute,
            forAction,
            subtype
        ]
    );

    // When unmounting, perform any cleanups requested by the forAction successes
    useEffect(() => {
        if (cleanups.current == null) {
            throw new Error('The cleanups array reference should always exists.');
        }
        const holdCleanups = cleanups.current;
        return () => holdCleanups.forEach(cleanup => cleanup());
    }, []);

    /**
     * If showBusyUntilFinished is true, then make the button have a busy status
     * until the forAction verb's success/failure is fired.
     */
    useEffect(() => {
        function handler(result) {
            if (result?.cleanup != null) {
                cleanups.current.push(result.cleanup);
            }
            setClockingWithDelay(false);
        }
        if (!autoRepeatSeconds && showBusyUntilFinished) {
            const unsubscribes = [
                subscribe(
                    { verb: forAction, namespace, relation, routePath: currentRoute, type: subtype, status: 'success' },
                    handler
                ),
                subscribe(
                    { verb: forAction, namespace, relation, routePath: currentRoute, type: subtype, status: 'failure' },
                    handler
                ),
                subscribe(
                    {
                        verb: 'selection',
                        namespace,
                        relation,
                        type: forAction,
                        routePath: currentRoute,
                        status: 'success'
                    },
                    handler
                ),
                subscribe(
                    {
                        verb: 'selection',
                        namespace,
                        relation,
                        type: forAction,
                        routePath: currentRoute,
                        status: 'failure'
                    },
                    handler
                )
            ];
            return () => unsubscribes.forEach(u => u());
        }
    }, [
        setClockingWithDelay,
        forAction,
        namespace,
        relation,
        currentRoute,
        subscribe,
        showBusyUntilFinished,
        subtype,
        autoRepeatSeconds
    ]);

    return rc(Button, {
        id,
        hNode,
        clocking,
        value: title,
        onClick,
        disabled,
        buttonStyle,
        icon: iconName,
        displayTitleOnButton,
        currentRoute,
        ...otherProps
    });
}
ActionButton.propTypes = {
    hNode: PropTypes.shape({
        id: PropTypes.string,
        forAction: PropTypes.string,
        subtype: PropTypes.string,
        title: PropTypes.string,
        disabled: PropTypes.bool,
        namespace: PropTypes.string,
        relation: PropTypes.string,
        iconName: PropTypes.string,
        displayTitleOnButton: PropTypes.bool,
        buttonStyle: PropTypes.string,
        showBusyUntilFinished: PropTypes.bool,
        withSelection: PropTypes.bool
    }).isRequired,
    actionPayload: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    currentRoute: PropTypes.string
};
