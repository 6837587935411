//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _change_sensor_tag_success from './change_sensor_tag_success';
import _create_sensor_tag_success from './create_sensor_tag_success';
import _doingCancel_sensor_tag from './doingCancel_sensor_tag';
import _doingChange_sensor_tag from './doingChange_sensor_tag';
import _doingCreate_sensor_tag_AT from './doingCreate_sensor_tag_AT';
import _doingCreate_sensor_tag_IT from './doingCreate_sensor_tag_IT';
import _doingNew_sensor_tag from './doingNew_sensor_tag';
import _doingValidate_sensor_tag from './doingValidate_sensor_tag';
import _willChange_sensor_tag from './willChange_sensor_tag';
export const change_sensor_tag_success = _change_sensor_tag_success;
export const create_sensor_tag_success = _create_sensor_tag_success;
export const doingCancel_sensor_tag = _doingCancel_sensor_tag;
export const doingChange_sensor_tag = _doingChange_sensor_tag;
export const doingCreate_sensor_tag_AT = _doingCreate_sensor_tag_AT;
export const doingCreate_sensor_tag_IT = _doingCreate_sensor_tag_IT;
export const doingNew_sensor_tag = _doingNew_sensor_tag;
export const doingValidate_sensor_tag = _doingValidate_sensor_tag;
export const willChange_sensor_tag = _willChange_sensor_tag;
export default {change_sensor_tag_success, create_sensor_tag_success, doingCancel_sensor_tag, doingChange_sensor_tag, doingCreate_sensor_tag_AT, doingCreate_sensor_tag_IT, doingNew_sensor_tag, doingValidate_sensor_tag, willChange_sensor_tag};

