import defaultChange from '../../namespace/relation/doingChange_namespace_relation';
import { constants } from 'lib_ui-services';
const { useCaseIds } = constants;

export default {
    verb: 'doingChange',
    description: 'Merge changes into the given namespace/relation record.',
    namespace: 'deploy',
    relation: 'environment',
    type: 'singleRecordForm',
    excludedUseCaseIds: [useCaseIds.ONE_TOUCH],
    //this is the actual logic:
    logic: defaultChange.logic
};
