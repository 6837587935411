import { createElement as rc, forwardRef, useCallback } from 'react';
import useFormControl from '../../hooks/useFormControl';
import { styled } from 'lib_ui-primitives';
import FormField from './FormField';
import PropTypes from 'prop-types';
import useHasFeatureFlag from '../../hooks/useHasFeatureFlag';
import conversions from '@sstdev/lib_epc-conversions';
import ScanTextInput from '../ScanTextInput';

const defaultHexRegex = new RegExp('^[0-9A-F]*$', 'i');

const StyledTextInput = styled(ScanTextInput)`
    flex-grow: 1;
    margin-top: 6px;
`;

const _p = {
    useFormControl
};
export const _private = _p;

const ShortEncodedText = forwardRef((props, ref) => {
    const {
        autoComplete = 'off',
        hNode,
        hNode: {
            id,
            max,
            maxLength = 256,
            min,
            minLength = 0,
            placeholder,
            pattern,
            propertyName,
            rows,
            treePosition: { sequence }
        },
        type = 'textbox',
        onKeyPress,
        onChange: _onChange
    } = props;

    const displayInAscii = useHasFeatureFlag('displayInAscii');

    const { title, value, setValue, disabled, errors, onBlur, onFocus, active, addErrorMessage } =
        _p.useFormControl(props);

    const fieldEmpty = value === '';

    const onChange = useCallback(
        (text, inputSource) => {
            let result = text || '';
            if (displayInAscii) {
                result = conversions.ascii.toHex(result, true);
            } else {
                result = result.toUpperCase();
                if (!result.match(defaultHexRegex)) {
                    addErrorMessage('Only 0-9 and A-F characters are allowed.');
                }
            }

            setValue(result, inputSource);
            _onChange?.(result, inputSource);
        },
        [_onChange, addErrorMessage, displayInAscii, setValue]
    );

    const displayValue = displayInAscii ? conversions.ascii.fromHex(value ?? '') : value;
    // prettier-ignore
    return rc(FormField, { hNode, title, errors, active, fieldEmpty, onBlur },
        rc(StyledTextInput, {
            hNode,
            active,
            ref,
            id,
            name: propertyName,
            type,
            disabled,
            autoComplete,
            spellCheck: false,
            max,
            maxLength,
            min,
            minLength,
            pattern: displayInAscii ? pattern : pattern ?? defaultHexRegex,
            placeholder,
            multiline: true,
            rows: (rows === 1 && displayValue.length > 24) ? 3 : rows,
            value: displayValue,
            onKeyPress,
            onChange,
            onFocus,
            sequence
        })
    );
});
ShortEncodedText.displayName = 'ShortEncodedText';
ShortEncodedText.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired,
    autoComplete: PropTypes.string,
    multiline: PropTypes.bool,
    type: PropTypes.string,
    onKeyPress: PropTypes.func,
    onChange: PropTypes.func
};

export default ShortEncodedText;
