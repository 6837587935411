import { useState, useEffect } from 'react';
import { network } from 'lib_ui-services';
import useEventSink from './useEventSink';
/**
 * @typedef NetworkStatus
 * @property {boolean} isConnected Being connected does NOT guarantee being able to reach our server
 * @property {boolean} isServerReachable Theoretically, this should give a guarantee we can actually connect to the server
 * @property  {'bluetooth'|'cellular'|'ethernet'|'none'|'wifi'|'wimax'|'other'|'unknown'} type The type of connection a device is using to communicate with the network
 * @property {'2g' | '3g' | '4g' | 'slow-2g' | undefined} effectiveType
 */

const _p = {
    getNetworkStatus: network.getStatus
};
export const _private = _p;

/**
 * @returns {NetworkStatus}
 */
export default function useNetwork() {
    const [status, setStatus] = useState({
        isOnline: false,
        isConnected: false,
        isServerReachable: false,
        type: 'unknown',
        effectiveType: 'unknown'
    });
    useEffect(() => {
        let allowStateUpdate = true;
        async function doAsync() {
            const newStatus = await _p.getNetworkStatus();
            if (allowStateUpdate) {
                setStatus(newStatus);
            }
        }
        doAsync();
        return () => (allowStateUpdate = false);
    }, []);

    const [subscribe] = useEventSink();
    useEffect(() => {
        return subscribe({ verb: 'update', namespace: 'application', relation: 'network' }, payload =>
            setStatus(payload)
        );
    }, [subscribe]);

    return status;
}
