export default {
    verb: 'willUpsert',
    namespace: 'identity',
    relation: 'user',
    prerequisites: [],
    description: 'fudget in some sort of meta',
    priority: 10, //before the default willUpsert
    //this is the actual logic:
    logic: willUpsert
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willUpsert({ data }) {
    const items =
        data.items?.map(user => {
            const { meta, ...rest } = user;
            return {
                ...rest,
                meta: {
                    ...meta,
                    modifiedBy: { _id: '000000000000000000000000', title: 'recorded on server' },
                    modifiedTime: new Date().toISOString()
                }
            };
        }) || [];
    return {
        ...data,
        items
    };
}
