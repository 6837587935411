import doingNew from '../../FORMS/namespace/relation/doingNew_namespace_relation';
export default {
    verb: 'doingNew',
    namespace: 'security',
    relation: 'profile',
    type: 'setOfflinePin',
    description: 'New a record for setOfflinePin using the default logic.',
    priority: 0,
    //this is the actual logic:
    logic: doingNew.logic
};
