import { ObjectId } from 'lib_ui-services';

export default {
    verb: 'doingCreate',
    namespace: 'metadata',
    relation: 'page',
    description: 'Bump build number and add patch record for useCaseDetail',
    status: 'success',
    //type: '',
    //priority:0,
    //useCaseIds:[]
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'metadata',
                relation: 'useCaseDetail',
                type: 'find',
                status: undefined
            },
            query: ({ data }) => {
                const {
                    'metadata:useCaseDetail': { _id }
                } = data.newRecord;
                return {
                    _id,
                    'meta.deleted': { $exists: false }
                };
            }
        }
    ],
    //this is the actual logic:
    logic: createSuccess
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function createSuccess({ data, prerequisiteResults, dispatch }) {
    if (data.clone) return;
    const newPage = data.result || data.newRecord;
    const useCaseDetail = prerequisiteResults[0].result?.[0];
    //if this was a cloning, there is no useCaseDetail record yet
    if (!useCaseDetail) return;
    const { major, minor = 0, patch = 0, build = 0, version: oldVersion } = useCaseDetail;

    const version = `${major}.${minor}.${patch}.${build + 1}`;
    //bump the version number
    dispatch(
        { oldRecord: useCaseDetail, newRecord: { ...useCaseDetail, build: build + 1, version, verified: false } },
        { verb: 'update', namespace: 'metadata', relation: 'useCaseDetail' }
    );
    //add a simplified history "patch" record to track the rough changes.
    const patchRecord = {
        _id: ObjectId(),
        'metadata:useCaseDetail': data.newRecord['metadata:useCaseDetail'],
        patches: [
            {
                op: 'update',
                path: '/version',
                value: version,
                oldValue: oldVersion
            },
            {
                op: 'add',
                path: '/page',
                value: newPage.title
            }
        ]
    };
    //create a patch record
    dispatch({ newRecord: patchRecord }, { verb: 'create', namespace: 'metadata', relation: 'changeHistory' });
}
