import styled from '../styled';
import PropTypes from 'prop-types';
import { useRef, useLayoutEffect, useContext, createElement as rc, useCallback } from 'react';
import { GestureContext } from './contextProviders/GestureRecognizer';
import ScrollBoundary, { ScrollContext } from './contextProviders/ScrollBoundary';
import cuid from 'cuid';
import useScrollLayoutChange from '../hooks/useScrollLayoutChange';

const InnerScrollView = styled.div.attrs()`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: ${props => (['vertical', 'both'].includes(props.scrollDirection) ? 'auto' : 'hidden')};
    overflow-x: ${props => (['horizontal', 'both'].includes(props.scrollDirection) ? 'auto' : 'hidden')};
`;

function ScrollHandler(props) {
    // This stuff handles swipe gestures
    const {
        blockLeftSwipe,
        blockRightSwipe,
        onScroll: _onScroll,
        id,
        onScrollLayoutChange = () => {
            /*default to noop*/
        }
    } = props ?? {};
    const { suspendLeftSwipe, suspendRightSwipe } = useContext(GestureContext);

    // This stuff informs the ScrollBoundary that scrolling has occurred.
    const scrollContext = useContext(ScrollContext);
    const ref = useScrollLayoutChange(
        useCallback(
            (newLayout, e) => {
                scrollContext.scroll(e);
                _onScroll?.(e);
                onScrollLayoutChange(newLayout);
            },
            [onScrollLayoutChange, _onScroll, scrollContext]
        )
    );

    useLayoutEffect(() => {
        const releases = [];
        if (!ref.current) {
            throw new Error('No reference for the scrolling element is defined.');
        }
        if (blockLeftSwipe) {
            releases.push(suspendLeftSwipe(() => ref.current.getBoundingClientRect()));
        }
        if (blockRightSwipe) {
            releases.push(suspendRightSwipe(() => ref.current.getBoundingClientRect()));
        }
        return () => releases.forEach(release => release());
    }, [blockLeftSwipe, blockRightSwipe, suspendRightSwipe, suspendLeftSwipe, ref]);

    // Create a method on the ref to measure the size of this component.  This needs to be consistent between
    // react native and react web.
    useLayoutEffect(() => {
        if (ref.current != null) {
            ref.current.measureRect = async () => {
                return ref.current.getBoundingClientRect();
            };
            // For some reason the x axis scroll position is not starting at 0.  This is a hack to fix that.
            ref.current.scrollLeft = 0;
        }
    }, [ref]);

    // Need a unique test id for this instance - only set once because of useRef.
    const testId = useRef('scrollview' + (id || cuid()));
    // Render the actual scrolling view here. Override any onScroll methods in props.
    return rc(InnerScrollView, { 'data-testid': testId.current, id: testId.current, ref, ...props });
}

function ScrollView(props) {
    // prettier-ignore
    return rc(ScrollBoundary, null,
        rc(ScrollHandler, props)
    );
}

ScrollView.defaultProps = {
    scrollDirection: 'vertical',
    name: 'ScrollView'
};

ScrollView.propTypes = {
    scrollDirection: PropTypes.oneOf(['vertical', 'horizontal', 'both']),
    name: PropTypes.string
};

export default ScrollView;
