import { createElement as rc, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ScanTextInput from '../ScanTextInput';
import FormField from './FormField';
import { View, Chip, Button, styled, fromTheme } from 'lib_ui-primitives';
import useFormControl from '../../hooks/useFormControl';

const StyledTextInput = styled(ScanTextInput)`
    flex-grow: 1;
`;
const Container = styled(View).attrs({ name: 'multipicker-shorttext' })`
    flex-direction: column;
    background-color: ${fromTheme('backgroundColor')};
    padding-left: ${fromTheme('viewMargin')};
    max-width: ${({ theme }) => theme.form.entryMaxWidth - theme.viewMargin}px;
`;
Container.displayName = 'Container';

const TagContainer = styled(View).attrs({ name: 'tag-container' })`
    flex-wrap: wrap;
    padding-bottom: ${fromTheme('viewPadding')};
`;
TagContainer.displayName = 'TagContainer';

const EMPTY_ARRAY = [];
const _p = {
    useFormControl
};
export const _private = _p;
/**
 * Allow selection of an array of ShortText values
 */
function MultiPickerShortText(props) {
    const {
        autoComplete = 'off',
        onKeyPress,
        hNode,
        hNode: { id, max, maxLength, min, minLength, placeholder, pattern, propertyName, rows, treePosition },
        multiline,
        type = 'textbox'
    } = props;
    const {
        autoFocus,
        title,
        value: selectedOptions = EMPTY_ARRAY,
        setValue: setSelectedOptions,
        disabled,
        errors,
        onBlur,
        onFocus,
        active
    } = _p.useFormControl(props);
    const [addValue, setAddValue] = useState('');
    const fieldEmpty = addValue === '';

    const removeOption = useCallback(
        function (optionToRemove) {
            let newSelectedOptions = [...selectedOptions];
            const index = newSelectedOptions.indexOf(optionToRemove);
            newSelectedOptions.splice(index, 1);
            if (newSelectedOptions.length === 0) {
                newSelectedOptions = undefined; // Avoids a problem with validation
            }
            setSelectedOptions(newSelectedOptions);
        },
        [setSelectedOptions, selectedOptions]
    );

    const addOption = useCallback(
        function () {
            const optionToAdd = addValue;
            const newSelectedOptions = [...selectedOptions, optionToAdd];
            setAddValue('');
            setSelectedOptions(newSelectedOptions);
        },
        [addValue, selectedOptions, setSelectedOptions]
    );

    // prettier-ignore
    return rc(Container, null,
        rc(FormField, { hNode, title, errors, active, fieldEmpty, onBlur },
            rc(StyledTextInput, {
                hNode,
                active,
                id,
                name: propertyName,
                type,
                disabled,
                autoComplete,
                spellCheck: false,
                max,
                maxLength,
                min,
                minLength,
                pattern,
                placeholder: active ? placeholder ?? '' : '',
                multiline,
                rows,
                value: addValue,
                onKeyPress,
                onChange: setAddValue,
                onFocus,
                autoFocus,
                sequence: treePosition.sequence
            }),
            rc(Button, { title: `Add ${title}`, onClick: addOption, icon: 'add', buttonStyle: 'round' })
        ),
        rc(TagContainer, null,
            selectedOptions.map(selectedOption =>
                rc(Chip, { key: selectedOption, id: selectedOption, value: selectedOption, onClick: () => removeOption(selectedOption) })
            )
        )
    );
}

MultiPickerShortText.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired,
    onKeyPress: PropTypes.func,
    autoComplete: PropTypes.string,
    multiline: PropTypes.bool,
    type: PropTypes.string
};
export default MultiPickerShortText;
