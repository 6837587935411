//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _SideNav from './SideNav';
import _TabNav from './TabNav';
export const SideNav = _SideNav;
export const TabNav = _TabNav;
export default {SideNav, TabNav};

