import react from 'react';
import { styled, Text, Icon, View } from 'lib_ui-primitives';
import PropTypes from 'prop-types';

const rc = react.createElement;

const LeftToRight = styled(View)`
    flex-direction: row;
    gap: 12px;
    margin-bottom: 12px;
    align-items: center;
`;

export default function FixedText(props) {
    const { id, title, iconName, fontSize = 16 } = props.hNode;
    if (iconName) {
        return rc(
            LeftToRight,
            { id },
            rc(Icon, { color: 'transparent' }, iconName),
            rc(Text, { style: { fontSize } }, title)
        );
    }
    return rc(Text, { id, style: { fontSize } }, title);
}

FixedText.propTypes = {
    hNode: PropTypes.shape({
        title: PropTypes.string,
        iconName: PropTypes.string,
        fontSize: PropTypes.number
    }).isRequired
};
