/**
 * Get (an array containing) a database entry using the entry's _id.
 */
export default database => (payload, context) => {
    const { namespace, relation } = context;
    const { _id } = payload;

    return database
        .relationDb(namespace, relation)
        .get(_id)
        .then(result => database.processSuccess(result, payload, context))
        .catch(err => {
            // Do not dispatch failures for empty results.
            if (err.status === 404) {
                return database.processSuccess([], payload, context);
            }
            throw err;
        });
};
