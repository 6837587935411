export default _private => {
    const { startPromise, collection } = _private;
    return function removeMany(criteria) {
        return startPromise.then(() => {
            const results = collection.chain().find(criteria);
            const count = results.filteredrows.length;
            results.remove();
            return { deleted: count };
        });
    };
};
