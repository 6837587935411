import { useCallback, useEffect, useState, useMemo } from 'react';
import { authentication } from 'lib_ui-services';
import log from '@sstdev/lib_logging';

const _p = {
    getCurrentSession: authentication.getCurrentSession,
    authorizeOAuthUser: authentication.authorizeOAuthUser
};

export const _private = _p;

export default function useAuthentication() {
    const [profile, setProfile] = useState(undefined);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    //default use: look for our own stored sessions
    useEffect(() => {
        let allowStateUpdate = true;
        async function doAsync() {
            try {
                const storedUser = await _p.getCurrentSession();
                if (allowStateUpdate) {
                    setProfile(storedUser);
                    setIsAuthenticated(!!storedUser);
                }
            } catch (error) {
                log.error('[AUTHENTICATION] ' + error.stack);
            }
            setIsLoading(false);
        }

        doAsync();
        return () => (allowStateUpdate = false);
    }, []);

    //secondary use: translate OAuth user into backbone user
    const authorizeOAuthUser = useCallback(async function authorizeOAuthUser(
        oauthUser,
        groupNumber,
        getAccessTokenSilently,
        logout
    ) {
        setIsLoading(true);
        const storedUser = await _p.authorizeOAuthUser(oauthUser, groupNumber, getAccessTokenSilently, logout);
        setProfile(storedUser);
        setIsAuthenticated(!!storedUser);
        setIsLoading(false);
        return !!storedUser;
    }, []);

    const result = useMemo(
        () => ({
            isAuthenticated,
            isLoading,
            profile,
            authorizeOAuthUser
        }),
        [authorizeOAuthUser, isAuthenticated, isLoading, profile]
    );
    return result;
}
