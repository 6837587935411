import logging from '@sstdev/lib_logging';
import { sensors, registeredSensorServiceQueues } from 'lib_ui-services';
export default {
    verb: 'doingRegister',
    namespace: 'sensor',
    relation: 'service',
    description: 'Register a service that will provide sensor reads',
    logic
};

async function logic({ data, context, dispatch }) {
    try {
        const { type, name } = data;
        // no re-registering the same service multiple times:
        if (registeredSensorServiceQueues.some(s => s.name === name && s.type === type)) return;

        const service = sensors[name];
        service.init(dispatch);
        registeredSensorServiceQueues.push({ type, name, service });
    } catch (err) {
        logging.error(err, context);
    }
}
