import ZPL from './ZPL';
import ZNode from './ZNode';
import { QRCODE_DOT_SIZE } from './zNodes/QRContent';

export async function generate(data, template, labelType = {}, printer, options = {}) {
    const sizeSpecification = createSizeSpecificationLabel(labelType, printer);
    const calibrationSpec = createCalibrationLabel(options.calibrateRfid, labelType, printer);

    const parsedTemplate = parseTemplate(template, labelType, printer);

    const actualLabels = await Promise.all(data.map(record => renderTemplate(parsedTemplate, record)));
    if (calibrationSpec) {
        return [sizeSpecification, calibrationSpec].join('\n\n');
    }
    return [sizeSpecification, ...actualLabels].filter(Boolean).join('\n\n');
}

async function renderTemplate(template, record) {
    return (await ZNode({ record, zNode: template })).toString();
}

function createSizeSpecificationLabel(labelType, printer) {
    const { height = 1, width = 2, overrides } = labelType;
    const dpi = printer.dpi || 203;

    let labelWidth = Math.round(width * dpi);
    let labelHeight = Math.round(height * dpi);
    let offset = { x: 0, y: 0 };

    if (overrides) {
        //convert override values to dots from inches
        if (overrides.offset) {
            offset = {
                x: Math.round(overrides.offset.x * dpi),
                y: Math.round(overrides.offset.y * dpi)
            };
        }
        if (overrides.labelLength) {
            labelHeight = Math.round(overrides.labelLength * dpi);
        }
        if (overrides.labelWidth) {
            labelWidth = Math.round(overrides.labelWidth * dpi);
        } else if (offset.x > 0) {
            labelWidth = labelWidth + 2 * offset.x;
        }
    }

    return ZPL.createLabel(labelWidth, labelHeight)
        .addLabelSpec(offset, overrides?.mediaMode)
        .addComment(`width: ${round(labelWidth / dpi)}", height: ${round(labelHeight / dpi)}"`)
        .toString();
}

function round(value, decimals = 2) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

function createCalibrationLabel(calibrateRfid, labelType, printer) {
    if (!calibrateRfid) return '';
    const { height = 1, width = 2 } = labelType;
    const dpi = printer.dpi || 203;

    let labelWidth = Math.round(width * dpi);
    let labelHeight = Math.round(height * dpi);
    return ZPL.createLabel(labelWidth, labelHeight).calibrateRfid().toString();
}

function parseTemplate(template, labelType, printer) {
    const { height = 1, width = 2, padding: paddingInInches = 0.04 } = labelType;
    const dpi = printer.dpi || 203;

    let labelWidth = Math.round(width * dpi);
    let labelHeight = Math.round(height * dpi);

    // padding is the space between the edge of the paper and the edge of the content
    // paper labels usually have about 1mm (0.04"), but on-metal rfid labels need more like a good 2 mm
    const padding = Math.round(paddingInInches * dpi);

    // there are 3 kinds of content:
    // 1. fixed position: the rfid icon (and rfid stuff itself)
    // 2. layout influencing content: QR codes that are left or right aligned
    // 3. inline (in column) content: everything else

    const { fixed, qr, inline } = template.reduce(
        (acc, content) => {
            switch (content.hNodeType) {
                case 'RfidContent': {
                    acc.fixed.push(content);
                    break;
                }
                case 'QRContent': {
                    if (typeof content.alignRight === 'boolean') {
                        acc.qr = content;
                    } else {
                        acc.inline.push(content);
                    }
                    break;
                }
                case 'BarcodeContent': {
                    acc.inline.push(content);
                    if (shouldIncludeHumanReadable(content)) {
                        acc.inline.push({ ...content, hNodeType: 'ReferencedContent' });
                    }
                    break;
                }
                default: {
                    acc.inline.push(content);
                    break;
                }
            }
            return acc;
        },
        { fixed: [], qr: null, inline: [] }
    );

    // now build up the parsed template
    let parsedTemplate = {
        hNodeType: 'NewLabel',
        width: labelWidth,
        height: labelHeight,
        padding,
        offset: { x: 0, y: 0 },
        content: [
            {
                hNodeType: 'ColumnLayout',
                width: labelWidth,
                height: labelHeight,
                content: [...fixed]
            }
        ]
    };
    // we only get qr separated out if it was not inline.
    if (qr) {
        parsedTemplate.content[0].content.push(qrToSplitLayout(qr, inline, dpi, labelWidth, labelHeight, padding));
    } else {
        parsedTemplate.content[0].content = [...parsedTemplate.content[0].content, ...inline];
    }
    return parsedTemplate;
}

function qrToSplitLayout(qrContent, inlineContent, dpi, labelWidth, labelHeight, padding = 8) {
    let qrSize = qrContent.size || 4;
    while (QRCODE_DOT_SIZE[dpi][qrSize] * 1.4 > labelHeight) qrSize--;
    qrContent.size = qrSize;
    qrContent.dpi = dpi;

    // include the 1 outside padding to the "QR width":
    let qrWidth = Math.round(1.2 * QRCODE_DOT_SIZE[dpi][qrSize] + padding);

    if (qrContent.alignRight) {
        return {
            hNodeType: 'SplitLayout',
            left: {
                offset: { x: 0, y: 0 },
                content: [
                    {
                        hNodeType: 'ColumnLayout',
                        width: labelWidth - qrWidth,
                        height: labelHeight,
                        content: inlineContent
                    }
                ]
            },
            right: {
                offset: { x: labelWidth - qrWidth, y: 0 },
                content: [
                    {
                        hNodeType: 'ColumnLayout',
                        width: qrWidth,
                        height: labelHeight,
                        // if the QR is on the right, no padding should be included
                        // in the QR code location calculation
                        content: [{ ...qrContent, padding: 0 }]
                    }
                ]
            }
        };
    } else {
        return {
            hNodeType: 'SplitLayout',
            left: {
                offset: { x: 0, y: 0 },
                content: [
                    {
                        hNodeType: 'ColumnLayout',
                        width: qrWidth,
                        height: labelHeight,
                        content: [{ ...qrContent, padding }]
                    }
                ]
            },
            right: {
                offset: { x: qrWidth, y: 0 },
                content: [
                    {
                        hNodeType: 'ColumnLayout',
                        width: labelWidth - qrWidth,
                        height: labelHeight,
                        content: inlineContent
                    }
                ]
            }
        };
    }
}

function shouldIncludeHumanReadable(content) {
    //we are having some issues with nested toggles. this will allow us to continue using either include or exclude
    // default: show the human readable under the barcode
    let humanReadableOnBarcode = true;
    if (typeof content.excludedHumanReadable === 'boolean') {
        // support typo in Asset Tracking configuration
        humanReadableOnBarcode = !content.excludedHumanReadable;
    } else if (typeof content.excludeHumanReadable === 'boolean') {
        humanReadableOnBarcode = !content.excludeHumanReadable;
    } else if (typeof content.includeHumanReadable === 'boolean') {
        humanReadableOnBarcode = content.includeHumanReadable;
    }
    return humanReadableOnBarcode;
}

export default { generate };
