import conversions from '@sstdev/lib_epc-conversions';
import logging from '@sstdev/lib_logging';

const _p = {
    getBrowserTimezone
};

export const _private = _p;

/**
 * Transforms the value of records based on the feature flags enabled and the navigation context
 * @param {Object[]} records
 * @param {Object[]} columns
 * @param {string[]} [featureFlags]
 * @param {{namespace:string, relation:string, record: object, available:boolean, loading:boolean }} navigationSelection
 */
export function transformRecords(records, columns, featureFlags = [], navigationSelection) {
    const displayInAscii = featureFlags.includes('displayInAscii');
    const multiInventory = featureFlags.includes('multiInventory');

    // If multiInventory is enabled, filter the records by the selected inventory
    if (multiInventory) {
        records = filterInventoryByNavigationSelection(records, navigationSelection);
    }

    const propertiesToTransformToAscii = columns
        .filter(column => column.dataType === 'encodedText')
        .map(column => column.propertyName);

    records.forEach(record => {
        if (displayInAscii) transformToAscii(record, propertiesToTransformToAscii);
    });
}

/**
 * Filters out the inventories that are not part of the selected inventory based on the navigation context
 * @param {Object[]} records
 * @param {{namespace:string, relation:string, record: object, available:boolean, loading:boolean }} navigationSelection
 * @returns {Object[]}
 */
export function filterInventoryByNavigationSelection(records, navigationSelection) {
    const selectedInventoryId = navigationSelection?.record?._id;
    if (!selectedInventoryId) return records;
    records.forEach(item => {
        if (Array.isArray(item.inventory)) {
            item.inventory = item.inventory.filter(inventoryItem => {
                return (
                    inventoryItem['inventory:inventory'] &&
                    inventoryItem['inventory:inventory']._id === selectedInventoryId
                );
            });
        }
    });
    return records;
}

/**
 * Takes one record and converts all encodedText properties to ascii
 * @param {Object} record
 * @param {string[]} propertiesToTransform
 */
export function transformToAscii(record, propertiesToTransform) {
    propertiesToTransform.forEach(property => {
        // Exporting with no tagId will cause the export to fail
        record[property] = record[property] ? conversions.ascii.fromHex(record[property]) : '';
    });
}

export async function applyDidGet(records, { namespace, relation }, dispatch) {
    return dispatch(
        {
            result: records
        },
        { verb: 'didGet', namespace, relation },
        true
    )
        .then(payload => {
            return payload?.result || records;
        })
        .catch(logging.error);
}

/**
 * Adds the browser's timezone to the dateTime and localVerboseDateTime columns
 * This is used to ensure that the exported data is set for the correct timezone when generating server-side exports
 * @param {Object[]} columns
 * @returns {Object[]}
 */
export function addTimezoneToColumns(columns) {
    const timezone = _p.getBrowserTimezone();
    return columns.map(column => {
        if (column.dataType === 'localVerboseDateTime' || column.dataType === 'dateTime') {
            return { ...column, timezone };
        }
        return column;
    });
}

/**
 * Get the browser's timezone using Intl.DateTimeFormat.
 * @returns {string} The IANA timezone string.
 */
export function getBrowserTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
