// TODO: Refactor this to use the SensorReadButton and move the other stuff into a service or primitive

import { BarcodeScanner as BaseBarcodeScanner, Button, styled } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';
import { createElement as rc, useState, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import useEventSink from '../../hooks/useEventSink';

const ScanButton = styled(Button)`
    margin: 0;
`;

const CloseButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
    z-index: ${({ theme }) => theme.zindex.BarcodeScannerCloseButton};
`;

const _p = {
    BaseBarcodeScanner
};
export const _private = _p;
/**
 * @param {Object} props
 * @param {string} [props.id]
 * @param {(code: string) => void} [props.onScan]
 */
const BarcodeScanner = forwardRef(({ id, onScan = () => {}, disabled, tabIndex }, ref) => {
    const [scannerActive, setScannerActive] = useState(false);
    const [, publish] = useEventSink();
    const closeScanner = useCallback(() => {
        setScannerActive(false);
    }, []);

    const openScanner = useCallback(() => {
        if (!disabled) setScannerActive(true);
    }, [disabled]);

    const interceptOnScan = useCallback(
        result => {
            onScan(result);
            // Prefer sending this to the ReadContext rather than consuming onScan
            publish([{ _id: result, asciiTagId: result, sensorType: constants.sensorTypes.BARCODE }], {
                verb: 'change',
                namespace: 'sensor',
                relation: 'read'
            });
            closeScanner();
        },
        [onScan, closeScanner, publish]
    );

    const onError = useCallback(closeScanner, [closeScanner]);

    if (scannerActive && !disabled) {
        // prettier-ignore
        return rc(_p.BaseBarcodeScanner, { scannerActive, onScan: interceptOnScan, onError, tabIndex },
            rc(CloseButton, {
                alt: 'Close Barcode Scanner',
                icon: 'close',
                color: 'error',
                id: `closeCameraScanner_${id}`,
                hoverText: 'Close the Barcode Scanner',
                onClick: closeScanner,
                buttonStyle: 'IconAction'
            },
                'Close'
            )
        );
    }

    return rc(ScanButton, {
        ref,
        alt: 'Scan a Barcode',
        icon: 'barcode-scan',
        iconFont: 'MaterialCommunityIcon',
        id,
        hoverText: 'Scan a Barcode w/ Camera',
        onClick: openScanner,
        disabled,
        color: 'transparent',
        role: 'button',
        tabIndex
    });
});
BarcodeScanner.displayName = 'BarcodeScanner';
BarcodeScanner.propTypes = {
    id: PropTypes.string,
    onScan: PropTypes.func,
    disabled: PropTypes.bool,
    tabIndex: PropTypes.number
};

export default BarcodeScanner;
