export default {
    verb: 'doingNavigate',
    namespace: 'application',
    relation: 'route',
    description: 'Release the router to make the requested route transition',
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @template T
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 */
async function logic({ data }) {
    // If useNavigation has not run yet, this will do nothing.
    // Otherwise, finished with willNavigate stuff, so allow the route change
    data?.navigate?.doRouteChange();
}
