import { createElement as rc, useMemo } from 'react';
import PropTypes from 'prop-types';
import { View, Text, h4, fromTheme, styled, ScrollView } from 'lib_ui-primitives';
import useFormControl from '../../hooks/useFormControl';
import DropDownButton from '../_abstractComponent/DropDownButton';
import { WorkflowActionPane } from '.';
import { metadata } from 'lib_ui-services';
import createTreePositionGetter from '../../utilities/createTreePositionGetter';

const StyledActionList = styled(View)`
    background-color: ${fromTheme('backgroundColor')};
    flex-direction: column;
    padding: ${fromTheme('viewPadding')};
    margin-bottom: ${fromTheme('viewMargin')};
`;
const Header = styled(View).attrs({ name: 'header' })`
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    min-height: 45px;
`;

function WorkflowActionList(props) {
    const {
        hNode: { id, propertyName, propertyPath: parentPropertyPath, children: fieldTemplates, treePosition },
        currentRoute
    } = props;

    const { title, value, setValue, disabled } = useFormControl(props);

    const children = useMemo(() => {
        function remove(index) {
            setValue(prev => {
                return prev.filter((value, i) => i !== index);
            });
        }

        const getTreePosition = createTreePositionGetter(treePosition, value.length);

        function fromDefinition(fieldTemplates, entry, index) {
            const template = fieldTemplates.find(template => actionFromHNode(template) === entry.workflowAction);
            if (template) {
                return rc(WorkflowActionPane, {
                    hNode: {
                        ...template,
                        propertyPath: `${metadata.getPathToProperty({
                            propertyPath: parentPropertyPath,
                            propertyName
                        })}[${index}]`,
                        treePosition: getTreePosition(index)
                    },
                    key: index,
                    childIndex: index,
                    value: entry,
                    onRemove: () => remove(index),
                    disabled,
                    currentRoute
                });
            }
            //todo: remove
            return rc(Text, { key: index }, JSON.stringify(entry));
        }

        return value.map((entry, index) => fromDefinition(fieldTemplates, entry, index));
    }, [treePosition, value, setValue, parentPropertyPath, propertyName, disabled, currentRoute, fieldTemplates]);

    const templates = useMemo(() => {
        function add(workflowAction) {
            setValue(prev => {
                return [...prev, { workflowAction }];
            });
        }

        return fieldTemplates.map((hNode, i) => {
            const { title } = hNode || {};
            const action = actionFromHNode(hNode);

            return rc(DropDownButton.MenuItem, {
                hNode: { title },
                onClick: () => add(action),
                key: action + i
            });
        });
    }, [fieldTemplates, setValue]);

    // prettier-ignore
    return rc(StyledActionList, { id },
        rc(Header, null,
            rc(h4, null, title),
            rc(DropDownButton, { id, icon: 'add', disabled, color: 'success' },
                templates
            )
        ),
        rc(ScrollView, null,
            children
        )
    );
}

WorkflowActionList.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};
export default WorkflowActionList;

const actionFromHNode = hNode => {
    const { workflowAction, foreignNamespace, originalRelation, foreignRelation } = hNode || {};
    const action = workflowAction?.includes('_')
        ? workflowAction
        : `${workflowAction}_${foreignNamespace}_${originalRelation || foreignRelation}`;
    return action;
};
