import { executeForEach } from '../registeredServiceQueues';

export default {
    verb: 'doingStop',
    namespace: 'sensor',
    relation: 'locate',
    description: 'Stop locating in a service that will provide sensor locate data',
    logic
};

async function logic({ data, context }) {
    return executeForEach('stopLocating', data, context);
}
