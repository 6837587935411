import { createElement as rc } from 'react';
import { View, Text, Switch, fromTheme, styled } from 'lib_ui-primitives';

const SwitchLayout = styled(View).attrs({ displayName: 'switch-layout' })`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SwitchWithMargin = styled(Switch)`
    margin-left: ${fromTheme('textPadding')};
    margin-right: ${fromTheme('textPadding')};
    flex-shrink: 0;
`;

const ToggleOptions = styled(Text).attrs({ name: 'toggle-options' })`
    color: ${({ selected, theme }) => {
        return selected ? theme.defaultFontColor : theme.disabledFontColor;
    }};
`;
ToggleOptions.displayName = 'ToggleOptions';

const Title = styled(Text).attrs({ name: 'title' })`
    padding-right: ${fromTheme('textPaddingMore')};
`;
Title.displayName = 'title';

export default function Toggle(props) {
    const { id, value, falseLabel, trueLabel, onClick, title } = props;
    // prettier-ignore
    return rc(SwitchLayout, null,
            title && rc(Title, null, title),
            rc(ToggleOptions, {selected: !value}, falseLabel),
            rc(SwitchWithMargin, {value, onClick, id }),
            rc(ToggleOptions, {selected: value}, trueLabel)
        );
}
