import { executeForEach } from '../registeredServiceQueues';

export default {
    verb: 'doingStartup',
    namespace: 'sensor',
    relation: 'locate',
    description: 'Start locating using a service that will provide sensor locate (distance) data',
    logic
};

async function logic({ data, context }) {
    return executeForEach('locate', data, context);
}
