import registeredSensorServiceQueues from 'lib_ui-services';

export default {
    verb: 'doingGet',
    namespace: 'sensor',
    relation: 'service',
    description: 'Retrieve the list of registered services',
    logic: doingGet
};

async function doingGet() {
    return registeredSensorServiceQueues.map(({ name, type }) => ({ name, type }));
}
