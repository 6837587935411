import { createElement as rc, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import _Grid from '../../Grid';

import { styled, fromTheme, webOnlyStyles } from 'lib_ui-primitives';
import HeaderRow from '../../TableList/HeaderRow';
import useRowDetail from '../../TableList/useRowDetail';
import useUserContext from '../../../../hooks/useUserContext';
import getNestedPatchDetailColumnHNodes from './getNestedPatchDetailColumnHNodes';
import { getTransformedPatches } from 'lib_ui-services';
import useDataModel from '../../../../hooks/useDataModel';

// Clicks on the patch detail are a noop;
const noopFunction = () => {};
let Grid = styled(_Grid)`
    border-width: 1px;
    border-color: ${fromTheme('borderColor')};
`;
Grid = webOnlyStyles(Grid)`
    border-style: solid;
    min-height: 230px;
`;

const _p = { getTransformedPatches, useDataModel };
export const _private = _p;
function PatchDetail(props) {
    const {
        parentRecordId,
        patches,
        hNode: { id, namespace, relation }
    } = props || { hNode: {} };

    const userContext = useUserContext();
    const nonPatchRelation = relation.replace('-patch', '');
    const dataModel = _private.useDataModel(namespace, nonPatchRelation);

    const records = useMemo(() => {
        return _private.getTransformedPatches(parentRecordId, patches, userContext, dataModel);
    }, [patches, dataModel, parentRecordId, userContext]);

    const hNode = useHNode(id);
    const [RowDetail, onScrollLayoutChange] = useRowDetail(hNode);
    // jsDom does not have a layout engine, so onScrollLayoutChange needs to be set manually during testing
    _p.onScrollLayoutChange = onScrollLayoutChange;

    if (!patches || patches.length === 0) {
        return null;
    }

    return rc(Grid, {
        listType: 'table',
        records,
        recordCount: records.length,
        sortedColumns: {},
        onClick: noopFunction,
        HeaderRow,
        RowDetail,
        hNode,
        onScrollLayoutChange
    });
}

PatchDetail.propTypes = {
    hNode: PropTypes.object
};

export default memo(PatchDetail);

const useHNode = id => {
    return useMemo(
        () => ({
            id: `patch-detail-${id}`,
            hNodeType: 'TableList',
            hNodeTypeGroup: 'list',
            children: getNestedPatchDetailColumnHNodes()
        }),
        [id]
    );
};
