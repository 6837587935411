import { createElement as rc, useLayoutEffect, useState, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { globalConfig, filters, metadata } from 'lib_ui-services';
import useDbView from '../../hooks/useDbView';
import { TextInput, View, Button, styled, useDebounce } from 'lib_ui-primitives';
import logging from '@sstdev/lib_logging';
import useNetwork from '../../hooks/useNetwork';
import useGetDbViewName from '../../hooks/useDbView/useGetDbViewName';
const { filterHelpers } = filters;
const pageSize = globalConfig().pageSizeForLimitSyncSizeRelations;
const KeySearchContainer = styled(View)`
    align-items: center;
    justify-content: center;
`;

const _p = {
    useDbView,
    useGetDbViewName,
    getRelationMetadata: metadata.getRelationMetadata
};
export const _private = _p;

/* Implemented separately from ValueSearch.
 * Theoretically, KeySearch could be implemented as some combination of a SearchPane and a ValueSearch
 * With some additional logic to show/hide the input field. But that exercise will be left to the reader
 */
function KeySearch(props) {
    const {
        searchText,
        hNode,
        hNode: {
            id,
            namespace,
            relation,
            treePosition: { sequence }
        }
    } = props ?? { hNode: { treePosition: {} } };

    const { isConnected } = useNetwork();
    const [inputValue, setInputValue] = useState(searchText || '');
    const [showInput, setShowInput] = useState(false);
    const searchInputRef = useRef();
    const viewName = _p.useGetDbViewName(namespace, relation);
    const { viewCriteria } = _p.useDbView(namespace, relation, viewName, hNode);
    let defaultFilters = useMemo(() => filterHelpers.getDefaultFilters(hNode), [hNode]);
    let searchIconColor = 'transparent';

    const { supportsFullTextSearch } = _p.getRelationMetadata(namespace, relation);

    if (!defaultFilters?.propertiesToSearch) {
        // eslint-disable-next-line no-undef
        __PRODUCTION__ &&
            logging.error(
                'Missing `propertiesToSearch` filter on `KeySearch`. You might have to move it up from the grid in blockly.'
            );
        searchIconColor = 'error';
        defaultFilters = {
            propertiesToSearch: { propertiesToSearch: ['assetNo', 'title', 'description'] },
            ...defaultFilters
        };
    }

    const updateSearchOptions = useDebounce(
        /**
         *
         * @param {string} updatedSearchText
         */
        function (updatedSearchText) {
            if (updatedSearchText.trim() === '') {
                // This should also resets the page so that new server side lookups will start at
                // offset 0
                viewCriteria.removeFilters(viewName, 'fullTextSearch');
                // noop if there are no changes after removing the filter
                viewCriteria.applyFilters(defaultFilters, viewName);
            } else {
                viewCriteria.applyFilters(
                    {
                        // paged filter is a noop for a local lookup, but is needed for an online lookup.
                        paged: { page: 0, offset: 0, limit: supportsFullTextSearch ? pageSize : undefined },
                        ...defaultFilters,
                        fullTextSearch: {
                            searchTerm: updatedSearchText.trim()
                        }
                    },
                    viewName
                );
            }
        },
        [viewCriteria, defaultFilters, viewName, supportsFullTextSearch],
        globalConfig().searchDebounceMilliseconds || 250,
        {
            leading: false,
            trailing: true
        }
    );

    // Clear out the fullTextSearch filter when the component unmounts
    useEffect(() => {
        return () => {
            viewCriteria.removeFilters(viewName, 'fullTextSearch');
        };
    }, [viewCriteria, viewName]);

    const inputChanged = useDebounce(
        newValue => {
            setInputValue(newValue);
            updateSearchOptions(newValue);
        },
        [updateSearchOptions],
        globalConfig().searchDebounceMilliseconds || 250,
        {
            leading: false,
            trailing: true
        }
    );

    useLayoutEffect(() => {
        if (showInput && document.activeElement !== searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [showInput]);

    function onSearchClick(e) {
        e.preventDefault();
        setShowInput(prev => !prev);
    }

    function onCloseClick(e) {
        e.preventDefault();
        inputChanged('');
        setShowInput(false);
    }

    // prettier-ignore
    return rc(KeySearchContainer,
        {
            id,
            'data-testid': 'keysearch-container'
        },
        rc(Button, {
            id: `open${id}`,
            buttonStyle: 'round',
            color: searchIconColor,
            name: 'search-icon-container',
            onClick: onSearchClick,
            // `manage_search` has text underneath the magnifying glass.
            icon: (supportsFullTextSearch && isConnected) ? 'manage_search' : 'search',
            'data-testid': 'keysearch-open-button',
            'aria-expanded': showInput,
            'aria-controls': id + '_input'
        }),
        showInput ? rc(TextInput, {
            id: id + '_input',
            onChange: inputChanged,
            value: inputValue,
            maxLength: 100,
            ref: searchInputRef,
            placeholder: (supportsFullTextSearch && isConnected) ? 'Enter 3 characters...' : 'Search',
            sequence
        }) : null,
        showInput ? rc(Button, {
            id: `close${id}`,
            buttonStyle: 'round',
            color: 'transparent',
            name: 'close-icon-container',
            onClick: onCloseClick,
            icon: 'close',
            'data-testid': 'keysearch-close-button'
        }) : null
    );
}

KeySearch.propTypes = {
    dispatch: PropTypes.func,
    title: PropTypes.string,
    searchText: PropTypes.string,
    window: PropTypes.object,
    forAction: PropTypes.string,
    currentRoute: PropTypes.string,
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};

export default KeySearch;
