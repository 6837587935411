import { useContext, useMemo, useReducer } from 'react';
import { NDimArray } from 'lib_ui-services';
import CollapsibleHierarchyRowContext from './CollapsibleHierarchyRowContext';
import CollapsibleHierarchyContext from './CollapsibleHierarchyContext';
import logging from '@sstdev/lib_logging';

export default function useCollapsibleHierarchy(name) {
    const parentCollapsibleHierarchyRowContext = useContext(CollapsibleHierarchyRowContext);
    const currentHierarchyRowCoordinates = useMemo(
        () => parentCollapsibleHierarchyRowContext?.rowCoordinates ?? [0],
        [parentCollapsibleHierarchyRowContext?.rowCoordinates]
    );
    const [, forceRender] = useReducer(s => s + 1, 0);
    const previousContext = useContext(CollapsibleHierarchyContext);
    const newContext = useMemo(() => {
        const collapsed = new NDimArray(false);
        return {
            name,
            isCollapsed: coordinates => {
                return collapsed.get(coordinates);
            },
            setCollapsed: (value, coordinates) => {
                collapsed.set(value, coordinates);
            },
            deleteCollapsed: coordinates => {
                collapsed.deleteDim(coordinates);
            },
            observeCollapsed: (callback, coordinates) => {
                collapsed.observe(callback, coordinates);
            },
            addCollapsed: coordinates => {
                collapsed.addDim(coordinates);
            },
            expandAll: coordinates => {
                collapsed.setBranch(false, coordinates);
            },
            collapseAll: coordinates => {
                collapsed.setBranch(true, coordinates);
            },
            hasChildren: coordinates => {
                return collapsed.hasChildren(coordinates);
            }
        };
    }, [name]);

    const hierarchyContext = previousContext ?? newContext;

    if (name != null) {
        logging.debug(
            `[COLLAPSIBLE] useCollapsibleHierarchy context: ${hierarchyContext.name} list: ${name}, currentCoordinates: ${currentHierarchyRowCoordinates}`
        );
    }

    hierarchyContext.observeCollapsed(forceRender, currentHierarchyRowCoordinates);
    return {
        hierarchyContext,
        currentHierarchyRowCoordinates
    };
}
