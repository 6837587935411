import { createElement as rc } from 'react';
import PropTypes from 'prop-types';

import { Html } from 'lib_ui-primitives';

// we import the css here, but we can't do it like this in React Native.
// TODO: Remove comment when these are scoped to prevent them from being global
//import './licenseAgreement.css';

/**
 * @typedef {Object} Props
 * @property {string} html
 */
/** @type {import('react').FC<Props>} */
function Agreement({ html }) {
    return rc(Html, { html });
}

Agreement.propTypes = {
    html: PropTypes.string.isRequired
};

export default Agreement;
