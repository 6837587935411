export default function moveToNextFormElement(target) {
    // Move to next valid form element.
    setTimeout(() => {
        const form = target?.form;
        if (form != null) {
            const index = Array.prototype.indexOf.call(form, target);
            form.elements[(index + 1).toString()]?.focus?.();
        }
    }, 0);
}
