import defaultWillRemove from '../../namespace/relation/willRemove_namespace_relation';

export default {
    verb: 'willRemove',
    namespace: 'inventory',
    relation: 'inventory',
    prerequisites: [],
    priority: defaultWillRemove.priority + 10, //higher priority than (run before) default
    description: 'Prevent the deletion if the inventory is in a busy state',
    //this is the actual logic:
    logic: willRemove
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @typedef {{record:{title:string, 'inventory:status':{title:string}}}} RemovePayload
 *
 * @param {{
 *   data: RemovePayload;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @throws {Error} if the inventory is not in a closed (or errored) state
 * @returns {RemovePayload}
 */
async function willRemove({ data, dispatch }) {
    //if the data already has a meta object on the root, we don't need to do anything, just pass through
    //most likely came from sockets
    if (data.skipConfirm) return data;

    const { title = '', 'inventory:status': status } = data.record;

    if (!['Closed', 'Error'].includes(status?.title)) {
        const message = `Unable to delete inventory ${title}. It currently has status ${
            status?.title || 'being created'
        }. Please make sure the inventory is closed before deleting.`;
        dispatch(
            {
                addToList: false,
                isError: true,
                message
            },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );
        throw new Error(message);
    }

    return data;
}
