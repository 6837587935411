import { globalConfig, constants } from 'lib_ui-services';

export default {
    verb: 'willUpdate',
    namespace: 'metadata',
    relation: 'profileMenu',
    description: 'validate the submitted blockly configuration before storing it',
    priority: 15,
    //this is the actual logic:
    logic: willUpdate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willUpdate({ data, dispatch }) {
    const topLevelBlocks = data.newRecord.blockly?.blocks?.blocks;
    const notifyUser = notify(dispatch);
    if (!topLevelBlocks?.length) {
        const message = 'No configuration available';
        notifyUser(message);
        throw new Error(message);
    }
    const topTypes = topLevelBlocks.filter(b => constants.acceptableTopProfileBlocks.includes(b.type));
    if (topTypes.length !== 1) {
        const message = 'Invalid configuration. The configuration must include exactly 1 top level block.';
        notifyUser(message);
        throw new Error(message);
    }

    data.fieldsToReplace = ['blockly'];
    data.createTests = false;
    return data;
}

const notify =
    publish =>
    (message, addToList = false) => {
        publish(
            { isError: true, message, timeout: globalConfig().notificationTimeoutFormError, addToList },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );
    };
