import lodash from 'lodash';
const { get } = lodash;

export default {
    fromHNode,
    getFilter,
    getUriComponent,
    getMql,
    getJavaScriptFilter
};

/**
 * @typedef ValueExistsFilter
 * @property {string} propertyName
 * @property {string} [optionalNamespace]
 * @property {string} [optionalRelation]
 *
 * @typedef ValueExistsHNode
 * @property {string} propertyName
 * @property {string} [namespace]
 * @property {string} [relation]
 * @property {string} [originalRelation]
 */

/**
 * @param {ValueExistsHNode} hNode
 */
function fromHNode({ propertyName, namespace, relation, originalRelation }) {
    return getFilter(propertyName, namespace, originalRelation ?? relation);
}

/**
 * @param {string} propertyName
 * @param {string} [optionalNamespace]
 * @param {string} [optionalRelation]
 */
function getFilter(propertyName, optionalNamespace, optionalRelation) {
    return {
        propertyName,
        optionalNamespace,
        optionalRelation
    };
}

/**
 * @param {ValueExistsFilter} filter
 */
// eslint-disable-next-line no-unused-vars
function getUriComponent(filters) {
    throw new Error('A filter for valueExists has not yet been implemented on the server');
}

function getMql(filters) {
    if (filters.valueExists) {
        let { propertyName, optionalNamespace, optionalRelation } = filters.valueExists;

        // Remove array index for lokijs (e.g. inventory.0.found => inventory.found)
        let nodes = propertyName.split('.');
        if (nodes.length > 1) {
            nodes = nodes.filter(n => isNaN(Number.parseInt(n)));
            propertyName = nodes.join('.');
        }

        if (optionalNamespace && optionalRelation) {
            propertyName = `${optionalNamespace}:${optionalRelation}.${propertyName}`;
        }

        return {
            [propertyName]: {
                $exists: true
            }
        };
    }
}

function getJavaScriptFilter(filter) {
    let { propertyName, optionalNamespace, optionalRelation } = filter;
    if (optionalNamespace && optionalRelation) {
        propertyName = `${optionalNamespace}:${optionalRelation}.${propertyName}`;
    }
    return record => {
        return get(record, propertyName, null) != null;
    };
}
