/**
 * https://github.com/necolas/react-native-web/blob/master/packages/react-native-web/src/exports/useWindowDimensions/index.js
 */
import Dimensions from '../utilities/dimensions';
import { useEffect, useState } from 'react';
const _p = {
    Dimensions
};
export const _private = _p;
export default function useWindowDimensions() {
    const [dims, setDims] = useState(() => _p.Dimensions.get('window'));
    useEffect(() => {
        function handleChange({ window }) {
            setDims(prev => {
                // keyboard open if same width, but height is less than before
                if (prev.width === window.width && prev.height > window.height) {
                    return {
                        ...prev,
                        keyboard: prev.height - window.height
                    };
                } else {
                    // keyboard closed
                    return {
                        ...window,
                        keyboard: 0
                    };
                }
            });
        }
        _p.Dimensions.addEventListener('change', handleChange);
        // We might have missed an update between calling `get` in render and
        // `addEventListener` in this handler, so we set it here. If there was
        // no change, React will filter out this update as a no-op.
        setDims(_p.Dimensions.get('window'));
        return () => {
            _p.Dimensions.removeEventListener('change', handleChange);
        };
    }, []);
    return dims;
}
