import logging from '@sstdev/lib_logging';
import { executeForEach } from '../registeredServiceQueues';
import contextFilter from '../../../utilities/loggingContextFilter';
const _p = {
    startRelevantServices,
    getSensorTypesAvailable
};
export const _private = _p;
export default {
    verb: 'doingStartup',
    namespace: 'sensor',
    relation: 'service',
    description: 'Start a service that will provide sensor reads',
    logic
};

async function logic({ data, context }) {
    try {
        await _p.startRelevantServices(data, context);
        const sensorTypesAvailable = await _p.getSensorTypesAvailable(data, context);

        return { ...data, sensorTypesAvailable };
    } catch (err) {
        logging.error(err, contextFilter(context));
    }
}
async function startRelevantServices(data, context) {
    const executeResults = await executeForEach('startupSensorService', data, context);
    executeResults.forEach(({ result, type }) => {
        if (type === data.type) {
            if (result) {
                logging.debug(`[${type}] service started.`);
            } else {
                logging.debug(`[${type}] service NOT started.`);
            }
        }
    });
}
async function getSensorTypesAvailable(data, context) {
    const executeResults = await executeForEach('isReaderAvailable', data, context);
    executeResults.forEach(({ result, type }) => {
        if (type === data.type) {
            if (result) {
                logging.debug(`[${type}] a reader is available.`);
            } else {
                logging.debug(`[${type}] a reader is NOT available.`);
            }
        }
    });
    return executeResults.filter(r => r.result === true).map(r => r.type);
}
