import { createElement as rc, useCallback, useEffect, useState } from 'react';
import { authentication, constants } from 'lib_ui-services';
import useAuth0 from '../../../hooks/useAuth0';
import useAuthentication from './useAuthentication';
import { h1, View, styled, contexts, hooks } from 'lib_ui-primitives';
import useEventSink from '../../../hooks/useEventSink';
import log from '@sstdev/lib_logging';
const { ASYNC_LOAD_STATUS } = constants;
const { useRouter } = hooks;

const LoadingStyle = styled(View)`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Title = styled(h1).attrs({ name: 'loading-status' })`
    padding: 6px;
    text-align: center;
    width: 100%;
`;

/** @type {import('react').Context<import('../../../../../types').Profile>} */
export const UserContext = contexts.UserContext;

const _p = {
    useRouter,
    useEventSink,
    useAuth0,
    useAuthentication,
    registerAuth0: authentication.registerAuth0
};
export const _private = _p;

export default function UserContextProvider(props) {
    const { groupNumber, protocol, host, redirectToLogin } = _p.useRouter();
    const [subscribe] = _p.useEventSink();
    const [status, setStatus] = useState(ASYNC_LOAD_STATUS.notStarted);
    const [statusMessage, setMessage] = useState('Loading...');
    /** @type {import('../../../../../types').StateHook<import('../../../../../types').Profile>} */
    const [user, setUser] = useState(undefined);
    const { children } = props;

    //initially, there is no rules engine, so we need to go direct
    const {
        isAuthenticated: isAuth0Authenticated,
        isLoading: isAuth0Loading,
        user: auth0User,
        logout,
        getAccessTokenSilently
    } = _p.useAuth0();

    let groupPath = '';
    if (groupNumber != undefined && groupNumber !== '') {
        groupPath = `/g/${groupNumber}`;
    }
    const redirectUrl = `${protocol}//${host}${groupPath}/`;
    const auth0Logout = useCallback(() => logout({ returnTo: redirectUrl }), [logout, redirectUrl]);
    useEffect(() => {
        _p.registerAuth0({ logout: auth0Logout, getAccessTokenSilently });
        log.info('[AUTHENTICATION] AUTH0 Initialized');
    }, [auth0Logout, getAccessTokenSilently]);

    const { isAuthenticated: isBackboneAuthenticated, isLoading, profile, authorizeOAuthUser } = _p.useAuthentication();

    useEffect(() => {
        //if backbone is authenticated, and we have already set the user: we're all good
        if (isBackboneAuthenticated && user) return;

        //if we have a backbone user, but didn't set it as active user yet: do so now
        if (isBackboneAuthenticated) {
            setMessage(`Resuming session for ${profile.displayName}`);
            setUser(profile);
            setStatus(ASYNC_LOAD_STATUS.success);
            return;
        }

        //if we are NOT still trying to get a backbone user, AND we have a oauth user, use that to obtain a backbone user
        if (!isLoading && isAuth0Authenticated) {
            setMessage(`Validating access for profile ${auth0User.nickname}`);
            let allowStateUpdate = true;
            const doAsync = async () => {
                try {
                    //this next call, if successful, will update the `profile` returned from `useAuthentication`. so we don't need to do anything for the happy path.
                    //note, in the mean time,  `useAuthentication` will update `isLoading` to true.
                    const validUser = await authorizeOAuthUser(
                        auth0User,
                        groupNumber,
                        getAccessTokenSilently,
                        auth0Logout
                    );
                    if (!validUser) {
                        log.warn(`[AUTHENTICATION] ${auth0User.nickname} not authorized.`);
                        if (allowStateUpdate) {
                            setMessage(`${auth0User.nickname} not authorized.`);
                        }
                        setTimeout(() => {
                            auth0Logout();
                        }, 1000);
                    }
                } catch (error) {
                    log.error('[AUTHENTICATION] ' + error.stack);

                    if (allowStateUpdate) {
                        setMessage(`ERROR: ${error.message}`);
                        setTimeout(() => {
                            auth0Logout();
                        }, 500);
                    } else {
                        auth0Logout();
                    }
                }
            };
            doAsync();
            setStatus(ASYNC_LOAD_STATUS.pending);
            return () => (allowStateUpdate = false);
        }
        //we are translating the oauth user to a backbone user
        if (isLoading && isAuth0Authenticated) {
            setMessage(`Authorizing ${auth0User.nickname}`);
            setStatus(ASYNC_LOAD_STATUS.pending);
            return;
        }

        //if we get here, and neither one is still loading: there is no user
        if (!isAuth0Loading && !isLoading) {
            setMessage('No previous session found.');
            setStatus(ASYNC_LOAD_STATUS.success);
            return;
        }

        //otherwise, we're still busy trying to retrieve one or the other
        setUser(null);
        setMessage('Looking for resumable session');
        setStatus(ASYNC_LOAD_STATUS.pending);
    }, [
        user,
        auth0User,
        groupNumber,
        authorizeOAuthUser,
        isAuth0Authenticated,
        isAuth0Loading,
        isBackboneAuthenticated,
        isLoading,
        profile,
        getAccessTokenSilently,
        auth0Logout
    ]);

    useEffect(() => log.debug('[AUTHENTICATION] ' + statusMessage), [statusMessage]);

    //for subsequent changes (after the use case is loaded)
    useEffect(() => {
        const unsubscribes = [
            subscribe({ verb: 'create', namespace: 'security', relation: 'profile', status: 'success' }, profile => {
                if (profile?.type === 'reset') return;

                log.info('[AUTHENTICATION] New profile received');
                setUser(profile.result ?? profile);
            }),
            subscribe(
                { verb: 'update', namespace: 'security', relation: 'profile', status: 'success' },
                (profile, context) => {
                    if (context?.type === 'acceptLicense') return;

                    log.info('[AUTHENTICATION] Updated profile received');
                    setUser(profile);
                }
            ),
            subscribe({ verb: 'remove', namespace: 'security', relation: 'profile', status: 'success' }, () => {
                log.info('[AUTHENTICATION] User Logged Out');
                //intentional new object every time, just in case, to make sure backbone.js re-renders
                setUser({});
                redirectToLogin();
            })
        ];
        return () => unsubscribes.forEach(u => u());
    }, [subscribe, redirectToLogin]);

    // prettier-ignore
    return rc(UserContext.Provider, { value: user },
        status !== ASYNC_LOAD_STATUS.success ? rc(LoadingStyle, null,
            rc(Title, {}, statusMessage)
        ) : null,
        status === ASYNC_LOAD_STATUS.success ? children : null
    );
}
