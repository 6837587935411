import sendToPrinter from './sendToPrinter';
import ZPL from './generateZPL';
import { getGridData } from '../namespace/relation/bulk/utilities';
import { UserError } from '@sstdev/lib_logging';
import { rfidStrategy } from 'lib_ui-services';

const _p = {
    sendToPrinter: sendToPrinter.send,
    getGridData,
    MAX_SEQUENTIAL: 10000
};
export const _private = _p;

export default {
    verb: 'doingCreate',
    namespace: 'print',
    relation: 'job',
    description: 'Print Labels',
    //this is the actual logic:
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'print',
                relation: 'printer',
                type: 'find'
            }
        }
    ],
    logic: doingCreate
};

async function doingCreate({ data, prerequisiteResults, dispatch }) {
    const { newRecord = {} } = data;
    const {
        foreignNamespace,
        foreignRelation,
        template, //  an array of labelContent to print (including properties)
        selection,
        'print:printer': printerRef,
        labelSpec,
        calibrateRfid,
        routePath
    } = newRecord;
    if (!prerequisiteResults[0]) {
        const message = 'Failed to print: is Zebra Browser Print installed and running?';
        dispatch({ isError: true, message }, { verb: 'pop', namespace: 'application', relation: 'notification' });
        throw new UserError(message);
    }
    const printers = prerequisiteResults[0].result;
    const printer = printers.find(p => p._id === printerRef?._id) || printers.find(p => p.default);

    let dataToPrint = [];
    let isSequentialPrint = foreignNamespace === 'print' && foreignRelation === 'job';
    if (isSequentialPrint) {
        try {
            dataToPrint = generateSequentialRecords(newRecord, labelSpec);
        } catch (error) {
            dispatch(
                { isError: true, message: error.message },
                { verb: 'pop', namespace: 'application', relation: 'notification' }
            );
            throw error;
        }
    } else {
        dataToPrint = await _p.getGridData(foreignNamespace, foreignRelation, routePath, selection);
    }

    if (!dataToPrint.length) {
        const message = 'Failed to print: Unable to generate data to print.';
        dispatch({ isError: true, message }, { verb: 'pop', namespace: 'application', relation: 'notification' });
        throw new UserError(message);
    }

    const zpl = await ZPL.generate(dataToPrint, template, labelSpec, printer, { calibrateRfid });

    await _p.sendToPrinter(printer, zpl);
    return {
        ...data,
        result: {
            count: dataToPrint.length
        }
    };
}

function generateSequentialRecords(newRecord, labelSpec) {
    let { prefix = '', start = 1, end = 1, increment = 1, leadingZeroes, numDigits = 4 } = newRecord;
    if (!start || !end) return [];
    if (increment < 1) increment = 1;
    if (end < start) {
        end = start;
    }
    if (end - start >= _p.MAX_SEQUENTIAL) {
        // start and end both will be printed, so only add 1 less
        end = start + (_p.MAX_SEQUENTIAL - 1);
    }
    let result = [];
    for (let index = start; index <= end; index += increment) {
        let value = index.toString();
        if (leadingZeroes) {
            value = value.padStart(numDigits, 0);
        }
        result.push({ _id: `seq${index}`, title: `${prefix}${value}` });
    }
    if (labelSpec?.rfid) {
        if (end.toString().length > rfidStrategy.getRfidConfig().maxShortLength) {
            throw new UserError('Failed to print: RFID value is too long.');
        }
        result.forEach(r => {
            r.tagId = rfidStrategy.getTagId(r, 'title');
        });
    }

    return result;
}
