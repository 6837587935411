import react, { Fragment, useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import useSpaceConstrained from '../../hooks/useSpaceConstrained';
const rc = react.createElement;

/**
 * Because the detail pane is only for block organization in the BB UI 2.0
 * we can forgo creating an actual component and just use a Fragment.
 * If this is rendering in the context of a small mobile screen, then
 * we'll need to hide headers and similar, so set the SpaceConstrainedContext
 * accordingly.
 * @param {object} props
 * @returns Detail Pane Component
 */
export default function DetailPane(props) {
    const [, setConstrained] = useSpaceConstrained();
    const { smallMobile } = useContext(ThemeContext);
    useEffect(() => {
        setConstrained(smallMobile);
        return () => setConstrained(false);
    }, [setConstrained, smallMobile]);
    return rc(Fragment, null, props.children);
}
