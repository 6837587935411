import { createElement as rc, useState, useMemo, useEffect } from 'react';
import UserActivatedInput from '../../_abstractComponent/UserActivatedInput';
import { MultiPickerTransaction, SpreadLeftToRight, Total, TotalLabel, TotalNumber } from './styles';
import PropTypes from 'prop-types';
import { useMergedReads } from '../../contextProviders/MergedReadProvider';
import SensorReadList from '../../list/SensorReadList';
import { constants, dbViews } from 'lib_ui-services';
const { EMPTY_ARRAY } = dbViews.emptyDbView;
const { sensorTypes } = constants;

// combination of a "manual input" sensor (for typing or camera barcode scanning)
// on top of a SensorReadList.
const _p = {
    useMergedReads,
    SelectedRecordList: SensorReadList,
    // eslint-disable-next-line no-undef
    isNative: () => __SST_REACT_NATIVE__ || __TEST_RFID__
};

export const _private = _p;
export default function MultiPickerTransactionGrid(props) {
    const {
        currentRoute,
        inputAutoFocus = false,
        hNode: {
            id,
            // namespace, //hardcoded to 'sensor' in blockly
            // relation, //hardcoded to 'read' in blockly
            title,
            readOnly,
            tooltip,
            displayShowUnknownTags,
            textForShowUnknownTagsCheckbox,
            children,
            displayScanButton,
            displayRfidPowerButton,
            scanRfid,
            scanBarcode,
            includeCheckboxColumn,
            allowCheckUnknownTags = false,
            treePosition
        }
    } = props || { hNode: {} };
    const [inputValue, setInputValue] = useState('');
    const { subscribeToChange, mergeNamespace, mergeRelation } = _p.useMergedReads();
    const [entries, setEntries] = useState(EMPTY_ARRAY);

    useEffect(() => {
        if (subscribeToChange != null) {
            return subscribeToChange(newEntries => setEntries(newEntries));
        }
    }, [subscribeToChange]);

    const inputProps = {
        id: id + 'input',
        disabled: readOnly,
        currentRoute,
        hNode: useMemo(
            () => ({ title, displayScanButton, displayRfidPowerButton, scanRfid, scanBarcode, treePosition }),
            [title, displayScanButton, displayRfidPowerButton, scanRfid, scanBarcode, treePosition]
        ),
        currentValue: inputValue,
        onChange: setInputValue,
        isNative: _p.isNative,
        tooltip,
        placeholder: 'Scan or Enter a Tag',
        autoFocus: inputAutoFocus,
        name: `${title ?? 'multi-picker-transaction-grid'}-input`
    };

    const listProperties = useMemo(() => {
        const removeButtonColumn = !includeCheckboxColumn && {
            id: `remove${id}`,
            hNodeType: 'button',
            hNodeTypeGroup: 'listColumn',
            dataType: 'button',
            title: 'Remove',
            label: 'Remove',
            namespace: 'sensor',
            relation: 'read',
            iconName: 'clear',
            forAction: 'remove',
            displayTitleOnButton: false,
            buttonStyle: 'round'
        };

        return {
            currentRoute,
            includeReadButton: false,
            style: _p.isNative ? { minHeight: 300 } : { minHeight: '300px' },
            hNode: {
                id: id + 'list',
                hNodeType: 'SensorReadList',
                hNodeTypeGroup: 'list',
                namespace: 'sensor',
                relation: 'read',
                sensorType:
                    _p.isNative && scanRfid ? sensorTypes.RFID : scanBarcode ? sensorTypes.BARCODE : sensorTypes.MANUAL,
                mergeNamespace,
                mergeRelation,
                children: [removeButtonColumn, ...children],
                displayShowUnknownTags,
                textForShowUnknownTagsCheckbox,
                includeCheckbox: includeCheckboxColumn,
                includeReadButton: false, // Read button is on UserActivatedInput instead
                allowCheckUnknownTags
            }
        };
    }, [
        includeCheckboxColumn,
        id,
        currentRoute,
        scanRfid,
        scanBarcode,
        mergeNamespace,
        mergeRelation,
        children,
        displayShowUnknownTags,
        textForShowUnknownTagsCheckbox,
        allowCheckUnknownTags
    ]);

    // prettier-ignore
    return rc(MultiPickerTransaction, { 'data-testid': props['data-testid'] },
        rc(SpreadLeftToRight, null,
            rc(UserActivatedInput, inputProps),
            rc(Total, null,
                rc(TotalLabel, null, 'Count'),
                rc(TotalNumber, null, `${entries.length || 0}`)
            )
        ),
        rc(_p.SelectedRecordList, listProperties)
    );
}

MultiPickerTransactionGrid.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};
