import logging from '@sstdev/lib_logging';

let allContent = {};

export default function render(props) {
    if (props == null) return props.output;
    if (!props.zNode?.hNodeType) {
        logging.warn("An ZPL Content Node without zNode definition was found. That can't be right...");
        return props.output;
    }

    const zNodeContent = allContent[props?.zNode?.hNodeType];
    if (!zNodeContent) {
        logging.warn(
            `A piece of the Label Content is not set up correctly. This failure occurred while attempting to render ${props?.zNode?.hNodeType} of Label content.`
        );
        return props.output;
    }

    return zNodeContent(props);
}

function calculateHight(props) {
    if (props == null) return 0;

    const zNodeContent = allContent[props?.zNode?.hNodeType];
    if (!zNodeContent) {
        return 0;
    }
    return zNodeContent.height(props);
}

function register(name, content) {
    allContent[name] = content;
}

export { register, render, calculateHight };
