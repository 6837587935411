import { createElement as rc, cloneElement, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function NestedDetail(props) {
    const {
        hNode: { propertyName },
        children = []
    } = props || { hNode: {} };

    //We are letting React render the nested elements first, so we don't need any logic (or references) to that
    //then, we clone, inject with our propertyPath, memoize and replace those elements.
    //(The memoization is so that on subsequent renders, our cloned and updated element is directly rendered.)
    const nestedSearchElements = useMemo(
        () =>
            children.map(element => {
                let {
                    hNode,
                    hNode: { id, propertyPath }
                } = element.props;

                if (propertyPath) {
                    propertyPath = `${propertyName}.${propertyPath}]`;
                } else {
                    propertyPath = propertyName;
                }

                return cloneElement(element, {
                    ...element.props,
                    hNode: { ...hNode, propertyPath },
                    key: id
                });
            }),
        [children, propertyName]
    );

    return rc(Fragment, null, ...nestedSearchElements);
}

NestedDetail.propTypes = { children: PropTypes.array.isRequired };

export default NestedDetail;
