import defaultWillRemove from '../../namespace/relation/willRemove_namespace_relation';

export default {
    verb: 'willRemove',
    namespace: 'identity',
    relation: 'role',
    prerequisites: [],
    priority: defaultWillRemove.priority + 10, //higher priority than (run before) default
    description: 'Prevent deletion of standard/system roles',
    //this is the actual logic:
    logic: willRemove
};

function willRemove({ data, dispatch }) {
    if (data.skipConfirm) return;
    const { system, title } = data.record;
    if (system) {
        const message = `Unable to delete built-in role ${title}.`;
        dispatch(
            { message, timeout: 5000, addToList: false, isError: true },
            {
                verb: 'pop',
                namespace: 'application',
                relation: 'notification'
            }
        );
        let error = new Error(message);
        error._isUserError = true;
        throw error;
    }

    // returning nothing will pass the full message to the next rule
    return;
}
