import PropTypes from 'prop-types';
import lodash from 'lodash';
const { get } = lodash;
import { PreviewLine, PreviewText } from '../styles';
import { textWidth } from 'lib_ui-primitives';
import { createElement as rc, useState, useEffect } from 'react';

// ZPL prints with FONT 0 at 28x28 per M. At a 203 dpi printer
// this seems to be very similar to Arial at pixel size 12, bold
const CHARACTER_WIDTH = 'bold 12';
const UNIT_SEPARATOR = '\n';

/**
 * Referenced content is dynamic. It references specific properties from the records.
 * E.g., it might reference the description.
 * This in contrast to the FixedContent, which only allows adding a fixed string to a label
 * think "Property Of"
 * but when you print the label for 2 different records, with ReferencedContent,
 * each label would have the content as specified on e.g. the "description" property for that record.
 * @param {*} props 
 * @returns 
 */
export default function ReferencedContent(props) {
    const {
        record,
        label,
        hNode: { propertyName }
    } = props || { hNode: {} };
    let labelWidth = label?.labelWidth || 200;

    const [displayValue, setDisplayValue] = useState('');
    const [lineHeight, setLineHeight] = useState(1);

    useEffect(() => {
        let allowStateUpdate = true;
        async function doAsync() {
            const value = get(record, propertyName, '').trim();

            // split words on spaces and dashes, remove the spaces, but not the dashes
            // can't use a positive look behind with zero length assertion like /\s|(?<=-)/
            // As ReactNative does not support that yet
            const words = value
                .replaceAll(' ', UNIT_SEPARATOR)
                .replaceAll('-', '-' + UNIT_SEPARATOR)
                .split(UNIT_SEPARATOR);
            let lines = [];
            let currentLine = 0;
            for (const word of words) {
                const lineLength = await textWidth.measure(
                    lines[currentLine] ? `${lines[currentLine]} ` : '',
                    'Arial, sans-serif',
                    CHARACTER_WIDTH
                );
                const wordLength = await textWidth.measure(word, 'Arial, sans-serif', CHARACTER_WIDTH);

                if (!lines[currentLine]) {
                    lines[currentLine] = word;
                } else if (lineLength + wordLength <= labelWidth) {
                    lines[currentLine] = `${lines[currentLine]} ${word}`;
                } else {
                    if (currentLine === 0) {
                        lines[currentLine + 1] = word;
                        currentLine++;
                        setLineHeight(2);
                    } else if (currentLine === 1) {
                        lines[currentLine] = `${lines[currentLine]} ${word}`;
                        while (
                            (await textWidth.measure(lines[currentLine], 'Arial, sans-serif', CHARACTER_WIDTH)) >
                            labelWidth
                        ) {
                            lines[currentLine] = lines[currentLine].substring(0, lines[currentLine].length - 1).trim();
                        }
                        break;
                    } else {
                        break;
                    }
                }
            }
            if (allowStateUpdate) {
                setDisplayValue(lines.join(' ').replace(/-\s/g, '-'));
            }
        }
        doAsync();
        return () => (allowStateUpdate = false);
    }, [labelWidth, propertyName, record]);

    return rc(PreviewLine, { lineHeight }, rc(PreviewText, {}, displayValue));
}

ReferencedContent.propTypes = {
    hNode: PropTypes.shape({
        propertyName: PropTypes.string
    }),
    record: PropTypes.object
};
