export default {
    getFilter,
    getUriComponent,
    fromHNode,
    pageResetRequired: true
};

function fromHNode() {
    return getFilter();
}

function getFilter() {
    return {};
}

function getUriComponent() {
    return 'exact=true';
}
