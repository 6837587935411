import { createElement as rc, Fragment } from 'react';
import Spinner from './Spinner';
import styled from '../styled';
import fromTheme from '../fromTheme';
import View from './View';
import nativeOnlyStyles from '../nativeOnlyStyles';

// Semi-transparent background for loading
let Loading = styled(View).attrs({ name: 'loading' })`
    position: absolute;
    z-index: ${fromTheme('zindex', 'PortalBackground')};
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: ${fromTheme('backgroundModal')};
    justify-content: center;
    align-items: center;
`;
Loading = nativeOnlyStyles(Loading)`
    elevation: ${fromTheme('zindex', 'PortalBackground')};
`;
Loading.displayName = 'Loading';

/**
 * Nest children inside to overlay them with a semi-transparent loading screen which
 * prevents users from clicking stuff while waiting for the load to finish.
 * @param {Object} props
 * @param {boolean} props.waiting - set to true to overlay the children with a loading screen
 * @param {boolean} props.showSpinner - set to true to show the spinner when the loading screen is visible
 * @returns function - react component
 */
export default function WaitScreen(props) {
    const { waiting, children, showSpinner = false } = props;
    if (waiting) {
        // prettier-ignore
        return rc(Fragment, null,
            children,
            rc(Loading, {id: props.id ?? 'waitScreen'},
                showSpinner && rc(Spinner, { size: 'large' })
            )
        );
    }
    return children;
}
