import copyActiveRoleToUserRoot from './copyActiveRoleToUserRoot';
export default function getUserProfileFromToken(token) {
    let { user, activeTenantId, activeUseCaseId, tenantId, appId, needTenantSelection, ...rest } = token;
    if (needTenantSelection) {
        return token;
    }
    activeTenantId = activeTenantId || tenantId;
    activeUseCaseId = activeUseCaseId || appId;
    let activeTenant = user.tenant.find(t => t['identity:tenant']._id === activeTenantId);
    let activeUseCase = activeTenant.useCase.find(t => t['metaui:useCase']._id === activeUseCaseId);

    const userActiveRecord = copyActiveRoleToUserRoot(activeTenant, activeUseCase, user);
    const allFeatureFlags = activeUseCase.featureFlags || [];
    return {
        ...userActiveRecord,
        activeTenantId,
        activeUseCaseId,
        activeTenant,
        activeUseCase,
        allFeatureFlags,
        ...rest,
        // to insert in meta as modifiedBy for instance.
        briefUserReference: { _id: user._id, displayName: user.displayName }
    };
}
