//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _didCreate_identity_user_setPassword from './didCreate_identity_user_setPassword';
import _doingValidate_identity_user from './doingValidate_identity_user';
import _willCreate_identity_user from './willCreate_identity_user';
import _willCreate_identity_user_setPassword from './willCreate_identity_user_setPassword';
import _willUpdate_identity_user_setPassword from './willUpdate_identity_user_setPassword';
import _willUpsert_identity_user from './willUpsert_identity_user';
export const didCreate_identity_user_setPassword = _didCreate_identity_user_setPassword;
export const doingValidate_identity_user = _doingValidate_identity_user;
export const willCreate_identity_user = _willCreate_identity_user;
export const willCreate_identity_user_setPassword = _willCreate_identity_user_setPassword;
export const willUpdate_identity_user_setPassword = _willUpdate_identity_user_setPassword;
export const willUpsert_identity_user = _willUpsert_identity_user;
export default {didCreate_identity_user_setPassword, doingValidate_identity_user, willCreate_identity_user, willCreate_identity_user_setPassword, willUpdate_identity_user_setPassword, willUpsert_identity_user};

