const ROUND_UP = 1;
/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {Number} maxTextWidth The max value allowed.
 * @param {String} fontFamily The css fontFamily that text is to be rendered with (e.g. "verdana").
 * @param {Number} fontSize This will always be number and will be measured in pixels.
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
async function measureTextWidth(text, fontFamily, fontSize, maxTextWidth) {
    // re-use canvas object for better performance
    const canvas = measureTextWidth.canvas || (measureTextWidth.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = `${fontSize}px ${fontFamily}`;
    const metrics = context.measureText(text);
    if ((maxTextWidth != null) & (maxTextWidth > 0)) {
        return Math.min(metrics.width + ROUND_UP, maxTextWidth);
    }
    return metrics.width + ROUND_UP;
}

export default {
    measure: measureTextWidth
};
