//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _doingCreate_deploy_environment_singleRecordForm from './doingCreate_deploy_environment_singleRecordForm';
import _willCreate_deploy_environment_singleRecordForm from './willCreate_deploy_environment_singleRecordForm';
export const doingCreate_deploy_environment_singleRecordForm = _doingCreate_deploy_environment_singleRecordForm;
export const willCreate_deploy_environment_singleRecordForm = _willCreate_deploy_environment_singleRecordForm;
export default {doingCreate_deploy_environment_singleRecordForm, willCreate_deploy_environment_singleRecordForm};

