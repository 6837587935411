import { useCallback, useRef, useEffect } from 'react';
import lodash from 'lodash';
const { isEqual } = lodash;
const { omit } = lodash;
const { memoize } = lodash;

const memoizeBind = memoize(element => element.scrollTo.bind(element));
export default function useScrollLayoutChange(onScrollLayoutChange) {
    // This is mainly to catch unforeseen scenarios where a maintainer does
    // not anticipate a consumer of the component this hook is in.
    if (useScrollLayoutChange == null || typeof onScrollLayoutChange !== 'function') {
        throw new Error(
            'A callback function (useScrollLayoutChange) must be passed into useScrollLayoutChange.  Default to a noop function if none is necessary.'
        );
    }
    const scrollLayout = useRef();
    const unsubscribes = useRef([]);

    // This is a 'callback ref': https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
    const refCallback = useCallback(element => {
        if (element == null) return;

        function handler(e) {
            const { clientWidth, clientHeight, scrollWidth, scrollHeight, scrollTop, scrollLeft } = element;
            // binding avoids 'illegal invocation' error
            const boundScrollTo = memoizeBind(element);
            const newLayout = {
                clientWidth,
                clientHeight,
                scrollWidth,
                scrollHeight,
                scrollTop,
                scrollLeft
            };

            const prev = scrollLayout.current;
            if (!isEqual(newLayout, omit(prev, 'scrollTo'))) {
                newLayout.scrollTo = boundScrollTo;
                scrollLayout.current = newLayout;
                onScrollLayoutChange(newLayout, e);
            }
            return e;
        }

        const observer = new ResizeObserver(handler);
        observer.observe(element);

        element.addEventListener('scroll', handler);

        unsubscribes.current.push(() => {
            observer.unobserve(element);
            element.removeEventListener('scroll', handler);
        });
        refCallback.current = element;

        // This should not be recreated because it will cause unnecessary renders
        // For more info, see: https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Cleanup when unmounting.
    useEffect(() => {
        const allUnsubscribes = unsubscribes.current;
        return () => allUnsubscribes.forEach(unsub => unsub());
    }, []);

    return refCallback;
}
