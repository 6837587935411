import { createElement as rc, Children, useMemo } from 'react';
import MenuEditor from './MenuEditor';
import ActiveRecord from '../../contextProviders/ActiveRecord';
import GreedySectionLayout from '../../layout/GreedySectionLayout';

export default function MenuEditorContainer(props) {
    // `record` is mainly included for test purposes here. It will usually
    // be supplied to the ActiveRecord component by an event from the rules
    // engine or similar.
    const { children: _children, hNode = {}, record } = props || { hNode: {} };
    const children = Children.toArray(_children);
    const greedyProps = useMemo(
        () => ({
            hNode: {
                flexDirection: 'left2right',
                greedySection: 'MIDDLE',
                firstSection: [hNode.id],
                middleSection: hNode.children?.map(c => c.id) || [],
                lastSection: []
            }
        }),
        [hNode]
    );

    // prettier-ignore
    return rc(ActiveRecord, { namespace: 'metadata', relation: 'page', activationVerb: 'edit', record },
        rc(GreedySectionLayout, greedyProps,
            [
                rc(MenuEditor, { ...props, key: 'menuEditor' }),
                ...children
            ]
        )
    );
}
