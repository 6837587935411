//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _SearchPane from './SearchPane';
import _SearchPaneHeader from './SearchPaneHeader';
export const SearchPane = _SearchPane;
export const SearchPaneHeader = _SearchPaneHeader;
export default {SearchPane, SearchPaneHeader};

