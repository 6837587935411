import { createElement as rc } from 'react';
import { fromTheme, styled, TextInput } from 'lib_ui-primitives';
import CriteriaElement from './CriteriaElement';
import PropTypes from 'prop-types';
import createTreePositionGetter from '../../utilities/createTreePositionGetter';
import { constants } from 'lib_ui-services';
const { criteriaOperations } = constants;

const supportedOperations = [criteriaOperations.EQUALS, criteriaOperations.GREATER_EQUAL, criteriaOperations.LESS_EQUAL];

const TextField = styled(TextInput)`
    max-width: ${fromTheme('form', 'entryMaxWidth')};
`;
export default function IntegerCriteria(props) {
    const {
        hNode: { id, min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER, propertyName, treePosition }
    } = props || { hNode: {} };

    const getTreePosition = createTreePositionGetter(treePosition, 2);
    return rc(
        CriteriaElement,
        { ...props, supportedOperations, defaultValue: 0, treePosition: getTreePosition(0) },
        rc(TextField, {
            id: `innerValue${id}`,
            type: 'number',
            min,
            max,
            name: propertyName,
            sequence: getTreePosition(1).sequence
        })
    );
}

IntegerCriteria.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};
