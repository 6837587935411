import lokijs from 'lokijs';
import lodash from 'lodash';
const { debounce, isEqual, uniqBy } = lodash;
import logging from '@sstdev/lib_logging';
import simpleChangeObserver from './simpleChangeObserver';
const MIN_LEXICAL_VALUE = '\u0000';

/**
 * Use loki binary index to order entries as they are added.  This
 * avoids sorting the entire set of entries all at once (or doing it
 * multiple times if new entries are often added).
 * This is not persistent storage and will not mess with the data stored in indexedDb.
 * It uses the in-memory loki storage driver.
 * @param {*} sortFields
 * @returns {Array} of entries ordered by the indexFields
 */
export default function orderedSet(sortFields = [], uniqueFields = [], throwOnDuplicates = false) {
    const _p = {};
    const COLLECTION_NAME = 'orderCollection' + orderedSet.debugName;
    const FILTERED_VIEW_NAME = 'filteredOrderedCollection' + orderedSet.debugName;
    const VIEW_FILTER_ID = 'viewFilterId';
    const { onChange, publishChange } = simpleChangeObserver();
    let changedRecords = [];
    // new in-memory lokijs database named orderedSet
    const db = new lokijs('orderedSet', {});
    db.addCollection(COLLECTION_NAME, {
        disableMeta: true, // remove lokijs's meta field
        unique: uniqueFields
    });

    // Track to compare for changes (see setUniqueConstraint)
    let _sortFields = [];

    let compositeSortField;
    const _set = db.getCollection(COLLECTION_NAME);

    setUniqueConstraint(sortFields);

    function setUniqueConstraint(sortFields) {
        if (sortFields != null && Array.isArray(sortFields) && sortFields.length > 0) {
            if (!isEqual(_sortFields, sortFields)) {
                _sortFields = sortFields;
                compositeSortField = 'compositeSortField';
                // Populate the collection with the composite sort field
                _set.data.forEach(d => {
                    d[compositeSortField] = createCompositeSortField(d, sortFields);
                    _set.update(d);
                });
                _set.ensureUniqueIndex(compositeSortField);
            }
        }
    }
    function createCompositeSortField(entry, sortFields) {
        const values = sortFields.map(fieldName => {
            const value = entry[fieldName];
            if (typeof value === 'boolean') {
                // invert boolean
                return value ? 0 : 1;
            }
            return value;
        });
        return values.join(MIN_LEXICAL_VALUE);
    }

    return (() => {
        const removeFilteredView = view => {
            changedRecords = [];
            if (view != null) {
                view.subscribers = [];
                view.destroy();
                _set.removeDynamicView(FILTERED_VIEW_NAME);
            }
        };

        _p.update = entry => {
            // concatenate indexFields to create one new field used for the
            // index (lokijs does not do composite indexes).
            if (compositeSortField != null) {
                entry[compositeSortField] = createCompositeSortField(entry, _sortFields);
            }

            try {
                const existing = _set.by(uniqueFields[0], entry[uniqueFields[0]]);
                if (!existing) {
                    throw new Error('Requested an update for an entry that did not exist');
                }
                // Avoid infinite loops for changing operations based off
                // changes :)  See throttledPublish based off update event below.
                if (!isEqual(existing, entry)) {
                    changedRecords.push(entry);
                    _set.update(entry);
                    publishChange('update', entry);
                }
                return true;
            } catch (err) {
                // Might prefer to update duplicates instead, but it shouldn't happen
                // with RFID (native code should eliminate dups).
                if (err.message.startsWith('Duplicate key for property') && !throwOnDuplicates) {
                    logging.debug(`[orderedSet] Duplicate ordered set request will be ignored: ${err.message}`);
                    return false;
                } else {
                    throw err;
                }
            }
        };

        const getChangeBatch = () => {
            // get a batch of the changes to report
            // Avoid race by getting a snapshot and then removing
            // them one by one.
            try {
                const batch = [...changedRecords];
                batch.forEach(r => {
                    changedRecords.splice(changedRecords.indexOf(r), 1);
                });
                // Get only the most recent change;
                const uniqueBatch = uniqBy(batch.reverse(), r => r[uniqueFields[0]]);
                return uniqueBatch;
            } catch (err) {
                throw new Error(
                    'Something bad happened while trying to prepare a batch of changes to report: ' + err.message
                );
            }
        };
        const surface = {
            reset: () => {
                _set.removeDataOnly();
                logging.debug('[orderedSet] reseting - ' + orderedSet.debugName);
                publishChange({ reset: [] });
            },
            add: entry => {
                // If an array is passed in, recurse on each entry.
                if (Array.isArray(entry)) {
                    entry.forEach(e => surface.add(e));

                    return;
                }
                // concatenate indexFields to create one new field used for the
                // index (lokijs does not do composite indexes).
                if (compositeSortField != null) {
                    entry[compositeSortField] = createCompositeSortField(entry, _sortFields);
                }

                try {
                    changedRecords.push(entry);
                    _set.insertOne(entry);
                    publishChange('add', entry);
                    return true;
                } catch (err) {
                    // Might prefer to update duplicates instead, but it shouldn't happen
                    // with RFID (native code should eliminate dups).
                    if (err.message.startsWith('Duplicate key for property') && !throwOnDuplicates) {
                        logging.debug(`[orderedSet] Duplicate ordered set request will be ignored: ${err.message}`);
                        return false;
                    } else {
                        throw err;
                    }
                }
            },
            update: entry => _p.update(entry),
            remove: (recordOrUniqueFieldName, value) => {
                if (typeof recordOrUniqueFieldName === 'object') {
                    value = recordOrUniqueFieldName[uniqueFields[0]];
                    recordOrUniqueFieldName = uniqueFields[0];
                }
                const entry = _set.by(recordOrUniqueFieldName, value);
                if (entry != null) {
                    _set.remove(entry);
                    publishChange('remove', entry);
                }
            },
            subscribeToChange: onChange,
            get: () => {
                if (compositeSortField != null) {
                    return _set.chain().simplesort(compositeSortField).data();
                }
                return _set.data;
            },
            getByUniqueField(uniqueField, value) {
                return _set.by(uniqueField, value);
            },
            getFilteredView: (filter, aggregateChangesForMils = 1000, sortFields = []) => {
                if (_set.getDynamicView(FILTERED_VIEW_NAME)) {
                    throw new Error(
                        'orderedSet.getFilteredView cannot be called more than once unless the original view is destroyed.'
                    );
                }
                let dynamicView = _set.getDynamicView(FILTERED_VIEW_NAME);
                if (dynamicView == null) {
                    dynamicView = _set.addDynamicView(FILTERED_VIEW_NAME);
                    dynamicView.subscribers = [];
                }
                if (sortFields.length > 0) {
                    // noop if same fields
                    setUniqueConstraint(sortFields);
                }
                if (filter != null) {
                    dynamicView.applyFind(filter, VIEW_FILTER_ID);
                }
                const getViewResults = () => {
                    let newData = dynamicView.data();
                    newData.subscribeToChange = dynamicView.subscribeToChange;
                    newData.changeFilter = dynamicView.changeFilter;
                    newData.destroy = () => removeFilteredView(dynamicView);
                    newData.update = surface.update;
                    newData.remove = surface.remove;
                    newData._private = { view: dynamicView, surfacePrivate: _p };
                    return newData;
                };
                dynamicView.applySimpleSort(compositeSortField, {});

                // need to know about changes so react can rerender them
                dynamicView.subscribeToChange = subscriber => {
                    const batch = getChangeBatch();
                    dynamicView.subscribers.push(subscriber);
                    subscriber(getViewResults(), batch);
                    return () => dynamicView.subscribers.splice(dynamicView.subscribers.indexOf(subscriber), 1);
                };
                dynamicView.changeFilter = newFilter => {
                    // Avoid rebuilds if there is no change.
                    const existingFind = dynamicView.filterPipeline.some(f => {
                        return f.type === 'find' && isEqual(f.val, newFilter);
                    });
                    if (existingFind) return;
                    dynamicView.removeFilter(VIEW_FILTER_ID);
                    dynamicView.applyFind(newFilter, VIEW_FILTER_ID);
                    return getViewResults();
                };
                dynamicView.update = surface.update;
                // only publish changes at set intervals to avoid rerendering constantly.
                const throttledPublish = debounce(
                    async () => {
                        try {
                            if (dynamicView.destroyed) {
                                dynamicView.removeListener('rebuild', throttledPublish);
                                _set.removeListener('update', throttledPublish);
                                _set.removeListener('insert', throttledPublish);
                                _set.removeListener('delete', throttledPublish);
                                return;
                            }
                            const batch = getChangeBatch();
                            const newData = getViewResults();
                            dynamicView.subscribers.forEach(listener => listener(newData, batch));
                        } catch (err) {
                            logging.error(err);
                        }
                    },
                    aggregateChangesForMils,
                    { leading: false }
                );
                dynamicView.destroy = () => {
                    dynamicView.destroyed = true;
                    throttledPublish.cancel();
                };
                dynamicView.addListener('rebuild', throttledPublish);
                _set.addListener('update', throttledPublish);
                _set.addListener('insert', throttledPublish);
                _set.addListener('delete', throttledPublish);
                return getViewResults();
            },
            removeFilteredView,
            _private: { ..._p, _set }
        };
        return surface;
    })();
}
