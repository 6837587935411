import getFormElementHNodes from './getFormElementHNodes';

export default async function trimStringWhitespaceIfNecessary(context, newModel) {
    const { hNode } = context;
    const hNodes = getFormElementHNodes(hNode?.children);
    const TEXT_FIELDS = ['ShortText', 'LongText', 'ShortEncodedText', 'Email'];

    // For text fields, trim whitespace.
    await Promise.all(
        hNodes
            .filter(h => TEXT_FIELDS.includes(h.hNodeType))
            .map(async _hNode => {
                if (newModel[_hNode.propertyName] && typeof newModel[_hNode.propertyName] === 'string') {
                    newModel[_hNode.propertyName] = newModel[_hNode.propertyName].trim();
                }
            })
    );
}
