import { useEffect, useContext, useState, useRef } from 'react';
import { RouteBoundMachineContext } from '../components/contextProviders/RouteBoundMachineProvider';

/**
 * Creates a state machine that remains available to all components within the current
 * route (until the route is exited).
 * It will force a render to the consuming component if the machine state changes.
 * The hook can be used by multiple components and will return the same state machine instance
 * if the machine configuration has the same id.
 * Unlike useRouteBoundedState, this machine is recreated if the user leaves the route and then
 * returns.
 * It gets a fresh machine every time the route is entered because that is most often what is
 * desired.
 * @param {object} machineConfig - JSON containing an xstate state machine config
 * @returns [machinestate, send, updateActions]
 */
export default function useRouteBoundedMachine(machineConfig, actions = {}, services = {}) {
    const { getStateMachine } = useContext(RouteBoundMachineContext);
    const [machineState, setMachineState] = useState({ matches: () => false });
    const updateActions = useRef(() => {});
    const updateServices = useRef(() => {});

    // If the consuming component inlines the machine config/actions, this hook will receive a new instance
    // of the same machine config/actions constant every render, so use useRef default to only see the first one.
    // (Otherwise the useEffect dependency array wants the machineConfig/actions in it and you get rerenders.)
    // Keep in mind this is only relevant for the current containing component.  Other consumers of the
    // hook will only share the same machine instance/actions if they use a machine config with the
    // same id (see ../components/Route.js for details).
    const firstMachineConfig = useRef(machineConfig);
    const firstActions = useRef(actions);
    const firstServices = useRef(services);
    const send = useRef(() => {});
    useEffect(() => {
        const [_send, onStateChange, initialState, _updateActions, _updateServices] = getStateMachine(
            firstMachineConfig.current,
            firstActions.current,
            firstServices.current
        );
        updateActions.current = _updateActions;
        updateServices.current = _updateServices;
        send.current = _send;
        // returns unsubscribe function
        const unsubscribe = onStateChange(newState => setMachineState(newState));
        setMachineState(initialState);
        return unsubscribe;
    }, [getStateMachine]);

    // This is a noop the first time it is called, but after the machine is created, it
    // allows the consuming component to change the actions if the component state changes
    // for instance if the action is inside a useCallback with a dependency that changes.
    updateActions.current(actions);
    updateServices.current(services);

    return [machineState, send.current];
}
