// import { helpers } from '../../../../packages/lib_ui';

export default function useSslIfDeployed() {
    //TODO: something like this:
    // const location = useLocation();
    // const isNotDeployed = helpers.isNotDeployedHelper({ location });
    // if (!isNotDeployed && location.protocol != 'https:') {
    //     location.href =
    //         'https:' + window.location.href.substring(window.location.protocol.length);
    // }
}
