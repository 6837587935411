// create static empty view (with viewCriteria) as placeholder when loading collections
export const EMPTY_ARRAY = [];
Object.freeze(EMPTY_ARRAY);
const emptyDbView = { data: EMPTY_ARRAY, count: 0, changeListeners: [], EMPTY_ARRAY };
emptyDbView.viewCriteria = {
    getSort: () => {},
    addSort: () => {},
    getFinds: () => [],
    addFind: () => {},
    applyFilters: () => {},
    removeFilters: () => {},
    calculateAggregateResult: () => {}
};
emptyDbView.containsId = () => false;
export default emptyDbView;
