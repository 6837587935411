import { prerequisites, preventChangesWhenLocked } from './utilities/lockedVersion';

//FUTURE: there are some problems with this blatant rejection of changes to a locked record.
//It means it is not possible
export default {
    verb: 'willUpdate',
    namespace: 'metadata',
    //any relation in this namespace
    description: 'Prevent any changes if this (or a higher) version is locked',
    //type: '',
    priority: 99,
    //useCaseIds:[]
    prerequisites: prerequisites(),
    //this is the actual logic:
    logic: preventChangesWhenLocked
};
