import { assign } from 'xstate';
import lodash from 'lodash';
const { isEqual } = lodash;
const { unset } = lodash;
import { getPathToProperty } from '../metadata';

export const mergeChanges = (context, event) => {
    // If a beginChange is called to set the form to dirty, but no actual
    // changes need be applied, then skip sending this to the rules engine.
    if (Object.keys(event.payload).length === 0) return;
    const { publish, newRecord, oldRecord, namespace, relation, type, propertiesToUnset, hNode } = context;
    publish(
        { propertiesToUnset, oldRecord, newRecord, ...event.payload, hNode },
        { verb: 'change', namespace, relation, type }
    );
};

export const mergeChangedRecord = assign((context, event) => {
    const { newRecord: prevRecord, propertiesToUnset: prevUnset } = context;
    const { newRecord, propertiesToUnset } = event.payload;
    let merged = { ...prevRecord, ...newRecord };
    const newlyUnset = diff(prevUnset, propertiesToUnset) || [];
    merged = newlyUnset.reduce((result, propToUnset) => {
        const path = getPathToProperty(propToUnset);
        unset(result, path);
        return result;
    }, merged);
    return { newRecord: merged, propertiesToUnset };
});

export const raiseSuccess = context => {
    const { publish, namespace, relation, type } = context;
    // Clears the record out of the ActiveRecord context
    publish({ namespace, relation, type }, { verb: 'submit', namespace, relation, status: 'success' });
};

export const updateContext = assign((context, event) => event.payload.context);

export const update = ({
    newRecord,
    oldRecord,
    namespace,
    relation,
    type,
    publish,
    hNode,
    isNew,
    propertiesToUnset,
    routePath
}) =>
    publish(
        { newRecord, oldRecord, propertiesToUnset },
        { verb: 'update', namespace, relation, type, hNode, isNew, routePath }
    );

export const create = ({
    newRecord,
    oldRecord,
    namespace,
    relation,
    type,
    publish,
    hNode,
    isNew,
    splashContext,
    routePath
}) =>
    publish(
        { newRecord, oldRecord, splashContext },
        { verb: 'create', namespace, relation, type, hNode, isNew, routePath }
    );

export const validate = ({ newRecord, oldRecord, namespace, relation, type, publish, hNode, isNew, routePath }) =>
    publish({ newRecord, oldRecord }, { verb: 'validate', namespace, relation, type, hNode, isNew, routePath });

function diff(left, right) {
    if (!left?.length) return right;
    if (!right.length) return [];
    return right.filter(r => !left.some(l => isEqual(l, r)));
}
