import { constants } from 'lib_ui-services';
import lodash from 'lodash';
const { set } = lodash;
const { useCaseIds } = constants;

export default {
    verb: 'willNew',
    namespace: 'deploy',
    relation: 'deployment',
    description: 'Prepopulate a new deployment record with the status',
    useCaseIds: [useCaseIds.ONE_TOUCH],
    //this is the actual logic:
    logic: willNew
};
function willNew({ data }) {
    set(data, ['newRecord', 'status'], 'new');
    return data;
}
