import lodash from 'lodash';
const { intersection } = lodash;

// Make this easy to mock.
const _p = { shouldRender };
export default adjustAvailableControlsByFeatureFlags;
export const _private = _p;
// Remove nodes that should not render for this tenant's feature flags
function adjustAvailableControlsByFeatureFlags(hNode, featureFlags) {
    if (hNode && hNode.children) {
        for (let i = hNode.children.length - 1; i >= 0; i--) {
            if (!_p.shouldRender(hNode.children[i], featureFlags)) {
                hNode.children.splice(i, 1);
            } else {
                adjustAvailableControlsByFeatureFlags(hNode.children[i], featureFlags);
            }
        }
    }
}

function shouldRender(hNode, featureFlags) {
    if (!hNode.addForFeatureFlags && !hNode.removeForFeatureFlags) return true;

    if (hNode.addForFeatureFlags) {
        return intersection(hNode.addForFeatureFlags, featureFlags).length > 0;
    }

    if (hNode.removeForFeatureFlags) {
        return intersection(hNode.removeForFeatureFlags, featureFlags).length === 0;
    }
}
