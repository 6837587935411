import { useEffect, useRef, useMemo, useCallback } from 'react';
import logging from '@sstdev/lib_logging';
import { moveToNextFormElement, hooks } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';
import useReads from './useReads';

const { useMemoizedObject } = hooks;
/**
 * @typedef Payload
 * @param {*} onChange
 * @param {*} active
 * @param {*} focusRef
 * @param {*} focusNextFormElement
 */

const _p = { useReads, moveToNextFormElement };
export const _private = _p;
export default function useScanningForField(
    hNodeOrSensorTypes = [],
    onChange,
    active = false,
    focusRef,
    focusNextFormElement = false
) {
    const { reads, sensorTypesAvailable, reset } = _p.useReads(undefined);

    // Determine the sensorTypes we are interested in.
    let sensorTypes = useMemo(() => {
        let _sensorTypes = [];
        if (Array.isArray(hNodeOrSensorTypes)) {
            _sensorTypes = hNodeOrSensorTypes;
        } else {
            const { scanRfid, scanBarcode } = hNodeOrSensorTypes;
            _sensorTypes = [];
            if (scanRfid && sensorTypesAvailable.includes(constants.sensorTypes.RFID)) {
                _sensorTypes.push(constants.sensorTypes.RFID);
            }
            if (scanBarcode && sensorTypesAvailable.includes(constants.sensorTypes.BARCODE)) {
                _sensorTypes.push(constants.sensorTypes.BARCODE);
            }
        }
        return _sensorTypes;
    }, [hNodeOrSensorTypes, sensorTypesAvailable]);

    // Result ref from useMemo can change even if it is equal (if dependencies
    // to useMemo change, but it still yields an equal result).
    sensorTypes = useMemoizedObject(sensorTypes);

    // Only use the first onChange ref (often lambdas are passed in or similar so ref
    // can change even though the function is the same).
    const memoizedOnChange = useRef(onChange);

    const sendOnChangeIfReadsChange = useCallback(() => {
        if (sensorTypes.length === 0) {
            return; // Do nothing if no sensor types are specified
        }
        if (active) {
            try {
                const _reads = reads.get();
                const allowedReads = _reads.filter(read => sensorTypes.includes(read.sensorType));
                if (allowedReads.length) {
                    const ordered = _reads.reverse();
                    let scanValue;
                    let scanType;
                    // If the most recent read is RFID...
                    if (ordered[0].sensorType === constants.sensorTypes.RFID) {
                        // Select highest RSSI for RFID.
                        const selected = allowedReads.reduce((maxSoFar, nextEntry) =>
                            (nextEntry.rssi ?? Number.MIN_SAFE_INTEGER) > (maxSoFar?.rssi ?? Number.MIN_SAFE_INTEGER)
                                ? nextEntry
                                : maxSoFar
                        );
                        scanValue = selected.tagId;
                        scanType = selected.sensorType;
                    } else {
                        // If the most recent read is barcode or manual read...
                        scanValue = ordered[0].asciiTagId;
                        scanType = ordered[0].sensorType;
                    }
                    memoizedOnChange.current(scanValue, scanType);
                    // ensure current entry is focused
                    focusRef?.current?.focus();
                    // Then move to the next one if instructed to do so.
                    if (focusNextFormElement && focusRef?.current != null) {
                        _p.moveToNextFormElement(focusRef.current);
                    }
                }
            } catch (err) {
                logging.error(err);
            }
        }
    }, [focusNextFormElement, active, focusRef, sensorTypes, reads]);

    // Send onChange when reads change.
    useEffect(() => {
        const unsubscribe = reads.subscribeToChange(sendOnChangeIfReadsChange);
        sendOnChangeIfReadsChange(reads);
        return unsubscribe;
    }, [reads, sendOnChangeIfReadsChange]);
    return { reset };
}
