/**
 * Returns a function that clamps a value between the specified min and max
 * @param {number} min The minimum acceptable number
 * @param {number} max The maximum acceptable number
 */
export default (min, max) => {
    if (min > max) throw new Error('The provided `min` value must be larger than `max` value');
    /**
     * Clamps the value between the specified min and max
     * @param {number} value value to be clamped
     */

    const clamp = value => {
        return Math.min(Math.max(value, min), max);
    };

    return clamp;
};
