import { createElement as rc, useState } from 'react';
import PropTypes from 'prop-types';
import useFormControl from '../../hooks/useFormControl';
import { TextInput, styled } from 'lib_ui-primitives';
import FormField from './FormField';
const StyledTextInput = styled(TextInput)`
    flex-grow: 1;
`;

const _p = {
    useFormControl
};
function Integer(props) {
    const {
        autoComplete = 'off',
        hNode: {
            id,
            min = Number.MIN_SAFE_INTEGER,
            max = Number.MAX_SAFE_INTEGER,
            maxLength,
            minLength,
            placeholder,
            tooltip,
            pattern,
            propertyName,
            treePosition
        },
        onBlur,
        onFocus
    } = props || { hNode: {} };

    const [active, setActive] = useState(false);
    const { title, value, setValue, isDefaultValue, disabled, errors } = _p.useFormControl(props);

    function onChange(value) {
        const newValue = value === '' || isNaN(value) ? '' : +parseFloat(value).toFixed(2);
        setValue(newValue);
    }

    /**
     * @param {React.FocusEvent<HTMLInputElement>} event
     */
    function handleOnFocus(e) {
        setActive(true);
        e.target.select?.();
        onFocus?.(e);
    }

    function handleOnBlur(e) {
        setActive(false);
        onBlur?.(e);
    }

    let _value = active
        ? value
        : value.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0, useGrouping: false });

    // previously, we did not display zero values.
    // but for ports or versions, 0 is a valid entry
    if (isDefaultValue) {
        _value = _value === '0' || _value === 0 ? '' : _value;
    }

    // Set the fieldEmpty value so that the label can move over the field if necessary
    const fieldEmpty = _value === '';

    // prettier-ignore
    return rc(FormField, { id, title, tooltip, errors, active, fieldEmpty },
        rc(StyledTextInput, {
            id,
            name: propertyName,
            type: active ? 'number' : 'text',
            disabled,
            autoComplete,
            spellCheck: false,
            max,
            maxLength,
            min,
            minLength,
            pattern,
            placeholder,
            value: _value,
            onChange,
            onBlur: handleOnBlur,
            onFocus: handleOnFocus,
            sequence: treePosition.sequence
        })
    );
}

Integer.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};

export default Integer;
export const _private = _p;
