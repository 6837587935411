import useFilterMetadata from './useFilterMetadata';
import { useFilterInterdependencyBoundary } from '../components/contextProviders/FilterInterdependencyBoundary';
import { useEffect } from 'react';

const _p = { useFilterInterdependencyBoundary, useFilterMetadata };
export default function useRegisterWithFilterInterdependencyBoundary(hNode) {
    const {
        namespace,
        foreignNamespace: tempForeignNamespace,
        relation,
        foreignRelation: tempForeignRelation
    } = hNode ?? {};
    // Some consumers of this abstract component use namespace/relation instead of
    // foreignNamespace/foreignRelation.
    const foreignNamespace = tempForeignNamespace || namespace;
    const foreignRelation = tempForeignRelation || relation;

    const filterMetadata = _p.useFilterMetadata(hNode);
    const FilterInterdependencyBoundary = _p.useFilterInterdependencyBoundary();

    // This should only run once when the parent is mounted.
    useEffect(() => {
        FilterInterdependencyBoundary?.addFilterMetadata(
            { namespace: foreignNamespace, relation: foreignRelation },
            filterMetadata
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
export const _private = _p;
