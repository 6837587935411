import { constants } from 'lib_ui-services';
const { useCaseIds } = constants;

export default {
    verb: 'doingNew',
    namespace: 'item',
    relation: 'item',
    status: 'success',
    description: 'Set an auto incremented new record on Take page to dirty',
    featureFlag: 'autoIncrementAssetNo',
    useCaseIds: [useCaseIds.ASSET_TRACKING, useCaseIds.AMERICAN_WATER_ASSET_TRACKING],
    routePath: ['/g/0/Inventory/Take', '/g/1/Inventory/Take', '/g/2/Inventory/Take'],
    logic: newSuccess
};

async function newSuccess({ dispatch }) {
    // Set the form to dirty.
    dispatch({}, { verb: 'beginChange', namespace: 'item', relation: 'item' }, { waitForSubscriber: true });
}
