import { useCallback, useRef } from 'react';
import { globalConfig, constants } from 'lib_ui-services';
import useEventSink from './useEventSink';

const DEFAULT_OPTIONS = {
    type: constants.notificationTypes.TOAST,
    isError: false,
    timeout: globalConfig().notificationTimeout
};
export const _private = { useEventSink };
export default function useNotification(defaultOptions) {
    const [, publish] = _private.useEventSink();
    // To avoid rerenders, only use the default options from the first call
    const _defaultOptions = useRef(defaultOptions);
    return useCallback(
        messageOrOptions => {
            let notification;
            if (typeof messageOrOptions === 'string') {
                notification = {
                    ...DEFAULT_OPTIONS,
                    ..._defaultOptions.current,
                    message: messageOrOptions
                };
            } else if (Array.isArray(messageOrOptions)) {
                notification = {
                    ...DEFAULT_OPTIONS,
                    ..._defaultOptions.current,
                    message: messageOrOptions.join('\n')
                };
            } else {
                notification = {
                    ...DEFAULT_OPTIONS,
                    ..._defaultOptions.current,
                    ...messageOrOptions
                };
            }

            publish(notification, { verb: 'pop', namespace: 'application', relation: 'notification' });
        },
        [publish]
    );
}
