export default {
    verb: 'willChange',
    namespace: 'organization',
    relation: 'person',
    description: 'Auto populate the name field',
    logic: willChange
};
/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willChange({ data }) {
    if (data?.newRecord?.meta)
        //if there is a meta, the object existed before: this is an edit
        //no title change on edit.
        return data;

    if (!['firstName', 'middleName', 'lastName', 'personId'].includes(data.propertyName))
        //if it is not a change to one of the fields that affect the name: break.
        return data;
    //if the name was manually changed: break.
    if (isManuallyOverridden(data.newRecord)) return data;
    const futureRecord = { ...data.newRecord, [data.propertyName]: data.newValue };
    const title = generateName(futureRecord);
    return { ...data, newRecord: { ...data.newRecord, title } };
}

function isManuallyOverridden(person) {
    // if the old title matches what would have been generated from the rest of the data
    // we count it as not overridden
    return generateName(person) !== (person.title || '');
}

export function generateName(person) {
    const { firstName, middleName, lastName, personId } = person;
    const titleParts = [];

    if (lastName) titleParts.push(lastName && (firstName || middleName) ? `${lastName},` : lastName);
    if (firstName) titleParts.push(firstName);
    if (middleName) titleParts.push(middleName);
    if (personId && (lastName || firstName || middleName)) titleParts.push('-');
    if (personId) titleParts.push(personId);

    return titleParts.join(' ');
}
