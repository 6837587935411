import notHandledHere from '../notHandledHere';
import { database } from 'lib_ui-services';
import setStatusOnPatchCollection from './setStatusOnPatchCollection';
import logging from '@sstdev/lib_logging';

const _p = {
    setStatusOnPatchCollection
};
export const _private = _p;

export default {
    verb: 'doingRollback',
    excludedNamespaceRelations: notHandledHere,
    type: 'remove',
    description: 'perform actual rollback of removal',
    //this is the actual logic:
    logic: doingRollback
};

/**
 *
 * @typedef {{_id:string, [s:string]:any}} BackboneRecord
 * @typedef {{ record: BackboneRecord, path:string, httpHeaders:object}} reduxOfflineRemoveResponse
 *
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: reduxOfflineRemoveResponse;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 */
async function doingRollback({ data, context }) {
    await restoreRemovedDocument(data, context);
    await _p.setStatusOnPatchCollection(context, 'clientSendFailure');
}

async function restoreRemovedDocument(data, context) {
    const _id = data.record?._id || data._id || data.id;
    if (!_id) {
        logging.error('Failed to restore removed record. No _id present', context);
        return;
    }
    const db = database.get();
    //perform the actual rollback
    await db.updateMany(
        {
            criteria: { _id },
            operations: {
                $unset: {
                    'meta.deleted': '',
                    'meta.deletedBy': '',
                    'meta.deletedTime': ''
                }
            }
        },
        context
    );
}
