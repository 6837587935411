import asTransactionCode from '../../../../utilities/asTransactionCode';

export default {
    verb: 'willCreate',
    namespace: 'metadata',
    relation: 'changeHistory',
    description: 'Add a title.',
    //type: '',
    priority: 15,
    //useCaseIds:[]
    //this is the actual logic:
    logic: willCreate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willCreate({ data }) {
    //change history is kind of a mock -patch.
    //It stores a simplified indicator of the update.
    //But similarly, it does not have a title. So make one up.
    return { ...data, newRecord: { ...data.newRecord, title: asTransactionCode(data.newRecord._id) } };
}
