import { register, render } from '../ZNode';

async function SplitLayout(props) {
    const {
        output,
        zNode: { left = {}, right = {} },
        height,
        padding
    } = props || { zNode: {} };

    let result = output;

    // eslint-disable-next-line no-undef
    if (__UNIT_TESTING__) {
        result = result.addRectangle(right.offset.x, padding, 1, height - 2 * padding);
    }

    result = await left.content.reduce(async (_sofar, content) => {
        const sofar = await _sofar;
        return render({ ...props, output: sofar, zNode: content });
    }, result.addComment('Left').setHome(left.offset));

    return right.content.reduce(async (_sofar, content) => {
        const sofar = await _sofar;
        return render({ ...props, output: sofar, zNode: content });
    }, result.addComment('Right').setHome(right.offset));
}

SplitLayout.height = ({ height }) => height;

register('SplitLayout', SplitLayout);
export default SplitLayout;
