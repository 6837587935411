import { createElement as rc, useLayoutEffect } from 'react';
// eslint-disable-next-line import/extensions
import _Login from './Login.js';

export default function Login(props) {
    // Make email autofocus in browser despite weirdness with the chrome autofill
    useLayoutEffect(() => {
        const loginEmail = document.querySelector('#loginEmail');
        setTimeout(() => {
            loginEmail?.focus();
            loginEmail?.select();
        }, 500);
    }, []);

    return rc(_Login, props);
}
