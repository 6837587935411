import lodash from 'lodash';
const { camelCase } = lodash;
import { useEffect, useMemo, useState } from 'react';
import { filterFactory } from 'lib_ui-services';
import useActiveRecord from './useActiveRecord';
import useSplashRecord from './useSplashRecord';
import useNavigationSelection from './useNavigationSelection';
import useUserContext from './useUserContext';
import { hooks } from 'lib_ui-primitives';
import useDataRightsHNode from './useDataRightsHNode';
import useHasFeatureFlag from './useHasFeatureFlag';
const { useRouter } = hooks;

const EMPTY_ARRAY = [];

const _p = {
    useNavigationSelection,
    useUserContext,
    useRouter,
    useDataRightsHNode,
    useHasFeatureFlag
};

export const _private = _p;

export default function useFilterMetadata(hNode) {
    const activeRecord = useActiveRecord();
    const splashRecord = useSplashRecord();
    const router = _p.useRouter();
    const routePath = router.getRouteStateKey();

    const dataRightsFilterHNode = _p.useDataRightsHNode(hNode);

    const clientDataRights = _p.useHasFeatureFlag('clientDataRights');
    const hNodeChildren = useMemo(() => {
        let children = hNode?.children ?? EMPTY_ARRAY;
        if (clientDataRights && dataRightsFilterHNode) {
            children = children.concat(dataRightsFilterHNode);
        }
        return [...children];
    }, [dataRightsFilterHNode, hNode?.children, clientDataRights]);

    //only copy a very limited amount of data into the context, it's just for filtering
    //and there is a LOT of info in the userContext...
    const { briefUserReference, 'identity:role': role, activeUseCaseId } = _p.useUserContext() || {};
    const navigationSelectionContext = _p.useNavigationSelection();

    // For the most part, we want to mutate a constant reference
    // for the contextualInfo to avoid unnecessary rerenders.
    const [contextualInfo, setContextualInfo] = useState({});
    contextualInfo.limitedUserContext = { ...briefUserReference, 'identity:role': role, activeUseCaseId };
    contextualInfo.activeRecord = activeRecord;
    contextualInfo.splashRecord = splashRecord;
    contextualInfo.navigationSelection = navigationSelectionContext;
    contextualInfo.currentRoute = routePath;

    // In the case of a ForeignRelationActiveRecord filter,
    // force a rerender by changing the contextualInfo reference
    // if the actual ActiveRecord changes.  This allows item history
    // lists inside a detail pane (for instance) to render new
    // content if the user selects a different item ActiveRecord.
    useEffect(() => {
        if (hNodeChildren.some(c => c.hNodeType === 'ForeignRelationActiveRecord')) {
            setContextualInfo(prev => ({ ...prev, activeRecord }));
        }
    }, [hNodeChildren, activeRecord]);

    // //Using the same trick for navigationSelection doesn't seem to work
    // useEffect(() => {
    //     if (hNodeChildren.some(c => c.hNodeType.startsWith('ByNavigationSelection'))) {
    //         setContextualInfo(prev => ({ ...prev, navigationSelection: navigationSelectionContext }));
    //     }
    // }, [hNodeChildren, navigationSelectionContext]);

    // so, make the memo dependent on navigationSelectionContext
    return useMemo(() => {
        return extractFiltersFromHNodes(hNodeChildren, { ...contextualInfo, navigationSelectionContext });
    }, [hNodeChildren, contextualInfo, navigationSelectionContext]);
}

// Package contextual information required by some filters.
export function extractFiltersFromHNodes(hNodes, contextualInfo) {
    return hNodes
        .filter(c => c.hNodeTypeGroup === 'filter' || c.hNodeType === 'PatchDetail')
        .reduce((filters, hNode) => {
            const filterType = camelCase(hNode.hNodeType);
            // If we don't have support for this filter type
            if (!filterFactory(filterType)) {
                throw new Error(`Unknown filter type: '${filterType}'`);
            }

            // If we previously already encountered a filter of this type
            if (filters[filterType] != null) {
                if (
                    ![
                        'orderBy',
                        'includedValues',
                        'excludedValues',
                        'byNavigationSelectionDropdownCriteria',
                        'byNavigationSelectionCriteria'
                    ].includes(filterType)
                ) {
                    throw new Error(`Filter type ${filterType} is already defined.`);
                }
                /*
                    ORDER BY
                    orderBy is special. We want to allow (sub)sorting by multiple fields so there may
                    be multiple filter blocks defined for orderBy in the metadata.
                    Therefore:
                        Array.concat will automatically spread a passed in array, or just add the value if not an array
                        so concatenating it to the empty array solves that we don't need to worry if it already was an array or not.

                        We are intentionally inverting the order, so that when applying the sorts,
                        The first one on the block, will be the last one in the array, and therefore applied last.
                        Making it the primary sort.

                        E.g. sorting with [transactionDate,AssetNo], will first sort by transactionDate
                        and then, as we have a stable sort, sorting by AssetNo, will result in a "grouping" (primary sort) by AssetNo,
                        and within those list per assetNo, it will then be sorted by transactionDate

                    INCLUDED VALUES & EXCLUDED VALUES
                    There might be several different properties for which certain values need to be included or excluded.
                    E.g. the the found report should only show `active=true && inventory.found=true`
                    Note, the order doesn't matter, so for simplicity, we use the same as orderBy

                    BY NAVIGATION SELECTION (DROPDOWN) CRITERIA
                    Same as included/excluded values, e.g. company, building, and room
            */
                filters[filterType] = [
                    filterFactory(filterType).fromHNode(hNode, contextualInfo, extractFiltersFromHNodes)
                ].concat(filters[filterType]);

                return filters;
            }

            // this is the first encounter of this filter type
            filters[filterType] = filterFactory(filterType).fromHNode(hNode, contextualInfo, extractFiltersFromHNodes);
            return filters;
        }, {});
}
