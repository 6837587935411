export async function send(printer, zpl) {
    const { serialNo, ipAddress, manufacturer } = printer;

    const url = `https://${ipAddress}/pstprnt`;
    //add a "special" printer, just in case we ever need to debug issues with a customer's printer or ZPL
    //just set the serial (or even the IP Address) to 'console', and it will spill the ZPL into the console.
    if ([ipAddress, serialNo].includes('console')) {
        /* eslint-disable no-console */
        console.group(url);
        console.log(zpl);
        console.groupEnd();
        /* eslint-enable no-console */
        return;
    }

    if (manufacturer === 'Zebra Technologies') {
        sendToZebraPrinter(printer, zpl);
        return;
    }
    if (ipAddress) {
        return sendToIpPrinter(printer, zpl);
    }
}

async function sendToIpPrinter(printer, zpl) {
    const { ipAddress } = printer;

    const url = `https://${ipAddress}/pstprnt`;
    const controller = new AbortController();
    setTimeout(() => controller.abort(), 2000);
    try {
        await fetch(url, {
            method: 'POST',
            headers: { 'Content-Length': zpl.length, 'Content-Type': 'text/plain;charset=UTF-8' },
            body: zpl,
            mode: 'no-cors',
            //if a port is passed in, the request will go through, and labels will be printed, but the request hangs forever.
            //so, we set a timer to just end the request, which will result in the caught error below, that we can safely ignore;
            signal: ipAddress.includes(':') ? controller.signal : undefined
        });
    } catch (error) {
        if (error.message !== 'The user aborted a request.') {
            throw error;
        }
    }
}

async function sendToZebraPrinter(printer, zpl) {
    printer.send(zpl);
}

export default { send };
