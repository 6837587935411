export function calculateDepreciation(data, now = new Date()) {
    const items = Array.isArray(data) ? data : [data];
    const result = items.map(item => {
        const { acquisitionDate, cost, recoveryPeriod, scrapValue = 0 } = item;
        if (!acquisitionDate || !cost || !recoveryPeriod) {
            //we don't have enough info:
            return { ...item, amountDepreciated: undefined, depreciatedValue: undefined };
        }
        if (acquisitionDate >= now) {
            // depreciation not applicable. Item purchased in the future (compared to report date)
            return item;
        }
        //depreciationPeriod is in years, so monthly depreciated value is:
        const monthlyDepreciation = (cost - scrapValue) / (recoveryPeriod * 12);

        const depreciationStart = firstMonthNoDepreciation(new Date(acquisitionDate));

        // How many full months are between the dates:
        const depreciationEnd = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1, 0, 0));
        const depreciatedMonths = countMonthsBetween(depreciationStart, depreciationEnd);
        const remainingValue = Math.min(cost, Math.max(scrapValue, cost - depreciatedMonths * monthlyDepreciation));
        const amountDepreciated = cost - remainingValue;

        return {
            ...item,
            amountDepreciated,
            depreciatedValue: remainingValue
        };
    });
    return Array.isArray(data) ? result : result[0];
}

function firstMonthNoDepreciation(acquisitionDate) {
    let start = new Date(acquisitionDate);
    return new Date(Date.UTC(start.getUTCFullYear(), start.getUTCMonth() + 1, 1, 0, 0, 0, 0));
}

function countMonthsBetween(fromDate, toDate) {
    const start = new Date(fromDate);
    const end = new Date(toDate);
    return 12 * (end.getUTCFullYear() - start.getUTCFullYear()) + (end.getUTCMonth() - start.getUTCMonth());
}
