import logging from '@sstdev/lib_logging';
import { executeForEach } from '../registeredServiceQueues';
import { constants } from 'lib_ui-services';

const _p = {
    executeForEach
};

export const _private = _p;
export default {
    verb: 'doingChange',
    namespace: 'sensor',
    relation: 'service',
    description: 'Alter the service (e.g. adjust power)',
    logic: doingChange
};

async function doingChange({ data, context }) {
    try {
        return _p.executeForEach('update', { sensorType: constants.sensorTypes.ANY, ...data }, context);
    } catch (err) {
        logging.error(err, context);
    }
}
