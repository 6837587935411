import { createElement as rc, forwardRef } from 'react';
import PropTypes from 'prop-types';
import ShortText from './ShortText';

const Email = forwardRef(function Email(props, ref) {
    const {
        hNode,
        hNode: { minLength = 0, maxLength = 256 }
    } = props;

    return rc(ShortText, {
        ...props,
        ref,
        hNode: {
            ...hNode,
            maxLength,
            minLength,
            pattern:
                "[a-zA-Z0-9.!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]+@[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?)*"
        },
        type: 'email'
    });
});

Email.propTypes = {
    hNode: PropTypes.object.isRequired
};
Email.displayName = 'Email';

export default Email;
