import { createElement as rc, useEffect, Fragment, useContext, useMemo, useCallback, useRef } from 'react';
import Route from './Route';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import useNetwork from '../../hooks/useNetwork';
import { hooks } from 'lib_ui-primitives';
const { useRouter } = hooks;

// /**
//  * Route containers can exist at multiple levels in the hNode hierarchy
//  * When a navigation occurs, the hNodes inside the route are rendered
//  * in this container.
//  * Example uses: LeftSideNavAppLayout and TabNav have route containers inside them.
//  * Be aware that SideNav DOES NOT have a RouteContainer in it because it must reside inside the sliding drawer area
//  * of LeftSideNavAppLayout while the route rendering must occur in the main content area.
//  */
export default function RouteContainer(props) {
    const { hNode, currentRoute } = props || {};
    const { viewPort } = useContext(ThemeContext);
    const router = useRouter();
    const { isConnected } = useNetwork();
    const routeContainerRoot = currentRoute || '/';

    const routeTree = useRef([]).current;

    /**
    //      * Descend by recursion through the hNode metadata to find routes
    //      * @param {object} hNode - metadata hNode
    //      * @param {string} currentRoute - the route before evaluating the hNode
    //      * @param {object} routes - react router route components
    //      * @param {array} parentRoutes - the route tree so far
    //      * @returns
    //      */
    const getRoutesFromHNode = useCallback(
        (hNode, currentRoute, routes = [], parentRoutes = routeTree, foundDefaultRoute = false) => {
            // hNodeTypeGroup of navHeading always indicates a new route
            // Example hNodeTypes (i.e. Blocks) in 'navHeading' group include 'NavHeading' and 'TabNavHeading'
            let nextParentRoutes = parentRoutes;
            if (hNode.hNodeTypeGroup === 'navHeading' && hNode.hNodeType !== 'NavHeadingRecordList') {
                // Do not render routes that are not allowed for this viewport.
                const allowedViewPorts = hNode.showForViewport || hNode.showForViewPort;
                //we should redirect to the default route here....
                if (allowedViewPorts && allowedViewPorts.length && !allowedViewPorts.includes(viewPort)) return null;

                // Append new route node for navHeading
                currentRoute = normalizedNewRoute(currentRoute, hNode.title);

                // Build onto the route tree by appending routes as they appear (which will be depth first)
                nextParentRoutes = [];
                parentRoutes.push({ [currentRoute]: nextParentRoutes });

                // Do not render routes that are not allowed for this platform
                //do not render routes that are hidden while offline, if offline, and vice versa
                if (!isConnected && hNode.hideWhen?.includes?.('OFFLINE')) {
                    routes.push(
                        rc(Route, {
                            key: currentRoute,
                            parentHNode: hNode,
                            currentRoute,
                            exact: false,
                            defaultRoute: false,
                            unavailableMessage: 'Unavailable while offline. Please go online to access this content.'
                        })
                    );
                } else if (isConnected && hNode.hideWhen?.includes?.('ONLINE')) {
                    routes.push(
                        rc(Route, {
                            key: currentRoute,
                            parentHNode: hNode,
                            currentRoute,
                            exact: false,
                            defaultRoute: false,
                            unavailableMessage: 'Unavailable while online. Please go offline to access this content.'
                        })
                    );
                } else if (!isConnected && hNode.disableWhen?.some?.(x => x.disableWhen === 'OFFLINE')) {
                    routes.push(
                        rc(Route, {
                            key: currentRoute,
                            parentHNode: hNode,
                            currentRoute,
                            exact: false,
                            defaultRoute: false,
                            unavailableMessage: hNode.disableWhen.find(x => x.disableWhen === 'OFFLINE').message
                        })
                    );
                } else if (isConnected && hNode.disableWhen?.some?.(x => x.disableWhen === 'ONLINE')) {
                    routes.push(
                        rc(Route, {
                            key: currentRoute,
                            parentHNode: hNode,
                            currentRoute,
                            exact: false,
                            defaultRoute: false,
                            unavailableMessage: hNode.disableWhen.find(x => x.disableWhen === 'ONLINE').message
                        })
                    );
                } else {
                    let isDefaultRoute = false;
                    if (
                        //if we haven't found the default route yet
                        !foundDefaultRoute &&
                        //and if this node does NOT have any navHeading or TabNav children
                        !hNode.children?.some(
                            childHNode =>
                                childHNode.hNodeTypeGroup === 'navHeading' || childHNode.hNodeType === 'TabNav'
                        )
                    ) {
                        //then make this the default route
                        isDefaultRoute = true;
                        foundDefaultRoute = true;
                    }
                    routes.push(
                        rc(Route, {
                            key: currentRoute,
                            parentHNode: hNode,
                            currentRoute,
                            exact: false,
                            defaultRoute: isDefaultRoute
                        })
                    );
                }
            }

            if (hNode.children) {
                hNode.children.forEach(childHNode => {
                    // TabNav has its own RouteContainer in it, so don't include its routes
                    // for this RouteContainer.  Otherwise, they will both be rendered (at the same time)
                    // which causes all kinds of weird stuff.
                    if (childHNode.hNodeType === 'TabNav') return;

                    // Recurse to find subroutes.  This allows nesting navigation in the sidenav.
                    getRoutesFromHNode(childHNode, currentRoute, routes, nextParentRoutes, foundDefaultRoute);
                });
            }
        },
        [isConnected, routeTree, viewPort]
    );

    const allRoutes = useMemo(() => {
        const accumulatedRoutes = [];
        getRoutesFromHNode(hNode, routeContainerRoot, accumulatedRoutes);
        return accumulatedRoutes;
    }, [hNode, routeContainerRoot, getRoutesFromHNode]);

    /**
     * Descend through the route tree depth first, returning with the first route path that does not have children
     * @param {array} routeSet - the set of routes to select among (each may have children)
     * @returns string - a routepath
     */
    const getDefaultRoute = useCallback(
        (routeSet = routeTree) => {
            if (routeSet.length === 0) {
                throw new Error('Empty parent route set should not be passed.');
            }
            let first = routeSet[0];
            // Always just one key per route. The key is the routePath.
            let routePath = Object.keys(first)[0];
            let childrenRouteSet = first[routePath];
            if (childrenRouteSet.length) {
                routePath = getDefaultRoute(childrenRouteSet);
            }
            return routePath;
        },
        [routeTree]
    );

    const redirectedToDefaultRouteIfNecessary = useCallback(
        location => {
            // location could be the path string, or if this is caused from the history listener, it could
            // be a location object.
            const path = typeof location === 'string' ? location : location.pathname;
            // If this is the root path for this route container, determine the default route to render
            if (path === routeContainerRoot || path === '/' || path.endsWith('.html')) {
                router.goToLocation(getDefaultRoute());
                return true;
            }
            return false;
        },
        [getDefaultRoute, routeContainerRoot, router]
    );

    useEffect(() => {
        redirectedToDefaultRouteIfNecessary(router.location.pathname);
    }, [router.location.pathname, redirectedToDefaultRouteIfNecessary]);
    useEffect(() => {
        const unlisten = router.history.listen(redirectedToDefaultRouteIfNecessary);
        return () => unlisten();
    }, [redirectedToDefaultRouteIfNecessary, router.history]);

    return rc(Fragment, null, allRoutes);
}

RouteContainer.propTypes = {
    hNode: PropTypes.object.isRequired,
    currentRoute: PropTypes.string.isRequired
};

function normalizedNewRoute(currentRoute, newAddition) {
    if (currentRoute.endsWith('/')) {
        return currentRoute + newAddition;
    } else {
        return currentRoute + '/' + newAddition;
    }
}
