import { ObjectId } from 'lib_ui-services';
import lodash from 'lodash';
const { omit } = lodash;

export default {
    verb: 'didCreate',
    namespace: 'metadata',
    relation: 'useCaseDetail',
    description: 'Clone all pages, etc or create the initial ones ',
    //type: '',
    //useCaseIds:[]
    prerequisites: [
        //namespaces
        getLinkedDocumentsFor('namespace'),
        //profile menu
        getLinkedDocumentsFor('profileMenu'),
        //navigation
        getLinkedDocumentsFor('navigation'),
        //pages
        getLinkedDocumentsFor('page')
    ],
    //this is the actual logic:
    logic: didCreate
};

function getLinkedDocumentsFor(relation) {
    return {
        context: {
            verb: 'get',
            namespace: 'metadata',
            relation: relation,
            type: 'find'
        },
        query: ({ data }) => {
            const {
                'metadata:useCaseDetail': { _id }
            } = data.newRecord;
            return {
                'metadata:useCaseDetail._id': _id,
                'meta.deleted': { $exists: false }
            };
        }
    };
}

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function didCreate({ data, prerequisiteResults, dispatch, log }) {
    const { _id, title, meta } = data.newRecord;
    const [
        { result: namespaces },
        {
            result: [profileMenu]
        },
        {
            result: [navigation]
        },
        { result: pages }
    ] = prerequisiteResults;

    log.debug(`[METADATA] Cloning ${namespaces.length} Namespaces`);
    const clonedNamespaces = namespaces.map(ns => ({
        ...stripLokiFields(ns),
        _id: ObjectId(),
        'metadata:useCaseDetail': { _id, title },
        meta
    }));
    const nsContext = { verb: 'create', namespace: 'metadata', relation: 'namespace' };
    for (const ns of clonedNamespaces) {
        await dispatch({ newRecord: ns, clone: true }, nsContext, true);
    }

    log.debug('[METADATA] Cloning profile menu');
    const clonedProfileMenu = {
        ...stripLokiFields(profileMenu),
        _id: ObjectId(),
        'metadata:useCaseDetail': { _id, title },
        meta
    };
    const pmContext = { verb: 'create', namespace: 'metadata', relation: 'profileMenu' };
    await dispatch({ newRecord: clonedProfileMenu, clone: true }, pmContext, true);

    log.debug('[METADATA] Cloning navigation');
    const clonedNavigation = {
        ...stripLokiFields(navigation),
        _id: ObjectId(),
        'metadata:useCaseDetail': { _id, title },
        meta
    };

    log.debug(`[METADATA] Cloning ${pages.length} pages`);
    const clonedPages = pages
        .map(p => {
            const oldId = p._id;
            const newId = ObjectId();
            if (updatePageId(clonedNavigation, oldId, newId)) {
                return { ...stripLokiFields(p), _id: newId, 'metadata:useCaseDetail': { _id, title }, meta };
            }
            return null;
        })
        .filter(Boolean);

    //verify we have all needed pages:
    const neededPages = pagesFromNavigation(clonedNavigation);
    const missingPages = neededPages.filter(np => !clonedPages.find(cp => cp._id.toString() === np._id.toString()));
    if (missingPages.length) {
        throw new Error(`Failed to clone pages: ${missingPages.map(mp => mp.title).join(', ')}`);
    }

    //save all cloned pages
    const pageContext = { verb: 'create', namespace: 'metadata', relation: 'page' };
    for (const page of clonedPages) {
        await dispatch({ newRecord: page, clone: true }, pageContext, true);
    }
    //saved  the cloned and updated navigation
    const navContext = { verb: 'create', namespace: 'metadata', relation: 'navigation' };
    await dispatch({ newRecord: clonedNavigation, clone: true }, navContext, true);

    log.debug(`[METADATA] Creating source change history record for ${title}`);
    //create a patch for the new useCaseDetail to track the source.
    const patchRecord = {
        _id: ObjectId(),
        'metadata:useCaseDetail': { _id, title },
        patches: [
            {
                op: 'copy',
                path: '/',
                value: title,
                oldValue: data.newRecord['metadata:useCaseDetail']?.title
            }
        ]
    };
    //create a patch record
    dispatch({ newRecord: patchRecord }, { verb: 'create', namespace: 'metadata', relation: 'changeHistory' });

    //no need to return anything.
    log.debug(`[METADATA] Successfully branched ${title} from ${data.newRecord['metadata:useCaseDetail']?.title}`);
}

function updatePageId(navigation, oldId, newId) {
    if (navigation._id === oldId) {
        navigation._id = newId;
        return true;
    } else if (navigation.children) {
        return navigation.children.reduce((sofar, child) => {
            // use JavaScript short-circuit evaluation to avoid unnecessary recursion
            return sofar || updatePageId(child, oldId, newId);
        }, false);
    }
    return false;
}

function stripLokiFields(record) {
    let result = omit(record, ['_idx', '_meta', '$loki', 'tenantId']);
    return result;
}

function pagesFromNavigation(navEntry) {
    if (!navEntry.children) {
        return navEntry;
    }
    return navEntry.children.reduce((acc, child) => {
        return acc.concat(pagesFromNavigation(child));
    }, []);
}
