import { createElement as rc } from 'react';
import MultiPickerDropDownSearch from './MultiPickerDropDownSearch';

const foreignNamespace = 'identity';
const foreignRelation = 'user';
/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function MultiUserPickerDropDownSearch(props) {
    return rc(MultiPickerDropDownSearch, { ...props, hNode: { ...props.hNode, foreignNamespace, foreignRelation } });
}

export default MultiUserPickerDropDownSearch;
