import { createElement as rc, useContext, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import UiNotificationContext from '../contexts/UiNotificationContext';
import { globalConfig, constants } from 'lib_ui-services';
import styled from '../styled';
import fromTheme from '../fromTheme';
import cuid from 'cuid';

const StyledToast = styled(ToastContainer).attrs(({ theme }) => ({
    position: theme.mobile ? 'top-center' : 'bottom-center'
}))`
    top: ${props => (props?.theme.mobile ? '35px' : 'inherit')};
    .Toastify__progress-bar--default {
        background: ${fromTheme('progressBarColor')};
    }
    .Toastify__toast--success {
        background: ${fromTheme('colorScheme', 'success')};
    }
    .Toastify__toast--error {
        background: ${fromTheme('colorScheme', 'error')};
    }
    .Toastify__toast--default {
        background: ${fromTheme('baseBackgroundColor')};
        color: ${fromTheme('defaultFontColor')};
    }
    .Toastify__close-button--default {
        color: ${fromTheme('defaultFontColor')};
    }
`;
let currentMessage = '';
export default function Toast() {
    const notificationContext = useContext(UiNotificationContext);
    const containerId = useRef(cuid());
    // Sometimes a new instance of this component will be rendered (in a modal for instance)
    // and take precedence.  If there are any toasts already displayed, then this new instance
    // should display them as well.
    useEffect(() => {
        const toastsInThisInstance = [];
        notificationContext.openToasts.forEach(t => {
            const { message, timeout, isError } = t;
            const correlationId = cuid();
            const onClose = () => {
                const thisIndex = toastsInThisInstance.findIndex(t => t.correlationId === correlationId);
                toastsInThisInstance.splice(thisIndex, 1);
            };
            if (isError) {
                toastsInThisInstance.push({
                    correlationId,
                    internalId: toast.error(message, {
                        containerId: containerId.current,
                        onClose,
                        autoClose: timeout
                    })
                });
            } else {
                toastsInThisInstance.push({
                    correlationId,
                    internalId: toast(message, {
                        containerId: containerId.current,
                        onClose,
                        autoClose: timeout
                    })
                });
            }
        });

        // When unmounting this instance be sure to dismiss any toasts it has displayed
        return () => {
            toastsInThisInstance.forEach(({ internalId }) => {
                toast.dismiss(internalId);
            });
        };
    }, [notificationContext.openToasts]);

    useEffect(() => {
        const removeHandler = notificationContext.addTypeHandler(constants.notificationTypes.TOAST, payload => {
            const { message, timeout = globalConfig().notificationTimeout, isError } = payload;

            // Prevent displaying the same message twice:
            if (currentMessage && currentMessage === message) return;
            currentMessage = message;

            // Keep track of which toasts are open
            const id = cuid();
            notificationContext.openToasts.push({
                id,
                message,
                timeout,
                isError
            });

            const onClose = () => {
                // We do want Errors to be rethrown (though not shown multiple times at the same time).
                // So, reset the "currentMessage" AFTER an error popup has closed
                currentMessage = '';

                // Remove this toast from the context when it closes.
                const toasts = notificationContext.openToasts;
                const thisIndex = toasts.findIndex(t => t.id === id);
                if (thisIndex >= 0) {
                    toasts.splice(thisIndex, 1);
                }
            };
            if (isError) {
                toast.error(message, {
                    containerId: containerId.current,
                    autoClose: timeout,
                    onClose
                });
            } else {
                toast(message, {
                    containerId: containerId.current,
                    autoClose: timeout,
                    onClose
                });
            }
        });
        return removeHandler;
    }, [notificationContext]);

    // prettier-ignore
    return rc(StyledToast, {
        enableMultiContainer: true,
        position: 'bottom-center',
        autoClose: globalConfig().notificationTimeout,
        newestOnTop: true,
        closeOnClick: true,
        draggable: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        containerId: containerId.current
    });
}
