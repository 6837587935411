import ObjectId from '../../ObjectId';
import filterFactory from '../../filterFactory';
import * as http from '../../http/index';
import getQueryUrl from '../../http/getQueryUrl';
import logging from '@sstdev/lib_logging';
import libIsomorphicJsonPatch from '@sstdev/lib_isomorphic-json-patch';
const { rfc6902 } = libIsomorphicJsonPatch;

export default database => async action => {
    try {
        database.publish(
            {
                syncInProgress: true
            },
            { verb: 'change', namespace: 'application', relation: 'runtime' }
        );
        const [verb] = action.type.split('_');
        const recordSuccessfullySynced = await sendInventoryChanges(database, action[verb].userId);

        database.publish(
            {
                message: `Resync has completed. ${
                    recordSuccessfullySynced ? ` ${recordSuccessfullySynced} records synced` : ''
                }`,
                addToList: true
            },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );
    } catch (err) {
        logging.error(err);
        database.publish(
            {
                message:
                    'Resync failed.  There was an error during the resync process.  The error has been logged for investigation.',
                addToList: true
            },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );
    }

    // Clear sync in progress flag
    database.publish(
        {
            syncInProgress: false
        },
        { verb: 'change', namespace: 'application', relation: 'runtime' }
    );

    async function sendInventoryChanges(database, userId) {
        const itemCollection = database.relationDb('item', 'item');

        const criteria = {
            'inventory.foundBy._id': userId,
            'meta.deleted': { $exists: false }
        };
        const itemsToSync = await itemCollection.find(criteria);

        const baseUrl = http.getUrlForRelation(database.settings.namespaces, 'item', 'item');
        let failureCount = 0;

        database.setStorageState('item', 'item', 'totalRecordsSynced', 0);

        for await (let clientItem of itemsToSync) {
            try {
                updateSyncProgress(database, itemsToSync.length);

                // Remove unused loki item info
                const cleanClientItem = { ...clientItem };
                // eslint-disable-next-line no-unused-vars
                const { modifiedBy, modifiedTime, createdBy, createdTime, ...lokiMeta } = cleanClientItem.meta;
                cleanClientItem.meta = {
                    modifiedBy,
                    modifiedTime,
                    createdBy,
                    createdTime
                };
                delete cleanClientItem['$loki'];
                delete cleanClientItem._idx;

                // Find corresponding server item by _id and use it compare with client item
                const getByIdUrl = http.getUrlForRelation(
                    database.settings.namespaces,
                    'item',
                    'item',
                    cleanClientItem._id
                );
                let getByIdResult;
                try {
                    getByIdResult = await http.get(getByIdUrl);
                } catch (err) {
                    if (err.status !== 404) {
                        throw err;
                    }
                }
                if (getByIdResult?.items?.length > 0) {
                    await mergeAndCreatePatchForChange(cleanClientItem, getByIdResult.items[0], baseUrl);
                    continue;
                }

                // Try to find by assetNo if an item is not found by _id
                const assetFilters = {
                    fullTextSearch: filterFactory('fullTextSearch').getFilter(cleanClientItem.assetNo),
                    propertiesToSearch: filterFactory('propertiesToSearch').getFilter(['assetNo']),
                    exactMatchOnly: filterFactory('exactMatchOnly').getFilter(),
                    preferLocal: true
                };
                const getByAssetNoUrl = getQueryUrl(baseUrl, assetFilters, new ObjectId());
                const getByAssetNoResult = await http.get(getByAssetNoUrl);
                if ((getByAssetNoResult?.items?.length ?? 0) === 0) {
                    // Handle when no matching server item is found i.e new item is created in lokijs
                    // Create new item in server
                    await http.post(baseUrl, cleanClientItem);
                } else if (getByAssetNoResult.items.length === 1) {
                    // If one matching item is found, process it
                    await mergeAndCreatePatchForChange(cleanClientItem, getByAssetNoResult.items[0], baseUrl);
                    // Replace the local version of the asset which has the wrong _id.
                    await itemCollection.remove({
                        type: 'remove_item_item',
                        remove: { trueDelete: true, id: cleanClientItem._id }
                    });
                    await itemCollection.insert({
                        type: 'insert_item_item',
                        insert: {
                            newModel: getByAssetNoResult.items[0]
                        }
                    });
                } else {
                    // Handle when multiple matching items are found
                    // Just throw an error because it shouldn't have happened
                    throw new Error(
                        `Force sync not yet implemented when multiple items are found in server (assetNo: ${cleanClientItem.assetNo})`
                    );
                }
            } catch (err) {
                failureCount++;
                logging.error(err);
            }
        }
        if (failureCount) logging.info(`${failureCount} updates failed.`);
    }

    async function mergeAndCreatePatchForChange(localItem, serverItem, baseUrl) {
        const serverFound = serverItem.inventory[0].found;
        const serverModifiedtime = serverItem.meta.modifiedTime;
        const localFound = localItem.inventory[0].found;
        const localModifiedtime = localItem.meta.modifiedTime;
        // original item wins, nothing else to do
        if (!serverFound && !localFound) return;

        // Merge modified and orig info with right priority
        const serverItemWins =
            (serverFound && !localFound) || (serverFound && localFound && serverModifiedtime >= localModifiedtime);
        const inventory = serverItemWins
            ? { ...localItem.inventory[0], ...serverItem.inventory[0] }
            : { ...serverItem.inventory[0], ...localItem.inventory[0] };
        const mergedItem = serverItemWins
            ? { ...localItem, ...serverItem, inventory: [inventory] }
            : { ...serverItem, ...localItem, inventory: [inventory] };

        // In case they are different (e.g. found using assetNo instead of _id)
        mergedItem._id = serverItem._id;

        const patch = rfc6902.compare(serverItem, mergedItem, true);
        // Do not optimistically update the database, but let the socket
        // push the change back to client side.
        if (patch.length > 0) {
            const patchUrl = `${baseUrl}/${serverItem._id}`;
            await http.patch(patchUrl, patch);
        }
    }

    /**
     *
     * @param {Object} database
     * @param {function} database.getStorageState
     * @param {function} database.setStorageState
     * @param {function} database.publish
     * @param {number} total
     */
    function updateSyncProgress(database, total) {
        const syncCount = database.getStorageState('item', 'item').totalRecordsSynced + 1;
        database.setStorageState('item', 'item', 'totalRecordsSynced', syncCount);

        database.publish(
            {
                mainTitle: 'Re-sync',
                description:
                    'Performing a forced synchronization of items.  This may take a few minutes, depending on network speed.',
                title: 'Items',
                current: syncCount,
                total
            },
            { verb: 'update', namespace: 'application', relation: 'progress' }
        );

        if (syncCount / total >= 1 || total === 0) {
            setTimeout(() => {
                database.publish(
                    { mainTitle: 'Re-sync' },
                    { verb: 'reset', namespace: 'application', relation: 'progress' }
                );
            }, 2000);
        }
    }
};
