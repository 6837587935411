import lokijs from 'lokijs';
import logging from '@sstdev/lib_logging';

// avoids unnecessary logging in unit test output.
//eslint-disable-next-line no-undef
const _setTimeout = __UNIT_TESTING__ ? func => func() : setTimeout;

// log when loki rebuilds
lokijs.DynamicView.prototype.queueRebuildEvent = function () {
    if (this.rebuildPending) {
        return;
    }
    this.rebuildPending = true;

    let self = this;
    _setTimeout(function () {
        if (self.rebuildPending) {
            self.rebuildPending = false;
            self.rebuildCount = self.rebuildCount == null ? 1 : self.rebuildCount + 1;
            logging.debug(
                `[FIREPANTS] Emitting rebuild ${self.name} | interval: ${
                    self.options.minRebuildInterval
                } | now: ${new Date().getTime()} | rebuild count: ${self.rebuildCount}`
            );
            self.emit('rebuild', self);
        }
    }, this.options.minRebuildInterval); // default 5 mills
};
