//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _didRollback_namespace_relation from './didRollback_namespace_relation';
import _doingRollback_namespace_relation_create from './doingRollback_namespace_relation_create';
import _doingRollback_namespace_relation_remove from './doingRollback_namespace_relation_remove';
import _doingRollback_namespace_relation_update from './doingRollback_namespace_relation_update';
import _setStatusOnPatchCollection from './setStatusOnPatchCollection';
export const didRollback_namespace_relation = _didRollback_namespace_relation;
export const doingRollback_namespace_relation_create = _doingRollback_namespace_relation_create;
export const doingRollback_namespace_relation_remove = _doingRollback_namespace_relation_remove;
export const doingRollback_namespace_relation_update = _doingRollback_namespace_relation_update;
export const setStatusOnPatchCollection = _setStatusOnPatchCollection;
export default {didRollback_namespace_relation, doingRollback_namespace_relation_create, doingRollback_namespace_relation_remove, doingRollback_namespace_relation_update, setStatusOnPatchCollection};

