import { errors as _errors } from 'lib_ui-primitives';
import basicValidation from '../namespace/relation/doingValidate_namespace_relation';

export default {
    verb: 'doingValidate',
    namespace: 'transaction',
    relation: 'checkOut',
    description: 'Prevent submitting checkouts without items',
    priority: (basicValidation.priority || 0) + 5, //we want this to run BEFORE basic validation
    prerequisites: [
        {
            context: {
                verb: 'count',
                namespace: 'location',
                relation: 'company'
            },
            query: {
                'meta.deleted': { $exists: false }
            }
        },
        {
            context: {
                verb: 'count',
                namespace: 'location',
                relation: 'building'
            },
            query: {
                'meta.deleted': { $exists: false }
            }
        }
    ],
    logic: doingValidate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingValidate({ data, prerequisiteResults }) {
    if (!data?.newRecord?.['organization:person']?._id) {
        const errors = { 'organization:person': new Error('`To` is required') };
        throw new _errors.ValidationError('Please select to whom you are checking out to.', errors);
    }
    if (!data?.newRecord?.includedItems?.length) {
        const errors = { includedItems: new Error('`Assets to Check Out` is required') };
        throw new _errors.ValidationError('Please select at least 1 Asset to check out.', errors);
    }
    const {
        'location:company': company,
        'location:building': building,
        'location:location': room
    } = data?.newRecord || {};

    const companyCount = prerequisiteResults?.[0]?.result?.[0] || 0;
    const buildingCount = prerequisiteResults?.[1]?.result?.[0] || 0;

    if (companyCount > 1 && company && !room) {
        const errors = {
            'location:location': new Error('If a Company is selected, both Building and Room are required.')
        };
        throw new _errors.ValidationError(
            'If a Company is selected on the Details tab, then both Building and Room are required.',
            errors
        );
    } else if (buildingCount > 1 && building && !room) {
        const errors = {
            'location:location': new Error('If a Building is selected, then Room is required.')
        };
        throw new _errors.ValidationError(
            'If a Building is selected on the Details tab, then Room is required.',
            errors
        );
    }
    if (room && !company) {
        const errors = {
            'location:company': new Error('If a Room is selected, then Company is required.')
        };
        throw new _errors.ValidationError(
            'If a Room is selected on the Details tab, then Company is required.',
            errors
        );
    }
    if (room && !building) {
        const errors = {
            'location:building': new Error('If a Room is selected, then Building is required.')
        };
        throw new _errors.ValidationError(
            'If a Room is selected on the Details tab, then Building is required.',
            errors
        );
    }

    return data;
}
