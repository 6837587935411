import { useState, useCallback } from 'react';

export default function useActiveComponentAwareness() {
    const [active, setActive] = useState(false);

    const onFocus = useCallback(
        /**
         * @param {import('react').SyntheticEvent} event
         */
        () => {
            setActive(true);
        },
        []
    );

    const onBlur = useCallback(e => {
        // Do not blur if the user clicked on a scan button (https://stackoverflow.com/a/60094794/1356444).
        // (relatedTarget will be the scan button in that case).
        if (e.relatedTarget == null || !e.currentTarget.contains(e.relatedTarget)) {
            setActive(false);
        }
    }, []);
    return { active, onBlur, onFocus };
}
