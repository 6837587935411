export default {
    verb: 'didUpdate',
    namespace: 'metaui',
    relation: 'captionOverrides',
    description: 'Show Caption Overrides notification',
    logic: showNotification
};

function showNotification({ dispatch }) {
    dispatch(
        {
            message: 'Caption Override values saved.',
            addToList: false,
            isError: false
        },
        {
            verb: 'pop',
            namespace: 'application',
            relation: 'notification'
        }
    );
}
