import { memo, createElement as ce } from 'react';
import PropTypes from 'prop-types';
import { styled, View } from 'lib_ui-primitives';

const CollapseStyled = styled(View)`
    overflow: hidden;
    height: auto;
`;

const Collapsed = styled(CollapseStyled).attrs({ name: 'collapsed' })`
    height: 0px;
    overflow: hidden;
`;

const Expanded = styled(CollapseStyled).attrs({ name: 'expanded' })`
    //
`;

/**
 * @type {React.FunctionComponent<{isCollapsed: boolean}>} Collapse
 */
const Collapse = props => {
    const { children, isCollapsed, ...restOfProps } = props || {};
    return isCollapsed ? ce(Collapsed, restOfProps, children) : ce(Expanded, restOfProps, children);
};

Collapse.propTypes = {
    isCollapsed: PropTypes.bool.isRequired
};

// wrap the component in memo so that it only re-renders on state and prop changes
// similar to using `PureComponent` but for functional components
export default memo(Collapse);
