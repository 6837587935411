import lodash from 'lodash';
const { memoize } = lodash;
const objectStore = memoize(
    thing => thing,
    thing => JSON.stringify(thing)
);

/**
 * This is to avoid rerenders if you are using an object that will a different
 * reference, but otherwise identical.
 * Don't pass in an object that has self-references or functions.  It's not that fancy.
 * @param {object} obj - to memoize
 * @returns memoized object
 */
export default function useMemoizedObject(obj) {
    return objectStore(obj);
}
