import { useCallback, useMemo, useState } from 'react';
import lodash from 'lodash';
const { camelCase } = lodash;
import transformMethods from './transformMethods';
import logging from '@sstdev/lib_logging';

const EMPTY_ARRAY = [];
export default function useTransforms(hNode) {
    const { transforms = EMPTY_ARRAY } = hNode || {};
    const [error, setError] = useState();

    const onChangeTransformation = useMemo(() => {
        try {
            const t = transforms
                .filter(h => h.transformInline)
                .map(getTransformMethodsFromHNode)
                .filter(x => x);
            return composeTransformMethods(t);
        } catch (error) {
            logging.error(error);
            setError(error);
            return x => x;
        }
    }, [transforms]);

    const transformOnChange = useCallback(
        value => {
            //apply the configured transformations
            return onChangeTransformation(value);
        },
        [onChangeTransformation]
    );

    const onSubmitTransformation = useMemo(() => {
        try {
            const t = transforms.map(getTransformMethodsFromHNode).filter(x => x);
            return composeTransformMethods(t);
        } catch (error) {
            logging.error(error);
            setError(error);
            return x => x;
        }
    }, [transforms]);

    const transformOnSubmit = useCallback(
        value => {
            //apply the configured transformations
            return onSubmitTransformation(value);
        },
        [onSubmitTransformation]
    );
    return { transformOnChange, transformOnSubmit, error };
}

/**
 * Takes in an array of functions and chains them together
 * so that the output of one function is used as the input
 * of the next until we have one unified transform function.
 * @param  {Array<Function>} funcs
 */
function composeTransformMethods(funcs) {
    if (funcs.length === 0) {
        return value => value;
    }

    if (funcs.length === 1) {
        return funcs[0];
    }

    return funcs.reduce((a, b) => value => a(b(value)));
}

/**
 * Looks at the provided hNodes and returns an array of defined transform methods that correlate to the given hNodes
 * @param {HNode} transformHNode
 */
function getTransformMethodsFromHNode(transformHNode) {
    /** @type {(hNode: HNode) => (value: any) => any} */
    const getTransform = transformMethods[camelCase(transformHNode.hNodeType)];
    if (!getTransform) throw new Error(`A transform for "${transformHNode.hNodeType}" has not yet been implemented`);

    return getTransform(transformHNode);
}
