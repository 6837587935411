import react, { Fragment } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
const { get } = lodash;
import { Barcode } from 'lib_ui-primitives';
import { BarcodeLine, PreviewLine, PreviewText } from '../styles';

const rc = react.createElement;

export default function BarcodeContent(props) {
    const {
        record,
        hNode: { propertyName, includeHumanReadable, excludeHumanReadable, excludedHumanReadable }
    } = props || { hNode: {} };
    const value = get(record, propertyName, '').trim();
    //we are having some issues with nested toggles. this will allow us to continue using either include or exclude
    // default: show the human readable under the barcode
    let humanReadableOnBarcode = true;
    if (typeof excludedHumanReadable === 'boolean') {
        // support typo in Asset Tracking configuration
        humanReadableOnBarcode = !excludedHumanReadable;
    } else if (typeof excludeHumanReadable === 'boolean') {
        humanReadableOnBarcode = !excludeHumanReadable;
    } else if (typeof includeHumanReadable === 'boolean') {
        humanReadableOnBarcode = includeHumanReadable;
    }

    //return as fragment, as we want the 2 lines to behave as 2 separate lines for spacing purposes.
    return rc(
        Fragment,
        null,
        rc(
            BarcodeLine,
            null,
            value &&
                rc(Barcode, {
                    value,
                    fontSize: 11,
                    height: 18,
                    width: 1,
                    displayValue: false
                })
        ),
        humanReadableOnBarcode && rc(PreviewLine, null, rc(PreviewText, {}, value))
    );
}

BarcodeContent.propTypes = {
    hNode: PropTypes.shape({
        propertyName: PropTypes.string,
        includeHumanReadable: PropTypes.bool,
        excludeHumanReadable: PropTypes.bool
    }),
    record: PropTypes.object
};
