import { useReducer, useEffect, useContext, useRef } from 'react';
import { ObjectId } from 'lib_ui-services';
import ModalQueueContext from '../contexts/ModalQueueContext';

export default function useModalQueue(visible, modalId, jumpQueue = false) {
    // Trick to allow us to tell react to rerender the consuming component if data changes
    const [, forceRender] = useReducer(s => s + 1, 0);

    const queue = useContext(ModalQueueContext);
    const instanceRef = useRef(modalId || new ObjectId());

    useEffect(() => {
        const holdId = instanceRef.current;
        // If there is a new queue leader, force this component
        // to rerender if there is a new modalAvailable value.
        const unsubscribe = queue.onNewLeader(newLeader => {
            if (newLeader === instanceRef.current) {
                forceRender();
            }
        });

        return () => {
            queue.dequeue(holdId);
            unsubscribe();
        };
    }, [queue]);

    useEffect(() => {
        if (visible && !queue.contains(instanceRef.current)) {
            if (jumpQueue) {
                queue.jumpQueue(instanceRef.current);
            } else {
                queue.enqueue(instanceRef.current);
            }
        }
        if (!visible && queue.contains(instanceRef.current)) {
            queue.dequeue(instanceRef.current);
        }
    }, [visible, queue, jumpQueue]);

    const showModal = queue.peek() === instanceRef.current && visible;
    return showModal;
}
