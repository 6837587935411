import { useEffect, useState, useCallback, useRef } from 'react';
import useDbView from '../../../hooks/useDbView';
import logging from '@sstdev/lib_logging';
import useEventSink from '../../../hooks/useEventSink';

const _p = { useDbView };
export const _private = _p;

const EMPTY_ARRAY = [];

/**
 * Abstract away the assembly of the recordset that will be given to the dropdown list.
 */

export default function useAssembleRecords(
    hNode,
    filterText = '',
    predefinedRecords,
    consumerReady,
    supportWildCard = false
) {
    const [, , request] = useEventSink();
    const [checkedForPredefined, setCheckedForPredefined] = useState(false);
    const [filteredPredefinedRecords, setFilteredPredefinedRecords] = useState(predefinedRecords);
    const [hintRecord, setHintRecord] = useState(EMPTY_ARRAY);
    const {
        id,
        propertyName,
        namespace,
        foreignNamespace: tempForeignNamespace,
        relation,
        foreignRelation: tempForeignRelation,
        dropdownDisplayProperties
    } = hNode || {};

    // Some consumers of this abstract component use namespace/relation instead of
    // foreignNamespace/foreignRelation.
    const foreignNamespace = tempForeignNamespace || namespace;
    const foreignRelation = tempForeignRelation || relation;

    useEffect(() => {
        // If the dropdown is called directly with predefinedRecords, then we don't need to check for them.
        if (predefinedRecords != null && predefinedRecords.length > 0 && !checkedForPredefined) {
            setCheckedForPredefined(true);
        }
        const doAsync = async () => {
            const result = await request(
                {},
                { verb: 'get', namespace: 'application', relation: 'predefinedRecords' },
                50,
                false
            );
            setCheckedForPredefined(true);
            if (result?.records) {
                setFilteredPredefinedRecords(result.records);
            }
        };
        consumerReady && doAsync();
    }, [predefinedRecords, checkedForPredefined, request, foreignNamespace, foreignRelation, consumerReady]);
    // We must always call useDbView because of the rules of hooks, but the last parameter of the hook
    // will determine if the hook internals will bother performing database operations that could
    // effect performance.
    const { records, recordCount, viewCriteria, viewReady } = _p.useDbView(
        foreignNamespace,
        foreignRelation,
        `${foreignNamespace}_${foreignRelation}_${id}`,
        hNode,
        checkedForPredefined && consumerReady && predefinedRecords == null,
        false
    );

    const filterGate = useRef({ gate: Promise.resolve(), preCount: 0, postCount: 0 });
    // If the records are from the database, use viewCriteria to filter them.
    useEffect(() => {
        async function doAsync() {
            if (predefinedRecords != null) return;
            // Queue up the filter changes using a gate so that they cannot overlap.
            // Otherwise, the removeFilters will sometimes be called after but finish before
            // the applyFilters - meaning the filters will be reapplied instead of being removed
            // as intended.
            const release = await createPromiseGate(filterGate);
            try {
                if (filterText === '') {
                    viewCriteria.removeFilters(id + 'ddInput');
                } else {
                    const splitProp = dropdownDisplayProperties?.length > 1 ? { splitChar: ',' } : {};
                    await viewCriteria.applyFilters(
                        {
                            // paged filter is a noop for a local lookup, but is needed for an online lookup.
                            paged: { page: 0 },
                            fullTextSearch: {
                                searchTerm: filterText,
                                ...splitProp
                            },
                            propertiesToSearch: { propertiesToSearch: dropdownDisplayProperties || [propertyName] }
                        },
                        id + 'ddInput'
                    );
                }
            } catch (e) {
                logging.error('Error applying filter', e);
            } finally {
                release();
            }
        }
        doAsync();
    }, [viewCriteria, filterText, id, propertyName, predefinedRecords, dropdownDisplayProperties]);

    const getHintRecord = useCallback(
        (value, hintText) => {
            const prop = (dropdownDisplayProperties || [propertyName])[0];
            return { _id: 'hint-regex', value, [prop]: hintText };
        },
        [propertyName, dropdownDisplayProperties]
    );

    useEffect(() => {
        if (recordCount > 0 || !filterText.includes('*') || !supportWildCard) {
            setHintRecord(prev => {
                if (!prev?.length) {
                    return prev;
                }
                return EMPTY_ARRAY;
            });
            return;
        }
        //we got NO records back, the filtertext includes a * AND we are configured to support the wildcard
        if (filterText.endsWith('*')) {
            const hintRecord = getHintRecord(filterText, `Wildcard Filter: ${filterText}`);
            setHintRecord([hintRecord]);
        } else {
            // we only support wildcards at the END of the string
            const hintRecord = getHintRecord('', 'Invalid wild card usage.');
            setHintRecord([hintRecord]);
        }
    }, [recordCount, filterText, supportWildCard, predefinedRecords, getHintRecord]);

    // If the records are passed in, then use built-in array filter.
    useEffect(() => {
        if (predefinedRecords == null) return;
        if (filterText === '') {
            setFilteredPredefinedRecords(predefinedRecords);
        } else {
            const filteredRecords = predefinedRecords.filter(r =>
                r[propertyName]?.toLowerCase().includes(filterText.toLowerCase())
            );
            setFilteredPredefinedRecords(filteredRecords);
        }
    }, [predefinedRecords, filterText, propertyName]);

    if (hintRecord?.length) {
        return { records: hintRecord, recordCount: hintRecord.length, viewReady, currentRecordFilter: filterText };
    }
    if (filteredPredefinedRecords == null) {
        return { records, recordCount, viewReady, currentRecordFilter: filterText };
    } else {
        return {
            records: filteredPredefinedRecords,
            recordCount: filteredPredefinedRecords.length,
            viewReady,
            currentRecordFilter: filterText
        };
    }
}

// Use a promise to gate - you must wait at the gate until the promise from the previous
// call has completed.
async function createPromiseGate(ref) {
    const oldRef = ref.current;
    let release;
    // Replace the old promise before waiting for it to finish,
    // to make sure a subsequent calls would wait on our new promise,
    // not the same promise we are waiting on.
    // `await` allows other threads to perform logic, but as long as we don't call `await`
    //  JavaScript can be considered atomic.
    ref.current = {
        gate: new Promise(resolve => (release = resolve))
    };
    await oldRef.gate;
    return release;
}
