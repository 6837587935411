import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary, Button } from 'lib_ui-primitives';
import ActiveRecord from '../../contextProviders/ActiveRecord';
import ActionButton from '../../actionElement/Button';
import SimpleDialog from '../../dialog/SimpleDialog';
import Form from '../../form/Form';
import InnerImportForm from './InnerImportForm';
import FormButton from '../../formButton/FormButton';
import useEventSink from '../../../hooks/useEventSink';
import { metadata } from 'lib_ui-services';

const rc = React.createElement;
function ImportForm(props) {
    const {
        currentRoute,
        hNode,
        hNode: { id, title, namespace, relation, displayTitleOnButton = false, iconName }
    } = props || { hNode: {} };

    const [, publish] = useEventSink();

    const relationLabel = metadata.getPrettyRelationName(namespace, relation);
    let openButton = {
        title: 'Import',
        id: 'openButton' + id,
        forAction: 'open',
        namespace,
        relation,
        buttonStyle: displayTitleOnButton ? 'AccentOne' : 'IconAction',
        iconName: iconName ?? 'cloud_upload'
    };
    let dialogHNode = {
        id: `dialog${id}`,
        viewport: ['all'],
        hNodeType: 'SimpleDialog',
        hNodeTypeGroup: 'dialog',
        title: title || 'Import ' + relationLabel,
        closeAction: 'close',
        openAction: 'open',
        namespace,
        relation,
        openActionType: { verb: 'open', namespace, relation },
        closeActionType: { verb: 'close', namespace, relation }
    };

    // prettier-ignore
    return rc(Fragment, null,
        rc(ActionButton, { hNode: openButton, currentRoute: currentRoute, 'data-testid': 'import-button' }),
        rc(SimpleDialog, { hNode: dialogHNode, currentRoute: currentRoute },
            rc(ErrorBoundary, null,
                rc(ActiveRecord, { namespace: 'import', relation: 'import', isNew: true },
                    rc(Form, { hNode: { id: `form${id}`, namespace: 'import', relation: 'import' } },
                        rc(InnerImportForm, {
                            ...props,
                            hNode: {
                                ...hNode,
                                title: relationLabel,
                                hNodeType: 'composite',
                                hNodeTypeGroup: 'formElement'
                            }
                        }),
                        rc(FormButton, {
                            hNode: {
                                id: `import${id}`,
                                formAction: 'submit',
                                title: 'IMPORT',
                                buttonStyle: 'Positive',
                                hNodeType: 'FormButton',
                                hNodeBlockGroup: 'formButtons',
                                hNodeTypeGroup: 'formButton'
                            }
                        }),
                        rc(Button, {
                            id: `close${id}`,
                            value: 'CLOSE',
                            buttonStyle: 'AccentOne',
                            hNode: {
                                hNodeType: 'FormButton',
                                hNodeBlockGroup: 'formButtons',
                                hNodeTypeGroup: 'formButton',
                            },
                            onClick: () => publish(null, dialogHNode.closeActionType)
                        })
                    )
                )
            )
        )
    );
}

ImportForm.propTypes = {
    dispatch: PropTypes.func,
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired,
    errors: PropTypes.object,
    children: PropTypes.array,
    relationLabel: PropTypes.string,
    currentRoute: PropTypes.string
};

export default ImportForm;
