import { createElement as rc, Fragment, useRef, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { Text, Link, Card, hooks } from 'lib_ui-primitives';
import Form from '../form/Form';
import { Email } from '../formElement';
import FormButton from '../formButton/FormButton';
import Disclaimer from './Disclaimer';
import useActiveRecord from '../../hooks/useActiveRecord';
import useAuthenticationType from './useAuthenticationType';
import useEventSink from '../../hooks/useEventSink';
import { nativeOnlyProperties } from 'lib_ui-services';
import { CardBox, HeaderText } from './styles';

const { useRouter } = hooks;

let hNode = {
    id: 'passwordResetForm',
    title: '',
    hNodeType: 'Form',
    hNodeTypeGroup: 'form'
};

const _p = { useAuthenticationType };
export const _private = _p;
export default function PasswordReset() {
    const errors = _p.useAuthenticationType();
    const emailRef = useRef();
    const router = useRouter();
    const { namespace, relation, record, type } = useActiveRecord();
    const [subscribe, publish] = useEventSink();
    const onKeyPress = e => {
        if (e.key === 'Enter') {
            if (emailRef.current.value == null || emailRef.current.value.length === 0) {
                emailRef.current.focus();
            } else {
                publish({ record }, { verb: 'submit', namespace, relation, type });
            }
        }
    };
    let groupPath = '';

    if (router.groupNumber != undefined && router.groupNumber !== '') {
        groupPath = `/g/${router.groupNumber}`;
    }

    useEffect(
        () =>
            subscribe({ verb: 'create', namespace: 'security', relation: 'profile', status: 'success' }, () =>
                router.goToLocation(`${groupPath}/reset/confirmed`)
            ),
        [subscribe, router, groupPath]
    );

    // prettier-ignore
    return rc(Fragment, null,
        rc(CardBox, null,
            rc(Card.Header, { style: { justifyContent: 'center' } },
                rc(HeaderText, { name: 'card-header-title' }, 'Password Reset')
            ),
            rc(Card.Body, null,
                rc(Switch, {},
                    rc(Route, { path: `${groupPath}/reset/confirmed` }, rc(Text, null, 'Reset email sent.')),
                    rc(Route, {},
                        rc(Form, { hNode, errors, popErrorNotifications: false, heightGreedy: false, centerFields: true },
                            rc(Email, {
                                ref: emailRef,
                                onKeyPress,
                                ...nativeOnlyProperties({
                                    onChange: text => emailRef.current.value = text
                                }),
                                hNode: {
                                    id: 'resetEmail',
                                    title: 'Email',
                                    hNodeType: 'ShortText',
                                    hNodeTypeGroup: 'formElement',
                                    placeholder: 'Email',
                                    propertyName: 'email',
                                    defaultValue: '',
                                    treePosition: { sequence: 1 }
                                }
                            }),
                            rc(FormButton, {
                                hNode: {
                                    id: 'reset',
                                    title: 'RESET',
                                    hNodeType: 'FormButton',
                                    hNodeBlockGroup: 'formButtons',
                                    hNodeTypeGroup: 'formButton',
                                    formAction: 'submit'
                                }
                            })
                        )
                    )
                )
            ),
            rc(Card.Footer, { style: { justifyContent: 'space-between', flexShrink: 0, borderTopWidth: 0 } },
                // eslint-disable-next-line no-undef
                !__SST_REACT_NATIVE__ && rc(Link, { id: 'signup-link', to: `${groupPath}/signup` }, rc(Text, { id: 'signup-text' }, 'Sign up')),
                // eslint-disable-next-line no-undef
                !__SST_REACT_NATIVE__ && rc(Link, { id: 'login-link', to: `${groupPath}/login` }, rc(Text, { id: 'login-text' }, 'Login'))
            )
        ),
        rc(Disclaimer)
    );
}
