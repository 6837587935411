import { createElement as rc } from 'react';
import * as reactMenu from '@szhsin/react-menu';

// react-menu has an invalid prop type definition for children
// (only allows a function).
if (reactMenu.FocusableItem?.propTypes?.children) {
    delete reactMenu.FocusableItem.propTypes.children;
}

export default props => {
    const { children, ...otherProps } = props;
    return rc(reactMenu.SubMenu, { label: otherProps.hNode.title }, children);
};
