// make this easier to mock
const _p = { isHidden };
export default adjustAvailableControlsByHiddenFlag;
export const _private = _p;
function adjustAvailableControlsByHiddenFlag(hNode, role) {
    if (hNode && hNode.children) {
        for (let i = hNode.children.length - 1; i >= 0; i--) {
            if (_p.isHidden(hNode.children[i], role)) {
                // hNode.children.splice(i, 1);
                // 2024-03-05: It seems that the server is pruning out any and all hiddenFor elements.
                // The new User Configurable Roles would be a LOT easier if that really is the case,
                // due to not having to worry about backwards compatibility for new roles (specifically on RN)
                // So, throwing an error now instead to help us identify it that is NOT the case.
                throw new Error('It was not expected to receive hiddenFor elements on the client');
            } else {
                adjustAvailableControlsByHiddenFlag(hNode.children[i], role);
            }
        }
    }
}

function isHidden(hNode, role) {
    return hNode.hiddenFor && hNode.hiddenFor.includes(role);
}
