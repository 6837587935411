import { useRef, createElement as rc, useLayoutEffect, useEffect, useContext } from 'react';
import { styled, View, contexts } from 'lib_ui-primitives';
const { SplitResizeContext } = contexts;

const _Carousel = styled(View).attrs({ name: 'carousel' })`
    flex-grow: 0;
    align-items: flex-end;
`;

// jsDom has trouble with this measurement.  :(
const _p = {
    publishOnLayout
    // overrideMeasurements: {
    //     offsetHeight,
    //     offsetWidth
    // }
};
export const _private = _p;
/**
 * Web version needs an onLayout implementation. (It comes free for react native.)
 * @param {object} props
 * @returns CarouselContainer
 */
export default function CarouselContainer(props) {
    const { onLayout, ...otherProps } = props;
    const ref = useRef();
    const splitSizes = useContext(SplitResizeContext);
    useEffect(() => {
        _p.publishOnLayout(ref, onLayout);
    }, [splitSizes, onLayout]);

    useLayoutEffect(() => {
        _p.publishOnLayout(ref, onLayout);
    }, [onLayout]);

    return rc(_Carousel, { ref, ...otherProps });
}
CarouselContainer.displayName = 'CarouselContainer';
CarouselContainer._private = _p;

function publishOnLayout(ref, onLayout) {
    const { offsetWidth: width, offsetHeight: height } = _p.overrideMeasurements ?? ref.current;
    if (width > 0) {
        onLayout({
            nativeEvent: {
                layout: {
                    width,
                    height
                }
            }
        });
    }
}
