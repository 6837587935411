import logging from '@sstdev/lib_logging';
import { executeForEach } from '../registeredServiceQueues';

export default {
    verb: 'doingStop',
    namespace: 'sensor',
    relation: 'service',
    description: 'Stop a service that will provide sensor reads',
    logic
};

async function logic({ data, context }) {
    try {
        executeForEach('stopSensorService', data, context);
    } catch (err) {
        logging.error(err, context);
    }
}
