import { network, globalConfig, http } from 'lib_ui-services';

export default {
    verb: 'doingCreate',
    namespace: 'security',
    relation: 'profile',
    type: 'reset',
    prerequisites: [],
    description: 'Reset a user password',
    //this is the actual logic:
    logic: reset
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function reset({ data }) {
    const { userName, email } = data.newRecord;
    const networkStatus = await network.getStatus();
    if (networkStatus.isOnline) {
        const { protocol, hostname: host, port } = globalConfig();
        const protocolAndHost = port ? `${protocol}//${host}:${port}` : `${protocol}//${host}`;
        let url = `/api/security/reset?username=${userName || email}&protocolAndHost=${protocolAndHost}`;
        await http.get(url, false); // Skip token validation
        return {};
    } else {
        throw new Error('Unable to reset password while offline. Please go online to initiate a password reset.');
    }
}
