import isDeployed from './isDeployed';
import getHostName from './getHostname';
export default function getEnvironment(productionBuild) {
    const hostname = getHostName(productionBuild);
    if (!isDeployed()) {
        return 'local';
    }
    const path = hostname.split('.');
    return path[0] === 'app' ? 'prod' : path[0];
}
