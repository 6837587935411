import { createElement as rc, useEffect, useMemo } from 'react';
import { ShortText, Toggle, FilePicker } from '../../formElement';
import useActiveRecord from '../../../hooks/useActiveRecord';
import useFormContext from '../../../hooks/useFormContext';
import useEventSink from '../../../hooks/useEventSink';
import ColumnSelector from './ColumnSelector';
import { metadata } from 'lib_ui-services';
import { ExternalLink } from 'lib_ui-primitives';

import { InnerImportForm as _InnerImportForm, Dimmed, LeftToRight, LeftAlignedTopToBottom } from './styles';

//for some reason, overriding the the ExternalLink with styled() doesn't work....
const linkStyle = {
    fontSize: '16px',
    marginLeft: '3px',
    flexGrow: 1
};

const _p = {
    getRawMetadata: x => metadata.getRawMetadata(x)
};
export const _private = _p;

const InnerImportForm = props => {
    const {
        hNode: { id, treePosition, namespace: foreignNamespace, relation: foreignRelation }
    } = props || { hNode: {} };

    const { record } = useActiveRecord();
    const { newRecord } = useFormContext();
    const { firstRowContainsHeader, dataModel, dataSample } = newRecord || record || {};

    const [, publish] = useEventSink();

    //trigger a "new" event to allow initializing defaults:
    useEffect(() => {
        publish(
            {
                hNode: props.hNode
            },
            { verb: 'new', namespace: 'import', relation: 'import' }
        );
    }, [props.hNode, publish]);

    const downloadURL = useMemo(() => {
        if (_p.getRawMetadata('title') !== 'FLAIR') return;
        return _p.getRawMetadata('downloadURL') || 'https://sstid.com/software/FLAIR/flairConversionTool.exe';
    }, []);

    return rc(
        _InnerImportForm,
        {
            hNode: {
                //hack to make the components show up in a dialog:
                hNodeTypeGroup: 'formElement',
                hNodeType: 'ad-hoc',
                defaultValue: ''
            }
        },
        rc(ShortText, {
            hNode: {
                id: `ShortText${id}`,
                hNodeType: 'ShortText',
                hNodeTypeGroup: 'formElement',
                title: 'Import Name',
                required: true,
                propertyName: 'title',
                treePosition: { sequence: 1 }
            }
        }),
        rc(FilePicker, {
            hNode: {
                id: `upload${id}`,
                hNodeType: 'FilePicker',
                hNodeTypeGroup: 'formElement',
                title: 'Data File',
                required: true,
                propertyName: 'file',
                mimeTypes: ['.csv', '.xlsx', '.xls'],
                defaultValue: []
            }
        }),
        rc(Toggle, {
            hNode: {
                id: `fileHeader${id}`,
                title: '',
                hNodeType: 'Toggle',
                hNodeTypeGroup: 'formElement',
                propertyName: 'firstRowContainsHeader',
                toggleValues: ['', 'First row contains column headers'],
                // make sure a default value is set, otherwise useFormControl will default to false and _then_ true,
                // leaving the internal state of useToggle set to false
                defaultValue: true
            }
        }),
        rc(ColumnSelector, {
            dataModel,
            dataSample,
            firstRowContainsHeader,
            foreignNamespace,
            foreignRelation,
            hNode: {
                id: `columnSelector${id}`,
                title: 'Preview:',
                hNodeType: 'ColumnSelector',
                hNodeTypeGroup: 'formElement',
                propertyName: 'columns',
                defaultValue: [],
                treePosition
            }
        }),
        rc(
            LeftAlignedTopToBottom,
            { hNode: { defaultValue: '' } },
            rc(
                Dimmed,
                null,
                'Note: rows with data in invalid formats (marked with ⚠️) or missing required fields (marked with ❓) will not be imported.'
            ),
            downloadURL &&
                rc(
                    LeftToRight,
                    null,
                    rc(Dimmed, null, 'Need to convert your import file first? '),
                    rc(ExternalLink, {
                        url: downloadURL,
                        title: 'Download the conversion tool here.',
                        includeExternalIcon: false,
                        style: linkStyle
                    })
                )
        )
    );
};

export default InnerImportForm;
