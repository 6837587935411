export default {
    verb: 'didCreate',
    namespace: 'print',
    relation: 'job',
    description: 'close print dialog (if any)',
    logic: closeDialog
};

function closeDialog({ data, dispatch }) {
    const { result, newRecord } = data || {};
    if (newRecord?.calibrateRfid) {
        dispatch(
            {},
            {
                verb: 'cancel',
                namespace: 'print',
                relation: 'job',
                type: 'ignore-changes'
            }
        );
        dispatch(
            {
                title: 'Label Printing',
                message: [
                    'Calibration request sent to printer.',
                    'No actual content will be printed at this time.',
                    'When calibration has finished, please reposition the labels in the printer',
                    'to realign the front of the label with the front of the printer.'
                ],
                okButtonText: 'OK',
                noCancelOption: true,
                okAction: () => {
                    dispatch(
                        {},
                        {
                            verb: 'open',
                            namespace: 'print',
                            relation: 'job'
                        }
                    );
                }
            },
            {
                verb: 'confirm',
                namespace: 'application',
                relation: 'user'
            }
            // don't send a cancel, we do NOT want to close the dialog.
        );
    } else {
        dispatch(
            {
                title: 'Label Printing',
                message: `${result.count} Label${result?.count === 1 ? '' : 's'} sent to printer.`,
                okButtonText: 'OK',
                noCancelOption: true
            },
            {
                verb: 'confirm',
                namespace: 'application',
                relation: 'user'
            }
        );
        dispatch(
            {},
            {
                verb: 'cancel',
                namespace: 'print',
                relation: 'job',
                type: 'ignore-changes'
            }
        );
    }
}
