//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _ConfirmationDialog from './ConfirmationDialog';
import _ProgressDialog from './ProgressDialog';
import _SimpleDialog from './SimpleDialog';
import _SyncDialog from './SyncDialog';
export const ConfirmationDialog = _ConfirmationDialog;
export const ProgressDialog = _ProgressDialog;
export const SimpleDialog = _SimpleDialog;
export const SyncDialog = _SyncDialog;
export default {ConfirmationDialog, ProgressDialog, SimpleDialog, SyncDialog};

