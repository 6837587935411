import lodash from 'lodash';
const { get } = lodash;
import globalConfig from '../globalConfig';
import ObjectId from '../ObjectId';

const headerPrefix = 'x-bb-meta';

export default function getHttpHeaders(headerEntries) {
    const resultHeaders = {};
    // createdBy metadata is just an ObjectId in some old data, while the new one is an Object with _id and displayName properties
    // Updated old values to new format though displayName would still be missing
    // Use modifiedBy if they represent same user since that has displayName as well
    let createdBy, modifiedBy;
    globalConfig().validHeaderProperties.forEach(propertyName => {
        const value = get(headerEntries, propertyName);
        if (typeof value !== 'undefined') {
            if (propertyName == 'createdBy' && value && typeof value !== 'object') createdBy = value;
            if (propertyName == 'modifiedBy' && value && typeof value === 'object') modifiedBy = value;
            const name = `${headerPrefix}-${propertyName.replace('.', '_')}`;
            // Prefer JSON representation if available (e.g. date use ISO8601 instead of longDateString or something)
            resultHeaders[name] = serialize(value);
        }
    });
    if (createdBy) {
        if (modifiedBy && typeof modifiedBy === 'object' && modifiedBy._id === createdBy) {
            resultHeaders['x-bb-meta-createdBy'] = serialize(modifiedBy);
        } else {
            resultHeaders['x-bb-meta-createdBy'] = serialize({ _id: createdBy });
        }
    }
    return resultHeaders;
}

function serialize(value) {
    if (value.toJSON) return value.toJSON();
    if (typeof value === 'object') {
        if (value instanceof ObjectId) {
            return value.toString();
        }
        return JSON.stringify(convertAllObjectIdsToStrings(value));
    }
    return value.toString();
}

function convertAllObjectIdsToStrings(item) {
    if (item instanceof ObjectId) {
        return item.toString();
    } else if (typeof item === 'object') {
        if (Array.isArray(item)) {
            item = item.map(convertAllObjectIdsToStrings);
        } else {
            Object.entries(item).forEach(([key, value]) => {
                // Sometimes things like displayName are null or undefined.
                item[key] = convertAllObjectIdsToStrings(value == null ? '' : value);
            });
        }
    }
    return item;
}
