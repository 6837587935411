import { createElement as rc, memo } from 'react';
import logging from '@sstdev/lib_logging';
import PropTypes from 'prop-types';
import MultiSelectBoundary from '../contextProviders/MultiSelectBoundary';
import ReadProvider from '../contextProviders/ReadProvider';
import { Route as _Route, h1, styled, View } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';
import FocusProvider from '../contextProviders/FocusProvider';
import RouteReadOwnershipProvider from '../contextProviders/RouteReadOwnershipProvider';
import RouteChildren from './RouteChildren';
import RouteBoundMachineProvider from '../contextProviders/RouteBoundMachineProvider';

const LoadingStyle = styled(View)`
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Centered = styled(h1)`
    text-align: center;
`;

const ALL_SENSOR_TYPES = [
    constants.sensorTypes.RFID,
    constants.sensorTypes.MANUAL,
    constants.sensorTypes.BARCODE,
    constants.sensorTypes.BLE
];
/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function Route(props) {
    const {
        parentHNode: _parentHNode,
        parentHNode: { title, id, children },
        currentRoute,
        exact = false,
        defaultRoute = false,
        unavailableMessage
    } = props;
    const readOwnerId = `${title}${id}`;
    if (!children) return null;
    if (unavailableMessage) {
        return rc(LoadingStyle, {}, rc(Centered, null, unavailableMessage));
    }

    let nonNavHeadings = children.filter(c => c.hNodeTypeGroup !== 'navHeading');
    let parentHNode = _parentHNode;
    if (!children[0]) {
        logging.debug(`${parentHNode.hNodeType} ${title} is misconfigured. it has an undefined child.`);
        return null;
    }
    //if the first child is a NavHeadingRecordList, skip that level
    if (children[0].hNodeType === 'NavHeadingRecordList') {
        nonNavHeadings = children[0].children.filter(c => c.hNodeTypeGroup !== 'navHeading');
        parentHNode = children[0];
    }
    if (nonNavHeadings.length > 0) {
        // prettier-ignore
        return rc(RouteBoundMachineProvider, {},
            rc(RouteReadOwnershipProvider, { currentRoute, readOwnerId },
                rc(MultiSelectBoundary, { currentRoute },
                    rc(FocusProvider, { name: currentRoute },
                        rc(_Route, {
                            key: currentRoute,
                            name: title,
                            path: currentRoute,
                            exact: exact,
                            isDefaultRoute: defaultRoute,
                        },
                            // Outermost read context in route will automatically own all sensors
                            rc(ReadProvider, { id: readOwnerId, desiredSensorTypes: ALL_SENSOR_TYPES },
                                rc(RouteChildren, { parentHNode, currentRoute })
                            )
                        )
                    )
                )
            )
        );
    } else {
        return null;
    }
}

Route.defaultProps = {};

Route.propTypes = {
    parentHNode: PropTypes.object.isRequired,
    exact: PropTypes.bool.isRequired,
    defaultRoute: PropTypes.bool.isRequired,
    currentRoute: PropTypes.string.isRequired,
    unavailableMessage: PropTypes.string
};

export default memo(Route);
