import { ThemeContext } from 'styled-components';
import { useContext } from 'react';

export default function useHiddenForViewPort(hNode) {
    const { viewPort } = useContext(ThemeContext);
    let allowedViewport = hNode?.showForViewport || hNode?.showForViewPort;
    let hiddenForViewport = false;
    if (viewPort && allowedViewport && allowedViewport.length && !allowedViewport.includes(viewPort)) {
        hiddenForViewport = true;
    }
    return hiddenForViewport;
}
