export default {
    getFilter,
    getUriComponent,
    fromHNode
};

function fromHNode({ appendResults }) {
    return getFilter(appendResults);
}

function getFilter(appendResults = true) {
    return appendResults;
}

// Meaningless for this filter.
function getUriComponent() {
    return '';
}
