/*
This is only used if there is a non-application logic error
(for instance a server 500).
If the web UI is offline, it should not go here.
The idea is to prevent us from DDOSing ourselves if we have a major
server side outage.
The clients won't all try to hit us at once when we come back up.
*/

const decaySchedule = [
    1000, // After 1 seconds
    1000 * 5, // After 5 seconds
    1000 * 15, // After 15 seconds
    1000 * 30, // After 30 seconds
    1000 * 60, // After 1 minute
    1000 * 60 * 3, // After 3 minutes
    1000 * 60 * 5, // After 5 minutes
    1000 * 60 * 10, // After 10 minutes
    1000 * 60 * 30, // After 30 minutes
    1000 * 60 * 60 // After 1 hour
];

export default (action, retries) => decaySchedule[Math.min(retries, 9)];
