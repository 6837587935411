import { createElement as rc, useMemo, useRef, useCallback, useState, useEffect } from 'react';
import { View, Text, styled, fromTheme, webOnlyStyles, Button } from 'lib_ui-primitives';
import useFormControl from '../../../hooks/useFormControl';
import useStepSize from './useStepSize';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import useEventSink from '../../../hooks/useEventSink';
import logging from '@sstdev/lib_logging';
import { globalConfig, constants } from 'lib_ui-services';
import ShortEncodedText from '../../formElement/ShortEncodedText';
import PropTypes from 'prop-types';

let Container = styled(View).attrs({ name: 'geiger-counter-container' })`
    flex-grow: 1;
    flex-direction: column;
    padding-left: 6px;
    padding-right: 6px;
`;
Container.displayName = 'GeigerCounterContainer';

const TagField = styled(ShortEncodedText)`
    z-index: ${({ theme }) => theme.zindex.GeigerCounterTagField};
`;

let GeigerContainer = styled(View).attrs({ name: 'geiger-counter' })`
    flex-grow: 1;
    flex-shrink: 1;
    margin-top: ${({ numArcs, arcWidth, extraOffset }) => -numArcs * arcWidth + extraOffset}px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: ${({ theme }) => theme.zindex.GeigerCounterContainer};
`;
GeigerContainer.displayName = 'GeigerCounter';

export const ButtonBar = styled(View)`
    flex-grow: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

//${props => fromTheme('button', props.enabled ? 'primary' : 'grayHover')(props)};
// an arc is created by showing the top and left using margin, then rounding that top left corner
let Arc = styled(View)`
    display: flex;
    flex-grow: 1;
    background-color: ${props => {
        const inRangeColorEven = fromTheme('button', 'primaryHighlight')(props);
        const inRangeColorOdd = fromTheme('button', 'primary')(props);
        const outOfRangeColorEven = props.theme.darkMode ? '#000000' : '#ffffff';
        const outOfRangeColorOdd = props.theme.darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)';
        if (props.odd) {
            return props.enabled ? inRangeColorOdd : outOfRangeColorOdd;
        } else {
            return props.enabled ? inRangeColorEven : outOfRangeColorEven;
        }
    }};
    padding: 0;
    margin-bottom: 0;
    margin-right: 0;
    margin-top: ${({ arcWidth }) => arcWidth}px;
    margin-left: ${({ arcWidth }) => arcWidth}px;
    border-top-left-radius: ${({ radius }) => radius}px;
    /* can't have a border width, and we need overflow:visible due to RN bug: https://github.com/facebook/react-native/issues/18266 */
    border-width: 0;
    overflow: visible;
`;
Arc.displayName = 'Arc';

Arc = webOnlyStyles(Arc)`
    border-style: solid;
`;

// if we rotate the outermost arc, all those inside will be rotated as well
// By fixing the size of the outermost arc, and using flex-grow, and margin
// we can control the size of all those contained.
// We can't use percentage, as percentage for margin is calculated as:
// "percentage of the containing element" which would get confusing, as to have the same thickness everywhere
// we'd need a larger and larger percentage for the margin on each consecutive child
const TopArc = styled(Arc)`
    width: ${({ radius }) => radius}px;
    height: ${({ radius }) => radius}px;
    margin: 0;
    transform: rotate(45deg);
    flex-grow: 0;
    flex-shrink: 0;
`;

const Status = styled(Text)``;

const _p = {
    useFormControl,
    useWindowDimensions
};
export const _private = _p;
const EMPTY_HNODE = {};
export default function GeigerCounter(props) {
    const inputDomElement = useRef(null);
    const {
        hNode = EMPTY_HNODE,
        hNode: {
            id,
            title,
            numBars = 10,
            size: overrideSize,
            sensorType = constants.sensorTypes.ANY,
            treePosition
        } = {}
    } = props || {
        hNode: { treePosition: {} }
    };
    const { value: itemTagId } = _p.useFormControl({ ...props, hNode: { ...hNode, propertyName: 'tagId' } }, '');
    const [tagId, setTagId] = useState(itemTagId);
    // const [value, setValue] = useState('');
    // const [errors, setErrors] = useState();
    const [proximity, setProximity] = useState(-1);
    const [showGeiger, setShowGeiger] = useState(false);
    // const [active, setActive] = useState(false);
    const [subscribe, publish] = useEventSink();

    // Startup the sensor service when this is mounted.
    useEffect(() => {
        logging.debug('[GEIGER] Requesting RFID Service');
        // Start the service.  If the service is already started, this is a noop.
        publish(
            {
                sensorType,
                scanType: 'Locate',
                intervalMilliseconds: globalConfig().sensorScanIntervalMilliseconds
            },
            { verb: 'startup', namespace: 'sensor', relation: 'service' }
        );

        // Do not stop the service, it is too slow/expensive and seems to cause crashes
        //but do make sure the scanning is stopped.
        return () => {
            publish({ sensorType }, { verb: 'stop', namespace: 'sensor', relation: 'locate' });
        };
        // This should only ever run when this mounts
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const unsubscribes = [
            subscribe({ verb: 'change', namespace: 'sensor', relation: 'locate' }, read => {
                if (tagId && tagId === read.tagId) {
                    logging.debug(`[GEIGER] ${tagId}: ${read.relativeDistance}%`);
                    setProximity(read.relativeDistance);
                }
            }),
            subscribe({ verb: 'startup', namespace: 'sensor', relation: 'read', status: 'success' }, () => {
                logging.debug('[GEIGER] RFID Engine Started');
            }),
            subscribe({ verb: 'startup', namespace: 'sensor', relation: 'locate', status: 'failure' }, result => {
                logging.debug('[GEIGER] Failed to start', result.errors.form[0]);
                setProximity(-1);
                inputDomElement.current?.focus();
            }),
            subscribe({ verb: 'stop', namespace: 'sensor', relation: 'read', status: 'success' }, () => {
                logging.debug('[GEIGER] RFID Engine STOPPED');
                setProximity(-1);
                inputDomElement.current?.focus();
            })
        ];
        return () => unsubscribes.forEach(unsubscribe => unsubscribe());
    }, [subscribe, tagId]);

    const startLocating = useCallback(() => {
        if (!tagId) return;
        logging.debug('[GEIGER] Requesting RFID Engine Start Up');
        inputDomElement.current?.blur();
        setProximity(-1);
        publish({ sensorType, tagId }, { verb: 'startup', namespace: 'sensor', relation: 'locate' });
        setShowGeiger(true);
    }, [publish, sensorType, tagId]);

    const stopLocating = useCallback(() => {
        logging.debug('[GEIGER] Requesting RFID Engine Stop');
        publish({ sensorType }, { verb: 'stop', namespace: 'sensor', relation: 'locate' });
        setShowGeiger(false);
        inputDomElement.current?.focus();
    }, [publish, sensorType]);

    // Any cancel should stop the geiger counter.
    useEffect(() => subscribe({ verb: 'cancel' }, () => stopLocating), [subscribe, stopLocating]);

    // we can get away with using the full size, unless explicitly specified, as most of the time it will be shown by itself on a handheld
    const { height, width } = _p.useWindowDimensions();
    // allow explicitly setting size for use e.g. in-grid for picklist. Although that will still need some alternative data source for value.
    const minSize = overrideSize || Math.min(width, height);
    // we need the width of the outer square, which is rotated 45°, to match the screen width
    // ratio of 45°-45°-90° triangle sides: 1:1:√2
    const rotatedWidth = minSize / Math.sqrt(2);
    //numArcs is double the number of bars, as we have an arc for both the bars, as well as for the empty space in between
    const numArcs = 2 * numBars;
    //we want to leave the appearance of 2 arcs (1 bar) width worth of space around it
    const radius = numArcs * (rotatedWidth / (numArcs + 2));
    //now each arc's width:
    const arcWidth = radius / numArcs;

    const steps = useStepSize(numArcs);

    const arcs = useMemo(
        () =>
            steps.reduce((children, step, index) => {
                if (index === steps.length - 1) {
                    return rc(
                        TopArc,
                        {
                            name: `arc${index}-${step}`,
                            'data-testid': `arc${index}-${step}`,
                            enabled: Number(proximity) >= step,
                            // As we double numBars to get `numArcs`, that will always be even.
                            // As we use a 0-based index, that means this is always odd
                            odd: index % 2 === 1, //true
                            radius,
                            arcWidth
                        },
                        children
                    );
                }
                return rc(
                    Arc,
                    {
                        name: `arc${index}-${step}`,
                        'data-testid': `arc${index}-${step}`,
                        enabled: Number(proximity) >= step,
                        odd: index % 2 === 1,
                        last: index === 0,
                        radius,
                        arcWidth
                    },
                    children
                );
            }, null),
        [steps, radius, arcWidth, proximity]
    );

    const extraOffset = itemTagId ? 100 : 0;
    // prettier-ignore
    return rc(Container, null,
        !itemTagId && rc(TagField, {
            hNode: {
                id,
                title: title || 'Tag ID',
                propertyName: 'tagId',
                hNodeType: 'ShortEncodedText',
                hNodeTypeGroup: 'formElement',
                treePosition
            },
            name: 'tagId',
            autoComplete: 'off',
            value: tagId,
            onChange: setTagId,
            onBlur: () => setShowGeiger(true),
            onFocus: () => setShowGeiger(false),
            readOnly: showGeiger,
        }),

        showGeiger && rc(GeigerContainer, { 'data-testid': 'geiger-counter' + id, arcWidth, numArcs, extraOffset }, arcs),
        rc(ButtonBar, null,
            showGeiger && proximity <= 0 && rc(Status, null, 'Searching'),
            showGeiger && rc(Button, { id: `stop${id}`, value: 'STOP', buttonStyle: 'primary', onClick: stopLocating }),
            !showGeiger && rc(Button, { id: `start${id}`, value: 'LOCATE', buttonStyle: 'Positive', disabled: tagId === '', onClick: startLocating })
        ),

    );
}
GeigerCounter.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};
