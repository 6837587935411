import Split from 'react-split';
import { styled, webOnlyStyles, fromTheme } from 'lib_ui-primitives';

// TODO:  This Split is not going to work in React Native
// There is also not a good replacement.  We aren't
// really using RN for tablet stuff though, so maybe not
// a big deal.
let StyledSummaryDetail = styled(Split).attrs({ name: 'StyledSummaryDetail' })`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    overflow: hidden;

    .gutter.gutter-vertical {
        cursor: ns-resize;
        :after {
            content: '${'\u205E'}'; // four vertical dots
            transform: rotate(90deg);
        }
    }

    .gutter.gutter-horizontal {
        cursor: ew-resize;
        :after {
            content: '${'\u205E'}'; // four vertical dots
        }
    }

    .gutter {
        background-color: ${fromTheme('outerMenus', 'backgroundColor')};
        border: none;
        display: ${({ collapsed }) => (collapsed == null ? 'flex' : 'none')};
        align-items: center;
        justify-content: center;
        color: ${fromTheme('outerMenus', 'fontColor')};
        font-size: 40px;
        font-weight: bold;
    }
    border: none;
`;

StyledSummaryDetail = webOnlyStyles(StyledSummaryDetail)`
    .gutter {
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    }
    div[name="FullWidth"]{
        ${({ theme }) => !theme.mobile && 'min-width: 340px;'}
    }
`;

export default StyledSummaryDetail;
