export default {
    getFilter,
    getUriComponent,
    fromHNode,
    pageResetRequired: true,
    getDbIndex
};

function fromHNode({ propertiesToSearch, fullTextSearch }) {
    return getFilter(propertiesToSearch, fullTextSearch);
}

function getFilter(propertiesToSearch, fullTextSearch = false) {
    return {
        propertiesToSearch,
        fullTextSearch
    };
}

function getUriComponent(filters) {
    const filter = filters['propertiesToSearch'];
    let { propertiesToSearch } = filter;
    propertiesToSearch = `["${propertiesToSearch.join('","')}"]`;
    return `searchProperties=${encodeURIComponent(propertiesToSearch)}`;
}

function getDbIndex(filters) {
    const propertiesToSearch = filters['propertiesToSearch']
        ? filters['propertiesToSearch'].propertiesToSearch
        : ['title'];
    if (!propertiesToSearch || propertiesToSearch.length === 0) return [];
    return propertiesToSearch.map(p => {
        return {
            fields: [p],
            name: p
        };
    });
}
