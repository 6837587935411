import { createElement as rc, useMemo, useState, useRef } from 'react';
import AnimatingContext from '../../../contexts/AnimatingContext';

/**
 * This is used to keep track of animations that may need priority
 * over other rendering operations.
 * Right now, it is only used by react-native to track the sidenav drawer
 * animation because it was getting lots of jank from the database
 * filter operations and list rendering taking CPU.
 * A corresponding useIsAnimating will allow consumers to perform
 * expensive operations only while the useIsAnimating hook returns
 * false.
 * It uses a counter which is not ideal in my opinion. If an animation
 * fails to report it is finished, this could hang the portion of the
 * UI or app listening for the animation to complete.
 * Unfortunately, the spring animations don't seem to have a failure
 * event to capture this scenario.  A future improvement might be to
 * add a timeout for the animation which reports the timeout and sets
 * the counter to zero and isAnimating to false.
 */
export default function AnimatingBoundary(props) {
    const { children } = props || {};
    const [isAnimating, _setIsAnimating] = useState(false);
    const animationCount = useRef(0);
    const context = useMemo(() => {
        return {
            isAnimating,
            setIsAnimating: shouldAdd => {
                if (shouldAdd) {
                    animationCount.current++;
                } else {
                    animationCount.current--;
                }
                _setIsAnimating(animationCount.current !== 0);
            }
        };
    }, [isAnimating]);
    return rc(AnimatingContext.Provider, { value: context }, children);
}
