export default database => () => {
    Object.keys(database._relationDb).map(namespaceTitle => {
        Object.keys(database._relationDb[namespaceTitle]).map(relationTitle => {
            // The data is no longer there, so set the sync values appropriately
            database.setStorageState(namespaceTitle, relationTitle, 'lastSyncTime', undefined);
            database.setStorageState(namespaceTitle, relationTitle, 'initialSyncFinished', false);
            database.setStorageState(namespaceTitle, relationTitle, 'latestModifiedTime', undefined);
            database.setStorageState(namespaceTitle, relationTitle, 'earliestModifiedTime', undefined);
        });
    });
    return database.abstractDbRoot.deleteDatabase();
};
