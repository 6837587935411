import { getRelationMetadata } from '../../metadata/index';
import verifyMetaKeysPresent from './_verifyMetaKeysPresent';

/**
 * Typical remove/delete CRUD
 * @param {object} action persist action dispatched to remove a document
 */
export default database => async (payload, context) => {
    const { namespace, relation, correlationId } = context;
    const { trueDelete = false, id, meta } = payload;

    const relationMetadata = getRelationMetadata(namespace, relation, false);
    const storesPatchHistory = 'patchOffline' === relationMetadata?.patchRelationType;
    if (trueDelete) {
        await database.relationDb(namespace, relation).remove(id);
    } else {
        verifyMetaKeysPresent(['deleted', 'deletedBy', 'deletedTime'], meta, {
            operation: 'remove',
            namespace,
            relation
        });

        await database.relationDb(namespace, relation).update({ _id: id, meta });
        if (storesPatchHistory) {
            await insertToPatchCollection();
        }
    }
    return database.processSuccess([], payload, context);

    async function insertToPatchCollection() {
        const patchDoc = {
            _id: correlationId,
            [`${namespace}:${relation}`]: { _id: id },
            patches: [{ op: 'remove', path: '/' }],
            meta: {
                modifiedTime: meta.deletedTime,
                modifiedBy: meta.deletedBy
            }
        };
        await database.relationDb(namespace, relation + '-patch').insert(patchDoc);
    }
};
