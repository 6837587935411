import groupByHelpers from './groupByHelpers';

export default {
    getFilter,
    getUriComponent,
    fromHNode,
    pageResetRequired: true,
    getAggregateReducer
};
function fromHNode(props) {
    const { propertyName, namespace, relation, originalRelation, trueLabel, falseLabel } = props;
    return getFilter(propertyName, namespace, originalRelation || relation, trueLabel, falseLabel);
}

function getFilter(propertyName = '', optionalNamespace, optionalRelation, trueLabel = 'Yes', falseLabel = 'No') {
    return {
        propertyName,
        optionalNamespace,
        optionalRelation,
        trueLabel,
        falseLabel
    };
}

function getUriComponent(filters) {
    const filter = filters['groupByBoolean'];
    let { propertyName, optionalNamespace, optionalRelation } = filter;
    if (optionalNamespace && optionalRelation) {
        propertyName = `${optionalNamespace}:${optionalRelation}.${propertyName}`;
    }
    return `groupBy=${encodeURIComponent(propertyName)}`;
}

function getAggregateReducer(filters, itemCount, accumulator = defaultAccumulator) {
    if (!filters.groupByBoolean) {
        return x => x;
    }
    //we need to group by the groupBy specs, and return a value per each
    const {
        optionalNamespace,
        optionalRelation,
        propertyName,
        trueLabel = 'Yes',
        falseLabel = 'No'
    } = filters.groupByBoolean;
    let key = propertyName.split('.');
    if (optionalNamespace && optionalRelation) {
        key = [`${optionalNamespace}:${optionalRelation}`, ...propertyName.split('.')];
    }

    return array =>
        Object.values(
            array.reduce(
                (aggregate, record) => {
                    if (!record) return aggregate;
                    // Apply search criteria to the record before grouping
                    if (groupByHelpers.hasSearchCriteria(filters)) {
                        record = groupByHelpers.applySearchCriteria(record, filters);
                    }
                    let value = groupByHelpers.getValue(record, key, undefined, trueLabel, falseLabel);
                    if (Array.isArray(value)) {
                        return value.reduce((resultSet, value) => accumulator(resultSet, value, record), aggregate);
                    }
                    return accumulator(aggregate, value, record);
                },
                //make sure that both labels show up in the legend.
                {
                    [trueLabel]: { _id: { title: trueLabel }, items: [], count: 0 },
                    [falseLabel]: { _id: { title: falseLabel }, items: [], count: 0 }
                }
            )
        );
}

function defaultAccumulator(resultSet, value, record) {
    resultSet[value] = resultSet[value] || { _id: { title: value }, items: [], count: 0 };
    resultSet[value].items.push(record);
    resultSet[value].count++;
    return resultSet;
}
