import { COMMON_COLOR_SCHEME, errors as _errors } from 'lib_ui-primitives';
import { network } from 'lib_ui-services';

const _p = { getNetworkStatus: network.getStatus };
export const _private = _p;

const ACTIVE_STATUS = 'Active';
const CLOSED_STATUS = 'Closed';

export default {
    verb: 'doingValidate',
    namespace: 'inventory',
    relation: 'inventory',
    description: 'Warn user when closing / re-opening inventory ',
    // type: '',
    priority: 0,
    // useCaseIds:[]
    // this is the actual logic:
    logic: doingValidate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingValidate({ data, dispatch }) {
    /**
     * After discussion during the 2024-01-08 Standup, it was decided that offline inventory creation or modification should be disallowed.
     * @see https://sstdev.visualstudio.com/Backbone/_workitems/edit/6454
     *
     * If the offline user creates an inventory that already exists, the server will merge it when the user goes online.
     * The previously offline user will still be using their local copy of the inventory, which will be out of sync
     * with the server. If that user tries to go to the "Take" page for that inventory, They will get an error message
     * saying that they need to open an inventory first. The only way to fix this for the user is to clear their local storage.
     * Adding this check prevents the user from both creating and modifying an inventory while offline.
     */
    const networkStatus = await _p.getNetworkStatus();
    if (!networkStatus.isOnline) {
        const message = 'Failed to communicate with the server. Unable to modify the inventory while offline.';
        throw new _errors.ValidationError(message, {});
    }

    const { newRecord, oldRecord } = data;
    const oldStatus = oldRecord?.['inventory:status']?.title;
    const newStatus = newRecord?.['inventory:status']?.title;

    if (oldStatus === ACTIVE_STATUS && newStatus === CLOSED_STATUS) {
        const response = await asyncConfirmationModal(dispatch);
        if (!response) {
            throw new Error('User cancelled closing of inventory.');
        }
    } else if (oldStatus === CLOSED_STATUS && newStatus === ACTIVE_STATUS) {
        const errors = {
            form: ['A closed inventory cannot be opened again.  Instead, cancel and create a new inventory.'],
            fields: {
                'inventory:status': 'Unable to re-open a closed inventory'
            }
        };
        throw new _errors.ValidationError(errors.form[0], errors);
    }
}

async function asyncConfirmationModal(dispatch) {
    return new Promise(resolve => {
        dispatch(
            {
                message: [
                    'You are about to close the inventory.',
                    'A closed inventory cannot be re-opened.',
                    'Are you sure you wish to continue?'
                ],
                okButtonText: 'YES',
                cancelButtonText: 'NO',
                icon: 'warning',
                iconColor: COMMON_COLOR_SCHEME.warn,
                okAction: () => resolve(true),
                cancelAction: () => resolve(false)
            },
            { verb: 'confirm', namespace: 'application', relation: 'user' }
        );
    });
}
