import react from 'react';
import { ActiveRecordContext } from '../components/contextProviders/ActiveRecord';
const { useContext } = react;

/**
 * @returns  {{
 *   namespace: string,
 *   relation: string,
 *   type: string,
 *   isNew: boolean,
 *   record?: object,
 *   activationVerb: string
 * }}}
 */
export default function useActiveRecord() {
    return useContext(ActiveRecordContext);
}
