import { createElement as rc, forwardRef, useMemo } from 'react';
import { useTheme } from 'styled-components';
import {
    styled,
    View,
    ValidationError,
    fromTheme,
    Text,
    webOnlyStyles,
    nativeOnlyStyles,
    PlainLabel
} from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';
import BarcodeScanner from '../actionElement/BarcodeScanner';
import SensorReadButton from '../actionElement/SensorReadButton';
import ReadProvider from '../contextProviders/ReadProvider';
import PowerSliderPopup from '../actionElement/PowerSliderPopup';
import useReadButtonSelection from '../../hooks/useReadButtonSelection';

const RfidButton = styled(SensorReadButton).attrs({
    name: 'rfid-button',
    alt: 'Scan for RFID',
    color: 'transparent'
})``;
RfidButton.displayName = 'RfidButton';

let Container = styled(View).attrs({ name: 'form-field' })`
    max-width: ${fromTheme('form', 'entryMaxWidth')};
    min-width: ${fromTheme('form', 'entryMinWidth')};
    flex-grow: 1;
    margin-top: ${fromTheme('textMargin')};
    margin-bottom: ${fromTheme('textMargin')};
    margin-right: ${({ theme }) => theme.textMargin * 2 + 'px'};
    color: ${fromTheme('defaultFontColor')};
    font-family: ${fromTheme('font')};
    font-size: ${fromTheme('fontSize')};
`;
Container = webOnlyStyles(Container)`
    display: block;
    overflow: visible;
`;
Container = nativeOnlyStyles(Container)`
    display: flex;
    flex-direction: column;
`;
Container.displayName = 'Container';

const ScanLabelAndField = styled(View).attrs({ name: 'scan-label-and-field' })`
    flex-direction: row;
    overflow: visible;
`;
ScanLabelAndField.displayName = 'ScanLabelAndField';

let LabelAndField = styled(View).attrs({ name: 'label-and-field' })`
    position: relative;
    font-size: 16px;
    margin: 0;
    padding: 14px 0 4px;
`;
LabelAndField = webOnlyStyles(LabelAndField)`
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    overflow: visible;
`;
LabelAndField.displayName = 'LabelAndField';

let Field = styled(View).attrs({ name: 'field-container' })`
    display: flex;
    align-items: center;
    flex-grow: 1;
`;
Field = nativeOnlyStyles(Field)`
    max-height: 40px;
`;
Field.displayName = 'Field';

// TODO: It would be better to calculate the label top below based on font size
// textMargin and borders.  If theme values for those change, this will likely be messed up.
let Label = styled(PlainLabel)`
    bottom: 0;
    left: ${({ floating }) => (floating ? '0' : '4px')};
    right: 0;
    top: ${({ floating }) => (floating ? '4px' : '24px')};
    font-size: ${({ floating, theme }) => (floating ? theme.fontSizeLabel + 'px' : theme.fontSize + 'px')};
    position: absolute;
    width: 100%;
    overflow: hidden;
    text-align: left;
    color: ${({ active, floating, theme, disabled, empty }) =>
        active
            ? theme.colorScheme.primary
            : floating
            ? theme.disabledLabelColor
            : disabled
            ? theme.disabledLabelColor
            : empty
            ? theme.labelForEmptyField
            : theme.labelColor};
`;
Label = webOnlyStyles(Label)`
    display: block;
    white-space: nowrap;
    pointer-events: none;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
`;

let Tooltip = styled(Text).attrs({ children: 'i' })`
    background-color: ${fromTheme('colorScheme', 'primary')};
    visibility: ${props => (props.theme.mobile ? 'hidden' : 'visible')};
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    font-size: 12px;
    padding: 2px 10px 2px 7px;
    color: white;
    width: 13px;
    text-align: center;
    font-weight: bold;
`;
Tooltip = webOnlyStyles(Tooltip)`
    cursor: help;
`;
Tooltip.displayName = 'Tooltip';

const _p = {
    RfidButton
};
const EMPTY_HNODE = {};
export default forwardRef(function FormField(props, ref) {
    const { native } = useTheme();
    const {
        hNode = EMPTY_HNODE,
        title,
        testID,
        children,
        active,
        errors,
        fieldEmpty,
        labelProps,
        className,
        disabled,
        style,
        onBlur,
        displayScanButton: _displayScanButton,
        displayRfidPowerButton: _displayRfidPowerButton,
        scanRfid: _scanRfid,
        scanBarcode: _scanBarcode,
        id: _id,
        tooltip: _tooltip,
        currentRoute
    } = props || { hNode: {} };

    // Allow these values to be passed in via hNode or individually.
    const modifiedHNode = {
        displayScanButton: _displayScanButton ?? hNode.displayScanButton ?? false,
        displayRfidPowerButton: _displayRfidPowerButton ?? hNode.displayRfidPowerButton ?? false,
        scanRfid: _scanRfid ?? hNode.scanRfid ?? false,
        scanBarcode: _scanBarcode ?? hNode.scanBarcode ?? false
    };

    // Calculate if we should display these buttons
    const { displayRfidButton, displayBarcodeButton, displayRfidPowerButton } = useReadButtonSelection(modifiedHNode);

    let id = _id ?? hNode.id;
    let tooltip = _tooltip ?? hNode.tooltip;
    const desiredSensorTypes = useMemo(() => {
        const _sensorTypes = [];
        if (modifiedHNode.scanRfid) {
            _sensorTypes.push(constants.sensorTypes.RFID);
        }
        if (modifiedHNode.scanBarcode) {
            _sensorTypes.push(constants.sensorTypes.BARCODE);
        }
        return _sensorTypes;
    }, [modifiedHNode.scanRfid, modifiedHNode.scanBarcode]);

    // prettier-ignore
    return rc(ReadProvider, { id: `${title}${id}`, desiredSensorTypes, active },
        rc(Container, { className, ref, onBlur, style },
            rc(ScanLabelAndField, null,
                displayRfidButton && rc(_p.RfidButton, {
                    ref,
                    id: 'rfidScanner' + id,
                    key: 'rfidScanner' + id,
                    'data-testid': 'rfidScannerButton' + id,
                    disabled,
                    tabIndex: -1
                }),
                displayRfidPowerButton && rc(PowerSliderPopup, {id, sensorTypesAvailable: desiredSensorTypes, currentRoute}),
                displayBarcodeButton && rc(BarcodeScanner, {
                    ref,
                    id: 'scanner' + id,
                    key: 'scanner' + id,
                    tabIndex: -1
                }),
                rc(LabelAndField, null,
                    !native && tooltip && rc(Tooltip, { title: tooltip }),
                    rc(Label, {
                        htmlFor: id,
                        floating: active || !fieldEmpty,
                        empty: fieldEmpty,
                        active,
                        disabled,
                        ...labelProps,
                        testID: `fieldLabel${testID ?? id}`,
                        // Needed for Appium testing
                        accessibility: true,
                        accessibilityLabel: `fieldLabel${testID ?? id}`
                    }, title ?? ''),
                    rc(Field, null, children)
                ),
            ),
            rc(ValidationError, { errors })
        )
    );
});
