import react from 'react';
import PropTypes from 'prop-types';
import { PreviewLine, PreviewText } from '../styles';

const rc = react.createElement;

export default function FixedTextData(props) {
    const {
        hNode: { fixedText = '', isError }
    } = props;
    return rc(PreviewLine, null, rc(PreviewText, { isError }, fixedText));
}

FixedTextData.propTypes = {
    hNode: PropTypes.shape({
        fixedText: PropTypes.string,
        isError: PropTypes.bool
    })
};
