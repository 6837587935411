import styled from '../styled';
import fromTheme from '../fromTheme';
import { createElement as rc } from 'react';
import customIcons from './customIcons';

// By default, all icons come from the google icon font https://fonts.google.com/icons (linked in createGlobalStyle.browser.js)
// Additional icons can be pulled from @mdi/js https://materialdesignicons.com/ (https://pictogrammers.com/library/mdi/)
// @mdi/js is the material design icon community vector file -- it is VERY LARGE.
// See https://dev.materialdesignicons.com/guide/webfont-alternatives#other
const mdiIcons = customIcons;

const MaterialIcon = styled('i').attrs(props => {
    const iconParts = props.children?.split('|') ?? [];
    const transform = iconParts?.[1] ?? 'unset';
    const className = props.theme.iconFont + (props.iconVariation ? '-' + props.iconVariation : '');
    return { className, transform, children: iconParts[0] };
})`
    font-size: ${props => props.iconSize ?? fromTheme('iconSize')(props)} !important;
    color: ${({ color, theme, disabled, fontColor }) => {
        if (disabled) return theme.disabledFontColor;
        if (fontColor) return fontColor;
        if (['base', 'transparent'].includes(color)) return theme.defaultFontColor;
        return theme.button.fontColor;
    }};
    transform: ${props => props.transform};
    max-width: ${props => props.iconSize ?? fromTheme('iconSize')};
    user-select: none;
`;
MaterialIcon.displayName = 'MaterialIcon';

const MaterialCommunityIcon = styled('svg')`
    vertical-align: -0.125em;
    font-size: ${fromTheme('iconSize')};
    color: ${({ color, theme, disabled, fontColor }) => {
        if (disabled) return theme.disabledFontColor;
        if (fontColor) return fontColor;
        if (['base', 'transparent'].includes(color)) return theme.defaultFontColor;
        return theme.button.fontColor;
    }};
    transform: ${props => props.transform};
    max-width: ${fromTheme('iconSize')};
    user-select: none;
`;
MaterialCommunityIcon.displayName = 'MaterialCommunityIcon';

export default function Icon(props) {
    const iconParts = props.children?.split('|') ?? [];
    const name = iconParts[0].replace(/_/, '-');
    const transform = iconParts?.[1];
    if (Object.keys(mdiIcons).includes(name)) {
        // prettier-ignore
        return rc(MaterialCommunityIcon, {
                title: name,
                disabled: props.disabled,
                preserveAspectRatio: 'xMidYMid meet',
                viewBox: '0 0 24 24',
                color: 'transparent',
                width: '1em',
                height: '1em',
                strokeWidth: '1',
                transform
            },
            rc('path', { d: mdiIcons[name], fill: (props.fontColor || 'currentColor') })
        );
    } else {
        return rc(MaterialIcon, props);
    }
}
