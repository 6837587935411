const verbs = ['create', 'update', 'remove'];
export default eventSink => store => next => action => {
    // avoid crashing on Redux init actions
    if (!store || !next) {
        return null;
    }

    if (action && action.type) {
        const [, namespace, relation, result] = action.type.split('_');

        if (!result || !['commit', 'rollback'].includes(result.toLowerCase())) return next(action);
        let { originalMessage, message, stack, status } = action.payload || {};

        //Added this check for #5486. Without it, the response from POSTs was not available to didCommit rules.
        if (!message) {
            message = action.payload;
        }
        const [, publish] = eventSink;
        // we get either a remove or submit verb on the `type`.
        // just always ignore that and figure out which one by using a verbs map
        // for easier code.
        verbs.forEach(verb => {
            if (!action[verb]) return;
            // publish commit or rollback to the normal event sink.
            publish(
                { ...action[verb], originalMessage, message, stack, status },
                { verb: result.toLowerCase(), namespace, relation, type: verb }
            );
        });
        return next(action);
    }
    next();
};
