import { useContext, useEffect } from 'react';
import UiNotificationContext from '../contexts/UiNotificationContext';
import { constants } from 'lib_ui-services';

// audiocontext cannot be started until after a user gesture on the page.
let init = false;
let audioCtx;
let oscillator;
let gainNode;
let playing = false;
let queue = [];

export default function Beep() {
    const notificationContext = useContext(UiNotificationContext);
    useEffect(() => {
        notificationContext.addTypeHandler(constants.notificationTypes.SOUND, payload => {
            const { isError } = payload;
            // TODO: Use switch or similar to decide type of beep to play.
            if (isError) {
                play({ frequency: 440, ...payload, type: payload.beepType || 'sawtooth' });
            } else {
                play({ ...payload, type: payload.beepType || 'sawtooth' });
            }
        });
    }, [notificationContext]);

    // prettier-ignore
    return null;
}

// Web has audio api so we don't need to store or serve sound files
function play(payload, fromQueue = false) {
    try {
        if (playing || (!fromQueue && queue.length > 0)) {
            enqueue(payload);
            return;
        }
        playing = true;
        // audiocontext cannot be started until after a user gesture on the page.
        if (!init) {
            audioCtx = new (window.AudioContext || window.webkitAudioContext)();
            gainNode = audioCtx.createGain();
            gainNode.connect(audioCtx.destination);
            init = true;
        }
        playBeep(payload, () => {
            playing = false;
            dequeue();
        });
    } catch (err) {
        queue = [];
        playing = false;
    }
}

function playBeep(options, onEnd) {
    try {
        const { times = 1, volume = 0.1, frequency = 3020, type = 'sawtooth', duration = 80 } = options;
        // Oscillator can only be used once.
        oscillator = audioCtx.createOscillator();
        oscillator.connect(gainNode);
        gainNode.gain.value = volume; // Volume: 0-1
        oscillator.frequency.value = frequency; // Hz
        oscillator.type = type; // 'sine', 'square', 'triangle', 'sawtooth'
        oscillator.start();

        setTimeout(function () {
            oscillator.stop();
            oscillator.disconnect();
            oscillator = undefined;
            if (times > 1) {
                setTimeout(() => {
                    playBeep({ ...options, times: times - 1 }, onEnd);
                }, 100);
            } else {
                onEnd();
            }
        }, duration);
    } catch (error) {
        if (oscillator != null) {
            oscillator.stop(); // noop if stopped
            oscillator.disconnect();
            oscillator = undefined;
        }
        //rethrow the error so `play` can reset what it needds.
        throw error;
    }
}

function enqueue(payload) {
    queue.push(payload);
}
function dequeue() {
    setTimeout(() => {
        const toRun = queue.shift();
        if (toRun == null) return;
        play(toRun, true);
        dequeue();
    }, 300);
}
