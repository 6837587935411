const notHandledHere = [
    { namespace: 'security', relation: 'profile' },
    { namespace: 'import', relation: 'import' },
    { namespace: 'application' },
    { namespace: 'sensor' },
    { namespace: 'file' },
    { namespace: 'metaui', relation: 'validations' },
    { namespace: 'metaui', relation: 'dataDictionary' },
    // Excludes Havens, but not regular environments
    { namespace: 'deploy', relation: 'environment', type: 'singleRecordForm' },
    { namespace: 'identity', relation: 'userRole' }
];
export default notHandledHere;
