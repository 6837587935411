/**
 * If a Button is inside a menu override it to provide a proper menu
 */
import { createElement as rc } from 'react';
import { MenuItem as _MenuItem, Text } from 'lib_ui-primitives';
import useEventSink from '../../hooks/useEventSink';

function MenuItem(props) {
    const {
        hNode: { forAction, title, disabled, namespace, relation, type },
        actionPayload = {},
        ...otherProps
    } = props;
    const [, publish] = useEventSink();
    function onClick() {
        publish(actionPayload, { verb: forAction, namespace, relation, type });
    }
    return rc(_MenuItem, { onClick, disabled, ...otherProps }, rc(Text, null, title));
}

export default MenuItem;
