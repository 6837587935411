import { constants } from 'lib_ui-services';
const { useCaseIds } = constants;

export default {
    verb: 'didEdit',
    namespace: 'deploy',
    relation: 'deployment',
    description: 'Set a valid deployment record to dirty so that the user can submit it.',
    useCaseIds: [useCaseIds.ONE_TOUCH],
    //this is the actual logic:
    logic: didEdit
};
function didEdit({ data, dispatch, context }) {
    if (['Ready For Confirmation'].includes(data.record.status)) {
        dispatch(
            { propertyName: 'meta.overriddenToDirty', newValue: true },
            { ...context, verb: 'beginChange' },
            { waitForSubscriber: true }
        );
    }
}
