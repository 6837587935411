import { ObjectId } from 'lib_ui-services';
export default {
    verb: 'didNew',
    namespace: 'transaction',
    description: 'Prepopulate new transaction relations.',
    //this is the actual logic:
    logic: didNew
};
function didNew({ data }) {
    const { record } = data;
    const _id = record?._id || new ObjectId().toString();
    const title = record?.title || asTransactionCode(_id);
    return {
        ...data,
        record: {
            ...record,
            _id,
            title
        }
    };
}

/**
 * turn `123456789012345678901234`
 * into `12345678-9012345678-901234`
 * so it looks a bit less daunting
 * @param {string} value
 * @returns
 */
function asTransactionCode(value) {
    if (!value || value.length !== 24) return value;
    return `${value.slice(0, 8)}-${value.slice(8, -6)}-${value.slice(-6)}`;
}
