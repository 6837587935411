import { createElement as rc, Fragment, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import _ReactDatePicker from 'react-datepicker';
const ReactDatePicker = _ReactDatePicker.default ?? _ReactDatePicker;
import { validDate, formatDate } from './dateHelpers';
import Button from '../Button';
import Input from './Input';
import styled from '../../styled';
import View from '../View';
import { useTheme } from 'styled-components';

let EnclosePickerAndButton = styled(View).attrs({ name: 'enclose-picker-and-button' })`
    align-items: ${({ theme }) => (theme.mobile ? 'flex-end' : 'flex-start')};
    flex-direction: ${({ theme }) => (theme.mobile ? 'column-reverse' : 'row')};
`;

EnclosePickerAndButton.displayName = 'EnclosePickerAndButton';

const DatePickerWrapper = styled(View).attrs({ role: 'dialog' })`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: ${({ theme }) => theme.zindex.PortalDatePickerWrapper};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DatePickerBackDrop = styled(View)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: calc(100% - 12px);
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
`;

/** @type {import('react').FC<Props>} */
const Portal = props => {
    const { isModalOpen, onChange, setIsModalOpen, showTimeInput, value } = props || {};
    const { native } = useTheme();
    if (!isModalOpen) return null;

    // prettier-ignore
    return createPortal(
        rc(DatePickerWrapper, null,
            rc(DatePickerBackDrop, { onClick: () => setIsModalOpen(false) }),
            rc(EnclosePickerAndButton, null,
                rc(ReactDatePicker, {
                    dateFormat: showTimeInput ? 'M/d/yyyy h:mm aa' : 'M/d/yyyy',
                    fixedHeight: true,
                    inline: true,
                    onChange,
                    selected: value,
                    shouldCloseOnSelect: !showTimeInput,
                    showTimeInput,
                    value
                }),
                !native && rc(Button, { icon: 'close', buttonStyle: 'round', onClick: () => setIsModalOpen(false) })
            )
        ),
        document.getElementById('datepicker-portal')
    );
};

/**
 * @typedef {Object} Props
 * @property {string} format
 * @property {(date: Date) => void} onChange
 * @property {boolean} showTimeInput
 * @property {Date} [value]
 */
/** @type {import('react').FC<Props>} */
const PortalDatePicker = props => {
    const { format, onChange, showTimeInput, value } = props || {};

    const [rawDateString, setRawDateString] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    /** @type {(event: import('react').FocusEvent<HTMLInputElement> | import('react-native').NativeSyntheticEvent) => void} */
    const handleBlur = useCallback(
        event => {
            const { value } = event.target;
            onChange(validDate(value) ? new Date(value) : value);
            setRawDateString(null);
        },
        [onChange]
    );

    /** @type {(value: import('react').SyntheticEvent) => void} event */
    const handleInputChange = useCallback(event => {
        setRawDateString(event.target.value);
    }, []);

    /** @type {(date: Date) => void} */
    const handleDatePickerChange = useCallback(
        date => {
            onChange(date);
            setRawDateString(null);
            !showTimeInput && setIsModalOpen(false);
        },
        [onChange, showTimeInput]
    );

    // prettier-ignore
    return rc(Fragment, null,
        rc(Input, {
            ...props,
            autoComplete: 'off',
            onChange: handleInputChange,
            onFocus: () => setIsModalOpen(true),
            value: rawDateString ?? (validDate(value) ? formatDate(value, format) : ''),
            onBlur: handleBlur
        }),
        rc(Portal, { isModalOpen, onChange: handleDatePickerChange, setIsModalOpen, showTimeInput, value })
    );
};

PortalDatePicker.defaultProps = {};

PortalDatePicker.propTypes = {
    format: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    showTimeInput: PropTypes.bool.isRequired,
    value: PropTypes.instanceOf(Date)
};

export default PortalDatePicker;
