import { createElement as rc, useMemo } from 'react';
import PropTypes from 'prop-types';
import { View, Button, h4, fromTheme, styled, COMMON_COLOR_SCHEME } from 'lib_ui-primitives';
import HNode from '../../HNode';

const ListRow = styled(View).attrs({ name: 'list-row' })`
    background-color: ${fromTheme('backgroundColor')};
    margin-left: ${fromTheme('viewMargin')};
    margin-right: ${fromTheme('viewMargin')};
    margin-bottom: ${fromTheme('viewMargin')};
    padding: ${fromTheme('viewPadding')};
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
`;

const RowHeader = styled(View).attrs({ name: 'row-header' })`
    flex-grow: 1;
    width: 100%;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled(h4)`
    margin: ${fromTheme('viewMargin')};
`;

const RemoveButton = styled(Button)`
    margin: 0;
`;

const RowContent = styled(View).attrs({ name: 'row-content' })`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
`;

function CriteriaRow(props) {
    const {
        templates = [],
        entryToTemplate = () => {},
        entry,
        remove,
        allowRemove = true,
        index: rowIndex,
        propertyName,
        currentRoute,
        treePosition,
        disabled
    } = props ?? {};
    const propertyPath = `${propertyName}[${rowIndex}]`;

    function subFormRemove() {
        remove(rowIndex);
    }

    const template = entryToTemplate(entry, templates);

    // Avoid rerenders.
    const hNode = useMemo(() => {
        return {
            ...template,
            disabled,
            propertyPath,
            id: `${template.id}_${rowIndex}`
        };
    }, [template, disabled, propertyPath, rowIndex]);

    if (!template) return null;
    // prettier-ignore
    return rc(ListRow, null,
        rc(RowHeader, null,
            rc(Title, null, template.title),
            allowRemove && rc(RemoveButton, {icon: 'clear', buttonStyle: 'round', color: 'transparent', disabled, fontColor:COMMON_COLOR_SCHEME.error, onClick: subFormRemove})
        ),
        rc(RowContent, null,
            rc(HNode, {hNode, currentRoute, treePosition}, entry)
        )
    );
}

CriteriaRow.propTypes = {
    treePosition: PropTypes.shape({
        sequence: PropTypes.number.isRequired
    }).isRequired
};
export default CriteriaRow;
