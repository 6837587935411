//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _mockRfidService from './mockRfidService';
import _webBarcodeService from './webBarcodeService';
import _zebraBarcodeService from './zebraBarcodeService';
import _zebraRfidService from './zebraRfidService';
export const mockRfidService = _mockRfidService;
export const webBarcodeService = _webBarcodeService;
export const zebraBarcodeService = _zebraBarcodeService;
export const zebraRfidService = _zebraRfidService;
export default {mockRfidService, webBarcodeService, zebraBarcodeService, zebraRfidService};

