import { network } from 'lib_ui-services';
import { COMMON_COLOR_SCHEME } from 'lib_ui-primitives';
import logging from '@sstdev/lib_logging';

export default {
    verb: 'doingClearCache',
    namespace: 'application',
    relation: 'runtime',
    description: 'Clear the cache and redirect to the login page',
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @template T
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 */
async function logic({ dispatch }) {
    try {
        const ok = await asyncConfirmationModal(dispatch);
        if (!ok) return;

        // Clear the cache by removing all service worker registrations, indexedDB databases, and clearing local storage
        await network.clearCache();
    } catch (error) {
        logging.error(error);
        const message =
            'An error occurred while attempting to reset the application.  You may need to refresh the web browser page and try again.';
        dispatch(
            { message, timeout: 5000, addToList: false, isError: true },
            {
                verb: 'pop',
                namespace: 'application',
                relation: 'notification'
            }
        );
    }
}

async function asyncConfirmationModal(dispatch) {
    return new Promise(resolve => {
        const message =
            'This action will delete any pending requests and will require a user to be online to log in again.  Continue?';
        dispatch(
            {
                message,
                okButtonText: 'YES',
                icon: 'warning',
                iconColor: COMMON_COLOR_SCHEME.warn,
                okAction: () => resolve(true),
                cancelAction: () => resolve(false)
            },
            { verb: 'confirm', namespace: 'application', relation: 'user' }
        );
    });
}
