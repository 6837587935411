import { lazy, createElement as rc, Suspense } from 'react';
import { h1 } from 'lib_ui-primitives';

export default function BlocklyEditorProxy(props) {
    const BlocklyEditor = lazy(() => import('./BlocklyEditor'));
    const fallback = rc(h1, null, 'Loading...');
    // prettier-ignore
    return rc(Suspense, {fallback},
        rc(BlocklyEditor, props)
    );
}
