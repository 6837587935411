import { ObjectId } from 'lib_ui-services';
import lodash from 'lodash';
const { cloneDeep } = lodash;

export default {
    verb: 'didRemove',
    namespace: 'metadata',
    relation: 'page',
    description: 'Bump build number and add patch record for useCaseDetail',
    // status: 'success',
    //type: '',
    priority: 5, //have to do this before the standard one, as that one removes the _id
    //useCaseIds:[]
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'metadata',
                relation: 'page',
                type: 'find',
                status: undefined
            },
            query: ({ data }) => {
                if (!(data?._id || data?.id)) {
                    throw new Error('No _id available on page removal request');
                }
                return {
                    _id: data._id || data.id
                    //don't exclude deleted, as we are actually looking for the deleted record.
                };
            }
        }
    ],
    //this is the actual logic:
    logic: didRemove
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function didRemove({ data, prerequisiteResults, dispatch, log }) {
    if (data.skipConfirm) return;
    const removedPage = prerequisiteResults[0].result[0];
    if (!removedPage) return;

    //To get the useCaseDetail record, we need the removedPage,
    //but we can't use prerequisite in prerequisites....
    const {
        'metadata:useCaseDetail': { _id }
    } = removedPage;
    const response = await dispatch(
        {
            query: {
                _id,
                'meta.deleted': { $exists: false }
            }
        },
        {
            verb: 'get',
            namespace: 'metadata',
            relation: 'useCaseDetail',
            type: 'find'
        },
        true
    );

    const useCaseDetail = response.result[0];
    //first, we need to update the menu
    const navigationResponse = await dispatch(
        {
            query: {
                'metadata:useCaseDetail._id': _id,
                'meta.deleted': { $exists: false }
            }
        },
        {
            verb: 'get',
            namespace: 'metadata',
            relation: 'navigation',
            type: 'find'
        },
        true
    );
    const navigation = navigationResponse?.result?.[0];
    if (navigation) {
        const newNav = cloneDeep(navigation);
        const [parent, childIndex] = findParent(removedPage._id, newNav);
        parent.children.splice(childIndex, 1);
        dispatch(
            { oldRecord: navigation, newRecord: newNav },
            { verb: 'update', namespace: 'metadata', relation: 'navigation' }
        );
    } else {
        log.warn(`METADATA] No navigation found to update for ${useCaseDetail.title}`);
    }

    //then we need to bump the useCaseDetail
    const { major, minor = 0, patch = 0, build = 0, version: oldVersion } = useCaseDetail;

    const version = `${major}.${minor}.${patch}.${build + 1}`;
    //bump the version number
    dispatch(
        { oldRecord: useCaseDetail, newRecord: { ...useCaseDetail, build: build + 1, version, verified: false } },
        { verb: 'update', namespace: 'metadata', relation: 'useCaseDetail' }
    );
    //add a simplified history "patch" record to track the rough changes.
    const patchRecord = {
        _id: ObjectId(),
        'metadata:useCaseDetail': removedPage['metadata:useCaseDetail'],
        patches: [
            {
                op: 'update',
                path: '/version',
                value: version,
                oldValue: oldVersion
            },
            {
                op: 'remove',
                path: '/page',
                value: removedPage.title
            }
        ]
    };
    //create a patch record
    dispatch({ newRecord: patchRecord }, { verb: 'create', namespace: 'metadata', relation: 'changeHistory' });
}

function findParent(_id, nav) {
    if (!nav.children) return null;
    const childIndex = nav.children.findIndex(c => c._id === _id);
    if (childIndex > -1) {
        return [nav, childIndex];
    }
    for (const child of nav.children) {
        const result = findParent(_id, child);
        if (result) return result;
    }
    return null;
}
