import logging from '@sstdev/lib_logging';
import handleBackboneMessages from './messageHandlers';
import * as util from 'util';
const { inspect } = util;
const _p = { handleBackboneMessages };
export const _private = _p;
export default function socketEventHandlers(socketState) {
    // == Connect ==
    socketState.socket.on('connect', () => {
        logging.info('[SOCKETS] connect.');
        socketState.updateSocketStatus(true);
        //socketState.socket.emit('authenticate', { token }); //connect and send the jwt token
    });

    // == disconnect ==
    socketState.socket.on('disconnect', function () {
        logging.info('[SOCKETS] disconnecting.');
        socketState.updateSocketStatus(false);
        if (socketState.offline) {
            logging.info('[SOCKETS] Setting socket offline.');
            return;
        }
        if (!socketState.initialStartup) {
            logging.info('[SOCKETS] Socket communication failed ');
        } else {
            socketState.initialStartup = false;
        }
    });

    // == The actual event processing ==
    if (!socketState.socket.onEventReplaced) {
        // by default, you do not get access to the "event name" (e.g. "upsert_item_item")
        // Additionally, you have to explicitly subscribe to the event name to handle it.
        // we want access to the event name, as we don't _know_ what it exactly will be.
        // Therefore, this is intercepting the normal onevent call, then does the call as normal (just in case),
        // and then IF the event name includes an underscore, call to our custom event handler
        const onevent = socketState.socket.onevent;
        socketState.socket.onevent = function (packet) {
            onevent.call(this, packet); // original call
            const args = packet.data || [];
            if (args.length && args[0].includes('_')) {
                const [event, ...data] = args;
                _p.handleBackboneMessages(event, data, socketState.eventSink);
            }
        };
        socketState.socket.onEventReplaced = true;
    }

    // == Error handling ==
    socketState.socket.removeAllListeners('error');
    socketState.socket.on('error', function (data) {
        logging.error(`[SOCKETS] An error occurred in the socket communication.  Details: ${inspect(data)}`);
    });

    // == bonus chat ==
    socketState.socket.removeAllListeners('chat');
    socketState.socket.on('chat', function (data) {
        handleBackboneMessages('chat', data, socketState.eventSink);
    });
}
