export default {
    verb: 'willEdit',
    namespace: 'metadata',
    relation: 'page',
    description: 'Assure record exists before editing',
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'metadata',
                relation: 'page',
                type: 'find'
            },
            query: ({ data }) => {
                return {
                    _id: data._id,
                    'meta.deleted': { $exists: false }
                };
            }
        }
    ],
    //this is the actual logic:
    logic: willEdit
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willEdit({ data, prerequisiteResults, context, dispatch }) {
    if (prerequisiteResults[0].result.length) {
        return;
    }

    const { namespace, relation } = context;

    const newRecord = {
        _id: data._id,
        title: data.title,
        isNavigationLink: true,
        'metadata:useCaseDetail': data['metadata:useCaseDetail'],
        blockly: {
            blocks: {
                languageVersion: 0,
                blocks: [
                    {
                        x: 50,
                        y: 70,
                        fields: { title: data.title },
                        type: 'page_NavigationLink'
                    }
                ]
            }
        }
    };
    await dispatch({ newRecord }, { verb: 'create', namespace, relation }, true);
}
