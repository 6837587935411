import { constants, defaultValueSelectors } from 'lib_ui-services';
import { hooks } from 'lib_ui-primitives';
const osPreference = defaultValueSelectors.getDarkModePreference();

/**
 * Custom hook that returns the dark mode preference state.
 * Returns false if there is no os preference and no previous stored preference.
 * @returns {boolean} The dark mode preference state.
 */
export default () => {
    return hooks.useBbState(osPreference, 'prefersDarkMode', {
        scope: constants.retention.SCOPE.GLOBAL,
        visibility: constants.retention.VISIBILITY.PER_BROWSER,
        duration: constants.retention.DURATION.ALWAYS
    });
};
