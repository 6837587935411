import { useEffect, createElement as rc, useContext, useState, useCallback } from 'react';
import { ThemeContext } from 'styled-components';
import { Fieldset, View, contexts, ScrollToBottomButton, hooks, WaitScreen } from 'lib_ui-primitives';
import propTypes from 'prop-types';
import { globalConfig } from 'lib_ui-services';
import useFormMachine from '../../hooks/useFormMachine';
import Title from './Title';
import useEventSink from '../../hooks/useEventSink';
import FilterInterdependencyBoundary from '../contextProviders/FilterInterdependencyBoundary';
import { StyledForm, FormFooter, ErrorText, ButtonBar, GreedyOtherElements, Loading } from './styles';
import { getFormButtons, getFormElements, getOtherElements } from './formComponentSelectors';
import useIsLoading from '../../hooks/useIsLoading';

const { useScrollLayoutChange } = hooks;

const _p = {
    ScrollToBottomButton
};
export const _private = _p;

/**
 * @typedef {Object} FormContext
 * @property {boolean} [isDirty]
 * @property {boolean} [submitting]
 * @property {boolean} [disabled]
 * @property {Object} [oldRecord]
 * @property {Object} [newRecord]
 */

/** @type react.Context<FormContext> */

const defaultErrors = { field: {}, form: [] };
/**
 * @typedef {Object} Props
 * @property {boolean} centerFields
 * @property {Object} hNode
 * @property {boolean} heightGreedy
 * @property {react.FormEventHandler} [onSubmit]
 * @property {string} title
 */
/**
 * The form has two primary types of layout:
 *
 * if scrollWholeForm === false, it will allow independent scrolling of the
 * fieldset (top) area and any components in the 'otherElements' (bottom) area
 *
 * if scrollWholeForm === true, it will scroll the entire form and the
 * fieldset(top) area will expand as much as needed to accommodate its content
 * whereas the 'otherElements (bottom) will need min-heights set (or they will
 * squish down vertically to their minimum possible size).
 * @type {import('react').FC<Props>}
 */
const Form = props => {
    const { mobile } = useContext(ThemeContext);
    const {
        centerFields,
        children,
        hNode: { title, id, namespace, relation },
        hNode,
        onSubmit,
        errors = defaultErrors,
        popErrorNotifications = !mobile,
        scrollWholeForm = false
    } = props || { hNode: {} };
    const formContext = useFormMachine(hNode);
    const isLoading = useIsLoading(namespace, relation);
    /**
     * Separate out the types of components
     *
     * - formElements (typically inputs)
     * will be rendered first inside a fieldset.
     * (This may need to be altered to include things like actionElements if any
     * forms contain those, but right now they are included in 'otherElements'.)
     *
     * - Other elements (typically layout components like tabs) will go next and
     * will be greedy with space, taking up as much as is available after the
     * components render.
     *
     * - Last, the form footer will contain the formButtons.
     * */
    const formElements = getFormElements(children);
    const otherElements = getOtherElements(children);
    const formButtons = getFormButtons(children);

    const [, publish] = useEventSink();
    const [formErrors, setFormErrors] = useState([]);
    const [scrollLayout, setScrollLayout] = useState({});
    const onScrollLayoutChange = useCallback(scrollLayout => {
        setScrollLayout(scrollLayout);
    }, []);
    const ref = useScrollLayoutChange(onScrollLayoutChange);

    // Combine errors passed from parents with any parents passed with the formContext
    // and set a stateful value so that these rendered or the user can be notified
    // (if popErrorNotifications is true).
    useEffect(() => {
        let _formErrors = [...formContext.formErrors, ...errors.form];
        _formErrors = _formErrors.map(e => e.message || e);
        setFormErrors(_formErrors);
    }, [formContext.formErrors, errors]);

    // If there are form level errors, publish them as a (toast) notification
    useEffect(() => {
        if (popErrorNotifications) {
            formErrors.forEach(message => {
                publish(
                    { isError: true, message, timeout: globalConfig().notificationTimeoutFormError },
                    { verb: 'pop', namespace: 'application', relation: 'notification' }
                );
            });
        }
    }, [formErrors, publish, popErrorNotifications]);

    if (isLoading) {
        return rc(Loading, null, 'Loading...');
    }
    // To exist, other elements should be an array with members or just one element.
    const otherElementsExist = otherElements != null && (otherElements.length > 0 || !Array.isArray(otherElements));

    // Prevent the default form submit action
    const handleSubmit = event => {
        event.preventDefault();
        // Call the onSubmit prop if it exists
        if (onSubmit) {
            onSubmit(event);
        }
    };

    // prettier-ignore
    return rc(WaitScreen, { id: `waitScreen-${id}`, waiting: formContext.submitting },
        rc(FilterInterdependencyBoundary, { name: 'form', restoreSelectionsOnRemount: true },
            rc(contexts.FormContext.Provider, { value: formContext },
                rc(StyledForm, { ref, autoComplete: 'off', id, onSubmit: handleSubmit, scrollWholeForm, heightGreedy: scrollWholeForm },
                    // Form title
                    title ? rc(Title, { name: 'form-title' }, title) : null,
                    // All the formElements for editing -
                    rc(Fieldset, { centerFields, greedy: !otherElementsExist, scrollWholeForm }, formElements),
                    // Everything else - rendered separately so they can be greedy with the remaining vertical space
                    otherElementsExist && rc(GreedyOtherElements, { scrollWholeForm }, otherElements),
                    rc(FormFooter, { name: 'form-footer' },
                        // Render form level errors in the footer
                        // eslint-disable-next-line react/no-array-index-key
                        rc(View, null, formErrors.map((err, i) => rc(ErrorText, { key: `formError${i}` }, err))),
                        rc(ButtonBar, null, formButtons)
                    )
                ),
                scrollWholeForm && rc(_p.ScrollToBottomButton, { scrollLayout, id: 'test' })
            )
        )
    );
};

Form.defaultProps = {
    heightGreedy: true,
    centerFields: false
};

Form.propTypes = {
    hNode: propTypes.shape({
        title: propTypes.string,
        id: propTypes.string
    }).isRequired,
    heightGreedy: propTypes.bool,
    centerFields: propTypes.bool
};

export default Form;
