import standardRule from '../../namespace/relation/doingUpdate_namespace_relation';
import { constants } from 'lib_ui-services';

export default {
    verb: 'doingUpdate',
    namespace: 'metadata',
    relation: 'page',
    description: 'Update the indicated data on the database',
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic(payload) {
    //at this point, we should receive one of 4 different top blocks:
    // a regular page (page_Page)
    // a Navigation Link (e.g. Logout: page_NavigationLink)
    // a regular nav heading (that has other nav headings or pages underneath it: navHeading_NavHeading)
    // a tab nav heading (which is rendered as a tab header on a page with tabs: navHeading_TabNavHeading)
    // Additionally, there might be other disconnected blocks on the workspace
    //that blockly reports in the ...blocks.blocks array.
    //We absolutely do not care about those...
    //So, first, filter out valid top blocks
    const topBlock = payload.data.newRecord.blockly.blocks.blocks.find(b =>
        constants.acceptableTopBlocks.includes(b.type)
    );

    //then, if the top block is one of the 2 page types, actually persist it to storage
    if (topBlock.type.startsWith('page_')) {
        return standardRule.logic(payload);
    }
    //otherwise it is a menu entry, which is not saved outside of navigation,
    //so just skip this step.
    return payload.data;
}
