import { prerequisites, preventChangesWhenLocked } from './utilities/lockedVersion';

export default {
    verb: 'willRemove',
    namespace: 'metadata',
    //any relation in this namespace
    excludedNamespaceRelations: [{ namespace: 'metadata', relation: 'changeHistory' }],
    description: 'Prevent any changes if this (or a higher) version is locked',
    //type: '',
    priority: 99,
    //useCaseIds:[]
    prerequisites: prerequisites(),
    //this is the actual logic:
    logic: preventChangesWhenLocked
};
