import notHandledHere from './notHandledHere';
import { database } from 'lib_ui-services';

export default {
    verb: 'doingCount',
    excludedNamespaceRelations: notHandledHere,
    description: 'Count the requested records on the database',
    //this is the actual logic:
    logic: doingCount
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingCount({ context, data }) {
    const persistContext = { ...context, verb: 'persist', eventId: undefined };
    //if the type is:
    // 'find': payload should contain a raw mongodb syntax query in the root or
    // in the query field of the payload.
    // 'get': payload should contain { _id }
    const db = database.get();
    let result = await db.count(data.query?.criteria ?? data.criteria ?? data.query ?? data, persistContext);
    return { ...data, result };
}
