import { metadata, validateModel, constants } from 'lib_ui-services';
import { errors as _errors } from 'lib_ui-primitives';
const { useCaseIds } = constants;

const _p = {
    validateWithHNode: validateModel.withHNode,
    getPrettyRelationName: metadata.getPrettyRelationName
};
export const _private = _p;

export default {
    verb: 'doingValidate',
    namespace: 'deploy',
    relation: 'environment',
    type: 'singleRecordForm',
    description: 'Validate the given record',
    excludedUseCaseIds: [useCaseIds.ONE_TOUCH],
    //this is the actual logic:
    logic: validation
};

async function validation({ data, context }) {
    const { namespace, relation, hNode } = context;
    const { newRecord } = data;

    // Check for field level validation problems.
    let errors = await _p.validateWithHNode(newRecord, hNode);
    if (errors && Object.keys(errors).length > 0) {
        let message = `There are problems with this ${_p.getPrettyRelationName(
            namespace,
            relation
        )}.  For details, see the red message(s) above.`;
        throw new _errors.ValidationError(message, errors);
    }
}
