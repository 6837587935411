import logging from '@sstdev/lib_logging';
export default function verifyMetaKeysPresent(requiredKeys, meta, errorInfo) {
    if (meta == null) {
        throw new Error('A meta parameter was not passed into verifyMetaKeysPresent.');
    }
    const metaKeys = Object.keys(meta);
    const missingKeys = requiredKeys.filter(k => !metaKeys.includes(k));
    if (missingKeys.length) {
        const { operation, namespaceTitle, relationTitle } = errorInfo;
        logging.error(
            `Values "${missingKeys.join(
                ','
            )}" are missing, but must be included on the meta object for ${operation} operations on ${namespaceTitle}/${relationTitle} relations.`
        );
    }
    // If in dev mode, take the extra CPU cycles to be sure we aren't making a mistake with this somewhere.
    // eslint-disable-next-line no-undef
    if (!__PRODUCTION__) {
        verifyKeyType(meta);
    }
}

function verifyKeyType(meta) {
    Object.entries(meta).forEach(([key, value]) => {
        if (key.endsWith('Time') && typeof value !== 'string') {
            throw new Error(`${key} must be a stringified date.  Value=${value}`);
        }
        if (key.endsWith('By')) {
            if (typeof value !== 'object') {
                throw new Error(`${key} must be a object.  Value=${value}`);
            }
            if (!value._id) {
                throw new Error(`${key} must be an object with an _id.  Value=${JSON.stringify(value)}`);
            }
        }
    });
}
