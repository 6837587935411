import { createElement as rc, useMemo, useEffect } from 'react';
import _Range from '../_abstractComponent/Range';
import useDbView from '../../hooks/useDbView';
import { filters, metadata } from 'lib_ui-services';
import { hooks } from 'lib_ui-primitives';
const { filterHelpers } = filters;
const { usePersistentState } = hooks;

const rangeTypes = {
    DateTime: { filter: 'dateRange', childComponentType: 'DatePicker' }
};

const _p = { useDbView };
export const _private = _p;
export default function Range(props) {
    const {
        hNode,
        hNode: { propertyName, namespace, relation, id, fromInputHNode }
    } = props ?? { hNode: {} };

    const filterType = rangeTypes[fromInputHNode.hNodeType].filter;

    const defaultFilters = useMemo(() => {
        return {
            [filterType]: filters[filterType].getFilter(null, null, hNode.propertyPath, hNode.fromInputHNode.format),
            ...filterHelpers.getDefaultFilters(hNode)
        };
    }, [hNode, filterType]);
    const [fromValue, setFromValue] = usePersistentState(
        `from-${propertyName}-${id}`,
        defaultFilters[filterType].startDateTime
    );
    const [toValue, setToValue] = usePersistentState(
        `to-${propertyName}-${id}`,
        defaultFilters[filterType].endDateTime
    );
    const propertyPath = metadata.getPathToProperty(hNode);

    const { viewCriteria } = _p.useDbView(namespace, relation, undefined, hNode);
    useEffect(() => {
        viewCriteria.applyFilters(
            {
                ...defaultFilters,
                [filterType]: filters[filterType].getFilter(fromValue, toValue, propertyPath, fromInputHNode.format)
            },
            id
        );
    }, [propertyPath, fromValue, toValue, defaultFilters, viewCriteria, fromInputHNode.format, filterType, id]);

    return rc(_Range, {
        ...props,
        fromValue,
        setFromValue,
        toValue,
        setToValue,
        rangeTypes
    });
}
