import { createElement as rc, useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { hooks, testProperties, Popper, Modal } from 'lib_ui-primitives';
import { Container, ToggleButton, PreviewText, PopperMessageView } from './infoDisplayButtonStyles';
import { useTheme } from 'styled-components';
import InfoDisplayButtonMessageContent from './InfoDisplayButtonMessageContent';

const { usePersistentState } = hooks;

const { createPopper } = Popper;
/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function InfoDisplayButton(props) {
    const { mobile } = useTheme();
    const {
        hNode: {
            id,
            title = '',
            message = '',
            preview = true,
            icon = 'info',
            defaultOpen = 'false',
            buttonStyleIfUnopened = 'warn',
            buttonStyleIfOpened = 'transparent'
        }
    } = props;

    const [wasOpened, setWasOpened, ready] = usePersistentState(`wasOpened${id}`, false);
    const [isOpen, setOpen] = useState(false);
    const toggleMessageVisibility = useCallback(() => {
        setOpen(open => {
            return !open;
        });
        setWasOpened(true);
    }, [setWasOpened]);

    const messageViewRef = useRef();
    const buttonRef = useRef();
    useEffect(() => {
        if (ready && !mobile) {
            createPopper(buttonRef.current, messageViewRef.current, {
                placement: 'right',
                modifiers: [
                    {
                        name: 'flip',
                        enabled: true
                    }
                ]
            });
        }
    }, [ready, mobile]);

    const showPreview = !wasOpened && preview;
    // Display the message if it was not previously opened and is supposed to default to open.
    useEffect(() => {
        if (ready && !wasOpened && defaultOpen) {
            setOpen(true);
            setWasOpened(true);
        }
    }, [ready, defaultOpen, wasOpened, setWasOpened]);

    // prettier-ignore
    return rc(Container, { id },
        rc(ToggleButton, {
            ref: buttonRef,
            ...testProperties(props.hNode, 'toggleButton'),
            icon,
            iconVariation: 'outlined',
            color: wasOpened ? buttonStyleIfOpened : buttonStyleIfUnopened,
            buttonStyle: showPreview ? 'Info' : 'round',
            onClick: toggleMessageVisibility,
            disabled: !ready
        },
            showPreview && rc(PreviewText, {
                name: 'preview-text',
                title,
                ...testProperties(props.hNode, 'previewText')
            }, message)
        ),
        !mobile && rc(PopperMessageView, { isOpen, ref: messageViewRef },
            rc(InfoDisplayButtonMessageContent, { hNode: props.hNode, toggleMessageVisibility })
        ),
        mobile && rc(Modal, { visible: isOpen },
            rc(InfoDisplayButtonMessageContent, { hNode: props.hNode, toggleMessageVisibility })
        )
    );
}

InfoDisplayButton.defaultProps = {};

InfoDisplayButton.propTypes = {
    hNode: PropTypes.object.isRequired,
    currentRoute: PropTypes.string.isRequired
};

export default InfoDisplayButton;
