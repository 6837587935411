import { useContext, useCallback } from 'react';
import { contexts } from 'lib_ui-primitives';
/**
 * Move focus to the component sequenced after the currently focused
 * component (or after the component which has the given id).
 * @param {string} [id] - (optional) id of component currently focused
 */
export function useMoveFocusToNext(id) {
    const context = useContext(contexts.FocusContext);
    return useCallback(() => context?.moveNext(id), [context, id]);
}
