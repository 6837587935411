import { useState, useEffect } from 'react';
import { defaultSize } from '../constants';
import useSelectedFormEntry from './useSelectedFormEntry';
import useEventSink from '../../../../hooks/useEventSink';

const _p = {
    useSelectedFormEntry
};
export const _private = _p;

export default function useLabelSize(template) {
    const [subscribe, publish] = useEventSink();
    const [labelSpec, setLabelSpec] = useState(defaultSize);

    useEffect(() => {
        let allowStateUpdate = true;
        const unsubscribe = subscribe(
            { verb: 'get', namespace: 'print', relation: 'size', type: 'get', status: 'success' },
            ({ result }) => {
                if (result?.length && allowStateUpdate) {
                    const newSpec = {
                        ...result[0],
                        labelWidth: (result[0].width || 2) * 100,
                        labelHeight: (result[0].height || 1) * 100
                    };
                    setLabelSpec(newSpec);
                }
            }
        );
        if (template?.['print:size']) {
            publish(
                { _id: template['print:size']._id },
                { verb: 'get', namespace: 'print', relation: 'size', type: 'get' }
            );
        } else {
            setLabelSpec({
                ...defaultSize,
                labelWidth: (defaultSize.width || 2) * 100,
                labelHeight: (defaultSize.height || 1) * 100
            });
        }
        return () => {
            unsubscribe();
            allowStateUpdate = false;
        };
    }, [publish, subscribe, template]);

    return labelSpec;
}
