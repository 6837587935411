import { createElement as rc, useCallback, useEffect, useRef } from 'react';
import useEventSink from '../../hooks/useEventSink';
import { contexts } from 'lib_ui-primitives';
import lodash from 'lodash';
const { get } = lodash;
const { set } = lodash;

const { LoadingContext } = contexts;
/**
 * Some components may need to be aware that loading for the given
 * namespace and relation is occurring even if those components are rendered
 * AFTER the loading starts.  This component allows the loading context to be maintained
 * higher up in the component tree, so loading info is available when the children
 * components that need it eventually load.
 * @param {object} props
 * @param {array} props.children
 * @returns react component
 */
export default function LoadingBoundary(props) {
    const { children } = props || {};
    const [subscribe] = useEventSink();
    const pendingLoads = useRef({});
    const stateSubscribers = useRef({});

    // Listen for load events and maintain a list of pending loading IDs
    useEffect(() => {
        /**
         * Get pending loads, change them (using the passed function), save the change
         * and give a clone of the updated array to any subscribers
         * @param {function} changeFunc - a function enacting the change to the pending load IDs
         * @param {string} namespace - the affected namespace
         * @param {string} relation - the affect relation
         */
        function updatePending(changeFunc, namespace, relation) {
            const pending = get(pendingLoads.current, [namespace, relation], []);
            const newPending = changeFunc(pending);
            set(pendingLoads.current, [namespace, relation], newPending);
            const subscribers = get(stateSubscribers.current, [namespace, relation], []);
            subscribers.forEach(func => func([...newPending]));
        }
        // Listen for load events
        const unsubscribes = [
            subscribe({ verb: 'load' }, ({ loadId }, { namespace, relation }) => {
                updatePending(pending => [...pending, loadId], namespace, relation);
            }),
            subscribe({ verb: 'load', status: 'success' }, ({ loadId }, { namespace, relation }) => {
                updatePending(pending => pending.filter(id => id !== loadId), namespace, relation);
            }),
            subscribe({ verb: 'load', status: 'failure' }, ({ loadId }, { namespace, relation }) => {
                updatePending(pending => pending.filter(id => id !== loadId), namespace, relation);
            })
        ];
        // Remove these subscriptions when unmounting
        return () => unsubscribes.forEach(u => u());
    }, [subscribe]);

    // Allow listeners to A) immediately get the current pending loads B) be informed if they change
    const addStateSubscribers = useCallback((namespace, relation, callback) => {
        const allSubscribers = stateSubscribers.current;
        const subscribers = get(allSubscribers, [namespace, relation], []);
        set(allSubscribers, [namespace, relation], [...subscribers, callback]);
        const pending = get(pendingLoads.current, [namespace, relation], []);
        callback(pending);
        // Return an unsubscribe function to the subscriber
        return () => {
            subscribers.splice(subscribers.indexOf(callback), 1);
        };
    }, []);

    return rc(LoadingContext.Provider, { value: { addStateSubscribers } }, children);
}
