import groupByHelpers from './groupByHelpers';

export default {
    getFilter,
    getUriComponent,
    fromHNode,
    pageResetRequired: true,
    getAggregateReducer
};

function fromHNode({ propertyName, namespace, relation, originalRelation }) {
    return getFilter(propertyName, namespace, originalRelation || relation);
}

function getFilter(propertyName = '', optionalNamespace, optionalRelation) {
    return {
        propertyName,
        optionalNamespace,
        optionalRelation
    };
}

function getUriComponent(filters) {
    const filter = filters['groupBy'];
    let { propertyName, optionalNamespace, optionalRelation } = filter;
    if (optionalNamespace && optionalRelation) {
        propertyName = `${optionalNamespace}:${optionalRelation}.${propertyName}`;
    }
    return `groupBy=${encodeURIComponent(propertyName)}`;
}

function getAggregateReducer(filters, itemCount, accumulator = defaultAccumulator) {
    if (!filters?.groupBy) {
        return x => x;
    }
    //we need to group by the groupBy specs, and return a value per each
    const { optionalNamespace, optionalRelation, propertyName } = filters.groupBy;
    let key = propertyName.split('.');
    if (optionalNamespace && optionalRelation) {
        key = [`${optionalNamespace}:${optionalRelation}`, ...propertyName.split('.')];
    }

    return array =>
        Object.values(
            array.reduce((aggregate, record) => {
                if (!record) return aggregate;
                // Apply search criteria to the record before grouping
                if (groupByHelpers.hasSearchCriteria(filters)) {
                    record = groupByHelpers.applySearchCriteria(record, filters);
                }
                let value = groupByHelpers.getValue(record, key, 'None');
                if (Array.isArray(value)) {
                    return value.reduce((resultSet, value) => accumulator(resultSet, value, record), aggregate);
                }
                return accumulator(aggregate, value, record);
            }, {})
        );
}

function defaultAccumulator(resultSet, value, record) {
    resultSet[value] = resultSet[value] || { _id: { title: value }, items: [], count: 0 };
    resultSet[value].items.push(record);
    resultSet[value].count++;
    return resultSet;
}
