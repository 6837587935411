import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { View, styled } from 'lib_ui-primitives';

const RightAlign = styled(View)`
    justify-content: flex-end;
    flex-grow: 1;
`;

function RightAlignLayout(props) {
    const { id, title: name, children, ...otherProps } = props;
    return rc(RightAlign, { name, id, ...otherProps }, children);
}

RightAlignLayout.propTypes = {
    children: PropTypes.array
};

export default RightAlignLayout;
