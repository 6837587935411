export default {
    verb: 'willCreate',
    prerequisites: [],
    priority: Number.MAX_SAFE_INTEGER,
    description: 'Prevent CRUD operations in preview mode.',
    // this is the actual logic:
    logic: willCreate
};

function willCreate({ context }) {
    if (context.preview) {
        throw new Error('Creates are not allowed in preview mode.');
    }
}
