import { createElement as rc, useCallback, useEffect } from 'react';
import { hooks, useDebounce } from 'lib_ui-primitives';
import useEventSink from '../../hooks/useEventSink';
import { constants, globalConfig, defaultValueSelectors } from 'lib_ui-services';
import AbstractToggle from '../_abstractComponent/Toggle';
import PropTypes from 'prop-types';

const { retention } = constants;
const { SCOPE, DURATION, VISIBILITY } = retention;
const { useBbState } = hooks;

const _p = {
    debounceMilliseconds: globalConfig().actionElementDebounceMilliseconds
};
export const _private = _p;
export default function Toggle(props) {
    const {
        hNode,
        hNode: {
            title,
            toggleValues,
            defaultValue,
            forAction,
            namespace,
            relation,
            subtype,
            propertyName,
            defaultValueSelector = 'static',
            retention = {
                duration: DURATION.ALWAYS,
                scope: SCOPE.LOCAL,
                visibility: VISIBILITY.PER_BROWSER,
                id: props?.hNode?.id
            }
        },
        currentRoute
    } = props ?? { hNode: {} };
    const [, publish] = useEventSink();
    const statePath = hNode?.namespace ? `${namespace}:${relation}.${propertyName}` : hNode?.propertyName;
    const stateName = statePath ?? props?.id;
    let _defaultValue = defaultValue;
    if (defaultValueSelector !== 'static') {
        _defaultValue = defaultValueSelectors[defaultValueSelector]();
    }
    const [value, setValue, ready] = useBbState(_defaultValue, stateName, retention);

    // if useBbState's retention data store has async methods, this could happen more than
    // once for the same value, so debounce.
    const publishValue = useDebounce(
        value => {
            // Don't publish if there is no forAction
            if (forAction == null || forAction === 'nothing') return;

            publish(
                { [propertyName]: value },
                { verb: forAction, namespace, relation, type: subtype, routePath: currentRoute }
            );
        },
        [forAction, namespace, relation, subtype, propertyName, currentRoute, publish],
        _p.debounceMilliseconds
    );

    const onClick = useCallback(
        newValue => {
            setValue(newValue);
            publishValue(newValue);
        },
        [setValue, publishValue]
    );

    useEffect(() => {
        if (ready) {
            publishValue(value);
        }
        // Only fire this once after the value is ready.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ready, publishValue]);

    // ignore-prettier;
    return rc(AbstractToggle, {
        id: hNode?.id ?? props?.id,
        title,
        onClick,
        value,
        trueLabel: toggleValues[1],
        falseLabel: toggleValues[0]
    });
}
Toggle.propTypes = {
    id: PropTypes.string,
    hNode: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        toggleValues: PropTypes.arrayOf(PropTypes.string),
        defaultValue: PropTypes.bool,
        forAction: PropTypes.string,
        namespace: PropTypes.string,
        relation: PropTypes.string,
        subtype: PropTypes.string,
        propertyName: PropTypes.string,
        retention: PropTypes.shape({
            duration: PropTypes.string,
            scope: PropTypes.string,
            visibility: PropTypes.string,
            id: PropTypes.string
        })
    })
};
