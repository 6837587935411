import { createElement as rc } from 'react';
import { Modal, Button, Text, styled, View, fromTheme } from 'lib_ui-primitives';

const BigTextView = styled(View)`
    flex-direction: column;
    width: 100%;
`;

const RightButton = styled(Button)`
    align-self: flex-end;
`;

const StyledText = styled(Text)`
    margin: ${fromTheme('viewMargin')};
`;

export default function SelectedNotification(props) {
    const { modalVisible, theme, selectedNotification, setSelectedNotification, onRemoveClick } = props;
    const close = () => setSelectedNotification(undefined);
    // prettier-ignore
    return rc(Modal, {visible: modalVisible, style: {width: theme.mobile ? '100%' :'90%'}},
        rc(BigTextView, null,
            rc(RightButton, { buttonStyle: 'round', color: 'transparent', icon: 'close', onClick: close }),
            rc(StyledText, null, selectedNotification.message),
            rc(RightButton, { buttonStyle: 'round', color: 'transparent', icon: 'delete', onClick: onRemoveClick })
        )
    );
}
