import { sub, add, startOfDay, endOfDay, isValid } from 'date-fns';
import { helpers } from '@sstdev/lib_metadata-config';

export default {
    getFilter,
    getUriComponent,
    fromHNode,
    pageResetRequired: true,
    getMql
};

function fromHNode(hNode) {
    const now = new Date();
    if (hNode.beginDateOffsetUnit === 'quarters') {
        hNode.beginDateOffsetUnit = 'months';
        hNode.beginDateOffsetInterval = hNode.beginDateOffsetInterval * 3;
    }
    let start = add(now, { [hNode.beginDateOffsetUnit]: hNode.beginDateOffsetInterval });
    let end = add(now, { [hNode.endDateOffsetUnit]: hNode.endDateOffsetInterval });
    if (hNode.beginDateOffsetUnit !== 'hours') {
        start = startOfDay(start);
    }
    if (hNode.endDateOffsetUnit !== 'hours') {
        end = endOfDay(end);
    }
    // We already took care of begin or end of day. pass in "H" for format to prevent it from being done again
    return getFilter(start, end, hNode.propertyName, 'H');
}

function getFilter(startDateTime, endDateTime, propertyPath = 'meta.createdTime', format, allowUndefined = false) {
    if (!allowUndefined) {
        if (!startDateTime) startDateTime = startOfDay(sub(new Date(), { months: 3 }));
        if (!endDateTime) endDateTime = endOfDay(new Date());
    } else if (!startDateTime && !endDateTime) {
        return null;
    }
    if (startDateTime) startDateTime = typeof startDateTime === 'string' ? new Date(startDateTime) : startDateTime;
    if (endDateTime) endDateTime = typeof endDateTime === 'string' ? new Date(endDateTime) : endDateTime;

    if (startDateTime && !isValid(startDateTime)) {
        throw new Error('startDateTime must be a valid Date');
    }

    if (endDateTime && !isValid(endDateTime)) {
        throw new Error('endDateTime must be a valid Date');
    }

    if (format == null || !helpers.shouldShowTimeInput(format)) {
        if (startDateTime) startDateTime = startOfDay(startDateTime);
        if (endDateTime) endDateTime = endOfDay(endDateTime);
    }

    return {
        startDateTime,
        endDateTime,
        propertyPath
    };
}

function getUriComponent(filters) {
    const filter = filters['dateRange'];
    if (filter.propertyName) {
        let criteria = { [filter.propertyName]: {} };

        if (filter.startDateTime) {
            criteria[filter.propertyName] = { $gte: { $date: new Date(filter.startDateTime).toISOString() } };
        }

        if (filter.endDateTime) {
            criteria[filter.propertyName] = {
                ...criteria[filter.propertyName],
                $lte: { $date: new Date(filter.endDateTime).toISOString() }
            };
        }

        return `criteria=${encodeURIComponent(JSON.stringify(criteria))}`;
    }
}
function getMql(filters) {
    if (filters == null || filters.dateRange == null) return;
    const {
        dateRange: { startDateTime, endDateTime, propertyPath }
    } = filters;
    if (!startDateTime) {
        return {
            [propertyPath]: { $lte: endDateTime.toISOString() }
        };
    } else if (!endDateTime) {
        return {
            [propertyPath]: { $gte: startDateTime.toISOString() }
        };
    }
    return {
        [propertyPath]: { $between: [startDateTime.toISOString(), endDateTime.toISOString()] }
    };
}
