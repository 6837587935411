/**
 * This is used to display Menu dropdowns like the Profile Menu in the
 * MeatballProfileLayout Component.
 * We might want to consider renaming this component as it might be confused
 * with the 'ComboButton' component which is the actual button attached to the
 * DropDown/index.js component.
 */
import { createElement } from 'react';
import {
    Menu,
    Button,
    fromTheme,
    webOnlyStyles,
    nativeOnlyStyles,
    MenuDivider,
    MenuHeader,
    styled
} from 'lib_ui-primitives';
import MenuItem from './MenuItem';
import lodash from 'lodash';
const { memoize } = lodash;

const rc = createElement;

let MenuStyle = styled(Menu)`
    background-color: ${fromTheme('menu', 'backgroundColor')};
    padding: ${fromTheme('viewPadding')};
    margin-right: ${fromTheme('textMargin')};
    border-radius: ${fromTheme('borderRadius')};
    border-style: solid;
    border-width: 1px;
    border-color: ${fromTheme('menu', 'borderColor')};
    font-family: ${fromTheme('font')};
    max-width: ${fromTheme('width')};
`;

MenuStyle = webOnlyStyles(MenuStyle)`
    list-style-type: none;
`;

MenuStyle = nativeOnlyStyles(MenuStyle)`
    margin-top: 52px;
`;

MenuStyle.displayName = 'MenuStyle';

const getMenuButton = memoize((id, props) => rc(Button, { buttonStyle: 'round', ...props, id: `menu-button-${id}` }));
const DropDownButton = ({ id, children, ...props }) => {
    const menuButton = getMenuButton(id, props);
    // prettier-ignore
    return rc(MenuStyle, {
        direction: 'bottom',
        menuButton
    },
        children
    );
};

//make these easily accessible/bundled on the DropDownButton
DropDownButton.MenuHeader = MenuHeader;
DropDownButton.MenuItem = MenuItem;
DropDownButton.MenuDivider = MenuDivider;
DropDownButton.displayName = 'DropDownButton';

export default DropDownButton;
