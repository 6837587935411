import { metadata, database } from 'lib_ui-services';
const { getRelationMetadata } = metadata;

const _p = {
    getRelationMetadata
};
export const _private = _p;

export default async function setStatusOnPatchCollection(context, status) {
    const { correlationId, namespace, relation } = context;
    const relationMetadata = _p.getRelationMetadata(namespace, relation);
    const storesPatchHistory = relationMetadata?.patchRelationType === 'patchOffline';

    if (!storesPatchHistory) return;

    const db = database.get();
    const lastModified = new Date();
    await db.updateMany(
        {
            criteria: { correlationId },
            operations: {
                $set: {
                    'meta.status': status,
                    'meta.modifiedTime': lastModified
                }
            }
        },
        { ...context, relation: `${relation}-patch` }
    );
}
