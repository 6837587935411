export default Object.freeze({
    USER: '177700000000000100000001',
    ADMIN: '177700000000000100000002',
    SUPPORT: '177700000000000100000003',
    /**
     * @deprecated Use DEITY
     */
    DIETY: 'DIETY',
    DEITY: 'DEITY',
    DEVICE: '177700000000000100000004'
});
/*
    {
        "_id" : ObjectId("177700000000000100000001"),
        "title" : "USER",
        "level" : NumberInt(30)
    }
    {
        "_id" : ObjectId("177700000000000100000002"),
        "title" : "ADMIN",
        "level" : NumberInt(20)
    }
    {
        "_id" : ObjectId("177700000000000100000003"),
        "title" : "SUPPORT",
        "level" : NumberInt(10)
    }
    {
        "_id" : ObjectId("177700000000000100000004"),
        "title" : "DEVICE",
        "level" : NumberInt(15)
    }
*/
