export default {
    getFilter,
    getUriComponent,
    fromHNode,
    pageResetRequired: true,
    getMql
};

function fromHNode() {
    return getFilter();
}

function getFilter() {
    return {};
}

function getUriComponent() {
    return '';
}

function getMql() {
    return { 'meta.deleted': { $exists: false } };
}
