import metadataServices from '@sstdev/lib_metadata-services';
import combineMetadata from '../../metadata/utilities/combineMetadata';
import { globalConfig, constants } from 'lib_ui-services';

const { dataDictionaryService } = metadataServices;
const _p = {
    combineMetadata,
    calculateDataDictionary: dataDictionaryService.transform
};
export const _private = _p;

export default {
    verb: 'doingCreate',
    namespace: 'metaui',
    relation: 'dataDictionary',
    description: '',
    //type: '',
    //priority:0,
    //useCaseIds:[]
    prerequisites: [
        //version detail (different from the others as we need to match on _id, rather than on metadata:useCaseDetail._id )
        {
            context: {
                verb: 'get',
                namespace: 'metadata',
                relation: 'useCaseDetail',
                type: 'find'
            },
            query: ({ data }) => {
                const {
                    'metadata:useCaseDetail': { _id }
                } = data;
                return {
                    _id,
                    'meta.deleted': { $exists: false }
                };
            }
        },
        //namespaces
        getLinkedDocumentsFor('namespace'),
        //profile menu
        getLinkedDocumentsFor('profileMenu'),
        //navigation
        getLinkedDocumentsFor('navigation'),
        //pages
        getLinkedDocumentsFor('page')
    ],
    //this is the actual logic:
    logic: doingCreate
};
function getLinkedDocumentsFor(relation) {
    return {
        context: {
            verb: 'get',
            namespace: 'metadata',
            relation: relation,
            type: 'find'
        },
        query: ({ data }) => {
            const {
                'metadata:useCaseDetail': { _id }
            } = data;
            return {
                'metadata:useCaseDetail._id': _id,
                'meta.deleted': { $exists: false }
            };
        }
    };
}

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingCreate({
    data,
    data: {
        'metadata:page': submittedPage,
        'metadata:profileMenu': submittedProfileMenu,
        'identity:role': role = { title: 'DEITY' }
    },
    dispatch,
    prerequisiteResults
}) {
    const [
        {
            result: [useCaseDetail]
        },
        { result: namespaces },
        {
            result: [profileMenu]
        },
        {
            result: [navigation]
        },
        { result: _pages }
    ] = prerequisiteResults;
    let pages;
    if (submittedPage) {
        pages = _pages.filter(x => x._id !== submittedPage._id).concat(submittedPage);
    } else {
        pages = _pages;
    }
    const combined = await _p.combineMetadata(
        namespaces,
        submittedProfileMenu || profileMenu,
        navigation,
        pages,
        useCaseDetail,
        dispatch
    );
    const notificationHandler = (message, blockId) => {
        dispatch(
            {
                message,
                blockId,
                timeout: globalConfig().notificationTimeoutFormError,
                addToList: true,
                isError: true
            },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );
    };
    let _accessRole = role.title;
    if (!Object.keys(constants.WELL_KNOWN_ROLES).includes(role.title)) {
        _accessRole = 'ADMIN';
    }
    const dataDictionary = _p.calculateDataDictionary(combined, _accessRole, notificationHandler);
    return { ...data, result: dataDictionary };
}
