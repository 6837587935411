//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _copy_item_item_success from './copy_item_item_success';
import _doingNew_item_item_success from './doingNew_item_item_success';
import _new_item_item_success_autoincrement from './new_item_item_success_autoincrement';
import _willChange_item_item from './willChange_item_item';
import _willNew_item_item from './willNew_item_item';
export const copy_item_item_success = _copy_item_item_success;
export const doingNew_item_item_success = _doingNew_item_item_success;
export const new_item_item_success_autoincrement = _new_item_item_success_autoincrement;
export const willChange_item_item = _willChange_item_item;
export const willNew_item_item = _willNew_item_item;
export default {copy_item_item_success, doingNew_item_item_success, new_item_item_success_autoincrement, willChange_item_item, willNew_item_item};

