import { createElement as rc, useMemo, useState, createContext, useContext } from 'react';

export const AccessContext = createContext();

/**
 *
 * @returns {{
 *  allowAccessWhenUndefined:boolean,
 *  selection:hNode,
 *  setSelection:function(hNode):void
 * }}
 */
export default function useAccessContext() {
    const context = useContext(AccessContext);
    return useMemo(() => context, [context]);
}

export function AccessContextProvider(props) {
    const { children, allowAccessWhenUndefined = true, value } = props || {};
    const [selection, setSelection] = useState(value);

    const context = useMemo(
        () => ({
            allowAccessWhenUndefined,
            selection,
            setSelection
        }),
        [allowAccessWhenUndefined, selection]
    );
    return rc(AccessContext.Provider, { value: context }, children);
}
