import { register } from '../ZNode';
import lodash from 'lodash';
const { get } = lodash;

/** In Dots, depending on dpi */
export const QRCODE_DOT_SIZE = {
    // on 203 dpi
    203: {
        5: 105, // 0.517 inch on 203 dpi
        4: 84, // 0.414 inch on 203 dpi
        3: 62, // 0.310 inch on 203 dpi
        2: 42 // 0.207 inch on 203 dpi
    }
};

async function QRContent(props) {
    const {
        record,
        output,
        zNode: { dpi, size, propertyName, padding },
        top
    } = props || { zNode: {} };
    const value = get(record, propertyName, '').replaceAll('~', '');

    if (value) {
        // barcodes don't have an `alignment = "center"` option.
        // Instead, they are always drawn with the top left corner on the cursor.
        // Approximate where the top left corner should be to center the barcode:
        const x = Math.round(QRCODE_DOT_SIZE[dpi][size] * 0.1) + padding;
        return output.moveTo(x, top).addBarcode(value, { type: 'QR', magnificationFactor: size });
    } else {
        return output.addComment(`No value in ${propertyName}`);
    }
}

QRContent.height = ({ zNode: { dpi, size = 3 } }) => {
    return QRCODE_DOT_SIZE[dpi][size] * 1.2;
};

register('QRContent', QRContent);
export default QRContent;
