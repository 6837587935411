import innerUpsert from './innerUpsert';
import jsonPatch from '@sstdev/lib_isomorphic-json-patch';
import verifyMetaKeysPresent from './_verifyMetaKeysPresent';
import { getRelationMetadata, getTitleAlternative } from '../../metadata/index';
const { appHistory } = jsonPatch;
/**
 * Typical update/change CRUD
 * @param {object} action persist action dispatched to update a document
 */
export default database => async (payload, context) => {
    const { namespace, relation, correlationId } = context;
    const { useUpsert, newRecord, oldRecord, patches, propertiesToUnset } = payload;

    const relationMetadata = getRelationMetadata(namespace, relation);
    const storesPatchHistory = 'patchOffline' === relationMetadata.patchRelationType;
    if (useUpsert) {
        const result = await innerUpsert(database)(namespace, relation, newRecord, propertiesToUnset);
        return database.processSuccess([result.upsertedItem], payload, context);
    }

    verifyMetaKeysPresent(['modifiedBy', 'modifiedTime'], newRecord.meta, {
        operation: 'update',
        namespace,
        relation
    });
    await database.relationDb(namespace, relation).update(newRecord, propertiesToUnset);
    if (storesPatchHistory) {
        await insertToPatchCollection(patches);
    }
    return database.processSuccess([], payload, context);

    async function insertToPatchCollection(patches) {
        const history = appHistory.getPatchHistory(oldRecord, patches);
        const titleAlternative = getTitleAlternative(namespace, relation);
        const patchDoc = {
            _id: correlationId,
            [`${namespace}:${relation}`]: {
                _id: newRecord._id,
                [titleAlternative]: newRecord[titleAlternative]
            },
            patches: history,
            meta: {
                modifiedTime: newRecord.meta.modifiedTime,
                modifiedBy: newRecord.meta.modifiedBy
            }
        };
        await database.relationDb(namespace, relation + '-patch').insert(patchDoc);
    }
};
