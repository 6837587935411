import { useRef } from 'react';
import lodash from 'lodash';
const { get } = lodash;
const { isEqual } = lodash;

/**
 * Like Lodash.get, but memoizes values so that a new reference is not returned for object values that are
 * otherwise the same between renders.
 * @param {object} record - contains a value at "path"
 * @param {string} path - path to the desired value in the "record"
 * @param {any} defaultValue - value returned if nothing exists in the record at the path
 * @returns a memoized value
 */
export default function useGetMemoized(record, path, defaultValue) {
    // The correct store is returned for each call because react requires hooks ALWAYS be
    // called in the same order.
    const comparisonStore = useRef({});
    const value = get(record, path, defaultValue);
    if (typeof value === 'object') {
        const previousValue = comparisonStore.current;
        if (isEqual(value, previousValue)) {
            return previousValue;
        }
        comparisonStore.current = value;
    }
    return value;
}
