/**
 * Explicitly DISallow any records that have any locations that WE _don't_ have
 * $exist clause is exactly the oposite of the standard Filter
 *
 * Each query will look something like this:
 * ```
 * {
 *     $and: [
 *         {"dataRights.ns:rel":{"$elemMatchAll":{"_id":{"$in":["a1","a2"]}}}},
 *         {"dataRights.ns:rel":{"$size":{"$gte":1}}}
 *     ]
 * }
 * ```
 * Or, if the role has access to records without the value:
 * ```
 * {
 *     $or:[
 *       {
 *         $and: [
 *             {"dataRights.ns:rel":{"$elemMatchAll":{"_id":{"$in":["a1","a2"]}}}},
 *             {"dataRights.ns:rel":{"$size":{"$gte":1}}}
 *         ]
 *       },
 *       {"dataRights.ns:rel":{"$exists":false}},
 *       {"dataRights.ns:rel":{"$size":{"$eq":0}}}
 *     ]
 * }
 * ```
 * @param {Array<string>} paths - Array of paths to filter
 * @param {Array<{_id:string}>} filterValues - Array of values to filter by
 * @returns {object} - filter object
 */
export default function requireMatchAllFilter(paths, filterValues) {
    if (!filterValues?.length || !paths?.length) return undefined;
    // separate the actual filter values from the special case of allowing undefined values
    const { actualValues, allowUndefined } = filterValues.reduce(
        (acc, { _id }) => {
            if (_id === '0'.repeat(24)) {
                return { ...acc, allowUndefined: true };
            }
            acc.actualValues.push(_id);
            return acc;
        },
        { actualValues: [], allowUndefined: false }
    );

    const filter = paths.map(path => {
        const pathWithoutId = path.replace(/._id$/, '');

        const clause = actualValues.length
            ? {
                  $and: [
                      {
                          [pathWithoutId]: {
                              // $elemMatchAll is a custom operator that requires ALL values in the array to match the criteria.
                              // See lib_ui-services/src/database/firepants/lokiJsPrototypeOverrides.js
                              $elemMatchAll: { _id: { $in: actualValues } }
                          }
                      },
                      { [pathWithoutId]: { $size: { $gte: 1 } } }
                  ]
              }
            : {};

        if (allowUndefined) {
            if (!actualValues.length) {
                return { $or: [{ [path]: { $exists: false } }, { [pathWithoutId]: { $size: { $eq: 0 } } }] };
            }
            return { $or: [clause, { [path]: { $exists: false } }, { [pathWithoutId]: { $size: { $eq: 0 } } }] };
        }
        return clause;
    });
    // nest it in an $and if there are multiple clauses, as ALL paths must fulfil the requirement
    return filter.length === 1 ? filter[0] : { $and: filter };
}
