import innerUpsert from './innerUpsert';

/**
 * Upserts are usually only used by sync or socket requests.
 * The goal is to avoid conflict errors for updates or inserts of a document that
 * may or may not already be in the local database.
 * @param {object} action upsert action dispatched to change or create a document
 */
export default database => (payload, context) => {
    const { namespace, relation } = context;
    const { items } = payload;

    // Skip if nothing to upsert.
    if (!items || items.length === 0) return Promise.resolve();
    const initializedInnerUpsert = innerUpsert(database);
    // map all upsert results into one promise
    return Promise.allSettled(items.map(item => initializedInnerUpsert(namespace, relation, item))).then(results => {
        return database.processSuccess(results, payload, context);
    });
};
