import logging from '@sstdev/lib_logging';
import server from './getFromServer';
import pThrottle from 'p-throttle';
//We are rate limited to
//50 requests per path per minute
//300 requests across all paths per minute
//6 requests (paths) max at a single time
//so, we can sync 6 relations in parallel, with every request having to take at least 1.2 seconds

const MAX_REQUEST_PER_TIME_FRAME = 600; //corresponds to USER_RATE_LIMIT
const MAX_PATH_REQUEST_PER_TIME_FRAME = 100; //Corresponds to USER_PATH_RATE_LIMIT
const REQUEST_RATE_TIME_FRAME = 60000; //1 minute in ms
const crossRelationThrottle = pThrottle({
    //throttle at 1 per part of interval, rather than MAX_REQUEST_PER_TIME_FRAME as limit and REQUEST_RATE_TIME_FRAME as interval
    // to avoid an initial surge
    limit: 1,
    interval: REQUEST_RATE_TIME_FRAME / MAX_REQUEST_PER_TIME_FRAME
});

const _p = {
    getThrottledBatch: crossRelationThrottle(server.getBatchWithRetry),
    getPerRelationThrottle: () =>
        pThrottle({
            //throttle at 1 per part of interval, rather than MAX_REQUEST_PER_TIME_FRAME as limit and REQUEST_RATE_TIME_FRAME as interval
            // to avoid an initial surge
            limit: 1,
            interval: MAX_PATH_REQUEST_PER_TIME_FRAME / MAX_REQUEST_PER_TIME_FRAME
        })
};

export const _private = _p;
/**
 * Check for any new purgeClient requests, and purge applicable tables
 * @param {*} synchronization
 * @param {function} synchronization.getOfflineRelations
 * @param {*} database
 * @param {*} database.settings
 * @param {object} database.settings.config
 * @param {Object[]} database.settings.namespaces
 * @param {function} database.setStorageState
 * @param {function} database.getStorageState
 * @param {function} database.dropCollection
 * @param {object} payload
 */
const purgeWhatsNeeded = async (synchronization, database) => {
    //only execute this if this usecase has application:purgeClient defined
    const purgeClientRelationDefined = database.settings.namespaces.some(
        n => n.title === 'application' && n.relations.some(r => r.title === 'purgeClient')
    );
    if (!purgeClientRelationDefined) {
        return [];
    }
    logging.debug('[SYNCHRONIZATION] purgeWhatsNeeded start');
    //get purgeClient requests (if any)
    const { getStorageState, setStorageState, dropCollection } = database;
    const { _getOfflineRelations } = synchronization;
    const storageState = () => getStorageState('application', 'purgeClient') || {};

    const syncStartTime = new Date();

    const options = storageState().lastPurgeSyncTime
        ? {
              ifModifiedSince: storageState().lastPurgeSyncTime
          }
        : {};
    const clientPurges = await _p.getThrottledBatch('application', 'purgeClient', options);

    logging.debug(`[SYNCHRONIZATION] Processing ${clientPurges.length} Purge Client Requests`);

    let relationsPurged = [];
    if (clientPurges.length) {
        //if we have any purgeRequests, execute upon them
        let relationsToPurge = [];
        //if there is even only 1 request that tells us to purge everything, purge everything!
        if (clientPurges.some(r => !r.namespace || !r.relation)) {
            relationsToPurge = _getOfflineRelations(getStorageState)(database.settings.namespaces).map(r => ({
                namespace: r.namespace.title,
                relation: r.relation.title
            }));
        } else {
            //Otherwise we know ALL requests have a namespace & relation
            relationsToPurge = clientPurges;
        }
        for (const relationPurge of relationsToPurge) {
            const { namespace, relation } = relationPurge;
            const dropped = await dropCollection(namespace, relation);
            if (dropped) {
                relationsPurged = [...relationsPurged, { namespace, relation }];
                // The data is no longer there, so set the sync values appropriately
                setStorageState(namespace, relation, 'lastSyncTime', undefined);
                setStorageState(namespace, relation, 'initialSyncFinished', false);
                setStorageState(namespace, relation, 'latestModifiedTime', undefined);
                setStorageState(namespace, relation, 'earliestModifiedTime', undefined);
            }
        }
    }
    setStorageState('application', 'purgeClient', 'lastPurgeSyncTime', syncStartTime.toISOString());
    if (relationsPurged.length > 0) {
        logging.debug('[SYNCHRONIZATION] Purging completed');
    } else {
        logging.debug('[SYNCHRONIZATION] No purging required');
    }
    return relationsPurged;
};

export default { purgeWhatsNeeded };
