import styled from '../../styled';
import fromTheme from '../../fromTheme';

const MenuDivider = styled('li').attrs({ name: 'menu-divider', role: 'separator' })`
    height: 1px;
    margin: 0.5rem 0;
    background-color: ${fromTheme('gridLineColor')};
`;
MenuDivider.displayName = 'MenuDivider';
MenuDivider._reactMenu = 'MenuDivider';

export default MenuDivider;
