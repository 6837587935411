//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _doingStartup_sensor_locate from './doingStartup_sensor_locate';
import _doingStop_sensor_locate from './doingStop_sensor_locate';
export const doingStartup_sensor_locate = _doingStartup_sensor_locate;
export const doingStop_sensor_locate = _doingStop_sensor_locate;
export default {doingStartup_sensor_locate, doingStop_sensor_locate};

