import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import RouteContainer from '../RouteContainer';
import { RoutedTabs as _RoutedTabs, styled, View, fromTheme, webOnlyStyles } from 'lib_ui-primitives';

const StyledTabNav = styled(View).attrs({ name: 'styled-tab-nav' })`
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: ${fromTheme('backgroundColor')};
`;

let RoutedTabs = styled(_RoutedTabs).attrs({ name: 'routed-tabs' })`
    line-height: 24px;
    background-color: ${fromTheme('baseBackgroundColor')};
`;

RoutedTabs = webOnlyStyles(RoutedTabs)`
    cursor: pointer;
`;

/**
 * Navigation container/wrapper providing space for tabs on top, and content underneath
 * Does NOT provide navigation by itself. That is implemented in navHeading:TabNavHeading
 */
function TabNav(props) {
    // prettier-ignore
    return rc(StyledTabNav, { id: props.hNode.id },
        rc(RoutedTabs, null, props.children),
        rc(RouteContainer, {
            hNode: props.hNode,
            currentRoute: props.currentRoute
        })
    );
}
TabNav.propTypes = {
    hNode: PropTypes.object,
    children: PropTypes.array,
    currentRoute: PropTypes.string
};
export default TabNav;
