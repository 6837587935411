import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { styled, fromTheme } from 'lib_ui-primitives';
import JsonDataViewer from '../dataView/JsonViewer';
import useFormControl from '../../hooks/useFormControl';

const _JsonViewer = styled(JsonDataViewer).attrs({ name: 'json-viewer' })`
    background-color: ${fromTheme('backgroundColor')};
`;

const _p = { useFormControl };
export const _private = _p;
/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function JsonViewer(props) {
    const {
        hNode: { id }
    } = props ?? {};

    const { value, errors, title } = _p.useFormControl(props);
    // prettier-ignore
    return rc(_JsonViewer, { id, tree: value, errors, title });
}

JsonViewer.defaultProps = {};

JsonViewer.propTypes = {
    hNode: PropTypes.object.isRequired,
    currentRoute: PropTypes.string.isRequired
};

export default JsonViewer;
