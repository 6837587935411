import { memo, createElement as rc } from 'react';
import RenderDefaultRow from './RenderDefaultRow';

// Useful for debugging which items are rendered (see commented logging.debug below)
// import logging from '@sstdev/lib_logging';
// let count = 0;

const ListItemRenderer = props => {
    const { data, index, style } = props || { style: {}, data: { items: [] } };

    const {
        Row,
        RowDetail,
        items,
        selectedItem,
        highlightedIndex,
        theme,
        getItemProps,
        onClick,
        onRenderItem: onRender,
        hasHeader = false,
        listType
    } = data;
    if (hasHeader && index === 0) {
        return null;
    }
    // Most of these properties are used by the FixedSizedList to control what is rendered
    // in the list's visible area.
    const trueIndex = hasHeader ? index - 1 : index;
    const item = items[trueIndex] || {};
    const isLastItem = items.length === trueIndex;
    // Useful for debugging which items are rendered
    //logging.debug(`Rendering index ${index}|#${count} (${item.tagId})`);
    //count++;

    // react-window wants to set this to 100%, but that messes up all kinds of styling.
    delete style.width;
    let rowProps = {
        name: 'list-row',
        //id on silo versions have the format of: "2d93bd2ef44cd0a3b0efa0e811816c31e965c6ba14baf8a9c2f0e2d625b1131b-1.1.4.58"
        //dots and dashes are not valid in a query selector.
        id: `${item._id}`.replace(/\.|-/g, ''),
        key: `${item._id}`.replace(/\.|-/g, ''),
        'data-id': item._id,
        onClick: () => onClick(item),
        style: { ...style, right: listType === 'cards' ? 0 : style.right },
        'data-row-index': trueIndex
    };

    return rc(RenderDefaultRow, {
        //Nested components
        Row,
        RowDetail,
        //the actual data
        item,
        selectedItem,
        //some indexes
        index,
        highlightedIndex,
        //the oh-la-la
        theme,
        style,
        //externally defined properties
        rowProps,
        getItemProps,
        //callback methods
        onClick,
        onRender,
        isLastItem
    });
};

ListItemRenderer.displayName = 'ListItemRenderer';
export default memo(ListItemRenderer);
