import { lazy, createElement as rc, Suspense } from 'react';
import { h1 } from 'lib_ui-primitives';

// This allows the swagger dependencies to be loaded lazily, which in turn allows webpack
// to put them in a separate chunk.  This helps initial page load time because these big
// dependencies don't have to be loaded until they are needed.
export default function SwaggerProxy(props) {
    const Swagger = lazy(() => import('./SwaggerInner'));
    const fallback = rc(h1, null, 'Loading...');
    // prettier-ignore
    return rc(Suspense, fallback,
        rc(Swagger, props)
    );
}
