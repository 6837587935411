let globalChangeObservers = []; // store callbacks for global change events subscriptions.
// Often components need a very simple change observer.
// The eventSink would be overkill
export default function simpleChangeObserver(global = false) {
    // store callbacks for change events subscriptions.
    const changeObservers = global ? globalChangeObservers : [];

    const onChange = function onChange(func) {
        if (typeof func !== 'function') {
            throw new Error('simpleChangeObserver.onChange must be passed a function.');
        }
        changeObservers.push(func);
        // unsubscribe
        return () => {
            changeObservers.splice(changeObservers.indexOf(func), 1);
        };
    };

    const publishChange = function publishChange(type, entry) {
        changeObservers.forEach(func => {
            func(type, entry);
        });
    };

    return { onChange, publishChange };
}
