import asTransactionCode from '../../../../utilities/asTransactionCode';
import notHandledHere from './notHandledHere';

export default {
    verb: 'willCreate',
    type: 'transaction',
    excludedNamespaceRelations: notHandledHere,
    prerequisites: [],
    priority: -10, // run after the default willCreate rule runs
    description: 'Prepare to create the transaction record on the database',
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data }) {
    // extract changes for the relation being affected by the transaction
    // e.g. extract changes for item relation which is being affected by the item-transaction
    // relation of this transaction.
    const {
        newRecord: { $loki, entries, meta, title, _id, ...changes },
        offlineAction
    } = data;
    const transactionTitle = title || asTransactionCode(data.newRecord._id);
    // This is the new <relation>-transaction record
    const newRecord = { $loki, _id, title: transactionTitle, entries, meta };
    // These are the updates for the <relation> record
    data.changes = changes;
    // Set the newRecord passed to doingCreate for <relation>-transaction (now that the
    // changes for the <relation> affected by the transaction are removed)
    data.newRecord = newRecord;
    offlineAction.create.newRecord = newRecord;
    return data;
}
