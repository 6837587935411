import { http } from 'lib_ui-services';
import exportHelpers from '../../../CRUD/namespace/relation/exportHelpers';

export default {
    verb: 'doingView',
    namespace: 'file',
    relation: 'attachment',
    type: 'content',
    description: 'Get file from server and offer it to the browser',
    //this is the actual logic:
    logic: doingView
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 *
 * @typedef {Object} Attachment
 * @prop {string} _id unique ID
 * @prop {string} title unique ID as string to guarantee uniqueness
 * @prop {string} filename original filename
 * @prop {string} url full path to retrieve file
 * @prop {string} size file size with Size unit (e.g. 1.2MB)
 * @prop {string} type mimetype, e.g. image/jpeg
 * @prop {'base64'|string} [encoding]
 */

/**
 * @param {{
 *   data: Attachment;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {Promise}
 */
async function doingView({ data }) {
    const { filename, url, type } = data;
    if (!url) return data;
    const blob = await http.get(url);
    if (blob instanceof Blob) {
        // TODO: this is where we might want to dispatch perhaps an open_file_attachment action if the type is an image
        // and add a subscriber in the filePicker to display a dialog instead. For now: this is faster to get to results:
        // (it will open images in a new tab)
        exportHelpers.sendToFile(filename, blob, { showPreview: type?.startsWith('image') });
    } else {
        throw new Error(`Error downloading attachment ${filename}`);
    }
    return data;
}
