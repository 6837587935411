import notHandledHere from './notHandledHere';

export default {
    verb: 'didRemove',
    description: 'Trim response to clear activeRecord.',
    excludedNamespaceRelations: notHandledHere,
    //this is the actual logic:
    logic: remove
};

// This will remove the record portion of the ActiveRecord context (see ActiveRecord.js).
// Changing the context will allow observing UI elements to react accordingly.
function remove({ context }) {
    const { namespace, relation, type } = context;
    return {
        namespace,
        relation,
        type
    };
}
