import { createElement as rc, useMemo } from 'react';
import { View, styled, ScrollView, h3, fromTheme } from 'lib_ui-primitives';
import { metadata } from 'lib_ui-services';
import useAccessContext from '../useAccessContext';
import Overview from './Overview';
import ShortText from '../../ShortText';
import TextLabel from '../../TextLabel';
import Toggle from '../../Toggle';
import { getConfigurableAccess } from './getConfigurableAccess';

const _p = {
    getMetadata: () => metadata.getRawMetadata()
};
export const _private = _p;

const DetailContainer = styled(ScrollView).attrs({ name: 'AccessDetails' })`
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0 10px 10px 10px;
    background-color: ${fromTheme('backgroundColorDarker')};
`;
DetailContainer.displayName = 'AccessDetails';

// No idea why the toggle/formControl does not accept this. But it looks ugly
// to let it sprawl out over the full height of the container.
const Constrainer = styled(View).attrs({ name: 'access-toggle' })`
    flex-grow: 0;
`;
Constrainer.displayName = 'AccessToggle';

function AccessDetails(props) {
    const { allowAccessWhenUndefined, selection } = useAccessContext();

    const { titleProps, tabProps, actionProps, hasTabs, hasActions } = useMemo(() => {
        if (!selection?.id) {
            return {};
        }

        const { type, tabs, actions } = getConfigurableAccess(_p.getMetadata(), selection.id);

        let typeLabel = 'Page';
        if (type === 'pageList') {
            typeLabel = 'All Pages Under';
        } else if (type === 'tab') {
            typeLabel = 'Tab';
        } else if (type === 'menu') {
            // this should never happen here, but just in case:
            typeLabel = 'Menu Entry';
        }

        const titleProps = {
            key: selection.id,
            hNode: {
                id: selection.id,
                propertyPath: [props.hNode.propertyName, 'rights', selection.id].join('.'),
                propertyName: 'title',
                defaultValue: selection.title,
                title: typeLabel,
                readOnly: true,
                hNodeType: 'ShortText',
                treePosition: {
                    sequence: 0
                }
            }
        };

        const tabProps = tabs?.map(tab => {
            return {
                key: tab.accessTargetId,
                hNode: {
                    id: tab.accessTargetId,
                    propertyPath: [props.hNode.propertyName, 'rights', selection.id].join('.'),
                    propertyName: tab.accessTargetId,
                    defaultValue: allowAccessWhenUndefined,
                    title: `${tab.title} ${tab.readonly ? '(Always Available)' : ''}`,
                    readOnly: tab.readonly,
                    toggleValues: ['No', 'Yes'],
                    hNodeType: 'Toggle',
                    neutralOption: 'Not Configured'
                }
            };
        });
        const actionProps = actions?.map(action => {
            return {
                key: action.accessTargetId,
                hNode: {
                    id: action.accessTargetId,
                    propertyPath: [props.hNode.propertyName, 'rights', selection.id].join('.'),
                    propertyName: action.accessTargetId,
                    defaultValue: allowAccessWhenUndefined,
                    title: `${action.title} ${action.readonly ? '(Always Available)' : ''}`,
                    readOnly: action.readonly,
                    toggleValues: ['No', 'Yes'],
                    hNodeType: 'Toggle',
                    neutralOption: 'Not Configured'
                }
            };
        });

        return {
            tabProps,
            actionProps,
            titleProps,
            hasTabs: !!tabs.length,
            hasActions: !!actions.length
        };
    }, [selection, props.hNode.propertyName, allowAccessWhenUndefined]);

    if (!selection?.id) {
        return rc(Overview, props);
    }

    return rc(
        DetailContainer,
        { scrollDirection: 'vertical' },
        rc(Constrainer, {}, rc(ShortText, titleProps)),

        // Allowed Actions:
        hasActions && rc(h3, { hNode: { title: 'Allow the Following Actions:' } }, 'Allow the Following Actions:'),
        actionProps?.map(action => rc(Constrainer, { key: action.key }, rc(Toggle, action))),

        // Tabs:
        hasTabs && rc(h3, { hNode: { title: 'Display the Following Tabs:' } }, 'Display the Following Tabs:'),
        tabProps?.map(tab => rc(Constrainer, { key: tab.key }, rc(Toggle, tab))),

        !hasTabs &&
            !hasActions &&
            rc(TextLabel, { hNode: { title: 'No configurable options.' } }, 'No configurable options.')
    );
}

export default AccessDetails;
