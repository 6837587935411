// Because this is only used by lokijs dynamicViews (as opposed to a paged database get),
// This filter relies entirely on having been constructed dynamically using useDataRightsFilter
export default {
    getFilter,
    fromHNode,
    getMql
};

function fromHNode(hNode) {
    const { namespace, relation, filter } = hNode;
    return getFilter(namespace, relation, filter);
}

function getFilter(namespace, relation, filter) {
    return {
        namespace,
        relation,
        filter
    };
}

function getMql(filters) {
    if (filters.dataRights == null) return;
    return filters.dataRights.filter;
}

// Not applicable. ALL data should be retrieved from the server. Filtering will happening at display time.
// function getUriComponent(filters) {}

// This is only used in when nested in e.g. a NestedObject or NestedDetail
// Which due to its design is not applicable to this filter.
// function getJavaScriptFilter(filter) {}
