import {
    View,
    TextInput,
    Label,
    List,
    Text,
    webOnlyStyles,
    Pressable,
    fromTheme,
    nativeOnlyStyles,
    styled,
    Button
} from 'lib_ui-primitives';

export const ScanLabelAndField = styled(View).attrs({ name: 'scan-label-and-field' })`
    flex-direction: row;
`;
ScanLabelAndField.displayName = 'ScanLabelAndField';

export const RowDetailStyle = styled(Text).attrs({ name: 'row-detail' })`
    line-height: ${fromTheme('listLineHeight')};
    text-align: center;
    padding-left: ${fromTheme('textPadding')};
    white-space: nowrap;
    overflow: hidden;
    ${({ displayStyle, theme }) => {
        if (displayStyle === 'indented') {
            return `padding-left: ${theme.textPadding * 3}px;`;
        }
        if (displayStyle === 'bold') {
            return 'font-weight: bold;';
        }
        if (displayStyle === 'italic') {
            return 'font-style: italic;';
        }
    }}
    color: ${({ disabled, theme }) => {
        return disabled ? theme.disabledFontColor : theme.defaultFontColor;
    }};
`;
RowDetailStyle.displayName = 'RowDetailStyle';

/*
Make dropdown menu at least as tall as one record to so the user will
see an empty dropdown (as opposed to nothing) when there are no records.
*/
const _Menu = styled(View).attrs({ name: 'Menu' })`
    z-index: ${({ theme }) => theme.zindex.DropDownList};
    height: ${props => {
        if (!props.visible) return 0;
        if (props.theme.mobile) return props.theme.width - 60 + 'px';
        return props.itemCount < 5 ? Math.max(props.itemCount, 1) * props.theme.listLineHeight + 'px' : '200px';
    }};
    max-width: ${props => (props?.theme.mobile ? '100%' : props.theme.form.entryMinWidth + 'px')};
    width: ${props => {
        const {
            visible,
            theme: { mobile, width }
        } = props;
        return mobile && visible ? width - 12 + 'px' : '100%';
    }};
    flex-grow: ${props => (!props?.visible ? 0 : 1)};
    margin-top: ${props => (props.visible && props.theme.mobile ? '6px' : '0')};
    ${({ theme }) =>
        theme.mobile &&
        `
            margin-bottom: ${theme.viewMargin}px;
            margin-left: ${theme.viewMargin}px;
        `}
`;
export const Menu = webOnlyStyles(_Menu)`
    background-color: ${props => (props.theme.darkMode ? 'black' : 'white')};
    box-shadow: ${props => {
        if (props.visible && props.theme.mobile) {
            return 'unset';
        }
        if (props.theme.darkMode) {
            return '8px 11px 13px -2px #000000f0, 4px 23px 39px 0px #5787a9c0';
        } else {
            return '4px 4px 11px -2px #000000d0, 4px 16px 32px 4px #5787a970';
        }
    }}
`;
Menu.displayName = 'Menu';

const PreDropdownList = styled(List)`
    min-height: ${props => {
        const {
            theme: { mobile },
            itemCount
        } = props;
        return mobile ? '200px' : props.itemCount < 5 ? itemCount * 42 + 'px' : '200px';
    }};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: ${fromTheme('borderRadius')};
    border-bottom-right-radius: ${fromTheme('borderRadius')};
    background-color: ${props => (props.theme.mobile ? props.theme.baseBackgroundColorDarker : 'transparent')};
    flex-grow: 1;
    display: ${({ hide }) => (hide ? 'none' : 'flex')};
`;
export const DropdownList = webOnlyStyles(PreDropdownList)`
    height: 100%;
`;
DropdownList.displayName = 'DropdownList';

let _ListItem = styled(Pressable).attrs({ name: 'list-item', block: true, tabIndex: 0 })`
    line-height: ${fromTheme('listLineHeight')};
    text-align: left;
    border-width: 0;
    background-color: ${({ isActive, rowIndex, isSelected, theme }) => {
        if (isActive) {
            return theme.listActiveBackgroundColor;
        }
        if (isSelected) {
            return theme.listSelectedBackgroundColor;
        }
        return rowIndex % 2 === 0 ? theme.backgroundColor : theme.backgroundColorDarker;
    }};
    font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
    right: 0px;
`;

/*
    This is a desperate concession to necessity.  After hours, I just couldn't get the RN width
    to be right without using this calc. :(  Fortunately, all RN dropdownlists will display
    in a modal so I don't need to worry about the calc being wrong due to nesting for
    instance
*/
_ListItem = nativeOnlyStyles(_ListItem)`
    width: ${({ theme }) => theme.width - theme.textPadding * 2 + 'px'};
`;
// Focus outline is handled by the downshift setHighlightIndex method (which sets a style)
_ListItem = webOnlyStyles(_ListItem)`
    &:focus-visible {
        outline-width: 0;
    }
    overflow: hidden;
    cursor: pointer;
`;

export const ListItem = _ListItem;
ListItem.displayName = 'ListItem';

export const InputAndButton = styled(View).attrs({ name: 'InputAndButton' })`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    background-color: transparent;
    border-top-left-radius: ${fromTheme('borderRadius')};
    border-top-right-radius: ${fromTheme('borderRadius')};
    border-bottom-left-radius: ${props => (props.isOpen ? '0' : props.theme.borderRadius + 'px')};
    border-bottom-right-radius: ${props => (props.isOpen ? '0' : props.theme.borderRadius + 'px')};
    align-items: center;
    max-width: ${({ theme, isOpen }) => (theme.mobile && isOpen ? theme.width : theme.form.entryMaxWidth)}px;
`;
InputAndButton.displayName = 'InputAndButton';

let _ComboButton = styled(Button).attrs({ name: 'ComboButton' })`
    flex-shrink: 0;
`;
export const ComboButton = nativeOnlyStyles(_ComboButton)`
    padding: 8px;
`;
ComboButton.displayName = 'ComboButton';

export const FlexLabel = styled(Label).attrs({
    name: 'flex-label',
    displayName: 'flex-label'
})`
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;
FlexLabel.displayName = 'FlexLabel';

export const StyledTextInput = styled(TextInput)`
    width: 100%;
`;
StyledTextInput.displayName = 'StyledTextInput';

export const ComboContainer = styled(View).attrs({ name: 'combo-container' })`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    ${({ theme, isOpen }) =>
        theme.mobile &&
        isOpen &&
        `
            z-index: ${theme.zindex.DropDownComboContainer};
            background-color: ${theme.baseBackgroundColor};
            height: ${theme.height}px;
            width: ${theme.width}px;
            position: ${theme.native ? 'absolute' : 'fixed'};
            top: 0;
            left: 0;
        `}
`;
ComboContainer.displayName = 'ComboContainer';
