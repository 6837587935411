import { createElement as rc, useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Button } from 'lib_ui-primitives';
import {
    Column,
    Header,
    TopToBottom,
    LeftToRight,
    ColumnTitle,
    SubText,
    Preview,
    Cell,
    TitleCell,
    Tooltip
} from './styles';
import { default as DropDown } from '../../_abstractComponent/DropDown';

const UNMATCHED_COLUMN = { _meta: { title: 'Unmatched Column' } };
const DO_NOTHING = () => {};

const ImportColumn = props => {
    const {
        id,
        dataModel,
        field = UNMATCHED_COLUMN,
        firstRowContainsHeader,
        onChange = DO_NOTHING,
        treePosition
    } = props || {};
    const [isUnassigned, setIsUnassigned] = useState(false);
    const [isSkipped, setIsSkipped] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const [inEditMode, setEditMode] = useState(props.modelIndex === -1);
    const theme = useTheme();

    const data = getDataFromProps(props) ?? [];

    useEffect(() => {
        setIsUnassigned(!field?._meta?.id);
        setIsSkipped(field?._meta?.id === '_skip');
        setSelectedValue();
    }, [field]);

    const dropDownEntries = useMemo(() => getDropDownEntries(dataModel), [dataModel]);
    const dropDownValue = dropDownEntries.find(d => d._id === field?._meta?.id);

    // we need a place to "stage" the new selection,
    // as we don't want it to immediately start messing with the columns
    // if they accidentally selected a value
    const onDropdownChange = useCallback(selectedEntry => {
        if (selectedEntry?._id) {
            return setSelectedValue(selectedEntry._field);
        }
        setSelectedValue();
    }, []);

    const saveChanges = useCallback(() => {
        if (selectedValue) {
            onChange(selectedValue);
        }
        setEditMode(false);
    }, [onChange, selectedValue]);

    const cancelChanges = useCallback(() => {
        setSelectedValue();
        setEditMode(false);
    }, []);

    const dropDownProps = {
        hNode: {
            id: `${id}dd`,
            displayUnassignedRow: false,
            treePosition
        },
        active: false,
        setValue: onDropdownChange,
        value: dropDownValue,
        records: dropDownEntries
    };

    const spec = field?._meta || UNMATCHED_COLUMN._meta;
    const title = spec.required ? `${spec.title}*` : spec.title;
    const tooltip = spec.dataType?.tooltip;

    // prettier-ignore
    return rc(Column, null,
        // top section: Edit Mode
        inEditMode && rc(Header, null,
            rc(TopToBottom, null,
                rc(SubText, null, 'Change Field'),
                rc(DropDown, dropDownProps),
                rc(LeftToRight, null,
                    rc(Button, { value: 'CANCEL', buttonStyle: 'primary', onClick: cancelChanges }),
                    rc(Button, { value: 'SAVE', buttonStyle: 'Positive', onClick: saveChanges })
                )
            )
        ),

        // top section: NOT Edit Mode
        !inEditMode && rc(Header, null,
            rc(TopToBottom, null,
                rc(LeftToRight, {},
                    rc(ColumnTitle, { isUnassigned, isSkipped, onClick: () => setEditMode(true) }, title),
                    rc(Button, { icon: 'edit', buttonStyle: 'round', color: 'transparent', fontColor: theme.button.primary, onClick: () => setEditMode(true) }),
                    tooltip && rc(Tooltip, { title: tooltip })
                ),
                !!spec.dataType && rc(SubText, null, spec.dataType?.type || ''),
                !!spec.minLength && rc(SubText, null, `Min Length: ${spec.minLength}`),
                !!spec.maxLength && rc(SubText, null, `Max Length: ${spec.maxLength}`),
                !!spec.min && rc(SubText, null, `Min: ${spec.min}`),
                !!spec.max && rc(SubText, null, `Max: ${spec.max}`)
            )
        ),

        // Data Preview:
        rc(Preview, { isUnassigned, isSkipped },
            data.map((value, i) => {
                const isTitle = firstRowContainsHeader && (i === 0);
                return isTitle
                    ? rc(TitleCell, { key: `cell-${i}`, isSkipped }, value)
                    : rc(Cell, { key: `cell-${i}`, odd: i % 2 === 1, isSkipped }, value);
            })
        )
    );
};

/**
 *
 * @param {*} props
 * @param {Array<any>} [props.data] array containing the raw data for this column
 * @returns {Array<string>} array of formatted strings of length previewSize
 */
function getDataFromProps(props) {
    const { data = [], field, firstRowContainsHeader, previewSize = 5 } = props || {};
    const result = new Array(previewSize);
    for (let index = 0; index < previewSize; index++) {
        if (index >= data.length) {
            result[index] = NON_BREAKING_SPACE;
        } else {
            const value = data[index];
            if (firstRowContainsHeader && index <= 0) {
                result[index] = value?.toString() || NON_BREAKING_SPACE;
            } else {
                result[index] = validateAndFormat(value, field?._meta);
            }
        }
    }
    return result;
}

const NON_BREAKING_SPACE = '\xA0';

function validateAndFormat(value, field) {
    if (field && field.required && !value && (!field.dataType || field.dataType.type !== 'Boolean')) {
        return '❓'; // missing a required field value
    }

    // if it is not required, and nothing is there, don't complain.
    if (!field || (!value && (!field.dataType || !['Boolean', 'Integer'].includes(field.dataType.type)))) {
        if (value instanceof Date) {
            return value.toLocaleString();
        }
        value = (value || '').toString();
        return value && value.trim() ? value : NON_BREAKING_SPACE;
    }

    // now check the formatting
    const warning = '⚠️';

    // if there is no dataType (so just string), only need to check length
    if (!field.dataType || !field.dataType.type) {
        if (value instanceof Date) {
            return value.toLocaleString();
        }
        value = value.toString();
        value = value && value.trim() ? value.trim() : NON_BREAKING_SPACE;
        if (field.minLength && value.length < field.minLength) {
            return warning + value;
        }
        if (field.maxLength && value.length > field.maxLength) {
            return warning + value;
        }
        return value;
    }

    switch (field.dataType.type) {
        case 'Hexadecimal': {
            const isValid = !value || /^[0-9A-Fa-f]*$/g.test(value.trim());
            return isValid ? value.trim().toUpperCase() : warning + value;
        }

        case 'Currency': {
            try {
                if (!value) return NON_BREAKING_SPACE;
                const replaceable = new RegExp(`${getThousandsSeparator(value)}|£|€`, 'g');
                // `$` doesn't play nice in regexp for some reason. just do it separately
                const valueWithoutLocaleFormatting = value.toString().replace(replaceable, '').replaceAll('$', '');
                const isValid = /^[0-9]*\.?[0-9]*$/g.test(valueWithoutLocaleFormatting.trim());
                if (isValid) {
                    // `.toFixed(2)` simply cuts off at 2 digits, it doesn't round
                    // Math.round() doesn't do decimals. So use a little hack:
                    return (Math.round(Number(valueWithoutLocaleFormatting) * 100) / 100).toFixed(2);
                }
                return warning + value;
            } catch (error) {
                return warning + value;
            }
        }
        case 'Integer': {
            try {
                if (!value) return NON_BREAKING_SPACE;
                const isValid = Number(value).toString() === value.toString();
                return isValid ? value : warning + value;
            } catch (error) {
                return warning + value;
            }
        }

        case 'Date/Time': {
            try {
                if (!value) return NON_BREAKING_SPACE;
                const parsed = new Date(value);
                const stringValue = parsed.toLocaleString();
                if (stringValue === 'Invalid Date') {
                    return warning + value;
                }
                return stringValue;
            } catch (error) {
                return warning + value;
            }
        }

        case 'Email': {
            const isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(value);
            return isValid ? value : warning + value;
        }

        case 'Image':
            return value ? `<img width='50px' height='50px' src='${value}'/>` : NON_BREAKING_SPACE;

        case 'Boolean': {
            const stringValue = value?.toString()?.trim();
            if ([undefined, null, ''].includes(stringValue)) return NON_BREAKING_SPACE;

            const parsed = !/^(false|no|0)$/i.test(stringValue);
            return parsed ? 'Yes' : 'No';
        }
    }

    value = value?.toString();
    return value && value.trim() ? value : NON_BREAKING_SPACE;
}

function getDropDownEntries(dataModel = {}) {
    return [
        {
            title: 'Exclude Column',
            _id: '_skip',
            _style: 'italic',
            _field: {
                _meta: { id: '_skip', title: 'Exclude Column' }
            }
        },
        {
            title: 'Required',
            // _id: '_required',
            _style: 'bold'
        },
        ...Object.values(dataModel)
            // filter out the _skip columns from being displayed on the column selector
            .filter(d => d._meta?.required && d._meta?.id !== '_skip')
            .map(d => ({
                title: `${d._meta.title} *`,
                _id: `${d._meta.id}`,
                _field: d,
                _style: 'indented'
            })),
        {
            title: 'Optional',
            // _id: '_optional',
            _style: 'bold'
        },
        ...Object.values(dataModel)
            // filter out the _skip columns from being displayed on the column selector
            .filter(d => !d._meta?.required && d._meta?.id !== '_skip')
            .map(d => {
                return {
                    title: d._meta.title,
                    _id: `${d._meta.id}`,
                    _field: d,
                    _style: 'indented'
                };
            })
    ];
}

const getThousandsSeparator = value => {
    // ideally, look at the string, see if it is #,###.## format
    // or the non-us #.###,## format
    const stringValue = value.toString();
    const nonDigits = stringValue.replace(/\d/g, '');
    if (nonDigits.includes(',.')) {
        return ',';
    } else if (nonDigits.includes('.,')) {
        return '.';
    }
    // otherwise assume US
    return ',';
};

ImportColumn.defaultProps = {
    previewSize: 5,
    firstRowContainsHeader: true
};
ImportColumn.propTypes = {
    onChange: PropTypes.func,
    field: PropTypes.object,
    dataModel: PropTypes.array,
    previewSize: PropTypes.number,
    modelIndex: PropTypes.number,
    firstRowContainsHeader: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.object]))
};
export default ImportColumn;
