import { constants } from 'lib_ui-services';
const { useCaseIds } = constants;
import basicWillUpdate from '../../namespace/relation/willUpdate_namespace_relation';

export default {
    verb: 'willUpdate',
    namespace: 'deploy',
    relation: 'deployment',
    description: 'Update deployment',
    priority: basicWillUpdate.priority + 10, //it has be be before the standard willUpdate
    useCaseIds: [useCaseIds.ONE_TOUCH],
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data }) {
    const { newRecord } = data;
    if (!['failureNoRollback'].includes(newRecord.status)) {
        newRecord.status = 'confirmed';
    }
    return data;
}
