import { createElement as rc } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { globalConfig as getGlobalConfig } from 'lib_ui-services';
import { hooks } from 'lib_ui-primitives';
const { useRouter } = hooks;

const Auth0ProviderWithHistory = ({ children }) => {
    const { auth0 } = getGlobalConfig();
    const domain = auth0.AUTH0_DOMAIN;
    const clientId = auth0.AUTH0_CLIENT_ID;

    const { protocol, host, groupNumber, history } = useRouter();
    const groupPath = groupNumber ? `/g/${groupNumber}` : '';

    const onRedirectCallback = appState => {
        if (appState?.returnTo) {
            history.push(appState?.returnTo);
        } else {
            history.push(`${groupPath}/`);
        }
    };

    const redirectUri = `${protocol}//${host}${groupPath}/`;
    return rc(
        Auth0Provider,
        {
            domain,
            clientId,
            redirectUri,
            audience: `${protocol}//${host}/api`,
            onRedirectCallback
        },
        children
    );
};

export default Auth0ProviderWithHistory;
