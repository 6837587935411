export default {
    getFilter,
    getUriComponent,
    fromHNode
};

function fromHNode({ forceRefresh }) {
    return getFilter(forceRefresh);
}

function getFilter(forceRefresh) {
    return {
        forceRefresh
    };
}

function getUriComponent() {
    return '';
}
