import { createElement as rc, useState } from 'react';
import PropTypes from 'prop-types';
import useFormControl from '../../hooks/useFormControl';
import { TextInput, styled } from 'lib_ui-primitives';
import FormField from './FormField';
const StyledTextInput = styled(TextInput)`
    flex-grow: 1;
`;

const _p = {
    useFormControl
};
function Currency(props) {
    const {
        autoComplete = 'off',
        hNode: {
            id,
            min = Number.MIN_SAFE_INTEGER,
            max = Number.MAX_SAFE_INTEGER,
            maxLength,
            minLength,
            placeholder,
            tooltip,
            pattern,
            propertyName,
            treePosition
        },
        onBlur,
        onFocus
    } = props || { hNode: {} };

    const [active, setActive] = useState(false);
    const { title, value, setValue, disabled, errors } = _p.useFormControl(props);

    // The label should float if the field is not empty OR if the user has input 0 (which is considered empty)
    const checkIfEmpty = value => value === '' || value === 0;
    const [fieldEmpty, setFieldEmpty] = useState(() => checkIfEmpty(value));

    function onChange(value) {
        const isEmpty = checkIfEmpty(value);
        setFieldEmpty(isEmpty);
        // Parse input value, keep it as string if empty or invalid, otherwise format to number
        const newValue = isEmpty || isNaN(value) ? '' : +parseFloat(value).toFixed(2);
        setValue(newValue);
    }

    /**
     * @param {React.FocusEvent<HTMLInputElement>} event
     */
    function handleOnFocus(e) {
        setActive(true);
        e.target.select?.();
        onFocus?.(e);
    }

    function handleOnBlur(e) {
        setActive(false);
        onBlur?.(e);
    }

    // Do not show the currency symbol because it cannot be determined just by localization
    // (it would have to map navigator.language (i.e. 'en-US') to currency (i.e. 'USD') and
    // it doesn't).  If this seems important to customers, then we can maintain the mapping,
    // but it doesn't seem valuable since *most* users probably know what currency they are
    // using. In future versions of ecmascript, additional I18N tools will be available.  See
    // https://formatjs.io/docs/polyfills/intl-numberformat
    let _value = active
        ? value
        : value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });

    // If the default value is set to zero, the label will not float, and the input will be empty.
    // Zero values will ONLY be displayed if the user has explicitly entered 0.
    // We can't use the same `isDefaultValue` from Integer.js because if the default value is zero,
    // that value will be displayed, and any changes made to the record (currency related or not)
    // will be sent to the server (including the zero value).
    _value = _value === '0.00' || _value === 0 ? '' : _value;

    // prettier-ignore
    return rc(FormField, { id, title, tooltip, errors, active, fieldEmpty },
        rc(StyledTextInput, {
            id,
            name: propertyName,
            type: active ? 'number' : 'text',
            disabled,
            autoComplete,
            spellCheck: false,
            max,
            maxLength,
            min,
            minLength,
            pattern,
            placeholder,
            value: _value,
            onChange,
            onBlur: handleOnBlur,
            onFocus: handleOnFocus,
            sequence: treePosition.sequence
        })
    );
}

Currency.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};

export default Currency;
export const _private = _p;
