import { useCallback, useRef } from 'react';
import useDebounce from './useDebounce';

/**
 * This works the same as useDebounce except that it gathers all the inputs from the debounced
 * function calls and gives them as an array to the callback when it finishes debouncing.
 * @template T
 * @param {function(T[]):void} callback - debounced function
 * @param {array} dependencies - any dependencies that should result in the function being recreated on a render
 * @param {number} delay - debounce mils
 * @param {object} [options] - see the lodash.debounce options for details.
 * @param {number} [options.maxWait] - setting this allows using this as a throttle rather than a debounce
 * @param {boolean} [options.leading=false]
 * @param {boolean} [options.trailing=true]
 * @returns {function(T):void} a function that will be debounced
 */
export default function useDebounceGatheringInputs(callback, dependencies, delay = 1000, options) {
    const inputs = useRef([]);
    const clearingCallback = useCallback(
        (...args) => {
            //first things first: reset the current list so new inputs are not lost
            inputs.current = [];
            //process using the passed in list.
            callback(...args);
        },
        [callback]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounced = useDebounce(clearingCallback, dependencies, delay, options);
    return useCallback(
        (...input) => {
            if (input.length === 1) {
                inputs.current.push(input[0]);
            } else {
                inputs.current.push(input);
            }
            //pass in NOT by reference, to avoid it from getting cleared when inputs.current is cleared
            debounced([...inputs.current]);
        },
        [debounced]
    );
}
