import fetchAllPages from './fetchAllPages';

export default {
    verb: 'doingEdit',
    namespace: 'metadata',
    relation: 'useCaseDetail',
    description: 'Assure all pages are locally available',
    status: 'success',
    //type: '',
    //priority:0,
    //useCaseIds:[]
    prerequisites: fetchAllPages.prerequisites,
    //this is the actual logic:
    logic: fetchAllPages.logic,
    // error handling to avoid the progress staying on the screen:
    onError: fetchAllPages.onError
};
