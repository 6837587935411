import { useState, useEffect } from 'react';
import lodash from 'lodash';
const { get } = lodash;
const { isEqual } = lodash;
import logging from '@sstdev/lib_logging';
import useSelectedFormEntry from './useSelectedFormEntry';
import useFormContext from '../../../../hooks/useFormContext';
import { emptyDesignTemplate, selectATemplate, standardColumnDefinition } from '../constants';

const _p = {
    useFormContext,
    useSelectedFormEntry
};
export const _private = _p;
export default function useLabelTemplate(props) {
    const {
        hNode: { namespace, relation },
        children
    } = props || { hNode: {} };
    // create a "default" based on the current context
    const inTemplateDesignMode = namespace === 'print' && relation === 'template';
    // if we are on the template design page, we want to show "Please Add Label Content"
    // If we are on a print page, we want to show "Select a Template"
    const defaultTemplate = { actions: inTemplateDesignMode ? emptyDesignTemplate : selectATemplate };

    // start with the default template
    const [template, setTemplate] = useState(defaultTemplate);
    // now, if we are in the template design mode, we want to use the "newRecord" from the form, which is what the user is currently creating
    const { newRecord = {} } = _p.useFormContext();
    // if we are on a print page, we need to get the selection from the dropdown
    // Note: useSelectedFormEntry will fetch the actual record based on the dropdown selection.
    const dropdownSelection = _p.useSelectedFormEntry({ namespace: 'print', relation: 'template' }, defaultTemplate);

    //if either definitions on mappings change, merge, and generate a new template
    useEffect(() => {
        // templates reference fields by index. the blockly contains a list of fields with property names
        // fetch the property names so we can merge those into the template instead of the indexes
        const properties = getFieldProperties(children);

        // if we are on a print page, newRecord = {}, so the dropdown selection is used
        // otherwise we just ignore that.
        let newTemplate = newRecord?.actions ? newRecord : dropdownSelection;

        newTemplate.actions = merge(newTemplate.actions, properties);

        if (!isEqual(newTemplate.actionsWithProperties, template.actions)) {
            setTemplate(newTemplate);
        }
    }, [template, dropdownSelection, newRecord, children]);
    return { template, inTemplateDesignMode };
}

function getFieldProperties(children) {
    //get the property mappings (from blockly)
    const childrenOrDefault = children?.length ? children : standardColumnDefinition;
    const barcodeProperty = get(
        //barcode _should_ be at index 1
        childrenOrDefault.find(col => get(col, 'props.hNode.hNodeType', '') === 'Barcode'),
        'props.hNode.propertyName'
    );
    const additionalProperties = childrenOrDefault
        .filter(col => get(col, 'props.hNode.hNodeType', '') !== 'Barcode')
        .map(col => get(col, 'props.hNode.propertyName'));

    return [barcodeProperty, ...additionalProperties];
}

export function merge(labelContent, properties) {
    return labelContent.map((line, index) => {
        if (typeof line.fixedText !== 'undefined' || line.workflowAction === 'update_print_template') {
            //Fixed Text lines are passed as normal
            return {
                ...line,
                hNodeType: 'FixedTextContent'
            };
        }
        if (typeof line.alignTop !== 'undefined' || line.workflowAction === 'update_sensor_tag') {
            return {
                ...line,
                hNodeType: 'RfidContent',
                propertyName: 'tagId',
                alternatePropertyName: properties[0]
            };
        }
        if (typeof line['print:field'] !== 'undefined' || line.workflowAction === 'update_print_field') {
            if (!line['print:field']) {
                //they still need to configure it.
                //Default to field 2 (usually description)
                return {
                    ...line,
                    hNodeType: 'ReferencedContent',
                    propertyName: properties[1] || 'title'
                };
            }
            const [fieldNum] = line['print:field'].title.split(',');
            if (isNaN(fieldNum)) {
                logging.error(
                    `print:field of line ${index} is set to ${line['print:field'].title}, which does not have a numeric title.`
                );
                return {
                    hNodeType: 'FixedTextContent',
                    isError: true,
                    fixedText: 'ERROR IN CONFIGURATION'
                };
            }
            const fieldIndex = Number(fieldNum) - 1; // fields are 1-based for ease of end user.
            if (fieldIndex < 0 || fieldIndex >= properties.length) {
                //the user chose a field index that is not provided as data for this relation
                //which means we'll always just print out a (fixed) blank line.
                return {
                    hNodeType: 'FixedTextContent',
                    fixedText: ' '
                };
            }
            return {
                ...line,
                hNodeType: 'ReferencedContent',
                propertyName: properties[fieldIndex] || 'title'
            };
        }
        if (typeof line.alignRight !== 'undefined' || line.workflowAction === 'update_print_printer') {
            return {
                alignRight: false,
                ...line,
                hNodeType: 'QRContent',
                propertyName: properties[0]
            };
        }
        //only option left is barcode
        return {
            ...line,
            hNodeType: 'BarcodeContent',
            propertyName: properties[0]
        };
    });
}
