import conversions from '@sstdev/lib_epc-conversions';

/**
 * @param {*} hNode
 * @returns{(value: string) => string}
 */
// eslint-disable-next-line no-unused-vars
const toAsciiFromHex = hNode => value => conversions.ascii.fromHex(value.toString());

export default toAsciiFromHex;
