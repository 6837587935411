import { createElement as rc, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { metadata } from 'lib_ui-services';
import { default as _DropDown } from '../_abstractComponent/DropDown';
import useEventSink from '../../hooks/useEventSink';
import useSearchElement from './useSearchElement';
import useActiveComponentAwareness from '../../hooks/useActiveComponentAwareness';

const defaultSort = [
    {
        id: '5a624d17c1885033a496b31f',
        hNodeType: 'OrderBy',
        hNodeTypeGroup: 'filter',
        propertyName: 'title',
        direction: 'ascending'
    }
];

const BEGINNING_OF_STRING_CHAR = '^';

/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function DropDownSearch(props) {
    const {
        hNode,
        hNode: {
            title,
            foreignNamespace,
            foreignRelation,
            children = defaultSort,
            dropdownDisplayProperties,
            propertyName
        }
    } = props || { hNode: {} };
    const [, publish] = useEventSink();
    const { active, onBlur, onFocus } = useActiveComponentAwareness();

    //This is specific for dropdowns, to support cascading dropdowns.
    const changeAndNotify = useCallback(
        setValue => {
            return value => {
                setValue(value);
                publish(
                    { value },
                    {
                        verb: 'select',
                        namespace: foreignNamespace,
                        relation: foreignRelation,
                        status: 'success'
                    }
                );
            };
        },
        [foreignNamespace, foreignRelation, publish]
    );

    //How a selected value is translated into a filter depends on what the search element captures
    const valueToFilter = useCallback(
        value => {
            if (value != null && value._id != null) {
                if (value._id === 'hint-regex') {
                    const prop = (dropdownDisplayProperties || [propertyName])[0];
                    const path = metadata.getPathToProperty(hNode) + `.${prop}`;
                    const startsWithRegex = BEGINNING_OF_STRING_CHAR + value.value.replaceAll('*', '.*');
                    const filter = { [path]: { $regex: [startsWithRegex, 'i'] } };
                    return filter;
                } else {
                    const path = metadata.getPathToProperty(hNode) + '._id';
                    const filter = { [path]: { $eq: value._id } };
                    return filter;
                }
            }
            return {};
        },
        [dropdownDisplayProperties, hNode, propertyName]
    );

    const { value, setValue } = useSearchElement(props, valueToFilter);

    const hNodeWithDefaultOrder = useMemo(() => ({ ...hNode, children }), [hNode, children]);

    // prettier-ignore
    return rc(_DropDown, {
        ...props,
        active,
        onBlur,
        onFocus,
        hNode: hNodeWithDefaultOrder,
        value,
        setValue: changeAndNotify(setValue),
        title,
    });
}

DropDownSearch.defaultProps = {
    disabled: false
};

DropDownSearch.propTypes = {
    hNode: PropTypes.object.isRequired,
    currentRoute: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

export default DropDownSearch;
