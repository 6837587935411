import React from 'react';
import PropTypes from 'prop-types';
import { View, styled } from 'lib_ui-primitives';
const { createElement: ce } = React;

/**
 * flex-grow will allow this to take more space if it is available. flex-basis of 1
 * means it will start out with the smallest proportion of space compared to its peers
 * (assuming they have the default flex-basis = auto).
 * This allows the peers to take up as much room as they need to actually get their
 * controls on the screen, but no more.
 */
const isGreedy = props => props?.hNode?.greedySection === props.section;
let Section = styled(View).attrs(props => ({ ...props, ['data-test-id']: props.id }))`
    flex-grow: ${props => (isGreedy(props) ? 1 : 0)};
    flex-basis: ${props => (isGreedy(props) ? '1' : 'auto')};
`;
Section.displayName = 'Section';

const GreedyContainer = styled(View)`
    flex-direction: ${props => (props.hNode.flexDirection === 'left2right' ? 'row' : 'column')};
    flex-grow: 1;
`;
GreedyContainer.displayName = 'GreedyContainer';

function GreedySectionLayout(props) {
    const {
        hNode,
        hNode: { id },
        children
    } = props || { hNode: {}, children: [] };
    const allowScroll = /true/i.test(hNode.allowScroll);
    const firstChildren = getFirstChildren(children, hNode);
    const middleChildren = getMiddleChildren(children, hNode);
    const lastChildren = getLastChildren(children, hNode);

    // prettier-ignore
    return ce(GreedyContainer, props,
        ce(Section, { section: 'FIRST', allowScroll, ...props, id: `${id}-first`, name: 'section-first' }, firstChildren),
        ce(Section, { section: 'MIDDLE', allowScroll, ...props, id: `${id}-middle`, name: 'section-middle' }, middleChildren),
        ce(Section, { section: 'LAST', allowScroll, ...props, id: `${id}-last`, name: 'section-last' }, lastChildren)
    );
}

GreedySectionLayout.propTypes = {
    children: PropTypes.array,
    hNode: PropTypes.object
};

export default GreedySectionLayout;

function getFirstChildren(children, hNode) {
    let { firstSection = [] } = hNode;
    return children.filter(c => firstSection.includes(c.props.hNode.id));
}

function getMiddleChildren(children, hNode) {
    let { middleSection = [] } = hNode;
    return children.filter(c => middleSection.includes(c.props.hNode.id));
}

function getLastChildren(children, hNode) {
    let { lastSection = [] } = hNode;
    return children.filter(c => lastSection.includes(c.props.hNode.id));
}
