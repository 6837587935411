export default {
    getFilter,
    fromHNode,
    getUriComponent,
    pageResetRequired: true
};

/*
    this is consumed by the FilterInterdependencyBoundary component because it is sensitive to where the
    component resides in the component hierarchy
*/
function fromHNode(hNode) {
    return {
        id: hNode.id,
        namespace: hNode.namespace,
        relation: hNode.relation
    };
}

function getFilter() {
    throw new Error('not implemented');
}

function getUriComponent() {
    throw new Error('not implemented');
}
