import lodash from 'lodash';
const { get, camelCase, isEqual } = lodash;

import getFilter from '../filterFactory';

function getDefaultFilters(hNode) {
    let filterHNodes = get(hNode, 'children', []).filter(
        // PatchDetail is a column, but if it is included, that means we need to do additional processing/filtering
        c => c.hNodeTypeGroup === 'filter' || c.hNodeType === 'PatchDetail'
    );
    return convertHNodeToFilters(filterHNodes);
}

function convertHNodeToFilters(hNodes) {
    let filters = {};
    hNodes.forEach(hNode => {
        let camelCased = camelCase(hNode.hNodeType);
        // Allow filters to be determined by metadata.
        /*eslint import/namespace: ['error', { allowComputed: true }]*/
        let filter = getFilter(camelCased);
        if (!filter) {
            throw new Error(`Unknown filter type: ${hNode.hNodeType}`);
        }
        filters[camelCased] = filter.fromHNode(hNode);
    });
    return filters;
}

/**
 *
 * @param {object} filter
 * @param {string} [filter.propertyName] Property Name or dot separated property path, might be absent if op is $and or $or
 * @param {string} filter.op Mongo style operator. See below for supported values
 * @param {string|Array<string>} filter.value value(s) to match against
 * @returns
 */
function basicFilterToJavaScript(filter) {
    const { propertyName, op, value } = filter;
    // the actual function that is applied to each record:
    return record => {
        if (propertyName?.includes('.')) {
            return applyRecursively(record, propertyName.split('.'), op, value);
        }
        //figure out how to perform the comparison
        let comparator = () => false;
        let defaultValue;
        switch (op) {
            case '$eq':
                comparator = recordValue => {
                    if (recordValue?._id && value?._id) {
                        return isEqual(recordValue._id, value._id);
                    }
                    return isEqual(recordValue, value);
                };
                break;
            case '$ne':
            case '$neq':
                comparator = recordValue => {
                    if (recordValue?._id && value?._id) {
                        return !isEqual(recordValue._id, value._id);
                    }
                    return !isEqual(recordValue, value);
                };
                break;
            case '$contains': {
                // Can't use the comparator function here, because we don't want the standard `recordValue.some()` behavior
                const recordValues = record?.[propertyName] ?? '';
                if (Array.isArray(value)) {
                    // based on loki.js implementation of $contains
                    // if value is an array, then the recordValue must contain all elements of the value array
                    return value.every(v => recordValues.includes(v));
                }
                return recordValues.includes(value);
            }
            case '$startsWith':
                comparator = recordValue => recordValue.startsWith(value);
                defaultValue = '';
                break;
            case '$gt':
                comparator = recordValue => recordValue > value;
                break;
            case '$gte':
                comparator = recordValue => recordValue >= value;
                break;
            case '$lte':
                comparator = recordValue => recordValue <= value;
                break;
            case '$lt':
                comparator = recordValue => recordValue < value;
                break;
            case '$in':
                comparator = recordValue => value.includes(recordValue);
                break;
            case '$nin':
                comparator = recordValue => !value.includes(recordValue);
                break;
            case '$exists':
                comparator = recordValue => {
                    if (value) return recordValue != null;
                    return recordValue == null;
                };
                break;
            case '$elemMatch': {
                const recordValues = record?.[propertyName];
                if (!Array.isArray(recordValues)) return false;
                // elemMatch returns true if at least one element in the array matches the subfilter
                const subFilter = criteriaToJavaScript(value);
                return recordValues.some(subFilter);
            }
            case '$elemMatchAll': {
                const recordValues = record?.[propertyName];
                if (!Array.isArray(recordValues)) return false;
                // elemMatchAll returns true if all elements in the array matches the subfilter
                const subFilter = criteriaToJavaScript(value);
                return recordValues.every(subFilter);
            }
            case '$size': {
                const recordValues = record?.[propertyName];
                if (recordValues == null || !Array.isArray(recordValues)) return false;

                const sizeFilter = criteriaToJavaScript({ size: value });
                return sizeFilter({ size: recordValues?.length });
            }
            case '$not': {
                const subFilter = criteriaToJavaScript({ [propertyName]: value });
                return !subFilter(record);
            }
            case '$and':
                return value.map(criteriaToJavaScript).every(subFilter => subFilter(record));
            case '$or':
                return value.map(criteriaToJavaScript).some(subFilter => subFilter(record));
            default:
                throw new Error(`Unsupported operator: ${op}`);
        }

        // do the actual comparison:
        const recordValue = record?.[propertyName] ?? defaultValue;
        if (Array.isArray(recordValue)) {
            return recordValue.some(comparator);
        }
        return comparator(recordValue);
    };
}

function criteriaToJavaScript(criteria = {}) {
    if (Array.isArray(criteria)) {
        return record => criteria.every(crit => criteriaToJavaScript(crit)(record));
    }

    const filters = Object.entries(criteria)
        .flatMap(([key, value]) => {
            if (['$or', '$and', '$not'].includes(key)) {
                // e.g. $and or $or
                return { op: key, value };
            } else {
                return Object.entries(value).map(([op, value]) => ({
                    propertyName: key,
                    op: op.replace('_', '$'),
                    value
                }));
            }
        })
        .filter(Boolean);

    return record => {
        return filters.every(part => {
            // curried call, because in many actual filters, basicFilterToJavaScript is called directly
            // and the resulting function is then used separately:
            const result = basicFilterToJavaScript(part)(record);
            // helpful for debugging:
            // if (result) {
            //     console.debug(record, ' matched ', part);
            // }
            return result;
        });
    };
}

/**
 *
 * @param {object} [record]
 * @param {Array<string} propertyPath
 * @param {string} op e.g. $eq, $ne, $gt, $gte, $lt, $lte, $in, $nin, $exists, $elemMatch, $elemMatchAll
 * @param {any} value
 * @returns {boolean}
 */
function applyRecursively(record, [propertyName, ...path], op, value) {
    if (path.length) {
        const propertyValue = record?.[propertyName];
        if (propertyValue == null) {
            // if the propertyValue is undefined, we don't need to walk down the tree: it will always be undefined
            // Shortcut. ONLY the $exists:false filter would return true, EVERYTHING ELSE should return false if the value is undefined
            if (op === '$exists') return value === false;
            return false;
        }
        if (Array.isArray(propertyValue)) {
            // default loki/mongo implementation, if you include an array in the path,
            // it will return true if ANY of the elements in the array match the filter
            // This is the same as $elemMatch does.
            // Use elemMatchAll if you need all the elements in the array to match the filter.
            return propertyValue.some(subRecord => applyRecursively(subRecord, path, op, value));
        }
        return applyRecursively(propertyValue, path, op, value);
    }
    return basicFilterToJavaScript({ propertyName, op, value })(record);
}

export default {
    getDefaultFilters,
    basicFilterToJavaScript,
    criteriaToJavaScript
};
