import { createElement as rc, useCallback, useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import styled from '../styled';
import webOnlyStyles from '../webOnlyStyles';
import fromTheme from '../fromTheme';
import Icon from './Icon';
import testProperties from '../testProperties';

let Label = styled.label`
    position: relative;
    width: ${fromTheme('iconSize')};
    height: ${fromTheme('iconSize')};
`;
Label = webOnlyStyles(Label)`
    display: inline-block;
`;

const Input = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
`;

let Dot = styled(Icon)``;

Dot = webOnlyStyles(Dot)`
    &:hover {
        color: ${fromTheme('button', 'grayHighlight')};
    }
    cursor: pointer;
`;

const STATES = {
    checked: 'radio_button_checked',
    unchecked: 'radio_button_unchecked',
    indeterminate: 'radio_button_unchecked',
    fromValue: value => (value === null ? STATES.indeterminate : value ? STATES.checked : STATES.unchecked)
};

export default function RadioButton(props) {
    const theme = useContext(ThemeContext);

    const {
        id,
        value,
        onClick,
        className,
        disabled,
        // "name" is what groups radio buttons.
        // Buttons with the same name, regardless of anything else, will "automatically" work as a group (single select)
        name,
        onBlur,
        onFocus,
        color = 'transparent',
        fontColor: baseFontColor = theme.button.gray,
        checkedColor = theme.button.primary
    } = props;
    const [checkedState, setChecked] = useState(STATES.fromValue(value));
    useEffect(() => {
        if (checkedState !== STATES.fromValue(value)) setChecked(STATES.fromValue(value));
    }, [checkedState, value]);

    const onChange = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            if (disabled) return;
            // radio buttons don't typically have an "uncheck" event
            // Which actually suites us well in the logic around saved filters
            // If we DO need an "uncheck" event, see CheckBox.js
            setChecked(STATES.checked);
            onClick(true);
        },
        [onClick, disabled]
    );
    const fontColor = checkedState === STATES.checked ? checkedColor : baseFontColor;
    // prettier-ignore
    return rc(Label, { disabled, className, onClick: onChange, ...testProperties(props) },
        // if we set `checked` to null, it doesn't change the checked state.
        // Also, when clicking on an "null" state switch, we want to be sure it always goes to "checked"
        // So, we explicitly need to treat "null" as false
        rc(Input, { name, type: 'radio', value:id, onChange, disabled, onBlur, onFocus }),
        rc(Dot, { disabled, color, fontColor, title: 'Select' }, checkedState)
    );
}
