export default {
    verb: 'willNavigate',
    namespace: 'application',
    relation: 'route',
    description: 'Log route change requests.  Also reset sensor reads between routes',
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @template T
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 */
async function logic({ data, context, log, dispatch, error }) {
    // If there is no user then this is probably before the login and
    // resetting sensors will not have a subscriber.
    // Because reads are recorded for manual entries as well as sensors,
    // reset them even if there are no sensors registered.
    if (context.user != null && Object.keys(context.user).length > 0) {
        try {
            log.debug(
                `[NAVIGATION] Reseting sensor reads for route change: ${data.navigate?.router?.location?.pathname}`,
                context
            );
            await dispatch(
                { resetPower: true, sensorType: 'RFID' },
                { verb: 'reset', namespace: 'sensor', relation: 'read' },
                true
            );
        } catch (err) {
            // No handlers for reset are available yet.
            if (err?.message?.includes('No handlers registered for processing')) {
                log.info(err.message, context);
            } else if (err?.message?.includes('No response received for')) {
                // timeout
                log.info(err.message, context);
            } else {
                log.error(err, context);
            }
        }
    }
    if (error) {
        log.error(error, context);
    } else {
        log.debug(`[NAVIGATION] Beginning navigation to ${data.navigate?.router?.location?.pathname}`, context);
    }
}
