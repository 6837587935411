import { helpers } from '@sstdev/lib_metadata-config';
import { metadata, localStorage } from 'lib_ui-services';
import { useEffect, useState, useMemo } from 'react';
import lodash from 'lodash';
const { isEqual } = lodash;
import useEventSink from './useEventSink';
import useAssembleRecords from '../components/_abstractComponent/DropDown/useAssembleRecords';

// the values set here on localStorage using these keys, are consumed in `lib_ui-core\src\utilities\setDefaultValuesIfNecessary.js`
const PREVIOUS_SELECTIONS_KEY = 'PreviousHNodeSelections';
const PRESELECTED_KEY = 'DefaultHNodeSelections';
const _p = {
    localStorage,
    useAssembleRecords
};

// eslint-disable-next-line no-undef
if (__UNIT_TESTING__) {
    _p.useAssembleRecords = () => ({});
}
export const _private = _p;
export default function useDefaultValueForHNode(hNode, overrideDefaultValue) {
    // Most of the time, the returned default value will be the one set in the default of this useState.
    // The rest of this code is pretty much either for the `DefaultToPreviousSelection` filter, or the "DefaultValue" filter.
    const [defaultValue, setDefaultValue] = useState(
        overrideDefaultValue || hNode.defaultValue || helpers.getDefaultValueForHNode(hNode)
    );

    const [subscribe] = useEventSink();

    // =======  DefaultToPreviousSelection ===
    const DefaultToPreviousSelection = useMemo(
        () => metadata.findHNode(hNode.children ?? [], 'DefaultToPreviousSelection'),
        [hNode]
    );
    const [defaultReady, setDefaultReady] = useState(DefaultToPreviousSelection == null);

    // This useEffect sets the value *used* by the `DefaultToPreviousSelection` filter by listening
    // for a change to the hNode component that holds the filter (e.g. a dropdown).
    useEffect(() => {
        // This allows the contained code to be ignored if there is a defaultValue on the hNode itself
        // or if we don't have a `DefaultToPreviousSelection` filter
        if (typeof hNode.defaultValue !== 'undefined' || !DefaultToPreviousSelection) return;

        let unsubscribe;
        let allowStateUpdate = true;
        async function asyncOp() {
            unsubscribe = subscribe({ verb: 'controlChange', hNodeId: hNode.id }, async data => {
                const { id } = DefaultToPreviousSelection;
                if (!data.newValue || data.newValue?.isDefaultRecord) {
                    // Remove saved default value.
                    await _p.localStorage.deleteKey(PREVIOUS_SELECTIONS_KEY + id);
                    // Revert default to the standard defaults (instead of DefaultToPreviousSelection)
                    if (allowStateUpdate) {
                        setDefaultValue(hNode.defaultValue || helpers.getDefaultValueForHNode(hNode));
                    }
                } else {
                    await _p.localStorage.setKey(PREVIOUS_SELECTIONS_KEY + id, data.newValue);
                    if (allowStateUpdate) {
                        setDefaultValue(data.newValue);
                    }
                }
            });
        }
        asyncOp();
        return () => {
            allowStateUpdate = false;
            unsubscribe?.();
        };
    }, [hNode, DefaultToPreviousSelection, subscribe]);

    // This useEffect looks up a value previously stored for `DefaultToPreviousSelection` and makes
    // that the new defaultValue
    useEffect(() => {
        // This allows the contained code to be ignored if there is a defaultValue on the hNode itself
        // or if we don't have a `DefaultToPreviousSelection` filter
        if (typeof hNode.defaultValue !== 'undefined' || !DefaultToPreviousSelection) return;

        let allowStateUpdate = true;
        async function asyncOp() {
            const { id } = DefaultToPreviousSelection;
            const previousSelection = (await _p.localStorage.getKey(PREVIOUS_SELECTIONS_KEY + id)) ?? {};
            // Avoid a change after the hook is unmounted and avoid an unnecessary change to state
            // because of a reference change to the same value.
            if (allowStateUpdate) {
                if (!isEqual(defaultValue, previousSelection)) {
                    setDefaultValue(previousSelection);
                }
                setDefaultReady(true);
            }
        }
        asyncOp();
        return () => (allowStateUpdate = false);
    }, [hNode, defaultValue, DefaultToPreviousSelection]);

    // =======  DefaultValue  ===
    const DefaultValueFilter = useMemo(() => metadata.findHNode(hNode.children ?? [], 'DefaultValue'), [hNode]);
    const [defaultValueFilterReady, setDefaultValueFilterReady] = useState(DefaultValueFilter == null);
    const [isSettingDefaultValue, setIsSettingDefaultValue] = useState(false);

    const { propertyName, defaultSelectedValue } = DefaultValueFilter || {};

    // we only need to lookup records if we DON'T have a defaultValue on the hNode itself
    // AND if there IS a defaultValue filter defined, AND it is for a dropdown
    const needRecords =
        typeof hNode.defaultValue === 'undefined' && hNode.hNodeType === 'DropDownWithFilter' && !!DefaultValueFilter;

    const { records = [] } = _p.useAssembleRecords(hNode, '', null, needRecords);

    // By calling "toString()" on the record's property, we can support boolean properties as well, like `isDefault`.
    const preselectedValue = records.find(
        lo => lo[propertyName]?.toString().toLowerCase() === defaultSelectedValue.toString().toLowerCase()
    );

    // This useEffect sets the value *used* by the `DefaultValue` filter by listening for a change to the hNode
    useEffect(() => {
        if (typeof hNode.defaultValue !== 'undefined' || !DefaultValueFilter) return;

        const unsubscribe = subscribe({ verb: 'controlChange', hNodeId: hNode.id }, data => {
            if (data?.newValue) {
                const { id } = DefaultValueFilter;
                _p.localStorage.setKey(PRESELECTED_KEY + id, data.newValue);
                // Prevent the useEffect from setting the default value again
                setIsSettingDefaultValue(true);
                setDefaultValue(data.newValue);
            }
        });

        return () => {
            setIsSettingDefaultValue(false);
            unsubscribe?.();
        };
    }, [hNode, DefaultValueFilter, subscribe]);

    // This useEffect looks up a value matching the defaultValueFilter and makes that the new defaultValue
    useEffect(() => {
        // This allows the contained code to be ignored if there is a defaultValue on the hNode itself
        // or if we don't have a `DefaultValue` filter
        // or if we are currently setting the default value
        if (typeof hNode.defaultValue !== 'undefined' || !preselectedValue || isSettingDefaultValue) {
            return setDefaultValueFilterReady(true);
        }

        async function asyncOp() {
            // Avoid a change after the hook is unmounted and avoid an unnecessary change to state
            // because of a reference change to the same value.
            if (!isEqual(defaultValue, preselectedValue)) {
                const { id } = DefaultValueFilter;
                await _p.localStorage.setKey(PRESELECTED_KEY + id, preselectedValue);
                setDefaultValue(preselectedValue);
            }
            setDefaultValueFilterReady(true);
        }
        asyncOp();
        return () => {};
    }, [hNode, defaultValue, preselectedValue, isSettingDefaultValue, DefaultValueFilter]);
    return { defaultValue, defaultReady, defaultValueFilterReady };
}
