//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _captionOverrides from './captionOverrides';
import _dataDictionary from './dataDictionary';
export const captionOverrides = _captionOverrides;
export const dataDictionary = _dataDictionary;
export default {captionOverrides, dataDictionary};

