import lodash from 'lodash';
const { omit } = lodash;
import notHandledHere from './notHandledHere';
import { metadata, ObjectId } from 'lib_ui-services';

export default {
    verb: 'doingCopy',
    description: 'Copy a record from a previously existing record of the same namespace/relation.',
    excludedNamespaceRelations: notHandledHere,
    //this is the actual logic:
    logic: copy
};

function copy({ data, context }) {
    const { namespace, relation, type } = context;
    const keysToOmit = metadata.getCopyKeysToOmit(namespace, relation);
    let record = { ...omit(data.record, keysToOmit), _id: new ObjectId().toString() };
    record = Object.entries(record).reduce((result, [key, value]) => {
        if (value !== '') {
            return { ...result, [key]: value };
        }
        return result;
    }, {});

    return {
        activationVerb: 'new',
        record,
        namespace,
        relation,
        type,
        isNew: true
    };
}
