import { database } from 'lib_ui-services';

export default {
    verb: 'doingPurge',
    namespace: 'application',
    relation: 'useCase',
    description: 'Remove the requested record from the database',
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data, context }) {
    const db = database.get();
    let result;
    if (context.type === 'all') {
        result = await db.deleteAllDatabases(data, context);
    } else {
        throw new Error('Purge application useCase must always be called with a context type of all.');
    }
    return { ...data, result };
}
