import notificationTypes from '../constants/notificationTypes';

export default eventSink => store => next => action => {
    // avoid crashing on Redux init actions
    if (!store || !next) {
        return null;
    }

    if (action != null) {
        if (action.type === 'Offline/BUSY') {
            /*
                "type": "Offline/BUSY",
                "payload": {
                    "busy": true
                }
            */
            const [, publish] = eventSink;
            publish(
                {
                    busy: action.payload.busy,
                    source: 'httpQueue',
                    message: 'offlineResilientCommunication Change',
                    type: notificationTypes.SYNC_BUSY
                },
                { verb: 'pop', namespace: 'application', relation: 'notification' }
            );
            // For Debugging:
            //console.log('[REDUXOFFLINE] ', JSON.stringify(action, null, 3));
        }
        return next(action);
    }
    next();
};
