//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _BarcodeContent from './BarcodeContent';
import _FixedTextContent from './FixedTextContent';
import _QRContent from './QRContent';
import _ReferencedContent from './ReferencedContent';
import _RfidContent from './RfidContent';
export const BarcodeContent = _BarcodeContent;
export const FixedTextContent = _FixedTextContent;
export const QRContent = _QRContent;
export const ReferencedContent = _ReferencedContent;
export const RfidContent = _RfidContent;
export default {BarcodeContent, FixedTextContent, QRContent, ReferencedContent, RfidContent};

