import getQueryUrl from '../http/getQueryUrl';
import http from '../http';
import database from '../database';
import paged from '../filters/paged';

const _p = {
    http,
    database
};
export const _private = _p;

/**
 * Provides a way to get server side data while online and store it temporarily
 * in the corresponding client side table.
 * It provides a cleanup function to remove the data.
 * It pulls a maximum of 1000 records
 * @param {object} dbView
 * @param {string} queryId - some unique identifier to tag temporary query results in the database
 * @returns {Promise<function>} - a cleanup function which will remove the temporary query results from the database
 */
export default async function fetchAndUpsertTemporaryQueryResult(dbView, queryId) {
    if (queryId == null) {
        throw new Error('A queryId must be included when calling fetchAndUpsertTemporaryQueryResult');
    }
    // Avoids 'Cannot access 'database' before initialization' error for unit tests
    _p.database = _p.database ?? database;

    const { namespace, relation, viewCriteria } = dbView;
    const filters = viewCriteria.getFiltersFromView();
    // paged is not an MQL filter implemented on the client side, so it won't be stored in the
    // loki dynamicview filterPipeline (and therefore won't be returned by getFiltersFromView
    // above)
    filters.paged = paged.getFilter(dbView.page, undefined, dbView.serverSideOffset ?? 0);
    // Get the query results from the server side
    const queryUrl = getQueryUrl(namespace, relation, filters);
    const httpResult = await _p.http.get(queryUrl);
    const records = httpResult?.items ?? [];

    if (!records || records.length === 0) return { result: records };

    // Mark each inserted record with the queryId so it can be cleaned up later.
    // Previous record metadata is merged when an update occurs, so this marker should survive
    // even if an update occurs (e.g. new filter results in same record being upserted again.)
    const mergeIntoInserts = { meta: { forTempQueryResult: queryId } };
    // get a reference to the local database and upsert the records
    const db = _p.database.get();
    await db.bulkUpsert(namespace, relation, records, mergeIntoInserts);
    // Provide cleanup function
    return {
        cleanup: () =>
            db.removeMany(
                { trueDelete: true, criteria: { 'meta.forTempQueryResult': queryId } },
                { namespace, relation }
            ),
        result: records
    };
}
