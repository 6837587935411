import lodash from 'lodash';
const { get } = lodash;
import IndexTools from './indexTools';
import { getTitleAlternative } from '../../metadata';
export default _private => {
    const indexTools = IndexTools(_private);
    const { addLexicalIndex, getSafeIndexPath } = indexTools;
    const { collection, namespace, relation } = _private;
    const titleAlternative = getTitleAlternative(namespace, relation);
    return function findPaged(
        pageSize = 50,
        indexTitle = titleAlternative,
        descending = false,
        startKey,
        reducer,
        caseSensitive = false
    ) {
        // Get the index path for this index title;
        let indexPath = getSafeIndexPath(indexTitle);

        return addLexicalIndex(indexTitle, caseSensitive).then(
            () =>
                new Promise((resolveOuter, rejectOuter) => {
                    try {
                        let page = collection.chain();
                        if (startKey) {
                            if (descending) {
                                page = page.find({ [indexPath]: { $lte: startKey } });
                            } else {
                                page = page.find({ [indexPath]: { $gte: startKey } });
                            }
                        }
                        if (reducer) {
                            page = page.where(reducer);
                        }
                        page = page.simplesort(indexPath, { desc: descending });
                        page = page.limit(pageSize + 1).data();

                        let nextKey;
                        if (page.length === pageSize + 1) {
                            const lastRow = page.pop();
                            if (indexTitle === '_id') {
                                nextKey = lastRow._id;
                            } else {
                                nextKey = get(lastRow, indexPath);
                            }
                        } else {
                            nextKey = descending ? _private.MIN_LEXICAL_VALUE : _private.MAX_LEXICAL_VALUE;
                        }
                        resolveOuter({
                            nextKey,
                            page
                        });
                    } catch (error) {
                        rejectOuter(error);
                    }
                })
        );
    };
};
