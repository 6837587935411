import { createElement as rc, useMemo } from 'react';
import { View, styled, fromTheme, Card, Text, webOnlyStyles } from 'lib_ui-primitives';
import useColumnCell from '../columns/useColumnCell';

const Field = styled(View).attrs({ name: 'cardbody-field' })`
    align-items: ${({ dataType }) => (dataType === 'patchDetail' ? 'stretch' : 'flex-start')};
    flex-grow: ${({ dataType }) => (dataType === 'patchDetail' ? 1 : 0)};
    min-width: ${({ dataType }) => (dataType === 'patchDetail' ? '100%' : 0)};
    flex-direction: column;
`;

const Label = styled(Text)`
    font-size: ${fromTheme('fontSizeLabel')};
    color: ${fromTheme('labelColor')};
    margin-bottom: ${fromTheme('textMargin')};
`;

let _CardBody = styled(Card.Body)`
    margin: ${fromTheme('viewMargin')};
    border-top-width: 0;
    flex-wrap: wrap;
    flex-direction: row;
`;

_CardBody = webOnlyStyles(_CardBody)`
    column-gap: ${fromTheme('viewMarginMore')};
`;

/**
 * This could be a normal component instead of a hook except that RowDetail is supplied
 * to lists as a property (which is a constraint that can't easily be changed). Creating
 * it as a hook allows the memoization of the property to be encapsulated.
 * @param {object} hNode - metadata
 * @returns CardBody component used as RowDetail in lists
 */
export default function useCardBody(hNode) {
    if (hNode.children == null) {
        throw new Error(`A ${hNode.hNodeType} must contain metadata columns.`);
    }
    return useMemo(() => {
        function CardBody(props) {
            const { item } = props || {};
            // prettier-ignore
            return rc(Card, null,
                    rc(_CardBody, {key: item._id, id: `cardbody${item._id}`},
                        hNode.children.filter(c => c.hNodeTypeGroup === 'listColumn').map((colHNode, i) =>
                            rc(Field, {key:i, dataType: colHNode.dataType},
                                rc(Label, null, colHNode.title + ':'),
                                rc(Value, {item, hNode: {...hNode, ...colHNode}})
                            )
                        )
                    )
                );
        }
        CardBody.getItemSize = () => {
            if (hNode.children.find(c => c.hNodeType === 'PatchDetail')) return 350;
            return 250;
        };
        return CardBody;
    }, [hNode]);
}

export function Value(props) {
    let { item, hNode, style } = props;
    hNode.asCardBody = true;
    return useColumnCell(item, hNode, style, undefined, false, undefined, false);
}
