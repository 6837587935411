import { network, globalConfig } from 'lib_ui-services';
import { COMMON_COLOR_SCHEME } from 'lib_ui-primitives';

const _p = { getNetworkStatus: network.getStatus };
export const _private = _p;

export default {
    verb: 'didNavigate',
    namespace: 'application',
    relation: 'route',
    description: 'Do not allow access to Caption Override while offline',
    routePath: ['/g/0/Setup/Caption Override', '/g/1/Setup/Caption Override'],
    //this is the actual logic:
    logic: didNavigate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @template T
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 */
async function didNavigate({ dispatch }) {
    // TODO: 2ROOT - #5044 replace with hide when offline access modifier
    const networkStatus = await _p.getNetworkStatus();
    if (!networkStatus.isServerReachable) {
        // Inform user that Caption Override is not available offline
        dispatch(
            {
                title: 'OFFLINE',
                message: 'Configuration of Caption Override only supported while online.',
                okButtonText: 'OK',
                noCancelOption: true,
                icon: 'warning',
                iconColor: COMMON_COLOR_SCHEME.warn,
                okAction: () => {
                    // Will fail over to index if inaccessible
                    //redirect to /Dashboard
                    dispatch(
                        {
                            to: `/g/${globalConfig().groupNumber || 0}/Dashboard`
                        },
                        { verb: 'redirect', namespace: 'application', relation: 'route' }
                    );
                }
            },
            { verb: 'confirm', namespace: 'application', relation: 'user' }
        );
    }
}
