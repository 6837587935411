// minLength = 0,
// maxLength = 9999,
// propertyName,
// pattern,
// rows = 5,

import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import ShortText from './ShortText';

function LongText(props) {
    const {
        hNode,
        hNode: { minLength = 0, maxLength = 9999, rows = 5 }
    } = props;

    return rc(ShortText, {
        ...props,
        multiline: true,
        hNode: {
            ...hNode,
            maxLength,
            minLength,
            rows
        }
    });
}

LongText.propTypes = {
    hNode: PropTypes.object.isRequired
};

export default LongText;
