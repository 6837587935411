import format from 'date-fns/format';
import lodash from 'lodash';
const { get } = lodash;
import logging from '@sstdev/lib_logging';
import constants from './constants';
import { getDictionary } from './metadata';

const hiddenTagIdUseCases = [
    constants.useCaseIds.FLAIR,
    constants.useCaseIds.ASSET_TRACKING,
    constants.useCaseIds.AMERICAN_WATER_ASSET_TRACKING
];

const _p = { getDictionary };
export const _private = _p;
/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
export default function getTransformedPatches(parentRecordId, patches, userContext, dataModel) {
    const transformed = patches.map((patch, index) => {
        try {
            // special generic case: if record was deleted:
            if (patch.path === '/' && patch.op === 'remove') {
                return {
                    _id: parentRecordId + '_' + index,
                    op: 'Record Deleted',
                    label: '',
                    newValue: '',
                    oldValue: ''
                };
            }

            //special cases that don't warrant displaying anything to the user
            // no id columns:
            if (
                !patch.path ||
                patch.path.endsWith('_id') ||
                //to accommodate a bug in check out:
                patch.path.endsWith('id') ||
                patch.path.endsWith('tenantId')
            )
                return null;
            // old and new value is the same
            if (patch.oldValue === patch.value) return null;
            // old and new value are both empty
            if (isEmpty(patch.oldValue) && isEmpty(patch.value)) return null;

            //special useCase specific case
            // In some useCases we don't really tell the user about tagIds...
            if (
                hiddenTagIdUseCases.includes(userContext?.activeUseCase?.['metaui:useCase']?._id) &&
                patch.path.endsWith('/tagId')
            ) {
                return null;
            }

            //now we know we want to show something to the user, get a property definition
            const propDef = getPropertyDefinition(patch, dataModel);

            //using the property definition, format what we are going to show the user
            const op = opMap[patch.op] || opMap.default;
            const label = propDef._meta.title;
            const newValue = getValue(propDef._meta, patch.value);
            const oldValue = getValue(propDef._meta, patch.oldValue);
            return { _id: parentRecordId + '_' + index, op, label, newValue, oldValue };
        } catch (error) {
            logging.error(error);
            return null;
        }
    });
    return transformed.filter(patch => patch != null);
}

const opMap = {
    remove: 'Clear',
    import: 'Import',
    open: 'Add to',
    close: 'Close',
    add: 'Add',
    default: 'Update'
};
const inventoryRegExp = {
    // To identify attributes like `/inventory/0/found` or `/inventory[0].found`
    found: new RegExp(/\/inventory.\d+.+found$/),
    foundDate: new RegExp(/\/inventory.\d+.+foundDate$/),
    foundBy: new RegExp(/\/inventory.\d+.+foundBy(.displayName)?$/),
    isNew: new RegExp(/\/inventory.\d+.+isNew$/),
    foundByScan: new RegExp(/\/inventory.\d+.+foundByScan$/)
};

function getValue(meta, value) {
    // Handle toggles
    if (meta.toggleValues) {
        if (value === undefined || value === null) {
            value = meta.defaultValue === 'true' ? true : meta.defaultValue === 'false' ? false : meta.defaultValue;
        }

        if (typeof value === 'boolean') {
            return value ? meta.toggleValues[1] : meta.toggleValues[0];
        }
    }

    // Handle date-time values
    if (meta?.hNodeType?.includes('Date')) {
        return value ? format(new Date(value), meta.format ?? 'PP p') : '';
    }

    // Default
    return value;
}

function isEmpty(value) {
    if ([null, undefined, ''].includes(value)) return true;
    if (Array.isArray(value) && value.length === 0) return true;
    if (typeof value === 'object' && Object.keys(value).length === 0) return true;
    return false;
}

const getInventoryVariantFrom = dataModel => (propertyName, defaultValue) => {
    const propParts = propertyName.includes('.') ? propertyName.split('.') : propertyName.split('/');

    //just in case they put in the full path as propertyName.
    //This _should_ already be handled correctly when generating the data dictionary, but keep it just in case
    //no need to worry about array index, as this would only apply to pre-multi-inventory blockly,
    //so it would always reference inventory index 0.
    const variants = ['inventory[0]', 'inventory.0', '/inventory/0', '/inventory[0]', 'inventory'];
    let candidates = variants.map(
        pathStart =>
            get(dataModel, [pathStart, ...propParts].join('.')) ||
            get(dataModel, [pathStart, ...propParts].join('/')) ||
            get(dataModel, [pathStart, ...propParts[0]].join('.')) ||
            get(dataModel, [pathStart, ...propParts[0]].join('/'))
    );

    //Depending on how the data dictionary was generated, it may generate the inventory array with, or without "0" index
    //or in with the new data dictionary generation, would even have a "0" property
    candidates = candidates
        .concat([
            get(dataModel, ['inventory', ...propParts]),
            get(dataModel, ['inventory[0]', ...propParts]),
            get(dataModel, ['inventory', '0', ...propParts])
        ])
        .filter(x => x);

    return !candidates.length ? defaultValue : candidates.find(def => def?._meta?.title) || candidates[0];
};

function getPropertyDefinition(patch, dataModel) {
    let propDef; //special category: inventories
    const getInventoryVariantFromDataModel = getInventoryVariantFrom(dataModel);

    if (inventoryRegExp.found.test(patch.path)) {
        propDef = getInventoryVariantFromDataModel('found', {
            _meta: {
                title: 'Inventory State',
                defaultValue: false,
                toggleValues: ['Unfound', 'Found']
            }
        });
    } else if (inventoryRegExp.foundDate.test(patch.path)) {
        propDef = getInventoryVariantFromDataModel('foundDate', {
            _meta: {
                title: 'Found Date'
            }
        });
    } else if (inventoryRegExp.foundBy.test(patch.path)) {
        propDef = getInventoryVariantFromDataModel('foundBy.displayName', {
            _meta: {
                title: 'Found By'
            }
        });
    } else if (inventoryRegExp.isNew.test(patch.path)) {
        propDef = /* getInventoryVariantFromDataModel('isNew',*/ {
            _meta: {
                title: 'Created During Inventory',
                defaultValue: false,
                toggleValues: [' ', 'Yes']
            }
        };
        // );
    } else if (inventoryRegExp.foundByScan.test(patch.path)) {
        propDef = getInventoryVariantFromDataModel('foundByScan', {
            _meta: {
                title: 'Barcode Scan',
                defaultValue: false,
                toggleValues: ['No', 'Yes']
            }
        });
    } else {
        //otherwise, find the pretty name for the property:
        const pathParts = patch.path.split('/').filter(x => !!x);
        propDef = dataModel[pathParts[0]];
        if (!propDef) {
            propDef = generatePropDef(pathParts, pathParts[0]);
        }
        propDef = { _meta: propDef._meta || propDef };
        if (['NestedObject', 'EditableList'].includes(propDef._meta.hNodeType)) {
            let needsArrayIndex = false;
            needsArrayIndex = propDef._meta.hNodeType === 'EditableList' || propDef._meta.overrideDataType === 'array';
            let subPath = pathParts.slice(1).join('.');
            // Extract the array index from subPath if needed, default to '0' if no index found
            let arrayIndex = needsArrayIndex ? subPath.match(/(\d+)/)[0] : '0';
            //for some weird configured exceptions:
            const child = propDef._meta.children
                .map(c => {
                    if (needsArrayIndex) {
                        // add the array index to the propertyName so that the child propertyName matches the pathParts
                        return { ...c, propertyName: `${arrayIndex}.${c.propertyName}` };
                    }
                    return c;
                })
                .find(c => {
                    return c.propertyName === subPath;
                });
            if (!child) {
                //ideally we'd find it recursively though:
                propDef = pathParts.slice(1).reduce((propDef, childProp) => {
                    if (propDef._meta?.children) {
                        const child = propDef._meta.children.find(c => c.propertyName === childProp);
                        if (child) return child._meta ? child : { _meta: child };
                    }
                    //no child prop, but we don't want "Title" anyway. return what we have
                    if (childProp === 'title') {
                        return propDef;
                    }
                    //if at this level we don't have propertyName 'title', generate something for this level, and use that
                    return generatePropDef(pathParts, childProp);
                }, propDef);
            } else {
                propDef = child._meta ? child : { _meta: child };
            }
        }
        if (!propDef || !propDef._meta || ['Image'].includes(propDef._meta.hNodeType)) return null;
    }
    return propDef;
}

function generatePropDef(pathParts, missingProp) {
    logging.info(
        `[PATCH DETAIL] Path /${pathParts.join(
            '/'
        )} was found as patch path, but we have no data model for ${missingProp}.
        Perhaps add an invisible element.`
    );
    return {
        _meta: {
            hNodeType: 'ShortText',
            hNodeTypeGroup: 'formElement',
            propertyName: missingProp,
            title: titleCase(missingProp)
        }
    };
}

/**
 * Takes a pascal case string and splits it before each (last) capital,
 * and add spaces. e.g. `item:configureRFIDPower` becomes `Item Configure RFID Power`
 * @param {*} pascalCase
 * @returns
 */
function titleCase(pascalCase) {
    return pascalCase
        .replace(/:([a-z])/, m => m.toUpperCase())
        .replace(':', '')
        .replace(/([A-Z][a-z]|[A-Z]+(?=[A-Z]|$))/g, ' $1')
        .replace(/./, m => m.toUpperCase())
        .trim();
}
