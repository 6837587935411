const logo = {
    sst: {
        white: { path: 'SST-Logo-White-transparent.png' },
        color: { path: 'SST-Logo-Blue-transparent.png' }
    },
    redbeam: {
        white: { path: 'Redbeam-Logo-White-transparent.png' },
        color: {
            dark: { path: 'Redbeam-Logo-RedWhite-transparent.png' },
            light: { path: 'Redbeam-Logo-Red-transparent.png' }
        }
    }
};
export default logo;
