// Start for when we are going to actually implement images
// Example for when we need to do anything post actual commit.

export default {
    verb: 'didCommit',
    namespace: 'item',
    relation: 'item',
    description: 'Remove associated Images',
    type: 'remove',
    //this is the actual logic:
    logic: didCommit
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * this may or may not be the correct format.... to be confirmed.
 * @typedef {{_id: string, 'file:image'?:{_id:string, title:base64Image}}} Item
 */

/**
 * @param {{
 *   data: Item;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {Item}
 */
async function didCommit({ data }) {
    if (data?.['file:image']?._id) {
        //Todo: Remove actual image files from server (?)
        //Todo: remove file from local file storage
    }
}
