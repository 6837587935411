export default function copyActiveRoleToUserRoot(activeTenant, activeUseCase, userRecord) {
    if (!activeTenant || !activeUseCase || !userRecord) {
        return userRecord;
    }

    if (userRecord['identity:role']) {
        return userRecord;
    }

    const currentTenant = activeTenant['identity:tenant'];
    if (!currentTenant) {
        return userRecord;
    }

    const currentUseCase = activeUseCase['metaui:useCase'];
    const chosenRole = findUserRoleForTenantApp(userRecord, currentTenant, currentUseCase);

    return appendRoleToRootOfUserRecord(userRecord, chosenRole);
}

function findUserRoleForTenantApp(user, tenant, useCase) {
    if (!user.tenant) return;
    const userTenant = findTenantById(user.tenant, tenant._id);
    if (!userTenant) return;
    if (!userTenant.useCase) return;
    const userUseCase = findUseCaseById(userTenant.useCase, useCase._id);
    if (!userUseCase) return;

    return userUseCase['identity:role'];
}

function findTenantById(tenantCollection, tenantId) {
    if (tenantId) {
        return tenantCollection.find(t => t['identity:tenant']._id === tenantId);
    }
    return tenantCollection[0];
}

function findUseCaseById(useCaseCollection, useCaseId) {
    if (useCaseId) {
        return useCaseCollection.find(a => a['metaui:useCase']._id === useCaseId);
    }
    return useCaseCollection[0];
}

function appendRoleToRootOfUserRecord(userRecord, role) {
    return { ...userRecord, 'identity:role': role };
}
