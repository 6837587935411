import { constants } from 'lib_ui-services';
import { hooks } from 'lib_ui-primitives';
const { useBbState: bbState } = hooks;

export default {
    verb: 'willRemove',
    namespace: 'security',
    relation: 'profile',
    description:
        'Clear the user\'s session as "DURATION.SESSION" gets cleared on reload, so we use ALWAYS to work around that',
    priority: 10,
    useCaseIds: [constants.useCaseIds.FREIGHT_RUNNER],
    logic
};

async function logic({ context }) {
    const {
        user: { _id: userId }
    } = context;
    const retention = {
        id: userId,
        scope: constants.retention.SCOPE.LOCAL,
        visibility: constants.retention.VISIBILITY.PER_BROWSER,
        duration: constants.retention.DURATION.ALWAYS
    };

    await bbState.setDirect(null, 'palletCounter', retention);
    await bbState.setDirect(null, 'firstScan', retention);
}
