/**
 * @module retention
 */

/**
 * @typedef {Object} retention
 * @property {SCOPE} retention.SCOPE
 * @property {VISIBILITY} retention.VISIBILITY
 * @property {DURATION} retention.DURATION
 */

/* Selection Retention:
 *  Scope: Local, PerRoute, Global
 *  Visibility: PerBrowser (later: PerUser, PerTenant)
 *  Duration: Request, Session, Always
 */

export const SCOPE = Object.freeze({
    LOCAL: 'local',
    PER_ROUTE: 'perRoute',
    GLOBAL: 'global'
});

export const VISIBILITY = Object.freeze({
    PER_BROWSER: 'perBrowser',
    PER_USER: 'perUser',
    PER_TENANT: 'perTenant'
});

export const DURATION = Object.freeze({
    /** Store for the duration of this single request */
    REQUEST: 'request',
    /** Store for the duration of this user session (remove at logout)*/
    SESSION: 'session',
    /** Store until changed or explicitly removed */
    ALWAYS: 'always'
});

export default { SCOPE, VISIBILITY, DURATION };
