import { createElement as rc, useCallback, useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import styled from '../styled';
import webOnlyStyles from '../webOnlyStyles';
import fromTheme from '../fromTheme';
import Icon from './Icon';

let Label = styled.label`
    position: relative;
    width: ${fromTheme('iconSize')};
    height: ${fromTheme('iconSize')};
`;
Label = webOnlyStyles(Label)`
    display: inline-block;
`;

// Display: block and appearance: none fixes Firefox. Otherwise, the checkbox
// is pushed down.
// See https://stackoverflow.com/questions/17213312/extra-space-between-label-and-checkbox-in-firefox
const Input = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
    display: block;
    appearance: none;
`;

let Box = styled(Icon)``;

Box = webOnlyStyles(Box)`
    &:hover {
        color: ${fromTheme('button', 'grayHighlight')};
    }
    cursor: pointer;
`;

const STATES = {
    checked: 'check_box',
    unchecked: 'check_box_outline_blank',
    indeterminate: 'indeterminate_check_box',
    fromValue: value => (value === null ? STATES.indeterminate : value ? STATES.checked : STATES.unchecked)
};

export default function CheckBox(props) {
    const theme = useContext(ThemeContext);

    const {
        value,
        onClick,
        className,
        disabled,
        name,
        onBlur,
        onFocus,
        color = 'transparent',
        fontColor: baseFontColor = theme.button.gray,
        checkedColor = theme.button.primary
    } = props;
    const [checkedState, setChecked] = useState(STATES.fromValue(value));
    useEffect(() => {
        if (checkedState !== STATES.fromValue(value)) setChecked(STATES.fromValue(value));
    }, [checkedState, value]);

    const onChange = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            if (disabled) return;
            setChecked(checkedState === STATES.checked ? STATES.unchecked : STATES.checked);
            onClick(checkedState !== STATES.checked);
        },
        [onClick, checkedState, disabled]
    );
    const fontColor = checkedState === STATES.checked ? checkedColor : baseFontColor;
    // prettier-ignore
    return rc(Label, { disabled, className, onClick: onChange },
        // tristate "null" styling is built on input _unchecked_.
        // But if we set `checked` to null, it doesn't change the checked state.
        // Also, when clicking on an "null" state switch, we want to be sure it always goes to "checked"
        // So, we explicitly need to treat "null" as false
        rc(Input, { name, type: 'checkbox', checked: checkedState === STATES.checked, onChange, disabled, onBlur, onFocus }),
        rc(Box, { disabled, color, fontColor, title: 'Select' }, checkedState)
    );
}
