import { createElement as rc, memo } from 'react';
import PropTypes from 'prop-types';
import _ReactDatePicker from 'react-datepicker';
const ReactDatePicker = _ReactDatePicker.default ?? _ReactDatePicker;

import Input from './Input';

/**
 * @typedef {Object} Props
 * @property {boolean} [disabled]
 * @property {string} id
 * @property {(date: string | number | Date) => void} onChange
 * @property {boolean} showTimeInput
 * @property {Date} [value]
 */
/** @type {import('react').FC<Props>} */
const InlineDatePicker = props => {
    const { format, id, onChange, showTimeInput, value, sequence } = props || {};

    return rc(ReactDatePicker, {
        ...props,
        customInput: rc(Input, { id, sequence }),
        dateFormat: format,
        fixedHeight: true,
        onChange,
        selected: value,
        shouldCloseOnSelect: !showTimeInput
    });
};
InlineDatePicker.displayName = 'InlineDatePicker';
InlineDatePicker.defaultProps = {
    disabled: false
};

InlineDatePicker.propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    showTimeInput: PropTypes.bool.isRequired,
    value: PropTypes.instanceOf(Date),
    sequence: PropTypes.number.isRequired
};

export default memo(InlineDatePicker);
