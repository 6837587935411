import { View, h3, Button, fromTheme, styled, Pressable } from 'lib_ui-primitives';

export const CaratButton = styled(Pressable).attrs({ name: 'carat-button' })`
    padding: ${fromTheme('button', 'padding')};
`;
CaratButton.displayName = 'CaratButton';
export const CollapseAllButton = styled(Button).attrs({ name: 'collapse-all-button' })`
    padding: ${fromTheme('button', 'padding')};
`;
export const ExpandAllButton = styled(Button).attrs({ name: 'expand-all-button' })`
    padding: ${fromTheme('button', 'padding')};
`;
export const ExpandCollapseButtons = styled(View).attrs({ name: 'expand-collapse-buttons' })`
    flex-direction: row;
    justify-content: flex-end;
    flex-grow: 1;
`;
ExpandCollapseButtons.displayName = 'ExpandCollapseButtons';
CollapseAllButton.displayName = 'CollapseAllButton';
export const StyledEditableList = styled(View).attrs({ name: 'editable-list' })`
    background-color: ${fromTheme('backgroundColor')};
    flex-direction: column;
    padding: 0;
`;
StyledEditableList.displayName = 'StyledEditableList';
export const Header = styled(View).attrs({ name: 'header' })`
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 280px;
`;
Header.displayName = 'Header';
export const ListTitle = styled(h3).attrs({ name: 'list-title' })`
    padding-left: ${fromTheme('viewPadding')};
    color: ${fromTheme('subheadingFontColor')};
    font-family: ${fromTheme('font')};
`;
ListTitle.displayName = 'ListTitle';
export const ColumnList = styled(View).attrs({ name: 'column-list' })`
    flex-direction: column;
`;
ColumnList.displayName = 'ColumnList';
