import { createElement as rc, memo } from 'react';
import PropTypes from 'prop-types';
import _DropDown from '../_abstractComponent/DropDown';
import useFormControl from '../../hooks/useFormControl';
import { h3 } from 'lib_ui-primitives';

// Avoids warning about switching between 'controlled' and 'uncontrolled' values.
const defaultValue = { title: '', isDefaultRecord: true };

const _p = {
    _DropDown,
    useFormControl
};
export const _private = _p;
/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function DropDown(props) {
    const { title, value, setValue, disabled, defaultReady, errors, autoFocus, scanButton, onFocus, onBlur, active } =
        _p.useFormControl(props, defaultValue);

    if (!defaultReady) {
        return rc(h3, null, 'Loading...');
    }
    // prettier-ignore
    return rc(_p._DropDown, { ...props, errors, title, value, setValue, disabled, autoFocus, scanButton, onFocus, onBlur, active });
}
DropDown.displayName = 'FormElementDropDown';
DropDown.defaultProps = {};

DropDown.propTypes = {
    hNode: PropTypes.object.isRequired,
    currentRoute: PropTypes.string.isRequired
};

export default memo(DropDown);
