import Get from './get';
import Insert from './insert';
export default _private => {
    const get = Get(_private);
    const insert = Insert(_private);
    return function upsert(_id, mergeCallback) {
        if (_id === undefined || _id === null) {
            throw new Error('An id must be provided to upsert an item.');
        }
        return get(_id).then(([existingItem]) => {
            const toSave = mergeCallback(existingItem);
            if (toSave === false) return; // noop (items are the same)
            if (existingItem) {
                //do NOT pass through to the generic update, as that duplicates what we already did in the mergeCallback
                //as well as it _requires_ properties to unset
                //which we don't have here. So,update it direct on the collection instead:
                const { startPromise, collection } = _private;
                return startPromise.then(async () => {
                    await collection.update(toSave);
                    return { updated: 1 };
                });
            }
            return insert(toSave);
        });
    };
};
