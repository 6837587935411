import { createMachine } from 'xstate';
import * as actions from './actions';

export default function getFormFsm(context) {
    return createMachine({ context, ...config }, { actions });
}

export const config = {
    id: 'Form',
    initial: 'edit',
    states: {
        view: {},
        edit: {
            initial: 'ready',
            on: {
                change: {
                    target: '.dirty',
                    actions: 'mergeChanges'
                },
                changeSuccess: {
                    target: '.dirty',
                    actions: 'mergeChangedRecord'
                },
                restart: {
                    target: '.ready',
                    actions: 'updateContext'
                },
                restartWithChanges: {
                    target: '.dirty',
                    actions: 'updateContext'
                }
            },
            states: {
                ready: {},
                dirty: {
                    initial: 'valid',
                    on: {
                        submit: '.submitting'
                    },
                    states: {
                        valid: {},
                        invalid: {},
                        submitting: {
                            initial: 'validating',
                            states: {
                                validating: {
                                    on: {
                                        validate_success: [
                                            {
                                                target: '#Form.edit.dirty.invalid',
                                                cond: (context, event) =>
                                                    Object.keys(event.errors?.field ?? {}).length ||
                                                    event.errors?.form?.length
                                            },
                                            { target: 'updating', cond: context => !context.isNew },
                                            { target: 'creating', cond: context => context.isNew }
                                        ],
                                        validate_failure: '#Form.edit.dirty.invalid'
                                    },
                                    entry: 'validate'
                                },
                                updating: {
                                    on: {
                                        update_success: '#Form.submitted',
                                        update_failure: '#Form.edit.dirty.invalid'
                                    },
                                    entry: 'update'
                                },
                                creating: {
                                    on: {
                                        create_success: '#Form.submitted',
                                        create_failure: '#Form.edit.dirty.invalid'
                                    },
                                    entry: 'create'
                                }
                            }
                        }
                    }
                }
            }
        },
        submitted: {
            entry: 'raiseSuccess',
            type: 'final'
        }
    }
};
