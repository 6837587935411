import { View, styled, fromTheme, Button, Text, webOnlyStyles } from 'lib_ui-primitives';
import { nativeOnlyProperties } from 'lib_ui-services';

const _ToggleButton = styled(Button).attrs({ name: 'toggle-button' })`
    max-width: ${fromTheme('width')};
`;
_ToggleButton.displayName = 'ToggleButton';
export const ToggleButton = _ToggleButton;

let _PreviewText = styled(Text).attrs({
    name: 'preview-text',
    ...nativeOnlyProperties({ numberOfLines: 1, ellipsizeMode: 'tail' })
})`
    margin-left: ${fromTheme('textMargin')};
    font-size: ${fromTheme('fontSize')};
    overflow: hidden;
`;
_PreviewText = webOnlyStyles(_PreviewText)`
    white-space: nowrap;
    text-overflow: ellipsis;
`;
export const PreviewText = _PreviewText;

const _Container = styled(View).attrs({ name: 'info-display-button' })`
    background-color: transparent;
`;
_Container.displayName = 'InfoDisplayButton';
export const Container = _Container;

const _TextContainer = styled(View).attrs({ name: 'text-container' })`
    padding-left: ${fromTheme('viewPadding')};
    padding-right: ${fromTheme('viewPadding')};
    padding-bottom: ${fromTheme('viewPadding')};
`;
_TextContainer.displayName = 'TextContainer';
export const TextContainer = _TextContainer;

const _Message = styled(Text).attrs({ name: 'message' })`
    font-size: ${fromTheme('fontSize')};
`;
_Message.displayName = 'message';
export const Message = _Message;

const _CloseButton = styled(Button).attrs({ name: 'close-button' })`
    float: right;
    margin: 0;
`;
_CloseButton.displayName = 'CloseButton';
export const CloseButton = _CloseButton;

let _PopperMessageView = styled(View).attrs({ name: 'popper-message-view' })`
    background-color: ${fromTheme('baseBackgroundColor')};
    z-index: ${({ theme }) => theme.zindex.InfoDisplayButtonMessage};
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'collapse')};
    display: block;
    max-width: ${fromTheme('form', 'entryMaxWidth')};
    padding: ${fromTheme('viewMargin')};
`;

_PopperMessageView = webOnlyStyles(_PopperMessageView)`
    box-shadow: 4px 4px 11px -2px #000000d0, 4px 16px 32px 4px #5787a970;
`;
_PopperMessageView.displayName = 'PopperMessageView';
export const PopperMessageView = _PopperMessageView;
