import { authentication } from 'lib_ui-services';

export default {
    verb: 'doingCreate',
    namespace: 'security',
    relation: 'profile',
    type: 'login',
    prerequisites: [],
    description: 'Authenticate a user',
    //this is the actual logic:
    logic: login
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function login({ data }) {
    return authentication.authenticate(data.newRecord);
}
