import getFilter from '../filterFactory';
import { getRelationMetadata } from '../metadata';

const _p = { getRelationMetadata };
export const _private = _p;
export default function getQueryUrl(namespace, relation, requestFilters, correlationId) {
    let baseUrl = `/api/${namespace}/${relation}`;
    if (!requestFilters || Object.keys(requestFilters).length === 0) {
        return baseUrl;
    }
    if (requestFilters.aggregateType) {
        baseUrl += '/aggregate';
    }
    if (requestFilters.fullTextSearch) {
        baseUrl += '/search';
    }

    let filterPieces = [];
    const { limitSyncSize } = _p.getRelationMetadata(namespace, relation);

    Object.keys(requestFilters).forEach(filterName => {
        // eslint-disable-next-line import/namespace
        let filter = getFilter(filterName);
        if (filter.getUriComponent) {
            let piece = filter.getUriComponent(requestFilters, limitSyncSize);
            if (!piece || piece.length === 0) return; // skip empty parameters.
            filterPieces.push(piece);
        }
    });

    if (filterPieces.length > 0) {
        if (correlationId) {
            return `${baseUrl}?${filterPieces.join('&')}&correlationId=${encodeURIComponent(correlationId)}`;
        }
        return `${baseUrl}?${filterPieces.join('&')}`;
    }
    return baseUrl;
}
