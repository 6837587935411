export default _private => {
    const { startPromise, collection } = _private;
    return function insert(doc) {
        return startPromise.then(async () => {
            if (Array.isArray(doc)) throw new Error('Expected document, received array for insert');
            if ([undefined, null].includes(doc)) throw new Error('You cannot insert a null or undefined document.');
            if (typeof doc._id === 'object') {
                doc._id = doc._id.toString();
            }
            await collection.insert(doc);
            return { updated: 1 };
        });
    };
};
