import lodash from 'lodash';
const { omit } = lodash;
import log from '@sstdev/lib_logging';
import conversion from '@sstdev/lib_epc-conversions';
import { constants, rfidStrategy } from 'lib_ui-services';
const { ERROR, REMOVE_AND_ERROR } = constants.tagAction;
const {
    STRATEGY_TYPES: { GS1, GID }
} = rfidStrategy;

/**
 * This strategy is for handling reads that do not have a corresponding record in the database.
 * (No match found.) It will display the last 6 digits of an appropriate representation of the tagId
 * and an indication that the tag is unknown
 * (E.g. in the case the assetNo usually is an ascii representation of the tagId,
 * the tagId will be converted to ascii before display to the user).
 * @param {{
 *    tagId:string,
 *    sensorType:string,
 *    rssi?:number,
 *    inDatabase?:boolean,
 * }} entry
 * @param {Array} dbResults not applicable
 * @param {{
 *    displayGatheredErrors:function,
 *    titularField:string,
 *    publish:function,
 *    request:function,
 *    matchProperties:Array<string>,
 *    displayInAscii:boolean,
 *    dataRights:import('../../../hooks/useProfileRole').Role
 *    mergeNamespace:string,
 *    mergeRelation:string,
 *    unknownTagAction:constants.tagAction,
 *    inactiveTagAction:constants.tagAction,
 *    beepForNewReads:boolean
 * }} config
 * @param {function} update
 * @param {function} remove
 * @returns {Promise<boolean>} True if the grid entry was updated, false if it was removed
 */
async function merge(entry, dbResults, config, update, remove) {
    const hexLookup = [constants.sensorTypes.RFID, constants.sensorTypes.BLE].includes(entry.sensorType);

    let newEntry = { ...omit(entry, ['rssi', 'batteryLevel']) };
    newEntry.inDatabase = false;

    let tagValue = entry.tagId;

    // if it was not a manual entry or barcode scan,
    // which would already be the right format for the titular field
    // then we need to convert it to the right format:
    if (hexLookup) {
        // IF we set the shortStrategy to a GS1 or GID, even when it is unknown
        // parse the tag as such, and add all the fields to the newEntry.
        // this would rely on the grid columns being updated to include all possible GS1 TDS fields.
        if ([GS1, GID].includes(rfidStrategy.getRfidConfig().shortStrategy)) {
            try {
                const { serial, type, toString, ...rest } = conversion.gs1.parse(tagValue);
                newEntry[config.titularField] = `<${serial || toString()}>`;
                newEntry.serial = serial;
                newEntry.description = type;
                for (const key in rest) {
                    newEntry[key] = rest[key];
                }
                newEntry.urn = toString();
                update(newEntry);
                return true;
            } catch (error) {
                log.debug(`Failed to parse '${tagValue}' as GS1 tag`, error);
                // continue with the hex value instead.
            }
        } else {
            tagValue = rfidStrategy.guessTitularValue(tagValue);
        }
    }

    // This is the maximum text that will fit in the current (2023-05-17)
    // statically sized grid columns
    const partialTag = tagValue.substring(tagValue.length - 6);
    newEntry[config.titularField] = `<${constants.UNKNOWN_TAG}...${partialTag}>`;

    if ([ERROR, REMOVE_AND_ERROR].includes(config.unknownTagAction)) {
        // Only display unknown tag errors for barcode/manual entries
        if ([constants.sensorTypes.MANUAL, constants.sensorTypes.BARCODE].includes(entry.sensorType)) {
            const message = `${entry.tagId} does not exist in the database.`;
            config.displayGatheredErrors(message);
            if (config.unknownTagAction === REMOVE_AND_ERROR) {
                remove(entry);
                return false;
            }
        }
    }
    update(newEntry);
    return true;
}

export default { merge };
