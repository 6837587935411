/**
 *  Include simple utilities that manipulate primitive values
 *
 *  If your function starts to get complex, consider if there is a lodash (or similar) version
 *  that would be worth the import to save on testing, etc.
 */

export function isNumeric(str) {
    // Number.isNaN looks for the actual NaN value.  i.e. It doesn't convert other value like 1, '2.5', etc. like the global isNaN does.
    if (Number.isNaN(str)) return false;
    if (typeof str === 'number') return true;
    if (typeof str != 'string') return false; // we only process strings
    return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
}

export function formatCurrency(value) {
    return isNumeric(value)
        ? parseFloat(value).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
        : '';
}

// https://262.ecma-international.org/11.0/#sec-time-values-and-time-range:~:text=A%20Number%20can,January%2C%201970%20UTC.
export const MAX_DATE = new Date(8640000000000000);
export const MIN_DATE = new Date(-8640000000000000);
export function dateMax(...dates) {
    const validDates = dates.map(date => {
        if (date == null) {
            return MIN_DATE;
        } else if (typeof date === 'string') {
            return new Date(date);
        } else if (date instanceof Date) {
            return date;
        } else if (typeof date === 'number' && Number.isInteger(date)) {
            return new Date(date);
        } else {
            throw new Error(`${date} does not appear to be a valid date.`);
        }
    });

    const max = validDates.reduce((maxSoFar, currentDate) => {
        if (maxSoFar.getTime() >= currentDate.getTime()) {
            return maxSoFar;
        } else {
            return currentDate;
        }
    });
    if (max === MIN_DATE) {
        throw new Error('You have not supplied a valid date for the dateMax function.');
    }
    return max;
}

export function dateMin(...dates) {
    const validDates = dates.map(date => {
        if (date == null) {
            return MAX_DATE;
        } else if (typeof date === 'string') {
            return new Date(date);
        } else if (date instanceof Date) {
            return date;
        } else if (typeof date === 'number' && Number.isInteger(date)) {
            return Date(date);
        } else {
            throw new Error(`${date} does not appear to be a valid date.`);
        }
    });

    const min = validDates.reduce((minSoFar, currentDate) => {
        if (minSoFar.getTime() <= currentDate.getTime()) {
            return minSoFar;
        } else {
            return currentDate;
        }
    });
    if (min === MAX_DATE) {
        throw new Error('You have not supplied a valid date for the dateMin function.');
    }
    return min;
}
