import { getGridData } from './utilities';

export default {
    verb: 'willBulk',
    description: 'replace selection criteria with actual records',
    priority: 50,
    //this is the actual logic:
    logic: willBulk
};

const _p = {
    getGridData
};
export const _private = _p;

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willBulk({ data, context }) {
    const { namespace, relation, routePath } = context;
    const records = await _p.getGridData(namespace, relation, routePath, data.selection);
    return { ...data, selection: records };
}
