import { useCallback, useEffect, useState } from 'react';

/**
 * @template V
 * @template T
 * @param {V} value - the value to be formatted
 * @param {(value: V) => T} formatter - a function to format the value
 * @param {boolean} [formatOnValueChange=false] - a flag to determine whether we should format on every state change
 * @returns {[T, () => T, () => void]}
 */
function useFormattedValue(value, formatter, formatOnValueChange = false) {
    const [formattedValue, setFormattedValue] = useState();

    const execute = useCallback(() => {
        const newFormattedValue = formatter(value);

        setFormattedValue(newFormattedValue);

        return newFormattedValue;
    }, [formatter, value]);

    useEffect(() => {
        if (formatOnValueChange) execute();
    }, [execute, formatOnValueChange]);

    const reset = useCallback(() => {
        setFormattedValue();
    }, []);

    return [formattedValue, execute, reset];
}

export default useFormattedValue;
