export default {
    verb: 'willCreate',
    namespace: 'identity',
    relation: 'user',
    prerequisites: [],
    description: 'Add a displayName if one is not set',
    priority: 20, //before the default willCreate
    //this is the actual logic:
    logic: willCreate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willCreate({ data }) {
    const { displayName, firstName, lastName, ...user } = data.newRecord;

    return {
        ...data,
        newRecord: { ...user, firstName, lastName, displayName: displayName || `${firstName} ${lastName}` }
    };
}
