export default {
    verb: 'didCommit',
    namespace: 'oauth',
    relation: 'client',
    type: 'create',
    description: 'Show a popup with the client id and secret ',
    logic
};

async function logic({ data, dispatch }) {
    const { client_secret, client_id, title } = data.message;
    const message = [
        'You have successfully added a new access token. Copy the token now!',
        'Client ID:',
        `${client_id}`,
        'Client Secret:',
        `${client_secret}`,
        ' ',
        "⚠️WARNING - Make sure you copy the above token now. We don't store it, and you will not be able to see it again."
    ];

    dispatch(
        {
            title: `Access Token ${title}`,
            message: message,
            okButtonText: 'CONTINUE',
            noCancelOption: true,
            okAction: () => {}
        },
        { verb: 'confirm', namespace: 'application', relation: 'user' }
    );
}
