import logging from '@sstdev/lib_logging';
export default _private => {
    const { startPromise, idbAdapter, mainFileName } = _private;

    return async function purgeDatabase() {
        const loki = await startPromise;

        await new Promise((resolve, reject) => {
            loki.collections.forEach(c => loki.removeCollection(c.name));
            loki.throttledSaveDrain(result => {
                if (!result) {
                    logging.error(
                        'There was a problem purging the database. The existing changes could not be drained.'
                    );
                }
                // This doesn't close the backing store database connection.
                // It just stops auto updates to it.
                loki.close(async err => {
                    if (err) reject(err);
                    await deleteLokiDataFromBackingStore(mainFileName, loki);
                    resolve();
                });
            });
        });
    };

    function deleteLokiDataFromBackingStore(mainFileName, loki) {
        // Delete the lokijs data from the backing store (e.g. indexedDB or sqllite).
        return new Promise((resolve, reject) => {
            try {
                if (idbAdapter) {
                    // Deletes the partitions (i.e. indexedDb records storing lokijs partitions)
                    idbAdapter.deleteDatabasePartitions(mainFileName);
                    // Close the actual indexedDb.  If you don't do this it will
                    // block when you try to delete the actual indexeddb database.
                    idbAdapter.closeDatabase();
                    resolve(loki);
                } else {
                    // for other (non-indexeddb) lokijs adapters.
                    loki.deleteDatabase(err => {
                        if (err) reject(err);
                        resolve(loki);
                    });
                }
            } catch (error) {
                reject(error);
            }
        });
    }
};
