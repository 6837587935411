/**
 * Libraries like highcharts.js use syntax like `element.setAttribute('style', someStyle)`;
 * which will break CSP because it is attempting to set an inline style (not allowed by
 * CSP).  This polyfill will parse the style passed in and translate the request into
 * individual style changes like this:
 * `element.style.color = 'red'`;
 * which are considered safe.
 */
// reference: https://csplite.com/csp/test343/
const setAttribute_ = Element.prototype.setAttribute; // Save source of Elem.setAttribute funct
Element.prototype.setAttribute = function (attr, val) {
    if (attr.toLowerCase() !== 'style') {
        // For debugging
        //console.log('set ' + attr + '=`' + val + '` natively');
        setAttribute_.apply(this, [attr, val]); // Call the saved native Elem.setAttribute funct
    } else {
        // e.g. 'color:red; background-color:#ddd' -> el.style.color='red'; el.style.backgroundColor='#ddd';
        // For debugging
        //console.log('set ' + attr + '=`' + val + "` via setAttribute('style') polyfill");
        const arr = val.split(';').map(el => el.trim());
        for (let i = 0, tmp; i < arr.length; ++i) {
            if (!/:/.test(arr[i])) continue; // Empty or wrong
            tmp = arr[i].split(':').map(el => el.trim());
            this.style[camelize(tmp[0])] = tmp[1];
        }
    }
};

function camelize(str) {
    return str
        .split('-') // Parse 'my-long-word' to ['my', 'long', 'word']
        .map(
            // Coverts all elements to uppercase except first: ['my', 'long', 'word'] -> ['my', 'Long', 'Word']
            (word, index) => (index == 0 ? word : word[0].toUpperCase() + word.slice(1))
        )
        .join(''); // Join ['my', 'Long', 'Word'] to 'myLongWord'
}
