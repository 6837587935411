//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _doingExport_namespace_relation from './doingExport_namespace_relation';
import _willExport_namespace_relation from './willExport_namespace_relation';
export const doingExport_namespace_relation = _doingExport_namespace_relation;
export const willExport_namespace_relation = _willExport_namespace_relation;
export default {doingExport_namespace_relation, willExport_namespace_relation};

