import { database, offlineResilientCommunication, network, localStorage, constants } from 'lib_ui-services';
export const _private = { database, getNetworkStatus: network.getStatus };
export default {
    verb: 'doingUpdate',
    namespace: 'application',
    relation: 'useCase',
    type: 'syncAllDataToLocal',
    description: 'Synchronize data with the server',
    //this is the actual logic:
    logic: syncAllDataToLocal,
    onError
};

/**
 * @param {{
 *      error: Error;
 *      data: T;
 *      context: Context;
 *      dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>,
 *      workflowStack: WorkflowStack[]
 * }} parameters
 * */
function onError({ error, context }) {
    if (
        context.user?.activeUseCaseId === constants.useCaseIds.FREIGHT_RUNNER &&
        error.message.includes('Error saving database')
    ) {
        // mute the error
        error._isUserError = true;
    }
    throw error;
}

async function syncAllDataToLocal({ data, context }) {
    // eslint-disable-next-line no-undef
    if (__DISABLE_SYNC__) return;
    const syncEnabled = await localStorage.getKey('syncEnabled', undefined, true, false);
    if (!syncEnabled) return;
    const networkStatus = await _private.getNetworkStatus();
    // If the user logged out, but remained online,
    // we need to re-enable the offline (client-to-server) sync
    // NOTE: if this is the first time the user logs in after loading the app
    // the sync most likely already is running, and this will be a no-op
    if (networkStatus.isServerReachable) {
        offlineResilientCommunication.enable(true);
    }

    //start the server-to-client sync:
    await _private.database.isDbReady();
    const db = _private.database.get();
    const result = await db.synchronization.syncAllRelations(data, context);
    return result;
}
