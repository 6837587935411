import { useState, useCallback, createElement as rc } from 'react';
import { Icon, Text, View, fromTheme, webOnlyStyles, Pressable, styled } from 'lib_ui-primitives';

import Collapse from '../../helpers/Collapse';
import AccessModifiersAsIcons from './AccessModifiersAsIcons';
import useEventSink from '../../../hooks/useEventSink';
import useSplashRecord from '../../../hooks/useSplashRecord';

const NoShrink = styled(View)`
    flex-shrink: 0;
    flex-direction: column;
`;

let NavHeaderPlain = styled(Pressable).attrs({ name: 'nav-header-plain' })`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    justify-content: space-between;
    letter-spacing: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    flex-grow: 0;
    background-color: 'rgba(0,0,0,0)';
    border-right-color: 'rgba(0,0,0,0)';
`;
NavHeaderPlain = webOnlyStyles(NavHeaderPlain)`
    cursor: pointer;
    &:hover {
        background-color: ${fromTheme('colorScheme', 'background-dark')};
        border-right-color: ${fromTheme('colorScheme', 'background-dark')};
    }
`;

let EditMenuContainer = styled(NoShrink)`
    background-color: ${fromTheme('colorScheme', 'background-dark')};
`;
EditMenuContainer = webOnlyStyles(EditMenuContainer)`
    ${NavHeaderPlain}{
        &:hover {
            background-color: #5c5c60;
        }
    }
`;

const MenuModifier = styled(NavHeaderPlain)`
    padding-right: 10px;
`;

const LinkText = styled(Text)`
    font-family: ${fromTheme('fontAlternate')};
    color: ${fromTheme('colorScheme', 'white-text')};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
`;

const CollapseOffset = styled(View).attrs({ name: 'collapse-offset' })`
    margin-left: 24px;
`;

const FullWidthCollapse = styled(Collapse).attrs({ name: 'full-width-collapse' })`
    flex-grow: 1;
    flex-direction: column;
`;

export default function MenuEntry(props) {
    const {
        data,
        data: { _id, title: _title, isTabContainer, isNavigationLink, isMenuList, children },
        namespace,
        topLevel,
        isFirst,
        isLast
    } = props;
    const relation = 'navigation';
    let menuIcon = '';
    if (isTabContainer) {
        menuIcon = '🗀'; //or 🖿?
    } else if (isNavigationLink) {
        menuIcon = '↗'; // or 🔗?
    } else if (isMenuList) {
        menuIcon = '☰';
    }
    const title = `${_title} ${menuIcon}`;

    const [, publish] = useEventSink();
    const [editMode, setEditMode] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const toggleIsCollapsed = useCallback(() => {
        setIsCollapsed(prevVal => !prevVal);
    }, []);
    const { record: useCaseDetail } = useSplashRecord();

    const onContextMenu = useCallback(e => {
        e.preventDefault();
        setEditMode(x => !x);
    }, []);

    const onCancel = e => {
        setEditMode(false);
        e.preventDefault();
    };

    // handled by (activeRecord and) BlocklyEditor
    const onSelect = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            if (data.children) {
                //it's a menu entry
                //create a virtual blockly model
                const virtualModel = navToBlockly(data, useCaseDetail);
                publish({ record: virtualModel }, { verb: 'edit', namespace, relation: 'page', status: 'success' });
                toggleIsCollapsed();
            } else {
                //it's a page
                //add extra info, just in case we need to create this record.
                publish(
                    { ...data, 'metadata:useCaseDetail': { _id: useCaseDetail._id, title: useCaseDetail.title } },
                    { verb: 'edit', namespace, relation: 'page' }
                );
            }
        },
        [data, useCaseDetail, publish, namespace, toggleIsCollapsed]
    );

    //the below are handled by MenuEditor:
    const onAddAbove = useCallback(() => {
        publish({ _id }, { verb: 'change', namespace, relation, type: 'addAbove' });
        setEditMode(false);
    }, [_id, publish, namespace, relation]);
    const onAddBelow = useCallback(() => {
        publish({ _id }, { verb: 'change', namespace, relation, type: 'addBelow' });
        setEditMode(false);
    }, [_id, publish, namespace, relation]);
    const onMoveUp = useCallback(() => {
        publish({ _id }, { verb: 'change', namespace, relation, type: 'moveUp' });
    }, [_id, publish, namespace, relation]);
    const onMoveDown = useCallback(() => {
        publish({ _id }, { verb: 'change', namespace, relation, type: 'moveDown' });
    }, [_id, publish, namespace, relation]);
    const onUnNest = useCallback(() => {
        publish({ _id }, { verb: 'change', namespace, relation, type: 'unNest' });
    }, [_id, publish, namespace, relation]);
    const onNestDeeper = useCallback(() => {
        publish({ _id }, { verb: 'change', namespace, relation, type: 'nestDeeper' });
    }, [_id, publish, namespace, relation]);
    const onDelete = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            publish(
                {
                    _id,
                    title: _title,
                    'metadata:useCaseDetail': { _id: useCaseDetail._id, title: useCaseDetail.title },
                    _isMenuEntryOnly: isTabContainer || isNavigationLink || !!children
                },
                { verb: 'change', namespace, relation, type: 'remove' }
            );
        },
        [
            publish,
            _id,
            _title,
            useCaseDetail._id,
            useCaseDetail.title,
            isTabContainer,
            isNavigationLink,
            children,
            namespace
        ]
    );

    if (editMode) {
        // prettier-ignore
        return rc(EditMenuContainer, null,
            rc(View, {style: {flexDirection: 'row'}},
                rc(MenuModifier, {onClick:onAddAbove},
                    rc(Icon, {title: 'Add a new page above this one.'}, 'vertical_align_top'),
                    rc(LinkText, {title: 'Add a new page above this one.'}, 'Add Above ')
                ),
                !isFirst && rc(MenuModifier, {onClick:onMoveUp},
                    rc(Icon, {title: 'Move above previous sibling.'}, 'move_up'),
                    rc(LinkText, {title: 'Move above previous sibling.'}, 'Move Up ')
                ),
                !topLevel && rc(MenuModifier, {onClick: onUnNest},
                    rc(Icon, {title: 'Moves menu item to 1 higher level in the menu.'}, 'turn_left'),
                    rc(LinkText, {title: 'Moves menu item to 1 higher level in the menu.'}, 'Un-nest')
                )
            ),
            rc(NavHeaderPlain, {_id, onClick: onSelect, onContextMenu},
                rc(LinkText, {}, title),
                rc(MenuModifier, {},
                    !data.children?.length && rc(Icon, {title: 'Delete', onClick: onDelete}, 'delete'),
                    rc(Icon, {title: 'Cancel', onClick: onCancel}, 'block')
                )
            ),
            rc(View, {style: {flexDirection: 'row'}},
                rc(MenuModifier, {onClick:onAddBelow},
                    rc(Icon, {title: 'Add a new page below this one.'}, 'vertical_align_bottom'),
                    rc(LinkText, {title: 'Add a new page below this one.'}, 'Add Below ')
                ),
                !isLast && rc(MenuModifier, {onClick:onMoveDown},
                    rc(Icon, {title: 'Move below next sibling.'}, 'move_down'),
                    rc(LinkText, {title: 'Move below next sibling.'}, 'Move Down ')
                ),
                rc(MenuModifier, {onClick:onNestDeeper},
                    rc(Icon, {title: 'Nest this menu item 1 level deeper, in a new sub menu.'}, 'subdirectory_arrow_right'),
                    rc(LinkText, {title: 'Nest this menu item 1 level deeper, in a new sub menu.'}, 'Nest Deeper')
                )
            )
        );
    }

    // prettier-ignore
    return rc(NoShrink, null,
        !editMode && rc(NavHeaderPlain, {_id, onClick: onSelect, onContextMenu},
            rc(LinkText, null, title),
            rc(AccessModifiersAsIcons, {...props, isCollapsed, toggleIsCollapsed})
        ),
        !editMode && rc(CollapseOffset, null,
            rc(FullWidthCollapse, {isCollapsed: isCollapsed}, children?.map((child, i) => {
                return rc(MenuEntry, {
                    key: child._id,
                    data: child,
                    namespace,
                    relation,
                    isFirst: i === 0,
                    isLast: i === children.length - 1
                });
            }))
        )
    );
}

function navToBlockly(navigation, useCaseDetail) {
    let virtualRecord = {
        _id: navigation._id,
        title: navigation.title,
        virtual: true,
        subset: 'metadata:navigation',
        'metadata:useCaseDetail': { _id: useCaseDetail._id, title: useCaseDetail.title },
        blockly: {
            blocks: {
                languageVersion: 0,
                blocks: [
                    //actual page content goes here:
                    {
                        x: 50,
                        y: 70,
                        type: navigation.isTabContainer ? 'navHeading_TabNavHeading' : 'navHeading_NavHeading',
                        fields: { title: navigation.title },
                        ...flagsToSecurityModifiers(navigation)
                    }
                ]
            }
        }
    };

    return virtualRecord;
}

function flagsToSecurityModifiers(navigation) {
    const allProps = Object.entries(navigation);
    const accessModifiersAsArray = allProps
        .flatMap(([key, values]) => {
            if (
                [
                    'readOnlyFor',
                    'showForViewPort',
                    'hideOn',
                    'hiddenFor',
                    'hiddenForValue',
                    'hiddenWhen',
                    'disableWhen',
                    'addForFeatureFlag',
                    'removeForFeatureFlag'
                ].includes(key)
            ) {
                const [first, ...rest] = key;
                const type = `accessModifier_${first.toUpperCase()}${rest.join('')}`;
                return values.map(value => ({
                    type,
                    fields: value
                }));
            }
        })
        .filter(x => !!x);
    return nest(accessModifiersAsArray, 'securityModifier');
}

function nest(array = [], property) {
    const [first, ...rest] = array;
    if (!first) return {};
    return { inputs: { [property]: { block: { ...first, ...nest(rest, property) } } } };
}
