import { createElement as rc, useEffect } from 'react';
import { constants } from 'lib_ui-services';
import useFormControl from '../../../hooks/useFormControl';
import MergedReadProvider, { useMergedReads } from '../../contextProviders/MergedReadProvider';
import MultiPickerTransactionGrid from '../../actionElement/MultiPickerTransactionGrid';
import lodash from 'lodash';
const { isEqual } = lodash;

// combination of a "manual input" sensor (for typing or camera barcode scanning)
// on top of a SensorReadList.
const _p = {
    useFormControl,
    useMergedReads,
    MultiPickerTransactionGrid,
    // eslint-disable-next-line no-undef
    isNative: () => __SST_REACT_NATIVE__ || __TEST_RFID__
};
export const _private = _p;

/**
 * Wraps MultiPickerTransactionList with a MergedReadProvider and useFormControl
 */
export default function MultiPickerSensorGrid(props) {
    const {
        hNode: { mergeNamespace, mergeRelation, matchProperties, id, sensorType }
    } = props ?? { hNode: {} };

    const mergeReadProviderProps = {
        hNode: {
            id: `MergedReadProvider${id}`,
            namespace: mergeNamespace,
            relation: mergeRelation,
            matchProperties,
            sensorType,
            unknownTagAction: constants.tagAction.REMOVE_AND_ERROR,
            beepForNewReads: false,
            // TODO: Update blockly Transaction block to allow these to be specified
            inactiveTagAction: constants.tagAction.REMOVE_AND_ERROR,
            duplicateTagAction: constants.duplicateTagAction.REMOVE_AND_ERROR
        }
    };

    return rc(MergedReadProvider, mergeReadProviderProps, rc(InnerMultiPickerSensorGrid, props));
}

function InnerMultiPickerSensorGrid(props) {
    const { setValue, autoFocus } = _p.useFormControl(props);
    const { subscribeToChange } = _p.useMergedReads();

    // If the merged reads change, set them as this components value.  This is necessary because this
    // component is behaving as a formElement and needs to update form state. MultiPickerTransactionGrid
    // will get the entries by itself (that's why the entries are not passed into it below).
    useEffect(() => {
        if (subscribeToChange != null) {
            return subscribeToChange(newEntries =>
                setValue(oldEntries => {
                    if (isEqual(newEntries, oldEntries)) {
                        return oldEntries;
                    }
                    return newEntries;
                })
            );
        }
    }, [subscribeToChange, setValue]);

    // prettier-ignore
    return rc(_p.MultiPickerTransactionGrid, {
        ...props,
        hNode: {
            ...props.hNode
        },
        inputAutoFocus: autoFocus
    });
}
