import { styled, fromTheme, View, Text, ScrollView } from 'lib_ui-primitives';
import FormField from '../../formElement/FormField';

export const ColumnSelector = styled(View).attrs({ name: 'ColumnSelector' })`
    flex-direction: column;
    padding-bottom: ${fromTheme('viewPaddingMore')};
`;

export const Title = styled(FormField)`
    margin-bottom: 0;
`;

export const ColumnArea = styled(ScrollView).attrs({ scrollDirection: 'horizontal' })`
    flex-direction: row;
    // This is inside a modal, so make max-width the same as modal minus the fieldset padding
    max-width: ${({ theme: { mobile, width, viewPadding, maxModalWidth } }) =>
        mobile ? width : maxModalWidth - 2 * viewPadding}px;
`;

export const Dimmed = styled(Text)`
    color: ${fromTheme('disabledColor')};
`;

export const InnerImportForm = styled(View)`
    flex-grow: 1;
    flex-direction: column;
`;

export const Column = styled(View).attrs({ name: 'import-column' })`
    flex-grow: 1;
    flex-shrink: 0;
    flex-direction: column;
`;

export const Header = styled(View)`
    flex-grow: 1;
    flex-direction: column;
    color: ${props => (props.isUnassigned ? props.theme.colorScheme.error : 'defaultFontColor')};
    padding-bottom: ${fromTheme('viewPadding')};
`;
export const TopToBottom = styled(View)`
    flex-grow: 1;
    flex-direction: column;
    padding-left: ${fromTheme('viewPadding')};
    padding-right: ${fromTheme('viewPadding')};
    align-items: center;
    justify-content: flex-start;
`;

export const LeftAlignedTopToBottom = styled(View)`
    flex-grow: 1;
    flex-direction: column;
    padding-left: ${fromTheme('viewPadding')};
    padding-right: ${fromTheme('viewPadding')};
    align-items: left;
    justify-content: flex-start;
`;

export const LeftToRight = styled(View)`
    flex-grow: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const ColumnTitle = styled(Text)`
    font-weight: bold;
    color: ${({ isUnassigned, isSkipped, theme }) => {
        if (isUnassigned) return theme.colorScheme.error;
        if (isSkipped) return theme.disabledFontColor;
        return theme.defaultFontColor;
    }};
`;
export const SubText = styled(Text)`
    font-size: 14px;
    color: ${fromTheme('disabledColor')};
`;

export const Preview = styled(View)`
    background-color: ${({ isUnassigned, isSkipped, theme }) => {
        if (isUnassigned) return theme.colorScheme.error + '20';
        if (isSkipped) return theme.backgroundColorLighter;
        return 'transparent';
    }};
    flex-direction: column;
    flex-grow: 0;
`;
export const Cell = styled(Text)`
    background-color: ${props => (props.odd ? props.theme.backgroundColorLighter : 'transparent')};
    font-size: 14px;
    flex-grow: 1;
    line-height: 27px;
    padding-left: ${fromTheme('viewPadding')};
    color: ${({ isSkipped, theme }) => {
        if (isSkipped) return theme.disabledFontColor;
        return theme.defaultFontColor;
    }};
`;
export const TitleCell = styled(Cell)`
    font-weight: bold;
    background-color: ${fromTheme('backgroundColorDarker')};
`;

export const Tooltip = styled(Text).attrs({ children: 'i' })`
    background-color: ${fromTheme('colorScheme', 'primary')};
    position: relative;
    top: -10px;
    right: 5px;
    border-radius: 50%;
    font-size: 8px;
    padding: 2px;
    color: white;
    width: 13px;
    text-align: center;
    font-weight: bold;
`;
