import { createContext, useState, useEffect, createElement as rc, useCallback } from 'react';
import PropTypes from 'prop-types';
import { localStorage } from 'lib_ui-services';
import useEventSink from '../../hooks/useEventSink';
import logging from '@sstdev/lib_logging';
import lodash from 'lodash';
const { isEqual } = lodash;
import { hooks } from 'lib_ui-primitives';
const { useRouter } = hooks;

export const SplashRecordContext = createContext({ available: false });
const SPLASH_RECORD = 'splashRecord';

//Closely based on ActiveRecord, just simpler, and at a higher level
// Upon the `submit` sets (or replaces) the record splashRecord
//Upon Cancel clears the record
function SplashRecord(props) {
    const { namespace, relation, type, children, record } = props || {};
    if (namespace == null || relation == null) {
        logging.error('Namespace and relation are required properties for SplashRecord');
    }
    const [splashRecord, _setSplashRecord] = useState({
        namespace,
        relation,
        type,
        record,
        hasSplashed: false,
        isLoading: true
    });
    const setSplashRecord = useCallback(record => {
        if (typeof record === 'function') {
            _setSplashRecord(prevValue => {
                let newRecord = record(prevValue);
                newRecord.isLoading = false;
                localStorage.setKey(SPLASH_RECORD, JSON.stringify(newRecord));
                return newRecord;
            });
        } else {
            record.isLoading = false;
            localStorage.setKey(SPLASH_RECORD, JSON.stringify(record));
            _setSplashRecord(record);
        }
    }, []);
    // Recover user's splash selection
    useEffect(() => {
        (async () => {
            let record = await localStorage.getKey(SPLASH_RECORD, undefined, JSON.stringify({ hasSplashed: false }));
            if (record) {
                record = JSON.parse(record);
                _setSplashRecord(record);
                router.goToLocation(`/g/${router.groupNumber}/`);
            } else {
                _setSplashRecord(prev => {
                    prev.isLoading = false;
                    return prev;
                });
            }
        })();
        //we only want this for the initial load.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [subscribe, , request] = useEventSink();
    const router = useRouter();

    useEffect(() => {
        if (props.type && splashRecord.type !== props.type) {
            const updatedSplashRecord = { ...splashRecord, type: props.type };
            setSplashRecord(updatedSplashRecord);
        }
    }, [splashRecord, setSplashRecord, props.type]);

    // If the rules engine publishes a success for one of these verbs, then the active record
    // will be changing.
    useEffect(() => {
        let allowStateChange = true;
        const editStates = [
            //use "view" (or "new") action to preview (or create) a Record to be used as splash record
            //use "edit" action to actually use that record as splash Record
            { verb: 'edit', namespace, relation, type, status: 'success' },
            //if that record is updated, this keeps it in sync
            { verb: 'update', namespace, relation, type, status: 'success' },
            { verb: 'create', namespace, relation, type, status: 'success' }
        ];
        const closeStates = [
            //to discard the current record from being the splash record:
            { verb: 'close', namespace, relation, type },
            //for now, as hack, to cancel out
            { verb: 'cancel', namespace, relation, type, status: 'success' }
        ];

        const unsubscribes = editStates
            .map(state =>
                subscribe(state, async (payload, context) => {
                    logging.debug(`[SPLASH] ${context.verb} ${payload.record?.title} (${context.type})`);
                    if (!payload.record) return;
                    payload.hasSplashed = true;
                    // Confirm splash record change.  If nothing cares (no subscribers) this request will just
                    // resolve without an error or timeout.
                    try {
                        // This will fail (promise reject) to the catch statement below if anything does not confirm the change.
                        // Otherwise, it will continue and set the new splash record.
                        await request(
                            { splashRecord, newRecord: payload, changeContext: context },
                            { verb: 'confirm', namespace, relation, type: 'changeSplashRecord' },
                            undefined,
                            false // do not error if there are no subscribers
                        );

                        if (allowStateChange) {
                            let redirectRequired = false;
                            // Avoid rerenders, etc if user clicks on the same record again.
                            setSplashRecord(prev => {
                                const newPayload = { ...prev, ...payload };
                                if (!isEqual(prev, newPayload)) {
                                    redirectRequired = true;
                                    return newPayload;
                                }
                                return prev;
                            });
                            if (redirectRequired) {
                                router.goToLocation(`/g/${router.groupNumber}/`);
                            }
                        }
                    } catch (err) {
                        logging.info('Splash record change canceled');
                    }
                })
            )
            .concat(
                closeStates.map(state =>
                    subscribe(state, async (payload, context) => {
                        logging.debug(`[SPLASH] ${context.verb} ${payload.record?.title}`);
                        delete payload.record;
                        payload.hasSplashed = false;
                        // Confirm splash record change.  If nothing cares (no subscribers) this request will just
                        // resolve without an error or timeout.
                        try {
                            // This will fail (promise reject) to the catch statement below if anything does not confirm the change.
                            // Otherwise, it will continue and set the new splash record.
                            await request(
                                { splashRecord, newRecord: payload, changeContext: context },
                                { verb: 'confirm', namespace, relation, type: 'changeSplashRecord' },
                                undefined,
                                false // do not error if there are no subscribers
                            );

                            if (allowStateChange) {
                                // Avoid rerenders, etc if user clicks on the same record again.
                                setSplashRecord(prev => {
                                    const newPayload = { ...prev, ...payload };
                                    if (!isEqual(prev, newPayload)) {
                                        return newPayload;
                                    }
                                    return prev;
                                });
                            }
                            router.goToLocation(`/g/${router.groupNumber}/`);
                        } catch (err) {
                            logging.info('Splash record change canceled');
                        }
                    })
                )
            );
        return () => {
            allowStateChange = false;
            unsubscribes.forEach(u => u());
        };
    }, [namespace, relation, type, subscribe, request, splashRecord, router, setSplashRecord]);

    //render the provider.
    return rc(SplashRecordContext.Provider, { value: splashRecord }, children);
}

SplashRecord.propTypes = {
    namespace: PropTypes.string.isRequired,
    relation: PropTypes.string.isRequired,
    type: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    isNew: PropTypes.bool
};
export default SplashRecord;
