import _Downshift from 'downshift-sst';
const Downshift = _Downshift.default || _Downshift;
import { createElement as rc, createContext, useContext } from 'react';
import useMultipleChangeHandlers from '../../../hooks/useMultipleChangeHandlers';

const DownshiftContext = createContext();

/**
 * Returns the downshift children function properties (https://github.com/downshift-js/downshift#children-function)
 * plus a few other props created in DownshiftProvider (below)
 * @returns @type {import('react').Context}
 */
export const useDownshift = () => {
    return useContext(DownshiftContext);
};

/**
 * Downshift uses a children function (https://github.com/downshift-js/downshift#children-function)
 * instead of a real component.  This means you cannot use hooks.  They do provide hooks
 * like useCombobox, but they require the actual records to be passed in instead of allowing
 * a child component to (lazily in our case) consume them.
 * This provider turns the children function into a context so it can be consumed by a component
 * using a hook (see useDownshift above).
 */
export default function DownshiftProvider(props) {
    const { refKey, selectedItem, inputItemToString, onStateChange, inputValue, onChange, id } = props;

    // When the Downshift component fires onInputValueChange, both the DropDownList and InnerDropDown
    // need to react to that.
    const [listenForInputChange, addInputChangeHandler] = useMultipleChangeHandlers();
    // prettier-ignore
    return rc(Downshift, {
        selectedItem,
        itemToString: inputItemToString,
        onInputValueChange: listenForInputChange,
        onStateChange,
        inputValue,
        onChange,
        labelId: `bb${id}-label`,
        inputId: `bb${id}-input`,
        menuId: `bb${id}-menu`
    }, downshift => {
        // downshift gets whiney if you don't call getRootProps here.  It tells
        // downshift that the refKey points to the root element of the
        // dropdown so it can figure out stuff like blur and focus for
        // all the parts of the component
        downshift.getRootProps({ refKey }, { suppressRefError: true });
        return rc(DownshiftContext.Provider, { value: { ...downshift, addInputChangeHandler } },
            props.children
        );
    }
    );
}
