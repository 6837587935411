import { useCallback, useRef } from 'react';
import logging from '@sstdev/lib_logging';

/**
 * This hook is a little like a reducer.  It returns a function (FUNC_B) that can receive a starting result (which will
 * be modified over time by asyncFunc) and other arguments you will need for the reduction calculation.
 * FUNC_B will queue passed values to be run with the asyncFunc you pass into the hook.
 * The asyncFunction you specify will receive the result from any previous runs of the this method (assuming
 * any are pending), and any other values FUNC_B is called with.
 */
export default function useChainedPromiseQueue(asyncFunc, dependencies) {
    const pending = useRef([]);

    // FUNC_B (described above)
    return useCallback(async (startingResults, ...args) => {
        const lastPromise = pending.current[pending.current.length - 1] ?? Promise.resolve(startingResults);
        const nextPromise = new Promise(resolve => {
            lastPromise.then(previousResults => {
                let asyncResult = asyncFunc(previousResults, ...args);
                if (!(asyncResult instanceof Promise)) {
                    asyncResult = Promise.resolve(asyncResult);
                }
                asyncResult
                    .then(newResults => {
                        resolve(newResults);
                    })
                    .catch(err => {
                        logging.error(err);
                        resolve(previousResults);
                    });
            });
        });
        // Add this promise to the pending list, so it finishes before future requests.
        pending.current.push(nextPromise);
        await nextPromise;
        // Remove this promise from the pending list when it finishes.
        pending.current.splice(pending.current.indexOf(nextPromise), 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
}
