import { createElement as rc, useMemo } from 'react';
import PropTypes from 'prop-types';
import MultiPickerDropDown from '../_abstractComponent/MultiPickerDropDown';
import CriteriaElement from './CriteriaElement';
import createTreePositionGetter from '../../utilities/createTreePositionGetter';

import { constants } from 'lib_ui-services';
const { criteriaOperations } = constants;

const supportedOperations = [criteriaOperations.INCLUDED_IN, criteriaOperations.NOT_INCLUDED_IN];
// Avoids warning about switching between 'controlled' and 'uncontrolled' values.
const defaultValue = [];
const _p = {
    MultiPickerDropDown
};
export const _private = _p;
export default function MultiPickerDropDownCriteria(props) {
    const {
        hNode: { id, foreignNamespace, foreignRelation, treePosition }
    } = props || { hNode: {} };

    const getTreePosition = createTreePositionGetter(treePosition, 2);
    const newTreePosition = getTreePosition(0);

    // Avoid rerenders.
    const hNode = useMemo(() => {
        return {
            ...props.hNode,
            foreignNamespace: undefined,
            foreignRelation: undefined,
            propertyName: `${foreignNamespace}:${foreignRelation}`,
            displayUnassignedRow: false,
            treePosition: newTreePosition
        };
    }, [props.hNode, foreignNamespace, foreignRelation, newTreePosition]);

    return rc(
        CriteriaElement,
        {
            ...props,
            hNode,
            supportedOperations,
            defaultValue
        },
        rc(_p.MultiPickerDropDown, { ...props, id: `innerValue${id}`, treePosition: getTreePosition(1) })
    );
}

MultiPickerDropDownCriteria.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};
