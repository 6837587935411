import lodash from 'lodash';

const { isEmpty } = lodash;

export default {
    hasSearchCriteria,
    getSearchCriteria,
    validateCriteria,
    filterRecordValues,
    applySearchCriteria,
    getValue
};

/**
 * Checks if filters contain search criteria.
 * @param {Object} filters - The filters to check.
 * @returns {boolean} True if the filters contain search criteria, false otherwise.
 */
function hasSearchCriteria(filters) {
    if (isEmpty(filters)) return false;
    return filters.searchCriteria && !isEmpty(filters.searchCriteria);
}

/**
 * Extracts conditions from search criteria.
 *
 * @param {Object} searchCriteria - The search criteria.
 * @returns {Object} The extracted conditions.
 */
function getSearchCriteria(searchCriteria) {
    const conditions = {};
    for (const path in searchCriteria) {
        const [propertyPath, condition] = Object.entries(searchCriteria[path])[0];
        conditions[path] = { condition: propertyPath, value: condition };
    }
    return conditions;
}

/**
 * Validates conditions against a record.
 *
 * @param {Object} record - The record to validate.
 * @param {Object} conditions - The conditions to validate against.
 * @returns {Array} The results of the condition validation.
 */
function validateCriteria(record, conditions) {
    const conditionResults = [];

    for (const [path, { condition, value }] of Object.entries(conditions)) {
        const recordValues = getValue(record, path.split('.'), null);

        if (Array.isArray(recordValues) && recordValues.includes(value)) {
            conditionResults.push({ path, match: true, value });
        } else if (!Array.isArray(recordValues) && condition === '$eq' && recordValues === value) {
            conditionResults.push({ path, match: true });
        } else {
            conditionResults.push({ path, match: false });
        }
    }

    return conditionResults;
}

/**
 * Applies search criteria to a record.
 *
 * @param {Object} record - The original record.
 * @param {Object} filters - All of the filters.
 * @returns {Object} The modified record.
 */
function applySearchCriteria(record, filters) {
    const searchCriteria = getSearchCriteria(filters.searchCriteria);
    const validatedCriteria = validateCriteria(record, searchCriteria);
    let modifiedRecord = { ...record };

    for (const { path, match, value } of validatedCriteria) {
        if (match) {
            modifiedRecord = filterRecordValues(modifiedRecord, path.split('.'), value);
        }
    }

    return modifiedRecord;
}

/**
 * Filters record values based on a match value.
 *
 * @param {Object} record - The record to filter.
 * @param {Array} path - The path to the value within the record.
 * @param {any} matchValue - The value to match.
 * @returns {Object} The filtered record.
 */
function filterRecordValues(record, path, matchValue) {
    const [targetPath, ...restOfPath] = path;
    const nestedPath = restOfPath.join('.');

    if (!record[targetPath] || !Array.isArray(record[targetPath])) return { ...record };

    const filteredRecord = record[targetPath].filter(item => {
        const value = getValue(item, nestedPath.split('.'));
        return value === matchValue;
    });

    return {
        ...record,
        [targetPath]: filteredRecord
    };
}

/**
 * Retrieves a value from an object at a given path, with support for true/false labels.
 * If the path is a string, it is split by '.' to navigate through nested objects.
 *
 * @param {Object} value - The object to retrieve the value from.
 * @param {Array|string} path - The path to the value, can be a string separated by '.' for nested objects or an array of keys.
 * @param {any} [defaultValue] - The default value if the path is not found. Optional.
 * @param {string} [trueLabel] - The label to use if the value is true. Optional.
 * @param {string} [falseLabel] - The label to use if the value is false. Optional.
 * @returns {any} The value at the path, the true/false label, or the default value.
 */
function getValue(value, path, defaultValue, trueLabel = null, falseLabel = null) {
    if (typeof value === 'undefined') return defaultValue || falseLabel;
    const pathArray = typeof path === 'string' ? path.split('.') : path;
    if (Array.isArray(value) && isNaN(path[0]))
        return value.map(x => getValue(x, path, defaultValue, trueLabel, falseLabel));
    if (!pathArray?.length) {
        if (trueLabel !== null && falseLabel !== null) {
            return value ? trueLabel : falseLabel;
        }
        return value;
    }
    return getValue(value?.[pathArray[0]], pathArray.slice(1), defaultValue, trueLabel, falseLabel);
}
