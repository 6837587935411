import { createElement as rc } from 'react';
import * as reactMenu from '@szhsin/react-menu';
import styled from '../../styled';
import fromTheme from '../../fromTheme';

const MenuHeader = styled(reactMenu.MenuHeader)`
    margin-bottom: ${fromTheme('viewPaddingMore')};
    padding: ${fromTheme('viewPadding')};
    font-size: ${fromTheme('fontSizeLarge')};
    font-family: ${fromTheme('fontAlternate')};
    text-transform: none;
`;

export default props => {
    return rc(MenuHeader, null, props?.hNode?.title ?? '');
};
