import { createElement as rc, forwardRef, useLayoutEffect } from 'react';
import useEnsureRef from '../hooks/useEnsureRef';
import styled from '../styled';

const _View = styled.div.attrs(props => ({ ...props, name: props.name || 'view' }))`
    display: ${props => (props.block ? 'block' : 'flex')};
    overflow: hidden;
`;
export default forwardRef(function View(props, passedRef) {
    const ref = useEnsureRef(passedRef);

    // Create a method to measure the size of this component.  This needs to be consistent between
    // react native and react web.
    useLayoutEffect(() => {
        if (ref.current != null) {
            ref.current.measureRect = async () => {
                return ref.current.getBoundingClientRect();
            };
        }
    }, [ref]);

    return rc(_View, { ...props, ref });
});
