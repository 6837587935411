import lodash from 'lodash';
const { cloneDeep } = lodash;
import baseWillCreate from '../../namespace/relation/willCreate_namespace_relation';
import { constants } from 'lib_ui-services';

export default {
    verb: 'willCreate',
    namespace: 'identity',
    relation: 'backboneUser',
    useCaseIds: [constants.useCaseIds.NUCLEUS],
    priority: baseWillCreate.priority - 5, //after the rule that adds the offlineAction data.
    description: 'Exclude password from body sent normally',
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data }) {
    //make sure this stays unchanged:
    data.newRecord = cloneDeep(data.newRecord);

    //but remove it from what gets sent to the server.
    delete data.offlineAction.meta.offline.effect.newRecord.password;
    delete data.offlineAction.create.newRecord.password;

    return data;
}
