import { createElement as rc, useCallback } from 'react';
import PropTypes from 'prop-types';
import { View, styled, fromTheme, h5, List } from 'lib_ui-primitives';
import useEventSink from '../../../hooks/useEventSink';
import useDbView from '../../../hooks/useDbView';
import { ObjectId } from 'lib_ui-services';
import useCardBody from './useCardBody';

const _p = { useDbView };
export const _private = _p;

const _CardList = styled(View).attrs({ name: 'card-list' })`
    min-height: 100px;
    flex-grow: 1;
    flex-direction: row;
`;

const Row = styled(View)`
    padding: ${fromTheme('viewPaddingMore')};
`;

/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function CardList(props) {
    const {
        hNode,
        hNode: { id, namespace, relation, rowClickAction },
        ...otherProps
    } = props || { hNodes: {} };
    const [subscribe, publish] = useEventSink();
    const { records, recordCount, viewReady } = _p.useDbView(namespace, relation, undefined, hNode);

    // useCallback avoids rerenders of List
    const onClick = useCallback(
        item => {
            if (rowClickAction === 'nothing') return;
            publish({ _id: item._id }, { verb: rowClickAction, namespace, relation });
        },
        [publish, namespace, relation, rowClickAction]
    );

    //used in lib_ui-primitives\src\components\List\RenderNotifyingRow.js (Which is never used...)
    const onRenderItem = useCallback(
        ({ item, updateItem, signal }) => {
            let aborted = false;
            const eventId = new ObjectId().toString();

            const unsubscribe = subscribe({ verb: 'didGet', namespace, relation, status: 'success' }, payload => {
                const {
                    result: [updatedItem]
                } = payload;

                if (item._id === updatedItem._id) {
                    if (!aborted) {
                        updateItem(updatedItem);
                    }
                }
            });
            signal.addEventListener('abort', () => {
                aborted = true;
                //unsubscribe event listeners here.
                unsubscribe();
            });
            publish({ result: [item] }, { verb: 'didGet', namespace, relation, eventId });
        },
        [subscribe, publish, namespace, relation]
    );

    const RowDetail = useCardBody(hNode);

    if (!viewReady) {
        return rc(View, null, rc(h5, null, 'Loading...'));
    }
    // prettier-ignore
    return rc(_CardList, {
            hNode,
            id,
            records,
            recordCount,
            onClick,
            listType: 'cards',
            ...otherProps
        }, rc(List, {
            id, data: records, itemCount: recordCount, onClick, onRenderItem, Row, RowDetail, listType: 'cards'
        })
    );
}

CardList.defaultProps = {};

CardList.propTypes = {
    hNode: PropTypes.object.isRequired,
    currentRoute: PropTypes.string.isRequired
};

export default CardList;
