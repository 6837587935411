/**
 * Remove many CRUD
 * @param {object} action persist action dispatched to remove a document
 */
export default database => (payload, context) => {
    const { namespace, relation } = context;
    const { trueDelete, criteria } = payload;
    if (!trueDelete) {
        throw new Error('removeMany is not implemented without trueDelete set to true.');
    }
    const getResult = database.relationDb(namespace, relation).removeMany(criteria);

    return getResult.then(result => database.processSuccess([], { ...payload, result }, context));
};
