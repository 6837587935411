import { ObjectId } from 'lib_ui-services';

export default {
    verb: 'doingUpdate',
    namespace: 'metadata',
    relation: 'useCaseDetail',
    description: 'Add the patch record after locking a use case version',
    status: 'success',
    prerequisites: [],
    logic: updateSuccess
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function updateSuccess({ data, dispatch }) {
    // If the user isn't locking the use case version, nothing to do here
    if (!isLocking({ data })) return data;

    const { newRecord: useCaseDetail, oldRecord } = data;
    const { _id, title, version } = useCaseDetail;

    // Update the patch record with the new version and the locked status
    const patchRecord = {
        _id: ObjectId(),
        'metadata:useCaseDetail': {
            _id,
            title
        },
        patches: [
            {
                op: 'update',
                path: '/version',
                value: version,
                oldValue: oldRecord.version
            },
            {
                op: 'update',
                path: '/locked',
                value: true,
                oldValue: false
            }
        ]
    };

    // create a patch record
    dispatch({ newRecord: patchRecord }, { verb: 'create', namespace: 'metadata', relation: 'changeHistory' });
}

/**
 * Check if the use case version is being locked
 * @param {object} data
 * @returns {boolean}
 */
function isLocking({ data }) {
    return data.newRecord.locked === true && data.patch.find(p => p.op !== 'test' && p.path === '/locked');
}
