import { createElement, memo } from 'react';
import { Caret } from 'lib_ui-primitives';
const { up, down, left, right } = Caret.DIRECTION;
const rc = createElement;

function ArrowIcon(props) {
    const { isOpen, closed = down, open = up, ...otherProps } = props;
    return rc(Caret, {
        isOpen,
        closed,
        open,
        ...otherProps
    });
}
export default memo(ArrowIcon);
export const DIRECTION = { up, down, left, right };
