import { createElement as rc } from 'react';
import { webOnlyProperties } from 'lib_ui-services';
export default function RenderDefaultRow(props) {
    const {
        //Nested components
        Row,
        RowDetail,
        //the actual data
        item,
        selectedItem,
        //some indexes
        index,
        highlightedIndex,
        //the oh-la-la
        theme,
        style,
        //externally defined properties
        rowProps: _rowProps,
        getItemProps,
        //callback methods
        onClick,
        isLastItem
        // onRender  - don't bother with this (used by Render Notifying Row)
    } = props || {};

    let rowProps = _rowProps;

    // if getItemProps is passed, it means this is a dropdown list created using downshift.
    // Downshift wants to add additional properties.
    if (getItemProps != null) {
        rowProps = {
            ...rowProps,
            ...getItemProps({
                item,
                index,
                rowIndex: index, // required by styling as well
                isActive: highlightedIndex === index,
                isSelected: selectedItem === item,
                ...webOnlyProperties({ style })
            })
        };
    }
    // prettier-ignore
    return rc(Row, rowProps,
        rc(RowDetail, {
            item,
            theme,
            rowIndex: index,
            onClick,
            testID: `listRow${index}`,
            // Needed for Appium testing
            accessibility: true,
            accessibilityLabel: `listRow${index}`,
            isLastItem
        })
    );
}
