import { useState, useCallback, createElement as rc } from 'react';
import { Icon, View, fromTheme, webOnlyStyles, styled } from 'lib_ui-primitives';

const ToggleGroup = styled(View)`
    background-color: ${fromTheme('backgroundColorDarker')};
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: stretch;
    gap: 3px;
    margin: 6px;
`;

let ToggleIcon = styled(Icon).attrs(({ selected }) => ({
    iconSize: '18px',
    fontColor: selected ? '#009F53' : undefined
}))`
    background-color: ${props =>
        props.selected
            ? fromTheme('colorScheme', 'background-dark')(props)
            : fromTheme('backgroundColorDarker')(props)};
    padding: 3px;
    max-width: 24px;
`;
ToggleIcon = webOnlyStyles(ToggleIcon)`
    cursor: pointer;
    &:hover {
        background-color: ${fromTheme('colorScheme', 'background-dark')};
    }
`;

export function ToggleButtons(props) {
    const { options = [], onChange } = props || {};
    const [selected, setSelected] = useState();

    const onClick = useCallback(
        key => {
            if (selected === key) {
                setSelected(null);
                onChange?.(null);
            } else {
                setSelected(key);
                onChange?.(key);
            }
        },
        [selected, onChange]
    );
    return rc(
        ToggleGroup,
        null,
        options.map(option =>
            rc(
                ToggleIcon,
                {
                    key: option.key,
                    'data-testid': `${option.description || option.key}${
                        selected && selected !== option.key ? '-disabled' : ''
                    }${selected === option.key ? '-selected' : ''}`,
                    title: option.description || option.key,
                    disabled: selected && selected !== option.key,
                    selected: selected === option.key,
                    onClick: () => onClick(option.key)
                },
                option.icon
            )
        )
    );
}

export default ToggleButtons;
