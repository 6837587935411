import react, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled, Image, testProperties } from 'lib_ui-primitives';
import lodash from 'lodash';
const { get } = lodash;
import { getImage, TRANSPARENT_PIXEL } from '../../../../../images';

const rc = react.createElement;

const Logo = styled(Image).attrs(() => ({
    name: 'ISO/IEC 29160:2020',
    alt: 'ISO/IEC 29160:2020'
}))`
    position: absolute;
    width: 20px;
    ${({ top, left, bottom, right }) => {
        let result = '';
        if (top) result += `top: ${top};`;
        if (left) result += `left: ${left};`;
        if (bottom) result += `bottom: ${bottom};`;
        if (right) result += `right: ${right};`;
        return result;
    }}
`;

export default function RfidContent(props) {
    const {
        record,
        hNode: { propertyName = 'tagId', alignTop = false, alignRight = false }
    } = props || { hNode: {} };
    const value = get(record, propertyName, '').trim();

    const [src, setImage] = useState(TRANSPARENT_PIXEL);
    useEffect(() => {
        let allowStateUpdates = true;
        const loadImage = async () => {
            const image = await getImage('rfid.png');
            if (allowStateUpdates) {
                setImage(image);
            }
        };
        loadImage();
        return () => (allowStateUpdates = false);
    }, []);

    const [top, right, bottom, left] = useMemo(() => {
        if (alignTop && alignRight) return ['5px', '5px', null, null];
        if (alignTop && !alignRight) return ['5px', null, null, '5px'];
        if (!alignTop && alignRight) return [null, '5px', '5px', null];
        //default: bottom left:
        return [null, null, '5px', '5px'];
    }, [alignTop, alignRight]);

    return rc(Logo, { src, top, right, bottom, left, ...testProperties({ testID: `rfid-${value}` }) });
}

RfidContent.propTypes = {
    hNode: PropTypes.shape({
        propertyName: PropTypes.string,
        includeHumanReadable: PropTypes.bool,
        excludeHumanReadable: PropTypes.bool
    }),
    record: PropTypes.object
};

/**
 *
 * // the x,y coordinate of the image:
 * ^FO150,150
 * // the actual image (5mmx5mm at 200dpi):
 * ^GFA,265,320,8,:Z64:eJzTe/doAQMQ1P8//AFEV61atA9EOzAwsIPoBwwM3AfAfEYHBzCf+YcBRF5GACLPtwOqngciv7gPor7wCER+xzuoejcwn4kvCcxnZ0kG8/nPPQbz5Y8kQvhNShD5Boj9/A1Q9zBA3cPADOWD6MePf+9rBtLuHy/KHgTxrRfvApnnLMgo4Azi71694zFY/qOAI1j9YguwvCNE/tHjZoh8IaMoSP8j62b7ZiTzke0DBYj9/////wFp8fr6+hIgDQAzDEao:7C08
 */
