import { styled, h3, Form as PrimitiveForm, Fieldset, View, fromTheme, webOnlyStyles } from 'lib_ui-primitives';

export const Loading = styled(h3)`
    margin: ${fromTheme('textMargin')};
`;

// Flex shrink is needed for scrolling of forms inside of dialogs when the
// form is taller than the dialog.
let _StyledForm = styled(PrimitiveForm).attrs({ name: 'styled-form' })`
    background-color: transparent;
    flex-grow: ${props => (props.heightGreedy ? 1 : 0)};
    width: 100%;
    flex-shrink: 1;
    max-height: ${({ theme: { height, native } }) => (native ? 9999 : height)}px;
`;
_StyledForm = webOnlyStyles(_StyledForm)`
    overflow-y: ${({ scrollWholeForm }) => (scrollWholeForm ? 'auto' : 'hidden')};
    overflow-x: hidden;
`;
export const StyledForm = _StyledForm;
StyledForm.displayName = 'StyledForm';

// max-width here keeps the footer from flowing off the screen on
// small mobile devices.
let _FormFooter = styled(View).attrs({ name: 'form-footer' })`
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex-wrap: wrap;
    justify-content: flex-end;
    border-top-width: ${({ theme }) => (theme.mobile ? '1px' : '0')};
    border-top-color: ${fromTheme('borderColor')};
    max-width: ${fromTheme('width')};
`;

export const FormFooter = webOnlyStyles(_FormFooter)`
    border-top-style: solid;
`;
FormFooter.displayName = 'FormFooter';

export const ErrorText = styled(h3).attrs({ name: 'error-text' })`
    font-size: ${fromTheme('fontSize')};
    color: ${fromTheme('colorScheme', 'error')};
    margin: ${fromTheme('textMargin')};
`;
ErrorText.displayName = 'ErrorText';

export const ButtonBar = styled(View).attrs({ name: 'button-bar' })`
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: space-around;
    min-height: 32px;
    margin: 6px;
`;
ButtonBar.displayName = 'ButtonBar';

export const WrappingFieldset = styled(Fieldset).attrs({ name: 'wrapping-fieldset' })`
    flex-wrap: wrap;
`;
WrappingFieldset.displayName = 'WrappingFieldset';

let _GreedyOtherElements = styled(View).attrs({ name: 'greedy-other-elements' })`
    flex-basis: 1px;
    flex-grow: 1;
    margin-left: ${({ theme: { viewMargin, mobile } }) => (mobile ? 0 : viewMargin + 'px')};
    flex-direction: column;
    min-height: ${({ scrollWholeForm, theme: { height } }) => (scrollWholeForm ? height / 2 : 0)}px; */
`;

_GreedyOtherElements = webOnlyStyles(_GreedyOtherElements)`
    overflow-y: ${({ scrollWholeForm }) => (scrollWholeForm ? 'visible' : 'hidden')};
`;

export const GreedyOtherElements = _GreedyOtherElements;
GreedyOtherElements.displayName = 'GreedyOtherElements';
