import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import styled from '../styled';
import fromTheme from '../fromTheme';

const TextStyle = styled.span.attrs(props => ({ name: props.name ?? 'text' }))`
    font-size: ${fromTheme('fontSize')};
    font-family: ${fromTheme('font')};
    color: ${({ theme }) => theme.defaultFontColor};
`;
TextStyle.displayName = 'TextStyle';

export default function Text({ children, id, testID, 'data-testid': dataTestId, ...otherProps }) {
    if (Array.isArray(children) && children.some(child => typeof child !== 'string')) {
        throw new Error('Text component expects children to be strings.');
    } else if (typeof children === 'number') {
        children = String(children);
    } else if (typeof children !== 'string' && children !== null && children !== undefined) {
        throw new Error(`Text component received invalid child type: ${typeof children}`);
    }

    return rc(TextStyle, { 'data-testid': dataTestId ?? testID ?? id, ...otherProps }, children);
}

Text.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
    id: PropTypes.string,
    testID: PropTypes.string,
    'data-testid': PropTypes.string
};
