import { createElement as rc, useMemo, useEffect, useState } from 'react';
import { helpers } from '@sstdev/lib_metadata-config';
import PropTypes from 'prop-types';
import { Text } from 'lib_ui-primitives';
import useEventSink from '../../hooks/useEventSink';
import JsonViewer from '../dataView/JsonViewer';

function DataDictionaryViewer(props) {
    const [subscribe, publish] = useEventSink();

    const [tree, setTree] = useState();

    useEffect(() => {
        let allowStateUpdate = true;
        const unsubscribe = subscribe(
            { verb: 'create', namespace: 'metaui', relation: 'dataDictionary', status: 'success' },
            ({ result }) => {
                if (result && allowStateUpdate) {
                    setTree(result);
                }
            }
        );
        if (!tree) {
            publish({}, { verb: 'view', namespace: 'metaui', relation: 'dataDictionary', status: 'success' });
        }
        return () => {
            unsubscribe();
            allowStateUpdate = false;
        };
    }, [setTree, tree, subscribe, publish]);

    const jsonDictionary = useMemo(() => treeToJson(tree), [tree]);

    if (!tree) {
        return rc(Text, null, 'Loading...');
    }
    if (!Object.keys(jsonDictionary).length) {
        return rc(Text, null, 'Add blocks to create metadata.');
    }

    return rc(JsonViewer, { ...props, collapsed: 2, tree: jsonDictionary });
}

DataDictionaryViewer.propTypes = {
    hNode: PropTypes.object
};
export default DataDictionaryViewer;

function treeToJson(dataDictionary = {}) {
    let childrenKeys = Object.keys(dataDictionary || {}).filter(function (child) {
        return child !== '_meta';
    });
    if (!childrenKeys.length) {
        if (dataDictionary._meta) {
            return helpers.getJavaScriptTypeForHNodeType(dataDictionary._meta) || {}; //{ title: 'string' };
        }
        return '';
    }
    return childrenKeys.reduce((result, key) => {
        return { ...result, [key]: treeToJson(dataDictionary[key]) };
    }, {});
}
