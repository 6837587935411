import { hooks } from 'lib_ui-primitives';
import { useCallback, useState, useEffect } from 'react';

const { useRouter } = hooks;

const _p = {
    useRouter
};
export const _private = _p;
/**
 * This acts like useState, but retains the state within the route. If the user navigates
 * to another route it will have its own state.  If the user returns to the first route,
 * the previously stored state will be available until the browser page/app is restarted.
 * @param {string} stateName - name of state to store/retrieve
 * @param {any} defaultValue - the default value to store/retrieve
 * @returns array containing a the current state and the function to change it
 */
export default function useRouteBoundedState(stateName, defaultValue) {
    const { getRouteState, setRouteState } = _p.useRouter();
    const [routeState, setLocalRouteState] = useState(getRouteState(stateName) || defaultValue);

    // If the route state for the state name is empty and there is a default value,
    // then populate the route state for the state name with the default value.
    useEffect(() => {
        if (getRouteState(stateName) == null && defaultValue != null) {
            setRouteState(stateName, defaultValue);
        }
    }, [stateName, defaultValue, getRouteState, setRouteState]);

    const combinedSetState = useCallback(
        newState => {
            // Store the new state by route -- this will be retained until the
            // browser is refreshed or app reloaded.
            setRouteState(stateName, newState);
            // store state locally -- this will cause a rerender on consumers
            setLocalRouteState(newState);
        },
        [setRouteState, stateName]
    );

    return [routeState, combinedSetState];
}
