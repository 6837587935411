import getColumnValue from './getColumnValue';

/**
 * Retrieves nested values from an object or array of objects.
 *
 * @param {Object|Array} value - The object or array of objects to retrieve the nested values from.
 * @param {string} nestedField - The field name to retrieve the value from.
 * @param {Object} context - Additional context that might be needed for value retrieval.
 * @returns {Array} - An array of unique nested values.
 */
export function getNestedValues(value, nestedField, context) {
    if (Array.isArray(value)) {
        if (!value.length) return [];

        const values = [...new Set(value.map(entry => getColumnValue(entry, nestedField, context)).filter(x => !!x))];
        return values;
    }
    return [getColumnValue(value, nestedField, context)];
}
