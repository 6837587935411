import lodash from 'lodash';
const { cloneDeep } = lodash;
import { createElement as rc, useMemo, useCallback } from 'react';
import { View, Spinner } from 'lib_ui-primitives';
import useFormControl from '../../../hooks/useFormControl';
import { ColumnSelector as _ColumnSelector, Title, ColumnArea, Dimmed } from './styles';
import { default as ImportColumn } from './ImportColumn';
import createTreePositionGetter from '../../../utilities/createTreePositionGetter';

// padding-top: ${fromTheme('viewPaddingMore')};

// Always false so that label floats permanently (instead of overlapping the control).
const fieldEmpty = false;
const active = false;
const EMPTY_ARRAY = [];

const _p = {
    useFormControl,
    validateColumns
};
export const _private = _p;

const ColumnSelector = props => {
    const {
        hNode: { id, treePosition },
        foreignNamespace,
        foreignRelation,
        dataModel = [],
        dataSample = [],
        firstRowContainsHeader = true,
        previewSize = 5
    } = props || { hNode: {} };

    const { title, value, setValue } = _p.useFormControl(props, EMPTY_ARRAY);
    const errors = useMemo(
        () => _p.validateColumns(value, dataModel, foreignNamespace, foreignRelation),
        [value, dataModel, foreignNamespace, foreignRelation]
    );

    const onChange = useCallback(
        (newField, columnIndex) => {
            // Avoid mutating the original value
            const columns = [...value];
            columns[columnIndex] = cloneDeep(newField);
            if (newField?._meta?.id && newField._meta.id !== '_skip') {
                // remove any other occurrence
                columns.forEach((element, i) => {
                    if (i !== columnIndex && element?._meta?.id === newField._meta.id) {
                        columns[i] = {};
                    }
                });
            }

            setValue(columns);
        },
        [value, setValue]
    );

    // This is needed for the one dropdown which is inside each of the ImportColumn components.
    const getTreePosition = createTreePositionGetter(treePosition, value.length);
    const isLoading = !value?.length;
    // prettier-ignore
    return rc(_ColumnSelector, null,
        rc(Title, { id, title, active, fieldEmpty, errors }),
        isLoading && rc(Spinner),
        !isLoading && rc(ColumnArea, null,
            value.map((field, i) => {
                const data = dataSample.map(row => (row?.length >= i) ? row[i] : undefined);
                return rc(ImportColumn, {
                    key: `column-${i}`,
                    id: `ic${i}`,
                    dataModel,
                    field,
                    firstRowContainsHeader,
                    previewSize,
                    data,
                    onChange: x => onChange(x, i),
                    treePosition: getTreePosition(i)
                });
            })
        ),
        rc(View, null, rc(Dimmed, null, '* Required Field'))
    );
};

function validateColumns(columns, dataModel, foreignNamespace, foreignRelation) {
    if (!columns?.length) return [];
    const requiredColumns = dataModel.filter(d => d._meta && d._meta.required);
    const missingColumns = requiredColumns.filter(r => !columns.find(c => c?._meta && c._meta.id === r._meta.id));
    const unassignedColumns = columns.some(c => !c?._meta);
    let errors = [];

    // Custom validation for organization:person
    // At least _one_ of the required columns should be present
    if (foreignNamespace === 'organization' && foreignRelation === 'person') {
        const requiredPersonColumns = dataModel.filter(d => ['lastName', 'personId'].includes(d?._meta?.propertyName));
        const missingPersonColumns = requiredPersonColumns.filter(
            r => !columns.some(c => c?._meta?.propertyName === r?._meta?.propertyName)
        );
        if (missingPersonColumns.length === requiredPersonColumns.length) {
            errors = missingPersonColumns.map(m => `Column ${m._meta.title} is required.`);
        }
    }

    if (missingColumns.length) {
        errors = missingColumns.map(m => `Column ${m._meta.title} is required.`);
    }

    if (unassignedColumns) {
        errors.push('Please assign or exclude all unmatched columns.');
    }

    return errors;
}

export default ColumnSelector;
