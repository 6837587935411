import getGlobalConfig from '../globalConfig';

const _private = { getGlobalConfig };
export default {
    getFilter,
    getUriComponent,
    fromHNode,
    _private
};

function fromHNode({ limit }) {
    return getFilter(1, limit);
}

function getFilter(page = 0, limit, currentOffset) {
    return {
        page,
        limit,
        currentOffset
    };
}

function getUriComponent(filters, limitSyncSize) {
    const { backgroundSyncBatchSize, pageSizeForLimitSyncSizeRelations } = _private.getGlobalConfig();
    const {
        paged: {
            page = 0,
            currentOffset,
            limit = limitSyncSize ? pageSizeForLimitSyncSizeRelations : backgroundSyncBatchSize
        } = {}
    } = filters;
    if (typeof limit !== 'number') {
        throw new Error('limit must be a number');
    }
    let result = `limit=${encodeURIComponent(limit)}`;
    result += `&offset=${encodeURIComponent(currentOffset ?? limit * page)}`;
    return result;
}
