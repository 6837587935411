//namespace: 'security', relation: 'profile', type: 'offlinePin'
import { session } from 'lib_ui-services';
const { loginWithPin } = session;
export const _private = { loginWithPin };
export default {
    verb: 'doingCreate',
    namespace: 'security',
    relation: 'profile',
    type: 'offlinePin',
    prerequisites: [],
    description: 'Authenticate a user using offline PIN',
    //this is the actual logic:
    logic: offlinePin
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function offlinePin({ data }) {
    return _private.loginWithPin(data.newRecord);
}
