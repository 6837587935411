//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _PatchDetailTable from './PatchDetailTable';
import _getNestedPatchDetailColumnHNodes from './getNestedPatchDetailColumnHNodes';
export const PatchDetailTable = _PatchDetailTable;
export const getNestedPatchDetailColumnHNodes = _getNestedPatchDetailColumnHNodes;
export default {PatchDetailTable, getNestedPatchDetailColumnHNodes};

