import { useEffect, createElement as rc, useState, useMemo } from 'react';
import { Text, ValidationError, hooks } from 'lib_ui-primitives';
import { BarcodeContent, FixedTextContent, ReferencedContent, RfidContent, QRContent } from './labelContent';
import useEventSink from '../../../hooks/useEventSink';
import useLabelSize from './hooks/useLabelSize';
import usePreviewData from './hooks/usePreviewData';
import useLabelTemplate from './hooks/useLabelTemplate';
import useCustomFormUpdater from './hooks/useCustomFormUpdater';
import { Label, PreviewArea, PreviewContainer, FixedContainer } from './styles';
import { getImage, TRANSPARENT_PIXEL } from '../../../../images';

const { useRouter } = hooks;

const labelContentTypes = { BarcodeContent, FixedTextContent, ReferencedContent, QRContent };

const _p = {
    useRouter,
    useLabelSize,
    useLabelTemplate,
    usePreviewData,
    useCustomFormUpdater
};
export const _private = _p;

export default function PrintPreview(props) {
    const {
        hNode: { namespace, relation }
    } = props || { hNode: {} };

    const [, publish] = useEventSink();
    const router = _p.useRouter();

    const { inTemplateDesignMode, template } = _p.useLabelTemplate(props);
    const labelSpec = _p.useLabelSize(template);
    const [labelWidth, setLabelWidth] = useState(labelSpec.labelWidth || 200);
    const { dataToPrint, totalRecords, errors, selection } = _p.usePreviewData(props, template.actions);

    _p.useCustomFormUpdater({
        labelContent: template.actions,
        labelSpec,
        foreignNamespace: namespace,
        foreignRelation: relation,
        selection,
        routePath: router.location.pathname
    });

    //only call the first time the preview is loaded so as to trigger populating the printer list
    useEffect(() => {
        publish({}, { verb: 'get', namespace: 'print', relation: 'printer', type: 'find' });
    }, [publish]);

    const [displayedContent, rfidContent, qrContent] = useMemo(
        () =>
            template.actions?.reduce(
                ([content, rfid, qr], hNode) => {
                    if (hNode.hNodeType === 'RfidContent') {
                        return [content, hNode, qr];
                    }
                    if (hNode.hNodeType === 'QRContent') {
                        return [content, rfid, hNode];
                    }
                    return [[...content, hNode], rfid, qr];
                },
                [[], null, null]
            ),
        [template.actions]
    );

    useEffect(() => {
        if (qrContent) {
            setLabelWidth((labelSpec.labelWidth || 200) - 50);
        } else {
            setLabelWidth(labelSpec.labelWidth || 200);
        }
    }, [labelSpec.labelWidth, qrContent]);

    const [backgroundImage, setImage] = useState(TRANSPARENT_PIXEL);
    useEffect(() => {
        let allowStateUpdates = true;
        const loadImage = async () => {
            const image = await getImage('rfidInlay.png');
            if (allowStateUpdates) {
                setImage(image);
            }
        };
        if (labelSpec.rfid) {
            loadImage();
        } else {
            setImage(TRANSPARENT_PIXEL);
        }
        return () => (allowStateUpdates = false);
    }, [labelSpec]);

    // prettier-ignore
    return rc(PreviewContainer, null,
        rc(PreviewArea,
            { id: props.hNode.id, name: 'Print-Preview'},
            dataToPrint.map(record =>
                {
                    let printedLines = displayedContent.map((hNode, i) => {
                        if (!labelContentTypes[hNode.hNodeType]) return null;
                        return rc(labelContentTypes[hNode.hNodeType], {
                            key: `${record._id}-${i}PrintPreview`,
                            record,
                            label: { labelWidth },
                            hNode
                        });
                    });
                
                    if (qrContent) {
                        printedLines = [
                            rc(QRContent, {
                                key: `${record._id}-barcode-PrintPreview`,
                                record,
                                label: labelSpec,
                                hNode: qrContent
                            }, printedLines),
                        ];
                    }
                    return rc(Label,
                        { key: `${record._id}PrintPreview`, ...labelSpec, backgroundImage  },
                        printedLines,
                        rfidContent && rc(RfidContent, { key: `${record._id}-rfid-PrintPreview`, record, label: labelSpec, hNode: rfidContent })
                    );
                }
            )

        ),
        !inTemplateDesignMode && rc(FixedContainer, null, rc(Text, null, `Total ${totalRecords || 0} label${totalRecords === 1 ? '' : 's'}`)),
        rc(FixedContainer,{},rc(ValidationError, { errors })),
        // rc(ExternalLink, { url: 'https://support.redbeam.com/knowledge/setting-up-your-printer', title: 'Printer Setup Instructions' })
    );
}
