import { globalConfig, constants } from 'lib_ui-services';

import genericRule from '../../FORMS/namespace/relation/doingNew_namespace_relation';

export default {
    verb: 'doingNew',
    namespace: 'sensor',
    relation: 'tag',
    description: genericRule.description,
    //this is the actual logic:
    logic: payload => {
        payload.dispatch(
            {
                sensorType: constants.sensorTypes.RFID,
                scanType: 'Encode',
                intervalMilliseconds: globalConfig().sensorScanIntervalMilliseconds,
                mergingNamespace: 'item',
                mergingRelation: 'item'
            },
            { verb: 'startup', namespace: 'sensor', relation: 'service' }
        );
        return genericRule.logic(payload);
    }
};
