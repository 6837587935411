export default {
    getFilter,
    fromHNode
};

/**
 * @param {{[key: string]: any}} hNode
 * @param {string} hNode.propertyName
 * @param {*} hNode.defaultSelectedValue
 */
function fromHNode({ propertyName, defaultSelectedValue }) {
    return getFilter(propertyName, defaultSelectedValue);
}

/**
 * @param {string} propertyName
 * @param {*} defaultSelectedValue
 */
function getFilter(propertyName = 'title', defaultSelectedValue) {
    return {
        propertyName,
        defaultSelectedValue
    };
}
