import { useContext, useEffect, useState } from 'react';
import { contexts } from 'lib_ui-primitives';
export default function useIsLoading(namespace, relation) {
    const { addStateSubscribers } = useContext(contexts.LoadingContext);

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        return addStateSubscribers(namespace, relation, newPendingLoadIds => {
            setIsLoading(newPendingLoadIds.length > 0);
        });
    }, [namespace, relation, addStateSubscribers]);

    return isLoading;
}
