import { errors as _errors } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';

export default {
    verb: 'doingValidate',
    namespace: 'import',
    relation: 'import',
    description: 'Prevent import while 1 already active',
    useCaseIds: [constants.useCaseIds.FLAIR],
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'inventory',
                relation: 'inventory',
                type: 'find'
            },
            query: {
                'inventory:status.title': 'Active'
            }
        }
    ],
    //this is the actual logic:
    logic: validation
};

async function validation({ prerequisiteResults }) {
    if (prerequisiteResults?.[0]?.result?.length) {
        let message = 'Unable to import. Please close the Active Inventory first.';
        throw new _errors.ValidationError(message, {});
    }
}
