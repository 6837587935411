import { createElement as rc } from 'react';
import CollapsibleHierarchyRowContext from './CollapsibleHierarchyRowContext';
import useCollapsibleHierarchy from './useCollapsibleHierarchy';
import logging from '@sstdev/lib_logging';

/**
 * Creates a higher-order component that adds collapsible functionality to a row component.
 * This higher-order component will wrap the given component with a CollapsibleHierarchyRowContext.Provider
 * and provides the row's coordinates to the component similar to the way
 * react.forwardRef() works.
 * This must be used in conjunction with the makeCollapsible component.
 * @param {React.Component} Component - The row component to be wrapped.
 * @returns {React.Component} - The wrapped component function.
 * @throws {Error} - Throws an error if the 'index' prop is not provided.
 * @example
 * // Create a collapsible row component
 * const CollapsibleRow = makeCollapsibleRow((props, { rowCoordinates }) => {
 *   returns < a component which may contain collapsible rows />
 */
export default function makeCollapsibleRow(Component) {
    return props => {
        const index = props.index;
        if (index == null) {
            throw new Error('index is a required property for a collapsible row');
        }
        const { currentHierarchyRowCoordinates } = useCollapsibleHierarchy();
        const rowCoordinates = [...currentHierarchyRowCoordinates, index];
        logging.debug(`[COLLAPSIBLE] makeCollapsibleRow: ${props?.propertyName} coordinates: ${rowCoordinates}`);
        const reactComponentInstance = Component(props, { rowCoordinates });
        const context = { rowCoordinates };
        // prettier-ignore
        return rc(CollapsibleHierarchyRowContext.Provider, { value: context, key: rowCoordinates.join('-') },
            reactComponentInstance
        );
    };
}
