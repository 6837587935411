//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _doingChange_sensor_service from './doingChange_sensor_service';
import _doingGet_sensor_service from './doingGet_sensor_service';
import _doingRegister_sensor_service from './doingRegister_sensor_service';
import _doingStartup_sensor_service from './doingStartup_sensor_service';
import _doingStop_sensor_service from './doingStop_sensor_service';
export const doingChange_sensor_service = _doingChange_sensor_service;
export const doingGet_sensor_service = _doingGet_sensor_service;
export const doingRegister_sensor_service = _doingRegister_sensor_service;
export const doingStartup_sensor_service = _doingStartup_sensor_service;
export const doingStop_sensor_service = _doingStop_sensor_service;
export default {doingChange_sensor_service, doingGet_sensor_service, doingRegister_sensor_service, doingStartup_sensor_service, doingStop_sensor_service};

