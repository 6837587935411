import { metadata } from 'lib_ui-services';

const _p = {
    getMinimumForeignKeyFieldsFromDictionary: metadata.getMinimumForeignKeyFieldsFromDictionary
};
export const _private = _p;

export default async function getLocationInfo(roomLocation, dispatch) {
    let newLocation;
    // If the Id was passed in as a string, look up the room, otherwise
    // It should already be a room location object.
    if (typeof roomLocation === 'string') {
        const newLocationResp = await dispatch(
            {
                criteria: { _id: roomLocation }
            },
            { verb: 'get', namespace: 'location', relation: 'location', type: 'find' },
            true
        );
        newLocation = newLocationResp.result[0];
    } else if (typeof roomLocation === 'object' && roomLocation._id != null) {
        newLocation = roomLocation;
    } else {
        throw new Error('Unexpected type passed into the roomLocation parameter for getLocationInfo.');
    }

    const locationInfo = {
        'location:location': await _p.getMinimumForeignKeyFieldsFromDictionary(
            newLocation,
            'item',
            'item',
            'location',
            'location'
        )
    };

    // If no building is specified on the newLocation param, just return now.
    if (!newLocation['location:building']) return locationInfo;

    // We should already have the location building foreign key from the location record
    locationInfo['location:building'] = newLocation['location:building'];

    // Get the company foreign key from the stored building record
    const buildingResp = await dispatch(
        {
            criteria: { _id: locationInfo['location:building']._id }
        },
        { verb: 'get', namespace: 'location', relation: 'building', type: 'find' },
        true
    );
    locationInfo['location:company'] = buildingResp.result[0]['location:company'];

    return locationInfo;
}
