import { UserError } from '@sstdev/lib_logging';
class ValidationError extends UserError {
    constructor(formMessage, fieldMessages) {
        super(formMessage);
        if (typeof formMessage !== 'string') {
            throw new Error('The formMessage parameter must be a string.');
        }
        if (typeof fieldMessages != 'object') {
            throw new Error(
                'The fieldMessages parameter must be an object with keys equal to the field names with errors and values equal to the error message for the field.'
            );
        }
        this.fieldMessages = fieldMessages;
        this.validationObject = { field: fieldMessages, form: [formMessage] };
    }
}

export default ValidationError;
