//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _willCreate_mqtt_users from './willCreate_mqtt_users';
import _willUpdate_mqtt_users from './willUpdate_mqtt_users';
export const willCreate_mqtt_users = _willCreate_mqtt_users;
export const willUpdate_mqtt_users = _willUpdate_mqtt_users;
export default {willCreate_mqtt_users, willUpdate_mqtt_users};

