import logging from '@sstdev/lib_logging';
import sensorTypes from '../constants/sensorTypes';

let dispatch, scanningTimeoutId;
let currentScanConfig;

const _p = {
    NativeModules: {},
    DeviceEventEmitter: {}
};

const init = function init(dispatchToRulesEngine) {
    dispatch = dispatchToRulesEngine;
    startupSensorService(
        {
            intervalMilliseconds: 100,
            scanType: 'OnRequest',
            sensorType: 'BARCODE'
        },
        {
            verb: 'startup',
            namespace: 'sensor',
            relation: 'service'
        }
    );
};

export default (NativeModules, DeviceEventEmitter) => {
    logging.debug('[BARCODE]: scan service pre-initialized.'); // not sure what else to call this :)
    _p.NativeModules = NativeModules;
    _p.DeviceEventEmitter = DeviceEventEmitter;
    return {
        init,
        startupSensorService,
        stopSensorService,
        scan,
        stopScanning,
        isStarted,
        // update, //Barcode does not support any form of updating at this time.
        dispose,
        // Currently we do not have a non-zebra native barcode scanner (like the image scanner we have for web).
        // Because of this, the scan button is not displayed at all on the native UI for native devices.
        // (The scanner on zebra devices is activated using the yellow hardware buttons).  At the point that we
        // add a non-zebra barcode scanner, this method should be implemented (the rough implementation is already
        // in the java code), and the UI should be changed to look at this method in determining which reader to call.
        isReaderAvailable: () => true,
        _private: _p
    };
};

function handleRead(reads) {
    logging.debug(`[BARCODE] received ${reads.length}`);
    clearTimeout(scanningTimeoutId);
    stopScanning();

    const processedTags = reads.map(rawRead => {
        //make it look similar to the RFID output:
        const { scanData: asciiValue } = rawRead;
        const tag = { ...currentScanConfig, sensorType: sensorTypes.BARCODE };
        tag['asciiTagId'] = asciiValue;
        tag['_id'] = asciiValue;
        logging.debug('[BARCODE]: ' + tag.asciiTagId);
        return tag;
    });
    setTimeout(() => {
        dispatch(processedTags, {
            verb: 'change',
            namespace: 'sensor',
            relation: 'read'
        });
    }, 0);
}

// Typically called by an on-screen UI affordance rather than a physical button
function scan() {
    logging.debug('[BARCODE]: direct scan call started.');
    _p.NativeModules.BarcodeReader.startScanning();
    scanningTimeoutId = setTimeout(() => {
        logging.debug('[BARCODE]: direct scan call stopped.');
        _p.NativeModules.BarcodeReader.stopScanning();
    }, currentScanConfig.intervalMilliseconds);
}

let serviceStarted = false;
function isStarted() {
    return serviceStarted;
}

async function startupSensorService(config) {
    // If called twice while processing, return the original promise.
    _p.promiseGate =
        _p.promiseGate ||
        new Promise((resolve, reject) => {
            try {
                if (serviceStarted) {
                    logging.debug(
                        '[BARCODE] Attempting to start the BARCODE service, but it is already started.  This is a noop.'
                    );
                    return resolve(serviceStarted);
                }
                logging.debug('[BARCODE]: starting sensor service');
                if (typeof config.intervalMilliseconds === 'undefined') {
                    throw new Error('intervalMilliseconds config property is required.');
                }

                if (typeof config.scanType === 'undefined') {
                    throw new Error('scanType config property is required.');
                }

                if (config.scanType === 'Continuous') {
                    throw new Error('Continuous scan for Zebra RFID service is not implemented.');
                }

                currentScanConfig = config;

                if (_p.eventListeners?.length > 0) {
                    logging.debug('[BARCODE] removing existing listeners');
                    _p.eventListeners.forEach(el => el.remove());
                }
                _p.eventListeners = [_p.DeviceEventEmitter.addListener('BarcodeScansReceived', handleRead)];
                _p.NativeModules.BarcodeReader.startService()
                    .then(() => {
                        _p.promiseGate = undefined;
                        serviceStarted = true;
                        logging.debug('[BARCODE]: sensor service finished starting');
                        resolve(serviceStarted);
                    })
                    .catch(err => {
                        _p.promiseGate = undefined;
                        serviceStarted = false;
                        logging.error('[RFID] An error occurred while starting the RFID service.');
                        reject(err);
                    });
            } catch (err) {
                _p.promiseGate = undefined;
                serviceStarted = false;
                logging.error('[RFID] An error occurred while starting the RFID service.');
                reject(err);
            }
        });
    return _p.promiseGate;
}

function stopSensorService(payload, context) {
    if (!serviceStarted) {
        logging.error('[BARCODE] Attempted to stop the BARCODE service, but it is already stopped.');
        return;
    }
    serviceStarted = false;
    try {
        logging.debug('[BARCODE]: stopping sensor service');
        currentScanConfig = undefined;
        stopScanning(payload, context);
        _p.eventListeners.forEach(el => el.remove());
        _p.eventListeners = [];
        logging.debug('[BARCODE] LISTENER REMOVED');
        _p.NativeModules.BarcodeReader.stopService();
    } catch (err) {
        logging.error(
            '[BARCODE] An error occurred while stopping the BARCODE service.  It may be in an inconsistent state.'
        );
        throw err;
    }
}

function dispose(payload, context) {
    stopSensorService(payload, context);
}

function stopScanning() {
    _p.NativeModules.BarcodeReader.stopScanning();
    if (scanningTimeoutId != null) {
        clearTimeout(scanningTimeoutId);
        scanningTimeoutId = null;
    }
}
