import notHandledHere from './notHandledHere';
import { database } from 'lib_ui-services';

export default {
    verb: 'doingRemove',
    excludedNamespaceRelations: notHandledHere.filter(f => f.namespace !== 'file'),
    description: 'Remove the requested record from the database',
    //this is the actual logic:
    logic: doingRemove
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @typedef {{trueDelete?: boolean, id:string, meta:object}} RemovePayload
 *
 * @param {{
 *   data: RemovePayload;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {Promise}
 */
async function doingRemove({ data, context }) {
    const db = database.get();
    try {
        const result = await db.remove(data, context);
        return { ...data, result };
    } catch (error) {
        if (
            data.skipConfirm &&
            error.message.includes('Trying to update unsynced document. Please save the document first')
        ) {
            //document was deleted locally, and now we get the confirmation from the server over the sockets
            //all OK:
            return { ...data, result: [] };
        }
        throw error;
    }
}
