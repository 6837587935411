import { dbViews } from 'lib_ui-services';

const { getDbView } = dbViews;

const _p = {
    getDbView
};
export const _private = _p;
/**
 * load all records for namespace_relation_routePath, filtered as displayed
 * then filter it down to only those selected
 * @param {string} namespace
 * @param {string} relation
 * @param {string} routePath
 * @param {{__invertSelection:boolean, <string>:boolean}} selection
 * @returns
 */
export async function getGridData(namespace, relation, routePath, selection) {
    const viewName = `${namespace}_${relation}_${routePath}`;
    const view = await _p.getDbView(namespace, relation, viewName);
    const allRecords = view.data;
    const records = allRecords.filter(isSelectedIn(selection));
    return records;
}
export const isSelectedIn =
    (selection = {}) =>
    x => {
        return XOR(selection.__invertSelection, selection[x._id]);
    };

export function XOR(left, right) {
    return Boolean(left) !== Boolean(right);
}
