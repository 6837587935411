import { database, network } from 'lib_ui-services';
export const _private = { database, getNetworkStatus: network.getStatus };
export default {
    verb: 'doingUpdate',
    namespace: 'application',
    relation: 'useCase',
    type: 'forceResyncAllDataToLocal',
    description: 'Force a full re-sync of all the data with the server',
    //this is the actual logic:
    logic: forceResyncAllDataToLocal
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function forceResyncAllDataToLocal({ data, context, dispatch }) {
    // eslint-disable-next-line no-undef
    if (__DISABLE_SYNC__) return;
    const networkStatus = await _private.getNetworkStatus();
    if (!networkStatus.isServerReachable) {
        const message = 'Unable to perform full sync. Internet is unavailable.';
        dispatch(
            { message, timeout: 5000, addToList: false, isError: true },
            {
                verb: 'pop',
                namespace: 'application',
                relation: 'notification'
            }
        );
        return;
    }

    //start the server-to-client sync:
    await _private.database.isDbReady();
    const db = _private.database.get();
    const result = await db.synchronization.syncAllRelations(data, context, true);
    return result;
}
