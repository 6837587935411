export default database => (tagIds, namespace, relation) => {
    return new Promise((resolve, reject) => {
        try {
            const db = database.relationDb(namespace, relation);
            resolve(db.find({ tagId: { $in: tagIds } }));
        } catch (err) {
            reject(err);
        }
    });
};
