import useReads from './useReads';
import { useTheme } from 'styled-components';
import { constants } from 'lib_ui-services';
const _p = {
    useReads,
    useTheme
};
export const _private = _p;
export default function useReadButtonSelection(hNode, active = true) {
    const { displayScanButton, displayRfidPowerButton, scanRfid, scanBarcode } = hNode;
    const { sensorTypesAvailable } = _p.useReads();
    const { native, mobile } = _p.useTheme();

    // Calculate if we should display these buttons
    let _displayRfidButton = false;
    let _displayBarcodeButton = false;
    let _displayRfidPowerButton = false;

    if (active) {
        // eslint-disable-next-line no-undef
        if ((native || __TEST_RFID__) && scanRfid && sensorTypesAvailable.includes(constants.sensorTypes.RFID)) {
            if (displayRfidPowerButton) {
                _displayRfidPowerButton = true;
            } else if (displayScanButton) {
                _displayRfidButton = true;
            }
        } else if (
            mobile &&
            displayScanButton &&
            scanBarcode &&
            sensorTypesAvailable.includes(constants.sensorTypes.BARCODE)
        ) {
            _displayBarcodeButton = true;
        }
    }
    return {
        displayRfidButton: _displayRfidButton,
        displayBarcodeButton: _displayBarcodeButton,
        displayRfidPowerButton: _displayRfidPowerButton
    };
}
