import { createElement as rc, useContext, useLayoutEffect, useRef, useEffect } from 'react';
import useEventSink from '../../hooks/useEventSink';
import logging from '@sstdev/lib_logging';
import {
    View,
    Slider as _Slider,
    fromTheme,
    styled,
    GestureContext,
    useDebounce,
    Text,
    PlainLabel,
    hooks,
    testProperties
} from 'lib_ui-primitives';
import { globalConfig } from 'lib_ui-services';
const { usePersistentState } = hooks;
const _p = {
    debounceMilliSeconds: globalConfig().actionElementDebounceMilliseconds || 100,
    usePersistentState
};
export const _private = _p;

const Label = styled(PlainLabel).attrs({ name: 'label' })`
    margin-top: ${fromTheme('viewMargin')};
    font-size: ${fromTheme('fontSizeLabel')};
    overflow: hidden;
    text-align: left;
    color: ${fromTheme('disabledLabelColor')};
`;
Label.displayName = 'Label';

const ValueText = styled(Text).attrs({ name: 'value-text' })`
    font-size: ${fromTheme('fontSize')};
    color: ${fromTheme('slider', 'selected')};
    margin: ${fromTheme('viewMargin')};
    min-width: ${({ theme }) => theme.fontSize * 3}px;
`;
ValueText.displayName = 'ValueText';

const SliderContainer = styled(View).attrs({ name: 'slider-container' })`
    /* then, to make it look evenly, we also need to add the same padding to the right */
    margin-right: ${fromTheme('textPadding')};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;
SliderContainer.displayName = 'SliderContainer';

const SliderLayout = styled(View).attrs({ name: 'slider-layout' })`
    margin-right: ${fromTheme('textPadding')};
    display: flex;
    flex-direction: row;
    align-self: stretch;
    flex-grow: 1;
    align-items: center;
`;
SliderLayout.displayName = 'SliderLayout';

const SliderWithMargin = styled(_Slider).attrs({ name: 'slider-with-margin' })`
    margin-left: ${fromTheme('viewMarginMore')};
    margin-right: ${fromTheme('viewMargin')};
    padding-right: ${fromTheme('viewPadding')};
    flex-grow: 1;
`;
SliderWithMargin.displayName = 'SliderWithMargin';

export default function Slider(props) {
    const ref = useRef();
    const {
        hNode,
        hNode: {
            id,
            forAction,
            title,
            disabled,
            namespace,
            relation,
            min = 0,
            max = 100,
            defaultValue = 50,
            propertyName
        },
        currentRoute,
        ...otherProps
    } = props;

    const { suspendLeftSwipe, suspendRightSwipe } = useContext(GestureContext);
    useLayoutEffect(() => {
        const releases = [];
        if (!ref.current) {
            throw new Error('No reference for the scrolling element is defined.');
        }
        if (!ref.current.getBoundingClientRect) return;
        releases.push(suspendLeftSwipe(() => ref.current.measureRect()));
        releases.push(suspendRightSwipe(() => ref.current.measureRect()));

        return () => releases.forEach(release => release());
    }, [suspendRightSwipe, suspendLeftSwipe]);

    const [, publish] = useEventSink();

    const [value, setValue, ready] = _p.usePersistentState(id, defaultValue);

    //react implemented the slider's onChange using onInput, which fires on every change, rather than when the user stops dragging
    //debouncing the event trailing-only to re-establish that behavior
    const emitValueDebounced = useDebounce(
        value => {
            publish({ [propertyName]: value }, { verb: forAction, namespace, relation, routePath: currentRoute });
            logging.debug(`[SLIDER] publishing ${value}`);
        },
        [currentRoute, forAction, namespace, propertyName, publish, relation],
        _p.debounceMilliSeconds,
        {
            leading: false,
            trailing: true
        }
    );

    useEffect(() => {
        if (ready) {
            emitValueDebounced(value);
        }
        // Only emit the value once (after it is retrieved from persistent storage)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ready]);

    function onChange(newValue) {
        setValue(newValue);
        emitValueDebounced(newValue);
    }

    useEffect(() => {
        emitValueDebounced(defaultValue);
        //we _only_ want to set the power to the default value upon first render.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // prettier-ignore
    return rc(SliderContainer, {ref, id},
        rc(Label, { id: `label-${id}`, htmlFor:id }, title),
        rc(SliderLayout, { },
            rc(ValueText, null, value.toString()),
            rc(SliderWithMargin, {
                id,
                hNode,
                value,
                disabled,
                min,
                max,
                ...testProperties(hNode, 'slider'),
                ...otherProps,
                onChange
            })
        )
    );
}
