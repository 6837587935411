import { authentication, network } from 'lib_ui-services';

export default {
    verb: 'doingUpdate',
    namespace: 'security',
    relation: 'profile',
    type: 'acceptLicense',
    prerequisites: [],
    description: 'Accept a license when logging in',
    //this is the actual logic:
    logic: acceptLicense
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function acceptLicense({ data, context, dispatch }) {
    const networkStatus = await network.getStatus();
    const { acceptedLicense, groupNumber } = data;
    if (networkStatus.isOnline) {
        if (acceptedLicense != null) {
            await authentication.acceptLicense(acceptedLicense, groupNumber, context);
            dispatch(
                {
                    title: 'License Accepted',
                    message: ['Thank you for accepting the license.', 'Please login again to continue.'],
                    okButtonText: 'CONTINUE',
                    noCancelOption: true,
                    okAction: () => {
                        authentication.logout(dispatch);
                    }
                },
                { verb: 'confirm', namespace: 'application', relation: 'user' }
            );
        }
    } else {
        throw new Error('Please go online to accept the license.');
    }
}
