import { authentication } from 'lib_ui-services';

export const _private = { authentication };

export default {
    verb: 'doingUpdate',
    namespace: 'security',
    relation: 'profile',
    type: 'selectTenant',
    prerequisites: [],
    description: 'Select a tenant when logging in',
    //this is the actual logic:
    logic: selectTenant
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function selectTenant({ data, context }) {
    const { userName, email, tenantId, useCaseId, isOauthUser, ...otherPayload } = data;
    if (tenantId == null || tenantId === '' || useCaseId == null || useCaseId === '') {
        return Promise.reject(new Error('Please select a use case to continue.'));
    }
    if (isOauthUser) {
        return _private.authentication.selectTenant(
            { userName: userName || email, tenantId, useCaseId, isOauthUser, ...otherPayload },
            context
        );
    }
    //otherwise, selecting a tenant really is logging in on a completely different use case.
    //just pass on the request.
    return _private.authentication.authenticate(
        { userName: userName || email, tenantId, useCaseId, ...otherPayload },
        context
    );
}
