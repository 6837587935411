import * as reduxOffline from './reduxOffline';
import getHttpHeaders from '../http/getHttpHeaders';

export async function initialize(eventSink) {
    return reduxOffline.initialize(eventSink);
}

/**
 *
 * @param  {...any} args
 * @example
 * {
        type: 'persist_X_Y',
        persist: {...}
        meta: {
            offline: {
                effect: {
                    type: 'update'
                    url: 'https://app.sstid.com/.....',
                    patch: [...]
                    newModel: {...}
                    oldModel: {...}
                },
                commit: {
                    type: `submit_X_Y_COMMIT`,
                    persist: {...}
                },
                rollback: {
                    type: `submit_X_Y_ROLLBACK`,
                    persist: {...}
                }
            },
        },
    }
 * @returns
 */
export function sendWithOfflineResilience(...args) {
    return reduxOffline.process(...args);
}

/**
 *
 * @returns {{ busy:boolean, online:boolean, pendingCount:number, retryCount:number, retryScheduled:boolean }}
 */
export function getStatus() {
    return reduxOffline.getStatus();
}

export function getOfflineAction(meta, correlationId, _id, payload, context) {
    // Gather the information for redux-offline action
    const { namespace, relation, verb } = context;
    const httpHeaders = getHttpHeaders({ ...meta, correlationId });
    const path = `/api/${namespace}/${relation}/${_id}`;
    const actionPayload = { ...payload, httpHeaders, path };
    const commitRollbackVerb = verb === 'remove' ? 'remove' : 'submit';
    // Create redux action syntax for offline resilience
    return {
        type: `${verb}_${namespace}_${relation}`,
        [verb]: actionPayload,
        meta: {
            offline: {
                effect: actionPayload,
                commit: {
                    type: `${commitRollbackVerb}_${namespace}_${relation}_commit`,
                    [verb]: actionPayload
                },
                rollback: {
                    type: `${commitRollbackVerb}_${namespace}_${relation}_rollback`,
                    [verb]: actionPayload
                }
            }
        }
    };
}

export function enable(enable) {
    reduxOffline.enable(enable);
}

export default { sendWithOfflineResilience, getStatus, getOfflineAction, initialize, enable };
