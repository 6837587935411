import { useMemo, memo, useEffect, useRef, useState, createElement as rc, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import logging from '@sstdev/lib_logging';
import { Modal, Text, useDebounce, testProperties } from 'lib_ui-primitives';
import { metadata, constants } from 'lib_ui-services';
import useUserContext from '../../../hooks/useUserContext';
import useDbView from '../../../hooks/useDbView';
import useEventSink from '../../../hooks/useEventSink';
import useReads from '../../../hooks/useReads';
import useRouteBoundedMachine from '../../../hooks/useRouteBoundedMachine';
import useNavigationSelection from '../../../hooks/useNavigationSelection';
import MergedReadProvider, { useMergedReads } from '../../contextProviders/MergedReadProvider';
import UserActivatedInput from '../../_abstractComponent/UserActivatedInput';
import SensorReadList from '../../list/SensorReadList';
import machineConfig, { actions as actionsFromConfig } from './markRecordStateMachine';
import { UnknownTagSwitch, ShowUnknownTagsLabel } from '../../list/SensorReadList/styles';
import {
    SpreadLeftToRight,
    Total,
    TotalLabel,
    TotalNumber,
    LittleDarker,
    CloseModalButton,
    MarkFoundButton,
    BoundingView,
    ButtonBar,
    ShowUnknownTags
} from './styles';
import UnanchoredOverlay from '../../_abstractComponent/UnanchoredOverlay';

const EMPTY_ARRAY = [];

const _p = {
    getLocationId: getLocation,
    useDbView,
    MarkRecord,
    useUserContext,
    useMergedReads,
    useReads,
    useNavigationSelection
};

export const _private = _p;
export default memo(MarkRecord);

/**
 * Find a record by one or more properties, E.g. on the Take screen. Uses rules for the "mark" verb for actual updating or creating of the record.
 * @param {Object} props
 * @param {string} props.currentRoute
 * @param {{[key: string]: string | boolean}} props.hNode
 */
function MarkRecord(props) {
    const {
        hNode: { namespace, relation, matchProperties = ['assetNo', 'serialNo'], id }
    } = props ?? { hNode: {} };

    const transactionProps = {
        errorNotificationDebounceMils: 400,
        hNode: {
            id: `MergedReadProvider${id}`,
            namespace,
            relation: `${relation}-transaction`,
            propertyName: 'includedItems',
            transactionType: 'markRecord',
            matchProperties,
            unknownTagAction: constants.tagAction.NONE,
            inactiveTagAction: constants.tagAction.REMOVE_AND_ERROR,
            beepForNewReads: false,
            publishChanges: false,
            duplicateTagAction: constants.duplicateTagAction.REMOVE_AND_ERROR
        }
    };

    return rc(MergedReadProvider, transactionProps, rc(InnerMarkRecord, props));
}

function InnerMarkRecord(props) {
    const {
        inputDebounce = 800,
        currentRoute,
        hNode,
        hNode: { forAction, id, namespace, relation }
    } = props || { hNode: {} };
    const { mobile, native } = useTheme();
    const [subscribe, publish, request] = useEventSink();
    const [disableMarkText, setDisableMarkText] = useState();
    const [inputActive, setInputActive] = useState();
    const markRecordInputRef = useRef();

    const foundBy = _p.useUserContext().briefUserReference;
    const titleAlternative = metadata.getTitleAlternative(namespace, relation, 'assetNo');
    const titleLabel = metadata.getTitleAlternativePretty(namespace, relation, 'Asset ID');

    // TODO: Blockly allows forAction to be something different, but it doesn't make
    // sense at this point because the corresponding rules engine rules
    // expect 'mark' for the verb.
    // It's possible this block could be used for some other action, but
    // until it is needed it, this safeguard may prevent some heartache.
    if (forAction !== 'mark') {
        throw new Error(
            `For now the "forAction" value for the MarkRecord block need to be "mark" not ${forAction}.  If another verb is needed, this rules engine rules will need to be built out.`
        );
    }

    // Read state stuff
    const { reads: allReads } = _p.useReads();
    const [records, setRecords] = useState(EMPTY_ARRAY);

    const { subscribeToChange, includeUnknownTags, toggleIncludeUnknownTags } = _p.useMergedReads();
    useEffect(() => {
        if (subscribeToChange != null) {
            return subscribeToChange(newRecords => {
                setRecords(newRecords);
            });
        }
    }, [subscribeToChange]);
    const readsExist = (allReads?.get?.()?.length ?? 0) > 0;
    const filteredReadsExist = (records?.length ?? 0) > 0;

    // Database stuff
    const { viewCriteria } = _p.useDbView(namespace, relation, undefined, hNode, undefined, false);
    const locationId = _p.getLocationId(viewCriteria);
    /**
     * @type { {namespace:string, relation:string, record: object, available:boolean, loading:boolean }}} NavigationSelection
     */
    const navigationSelection = _p.useNavigationSelection();

    // SERVICES for the markRecordStateMachine
    const service_resetReads = useCallback(() => {
        return new Promise(resolve => {
            request(
                { sensorType: constants.sensorTypes.RFID },
                { verb: 'reset', namespace: 'sensor', relation: 'read' }
            ).then(() => {
                // Wait until next cycle or the reads will still be present in the machine and the
                // modal will open again.
                setTimeout(resolve, 1);
            });
        });
    }, [request]);

    // ACTIONS for the markRecordStateMachine
    const action_logError = useCallback((context, event) => {
        logging.error(event.data);
    }, []);

    const action_applyFilter = useDebounce(
        /**
         * @parm {string} markText
         */
        function (context) {
            viewCriteria.applyFilters(
                {
                    // paged filter is a noop for a local lookup, but is needed for an online lookup.
                    paged: { page: 0 },
                    fullTextSearch: {
                        searchTerm: context.markText
                    },
                    propertiesToSearch: {
                        propertiesToSearch: ['assetNo', 'serialNo']
                    }
                },
                // Use a transient id to avoid overwriting the metadata based filters
                `transient_${id}`
            );
        },
        [viewCriteria, id],
        inputDebounce || 800,
        {
            leading: false,
            trailing: true
        }
    );

    /**
     * for each record in the list, set inventory found = true and save
     */
    const service_markAllAsFound = useCallback(() => {
        // Cancel any pending debounced applyFilter requests (they serve no purpose
        // because we are going to clear the filter).
        action_applyFilter.cancel();
        return request(
            {
                records,
                includeUnknownTags,
                locationId,
                foundBy,
                navigationSelection
            },
            { verb: forAction, namespace, relation, type: 'all' },
            constants.MARKALL_TIMEOUT
        );
    }, [
        request,
        action_applyFilter,
        records,
        includeUnknownTags,
        locationId,
        foundBy,
        forAction,
        namespace,
        relation,
        navigationSelection
    ]);

    const action_clearMarkTextFilter = useCallback(() => {
        // Immediately clear the filter.  This prevents hard to debug race
        // conditions with the handleMarkTextChange above.
        viewCriteria.applyFilters(
            {
                fullTextSearch: {
                    searchTerm: ''
                },
                propertiesToSearch: {
                    propertiesToSearch: ['assetNo', 'serialNo']
                }
            },
            // Use a transient id to avoid overwriting the metadata based filters
            `transient_${id}`
        );
    }, [id, viewCriteria]);

    /**
     * Publishes the action specified in the forAction field of the metadata.
     * For now, this needs to be 'mark'.
     */
    const action_markAsFound = useCallback(
        (context, event) => {
            // Don't mark an empty serialNo or assetNo
            if (!event.markText || locationId == null) {
                logging.error('Mark as found was called without an assetNo or without a location.');
                return;
            }

            // Cancel any pending debounced applyFilter requests (they serve no purpose
            // because we are going to clear the filter).
            action_applyFilter.cancel();

            // publish our action - probably a 'mark' verb.
            publish(
                {
                    markText: event.markText,
                    sensorType: event.sensorType,
                    locationId,
                    foundBy,
                    caseSensitive: false,
                    exactMatchOnly: true,
                    navigationSelection
                },
                { verb: forAction, namespace, relation, routePath: currentRoute }
            );
        },
        [
            forAction,
            action_applyFilter,
            foundBy,
            currentRoute,
            locationId,
            namespace,
            relation,
            publish,
            navigationSelection
        ]
    );

    const action_displayHelpText = useDebounce(
        () => {
            publish(
                { message: 'Please select a room to enable RFID scanning.', type: constants.notificationTypes.TOAST },
                {
                    verb: 'pop',
                    namespace: 'application',
                    relation: 'notification'
                }
            );
            setTimeout(() => {
                publish(
                    { sensorType: constants.sensorTypes.RFID },
                    { verb: 'reset', namespace: 'sensor', relation: 'read' }
                );
            }, 1);
        },
        [publish],
        1000,
        { leading: true, trailing: false }
    );

    const [routeState, send] = useRouteBoundedMachine(
        { ...machineConfig, context: { native, request, mobile, markText: '' } },
        {
            markAsFound: action_markAsFound,
            clearMarkTextFilter: action_clearMarkTextFilter,
            applyFilter: action_applyFilter,
            logError: action_logError,
            displayHelpText: action_displayHelpText,
            ...actionsFromConfig
        },
        {
            resetReads: service_resetReads,
            markAllAsFound: service_markAllAsFound
        }
    );

    useEffect(() => {
        send('readCountChange', { readsExist, filteredReadsExist });
    }, [readsExist, filteredReadsExist, send]);

    // Machine derived state
    const machineStarted = routeState.machine != null;
    const modalOpen = routeState.matches('modal');
    const detailOpen = routeState.matches('detail');
    const currentMarkText = routeState.context?.markText;

    // Machine Events
    const inputFocused = useCallback(
        e => {
            setInputActive(true);
            send('inputFocused', {
                blur: () => {
                    e.target.blur();
                }
            });
        },
        [send]
    );
    const inputBlurred = useCallback(() => {
        setInputActive(false);
        send('inputBlurred');
    }, [send]);
    const onMarkAsFound = useCallback(
        (markText, sensorType) => {
            send('markAsFound', { markText, sensorType });
        },
        [send]
    );
    const onMarkTextChanged = useCallback(
        (markText, sensorType) => send('markTextChanged', { markText, sensorType }),
        [send]
    );
    const onLocationChange = useCallback(
        locationId => {
            if (locationId) {
                send('locationChange', { locationId });
            } else {
                send('locationCleared', { locationId });
            }
        },
        [send]
    );
    const onMarkAllSuccess = useCallback(() => send('markAllSuccess'), [send]);
    const onCloseModal = useCallback(
        e => {
            e.stopPropagation();
            send('closeModal');
        },
        [send]
    );
    const onMarkAllAsFound = useCallback(() => send('markAllAsFound'), [send]);
    const onChangeAbandonConfirmationFailed = useCallback(() => send('changeAbandonConfirmationFailed'), [send]);
    useEffect(() => {
        return subscribe(
            {
                verb: 'confirm',
                namespace: 'item',
                relation: 'item',
                type: 'changeRecord',
                status: 'failure'
            },
            () => {
                onChangeAbandonConfirmationFailed();
            }
        );
    }, [subscribe, onChangeAbandonConfirmationFailed]);

    const onMarkAsFoundFailed = useCallback(() => send('reset'), [send]);
    useEffect(() => {
        return subscribe(
            {
                verb: 'mark',
                namespace: 'item',
                relation: 'item',
                status: 'failure'
            },
            () => {
                onMarkAsFoundFailed();
            }
        );
    }, [subscribe, onMarkAsFoundFailed]);
    // Let the state machine know when an unknown tag is clicked.
    const onUnknownItemClicked = useCallback(() => send('unknownTagClicked'), [send]);
    useEffect(() => {
        return subscribe({ verb: 'new', namespace, relation }, payload => {
            if (payload?.newRecord?.assetNo?.startsWith(`<${constants.UNKNOWN_TAG}`)) {
                onUnknownItemClicked();
            }
        });
    }, [subscribe, namespace, relation, onUnknownItemClicked]);

    // Let the state machine know when an known tag is clicked.
    const onKnownItemClicked = useCallback(() => send('knownTagClicked'), [send]);
    useEffect(() => {
        return subscribe({ verb: 'edit', namespace, relation }, () => {
            onKnownItemClicked();
        });
    }, [subscribe, namespace, relation, onKnownItemClicked]);

    // If a new unknown tag is created, add the locationId to it.  This works because data
    // is a reference.
    useEffect(() => {
        subscribe({ verb: 'new', namespace, relation, priority: 10 }, data => {
            data.locationId = locationId;
        });
    }, [subscribe, locationId, namespace, relation]);

    // If the location changes let the machine know.
    useEffect(() => onLocationChange(locationId), [locationId, onLocationChange]);

    const [delayedFocus, setDelayedFocus] = useState(false);
    /**
     * if location does not exist, then disable text input.
     * Also, if conditions are right, auto focus on the text input.
     */
    useEffect(() => {
        setDisableMarkText(locationId == null || detailOpen);
        if (locationId && !detailOpen) {
            setDelayedFocus(true);
        }
    }, [locationId, mobile, detailOpen, native]);

    useEffect(() => {
        if (!disableMarkText && delayedFocus) {
            // Native has a problem where it can be focused before enabled,
            // then additional focus calls will not add the focus unless the
            // input is blurred.
            // Only Android has the `isFocused` method.
            if (markRecordInputRef.current?.isFocused?.()) {
                markRecordInputRef.current?.blur();
            } else if (!native) {
                // Native MarkRecord input should not be automatically focused
                // because it opens the modal view.
                markRecordInputRef.current?.focus();
            }
            setDelayedFocus(false);
        }
    }, [disableMarkText, delayedFocus, native]);

    // If a mark all found completes successfully, then reset reads and close
    // modal modal screen
    useEffect(() => {
        const unsubscribes = [
            subscribe({ verb: forAction, namespace, relation, type: 'all', status: 'success' }, () => {
                onMarkAllSuccess();
            })
        ];
        return () => unsubscribes.forEach(unsubscribe => unsubscribe());
    });

    useEffect(() => {
        // when unit testing, we rely on the ability to "stage" the environment
        // eslint-disable-next-line no-undef
        if (!__UNIT_TESTING__) {
            //we need to make sure we start "fresh". Just in case they navigated away while on detail on modal.
            send('reset', {});
        }
    }, [send]);

    // Default all scanning properties to true (which will prefer the RFID Power Button if possible).
    const userActivatedInputHNode = useMemo(
        () => ({ scanRfid: true, scanBarcode: true, displayScanButton: true, displayRfidPowerButton: true, ...hNode }),
        [hNode]
    );
    if (!machineStarted) return rc(Text, {}, 'Loading...');
    if (native && mobile && modalOpen) {
        // prettier-ignore
        return rc(Fragment, null,
            rc(UserActivatedInput, {
                id: `normalMarkRecord-${id}`,
                disabled: disableMarkText,
                currentRoute,
                hNode: userActivatedInputHNode,
                currentValue: currentMarkText,
                onSubmit: onMarkAsFound,
                onChange: onMarkTextChanged,
                inputRef: markRecordInputRef,
                inputFocused,
                inputBlurred
            }),
            rc(Modal, { visible: true, id: `modalMarkRecord-${id}` },
                rc(BoundingView, null,
                    rc(CloseModalButton, {
                        title: 'Close',
                        alt: 'Close',
                        onClick: onCloseModal,
                        icon: 'close',
                        buttonStyle: 'Dark',
                        ...testProperties(hNode, 'modalClose')
                    }),
                    rc(LittleDarker, null,
                        rc(SpreadLeftToRight, null,
                            rc(UserActivatedInput, {
                                id: `modalMarkRecord-${id}`,
                                disabled: disableMarkText,
                                currentRoute,
                                hNode: userActivatedInputHNode,
                                currentValue: currentMarkText,
                                onSubmit: onMarkAsFound,
                                onChange: onMarkTextChanged,
                                inputRef: markRecordInputRef,
                                readsExist,
                                autoFocus: true
                            }),
                            rc(Total, null,
                                rc(TotalLabel, null, 'Count'),
                                rc(TotalNumber, null, `${records.length || 0}`)
                            )
                        ),
                        rc(SensorReadList, {
                            currentRoute,
                            hNode: {
                                includeReadButton: false,
                                displayShowUnknownTags: false,
                                id: `sensor-list-${id}`,
                                mergeNamespace: namespace,
                                mergeRelation: relation,
                                sensorType: 'RFID',
                                hNodeType: 'SensorReadList',
                                hNodeTypeGroup: 'list',
                                children: [
                                    {
                                        id: `remove${id}`,
                                        hNodeType: 'button',
                                        hNodeTypeGroup: 'listColumn',
                                        dataType: 'button',
                                        title: 'Remove',
                                        label: 'Remove',
                                        namespace: 'sensor',
                                        relation: 'read',
                                        iconName: 'clear',
                                        forAction: 'remove',
                                        displayTitleOnButton: false,
                                        buttonStyle: 'round'
                                    },
                                    {
                                        'id': 'bb6116dac0fefb2133bccd17a0',
                                        'hNodeType': 'Text',
                                        'hNodeTypeGroup': 'listColumn',
                                        'title': titleLabel,
                                        'label': titleLabel,
                                        'propertyName': titleAlternative,
                                        'dataType': 'text',
                                        'sequence': 0,
                                        'sortable': 'false',
                                        'visuallyFlagOnValue': ''
                                    },
                                    {
                                        'id': 'bb6116db4afefb2133bccd17ae',
                                        'hNodeType': 'Text',
                                        'hNodeTypeGroup': 'listColumn',
                                        'title': 'Description',
                                        'label': 'Description',
                                        'propertyName': 'description',
                                        'dataType': 'text',
                                        'sequence': 1,
                                        'sortable': 'false',
                                        'visuallyFlagOnValue': ''
                                    }
                                ]
                            }
                        }),
                    ),
                    rc(ButtonBar, null,
                        rc(ShowUnknownTags, null,
                            rc(UnknownTagSwitch, { onClick: toggleIncludeUnknownTags, value: includeUnknownTags }),
                            rc(ShowUnknownTagsLabel, null, 'Show Unknown Tags')
                        ),
                        rc(MarkFoundButton, { value: 'Mark Found', onClick: onMarkAllAsFound, disabled: records.length < 1 })
                    )
                )
            )
        );
    }

    // Using CSS to vary the appearance (based on keyboard presence and focus)
    // instead of rendering different components, avoids problems with focus
    // due to the input disappearing and reappearing.
    return rc(
        UnanchoredOverlay,
        { active: inputActive },
        rc(UserActivatedInput, {
            id: `normal-${id}`,
            disabled: disableMarkText,
            currentRoute,
            hNode: userActivatedInputHNode,
            currentValue: currentMarkText,
            onSubmit: onMarkAsFound,
            onChange: onMarkTextChanged,
            inputRef: markRecordInputRef,
            inputFocused,
            inputBlurred
        })
    );
}

MarkRecord.propTypes = {
    currentRoute: PropTypes.string,
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};

/**
 * Look at the configured filters on the viewCriteria, and IF there is one for
 * 'location:location._id', use that to return an _id.
 * If not, return nothing.
 * @param {*} viewCriteria
 * @returns {string | undefined}
 */
function getLocation(viewCriteria) {
    if (viewCriteria == null) return;

    const filters = viewCriteria.getFinds();
    const locationFilter = filters.find(f => f.val?.$and?.some(a => a['location:location._id']));
    if (locationFilter) {
        return locationFilter.val.$and.find(a => a['location:location._id'])['location:location._id'].$eq;
    }
}
