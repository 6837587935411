import { ObjectId, metadata } from 'lib_ui-services';
import notHandledHere from './notHandledHere';
export default {
    verb: 'willUpdate',
    excludedNamespaceRelations: notHandledHere,
    prerequisites: [],
    //this probably should go before pretty much anything else
    priority: 99,
    description: 'Split off attachments into their own creation flow',
    // this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data, context, dispatch }) {
    const { newAttachments, ...newRecord } = data.newRecord;
    const { namespace, relation } = context;
    const titleProperty = metadata.getTitleAlternative(namespace, relation);
    let attachments = [];
    if (Array.isArray(newAttachments) && newAttachments.length) {
        attachments = newAttachments;
    } else if (newAttachments?.name) {
        attachments = [newAttachments];
    }
    if (attachments.length) {
        for (const file of attachments) {
            const _id = ObjectId();
            let newFileRecord = {
                _id,
                // guarantee uniqueness of title:
                title: _id.toString(),
                filename: file.name,
                size: formatBytes(file.size),
                type: file.type,
                encoding: file.encoding,
                extension: getExtension(file.name),
                // foreign Key Reference:
                [`${namespace}:${relation}`]: { _id: newRecord._id, [titleProperty]: newRecord[titleProperty] },
                url: `/api/file/attachment/${_id}/content`
            };
            if (file.content) {
                // image data is embedded:
                newFileRecord.content = file.content;
            } else {
                // what we got was a `File` object that needs to be streamed
                newFileRecord.file = file;
            }

            await dispatch({ newRecord: newFileRecord }, { verb: 'create', namespace: 'file', relation: 'attachment' });
        }
    }

    // leave a marker in the data object so we don't incorrectly tell the user there were no changes
    // if the attachments were the only changes there were.
    return { ...data, newRecord, hadNewAttachments: true };
}

/**
 * Return file extension (including leading '.')
 * @param {string|undefined} fileName
 * @returns {string|undefined}
 */
function getExtension(fileName) {
    if (!fileName?.includes('.')) return;
    return fileName.substring(fileName.lastIndexOf('.'));
}
/**
 * https://stackoverflow.com/a/18650828
 * @param {number|string} bytes
 * @param {number} decimals
 * @returns {string}
 */
function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 B';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    // Calculate the exponent, which will give us the index into the Size array
    // As well as helps us with rounding
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
