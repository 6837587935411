export default database => (tagId, namespace, relation) => {
    return new Promise((resolve, reject) => {
        try {
            const db = database.relationDb(namespace, relation);
            const result = db.find({ tagId });
            if (result.length > 1) {
                throw new Error(`More than one result found for tagId ${tagId}`);
            } else if (result.length === 0) {
                resolve();
            } else {
                resolve(result);
            }
        } catch (err) {
            reject(err);
        }
    });
};
