//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _didEdit_deploy_deployment from './didEdit_deploy_deployment';
import _doingCopy_deploy_deployment from './doingCopy_deploy_deployment';
import _willNew_deploy_deployment from './willNew_deploy_deployment';
export const didEdit_deploy_deployment = _didEdit_deploy_deployment;
export const doingCopy_deploy_deployment = _doingCopy_deploy_deployment;
export const willNew_deploy_deployment = _willNew_deploy_deployment;
export default {didEdit_deploy_deployment, doingCopy_deploy_deployment, willNew_deploy_deployment};

