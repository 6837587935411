import { ObjectId } from 'lib_ui-services';

export default {
    verb: 'doingUpdate',
    namespace: 'metadata',
    relation: 'profileMenu',
    description: 'Bump build number and add patch record for useCaseDetail',
    status: 'success',
    //type: '',
    //priority:0,
    //useCaseIds:[]
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'metadata',
                relation: 'useCaseDetail',
                type: 'find',
                status: undefined
            },
            query: ({ data }) => {
                const {
                    'metadata:useCaseDetail': { _id }
                } = data.newRecord;
                return {
                    _id,
                    'meta.deleted': { $exists: false }
                };
            }
        }
    ],
    //this is the actual logic:
    logic: updateSuccess
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function updateSuccess({ data, prerequisiteResults, dispatch }) {
    if (data.clone) return;
    const useCaseDetail = prerequisiteResults[0].result[0];
    const { major, minor = 0, patch = 0, build = 0, version: oldVersion } = useCaseDetail;

    const version = `${major}.${minor}.${patch}.${build + 1}`;
    //bump the version number
    dispatch(
        { oldRecord: useCaseDetail, newRecord: { ...useCaseDetail, build: build + 1, version, verified: false } },
        { verb: 'update', namespace: 'metadata', relation: 'useCaseDetail' }
    );
    //add a simplified history "patch" record to track the rough changes.
    const patchRecord = {
        _id: ObjectId(),
        'metadata:useCaseDetail': data.newRecord['metadata:useCaseDetail'],
        patches: [
            {
                op: 'update',
                path: '/version',
                value: version,
                oldValue: oldVersion
            },
            {
                op: 'update',
                path: '/profileMenu',
                value: 'Profile Menu'
            }
        ]
    };
    //create a patch record
    dispatch({ newRecord: patchRecord }, { verb: 'create', namespace: 'metadata', relation: 'changeHistory' });
}
