import { createElement as rc, useState, useCallback, useEffect, useContext } from 'react';
import { Button, testProperties, contexts } from 'lib_ui-primitives';
import { default as _DropDown } from '../../_abstractComponent/DropDown';
import HNode from '../../../HNode';
import { ListRow, Container, LabelAndField } from './styles';
import PropTypes from 'prop-types';

// value of dropdown must be seet to something with an _id or the selectedItem
// styling comparison (which looks at _id) will always pass (i.e. undefined === undefined)
const DEFAULT_SELECTED_ITEM = { _id: 'default' };
const _p = {
    _DropDown
};
export default function DynamicFieldEntryRow(props) {
    const { entry, remove, index, currentRoute, allowedEntries, entryUsed, hNode } = props ?? {};
    const {
        displayScanButton = false,
        scanRfid = false,
        scanBarcode = false,
        displayRfidPowerButton = false
    } = hNode ?? {};
    const [childHNode, setChildHNode] = useState({
        ...entry.hNode,
        displayScanButton,
        scanRfid,
        scanBarcode,
        displayRfidPowerButton
    });
    const focusContext = useContext(contexts.FocusContext);
    const subFormRemove = useCallback(() => {
        remove(index, childHNode);
    }, [index, remove, childHNode]);

    const setChildHNodeProxy = useCallback(
        hNode => {
            focusContext.disableFocusManagement = true;
            let childHNode = {
                ...hNode,
                displayScanButton,
                scanRfid,
                scanBarcode,
                displayRfidPowerButton
            };
            entry.hNode = childHNode;
            setChildHNode(childHNode);
            entryUsed(index, childHNode);
        },
        [index, entryUsed, entry, displayScanButton, scanRfid, scanBarcode, focusContext, displayRfidPowerButton]
    );

    useEffect(() => {
        return focusContext.onChange((type, entry) => {
            if (entry.id === childHNode.id && type === 'add') {
                entry.focus?.();
            }
        });
    }, [childHNode.id, focusContext]);

    // prettier-ignore
    return rc(ListRow, null,
        rc(Container, null,
            rc(LabelAndField, null,
                !childHNode.hNodeType && rc(_p._DropDown, {
                    records: allowedEntries,
                    hNode: {
                        displayUnassignedRow: false,
                        id: `fdd${index}`,
                        propertyName: 'title',
                        placeholder: 'Field',
                        // Ensure new entries have a position at the beginning
                        treePosition: { sequence: hNode.treePosition.minSequence }
                    },
                    value: DEFAULT_SELECTED_ITEM,
                    setValue: setChildHNodeProxy,
                    active: false // usually a required value but not relevant here (there is no label to move when active)
                }),
                !!childHNode.hNodeType && rc(HNode, {
                    hNode: childHNode,
                    key: index,
                    currentRoute
                })
            )
        ),
        rc(Button, {
            icon: 'clear',
            buttonStyle: 'round',
            color: 'error',
            onClick: subFormRemove,
            ...testProperties(hNode, 'clear', index)
        })
    );
}

DynamicFieldEntryRow.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};
