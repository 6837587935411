import { createElement } from 'react';
import Missing from '../MissingComponent';

const rc = createElement;

export default function Signup() {
    //Should we redirect to https://www.sstid.com/contact-us/ or https://www.redbeam.com/get-a-demo/
    //depending on the router.brand value?

    // prettier-ignore
    return rc(Missing, { width:'auto', height:'auto' }, 'Please contact support to sign up.');
}
