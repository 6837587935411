export default function getNestedPatchDetailColumnHNodes() {
    return [
        {
            id: 'bb61f8951cd90e272b835feb6a',
            hNodeType: 'Text',
            hNodeTypeGroup: 'listColumn',
            title: 'Type',
            label: 'Type',
            // propertyPath: propertyPath ? propertyPath + '.0' : undefined,
            propertyName: 'op',
            dataType: 'text',
            sequence: 0,
            sortable: 'false',
            visuallyFlagOnValue: '',
            width: 160 //14*16*0.66+12
        },
        {
            id: 'bb61f8952bd90e272b835feb6b',
            hNodeType: 'Text',
            hNodeTypeGroup: 'listColumn',
            title: 'Field',
            label: 'Field',
            // propertyPath: propertyPath ? propertyPath + '.0' : undefined,
            propertyName: 'label',
            dataType: 'text',
            sequence: 1,
            sortable: 'false',
            visuallyFlagOnValue: '',
            width: 200
        },
        {
            id: 'bb61f8953fd90e272b835feb6c',
            hNodeType: 'Text',
            hNodeTypeGroup: 'listColumn',
            title: 'Old Value',
            label: 'Old Value',
            // propertyPath: propertyPath ? propertyPath + '.0' : undefined,
            propertyName: 'oldValue',
            dataType: 'text',
            sequence: 2,
            sortable: 'false',
            visuallyFlagOnValue: '',
            width: 200
        },
        {
            id: 'bb61f8954ad90e272b835feb6d',
            hNodeType: 'Text',
            hNodeTypeGroup: 'listColumn',
            title: 'New Value',
            label: 'New Value',
            // propertyPath: propertyPath ? propertyPath + '.0' : undefined,
            propertyName: 'newValue',
            dataType: 'text',
            sequence: 3,
            sortable: 'false',
            visuallyFlagOnValue: '',
            width: 200
        }
    ];
}
