import { Modal, Card, h1 } from 'lib_ui-primitives';
import { useEffect, useState, useContext, createElement as rc, Fragment } from 'react';
import { ThemeContext } from 'styled-components';
import useEventSink from '../../hooks/useEventSink';
import { metadata } from 'lib_ui-services';

export default function SimpleDialog(props) {
    const {
        id,
        openAction,
        closeAction,
        namespace,
        relation,
        title,
        openActionType: openActionTypeOverride,
        closeActionType: closeActionTypeOverride
    } = props.hNode ?? {};
    const [subscribe] = useEventSink();
    const { smallMobile } = useContext(ThemeContext);
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        let openActionType;
        if (['edit', 'new'].includes(openAction)) {
            openActionType = openActionTypeOverride || { verb: openAction, namespace, relation, status: 'success' };
        } else {
            openActionType = openActionTypeOverride || { verb: openAction, namespace, relation };
        }
        const closeActionType = closeActionTypeOverride || { verb: closeAction, namespace, relation };
        const unsubscribes = [
            subscribe(openActionType, () => {
                setVisible(true);
            }),
            subscribe(closeActionType, () => setVisible(false))
        ];
        // unsubscribe during cleanup
        return () => unsubscribes.forEach(u => u());
    }, [openAction, closeAction, namespace, subscribe, relation, openActionTypeOverride, closeActionTypeOverride]);
    const scrollingRequired =
        metadata.containsHNodeType(props.hNode, 'Form') || metadata.containsHNodeType(props.hNode, 'SingleRecordForm');
    // prettier-ignore
    return rc(Modal, { id: `modalSimpleDialog-${id}`, visible, scrollingRequired },
        rc(Card, null,
            !smallMobile && title && rc(Card.Header, null, rc(h1, null, title)),
            rc(Fragment, null, props.children)
        )
    );
}
