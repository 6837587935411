import { useRef, createElement as rc, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import styled from '../styled';

const _ScrollToBottomButton = styled(Button).attrs({ name: 'scroll-to-bottom-button' })`
    position: absolute;
    bottom: 3px;
    right: 3px;
`;
/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function ScrollToBottomButton(props) {
    const {
        scrollLayout: { clientHeight, scrollHeight, scrollTop, scrollTo },
        ...otherProps
    } = props;
    const [scrolledToBottom, setScrolledToBottom] = useState();

    useEffect(() => {
        setScrolledToBottom(scrollTop + clientHeight + 2 > scrollHeight);
    }, [clientHeight, scrollHeight, scrollTop]);

    // Put these things in a ref to avoid creating an unnecessary new function every time
    // they change.
    const scrollRef = useRef({ scrollTo, scrollHeight });
    scrollRef.current = { scrollTo, scrollHeight };
    const scrollToBottom = useCallback(() => {
        scrollRef.current.scrollTo({ top: scrollRef.current.scrollHeight, behavior: 'smooth' });
    }, []);

    if (scrolledToBottom) return null;
    // prettier-ignore
    return rc(_ScrollToBottomButton, {
        onClick: scrollToBottom,
        iconFont: 'MaterialCommunityIcon',
        color: 'base',
        buttonStyle: 'round',
        icon: 'keyboard-double-arrow-down',
        ...otherProps
    });
}

ScrollToBottomButton.defaultProps = {};

ScrollToBottomButton.propTypes = {
    scrollLayout: PropTypes.object.isRequired
};

export default ScrollToBottomButton;
