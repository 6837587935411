import notHandledHere from './notHandledHere';
import { database } from 'lib_ui-services';

export default {
    verb: 'doingUpdate',
    description: 'Update the indicated data on the database',
    excludedNamespaceRelations: notHandledHere.concat({ namespace: 'metadata', relation: 'page' }),
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data, context }) {
    // no patches (it was probably just new attachments), noop.
    if (!data.patch?.length) return data;

    const db = database.get();
    const result = await db.update(data, context);
    return { ...data, result };
}
