// https://github.com/redux-offline/redux-offline/blob/develop/docs/api/config.md#effect
import * as http from '../http/index';
export const _private = {
    http
};
export default (effect, action) => {
    const [verb] = action.type.split('_');
    switch (verb) {
        case 'update':
            return _private.http.patch(effect.path, effect.patch, effect.httpHeaders);
        case 'create':
            return _private.http.post(effect.path, effect.newRecord, effect.httpHeaders);
        case 'remove':
            return _private.http.remove(effect.path, effect.httpHeaders);
        default:
            return Promise.reject(new Error(`Effect verb '${verb}' has not been implemented.`));
    }
};
