import { COMMON_COLOR_SCHEME, errors } from 'lib_ui-primitives';
import { globalConfig } from 'lib_ui-services';

const _p = {
    globalConfig
};
export const _private = _p;

export default {
    verb: 'willUpdate',
    namespace: 'metadata',
    relation: 'useCaseDetail',
    description: 'Update the title when changing the usecase or version',
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'metadata',
                relation: 'deployment',
                type: 'find',
                status: undefined
            },
            query: ({ data }) => {
                const { _id, version } = data.newRecord;
                return {
                    // Get the deployments for this use case version based on the version
                    criteria: {
                        'metadata:useCaseDetail._id': _id,
                        version,
                        'meta.deleted': { $exists: false }
                    },
                    // Sort the deployments by created date, descending
                    orderBy: (x, y) => {
                        return y.meta.created - x.meta.created;
                    }
                };
            }
        }
    ],
    logic: willUpdate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willUpdate({ data, prerequisiteResults, dispatch }) {
    let useCaseDetail = data.newRecord;
    const useCase = useCaseDetail['metaui:useCase'];
    let { major, minor, patch, build } = useCaseDetail;

    // Only perform these steps if the use case version is being locked
    if (isLocking({ data })) {
        const previousVersion = `${major}.${minor}.${patch}.${build}`;
        build = build + 1;

        // Get the deployment records for this use case version
        const deployments = prerequisiteResults[0].result;

        // If the (previous) use case version has no deployments, show a modal and throw an error
        // We probably don't want to lock and deploy a version that has never been deployed
        if (deployments.length === 0) {
            showNoDeploymentsModal(previousVersion, dispatch);
            throw new errors.ValidationError('No deployments for this use case version', {});
        }

        // Get the deployment record for so we can get the group, environment etc
        const deployment = deployments[0];

        // Run the metadata deployment against the source environment
        const sourceEnvironment = getHostname();
        await dispatch(
            {
                // Pass in the old record with the locked property set to false so we can bypass the locked validation check in the deployment rule
                oldRecord: { ...useCaseDetail, locked: 'false' },
                newRecord: {
                    // Build up the deployment record
                    'deploy:environment': { title: sourceEnvironment },
                    'deploy:group': deployment['deploy:group'],
                    'metadata:useCaseDetail': {
                        _id: useCaseDetail._id,
                        title: useCaseDetail.title,
                        build,
                        version: `${major}.${minor}.${patch}.${build}`
                    },
                    type: 'USE_CASE_VERSION_LOCK'
                }
            },
            { verb: 'create', namespace: 'metadata', relation: 'deployment' },
            true
        );
    }

    useCaseDetail.build = build;
    useCaseDetail.version = `${major}.${minor}.${patch}.${build}`;
    useCaseDetail.title = `${useCase.title} v${major}.${minor}.${patch}`;

    return {
        ...data,
        newRecord: useCaseDetail
    };
}

/**
 * Check if the use case version is being locked
 * @param {object} data
 * @returns {boolean}
 */
function isLocking({ data }) {
    return data.newRecord.locked === true && data.patch.find(p => p.op !== 'test' && p.path === '/locked');
}

/**
 * Get the hostname of the current environment
 */
function getHostname() {
    const { hostname } = _p.globalConfig();
    return hostname.split('.')[0];
}

/**
 * Show a modal to the user if there are no deployments for the use case version
 */
function showNoDeploymentsModal(useCaseVersion, dispatch) {
    dispatch(
        {
            message: [
                `There are no deployments for use case version ${useCaseVersion}.`,
                'Please deploy this version before attempting to lock it.'
            ],
            okButtonText: 'OK',
            icon: 'warning',
            iconColor: COMMON_COLOR_SCHEME.warn,
            noCancelOption: true,
            okAction: () => {}
        },
        { verb: 'confirm', namespace: 'application', relation: 'user' }
    );
}
