import { createElement as rc, useMemo } from 'react';
import { metadata } from 'lib_ui-services';
import AccessNavHeading from './AccessNavHeading';
import { AccessMenuContainer } from './styles';

const _p = {
    getNavMenu: metadata.getNavMenu
};
export const _private = _p;

/**
 * @typedef {{
 *    title: string,
 *    pageId: string,
 *    type: 'page' | 'pageList' |'tab' | 'menu',
 *    tabs: ComponentAccessTarget[],
 *    actions: ComponentAccessTarget[],
 * }} PageAccessTarget
 */

function AccessMenu(props) {
    const currentUseCaseMetadata = useMemo(() => _p.getNavMenu(), []);

    return rc(
        AccessMenuContainer,
        { id: props.id, scrollDirection: 'vertical' },
        currentUseCaseMetadata.map((navHeading, i) =>
            rc(
                AccessNavHeading,
                {
                    hNode: navHeading,
                    key: (navHeading.id || navHeading.title) + i,
                    formElementHNode: props.hNode
                },
                navHeading.title
            )
        )
    );
}
export default AccessMenu;
