import logging from '@sstdev/lib_logging';
import conversion from '@sstdev/lib_epc-conversions';
import { localStorage } from 'lib_ui-services';

export default function enableCommands(eventSink) {
    /**
     * @type {[
     *     (eventName:string, callback:function)=>function,
     *     (eventName:string, payload:any)=>void,
     *     (eventName:string, payload:any)=>Promise<any>
     * ]} [ subscribe, publish, request ]
     */
    const [, publish] = eventSink;
    // Browser devtools does not allow setting sockets offline
    // to test offline you have to do it in the console
    // so you need to use a global like this:
    // `window.bb.socket.setOffline()`
    // `window.bb.socket.setOnline()`

    window.bb = window.bb || {};
    const bb = window.bb;
    bb.socket = { setOffline: setOffline(publish), setOnline: setOnline(publish) };

    bb.testNewAsset = testNewAsset(publish);
    bb.testUpdatedAsset = testUpdatedAsset(publish);
    bb.createNotification = createNotification(publish);
    bb.createProgress = createProgress(publish);
    bb.toHex = val => conversion.ascii.toHex(val);
    bb.setSyncEnabled = setSyncEnabled;
    let originalFetch = window.fetch;
    bb.doMockFetchFail = (doMock = true) => {
        if (doMock) {
            window.fetch = async url => {
                logging.info(`[MOCK] Fetching ${url}`);
                throw new Error('TypeError: Failed to fetch');
            };
        } else {
            window.fetch = originalFetch;
        }
    };
}

const setSyncEnabled = async enabled => {
    await localStorage.setKey('syncEnabled', enabled, undefined, false);
    const syncEnabled = await localStorage.getKey('syncEnabled', undefined, true, false);
    logging.info(`syncEnabled set to ${syncEnabled}.`);
    return `syncEnabled set to ${syncEnabled}.`;
};

const createProgress = publish => (mainTitle, title, current, total) => {
    publish(
        {
            mainTitle,
            title,
            description: `description ${mainTitle}`,
            current,
            total
        },
        {
            verb: 'update',
            namespace: 'application',
            relation: 'progress'
        }
    );
};

const createNotification = publish => () => {
    publish(
        {
            addToList: true,
            message: 'asd sdkrwe really lnog asd is a message that is going to wrapppoa  rap rap.'
        },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
};

//all commands are emitted as with a "cli" (Command Line Input) "verb",
//actual processing happens in the rules engine.
const setOffline = publish => () => {
    publish(
        {
            online: false
        },
        { verb: 'cli', namespace: 'application', relation: 'socket' }
    );
};

const setOnline = publish => () => {
    publish(
        {
            online: true
        },
        { verb: 'cli', namespace: 'application', relation: 'socket' }
    );
};

const testNewAsset = publish => asset => {
    if (asset?.assetNo == null) {
        logging.error('You must, at a minimum, specify assetNo like so: {assetNo: "<some asset number>"}');
    }
    publish(
        {
            type: 'create',
            payload: asset
        },
        { verb: 'cli', namespace: 'item', relation: 'item' }
    );
};

const testUpdatedAsset = publish => (assetNo, changes) => {
    if (!changes || Object.keys(changes).length === 0) {
        logging.error(
            'You need to specify a second parameter with changes like this: { description: "example changed description"}'
        );
        publish(
            {
                type: 'update',
                criteria: { assetNo },
                changes
            },
            { verb: 'cli', namespace: 'item', relation: 'item' }
        );
    }
};
