import { nativeOnlyProperties } from 'lib_ui-services';
/**
 * Keep track of what is at each css z-index, so you know
 * where it makes sense to add a new entry.
 * Merge this into the theme for easy access in styles
 */
export default {
    GeigerCounterContainer: 0,
    GeigerCounterTagField: 1,
    SensorReadListReadButton: 1,
    ShowUnknownTags: 1,
    UnanchoredOverlayBackground: 1,
    UnanchoredOverlayView: 1,
    ListStickyHeader: 2,
    TabCarouselArrowButton: 2,
    VideoViewPort: 4,
    BarcodeScannerCloseButton: 5,
    CameraButtonWrapper: 5,
    DropDownList: 5,
    PortalDatePickerWrapper: 5,
    /* Blockly workspace uses up to z-index 70) */
    Drawer: 73,
    DropDownComboContainer: 74,
    PortalBackground: 100,
    InfoDisplayButtonMessage: 999,
    // Allow for native specific values or overrides
    ...nativeOnlyProperties({
        Drawer: 4,
        DrawerCloseButton: 2
    })
};
