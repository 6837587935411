import { createElement as rc, useRef } from 'react';
import { contexts, hooks } from 'lib_ui-primitives';
import logging from '@sstdev/lib_logging';
import cuid from 'cuid';
export { useMoveFocusToNext } from './useMoveFocusToNext';
const { useSimpleChangeObserver } = hooks;

export default function FocusProvider(props) {
    const { disableFocusManagement = false, reportChanges = true, name: focusProviderName = cuid() } = props ?? {};
    const { onChange, publishChange } = useSimpleChangeObserver();
    const context = useRef({
        focusProviderName,
        reportChanges,
        // disableFocusManagement disables the moveNext method.
        // The FocusProvider still tracks registered consumers so that,
        // focusIfExists (for instance) would still be able to move the focus.
        disableFocusManagement,
        onChange,
        focusConsumers: [],
        registerConsumer: (id, name, sequence) => {
            let { focusConsumers } = context.current;
            const consumer = { id, name, sequence };
            focusConsumers.push(consumer);
            focusConsumers.sort((a, b) => a.sequence - b.sequence);
            const currentIndex = focusConsumers.findIndex(c => c.id === id);
            logging.debug(
                `[FocusProvider] ${focusProviderName} - Adding consumer ${id} | ${name} | ${sequence} | index ${currentIndex}`
            );
            // Sometimes helpful for debugging
            //logging.debug(`[FocusProvider] ${JSON.stringify(focusConsumers, null, 3)}`);
            if (context.current.reportChanges) {
                // Wait for the new focus consumer to finish rendering.
                setTimeout(() => {
                    publishChange('add', consumer);
                }, 10);
            }
            return {
                updateConsumer: (focus, enabled, value) => {
                    consumer.focus = focus ?? consumer.focus;
                    consumer.enabled = enabled ?? consumer.enabled;
                    // Passing value makes this slightly easier to debug - the value
                    // is usually the text from a text input.
                    consumer.value = value ?? consumer.value;
                },
                deregister: () => {
                    focusConsumers.splice(focusConsumers.indexOf(consumer), 1);
                    // avoid logging this during unit tests (it logs after `afterEach` clears the logging spies)
                    /* eslint-disable no-undef */
                    !__UNIT_TESTING__ &&
                        logging.debug(`[FocusProvider] ${focusProviderName} - Removing consumer ${id} | ${name}`);
                    // Sometimes helpful for debugging
                    //!__UNIT_TESTING__ && logging.debug(`[FocusProvider] ${JSON.stringify(focusConsumers, null, 3)}`);
                    /* eslint-enable no-undef */
                }
            };
        },
        setLastFocused: id => {
            logging.debug(`[FocusProvider] ${focusProviderName} - setting lastFocused to ${id}.`);
            context.current.lastFocused = id;
        },
        focusIfExists: id => {
            let { focusConsumers } = context.current;
            let idIndex = focusConsumers.findIndex(c => c.id === id);
            const consumer = focusConsumers[idIndex];
            consumer?.focus();
        },
        moveNext: id => {
            let { focusConsumers, lastFocused, disableFocusManagement } = context.current;
            disableFocusManagement && logging.debug(`[FocusProvider] ${focusProviderName} -Focus mgmt disabled.`);
            logging.debug(`[FocusProvider] ${focusProviderName} - Focus moveNext called with id ${id}.`);
            if (focusConsumers.length === 0 || disableFocusManagement) {
                logging.debug(`[FocusProvider] ${focusProviderName} -No focus consumers.`);
                return;
            }

            if (id == null) {
                id = lastFocused;
                logging.debug(`[FocusProvider] ${focusProviderName} - Focus moveNext using lastFocused id ${id}.`);
            }

            let currentIndex = focusConsumers.findIndex(c => c.id === id);
            logging.debug(
                `[FocusProvider] ${focusProviderName} - Current focus index is ${currentIndex} | ${focusConsumers[currentIndex]?.name}.`
            );
            // If an id is not found, currentIndex will be -1 so focus will
            // go to the first index (or the first enabled one starting with
            // 0 index).
            // cycle through the focusConsumers trying to find the
            // next enabled one after the current index.
            // If no additional enabled focusConsumers exist after the current consumer,
            // this is a noop.
            for (let i = currentIndex + 1; i < focusConsumers.length; i++) {
                logging.debug(
                    `[FocusProvider] ${focusProviderName} - Trying focus index ${i} | ${focusConsumers[i]?.name}.`
                );
                const consumer = focusConsumers[i];
                if (consumer.enabled) {
                    // Sometimes helpful for debugging (will crash in React Native non-debug mode.).
                    //logging.debug(`[FocusProvider] Active before focus: ${document.activeElement.id} | ${document.activeElement.name}`);
                    logging.debug(
                        `[FocusProvider] ${focusProviderName} - Focusing id: ${consumer.id} | name: ${consumer.name} with index ${i}.`
                    );
                    consumer.focus();
                    break;
                }
            }
        }
    });
    return rc(contexts.FocusContext.Provider, { value: context.current }, props.children);
}
