import { useContext, useEffect, useMemo } from 'react';
import { hooks, contexts } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';
import logging from '@sstdev/lib_logging';

const { ReadContext } = contexts;
const { useMemoizedObject } = hooks;
const { RFID, BARCODE, BLE } = constants.sensorTypes;

/**
 * @typedef {Object} Read
 * @property {'RFID'|'MANUAL'|'BARCODE'|'NONE'|'ANY'|'BLE'} [sensorType] Source of scan. _should_ never be NONE or ANY
 * @property {string} tagId HEX string representation of read
 * @property {string} asciiTagId ASCII string representation of read
 * @property {number} [rssi] rssi value (negative value between -100 and 0), only for RFID
 * @property {number} [batteryLevel] battery level of BLE tag (value between 0 and 100), only for BLE
 */

// Provide a default context with warnings where appropriate.
const noopWhenNoContext = () => {};
const noopWithWarning = () => {
    logging.warn('Read Context is undefined.  You may be missing a ReadProvider in your component hierarchy.');
};
const defaultContext = {
    sensorTypesAvailable: [],
    requestSensorTypes: noopWhenNoContext,
    reads: { get: () => [], subscribeToChange: () => {} },
    setMostRecentIndividualRead: noopWithWarning,
    addReads: noopWithWarning,
    removeRead: noopWithWarning,
    reset: noopWithWarning,
    reading: false,
    onChange: noopWithWarning,
    id: 'defaultReadContext'
};

/**
 * Starts sensor services for a given config and returns reader types available and
 * the read context.
 * @returns {{
 *      reads: Array<Read>,
 *      mostRecentIndividualRead: Read,
 *      addRead: {(hexTagId: string) => void},
 *      removeRead: {(read: {tagId?:string, asciiTagId?:string}) => void},
 *      reset: function():void
 * }}
 */
export default function useReads(sensorTypes = [BARCODE, RFID, BLE]) {
    const memoizedSensorTypes = useMemoizedObject(sensorTypes);

    const context = useContext(ReadContext) ?? defaultContext;
    const requestSensorTypes = context.requestSensorTypes;

    useEffect(() => {
        requestSensorTypes(memoizedSensorTypes);
    }, [requestSensorTypes, memoizedSensorTypes]);

    return useMemo(() => context, [context]);
}
