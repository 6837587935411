import { createElement as rc, Fragment, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useDarkMode from '../../hooks/useDarkMode';
import useCustomTheme from '../../hooks/useCustomTheme';
import { globalConfig } from 'lib_ui-services';
import { BRAND_THEMES, h1, View, styled, themeValues } from 'lib_ui-primitives';
import GlobalStyle from './createGlobalStyle';
import _isIos from './isIos';
import merge from 'lodash/merge';
/*
(320 x 461) MC3390 total pixels (should be 'small mobile')
    web: 129920
    native: 147626.6
(360 x 590) TC5x, allowing 50 px for "Other" screen elements (should be larger than 'small mobile')
    194400 (web or native?)
*/
const MAX_SMALL_MOBILE = 160000; // somewhere safely in between MC33 and TC5X
const MAX_MOBILE_PIXELS = 396328; // (428 x 926) iphone 6.7"
const MAX_TABLET_PIXELS = 995796; // (834 x 1194) iPad 11"

const LoadingThemeStyle = styled(View)`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Centered = styled(h1)`
    text-align: center;
`;
export default function Theme(props) {
    const { children } = props || {};
    const [darkMode, setDarkMode, ready] = useDarkMode();
    const [customTheme] = useCustomTheme();
    const { height, width, keyboard = 0 } = useWindowDimensions();
    const isIos = useMemo(() => _isIos(), []);
    const totalPixels = height * width;
    const tablet = totalPixels <= MAX_TABLET_PIXELS && totalPixels > MAX_MOBILE_PIXELS;
    const mobile = MAX_MOBILE_PIXELS >= totalPixels;
    const smallMobile = MAX_SMALL_MOBILE >= totalPixels;

    // Avoid rerenders
    const theme = useMemo(() => {
        const regularTheme = themeValues({
            keyboardHeight: keyboard,
            height,
            width,
            darkMode,
            setDarkMode,
            mobile,
            tablet,
            smallMobile,
            isIos
        });
        // Need a new object if anything changes.  Otherwise, the UI won't rerender
        return { ...merge(regularTheme, customTheme) };
    }, [keyboard, height, width, darkMode, setDarkMode, mobile, tablet, smallMobile, isIos, customTheme]);

    if (!ready) {
        return rc(LoadingThemeStyle, null, rc(Centered, null, 'Loading Theme Preference'));
    }

    // prettier-ignore
    return rc(Fragment, null,
        GlobalStyle ? rc(GlobalStyle, { theme }) : null,
        rc(ThemeProvider, { theme, ...BRAND_THEMES[globalConfig().brand] }, children)
    );
}
